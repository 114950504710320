import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCityDropDown } from "../../../hooks/DropDown_Api";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Edit_Transport_Format = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Transport Format"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    // const { id } = useParams()


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.transport_detail?.id
    const tempData = addData?.transport_detail
    const data = tempData
    const prevDataRef = useRef();



    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_TRANSPORT_FORMAT_API?.toString()
    // const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            prevDataRef.current = data;
        }
    }, [data, reset]);

    const [selCity1, setSelCity1] = useState('')
    const [selCity2, setSelCity2] = useState('')
    const [selectedCity1, setSelectedCity1] = useState("")
    const [selectedCity2, setSelectedCity2] = useState("")

    const [forCity1Value, setForCity1Value]: any = useState()
    const [forCity2Value, setForCity2Value]: any = useState()
    const [cityOptions, setCityOptions]: any = useState([])
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        setCityOptions(cityData)
    }, [cityData])


    const [forState, setForState] = useState("")
    const [forCountry, setForCountry] = useState("")


    const [totalOfCities, setTotalOfCities] = useState(0)

    useEffect(() => {
        if (forCity1Value || forCity2Value) {
            setTotalOfCities(1)
            setValue("total_city_qty", 1)
        }
        if (forCity1Value && forCity2Value) {
            setTotalOfCities(2)
            setValue("total_city_qty", 2)
        }
    }, [forCity1Value, forCity2Value, totalOfCities])


    const handleChangeCity1 = (e: any) => {

        // setForCity1Value(e)
        setValue("city_1", e?.value)
        setSelCity1(e)

    }


    const handleChangeCity2 = (e: any) => {

        // setForCity2Value(e)
        setValue("city_2", e?.value)
        setSelCity2(e)
    }



    const handleReset = () => {
        reset()
    }


    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedCity1(data.city_1?.id)
            setValue("city_1", data.city_1?.id)
            setSelectedCity2(data.city_2?.id)
            setValue("city_2", data.city_2?.id)
            // setTotalOfCities(data?.total_city_qty)
            // setValue("total_city_qty",data?.total_city_qty)
        }
    }, []);


    const onSubmit = (materialtypeDetails: any) => {

        if (!forCity2Value) {
            delete materialtypeDetails?.city_2
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)


    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");


            let tempId = {
                transport_detail: data?.data?.id
            }

            let tempData = {
                id: basicIdTemp,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            setBasicDetailsInitiated(true);
            //   navigate("/listCity", { state: { showEditToast: true } });
            // setCurrentStep(currentStep + 1)
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
        isSuccess,]);

    const [updateCompleted, setUpdateCompleted] = useState(false)

    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            //   localStorage.setItem('Edit Data',JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            if (personTypeLabel) {

                navigate('/listPersonDetail')
                localStorage.removeItem('PersonType')
                localStorage.removeItem('Add Data')
            } else {

            }
            // navigate('listPersonDetail')
            //   setCurrentStep(currentStep + 1);
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, []);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const currentPath = window.location.pathname;


    const apinameTransport = process.env.REACT_APP_TRANSPORT_FORMAT_API?.toString()

    useEffect(() => {
        if (data.id) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameTransport}/${data.id}/`);
                    reset(response?.data?.data)

                    setSelectedCity1(response?.data?.data.city_1?.id)
                    setValue("city_1", response?.data?.data.city_1?.id)
                    setSelectedCity2(response?.data?.data.city_2?.id)
                    setValue("city_2", response?.data?.data.city_2?.id)




                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }

    }, [])



    return (
        <div>
            <div >
                <section className="content-header">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales And Marketing</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                            <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name?addData?.name:addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">

                                                    <label >Transport Code</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" placeholder="Transport Code" {...register("code", { required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Priority</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Priority" {...register("priority", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.priority?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Transport Id <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Transport Id" {...register("transport_id", { required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.transport_id?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Transport Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Transport Name" {...register("name", { onChange: handleValue, required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Transport City-1 <span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        isClearable
                                                        {...register("city_1", { required: { value: true, message: "Field is required" } })}
                                                        placeholder="Transport City-1"
                                                        onChange={handleChangeCity1}
                                                        options={cityOptions}
                                                        components={customIndicator}
                                                        value={selCity1 === "" ? cityOptions?.find((obj: any) => obj.value === selectedCity1) : selCity1}

                                                    />

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Transport City-2 </label>

                                                    <Select
                                                        isClearable
                                                        {...register("city_2", { required: { value: false, message: "Field is required" } })}
                                                        placeholder="Transport City-2"
                                                        value={selCity2 === "" ? cityOptions?.find((obj: any) => obj.value === selectedCity2) : selCity2}
                                                        options={cityOptions}
                                                        components={customIndicator}
                                                        onChange={handleChangeCity2}

                                                    />

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone,Village & City Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" disabled className="form-control" value={totalOfCities ? totalOfCities : data?.total_city_qty} placeholder="Zone,Village & City Qty" {...register("total_city_qty", { required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.total_city_qty?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Mobile Number Of City 1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Mobile Number Of City 1"
                                                            maxLength={10}
                                                            minLength={15}
                                                            onInput={maxLengthCheck}
                                                            {...register('mobile_no_city_1', {

                                                                required: 'Mobile Number is required',
                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_no_city_1?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Mobile Number Of City 2 </label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Mobile Number Of City 2"
                                                            maxLength={10}
                                                            minLength={15}
                                                            onInput={maxLengthCheck}
                                                            {...register('mobile_no_city_2', {

                                                                required: false,

                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_no_city_2?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Email ID Of City 1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder="Email ID Of City 1"
                                                            {...register('email_id_city_1', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_id_city_1?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Email ID Of City 2 </label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder="Email ID Of City 2"
                                                            {...register('email_id_city_2', {
                                                                required: false,
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_id_city_2?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >State Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="State Name" {...register("state", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


                                                    <label >Country Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Country Name" {...register("country", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>

            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>



            {show && (
                <Mui_Toaster
                    message={`Transport Updated Successfully`}
                    severity={"success"}
                    Isopen={true}
                />
            )}
        </div>
    )
}

export default Edit_Transport_Format


