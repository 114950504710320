import DatePicker from 'antd/es/date-picker';
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import Select from "react-select"
import { useFetchRawMaterialGardeDropDown, useFetchEmployeeDropDown, useFetchMachineDropDown, useFetchSheetCodeNameDropDown } from '../../../hooks/DropDown_Api';
import { RxCross2 } from "react-icons/rx";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import { QueryClient, InvalidateQueryFilters } from 'react-query';
import { Tooltip } from "@mui/material";

import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import { useCreate } from '../../../hooks/Api';
import { useQueryClient } from 'react-query';
import Axiosinstance from '../../../hooks/Interceptor';
function getSteps() {
    return ['Step 1', 'Step 2'];
}


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}




const Granual = ({ onOpen, setOnOpen, setNewAdded }: any) => {
    const queryClient = useQueryClient();
    const [openModel, setOpenModel] = useState(true)
    const navigate = useNavigate()

    const [optionsProductCode, setOptionsProductCode]: any = useState([])
    const [forProductCodeValue, setForProductCodeValue]: any = useState()
    const [ProductQty, setProductQty]: any = useState('')



    const apiname = process.env.REACT_APP_PRODUCTION_PRODUCTION_LINE_API?.toString()


    const { mutate: Granual, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, reset, setValue, getValues, control, trigger, formState: { errors } }: any = useForm()
    const apinameGrade = process.env.REACT_APP_PRODUCTION_SHEET_RAW_MATERIAL_BULK_API?.toString()


    const { mutate: mutateGrade, data: dataGrade, isError: isErrorGrade, isLoading: isLoadingGrade, isSuccess: isSuccessGrade, error: errorGrade }: any = useCreate(apinameGrade)
    // const { register: registerGrade, handleSubmit:handleSubmitGrade, setError:setErrorGrade, formState:formStateGrade, reset:resetGrade, setValue:setValueGrade }: any = useForm();

    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [HelperOptions, setHelperOptions]: any = useState([])

    const [forOperatorValue, setForOperator]: any = useState("")
    const [forHelperValue, setForHelper]: any = useState([])
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()



    useEffect(() => {
        setEmployeeOptions(EmployeeData)
        setHelperOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeOperator = (e: any) => {

        setForOperator(e)
        setValue("opretar", e?.value)
    }
    const handleChangeHelper = (e: any) => {
        setForHelper(e)
        const helper = e?.map((e: any) => {
            return e.value
        })
        setValue("helper", helper)
    }



    const [MachineOptions, setMachineOptions]: any = useState([])
    const [MachineValue, setMachineValue]: any = useState('')

    const { data: MachineData, refetch: MachineRefetch } = useFetchMachineDropDown()

    useEffect(() => {
        setMachineOptions(MachineData)
    }, [EmployeeData])

    const handleChangeMachine = (e: any) => {

        setMachineValue(e)
        setValue("machine", e?.value)
    }

    const [SheetCodeOptions, setSheetCodeOptions]: any = useState([])
    const [SheetCodeValue, setSheetCodeValue]: any = useState('')
    const [Grade, SetGrade] = useState('')
    const [Color, SetColor] = useState('')
    const [AllProduct, SetAllProduct] = useState('')

    const { data: SheetCodeData, refetch: SheetCodeRefetch } = useFetchSheetCodeNameDropDown()

    useEffect(() => {
        setSheetCodeOptions(SheetCodeData)
    }, [SheetCodeData])

    const handleChangeSheetCode = (e: any) => {
        SetGrade(e.grade)
        SetColor(e.color)
        SetAllProduct(e.allproduct)
        setSheetCodeValue(e)
        setValue("sheet", e?.value)
    }

    const { data: ProductCodeData, refetch: ProductCodeRefetch } = useFetchRawMaterialGardeDropDown()

    useEffect(() => {
        setOptionsProductCode(ProductCodeData)
    }, [ProductCodeData])

    // const handleChangeProductCode = (e: any) => {
    //     e?.preventDefault()
    //     setForProductCodeValue(e)
    //     setValue('sheet_raw_material_temp', e.value)




    //     let tempAdd = {
    //         id: e?.value,
    //         label: e?.label,
    //         kg: 0,
    //         type: 'number'
    //     }


    //     setAddToForm((oldFields: any) => [...oldFields, tempAdd])
    // }


    const handleChangeProductCode = (selectedItem: any) => {
        setForProductCodeValue(selectedItem);
        setValue('sheet_raw_material_temp', selectedItem?.value);

        let tempAdd = {
            id: selectedItem.value,
            label: selectedItem.label,
            grade: selectedItem.grade,
            gradeType: selectedItem.gradeType,
            materialName: selectedItem.materialName,
            kg: 0,
            type: 'number'
        };

        setAddToForm((oldFields: any) => [...oldFields, tempAdd]);
    }






    const [addToForm, setAddToForm]: any = useState([])


    const [showData, setShowData]: any = useState([])

    const handleAdd = (e: any) => {
        // setForProductCodeValue(e);
        setShowData((oldData: any) => [...oldData, ...addToForm]);
        setAddToForm([])

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listProductionline', { state: { showToast: true } })
            setOpenModel(false)
            setOnOpen(false)
            setNewAdded(true)
            queryClient.invalidateQueries(['Fetch']);
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleFinalSubmit = async () => {
        let finalData = showData?.map((e: any) => {

            return {
                raw_material: e.id,
                kg: e.kg.toFixed(3)
            }
        });
        const isFormValid = await trigger();
        if (isFormValid) {
            mutateGrade({ data: finalData })
        }

    }

    useEffect(() => {
        const goToCall = async () => {
            if (isSuccessGrade && dataGrade) {
                let sheet_raw_material = dataGrade?.data?.map((e: any) => {
                    return e.id
                })

                await setActiveStep(activeStep - 1)

                let final = { sheet_raw_material, ...oneData, raw_material_betch_total_kg: TotalKg.toFixed(3) }
                await Granual(final)


            }
        }
        goToCall()
    }, [isSuccessGrade, dataGrade])

    const [TotalKg, setTotalKg]: any = useState(0)
    const handleRemove = (data: any) => {


        setAddToForm((oldData: any) => oldData?.filter((e: any) => e.id !== data.id))
        // calculateTotalSum(addToForm);
    }

    useEffect(() => {
        if (addToForm) {
            calculateTotalSum(addToForm);
        }
    }, [addToForm])


    const calculateTotalSum = (data: any) => {
        const sum = showData.reduce((acc: any, item: any) => acc + (parseFloat(item.kg) || 0), 0);
        setTotalKg(sum);
        setValue("raw_material_betch_total_kg", sum)
    };

    const handleChangeKg = useCallback((e: any, data: any, index: any) => {
        const tempData = [...addToForm];
        tempData[index].kg = parseFloat(e.target.value);
        setAddToForm(tempData);
        calculateTotalSum(tempData);
    }, [addToForm]);

    // useEffect(()=>{
    //     const sum = data?.reduce((acc: any, item: any) => acc + (parseFloat(item.kg) || 0), 0);
    //     setTotalKg(sum);
    //     setValue("raw_material_betch_total_kg", sum)
    // },[addToForm,data])




    const [oneData, setOneData]: any = useState()




    const convertFormDataToRawData = (formData: any) => {
        const rawData: any = {};
        formData.forEach((value: any, key: any) => {
            const isNumber = !isNaN(value) && value.trim() !== '';
            if (key === 'helper') {
                if (!rawData.helper) {
                    rawData.helper = [];
                }
                if (isNumber && !rawData.helper.includes(Number(value))) {
                    rawData.helper.push(Number(value));
                }
            } else {
                if (rawData.hasOwnProperty(key) && key === 'helper') {
                    if (!Array.isArray(rawData[key])) {
                        rawData[key] = [rawData[key]];
                    }
                    if (isNumber && !rawData[key].includes(Number(value))) {
                        rawData[key].push(Number(value));
                    }
                } else {
                    rawData[key] = isNumber ? Number(value) : value;
                }
            }
        });

        return rawData;
    };


    const onSubmit = async (granual: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(granual)) {
            let formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "helper") {
                value.forEach((val, index) => formData.append(`helper`, val))
            }
        }

        const rawData = convertFormDataToRawData(formData);
        setOneData(rawData);
    };

    const [shift,setShift]:any = useState()

const onChangeShift = (e:any) =>{
    setShift(e.target.value)
}



const apiNamegranualTotal= process.env.REACT_APP_PRODUCTION_PRODUCTION_LINE_API?.toString()
let todayDate = new Date().toISOString().split("T")[0]
// let isoDate = "2024-01-31"; // Replace with any ISO format date
let [year, month, day] = todayDate.split("-");
let formattedDate = `${day}-${month}-${year}`;

const [totalBatch,setTotalBatch] = useState('')

useEffect(()=>{
    // if(data?.mould_shape.length>0){
        const getCall = async()=>{
            if(shift){
        try {
            const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiNamegranualTotal}/search_filter/`, {
              date: formattedDate,
              opretar_id:forOperatorValue.value,
              shift_time:shift
            });
            let a = response.data?.results?.filter((e:any)=>e?.code!=='Total')
            setTotalBatch(a?.length)

          } catch (error) {
            console.error('Error:', error);
          }
        }
    }
        getCall()
    // }
},[shift])





    const getStepContent = (stepIndex: any) => {
        switch (stepIndex) {
            case 0:

                return (
                    <>
                        {activeStep !== 1 &&
                            <form name='step -1' key={'0'} onSubmit={handleSubmit(onSubmit)}>


                                <div className="card-body" style={{ maxHeight: "100vh" }}>
                                    <div className="row d-flex justify-content-md- center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Operator Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="">
                                                        <Select
                                                            {...register("opretar", { required: { value: true, message: "Operator Code & Name is required" } })}
                                                            placeholder="Operator Code & Name"
                                                            options={employeeOptions}
                                                            onChange={handleChangeOperator}
                                                            value={forOperatorValue}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}


                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.opretar?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Helper Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="">
                                                        <Select
                                                            {...register("helper", { required: { value: true, message: "Helper Code & Name is required" } })}
                                                            placeholder="Helper Code & Name"
                                                            options={HelperOptions}
                                                            onChange={handleChangeHelper}
                                                            value={forHelperValue}
                                                            maxMenuHeight={150}
                                                            isMulti
                                                            components={customIndicator}


                                                        />


                                                    </div>
                                                    <span className='text-red w-100'>{errors?.helper?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="">
                                                        <Select
                                                            placeholder={'Machine Code & Name'}
                                                            {...register("machine", { required: { value: true, message: "Machine Code & Name is required" } })}
                                                            value={MachineValue}
                                                            options={MachineOptions}
                                                            components={customIndicator}
                                                            onChange={handleChangeMachine}
                                                            maxMenuHeight={150}

                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.machine?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sheet Code, Grade, Name & Color <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="">
                                                        <Select
                                                            {...register("sheet", { required: { value: true, message: "Sheet Code, Grade, Name & Color is required" } })}
                                                            options={SheetCodeOptions}
                                                            components={customIndicator}

                                                            value={SheetCodeValue}
                                                            onChange={handleChangeSheetCode}
                                                            maxMenuHeight={150}

                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheet?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Shift <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <select className="form-select" 
                                                            {...register("shift_time", {  required: { value: true, message: "Shift is required" } })}
                                                            onChange={onChangeShift}
                                                        >
                                                            <option value={""} selected>Select Any</option>
                                                            <option value={"Day"}>Day</option>
                                                            <option value={"Night"}>Night</option>
                                                        </select>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.shift_time?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Grade Name </label>
                                                    <div className="d-flex row" >
                                                    </div>
                                                    <input type="text" className="form-control" disabled
                                                        value={Grade}
                                                        placeholder="Material Grade Name"
                                                    />
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Color </label>
                                                    <div className="d-flex row" >
                                                    </div>
                                                    <input type="text" className="form-control" disabled
                                                        value={Color}
                                                        placeholder="Material Color"
                                                    />
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Total Batch </label>
                                                    <div className="d-flex row" >
                                                    </div>
                                                    <input type="text" className="form-control" disabled
                                                        value={totalBatch}
                                                        placeholder="Total Batch"
                                                    />
                                                </div>

                                            </div>
                                            <div className='row' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">All Single Code & Name & Type </label>
                                                    <div className="d-flex row" >
                                                    </div>
                                                    <input type="text" className="form-control" disabled

                                                        placeholder="All Single Code & Name & Type"
                                                    />
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">All Product Code & Name & Type </label>
                                                    <div className="d-flex row" >
                                                    </div>
                                                    <input type="text" className="form-control" disabled value={AllProduct}

                                                        placeholder="All Product Code & Name & Type"
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                    </>

                )

            case 1:
                return (

                    <form name='step -2' key={'1'} >
                        <div className="card-body" style={{ maxHeight: "100vh" }}>
                            <div className="row d-flex justify-content-md- center justify-content-lg-start">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm" style={{ paddingRight: '0px' }}>


                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Operator Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                    {/* <Select
                                                        // {...register("opretar", { required: { value: true, message: "Operator Code & Name is required" } })}
                                                        placeholder="Operator Code & Name"
                                                        options={employeeOptions}
                                                        onChange={handleChangeOperator}
                                                        value={forOperatorValue}
                                                        // components={customIndicator}
                                                        // maxMenuHeight={150}
                                                        isDisabled

                                                    /> */}

                                                    <input type="text" className="form-control" disabled
                                                        value={forOperatorValue?.label}
                                                        placeholder="Operator Code & Name"
                                                    />

                                                </div>
                                                <span className='text-red w-100'>{errors?.opretar?.message}</span>

                                            </div>

                                            {/* <Tooltip title={<h5 style={{ fontSize: '15px', color: 'white' }}>{forHelperValue[0]?.label}</h5>} placement="top" arrow > */}
                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Helper Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                    <Select
                                                        // {...register("helper", { required: { value: true, message: "Helper Code & Name is required" } })}
                                                        placeholder="Helper Code & Name"
                                                        options={HelperOptions}
                                                        onChange={handleChangeHelper}
                                                        value={forHelperValue}
                                                        // maxMenuHeight={150}
                                                        isMulti
                                                        // components={customIndicator}
                                                        isDisabled
                                                        
                                                    />
                                                  
                                                  
                                                    
                                                        {/* <input type="text" className="form-control" disabled
                                                            value={forHelperValue?.map((e: any) => e?.label)}
                                                            placeholder="Helper Code & Name"
                                                        /> */}
                                                <span className='text-red w-100'>{errors?.helper?.message}</span>
                                                </div>

                                            </div>
                                            {/* </Tooltip> */}

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Machine Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                    {/* <Select
                                                        placeholder={'Machine Code & Name'}
                                                        // {...register("machine", { required: { value: true, message: "Machine Code & Name is required" } })}
                                                        value={MachineValue}
                                                        options={MachineOptions}
                                                        // components={customIndicator}
                                                        onChange={handleChangeMachine}
                                                        // maxMenuHeight={150}
                                                        isDisabled
                                                    /> */}
                                                    <input type="text" className="form-control" disabled
                                                        value={MachineValue?.label}
                                                        placeholder={'Machine Code & Name'}
                                                    />

                                                </div>
                                                <span className='text-red w-100'>{errors?.machine?.message}</span>

                                            </div>



                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Sheet Code, Grade, Name & Color </label>
                                                </div>
                                                <div className="col-6 ">
                                                    {/* <Select
                                                        // {...register("sheet", { required: { value: true, message: "Sheet Code, Grade, Name & Color is required" } })}
                                                        options={SheetCodeOptions}
                                                        // components={customIndicator}

                                                        value={SheetCodeValue}
                                                        onChange={handleChangeSheetCode}
                                                        // maxMenuHeight={150}
                                                        isDisabled
                                                    /> */}
                                                    <input type="text" className="form-control" disabled
                                                        value={SheetCodeValue?.label}
                                                        placeholder="Sheet Code, Grade, Name"
                                                    />
                                                </div>
                                                <span className='text-red w-100'>{errors?.sheet?.message}</span>

                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Total Batch </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                
                                                    <input type="text" className="form-control" disabled
                                                        value={totalBatch}
                                                        placeholder="Total Batch"
                                                    />
                                               
                                                </div>
                                            </div>


                                        </div>

                                        <div className="col-sm" style={{ paddingRight: '0px' }}>
                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label >Shift </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    {/* <select className="form-select" disabled
                                                        {...register("shift_time", { required: { value: true, message: "Shift is required" } })}
                                                    >
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"Day"}>Day</option>
                                                        <option value={"Night"}>Night</option>
                                                    </select> */}
                                                    <input type="text" className="form-control" disabled
                                                        // value={Grade}
                                                        {...register("shift_time", { required: { value: true, message: "Shift is required" } })}
                                                        placeholder="Material Grade Name"
                                                    />
                                                </div>
                                                <span className='text-red w-100'>{errors?.shift_time?.message}</span>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Material Grade Name </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled
                                                        value={Grade}
                                                        placeholder="Material Grade Name"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Material Color </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled
                                                        value={Color}
                                                        placeholder="Material Color"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">All Single Code & Name & Type </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled

                                                        placeholder="All Single Code & Name & Type"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">All Product Code & Name & Type </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled value={AllProduct}

                                                        placeholder="All Product Code & Name & Type"
                                                    />
                                                </div>
                                            </div>
                                         
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Raw Material Grade & Type <span style={{ color: 'red' }}>*</span></label>
                                            <div className="d-flex row" >
                                                <Select
                                                    placeholder={'Raw Material Grade & Type'}
                                                    {...register("sheet_raw_material_temp", { required: { value: true, message: "Raw Material Grade & Type is required" } })}
                                                    onChange={handleChangeProductCode}
                                                    options={optionsProductCode}
                                                    components={customIndicator}
                                                    value={forProductCodeValue}

                                                    isOptionDisabled={(optionsCodeName: any) => {
                                                        const temp = addToForm.map((e: any) => e.id);
                                                        return temp.includes(optionsCodeName.value);
                                                    }}

                                                />

                                            </div>
                                            <span className='text-red w-100'>{errors?.sheet_raw_material_temp?.message}</span>
                                        </div>


                                        {/* <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}></span></label>
                                            <div className="d-flex row" >
                                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"

                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                    onClick={handleAdd}
                                                    disabled={!forProductCodeValue}
                                                >Add</button>

                                            </div>
                                        </div> */}
                                        {/* <div className='row'> */}
                                        {addToForm?.map((data: any, index: any) => {
                                            return (
                                                <div className="col-sm-3 mt-2" key={data?.id}>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <label htmlFor="exampleInputEmail1">Material-{data.label} Kg.<span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div>
                                                            <RxCross2 onClick={() => handleRemove(data)} style={{ color: 'red' }} />
                                                        </div>
                                                    </div>
                                                    <div className=" input-group" >
                                                        <input
                                                            className="form-control"
                                                            type={data.type}
                                                            placeholder={data.label}
                                                            value={data?.kg}
                                                            onChange={(e) => handleChangeKg(e, data, index)}
                                                        />

                                                    </div>

                                                </div>

                                            )
                                        })}
                                        <div className="col-sm-2">
                                            <label htmlFor="exampleInputEmail1"> </label>
                                            <div className="d-flex row" >
                                                <button
                                                    type="button" className="border-1 rounded text-white px-2 py-1  mt-3"

                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                    onClick={handleAdd}
                                                    disabled={!forProductCodeValue}
                                                >Add</button>

                                            </div>
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Raw Material Batch Total Kg. <span style={{ color: 'red' }}>*</span></label>
                                            <div className="d-flex row" >
                                            </div>
                                            <input type="text" className="form-control" disabled
                                                label="Step 2 Field"
                                                value={TotalKg}

                                                placeholder="Raw Material Batch Total Kg."
                                                {...register("raw_material_betch_total_kg", { required: { value: false, message: "Raw Material Batch Total Kg. is required" } })}
                                            />
                                        </div>
                                        {/* </div> */}
                                        <div className="mt-2 ">
                                            <table className="table table-bordered" style={{ textAlign: 'center' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr.<br />No.</th>
                                                        <th>Grade<br />Type</th>
                                                        <th>Material<br />Name</th>
                                                        <th>Material<br />Grade Name</th>
                                                        <th>Material<br />Color Name</th>
                                                        <th>Material<br />Kg.</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {showData?.map((field: any, index: any) => {
                                                        ;
                                                        return (

                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{field.gradeType}</td>
                                                                <td>{field.materialName}</td>
                                                                <td>{field.grade}</td>
                                                                <td>{Color}</td>
                                                                <td>{field.kg}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr style={{ backgroundColor: '#aaaaaa' }}>
                                                        <td colSpan={2}>Total</td>
                                                        {/* <td>-</td> */}
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>{TotalKg}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );

            default:
                return 'Unknown step';
        }
    }





    const [activeStep, setActiveStep]: any = useState(0);
    const steps = getSteps();

    const handleNext = async () => {
        const isFormValid = await trigger();

        if (isFormValid) {


            setActiveStep((prevActiveStep: any) => {
                if (prevActiveStep === 1) {
                    return prevActiveStep;
                }
                return prevActiveStep + 1;
            });

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };




    return (
        <div>

            <Modal
                size="xl"
                show={openModel}
                onHide={() => {
                    setOpenModel(false)
                    setOnOpen(false)
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title style={{ fontSize: '16px' }}>Production Line </Modal.Title>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '70%', height: '13%' }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel></StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Modal.Header>
                <Modal.Body>


                    <div>
                        {activeStep === steps.length ? (
                            <div>

                            </div>
                        ) :
                            (
                                <div>
                                    <Typography>{getStepContent(activeStep)}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Edit
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? <>
                                                <Button className='border-0' onClick={handleFinalSubmit}>Submit</Button>
                                            </> : <Button className='border-0' onClick={handleSubmit(onSubmit)}>Submitt</Button>}
                                        </Button>
                                    </Box>
                                </div>
                            )}
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Granual