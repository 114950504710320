
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";


interface Data {
  username: any
  is_active: any
  code_and_employees_name_and_city: any
  groups: any
  department: any
  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  status: any;
  actions: any;
}


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,


  },

  {
    id: 'username',
    Header: `UserName`,



  },

  {
    id: 'department',
    Header: `Department`,
  },



  {
    id: 'code_and_employees_name_and_city',
    Header: `Code, Name & City`,
  },

  {
    id: 'groups',
    Header: `Groups`,

  },



  {
    id: 'status',
    Header: 'Status',



  },

  {
    id: 'created_at',
    Header: `Created At`,

  },


  {
    id: 'created_by',
    Header: `Created By`,



  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_UserAdmin: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",

  },
  // {
  //   id: `name`,
  //   Header: () => <div>U.O.M<br/>Name</div>,
  //   sticky: "left",
  //   accessor:'name',

  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.name}
  //       </div>
  //   )}

  // },
  {
    id: 'username',
    Header: () => <div style={{ display: "inline" }}>User<br />Id</div>,
    sticky: "left",
    accessor: 'username',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.username
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }


  },
  {
    id: 'employee',
    Header: `Employee`,
    sticky: "left",
    accessor: 'employee',

    Cell: ({ row }: any) => {
      return <div>
        {row.original.extra_response?.code_and_employees_name_and_city !== null ?
          <>
            {`${row.original.extra_response?.code_and_employees_name_and_city?.surname} ${row.original.extra_response?.code_and_employees_name_and_city?.name} ${row.original.extra_response?.code_and_employees_name_and_city?.father_name}`}
          </> : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
      // let employee=`${row.original.extra_response?.code_and_employees_name_and_city!==null?`${row.original.extra_response?.code_and_employees_name_and_city?.surname} ${row.original.extra_response?.code_and_employees_name_and_city?.name} ${row.original.extra_response?.code_and_employees_name_and_city?.father_name}`:<span style={{ color: 'red',fontSize:"20px" }}>****</span> }`


      // ?row.original.code_and_employees_name_and_city:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    }
  },
  {
    id: 'department',
    Header: `Department`,
    accessor: 'department',

    Cell: ({ row }: any) => row.original.extra_response?.code_and_employees_name_and_city?.department?.name ? row.original.extra_response?.code_and_employees_name_and_city?.department?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
    // ?row.original.code_and_employees_name_and_city:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: 'designation',
    Header: `Designation`,
    accessor: 'designation',

    Cell: ({ row }: any) => row.original.extra_response?.code_and_employees_name_and_city?.designation?.name ? row.original.extra_response?.code_and_employees_name_and_city?.designation?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
    // ?row.original.code_and_employees_name_and_city:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: 'mobile',
    Header: () => <div style={{ display: "inline" }}>Mobile<br />Number</div>,
    accessor: 'mobile',
    Cell: ({ row }: any) => row.original.extra_response?.code_and_employees_name_and_city?.contact_detail?.mobile_no ? row.original.extra_response?.code_and_employees_name_and_city?.contact_detail?.mobile_no : <span style={{ color: 'red',fontSize:"20px" }}>****</span>
    // ?row.original.code_and_employees_name_and_city:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: 'email',
    Header: () => <div style={{ display: "inline" }}>Email<br />Id</div>,
    accessor: 'email',
    Cell: ({ row }: any) => row.original.extra_response?.code_and_employees_name_and_city?.contact_detail?.email_id ? row.original.extra_response?.code_and_employees_name_and_city?.contact_detail?.email_id : <span style={{ color: 'red',fontSize:"20px" }}>****</span>
    // ?row.original.code_and_employees_name_and_city:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: 'groups',
    Header: () => <div style={{ display: "inline" }}>User<br />Type</div>,
    accessor: 'groups',

    Cell: ({ row }: any) => {
      let group = row.original?.extra_response?.group_name?.map((e: any) => {
        return e
      })
      return (
        <div>
          {group?.join(",")}
        </div>
      )
    }
  },

  {
    id: 'finger_print_id',
    Header: () => <div style={{ display: "inline" }}>Finger<br />Print Id</div>,
    accessor: 'finger_print_id',
    Cell: ({ row }: any) => row.original.finger_print_id?row.original.finger_print_id:<span style={{ color: 'red',fontSize:"20px" }}>****</span>


  },

  {
    id: 'face_id',
    Header: () => <div style={{ display: "inline" }}>Face<br />Id</div>,
    accessor: 'face_id',
    Cell: ({ row }: any) => row.original.face_id?row.original.face_id:<span style={{ color: 'red',fontSize:"20px" }}>****</span>


  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor:'is_active',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original.is_active
  //     // if (!convertToUpper) return "";
  //     // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }

  // },



  // {
  //   id: 'baseUnit',
  //   Header: () => <div>Base<br/>Unit</div>,
  //   accessor:'baseUnit',

  //   Cell: ({ row }: any) => row.original.baseUnit?.name?row.original.baseUnit?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  // },

  // {
  //   id: 'conversationRatio',
  //   Header: () => <div>Conversation<br/>Ratio</div>,
  //   accessor:'conversationRatio',
  //   Cell: ({ row }: any) => row.original.conversationRatio

  // },




  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },







  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_UserAdmin, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LEOUSER_GROUP_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



