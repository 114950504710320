import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineMail, AiOutlinePlus } from 'react-icons/ai';
import Select from "react-select";
import { RiPencilLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import useFocusOnEnter from '../../../components/focus';
import { useForm } from 'react-hook-form';
import { useFetchCityDropDown, useFetchDepartmentDropDown, useFetchEmployeeDropDown, useFetchFaultDropDown, useFetchMachineLocationDropDown, useFetchMaintenanceIssueDropDown, useFetchMaterialTypeDropDown, useFetchUOMDropDown } from '../../../hooks/DropDown_Api';
import { useCreate } from '../../../hooks/Api';
import { DatePicker, TimePicker, Upload } from 'antd';
import Button from "react-bootstrap/esm/Button";
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { Alert } from "../../../components/Model/Model";
import { nanoid } from 'nanoid';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Axiosinstance from '../../../hooks/Interceptor';
import Modal from "react-bootstrap/Modal";
import CreatableSelect from 'react-select/creatable';
import { Department_Model } from "../../../components/Dynamic_Form_Models/Department_Model";
import { Employee_Model } from '../../../components/Dynamic_Form_Models/Employee_Model';
import type { UploadFile } from 'antd/es/upload/interface';
import cameraLogo from "../../../images/Export/Camera.png";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import noImage from "../../../images/noImage.png"



interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});


const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Maintenance = () => {
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()

  const [inquiryModal, setInquiryModal] = useState(false)





  const apinameAddMaintenance = process.env.REACT_APP_MAINTENANCE_DETAIL_API?.toString()

  const {
    mutate: addMaintenance,
    data: addMaintenanceData,
    isError: addMaintenanceIsError,
    isLoading: addMaintenanceIsLoading,
    isSuccess: addMaintenanceIsSuccess,
    error: addMaintenanceError,
  }: any = useCreate(apinameAddMaintenance);


  const apinameGetMultipleMaintenance = process.env.REACT_APP_MAINTENANCE_FETCH_API?.toString();




  const {
    mutate: getMaintenance,
    data: getMaintenanceData,
    isError: getMaintenanceIsError,
    isLoading: getMaintenanceIsLoading,
    isSuccess: getMaintenanceIsSuccess,
    error: getMaintenanceError,
  }: any = useCreate(apinameGetMultipleMaintenance);

  const apiname = process.env.REACT_APP_MAINTENANCE_API?.toString();

  const {
    mutate,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);

  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });
  const { errors } = formState;

  const { register:registerAddMaintenance, handleSubmit:handleSubmitAddMaintenace, setError:setErrorAddMaintenace, formState:formStateAddMaintenace, reset:resetAddMaintenace, setValue:setValueAddMaintenace, getValues:getValuesAddMaintenace }: any = useForm();
  const { errors:errorsAddMaintenace } = formStateAddMaintenace;

  const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown();








  const [openIssuePerson, setOpenIssuePerson]: any = useState(false)
  const [openReference, setOpenReference]: any = useState(false)











  const [requirementDate, setRequirementDate] = useState(null)

  const dateFormatList = ['DD-MM-YYYY'];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {





    return current && current < dayjs().startOf('day');
  };







  const onSubmit = async (details: any) => {
    const formData = new FormData();


   

    for (const [key, value] of Object.entries(details)) {
      const formvalue: any = value



      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
      





    }

    


    if (Array.isArray(MaintenanceIds)) {
      MaintenanceIds.forEach((val: any, index: any) => formData.append(`maintenance_detail`, val))
    }

    if (displayData?.length === 0) {
      setNoProductError(true)
    } else {
      await mutate(formData);
    }
  }



  const handleReset = () => {
    reset()
    setRequirementDate(null)
    setRequirementDate(null)
  }



  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")
      navigate('/listMaintenance', { state: { showToast: true }})
    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [data, error, isSuccess])



  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 5000)

  }, [show]);









  const [EmployeeOptions, setEmployeeOptions]: any = useState([])
  const [EmployeeIssueOptions, setEmployeeIssueOptions]: any = useState([])
  const [ForIssueValue, setForIssueValue]: any = useState('')
  const [ForReferenceValue, setForReferenceValue]: any = useState('')
  const { data: employeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


  useEffect(() => {
    setEmployeeOptions(employeeData)
  }, [employeeData])
  useEffect(() => {
    setEmployeeIssueOptions(employeeData)
  }, [employeeData])


  const handleChangeIssueReg = (e: any) => {
    setForIssueValue(e)
    setValue('issue_registered_by_person_code_and_name', e.value)
  }
  const handleChangeReference = (e: any) => {
    setForReferenceValue(e)
    setValue('refrence_person_code_and_name', e.value)
  }











  const [additionalFields, setAdditionalFields]: any = useState(false);

  const handleAddMaintenance = () => {

    if(isMaintenanceLocation === null){
      setErrorMaintenanceLocation(true)
    }else{

    setAdditionalFields(true)
    }
   
  };


  const handleDeleteProduct = (id: any) => {
    
    resetAddMaintenace()
    setValueMaintenanceIssue(null)
    setValueMaintenanceIssueCreatable(null)
    setAdditionalFields(false)
  };





  const [added, setAdded] = useState(false)

  const [logo, setLogo]:any = useState([])

  const[errorMaintenanceLocation,setErrorMaintenanceLocation] = useState(false)
  const onSubmitAddMaintenance = (data: any) => {
    const formData = new FormData();

    for (let i = 0; i < logo.length; i++) {
        formData.append('image', logo[i]);
    }

    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value
        if (typeof value !== "object" && value !== undefined) {
            formData.append(key, formvalue);
        }
    }

    addMaintenance(formData);
    setAdded(true);
};


  useEffect(() => {
    if (added && addMaintenanceIsSuccess) {
      if (addMaintenanceData) {
        setMaintenanceIds((oldIds: any) => [...oldIds, addMaintenanceData?.data?.id]);


        // resetAddMaintenace()
        setValueAddMaintenace('maintenance_fault','')
        setValueAddMaintenace('reason_detail','')

        setValueMaintenanceIssue(null)
        setValueMaintenanceIssueCreatable(null)
        setAdditionalFields(false)
        setDateLimitValue(null)
        setForTimeLimit(null)
        setAdded(false)
        setLogo([])
        setUploadImage([])
      }


    }

  }, [addMaintenanceData, addMaintenanceIsSuccess, added])


  const handleChangeMaintenanceIssue = (selectedOption: any, index: any) => {

    setValueAddMaintenace('maintenance_issue',selectedOption?.value)
    setValueMaintenanceIssue(selectedOption)
    setValueMaintenanceIssueCreatable(null)

  };

















  const handleChangeFaultName = (e: any, index: any) => {
   

    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  

    setValueAddMaintenace('maintenance_fault',e.target.value)


  };


  
const [dateLimitValue,setDateLimitValue] = useState(null)
  const handleChangeDateLimit = (e: any, dateString:any) => {
 

    setValueAddMaintenace('maintenance_date_limit',dateString)
    setDateLimitValue(e)



  };

  const handleChangeTimeLimit = (e:any, timeString: any) => {




    setValueAddMaintenace('maintenance_time_limit',timeString)
    setForTimeLimit(e)



  }


  const handleChangeReasonDetail = (e: any, index: any) => {

    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

 

    setValueAddMaintenace('reason_detail',e.target.value)



  };




  const [displayData, setDisplayData] = useState([])
  const [MaintenanceIds, setMaintenanceIds]: any = useState([])
  const [noProductError, setNoProductError] = useState(false)




  useEffect(() => {
    if (displayData?.length > 0) {
      setNoProductError(false)
    }
  }, [displayData])




  useEffect(() => {
    if (getMaintenanceData) {
      setDisplayData(getMaintenanceData?.data)
    }
  }, [getMaintenanceData])

  useEffect(() => {
    if (MaintenanceIds) {
      getMaintenance({ ids: MaintenanceIds })
    }
  }, [MaintenanceIds])



  


  const [renderKey, setRenderKey]: any = useState(false)



  const handleDeleteProductInTable = async (id: any) => {
 
    await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apinameAddMaintenance}/${id}/`)

    setMaintenanceIds((currentIds: any) => currentIds.filter((currentId: any) => currentId !== id));

    getMaintenance({ ids: MaintenanceIds })
    setRenderKey((key: any) => key + 1)


  };



  useEffect(() => {
    if (isSuccess) {
      setDisplayData([])
      setMaintenanceIds([])
    }
  }, [isSuccess])




  const [valueMaintenanceIssue, setValueMaintenanceIssue]: any = useState();
  const [optionsMaintenanceIssue, setOptionsMaintenanceIssue]: any = useState([]);
  const [valueMaintenanceIssueCreatable, setValueMaintenanceIssueCreatable] = useState<Option | null>();

  const apinameForMaintenanceIssue = process.env.REACT_APP_MAINTENANCE_ISSUE_API?.toString()
  const { mutate: mutateMaintenanceIssue, data: dataMaintenanceIssue, isError: MaintenanceIssueIsError, isLoading: MaintenanceIssueLoading, isSuccess: MaintenanceIssueSuccess, error: MaintenanceIssueError }: any = useCreate(apinameForMaintenanceIssue)

  const { data: MaintenanceIssueData, refetch: MaintenanceIssueRefetch } = useFetchMaintenanceIssueDropDown()

  useEffect(() => {
    setOptionsMaintenanceIssue(MaintenanceIssueData)
  }, [MaintenanceIssueData])




  const handleMaintenanceIssueCreate = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);


      mutateMaintenanceIssue({ name: newOption.name, status: newOption.status })
      setOptionsMaintenanceIssue((prev: any) => [...prev, newOption]);
      setValueMaintenanceIssueCreatable(newOption);
    }, 0);


  };


  useEffect(() => {
    (async () => {
      if (MaintenanceIssueSuccess && optionsMaintenanceIssue) {
        await MaintenanceIssueRefetch();
        setValueAddMaintenace("maintenance_issue", MaintenanceIssueData[0]?.value)

      }
    })();

    return () => {
    };
  }, [MaintenanceIssueSuccess, MaintenanceIssueData]);

  const [openDepartment, setOpenDepartment] = useState(false)
  const [DepartmentOptions, setDepartmentOptions]: any = useState([])
  const [forDepartmentValue, setForDepartmentValue]: any = useState([])

  const { data: DepartmentData, refetch: DepartmentRefetch } = useFetchDepartmentDropDown()

  useEffect(() => {
    setDepartmentOptions(DepartmentData)
  }, [DepartmentData])

  const handleChangeDepartment = (e: any) => {
    setValue("department_name", e.value)
    setForDepartmentValue({ label: e?.label, value: e?.value })
  }


  const [submittedDepartment, setSubmittedDepartment] = useState(false)


  useEffect(() => {
    if (submittedDepartment) {
      setForDepartmentValue(DepartmentOptions[0])

      setValue("department_name", DepartmentOptions[0]?.value)

    }

  }, [submittedDepartment, DepartmentOptions])

  const [SubmittedIssuePerson, setSubmittedIssuePerson] = useState(false)


  useEffect(() => {
    if (SubmittedIssuePerson) {
      setForIssueValue(EmployeeIssueOptions[0])

      setValue("issue_registered_by_person_code_and_name", EmployeeIssueOptions[0]?.value)

    }

  }, [SubmittedIssuePerson, EmployeeIssueOptions])
  
  const [SubmittedReference, setSubmittedReference] = useState(false)

  useEffect(() => {
    if (SubmittedReference) {
      setForReferenceValue(EmployeeOptions[0])

      setValue("refrence_person_code_and_name", EmployeeOptions[0]?.value)

    }

  }, [SubmittedReference, EmployeeOptions])


  const format = 'HH:mm';


  const [timeLimit, setTimeLimit]: any = useState()

 


  const [forTimeLimit, setForTimeLimit]: any = useState(null)


  const [uploadImage, setUploadImage] = useState<UploadFile[]>([])
  const ImageOnChange = (e: any) => {
    e.file.status = "done"

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    })
    setLogo(tempFiles)
    setUploadImage(e.fileList)

    // setValueAddMaintenace('image',tempFiles)

    

  }

  const [isMaintenanceLocation,setIsMaintenanceLocation] = useState(null)

  const handleChangeMaintenanceLocation=(e:any)=>{
    setValue('maintenance_location',e.target.value)
    setValueAddMaintenace('maintenance_location',e.target.value)
    setIsMaintenanceLocation(e.target.value)
    setErrorMaintenanceLocation(false)
  }


  useEffect(()=>{
    if(isMaintenanceLocation === null || isMaintenanceLocation === undefined || isMaintenanceLocation === ''){
      setAdditionalFields(false)
      setErrorMaintenanceLocation(true)
    }
  },[isMaintenanceLocation])



  const [showImages,setShowImages] = useState(false)

  const [fieldImages,setFieldImages] = useState([])

  const handleShowImages=(field:any)=>{
    setShowImages(true)
    setFieldImages(field.image)
    // setFieldImages(field)
  }



  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? fieldImages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === fieldImages.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };


  const [optionsFault, setOptionsFault]: any = useState();

  const { data: FaultData, refetch: FaultRefetch } = useFetchFaultDropDown();

  useEffect(() => {
    setOptionsFault(FaultData);
  }, [FaultData]);

  const [suggestion, setSuggestions]: any = useState([]);
  const [input, setInput] = useState("");

  const[choosed,setChoosed] = useState(false)

  const handleChange = (e: any) => {
    setChoosed(false)
    let value = e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    let matches = [];
    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = optionsFault?.filter((item: any) => regex.test(item.label));
    }
    setSuggestions(matches);
    setInput(value);
  };
  const [suggestionReason, setSuggestionsReason]: any = useState([]);
  const [inputReason, setInputReason] = useState("");

  const[choosedReason,setChoosedReason] = useState(false)

  const handleChangeReason = (e: any) => {
    setChoosedReason(false)
    let value = e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    let matches = [];
    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = optionsFault?.filter((item: any) => regex.test(item.reason));
    }
    setSuggestionsReason(matches);
    setInputReason(value);
  };


  const apinameForLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
  const { mutate: mutateLocation, data: dataLocation, isError: LocationIsError, isLoading: LocationLoading, isSuccess: LocationSuccess, error: LocationError }: any = useCreate(apinameForLocation)
  const [isLoadingValue, setIsLoadingValue] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')

  const [valueLocation, setValueLocation]: any = useState();
  const [optionsLocation, setOptionsLocation]: any = useState([]);
  const [valueLocationCreatable, setValueLocationCreatable] = useState<Option | null>();

  const { data: LocationData, refetch: LocationRefetch } = useFetchMachineLocationDropDown()

  useEffect(() => {
      setOptionsLocation(LocationData)
  }, [LocationData])


  useEffect(() => {
      if (searchTerm?.length >= 1) {
          LocationRefetch()
      }
  }, [])


  const handleChangeLocation = (e: any) => {


    setValue('maintenance_location', e?.value)
    setValueAddMaintenace('maintenance_location', e?.value)
    setIsMaintenanceLocation(e?.value)
    setErrorMaintenanceLocation(false)

      LocationRefetch()

      // setApiName(apinameForLocation)
      // setDeleteApiName(apinameForLocation)
      // setEditId(e?.value)
      // setEditData(LocationData)
      // setRenderKey(prevKey => prevKey + 1);

      setValueLocation(e)

      setValue("maintenance_location", `${e?.value}`)
      setValueLocationCreatable(null)
  }





  const handleCreateLocation = (inputValue: string) => {

      inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      setIsLoadingValue(true);
      setTimeout(() => {
          const newOption = createOption(inputValue);
          setIsLoadingValue(false);


          mutateLocation({ name: newOption.name, status: newOption.status })
          setOptionsLocation((prev: any) => [...prev, newOption]);
          setValueLocationCreatable(newOption);
      }, 0);


  };

  useEffect(() => {
      (async () => {
          if (LocationSuccess && optionsLocation) {
              await LocationRefetch();
          }
          setValue("maintenance_location", LocationData[0]?.value)
      })();

      return () => {
      };
  }, [LocationSuccess, LocationData]);







  return (
    <>
      <div>
        <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
          <section className="content-header py-1 px-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h4>Add Maintenance</h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Maintenance</Link></li>
                    <li className="breadcrumb-item active">Add Maintenance</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">

                <form ref={focus}
                  onKeyUp={event => onEnterKey(event)}>

                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                  <div className='row'>

                 



                    <div className="col-sm-3 mt-2">
                      <label htmlFor="exampleInputEmail1">Department Name <span style={{ color: 'red' }}>*</span></label>
                      <div className="d-flex row" >
                        <Select
                          {...register("department_name", { required: { value: true, message: "Department Name is required" } })}
                          placeholder={'Department Name'}
                          onChange={handleChangeDepartment}
                          components={customIndicator}
                          value={forDepartmentValue}
                          options={DepartmentOptions}
                          className="dropdown-select"
                        />
                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                          <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                            setOpenDepartment(true)
                          }}><AiOutlinePlus /></Button>
                        </div>
                      </div>
                      <span className='text-red w-100'>{errors?.department_name?.message}</span>
                    </div>




                    <div className="col-sm-3 mt-2">
                      <label htmlFor="exampleInputEmail1">Issue Registered By Person Code & Name <span style={{ color: 'red' }}>*</span></label>
                      <div className="d-flex row" >
                        <Select
                          {...register("issue_registered_by_person_code_and_name", { required: { value: true, message: "Issue Registered Person is required" } })}
                          placeholder={'Issue Registered Person'}
                          onChange={handleChangeIssueReg}
                          components={customIndicator}
                          value={ForIssueValue}
                          options={EmployeeIssueOptions}
                          className="dropdown-select"
                        />
                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                          <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                            setOpenIssuePerson(true)
                          }}><AiOutlinePlus /></Button>
                        </div>
                      </div>
                      <span className='text-red w-100'>{errors?.issue_registered_by_person_code_and_name?.message}</span>
                    </div>



                    <div className="col-sm-3 mt-2">
                      <label htmlFor="exampleInputEmail1">Fault Reference Person Code & Name <span style={{ color: 'red' }}>*</span></label>
                      <div className="d-flex row" >
                        <Select
                          {...register("refrence_person_code_and_name", { required: { value: true, message: "Fault Reference Person is required" } })}
                          placeholder={'Fault Reference Person'}
                          onChange={handleChangeReference}
                          components={customIndicator}
                          value={ForReferenceValue}
                          options={EmployeeOptions}
                          className="dropdown-select"
                        />
                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                          <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                            setOpenReference(true)
                          }}><AiOutlinePlus /></Button>
                        </div>
                      </div>
                      <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                    </div>



                    <div className="col-sm-3 mt-2">

                      <label htmlFor="exampleInputEmail1">Maintenance Location</label>
                      {/* <div className="input-group" >

                        <input type="text" placeholder="Maintenance Location" className="form-control"
                          {...register("maintenance_location", { onChange:handleChangeMaintenanceLocation,required: { value: true, message: "Maintenance Location is required" } })}
                        />

                        <div className="input-group-append">
                          <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                        </div>
                      </div> */}
                       <CreatableSelect
                        isClearable={() => {
                          setValueLocation(null)
                          setValue(null)
                        }}
                        {...register("maintenance_location", { required: { value: true, message: "Material Location is required" } })}
                        placeholder={'Select Material Location'}
                        // menuIsOpen={true}
                        components={customCreatableIndicator}
                        options={optionsLocation}
                        onMenuOpen={() => LocationRefetch()}
                        onCreateOption={handleCreateLocation}
                        onChange={handleChangeLocation}
                        value={valueLocationCreatable == null ? valueLocation : valueLocationCreatable}

                      />
                      <span className='text-red'>{errors?.maintenance_location?.message}</span>

                    </div>




                    <div className="col-sm-3 mt-2">
                      <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                      <select className="form-select" {...register("status",
                        {
                          required: { value: true, message: " Status is required" }, 
                        }
                      )}>
                        <option value="" selected >Select Any</option>
                        <option value={"active"}>Active</option>
                        <option value={"inactive"}>Inactive</option>
                      </select>
                      <span className='text-red w-100'>{errors?.status?.message}</span>
                    </div>















                    <div className="row d-flex">
                      <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                        <div className="row d-flex ">
                          <div className="col-sm-3 mt-2 image-upload">
                            <button
                              type="button" className="border-1 rounded text-white px-4 py-1 mt-2 "
                              style={additionalFields || isMaintenanceLocation === null || isMaintenanceLocation === undefined || isMaintenanceLocation === '' ? { borderColor: "rgb(0, 54, 101)", background: "rgb(117 143 166)", color: "white" } : { borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                              disabled={additionalFields|| isMaintenanceLocation === null || isMaintenanceLocation === undefined || isMaintenanceLocation === ''}
                              onClick={handleAddMaintenance}
                            // onClick={handleSubmit(onSubmit)}
                            >Add Compliant</button>
                          </div>

                        </div>
                      </div>
                    </div>



                    {additionalFields?


                      <>
<form onSubmit={handleSubmitAddMaintenace(onSubmitAddMaintenance)} action="">
                        <div className="row" >


                        <div className="col-md-2  mt-4">
                            <div className="mb-3">
                              <label htmlFor="inputField" className="form-label">Fault Name<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group">
                              <input
                                {...registerAddMaintenance("maintenance_fault", { onChange:handleChange, required: { value: true, message: "Fault Name is required" } })}

                                className="form-control"
                                type="text"
                                placeholder="Fault Name"
                                value={input}
                                // onChange={handleChangeFaultName}
                              />
                             {suggestion?.length > 0 && !choosed? (
                              <div className="suggestion-wrapper">
                                {suggestion.map((item: any) => {
                                  return (
                                    <div
                                      className="suggestions"
                                      key={item.value}
                                      onClick={()=>{
                                        setValueAddMaintenace('maintenance_fault',item.label)
                                        setChoosed(true)
                                        setInput(item.label)
                                      }}
                                    >
                                      {item.label}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                            </div>
                               <span className='text-red'>{errorsAddMaintenace?.maintenance_fault?.message}</span>

                            </div>
                          </div>



                          <div className="col-sm-2 mt-4">
                            <label >Maintenance The Reason<span style={{ color: "red" }}>*</span></label>
                            <CreatableSelect
                              {...registerAddMaintenance("maintenance_issue", { required: { value: true, message: "Maintenance The Reason is required" } })}
                              isClearable={true}
                              placeholder={'Select Maintenance The Reason'}
                              components={customCreatableIndicator}
                              options={optionsMaintenanceIssue}
                              onCreateOption={handleMaintenanceIssueCreate}
                              onChange={handleChangeMaintenanceIssue}
                              value={valueMaintenanceIssueCreatable == null ? valueMaintenanceIssue : valueMaintenanceIssueCreatable}
                            />
                         
                             <span className='text-red'>{errorsAddMaintenace?.maintenance_issue?.message}</span>
                          </div>

                          <div className="col-md-2  mt-4">
                            <div className="mb-3">
                              <label htmlFor="inputField" className="form-label">Reason Detail</label>
                              <div className="input-group">
                              <input
                               {...registerAddMaintenance("reason_detail", { onChange:handleChangeReason, required: { value: false, message: "Reason Detail is required" } })}

                                className="form-control"
                                type="text"
                                placeholder="Reason Detail"
                                // onChange={handleChangeReasonDetail}
                                value={inputReason}
                              />
                               {suggestionReason?.length > 0 && !choosedReason? (
                              <div className="suggestion-wrapper">
                                {suggestionReason.map((item: any) => {
                                  return (
                                    <div
                                      className="suggestions"
                                      key={item.value}
                                      onClick={()=>{
                                        setValueAddMaintenace('reason_detail',item.label)
                                        setChoosedReason(true)
                                        setInputReason(item.reason)
                                      }}
                                    >
                                      {item.reason}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                            </div>
                               <span className='text-red'>{errorsAddMaintenace?.reason_detail?.message}</span>
                            </div>
                          </div>






                          {/* Requirement <br />Date */}
                          <div className="col-md-2  mt-4">
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1">Date Limit<span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group">
                                <DatePicker
                                  {...registerAddMaintenance("maintenance_date_limit", { required: { value: true, message: "Date Limit is required" } })}

                                  className="form-control"
                                  value={dateLimitValue}
                                  format={dateFormatList}
                                  onChange={handleChangeDateLimit}
                                  disabledDate={disabledDate}

                                />
                              </div>
                             
                               <span className='text-red'>{errorsAddMaintenace?.maintenance_date_limit?.message}</span>
                            </div>
                          </div>


                          <div className="col-md-2  mt-4">
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1">Take Time To Complete<span style={{ color: 'red' }}>*</span></label>
                              <TimePicker format={format} className="form-control"
                                {...registerAddMaintenance("maintenance_time_limit", { required: { value: true, message: "Take Time To Complete is required" } })}

                                showNow={false}
                                value={forTimeLimit}
                                onChange={handleChangeTimeLimit} />
                              
                               <span className='text-red'>{errorsAddMaintenace?.maintenance_time_limit?.message}</span>
                            </div>
                          </div>


                          
                          <div className="col-md-2  mt-4 image-upload">
                              <label htmlFor="exampleInputEmail1 " style={{display:'block'}}>Image All </label>
                              <Upload

                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={ImageOnChange}
                                multiple={true}
                                defaultFileList={uploadImage}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}
                              >
                                <img src={cameraLogo} width={70} height={70} alt='' className="uploaded-image" />
                              </Upload>
                              {/* {logo.length === 0 ?<span className='text-red'>{'Image is Required'}</span>:null} */}

                          </div>
                          






<div style={{display:'flex',justifyContent:'center'}}>

                          <div className="col-md-2" style={{ display: "flex", justifyContent: "space-around"}}>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleDeleteProduct}
                              >
                                Cancel
                              </button>


                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleSubmitAddMaintenace(onSubmitAddMaintenance)}
                              >
                                Save
                              </button>


                            </div>


                            </div>


                          </div>
                        </div>

                        </form>

                      </>
                    :null}











                    <div className="table-responsive"  >

                      {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Maintenance</span> : null}

                      {errorMaintenanceLocation ? <span style={{ color: 'blue' }}>Maintenance Location Is Required To Add Maintenance</span> : null}


                      <table key={renderKey} className="table table-striped table-bordered" style={{ marginTop: "3px", width: "-webkit-fill-available" }}>
                        <thead style={{ textAlign: "center" }}>
                          <tr>
                            <th >Sr. <br /> No</th>
                            <th >Maintenance<br />Location</th>
                            <th>Fault<br />Name</th>


                            <th>Maintenance<br />The Reason</th>
                            <th>Reason <br />Detail</th>
                            <th>Date <br />Limit</th>
                            <th>Take Time<br />To Complete</th>
                            <th>Image<br />All</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                          {displayData?.map((field: any, index: any) => (
                            <tr key={index} >
                              <td>{index + 1}</td>


                              <td>
                               
                                {field.maintenance_location?.name}

                              </td>



                              <td>
                             

                                {field.maintenance_fault}

                              </td>

                              <td>
                             

                                {field.maintenance_issue?.name}

                              </td>

                              <td>
                               

                                {field?.reason_detail?field.reason_detail:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

                              </td>
                              <td>
                              

                                {field.maintenance_date_limit}

                              </td>
                              <td>
                              {field.maintenance_time_limit}
                              </td>
                              <td>
                              {!field.image[0]?.doc?
                              <>
                               <img src={noImage} height={50} width={50} />
                              </>
                              :<>
                                <img src={field.image[0]?.doc} onClick={()=>handleShowImages(field)} alt='no image' width={50} height={50}/>
                                </>}
                                {/* {field.image[0].doc} */}
                              </td>

                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteProductInTable(field.id)}
                                >
                                  Delete
                                </button>
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </table>


                    </div>







                  </div>
                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                            onClick={handleSubmit(onSubmit)}
                          >Submit</button>
                          <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaintenance")}>Cancel</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
             

            </div>
          </section>
        </div>

   



      <Modal show={showImages} onHide={() => setShowImages(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    
  </div>
 


   <div className="carousel-inner">
        {fieldImages.map((imgSrc:any, index) => (
          <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
            <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
          </div>
        ))}
      </div>

  <button className="carousel-control-prev" type="button"  onClick={goToPrev} >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button"  onClick={goToNext} >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setShowImages(false)}>OK</button>

            </Modal.Footer>
          </Modal>


        <Department_Model refetchForm={DepartmentRefetch} open={openDepartment} setOpen={setOpenDepartment} setSubmitted={setSubmittedDepartment} />
        <Employee_Model refetchForm={EmployeeRefetch} open={openIssuePerson} setOpen={setOpenIssuePerson} setSubmitted={setSubmittedIssuePerson} />
        <Employee_Model refetchForm={EmployeeRefetch} open={openReference} setOpen={setOpenReference} setSubmitted={setSubmittedReference} />


      </div>
    </>
    
  )
}

export default Add_Maintenance
