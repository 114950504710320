import { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFocusOnEnter from '../../../components/focus';
import { useCreate, useFetchSingle, useUpdateSingle } from '../../../hooks/Api';
import { useForm } from 'react-hook-form';
import { Alert } from '../../../components/Model/Model';
import { Button } from 'antd';
import { Modal } from 'react-bootstrap';
import { Task_Status_Model } from '../../../components/Dynamic_Form_Models/Task_Status_Model';
import { useFetchTaskDropDown, useFetchTaskStatusDropDown } from '../../../hooks/DropDown_Api';
import DeleteColumn from '../../../components/Cell/Delete Column/DeleteColumn';
import { AiOutlinePlus } from 'react-icons/ai';
import { Task_Model } from '../../../components/Dynamic_Form_Models/Task_Model';
import Task from './Task';
import { Tooltip } from '@mui/material';
import Axiosinstance from '../../../hooks/Interceptor';
import Mui_Toaster from '../../../components/Mui_Toaster/Mui_Toaster';
// import { Select, Space } from 'antd';
import Select, { components } from 'react-select';

const CustomMultiValue = (props: any) => {
    const { index, getValue } = props;
    const maxToShow = 1; // Maximum number of tags to show
    const overflowCounter = getValue().length - maxToShow;

    if (index < maxToShow) {
        return <components.MultiValue {...props} />;
    }

    if (index === maxToShow) {
        return (
            <components.MultiValue {...props}>
                <span>{`+${overflowCounter} more`}</span>
            </components.MultiValue>
        );
    }

    return null;
};

const CustomMultiValueRemove = () => null;

const Task_Board = () => {
    let pageName = 'Task Board'

    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate();

    const [openModel, setOpenModel] = useState(false)
    const [openModelTask, setOpenModelTask] = useState(false)

    const apiname = process.env.REACT_APP_HRMS_PROJECT_API?.toString();
    const { id } = useParams()
    const [currentId, setCurrentId]: any = useState()

    const { mutate: gst, data, isSuccess }: any = useCreate(apiname);
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    const [optionsTaskStatus, setOptionsTaskStatus]: any = useState();
    const [Updated, setUpdated] = useState(false)

    const { data: TaskStatusData, refetch: TaskStatusRefetch } = useFetchTaskStatusDropDown(id);

    useEffect(() => {
        const Project_Id = TaskStatusData?.filter((e: any) => id == e?.project)
        const final_Data = Project_Id?.reverse()

        setOptionsTaskStatus(final_Data);
    }, [TaskStatusData]);

    const [optionsTask, setOptionsTask]: any = useState();

    const { data: TaskData, refetch: TaskRefetch } = useFetchTaskDropDown();

    useEffect(() => {
        const Task_Id = TaskData?.filter((e: any) => id == e?.project)
        setOptionsTask(Task_Id);
    }, [TaskData]);

    const [btnId, setBtnId]: any = useState();

    const onDelete = async (e: any) => {
        setBtnId(e)

        await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_TASK_API}/${e}/?force=true`).then((res: any) => TaskRefetch())

    };

    const apinameTask = process.env.REACT_APP_HRMS_TASK_API?.toString();
    const { mutate: TaskMutate, isSuccess: TaskisSuccess, isError, error }: any = useUpdateSingle();

    const handleDragEnd = (result: any) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        if (type === "column") {
        } else {
            const sourceColumn = optionsTaskStatus?.find(
                (column: any) => "column" + column.value === source.droppableId
            );
            const destinationColumn = optionsTaskStatus?.find(
                (column: any) => "column" + column.value === destination.droppableId
            );

            if (sourceColumn === destinationColumn) {
                const newTask = Array?.from(sourceColumn?.value);
                sourceColumn.task?.map((task: any) => {
                    if ("task" + task.value === draggableId) {
                        newTask?.splice(source.index, 1);
                        newTask?.splice(destination.index, 0, task);
                    }
                });
                const newColumn = {
                    ...sourceColumn,
                    task: newTask,
                };

                const newColumns = optionsTaskStatus?.map((column: any) => {
                    if (column.value === newColumn.value) {
                        return newColumn;
                    }
                    return column;
                });
                setOptionsTaskStatus(newColumns);
            } else {

                const sourceTask = Array?.from(sourceColumn?.value);
                sourceTask?.splice(source?.index, 1);

                const newSourceColumn = {
                    ...sourceColumn,
                    task: sourceTask,
                };

                const destinationTask = Array?.from(destinationColumn?.value);
                sourceColumn.task?.map((task: any) => {
                    if ("task" + task.value === draggableId) {
                        destinationTask?.splice(destination.index, 0, task);
                    }
                });

                const newDestinationColumn = {
                    ...destinationColumn,
                    task: destinationTask,
                };

                const newColumns = optionsTaskStatus?.map((column: any) => {
                    if (column.value === newSourceColumn?.value) {
                        return newSourceColumn;
                    } else if (column.value === newDestinationColumn.value) {
                        return newDestinationColumn;
                    } else {
                        return column;
                    }
                });
                setOptionsTaskStatus(newColumns);
            }

            const statusId = draggableId.split("task")[1];
            const taskStatusId = destination.droppableId.split("column")[1];
            // make json data
            const sendData = {
                task_status: taskStatusId,
            };
            let data = {
                apiname: apinameTask,
                id: statusId,
                data: sendData
            }
            TaskMutate(data);
        }
    };
    useEffect(() => {
        if (TaskisSuccess || Updated) {
            TaskStatusRefetch()
            TaskRefetch()

        }
    }, [TaskisSuccess, Updated])

    const [show, setShow] = useState(false);
    const [showmessage, setshowmessage] = useState("");

    useEffect(() => {
        if (TaskisSuccess) {
            setShow(true);
            setshowmessage("success");
            ({ state: { showEditToast: true } });
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, error]);

    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);

    const [submitted, setSubmitted] = useState(false)


    useEffect(() => {
        if (submitted) {
            // setInput(optionsTaskStatus[0])

            setValue("machine_department", optionsTaskStatus[0]?.value)

        }

    }, [submitted, optionsTaskStatus])


    const [selectedStatus, setSelectedStatus]: any = useState([]);

    useEffect(() => {
        if (optionsTaskStatus) {
            const defaultStatus = optionsTaskStatus?.filter((data: any) => {
                if (data?.label === 'In Progress' || data?.label === 'Incomplete' || data?.label === 'Completed') {
                    return { value: data.value, label: data.label }
                }
            });
            setSelectedStatus(defaultStatus);
        }
    }, [optionsTaskStatus]);

    const handleChange = (options: any) => {


        let option = options?.filter((option: any) => {
            if (Object.keys(option).length !== 0) {
                return { value: option.value, label: option.label }
            }
        })

        setSelectedStatus((prev: any) => {
            let newOptions = option?.filter((data: any) => {
                if (Object.keys(data).length !== 0 && options.includes(data)) {
                    return { value: data.value, label: data.label }
                }
            })

            // let filterPrevOption = prev?.filter((data: any) => {
            //     if (data?.label === 'In Progress' || data?.label === 'Incomplete' || data?.label === 'Complete') {
            //         return { value: data.value, label: data.label }
            //     }
            // })

            const updatedData = [...newOptions];
            return updatedData;
        });

    };



    return (
        <div style={{ height: "97%" }}>
            <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-sm-6">
                            </div> */}
                            <div className="col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/listHrmsTask" className="text-decoration-none text-black">{pageName}</Link>
                                    </li>
                                    <li className="breadcrumb-item">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default" >
                            <div className="card-body" >
                                {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div  style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                <div>

                                                    <h4>{pageName}</h4>
                                                </div>

                                                <div className='d-flex ms-5' style={{ width: '30%',justifyContent:'end' }}>

                                                    <Select
                                                        className='mx-2'
                                                        isMulti
                                                        placeholder="Please select"
                                                        onChange={handleChange}
                                                        value={selectedStatus}
                                                        options={optionsTaskStatus}
                                                        hideSelectedOptions={false}
                                                        components={{ MultiValue: CustomMultiValue, MultiValueRemove: CustomMultiValueRemove }}

                                                    />
                                                    <Button type="primary" style={{ backgroundColor: 'rgb(0, 54, 101)' }} onClick={() => setOpenModel(true)}>Add Status</Button>
                                                </div>


                                            </div>
                                            <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                                                {selectedStatus?.length > 0 ? (
                                                    <div className=' overflow-x-scroll' style={{ display: 'flex', height: "100%" }}>
                                                        <DragDropContext onDragEnd={handleDragEnd}>
                                                            {selectedStatus?.map((column: any, columnIndex: any) => (
                                                                <div
                                                                    key={column.value}
                                                                    className='p-3'
                                                                    style={{ minWidth: "400px", height: "40vw" }}>
                                                                    <div
                                                                        className='new-card shadow p-3 overflow-y-auto scrollbar-hide'
                                                                        style={{ height: "100%", overflow: 'auto' }}>
                                                                        {/* <h2 className='text-lg font-medium '>{column.label}</h2> */}
                                                                        <Droppable
                                                                            droppableId={"column" + column.value}
                                                                            key={"column" + column.value}>
                                                                            {(provided, snapshot) => (

                                                                                <ul
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.droppableProps}
                                                                                    className='space-y-2 '
                                                                                    style={{ height: "90%", listStyleType: 'none' }} >
                                                                                    <div className=' mb-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <h2 className='text-lg font-semibold txt-color-2 capitalize'>
                                                                                            {column.label}
                                                                                        </h2>
                                                                                        <div >
                                                                                            <Tooltip title="Add Task" placement="top">
                                                                                                <Button type="link" onClick={() => {
                                                                                                    setOpenModelTask(true)
                                                                                                    setCurrentId(column.value)
                                                                                                }}><AiOutlinePlus /></Button>
                                                                                            </Tooltip>
                                                                                            {column.label === 'In Progress' || column.label === 'Incomplete' || column.label === 'Completed' ?
                                                                                                    ''
                                                                                                    :
                                                                                            <>
                                                                                              < Tooltip title={`Delete ${column.label}`} placement="top">
                                                                                                <button style={{ background: 'none', border: 'none' }}>

                                                                                                    <DeleteColumn
                                                                                                        id={column.value}
                                                                                                        projectId={id}
                                                                                                        Refetch={TaskStatusRefetch}
                                                                                                    />
                                                                                                </button>
                                                                                              </Tooltip>
                                                                                            </>  
                                                                                            }   
                                                                                    </div>
                                                                                </div>
                                                                                    {optionsTask?.map((innerTask: any, index: any) => {

                                                                                    if (column?.value === innerTask?.task_status) {
                                                                                        return (
                                                                                            <Draggable
                                                                                                key={"task" + innerTask?.value}
                                                                                                draggableId={"task" + innerTask?.value}
                                                                                                index={index}>
                                                                                                {(provided: any, snapshot: any) => (
                                                                                                    <li
                                                                                                        ref={provided?.innerRef}
                                                                                                        {...provided?.draggableProps}
                                                                                                        {...provided?.dragHandleProps}
                                                                                                        className='rounded-lg px-1 py-1 cursor-move flex justify-between items-center'>
                                                                                                        <Task
                                                                                                            taskS={innerTask}
                                                                                                            // btnLoading={isLoading}
                                                                                                            btnId={btnId}
                                                                                                            handleDeleteTask={onDelete}
                                                                                                        />
                                                                                                    </li>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    }

                                                                                })}
                                                                            {provided.placeholder}

                                                                        </ul>
                                                                            )}
                                                                    </Droppable>
                                                                </div>
                                                                </div>
                                                ))}
                                            </DragDropContext>
                                        </div>
                                        ) : (
                                        <div className='d-flex justify-center items-center h-96'>
                                            <div>
                                                <h4 className='text-red font-semibold ' >
                                                    No Task Found
                                                </h4>
                                            </div>
                                        </div>
                                                )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
            </div>
        </div>
                </section >
            </div >
            <Task_Status_Model refetchForm={TaskStatusRefetch} open={openModel} setOpen={setOpenModel} id={id} />
            <Task_Model refetchForm={TaskRefetch} open={openModelTask} setOpen={setOpenModelTask} id={id} currentId={currentId} />
{
    TaskisSuccess && (
        <Mui_Toaster
            message={`Updated Successfully`}
            severity={"success"}
            Isopen={true}
        />
    )
}
        </div >
    )
}

export default Task_Board