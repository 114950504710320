import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_TFM_Transaction, titleofheaders } from "./Columns_TFM_Transaction"
import Table from "../../../components/Dynamic_Table/Table/Table";
import DatePicker from 'antd/es/date-picker';
import Accordion from "react-bootstrap/esm/Accordion";
import { useCreate } from "../../../hooks/Api";
import { useForm } from "react-hook-form";

const List_TFM_Transaction = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Production";

  const Breadcrumb_Title_Name = "TFM Transaction";
  let todayDate = new Date().toISOString().split("T")[0]
  // let isoDate = "2024-01-31"; // Replace with any ISO format date
  let [year, month, day] = todayDate.split("-");
  let formattedDate = `${day}-${month}-${year}`;
  const apiname = `${process.env.REACT_APP_PRODUCTION_TFM_TRANSACTION_API}`?.toString();

  const apinameGetData = `${process.env.REACT_APP_PRODUCTION_PRODUCTION_LINE_API}/search_filter`?.toString()
  const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apinameGetData)

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();
  const [renderTable, setRenderTable] = useState(0)
  const [GranualReportData, setGranualReportData]: any = useState([])

  const dateFormatList = ['DD-MM-YYYY'];
  const [forDisableDate, setForDisableDate]: any = useState()
  const [startDate, setStartDate]: any = useState()
  const [forPointStartDateValue, setForPointStartDateValue]: any = useState()

  const [endDate, setEndDate]: any = useState()
  const [forPointEndDateValue, setForPointEndDateValue]: any = useState()


  const handleStartDate = (e: any) => {
    setForDisableDate(e)
    setStartDate(`${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("start_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)

    setForPointStartDateValue(e)

  }

  const handleEndDate = (e: any) => {
    setEndDate(`${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("end_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setForPointEndDateValue(e)

  }



  const onSubmit = (data: any) => {

    let tempData = {

      start_date: startDate,
      end_date: endDate,

    }

    mutate(tempData);
  }

  const [resetData, setResetData] = useState(false)
  const handleReset = () => {
    reset()

    setStartDate(null)
    setForPointStartDateValue(null)
    setEndDate(null)
    setForPointEndDateValue(null)

    setGranualReportData([])



    setResetData(false)
    setRenderTable(renderTable + 1)
  }

  useEffect(() => {
    if (isSuccess) {
      setGranualReportData(data?.results)
      setResetData(true)
      setRenderTable(renderTable + 1)
    }
  }, [data, isSuccess])


  const filter = {
    // date: formattedDate,
    // selection: "",
    // code: "",
    // sheet: "",
    // material_garde: "",
    // sheet_color: "",
    // opretar: "",
    // helper: "",
    // shift_time: "",
    // machine: "",
    // raw_material_betch_total_kg: "",

    status: "",
    created_by: "",
    created_at: "",
    // updated_by: "",

  };

  const addButtonName = "TFM Transaction";
  const addButtonPageName = "TFMTransaction"
  const permissionClass = {
    front: "mould",
    back: "mouldshape"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <div className="">
                  <Accordion flush >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Search Filter</Accordion.Header>
                      <Accordion.Body>
                        <form>
                          <div className="row d-flex">


                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Start Date </label>
                              <DatePicker
                                {...register("start_date")}
                                className="form-control"
                                format={dateFormatList}
                                onChange={handleStartDate}
                                value={forPointStartDateValue}
                              />
                              <span className='text-red w-100'>{errors?.start_date?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="manualcode_2">End Date</label>

                              <DatePicker
                                {...register("end_date")}
                                className="form-control"
                                format={dateFormatList}
                                value={forPointEndDateValue}
                                onChange={handleEndDate}
                              />

                              <span className='text-red w-100'>{errors?.end_date?.message}</span>

                            </div>


                            <div className="col-sm-3 mt-2" >
                              <label htmlFor="manualcode_2"></label>
                              <div className="d-flex row" >
                                <button
                                  type="button" className="border-1 rounded text-white px-1 py-1 mt-2 mx-1 "
                                  style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", width: '30%' }}
                                  onClick={handleSubmit(onSubmit)} >Get Data</button>
                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-1" style={{ borderColor: "blue", background: "blue", color: "white", width: '30%' }} onClick={handleReset}>Reset</button>
                              </div>
                            </div>
                          </div>
                          
                        </form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div> */}


                <Table
                  key={renderTable}
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_TFM_Transaction}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                  // GranualReportData={GranualReportData}
                  resetData={resetData}

                />
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  );
};

export default List_TFM_Transaction;
