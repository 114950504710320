import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchCavityNameDropDown, useFetchCavityShapeDropDown, useFetchDisposableDropDown, useFetchMouldDropDown, useFetchMouldShapeDropDown, useFetchProductNameDropDown } from "../../hooks/DropDown_Api";
import CreatableSelect from 'react-select/creatable';
import { Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});




const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')


export const Cavity_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    // const [logo, setlogo] = useState(null)
    // const [logoPdf, setlogoPdf] = useState(null)
    const [logo, setlogo] = useState([])
    const [logoPdf, setlogoPdf] = useState([])
    const onLogoChange = (e:any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e:any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { register:register, handleSubmit:handleSubmit, formState: { errors },reset,setValue}: any = useForm({
        mode: "onChange"
    });
    const apiname = process.env.REACT_APP_CAVITY_API?.toString()


    const  { mutate: brandPost, data,isSuccess,error,isError}:any = useCreate(apiname)

    const [options, setOptions]: any = useState([])
    const [optionsProduct, setOptionsProduct]: any = useState([])



    const [optionsCavity, setOptionsCavity]: any = useState([]);

    const [valueCavity, setValueCavity]: any = useState();
    const [searchTerm, setSearchTerm] = useState("")
    const [isLoadingValue, setIsLoadingValue] = useState(false);


    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();



    const apinameForCavity = process.env.REACT_APP_CAVITY_NAME_API?.toString()

    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)





    
  

    const { data: cavityNameData, refetch: cavityNameRefetch } = useFetchCavityNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavity(cavityNameData)
    }, [cavityNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            cavityNameRefetch()
        }
    }, [])


    const handleChangeCavityName = (e: any) => {
        setValue("name", `${e?.value}`)
        setValueCavityCreatable(null)
    }

    const handleCreate = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavity({ name: newOption.name, status: newOption.status })


            setOptionsCavity((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsCavity) {
                await cavityNameRefetch();
                setValue("name", cavityNameData[0].value)
            }
        })();

        return () => {
        };
    }, [cavitySuccess, cavityNameData]);

    const [valueProduct, setValueProduct]: any = useState();
    const [valueProductCreatable, setValueProductCreatable] = useState<Option | null>();
    const apinameForProduct = process.env.REACT_APP_PRODUCT_NAME_API?.toString()

    const { mutate: mutateProduct, data: dataProduct, isError: ProductIsError, isLoading: ProductLoading, isSuccess: ProductSuccess, error: ProductError }: any = useCreate(apinameForProduct)
    const { data: productNameData, refetch: productNameRefetch } = useFetchProductNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsProduct(productNameData)
    }, [productNameData])


    const handleChangeProduct = (e: any) => {
        setValueProduct(e)
        setValue("product_name", `${e?.value}`)
        setValueProductCreatable(null)
    }

    const handleCreateProduct = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateProduct({ name: newOption.name, status: newOption.status })


            setOptionsProduct((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueProductCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (ProductSuccess && optionsProduct) {
                await productNameRefetch();
                setValue("product_name", productNameData[0].value)
            }
        })();

        return () => {
        };
    }, [ProductSuccess, optionsProduct]);


    const [optionsCavityShape, setOptionsCavityShape]: any = useState([]);

    const [valueCavityShape, setValueCavityShape]: any = useState();


    const [valueCavityShapeCreatable, setValueCavityShapeCreatable] = useState<Option | null>();



    const apinameForCavityShape = process.env.REACT_APP_MOULD_SHAPE_API?.toString()

    const { mutate: mutateCavityShape, data: dataCavityShape, isError: cavityShapeIsError, isLoading: cavityShapeLoading, isSuccess: cavityShapeSuccess, error: cavityShapeError }: any = useCreate(apinameForCavityShape)

    const { data: CavityShapeData, refetch: CavityShapeRefetch } = useFetchMouldShapeDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavityShape(CavityShapeData)
    }, [CavityShapeData])

    const handleChangeCavityShape = (e: any) => {
        setValueCavityShape(e)
        setValue("cavity_shape", `${e?.value}`)
        setValueCavityShapeCreatable(null)
    }


    const handleCreateCavityShape = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavityShape({ name: newOption.name, status: newOption.status })


            setOptionsCavityShape((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityShapeCreatable(newOption);


        }, 0);

    };

    useEffect(() => {
        (async () => {
            if (cavityShapeSuccess && optionsCavityShape) {
                await CavityShapeRefetch();
                setValue("cavity_shape", CavityShapeData[0].value)
            }
        })();

        return () => {
        };
    }, [cavityShapeSuccess, optionsCavityShape]);


    const { data: colorData, refetch: colorRefetch } = useFetchMouldDropDown()

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])

    const handleChange = (e: any) => {
        setMouldUsageValue(e)
        setValue("mould_usage", `${e?.value}`)
        setMouldUsageValue({ label: e?.label, value: e?.value })

    }


    // const [cavityNameValue,setCavityNameValue]:any = useState()
    const [mouldUsageValue, setMouldUsageValue]: any = useState()




    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
    const [forGST,setForGSTValue]:any = useState([])

 
const handleReset = () => {
    reset()
    setForGSTValue(null)
    setProductDrawing([])
    setUploadFileDoc([])
    setUploadProductDrawing([])
    }

    const onSubmit = (branddetail: any) => {
        if(logo.length == 0){
            delete branddetail.image
        }
        if(logoPdf.length == 0){
            delete branddetail.pdf
        }
        if(productImage.length == 0){
            delete branddetail.product_image
        }
        if(productDrawing.length == 0){
            delete branddetail.product_pdf
        }
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "image") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('image', logo[i])
                }
                continue
            }
            if (key === "pdf") {
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdf', logoPdf[i])
                }
                continue
            }
            if (key === "product_image") {
                for (let i = 0; i < productImage.length; i++) {

                    formData.append('product_image', productImage[i])
                }
                continue
            }
            if (key === "product_pdf") {
                for (let i = 0; i < productDrawing.length; i++) {

                    formData.append('product_pdf', productDrawing[i])
                }
                continue
            }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }


            if (key === 'height') {
                let decimalNumber = Number(valueHeight);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('height', formattedString)
            }
            if (key === 'cavity_size_mm') {
                let decimalNumber = Number(valueSize);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_size_mm', formattedString)
            }
            if (key === 'cavity_length_mm') {
                let decimalNumber = Number(valueLength);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_length_mm', formattedString)
            }
            if (key === 'cavity_width_mm') {
                let decimalNumber = Number(valueWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_width_mm', formattedString)
            }
            // else if (key === "logo" || key === "logo_pdf"){
            //     if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            //         formData.append(key, formvalue[0])
            //     }
            //     else {
            //         formData.append(key, "")
            //     }
            // }

            


        }
        brandPost(formData);
       
    }
    



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    // const [optionsGST, setOptionsGST] = useState([])

    // const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown()

    // useEffect(() => {
    //     setOptionsGST(brandData)
    // }, [brandData])

    // const handleChange = (e: any) => {
    //     setValue("gst",`${e.value}`)
    //     setForGSTValue({label:e?.label,value:e?.value})

    // }  






    // const [color, setColor]: any = useState({ hex: "#ffffff" });
    // const [colorname, setColorname]: any = useState("");
    // const [colorName, setColorName] = useState("");
    // const [cn, setCn] = useState("")
    // const [displayColorPicker, setdisplayColorPicker] = useState(false);

    // useEffect(() => {
    //     setColorName(ntc(color.hex).colorName);
    // }, [color]);


    // const handleClick = () => {
    //     setdisplayColorPicker(!displayColorPicker)
    // };

    // const handleClose = () => {
    //     setdisplayColorPicker(false)
    // };

    // const style = {
    //     popover: {
    //         position: 'absolute',
    //         zIndex: '2',
    //     },
    //     cover: {
    //         position: 'fixed',
    //         top: '0px',
    //         right: '0px',
    //         bottom: '0px',
    //         left: '0px',
    //     }
    // }


    // const onColorPickerInfoChange = (color: any) => {


    //     if (color.target.value.includes('#')) {
    //         // setColorname(ntc(color.target.value).colorName);
    //         setValue("name", ntc(color.target.value).colorName)
    //         setValue("hex", color.target.value)
    //         setCn(ntc(color.target.value).closestColor)
    //     }

    //     if (color.target.placeholder.includes("Name")) {
    //         // setColorname(color.target.value);
    //         if (toHex(color.target.value)) {
    //             // setColorname(toHex(color.target.value))
    //             setValue("hex", toHex(color.target.value))
    //             setCn(toHex(color.target.value))
    //         }
    //         else {
    //             color.target.value = color.target.value.toLowerCase()
    //     .split(' ')
    //     .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    //     .join(' ');
    //             setColorname("")
    //         }
    //     }
    //     if (color.target.placeholder.includes("Code")) {
    //         setCn(ntc(color.target.value).closestColor)
    //         setValue("hex", color.target.value)
    //     }

    //     // setError(!errors)
    // };


    const [valueSize, setValueSize] = useState('')
    const [valueHeight, setValueHeight] = useState('')
    const [valueLength, setValueLength] = useState('')
    const [valueWidth, setValueWidth] = useState('')

    const [productImage, setProductImage] = useState([])
    const [productDrawing, setProductDrawing] = useState([])

    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc] = useState<UploadFile[]>([])

    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])
    const [uploadProductDrawing, setUploadProductDrawing] = useState<UploadFile[]>([])

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const ProductOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const ProductDrawingOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductDrawing(tempFiles)
        setUploadProductDrawing(e.fileList)

    }

  


    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>Cavity</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form 
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                               
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("name", { required: { value: true, message: "Cavity Name is required" } })}
                                                        isClearable={() => {
                                                            setValueCavity(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Name'}
                                                        options={optionsCavity}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeCavityName}
                                                        components={customCreatableIndicator}
                                                        value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}
                                                        maxMenuHeight={130}


                                                    />
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProduct(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_name", { required: { value: true, message: "Product Name is required" } })}
                                                        placeholder={'Select Product Name'}
                                                        options={optionsProduct}
                                                        onCreateOption={handleCreateProduct}
                                                        onChange={handleChangeProduct}
                                                        value={valueProductCreatable == null ? valueProduct : valueProductCreatable}
                                                        components={customCreatableIndicator}
                                                        maxMenuHeight={130}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name & Type</label>
                                                    <Select
                                                        {...register("", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={}
                                                        onChange={}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Qty</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>

                                                <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Single Code</label>
                                                    <Select
                                                        {...register("mould_usage", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={options}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div> 
                                                
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Single Code</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>
                                                
                                                */}


                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Mould Code & Diameter & Cavity Qty <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select

                                                            {...register("mould_usage", { onChange: handleUpper, required: { value: true, message: "Mould Usage is required" } })}
                                                            placeholder={'Select Mould Usage'}
                                                            options={options}
                                                            onChange={handleChange}
                                                            value={mouldUsageValue}
                                                            components={customIndicator}
                                                            // className="dropdown-select"
                                                            maxMenuHeight={130}

                                                        />
                                                        {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Stock Pcs <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Stock Pcs" {...register("cavity_stock_pcs", { required: { value: true, message: "Cavity Stock Pcs is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_stock_pcs?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Shapes <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("cavity_shape", { required: { value: true, message: "Cavity Shapes is required" } })}
                                                        isClearable={() => {
                                                            setValueCavityShape(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Shapes'}
                                                        options={optionsCavityShape}
                                                        onCreateOption={handleCreateCavityShape}
                                                        onChange={handleChangeCavityShape}
                                                        components={customCreatableIndicator}
                                                        value={valueCavityShapeCreatable == null ? valueCavityShape : valueCavityShapeCreatable}
                                                        maxMenuHeight={130}

                                                    />
                                                    <span className='text-red w-100'>{errors?.cavity_shape?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Cavity Size MM </label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Size MM" 
                                                                {...register("cavity_size_mm", { onChange: ((e: any) => setValueSize(e.target.value)), required: { value: valueCavityShape?.mouldA === "false"? true : false, message: "Cavity Size MM is required" } })} 
                                                                disabled={(valueCavityShape?.mouldA ? false: true)}
                                                                />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.cavity_size_mm?.message}</span>
                                                </div>

                                                




                                                {/* {(valueCavityShape?.mouldC ? <> */}
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Cavity Width MM </label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Width MM"
                                                            disabled={(valueCavityShape?.mouldC ? false: true)}
                                                            {...register("cavity_width_mm", { onChange: ((e: any) => setValueWidth(e.target.value)), required: { value: valueCavityShape?.mouldC === "false"? true : false, message: "Cavity Width MM is required" } })} />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.cavity_width_mm?.message}</span>
                                                    </div>  
                                                {/* </>
                                                    : "")} */}

                                                {/* {(valueCavityShape?.mouldB ? <> */}
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Cavity Length MM </label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="number" className="form-control" id="exampleInputBrand" 
                                                            disabled={(valueCavityShape?.mouldB ? false: true)} placeholder="Cavity Length MM" 
                                                            {...register("cavity_length_mm", { onChange: ((e: any) => setValueLength(e.target.value)), required: { value: valueCavityShape?.mouldB === "false"? true : false, message: "Cavity Length MM is required" } })} />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.cavity_length_mm?.message}</span>
                                                    </div>
                                                {/* </>
                                                    : "")} */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Height MM <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height MM" {...register("height", { onChange: ((e: any) => setValueHeight(e.target.value)), required: { value: true, message: "Cavity Height is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Type <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("type", { required: { value: true, message: "Cavity Type is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="special" >Special</option>
                                                        <option value="running">Running</option>
                                                        <option value="full_running">Full Running</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity Image</label>
                                                    <Upload
                                                        {...register("image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={FileOnChange}
                                                        multiple={true}
                                                        fileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity PDF</label>
                                                    <Upload
                                                        {...register("pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        fileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image</label>
                                                    <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF</label>
                                                    <Upload
                                                        {...register("product_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ProductDrawingOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductDrawing}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



