import React, { useEffect, useState, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import { RiContactsBookUploadLine, RiPencilLine } from "react-icons/ri";
import Select from "react-select"
import axios from 'axios'
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Gst_Model } from "../../components/Dynamic_Form_Models/Gst_Model";
import { useFetchGSTDropDown } from "../../hooks/DropDown_Api";

const Edit_Material_Density = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_MATERIAL_DENSITY_API?.toString()
    const { data } = useFetchSingle(id,apiname)
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [openEditModel,setOpenEditModel] = useState(false)
    const [expirationDate,setExpirationDate] = useState()
    const [confirmmodal,setConfirmmodal]=useState(false)
    const [valuesofform,setValuesofform]=useState<any>()


    const [valueDensity,setValueDensity] = useState('')

    const { mutate, isSuccess, isError, error, data: materialUpdated_response }: any = useUpdateSingle()
    const { mutate:mutateyes, isSuccess:yessuccess, isError:yeserror, error:yeserrordetail, data: yesupdated_response }:any = useUpdateSingleforcewithyes()
    const { mutate:mutateno, isSuccess:nosuccess, isError:noerror, error:noerrordetail, data: noupdated_response }:any = useUpdateSingleforcewithno()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, control ,getValues}:any = useForm();



    // const fetchOptions = async () => {
    //     try {
    //         setLoading(true)
    //         const apicall = await axios.get(`${process.env.REACT_APP_BASE_URL}gst/fetch_all/`, {
    //             headers: {
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`
    //             }
    //         })

    //         const data = apicall.data.results.map((option: any) => ({
    //             label: option.india_GST,
    //             value: option.id
    //         }))
    //         setOptions(data)
    //     } catch (error) {
    //     } finally {
    //         setLoading(false)
    //     }
    // }
    const [optionsGST, setOptionsGST]: any = useState([])

    const [selected, setSelected]: any = useState('');
    const [sel, setSel]:any= useState('');
    const [forGST, setForGSTValue]: any = useState([])
    const { data: gstData, refetch: gstRefetch } = useFetchGSTDropDown()

    useEffect(() => {
        setOptionsGST(gstData)
    }, [gstData])

    const handleChange = (e: any) => {
        
        setSel(e)
        setValue("gst",e.value)
        
        
        setForGSTValue({ label: e?.label, value: e?.value })
    }
    

    useEffect(() => {
        if (data) {
            
            reset(data)
            setSelected(data?.gst?.id);
            setValue("gst",data?.gst?.id)
            setValueDensity(data?.material_density)
        }
    }, [data]);

    const onSubmit = (materialbranddetail: any) => {
        // materialbranddetail.gst = materialbranddetail.gst.id
        const formData = new FormData();
        // delete materialbranddetail.gst
        for (const [key, value] of Object.entries(materialbranddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

            if(valueDensity && key === 'material_density'){
                let decimalNumber = Number(valueDensity); 
                const formattedString = decimalNumber.toFixed(3);
                formData.append('material_density', formattedString)
            }
           
            
        }
        let data = {
            apiname: apiname,
            id:id,
            data: formData
        }
        mutate(data);
        setValuesofform(data);
       
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess||yessuccess||nosuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listMaterialDensity', { state: { showEditToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, isSuccess,yessuccess,
        nosuccess,materialUpdated_response])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);





    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }




    useEffect(()=>{
        if(isError)
        {
            if(error.response.status===405){
                setConfirmmodal(true)
            }
        }
        else if (isError||yeserror||noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    },[isError,yeserror,noerror])


    const yesupdatemodal=()=>{
    setConfirmmodal(false);
    mutateyes(valuesofform)
    }
    const cancelupdatemodal=()=>{
       setConfirmmodal(false) ;
    }



    const noupdatemodal=()=>{
        setOpenEditModel(true)
      }


      const handleExpirationDate=(e:any)=>{
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
}

const handleConfirm=(data:any)=>{
         
   
    let ok = {
        id:id,
        apiname:apiname,
        data:data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
}




const handleDensity = (e:any) => {
  // let decimalNumber = Number(e.target.value); 
  // const formattedString = decimalNumber.toFixed(3); 
  // setValue("material_density",formattedString)

  setValueDensity(e.target.value)
//   setValue("material_density",e.target.value)
};

const [submitted, setSubmitted] = useState(false)

useEffect(() => {
    if (submitted) {
        // setForGSTValue(optionsGST[0])
        setSel(optionsGST[0])
        setValue("gst",optionsGST[0].value)
        // setValue("gst", optionsGST[0].value)

    }

}, [submitted, optionsGST])


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Material Density</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Purchase</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listMaterialDensity" className="text-decoration-none text-black">Material Density</Link></li>
                                    <li className="breadcrumb-item active">Edit Material Density</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            
                        <form ref={focus} onKeyUp={event => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Material Type Name" {...register("material_type_name",{onChange:handleValue})} />
                                                    <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Material Density"  {...register("material_density",{onChange:handleDensity, required: { value: false, message: "Material Density is required" }})} />
                                                        <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Name<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Grade Name"  {...register("use_grade_name",{onChange:handleValue})} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Quantity<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Grade Quantity"  {...register("use_grade_qty",{onChange:handleValue})} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Raw Material Name<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Raw Material Name"  {...register("use_raw_material_name",{onChange:handleValue})} />
                                                    </div>
                                                </div> */}
                                               
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Sutra</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Grade Sutra"  {...register("grade_sutra",{onChange:handleValue})} />
                                                        <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">GST % <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                    <Select
                                                     {...register("gst")}
                                                    components={{ IndicatorsContainer }}
                                                    placeholder={'Select Brand Name'}
                                                    
                                                    options={optionsGST}
                                                   
                                                    onChange={handleChange}
                                                    value={sel === "" ? optionsGST?.filter((obj: any) => obj.value === selected) : sel}
                                                        // onInputChange={handleInputChange}
                                                        className="dropdown-select"
                                                    />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                        </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Polymer Name </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Polymer Name"  {...register("polymer_name",{onChange:handleValue})} />
                                                        <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)} 
                                                // disabled={!isDirty}
                                                >Submit</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaterialDensity")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}

            <Gst_Model refetchForm={gstRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            
           
            
          

<div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                                    <input type="date" className="form-control" id="exampleInputBrand" 
                                                    
                                                    {...register("expiration_date",{onChange:handleExpirationDate})} 
                                                    />
                                                </div>
                                                </div>
           </div>
            
          
            
        </Modal.Body>
        <Modal.Footer>
        

          
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

        </Modal.Footer>
      </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
yesupdatemodal={yesupdatemodal}
cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )


}

export default Edit_Material_Density