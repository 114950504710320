import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import Edit_Employee from "../../Employee/Edit_Employee";
import Edit_Basic_Customer_Details from "../Basic Customer Detail/Edit_Basic_Customer_Detail";
import Axiosinstance from "../../../hooks/Interceptor";
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Edit_Basic_Details = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Customer Grade"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()

    const [data, setData]: any = useState([])

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()



    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {

                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${id}`);
                    localStorage.setItem('Add Data', JSON.stringify(response?.data?.data))
                    localStorage.setItem('PersonType', JSON.stringify(response?.data?.data?.person_type))
                    setData(response?.data?.data)
                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();
    }, [id]);



    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_CUSTOMER_GRADE_API?.toString()
    // const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();




    // useEffect(() => {
    //     if (data) {
    //         reset(data)
    //     }
    // }, [data]);


    // const onSubmit = (materialtypeDetails: any) => {


    //     const formData = new FormData();
    //     for (const [key, value] of Object.entries(materialtypeDetails)) {
    //         const formvalue: any = value
    //         if (typeof value !== "object") {
    //             formData.append(key, formvalue)
    //         }

    //     }
    //     let data = {
    //         apiname: apiname,
    //         id: id,
    //         data: formData
    //     }
    //     mutate(data);


    // }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            }
            // navigate('/listPersonDetailGrade', { state: { showEditToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error, isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);




    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
        setBirthDate(null)
        setJoiningDate(null)
        setLeavingDate(null)
        setAnniversaryDate(null)
        setAge(0)
    }


    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])



    const [personTypeLabel, setPersonTypeLabel] = useState('')

    

    const currentPath = window.location.pathname;

    const handleChangePersonType = (e: any) => {
        setValuePersonType(e)
        setPersonTypeLabel(e?.label)
        setValue("person_type", e?.value)
        setValuePersonTypeCreatable(null)
        if (currentPath === '/addCustomerDetails') {

            localStorage.removeItem('Add Data')
        }
    }

    let tempData: any = localStorage.getItem('PersonType')
    let tempDataString = tempData ? JSON.parse(tempData) : null;
    const [selectedId, setSelectedID]: any = useState('')



    useEffect(() => {

        // if(data){
        //     reset(data)
        //     setSelectedID(data?.person_type?.id)
        //     setValue('person_type',data?.person_type?.id)
        //     setPersonTypeLabel(data?.person_type?.name)

        // }


        // else 
        if (tempDataString) {

            if (tempDataString?.id) {
                setSelectedID(tempDataString?.id)
                setValue('person_type', tempDataString?.id)
                setPersonTypeLabel(tempDataString?.name)
            } else {
                setSelectedID(tempDataString?.value)
                setValue('person_type', tempDataString?.value)
                setPersonTypeLabel(tempDataString?.label)
            }

            // setSelectedID(tempDataString?.value)
            // setValue('person_type',tempDataString?.value)
            // setPersonTypeLabel(tempDataString?.label)
        }

    }, [personTypeLabel, valuePersonType, data, tempDataString])

    useEffect(() => {
        if (valuePersonType) {
            localStorage.setItem('PersonType', JSON.stringify(valuePersonType))
        }
    }, [valuePersonType, personTypeLabel, tempDataString])

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("person_type", personTypeData[0].value)
                localStorage.setItem('PersonType', JSON.stringify(personTypeData[0]))
            }

        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);


    const person_type = getValues('person_type')

    const [birthDate, setBirthDate]: any = useState(null)

    const [age, setAge]: any = useState()

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)

        setAge(0)
        setValue('age', 0)
    }


    //   useEffect(() => {

    //     if (birthDate != null || birthDate) {

    //         // if(birthDate == null){

    //         // }

    //         let todayDate = new Date().toISOString().split("T")[0]

    //         if (birthDate?.$D === 31) {
    //             var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
    //         } else {
    //             var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
    //         }



    //         var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

    //         setAge(currentDate.diff(bDate, 'years'))
    //         setValue("age", age)





    //     } else {
    //         setAge(0)
    //         setValue("age", 0)
    //     }



    // }, [birthDate, age])    


    //   useEffect(() => {

    //       if (birthDate != null || birthDate) {


    //           let todayDate = new Date().toISOString().split("T")[0]

    //           if (birthDate?.$D === 31) {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
    //           } else {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
    //           }



    //           var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

    //           setAge(currentDate.diff(bDate, 'years'))
    //           setValue("age", age)





    //       } else {
    //           setAge(0)
    //           setValue("age", 0)
    //       }



    //   }, [birthDate,age])



    const [Married, setMarried] = useState("Single")

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const [JoiningDate, setJoiningDate]: any = useState("")
    const [LeavingDate, setLeavingDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }
    const handleLeavingDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let leaving_date = `${date}-${month}-${year}`
        setValue("leaving_date", leaving_date)
        setLeavingDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }

    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }











    return (
        <div>
            <div className="" >
                <section className="content-header">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales And Marketing</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                            // onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="card-body" >
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={false}
                                                        components={customCreatableIndicator}
                                                        placeholder={'Person Type'}
                                                        isDisabled
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        // value={valuePersonTypeCreatable == null ? valuePersonType : valuePersonTypeCreatable}
                                                        value={valuePersonTypeCreatable == null ? valuePersonType == null ? optionsPersonType?.filter((obj: any) => obj.value === selectedId) : valuePersonType : valuePersonTypeCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.person_type?.message}</span>

                                                </div>






                                                <div className="row d-flex">
                                                    <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            {personTypeLabel === 'Employee' ?
                                                                <>

                                                                    <Edit_Employee setCurrentStep={setCurrentStep} currentStep={currentStep} />
                                                                </>
                                                                :
                                                                null
                                                            }


                                                            {/* {personTypeLabel === 'Customer' ? */}
                                                             {personTypeLabel != 'Employee'?
                                                                <>

                                                                    <Edit_Basic_Customer_Details setCurrentStep={setCurrentStep} currentStep={currentStep} />
                                                                </>
                                                                :
                                                                null
                                                            }





                                                        </div>
                                                    </div>
                                                </div>






                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleNext}
                                                >Next</button>
<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Edit_Basic_Details




