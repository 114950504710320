import React, { useEffect, useMemo, useState } from "react";
import { Styles } from "../../../components/Freeze_Columns/freezeStyle";
import FilterInput from "../../../components/Table Component/FilterInput";
import Actions, { Deleteforce, Deletesingle, ViewUsage } from "../../../components/Cell/Actions";
import Status from "../../../components/status";
import { Columns_Brand, titleofheaders } from "../../../pages/Brand/Columns_Brand";
import {
  useBlockLayout,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import Body from "./Components/Body/Body";
import Table_footer from "../../../components/Table Component/Table_footer";
import Header from "./Components/Header/Header";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";
import { Alert } from "../../../components/Model/Model";
import { Update, useFetch, useUpdateSingle } from "../../../hooks/Api";
import { group } from "console";
import { useLocation, useNavigate } from "react-router-dom";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import {
  useDeletemultiple,
  useDeletemultipleforce,
} from "../../../hooks/deletemultiple";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Axiosinstance from "../../../hooks/Interceptor";
import { Columns_DisposableProduct, titleofheaders_Disposable } from "../../../pages/Disposable Product/Columns_Disposable";
// import { Columns_LLDPE, titleofheaders_LLDPE } from "../../../pages/Disposable Product/Columns_LLDPE";
import { useFetchCheckListMapperDropDown } from "../../../hooks/DropDown_Api";
import axios from "axios";
import { useViewData } from "../../../hooks/viewUsageApi";


export interface IColumns {
  id?: string;
  Header?: any;
  sticky?: any;
  accessor?: any;
  Cell?: any;
}

export interface ITable {
  apiname?: any;
  // apiForOnlyDelete?:any
  filterFields: Object;
  addButtonName?: string;
  addButtonPageName?: any
  stickyStart: any;
  stickyEnd: any;
  titleofheaders?: any
  TableColumns?: IColumns[];
  // setProuctType?:any
  // productType?:any
  updateMyData?: any
  editableData?: any
  newRowClick?: () => void
  defaultColumn?: any
  permissionClass?: any
  isMore?: any
  isFooter?: any
  isAddButton?: any
  idTableFooter?: any
  priceListData?: any
  orderData?: any
  addressData?: any
  totalOfAll?: any
  isGenerateButton?: any
  handleMultipleData?: any
  multipleIds?: any
  added?: any
  setAdded?: any
  checkListReportData?: any
  maintenanceReportData?: any
  GranualReportData?: any
  resetData?:any
}

const Table = ({
  apiname,
  // apiForOnlyDelete,
  filterFields,
  addButtonName,
  addButtonPageName,
  stickyStart,
  stickyEnd,
  TableColumns,
  titleofheaders,
  handleMultipleData,
  // setProuctType,
  // productType,
  updateMyData,
  editableData,
  newRowClick,
  defaultColumn,
  permissionClass,
  isMore,
  isFooter = true,
  isAddButton,
  idTableFooter,
  priceListData,
  orderData,
  totalOfAll,
  isGenerateButton,
  multipleIds,
  added,
  setAdded,
  checkListReportData,
  maintenanceReportData,
  GranualReportData,
  resetData
}: ITable) => {
  const [headertitles, setHeadertitle] = useState<string[]>([]);


  const navigate = useNavigate();
  const [page, setPage]: any = useState('1');
  const [filter, setFilter] = useState(false);
  const [FilterBy, setFilterBy] = useState({ ...filterFields });
  const [data, setData]: any = useState([]);
  const [pageSize, setPageSize]:any = useState("10");
  const [search, setSearch] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [deleteid, setDeleteid] = useState();
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [excelBody, setExcelBody]: any = useState([]);
  const [excelHeader, setExcelHeader]: any = useState<any[]>([]);

  const [columnVisibility, setColumnVisibility] = useState(false);
  const [pageNumber, setPageNumber] = useState<Number[]>([]);
  const [totalPages, setTotalPages] = useState<Number>(1);
  const [columnSort, setColumnSort] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [pageLoader, setPageLoader] = useState(false);

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { state } = useLocation();
  const [tempShow, setTempShow] = useState(false);
  const [tempUpdateShow, setTempUpdateShow] = useState(false);
  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();


  const {
    mutate: viewData,
    data: viewUsageData,
    isSuccess: isSuccessViewUsage,
    isError: isErrorViewUsage,
  } = useViewData();

  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);

  const [openViewUsage, setOpenViewUsage] = useState(false);

  //Color Copy

  //---------------------------------------- API Section ---------------------------------------//

  const {
    isLoading: fetchLoading,
    isError,
    data: fetchAllData,
    error,
    isFetching,
    refetch,
    isSuccess,
    isPreviousData,
    isRefetching,
  } = useFetch(FilterBy, search, page, pageSize, orderBy, apiname);

  //---------------------------------------- API Section End ---------------------------------------//

  //------------------------------------React Table---------------------------------------//

  const columns: any = useMemo(() => TableColumns, []);












  const tableInstance: any = useTable(
    {
      columns,
      data: data,
      editableData,
      defaultColumn,
      updateMyData,
      initialState: { sortBy: columnSort },


    },
    useColumnOrder,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useBlockLayout,
    useSticky
  );

  let {
    visibleColumns,
    getTableProps,
    disableSortBy,
    selectedFlatRows,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setSortBy,
    setColumnOrder,
    toggleHideAllColumns,
    toggleHideColumn,
    state: { sortBy },
  } = tableInstance;

  const [NewColumnOrder, setNewColumnOrder] = useState(allColumns);

  //------------------------------------End---------------------------------------//

  const handleFilter = () => {
    setFilter(!filter);
  };

  useEffect(() => {
    setFilterBy({ ...filterFields });
  }, [filter]);

  useEffect(() => {
    refetch();
  }, [page, pageSize, search, FilterBy, orderBy]);


  const [productType, setProductType]: any = useState("")
  const [productTypeDisposable, setProductTypeDisposable]: any = useState("")

  const [productTypeOptions, setProductTypeOptions]: any = useState("")
  const [RawMaterialGradeOptions, setRawMaterialGradeOptions]: any = useState("")
  const [TapeRollOptions, setTapeRollOptions]: any = useState("")
  const [BoxPattiOptions, setBoxPattiOptions]: any = useState("")
  const [PanniOptions, setPanniOptions]: any = useState("")
  const [EmployeeOptions, setEmployeeOptions]: any = useState("")
  const [CustomerOptions, setCustomerOptions]: any = useState("")
  const [DispatchedOptions, setDispatchedOptions]: any = useState("")


  const [customerId, setCustomerId] = useState('')
  const [dos, setDos] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [newAdded,setNewAdded]:any = useState(false)
  const [newAddedTFM,setNewAddedTFM]:any = useState(false)

  const ListGranual = window.location.pathname === '/listProductionline';
  // useEffect(()=>{
  //   const callApi = ()=>{
  //     if (apiname === "production/productionLine") {
  //       if (ListGranual) {
  //         const apinameForGranual = "production/productionLine";
  //         let todayDate = new Date().toISOString().split("T")[0]
  //         let [year, month, day] = todayDate.split("-");
  //         let formattedDate = `${day}-${month}-${year}`;
  //         // if (!isChecked) {
  //           Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameForGranual}/search_filter/`, {
  //             // customer_id: customerId,
  //             date: formattedDate
  //           })
  //             .then((response) => {
  //               setData(response?.data?.data)
    
  //             })
  //             .catch((error) => {
  //               console.error('There was an error!', error);
  //             });
  //         // }
         
    
    
  //       } else  {
  //         setData(fetchAllData?.results);
  //       }
  //     }
  //   }
  //   callApi()
  // },[newAdded])

  useEffect(() => {

    let currentPage = window.location.pathname


    const pagePriceList = window.location.pathname === "/addPriceList"
    const salesOrderPageData = window.location.pathname === "/addSalesOrder"

    const isLLDP = window.location.pathname === '/listSinglecode/LLDP';
    const isDisposable = window.location.pathname === '/listSinglecode';
    const isLD = window.location.pathname === '/listSinglecode/LDPE';
    const isHDPE = window.location.pathname === '/listSinglecode/HDPE';
    const isLDPEShrink = window.location.pathname === '/listSinglecode/LDPEShrink';
    const isShrink = window.location.pathname === '/listSinglecode/Shrink';
    const isPP = window.location.pathname === '/listSinglecode/PP';
    const isOther = window.location.pathname === '/listSinglecode/Other';

    const DisposableisLLDP = window.location.pathname === '/listDisposableproducts/LLDPE';
    const DisposableisDisposable = window.location.pathname === '/listDisposableproducts';
    const DisposableisLD = window.location.pathname === '/listDisposableproducts/LDPE';
    const DisposableisHDPE = window.location.pathname === '/listDisposableproducts/HDPE';
    const DisposableisLDPEShrink = window.location.pathname === '/listDisposableproducts/LDPEShrink';
    const DisposableisShrink = window.location.pathname === '/listDisposableproducts/Shrink';
    const DisposableisPP = window.location.pathname === '/listDisposableproducts/PP';
    const DisposableisOther = window.location.pathname === '/listDisposableproducts/Other';

    const productTypeAll = window.location.pathname === '/listProducttype';
    const productTypeDisposable = window.location.pathname === '/listProductType/Disposable';
    const productTypeLiner = window.location.pathname === '/listProductType/Liner';
    const productTypeOther = window.location.pathname === '/listProductType/Other';

    const RawMaterialGradeAll = window.location.pathname === '/listRawmaterialgrade';
    const RawMaterialGradePP = window.location.pathname === '/listRawMaterialGrade/PP';
    const RawMaterialGradeLD = window.location.pathname === '/listRawMaterialGrade/LD';
    const RawMaterialGradeLLDP = window.location.pathname === '/listRawMaterialGrade/LLDP';
    const RawMaterialGradeHM = window.location.pathname === '/listRawMaterialGrade/HM';
    const RawMaterialGradeOther = window.location.pathname === '/listRawMaterialGrade/Other';

    const TapeRollAll = window.location.pathname === '/listTaperoll';
    const TapeRollBopp = window.location.pathname === '/listTaperoll/Bopp';
    const TapeRollPP = window.location.pathname === '/listTaperoll/PP';
    const TapeRollOther = window.location.pathname === '/listTaperoll/Other';


    const BoxPattiAll = window.location.pathname === '/listBoxpatti';
    const BoxPattiBopp = window.location.pathname === '/listBoxpatti/Bopp';
    const BoxPattiPP = window.location.pathname === '/listBoxpatti/PP';
    const BoxPattiOther = window.location.pathname === '/listBoxpatti/Other';

    const PanniAll = window.location.pathname === '/listPanni';
    const PanniPP = window.location.pathname === '/listPanni/PP';
    const PanniLd = window.location.pathname === '/listPanni/LD';
    const PanniLldp = window.location.pathname === '/listPanni/LLDP';
    const PanniLDPEShrink = window.location.pathname === '/listPanni/LDPEShrink';
    const PanniHm = window.location.pathname === '/listPanni/HM';
    const PanniOther = window.location.pathname === '/listPanni/Other';

    const EmployeeAll = window.location.pathname === '/listEmployee';
    const EmployeeMonthlySalary = window.location.pathname === '/listEmployee/MonthlySalary';
    const EmployeeDailyWages = window.location.pathname === '/listEmployee/DailyWages';
    const EmployeeYearlySalary = window.location.pathname === '/listEmployee/YearlySalary';
    const EmployeeContract = window.location.pathname === '/listEmployee/Contract';
    const EmployeeOther = window.location.pathname === '/listEmployee/Other';

    const CustomerAll = window.location.pathname === '/listPersonDetail';
    const CustomerEmployee = window.location.pathname === '/listPersonDetail/Employee';
    const CustomerCustomer = window.location.pathname === '/listPersonDetail/Customer';
    const CustomerSupplier = window.location.pathname === '/listPersonDetail/Supplier';
    const CustomerOther = window.location.pathname === '/listPersonDetail/Other';


    const Dispatch = window.location.pathname === '/listSinglecode/LLDP';
    const DispatchNot = window.location.pathname === '/listSinglecode';

    const ListDispatchOrder = window.location.pathname === '/listDispatchOrder';


    if (apiname === "sales/dispatchOrder") {
      if (ListDispatchOrder) {
        const apinameForDispachOrder = "sales/dispatchOrder";
        if (customerId && !isChecked) {
          Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameForDispachOrder}/search_filter/`, {
            customer_id: customerId,
            order_dispatched: "~Fully Dispatched"
          })
            .then((response) => {
              setData(response?.data?.results)

            })
            .catch((error) => {
              console.error('There was an error!', error);
            });
        }
        else if (fetchAllData && apiname) {
          setData(fetchAllData?.results);
        }


      }
    }
   




    





    if (apiname === "singleCode/singleCode") {

      if (isLLDP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LLDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("LLDPE")
      }
      else if (isDisposable) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Disposable"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("Disposable")
      }
      else if (isLD) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("LDPE")
      }
      else if (isHDPE) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"HDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("HDPE")
      }
      else if (isLDPEShrink) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LDPE Shrink"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("LDPE Shrink")
      }
      else if (isShrink) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Shrink"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("Shrink")
      }
      else if (isPP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Pp"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("Pp")
      }
      else if (isOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductType("Other")
      }
    }


    if (apiname === "allCode/disposableProduct") {
      if (DisposableisLLDP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LLDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("LLDPE")
      }
      else if (DisposableisDisposable) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Disposable"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("Disposable")
      }
      else if (DisposableisLD) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("LDPE")
      }
      else if (DisposableisLDPEShrink) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"LDPE Shrink"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("LDPE Shrink")
      }
      else if (DisposableisShrink) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Shrink"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("Shrink")
      }
      else if (DisposableisHDPE) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"HDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("HDPE")
      }
      else if (DisposableisPP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"PP"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("PP")
      }
      else if (DisposableisOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("Other")
      }
    }



    if (apiname === "productType") {
      if (productTypeAll) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"All"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"All"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"All"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
        // // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"All"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setProductTypeOptions("All")
      }
      else if (productTypeDisposable) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Disposable"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Disposable"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Disposable"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setProductTypeOptions("Disposable")
      }
      else if (productTypeLiner) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Liner"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Liner"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Liner"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setProductTypeOptions("Liner")
      }

      else if (productTypeOther) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Other"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Other"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productCategory/?categories=${"Other"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setProductTypeOptions("Other")
      }
    }


    if (apiname === "rawMaterialGrade") {
      if (RawMaterialGradeAll) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"All"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("All")
      }
      else if (RawMaterialGradePP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"PP"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("PP")
      }
      else if (RawMaterialGradeLD) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"LD"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("LD")
      }
      else if (RawMaterialGradeLLDP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"LLDP"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("LLDP")
      }
      else if (RawMaterialGradeHM) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"HM"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("HM")
      }

      else if (RawMaterialGradeOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setRawMaterialGradeOptions("Other")
      }
    }





    if (apiname === "tapeRoll/taperoll") {
      if (TapeRollAll) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"All"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setTapeRollOptions("All")
      }
      else if (TapeRollBopp) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Bopp"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setTapeRollOptions("Bopp")
      }
      else if (TapeRollPP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Pp"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setTapeRollOptions("Pp")
      }
      else if (TapeRollOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setTapeRollOptions("Other")
      }


    }




    if (apiname === "boxPatti/boxPatti") {
      if (BoxPattiAll) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"All"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setBoxPattiOptions("All")
      }
      else if (BoxPattiBopp) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Bopp"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setBoxPattiOptions("Bopp")
      }
      else if (BoxPattiPP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Pp"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setBoxPattiOptions("Pp")
      }
      else if (BoxPattiOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setBoxPattiOptions("Other")
      }


    }
    if (apiname === "panni/panniView") {
      if (PanniAll) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"All"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("All")
      }
      else if (PanniPP) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"PP"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("PP")
      }
      else if (PanniLd) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"LDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("LDPE")
      }
      else if (PanniLldp) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"LLDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("LLDPE")
      }
      else if (PanniLDPEShrink) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"LDPE Shrink"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setProductTypeDisposable("LDPE Shrink")
      }
      else if (PanniHm) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"HDPE"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("HDPE")
      }
      else if (PanniOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_materialType/?Type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setPanniOptions("Other")
      }


    }

    if (apiname === "leoUser/employee/employee") {
      if (EmployeeAll) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"All"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("All")
      }
      else if (EmployeeMonthlySalary) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"Monthly Salary"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("Monthly Salary")
      }
      else if (EmployeeDailyWages) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"Daily Wages"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("Daily Wages")
      }
      else if (EmployeeYearlySalary) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"Yearly Salary"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("Yearly Salary")
      }
      else if (EmployeeContract) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"Contract"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("Contract")
      }
      else if (EmployeeOther) {
        Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_salaryType/?Type=${"Other"}`).then((data: any) => {
          setData(data?.data?.results)
        })
        setEmployeeOptions("Other")
      }


    }

    if (apiname === "person/employee/person") {

      if (CustomerAll) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"All"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"All"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }

        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"All"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setCustomerOptions("All")
      }
      else if (CustomerEmployee) {
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Employee"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Employee"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }

        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Employee"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setCustomerOptions("Employee")
      }
      else if (CustomerCustomer) {

        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Customer"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Customer"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Customer"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setCustomerOptions("Customer")
      }
      else if (CustomerSupplier) {

        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Supplier"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Supplier"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Vendor"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setCustomerOptions("Supplier")
      }
      
      else if (CustomerOther) {
        
        if(pageSize === "all"){
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Other"}&page_size=all`).then((data: any) => {
              setData(data?.data?.results)
            })
        }else{
          Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Other"}&page=${page}&page_size=${pageSize}`).then((data: any) => {
              setData(data?.data?.results)
            })
        }
        // Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_personType/?name=${"Other"}`).then((data: any) => {
        //   setData(data?.data?.results)
        // })
        setCustomerOptions("Other")
      }


    }


    // if(apiname === "sales/dispatchOrder"){

    //   if(Dispatch){
    //   Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Partially"}`).then((data:any)=>{
    //         setData(data?.data?.results)
    //   })
    //   setProductType("Partially")
    // }
    //   else if(DispatchNot){
    //     Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${"Not"}`).then((data:any)=>{
    //       setData(data?.data?.results)
    // })
    // setProductType("Not")
    //   }

    // }

    else if (pagePriceList) {
      if (priceListData) {
        setData(priceListData)
        refetch()
      }
    }

    else if (salesOrderPageData) {
      if (orderData || added) {
        setData(orderData)
        setAdded(false)
        refetch()

      }
    }


    else if (handleMultipleData) {

      //     Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`,{
      //       ids:multipleIds
      //     })
      //     .then((response) => {
      //     setData(handleMultipleData)

      //     })
      // .catch((error) => {
      //   console.error('There was an error!', error);  
      // });

      setData(handleMultipleData)

    }

    else if (checkListReportData) {
      setData(checkListReportData);
    }

    else if (maintenanceReportData && resetData) {
      setData(maintenanceReportData);
    }
 


    else if (fetchAllData && apiname) {
      setData(fetchAllData?.results);
    }





  }, [fetchAllData, priceListData, orderData, customerId, dos, isChecked, handleMultipleData]);






  useEffect(() => {
    return () => {
      setDeleteMultipleid([]);
    };
  }, []);

  useEffect(() => {
    setDeleteMultipleid(() => {
      const temp = selectedFlatRows.map((e: any) => {
        return e.original.id;
      });
      return temp;
    });
  }, [selectedFlatRows]);

  useEffect(() => {
    if (deletesucess === true) {
      refetch();

    }
  }, [deletesucess, fetchAllData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);

  const handleColumnVisibility = () => {
    if (!columnVisibility) {
      setColumnVisibility(true);
    } else {
      setColumnVisibility(false);
    }
  };

  useEffect(() => {
    refetch(fetchAllData?.results);
  }, [useUpdateSingle, fetchAllData?.results]);

  if (isError) {
    const errorpassesfromcall: any = error;
    if (errorpassesfromcall?.response?.data?.error === "Unauthorized") {
      navigate("/login");
    }
  }

  useEffect(() => {
    if (isSuccess) {
      try {
        setPageNumber(
          Array.from(
            Array(Math.ceil(fetchAllData?.count / Number(pageSize))),
            (_, x) => x + 1
          )
        );
      } catch (error) {
        setPageNumber([1]);
      }
      const temp_ = pageNumber[pageNumber.length - 1];
      setTotalPages(temp_);
    }
  }, [fetchAllData?.results]);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1)
  };

  // useEffect(() => {
  //   setExcelBody(() => {
  //     const temp = selectedFlatRows.map((e: any, index: any) => {
  //       const take_columns: any = {
  //         Sr: index + 1,
  //       };
  //       for (const [key, value] of Object.entries(e.original)) {
  //         // if(key === 'checklistMapper'){
  //         //   let temp = e.original.checklistMapper.map((e:any)=>{
  //         //     let created_date = e.original?.created_at.split(' ')[0].split('-')[0]
  //         //     if (created_date) {
  //         //       return take_columns[created_date] = value;

  //         //     }
  //         //   })
  //         //   temp = value
  //         // }
  //         if (excelHeader.includes(key)) {
  //           take_columns[key.charAt(0).toUpperCase() + key.slice(1)] = value;
  //         }
  //       }
  //       return take_columns;
  //     });
  //     return temp;
  //   });
  // }, [selectedFlatRows, excelHeader]);


  useEffect(() => {
    setExcelBody(() => {
      const temp = selectedFlatRows.map((row: any, index: any) => {
        const take_columns: any = { Sr: index + 1 };

        // // Handle regular columns
        // excelHeader.forEach((header:any) => {
        //   if (header !== 'checklistMapper') {
        //     take_columns[header] = row.original[header];
        //   }
        // });

        // Define columns to add before and after date columns
        const preDateColumns = ['Sr No', 'Checklist Code', 'Task Name', 'Choice Yes/No', 'Open Time', 'Close Time']; // Replace with actual column names
        const postDateColumns = excelHeader.filter((header: any) =>
          header !== 'checklistMapper' && !preDateColumns.includes(header));

        // Add pre-date columns
        preDateColumns.forEach((header: any) => {
          if (row.original.hasOwnProperty(header)) {
            take_columns[header] = row.original[header];
          }
        });

        // Flatten checklistMapper
        for (let date = 1; date <= 31; date++) {
          const formattedDate = String(date).padStart(2, '0');
          const checklistEntry = row.original.checklistMapper?.find((e: any) => {
            let created_date = e.created_at?.split(' ')[0].split('-')[0]
            // const created_date = e.created_at.split(' ')[0].split('-')[2]; // Assuming YYYY-MM-DD format
            return created_date === formattedDate;
          });

          // take_columns[formattedDate] = checklistEntry 
          // ? checklistEntry?.checkList?.choice_yes_no==='yes'? 
          // checklistEntry?.checkList?.choice_yes_no==='no'? 'no' : 'yes':'-':'';
          if (checklistEntry && checklistEntry.checkList) {
            if (checklistEntry.choice_yes_no === 'yes') {
              take_columns[formattedDate] = 'yes';
            } else if (checklistEntry?.choice_yes_no === 'no') {
              take_columns[formattedDate] = 'no';
            } else {
              take_columns[formattedDate] = '-';
            }
          } else {
            take_columns[formattedDate] = '-';
          }

        }


        // Add post-date columns
        postDateColumns.forEach((header: any) => {
          if (row.original.hasOwnProperty(header)) {
            take_columns[header] = row.original[header];
          }
        });

        return take_columns;
      });

      return temp;
    });
  }, [selectedFlatRows, excelHeader]);


  useEffect(() => {
    const temp_col: any = allColumns;
    setExcelHeader(() => {
      const temp_header = temp_col
        .filter((item: any) => item.isVisible && item.accessor !== undefined)
        .map((e: any) => {
          return e.id;
        });
      return temp_header;
    });
  }, [headerGroups]);

  useEffect(() => {
    setColumnOrder(() => {
      const temp_order = NewColumnOrder.map((e: any) => {
        return e.id;
      });
      return temp_order;
    });
  }, [NewColumnOrder]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  // Column TH Drag & Drop
  const [tempColu, setTempColu] = useState("");

  const handleDragStart = (e: any) => {
    const { id } = e.target;
    const dummy = NewColumnOrder;
    const idx = dummy.map((e: any, index: any) => {
        if (e.id === id) {
          return index;
        }
      })
      .filter((e: any) => e != undefined)[0];
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (e: any) => e.preventDefault();
  const handleDragEnter = (e: any) => {
    const { id } = e.target;
    const dummy = NewColumnOrder;
    const idx = dummy.map((e: any, index: any) => {
        if (e.id === id) {
          return index;
        }
      })
      .filter((e: any) => e != undefined)[0];
    setTempColu(idx);
  };

  // const handleOnDrop = async (e: any) => {
  //   const { id } = e.target;
  //   const dummy = NewColumnOrder;
  //   let droppedColIdx = await dummy.map((e: any, index: any) => {
  //       if (e.id === id) {
  //         return index;
  //       }
  //     })
  //     .filter((e: any) => e != undefined)[0]; //return id where we will place the content
  //   const draggedColIdx = await Number(e.dataTransfer.getData("colIdx")); //picked
  //   const tempCols = NewColumnOrder;
  //   const dummydatacol = tempCols[draggedColIdx];

  //   droppedColIdx =
  //     droppedColIdx <= stickyStart
  //       ? stickyEnd
  //       : droppedColIdx == undefined
  //         ? stickyEnd
  //         : droppedColIdx;

  //   if (draggedColIdx > stickyStart) {
  //     tempCols.splice(draggedColIdx, 1);
  //     tempCols.splice(droppedColIdx, 0, dummydatacol);
  //     setNewColumnOrder(tempCols);
  //     setColumnOrder(() => {
  //       const temp_order = tempCols.map((e: any) => {
  //         return e.id;
  //       });
  //       return temp_order;
  //     });
  //   }
  // };

  const handleOnDrop = async (e:any) => {
    const { id } = e.target;
  
    // Check if the target element has an ID
    if (!id) {
      console.error('Target element does not have an ID');
      return;
    }
  
    const dummy = NewColumnOrder;
    let droppedColIdx = dummy
      .map((e:any, index:any) => {
        if (e.id === id) {
          return index;
        }
      })
      .filter((e:any) => e != undefined)[0]; // Return id where we will place the content
  
    const draggedColIdx = Number(e.dataTransfer.getData('colIdx')); // Picked
    const tempCols = [...NewColumnOrder];
    const dummydatacol = tempCols[draggedColIdx];
  
    droppedColIdx =
      droppedColIdx <= stickyStart ? stickyEnd : droppedColIdx ?? stickyEnd;
  
    if (draggedColIdx > stickyStart) {
      tempCols.splice(draggedColIdx, 1);
      tempCols.splice(droppedColIdx, 0, dummydatacol);
      setNewColumnOrder(tempCols);
      setColumnOrder(() => {
        const temp_order = tempCols.map((e) => {
          return e.id;
        });
        return temp_order;
      });
    }
  };
  

  // const handleOnDrop = async (e:any) => {
  //   const { id: droppedId } = e.target;
  //   const { colIdx: draggedIdx } = e.dataTransfer.getData("text/plain");
    
  //   if (!droppedId) {
  //     console.error('Target element does not have an ID');
  //     return;
  //   }
  
  //   const tempCols = [...NewColumnOrder];
  //   const droppedIdx = tempCols.findIndex(col => col.id === droppedId);
  
  //   if (draggedIdx > stickyStart) {
  //     const draggedCol = tempCols.splice(draggedIdx, 1)[0]; // Remove dragged column
  //     tempCols.splice(droppedIdx, 0, draggedCol); // Insert dragged column at dropped position
  //     setNewColumnOrder(tempCols);
      
  //     const temp_order = tempCols.map((col) => col.id);
  //     setColumnOrder(temp_order);
  //   }
  // };
  

  const handleRefresh = () => {
    navigate(0);
  };

  useEffect(() => {
    setColumnSort(sortBy);
  }, [useSortBy, sortBy]);

  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const viewUsageModal = (viewId: any) => {
    setOpenViewUsage(true);
    viewData({ apiname, viewId })
    // setDeleteMultipleid([]);
  };

  const closeViewUsageModal = () => {
    setOpenViewUsage(false);
    // setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname });

  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  useEffect(() => {
    const documentmodal = document.querySelector(".modal-backdrop");
    documentmodal?.classList?.remove(".modal-backdrop");
  }, []);
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) {
      deleteforce({ deleteid, apiname });

    }
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname });
      if (handleMultipleData) {
        setData(handleMultipleData)
      }
    }
  };

  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
      refetch();
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
      refetch();
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname });

        refetch();
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess]);



  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname });
    setDeleteConfirm(false);
  };

  useEffect(() => {
    if (state) {
      if (state.showToast) {
        setTempShow(true);
        window.history.replaceState({}, document.title);
      }
      if (state.showEditToast) {
        setTempUpdateShow(true);
        window.history.replaceState({}, document.title);
      }

      setTimeout(() => {
        refetch();
      }, 2000);
    }
  }, [state]);

  useEffect(() => {
    setColumnSort(sortBy);

    if (sortBy.length > 0) {
      const temp_orderBy = sortBy[0];
      const order_by = temp_orderBy.desc
        ? `-${temp_orderBy.id}`
        : temp_orderBy.id;
      setOrderBy(order_by);
    } else {
      setOrderBy("");
    }
  }, [useSortBy, sortBy]);

  useEffect(() => {
    if (isFetching) {
      setPageLoader(true);
    } else {
      setPageLoader(false);
    }
  }, [isFetching, fetchAllData]);

  useEffect(() => {
    let paging = page != 1 ? page - 1 : page;
    if (deleteMultipleSuccess) {
      setPage(paging);
      refetch();
    }
  }, [deleteMultipleSuccess]);

  // useEffect(() => {
  //   setExcelBody(() => {
  //     const temp = selectedFlatRows.map((e: any, index: any) => {
  //       const take_columns: any = {
  //         Sr: index + 1,
  //       };
  //       for (const [key, value] of Object.entries(e.original)) {
  //         if (excelHeader.includes(key)) {
  //           // take_columns[key.charAt(0).toUpperCase() + key.slice(1)] = value;
  //           take_columns[key] = value;
  //         }
  //       }
  //       return take_columns;
  //     });
  //     return temp;
  //   });
  // }, [selectedFlatRows, excelHeader]);

  useEffect(() => {
    setExcelBody(() => {
      const temp = selectedFlatRows.map((row: any, index: any) => {
        const take_columns: any = { Sr: index + 1 };

        // Handle regular columns
        // excelHeader.forEach((header:any) => {
        //   if (header !== 'checklistMapper') {
        //     take_columns[header] = row.original[header];
        //   }
        // });

        // Define columns to add before and after date columns
        const preDateColumns = ['Sr No', 'Checklist Code', 'Task Name', 'Choice Yes/No', 'Open Time', 'Close Time']; // Replace with actual column names
        const postDateColumns = excelHeader.filter((header: any) =>
          header !== 'checklistMapper' && !preDateColumns.includes(header));

        // Add pre-date columns
        preDateColumns.forEach((header: any) => {
          if (row.original.hasOwnProperty(header)) {
            take_columns[header] = row.original[header];
          }
        });


        // Flatten checklistMapper data
        for (let date = 1; date <= 31; date++) {
          const formattedDate = String(date).padStart(2, '0');
          const checklistEntry = row.original.checklistMapper?.find((e: any) => {
            let created_date = e.created_at?.split(' ')[0].split('-')[0]

            return created_date === formattedDate;
          });

          // take_columns[formattedDate] = checklistEntry 
          // ? checklistEntry?.checkList?.choice_yes_no==='yes'? 
          // checklistEntry?.checkList?.choice_yes_no==='no'? 'no' : 'yes':'-':'';

          if (checklistEntry && checklistEntry.checkList) {
            if (checklistEntry.choice_yes_no === 'yes') {
              take_columns[formattedDate] = 'yes';
            } else if (checklistEntry?.choice_yes_no === 'no') {
              take_columns[formattedDate] = 'no';
            } else {
              take_columns[formattedDate] = '-';
            }
          } else {
            take_columns[formattedDate] = '-';
          }


        }

        // Add post-date columns
        postDateColumns.forEach((header: any) => {
          if (row.original.hasOwnProperty(header)) {
            take_columns[header] = row.original[header];
          }
        });

        return take_columns;
      });

      return temp;
    });
  }, [selectedFlatRows, excelHeader]);


  useEffect(() => {
    const temp_col: any = allColumns;
    setExcelHeader(() => {
      const temp_header = temp_col
        .filter((item: any) => item.isVisible && item.accessor != undefined)
        .map((e: any) => {
          return e.id;
        });
      return temp_header;
    });
    setHeadertitle(() => {
      const temp_header = temp_col
        .filter((item: any) => item.isVisible && item.accessor != undefined)
        .map((e: any) => {
          const onlyname = titleofheaders.filter((filtername: any) => {
            return filtername.id === e.id;
          });
          return onlyname[0]?.Header;
          // return e.id
        });
      return temp_header;
    });
  }, [headerGroups]);


  let currentPage = window.location.pathname

  // useEffect(() => {
  //   const getDataItem = async () => {
  //     if (currentPage === '/listProductionline' || currentPage === '/listproductionline') {
  //       await setData(fetchAllData?.results)
  //       if (isSuccess && fetchAllData?.results) {
  //         if(fetchAllData?.results !== undefined || fetchAllData?.results !== 'undefined' || fetchAllData?.results !==  null)
  //         await localStorage.setItem('dataItem', JSON.stringify(fetchAllData?.results))
  //       }
  //     }
  //   }
  //   getDataItem()
  // }, [currentPage, isSuccess, fetchAllData,isRefetching])



  useEffect(()=>{
    const callApi = async()=>{
      if (apiname === "production/productionLine") {
        if (ListGranual) {
          const apinameForGranual = "production/productionLine";
          let todayDate = new Date().toISOString().split("T")[0]
          let [year, month, day] = todayDate.split("-");
          let formattedDate = `${day}-${month}-${year}`;
          // if (!isChecked) {
          if(GranualReportData && resetData){
            setData(GranualReportData)
          }else{
            Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameForGranual}/search_filter/`, {
              // customer_id: customerId,
              date: formattedDate
            })
              .then((response) => {

                setData(response?.data?.results)
                localStorage.setItem('dataItem', JSON.stringify(response?.data?.results))
    
              })
              .catch((error) => {
                console.error('There was an error!', error);
              });
          }
         
    
    
        } 
        // else  {
        //   setData(fetchAllData?.results);
        // }
      }
    }
    callApi()
  },[newAdded,GranualReportData,newAddedTFM,
    resetData])




  // const [choiceData,setChoiceData]:any = useState()

  //   useEffect(() => {
  //     if(checklistMapperData?.length > 0 ){

  //     let final = data?.map((e:any) => {
  //     let ctx:any = e
  //     let ct:any = checklistMapperData?.find((s:any) => e.id === s.id)
  //     if( ct != "" && ct != undefined){
  //         ctx["submitted"] = true
  //     }
  //     else{
  //         ctx["submitted"] = false
  //     }
  //     return ctx
  // })
  // setChoiceData(final)

  //     }

  //   }, [checklistMapperData])







  return (
    <>
      <div className="table-wrapper">
        {/* <button onClick={newRowClick}>Add New Row</button> */}
        {/* Table Header */}
        <Header
          isMore={isMore}
          refetch={refetch}
          key={"header"}
          apiname={apiname}
          titleofheaders={titleofheaders}
          columnVisibility={columnVisibility}
          NewColumnOrder={NewColumnOrder}
          setNewColumnOrder={setNewColumnOrder}
          tableInstance={tableInstance}
          handleColumnVisibility={handleColumnVisibility}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          excelBody={excelBody}
          excelHeader={excelHeader}
          setShow={setShow}
          setshowmessage={setshowmessage}
          headertitles={headertitles}
          handleConfirmDelete={handleConfirmDelete}
          handleFilter={handleFilter}
          setColumnVisibility={setColumnVisibility}
          filter={filter}
          permissionClass={permissionClass}
          addButtonPageName={addButtonPageName}
          addButtonName={`Add ${addButtonName}`}
          isAddButton={isAddButton}
          setNewAdded={setNewAdded}
          setNewAddedTFM={setNewAddedTFM}
        />

        {/* Error Messages */}
        {tempShow && (
          <Mui_Toaster
            message={`${addButtonName} Added Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}
        {tempUpdateShow && (
          <Mui_Toaster
            message={`${addButtonName} Updated Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}

        {show && showmessage === "success" && (
          <Alert
            color={showmessage}
            message={`${addButtonName} Deleted successfully`}
          />
        )}
        {show && showmessage === "danger" && (
          <Alert color={showmessage} message="No Rows are Selected" />
        )}

        {/* Table Body */}
        <Body
          // choiceData={choiceData}
          key={"body"}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          allColumns={allColumns}
          filter={filter}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleOnDrop={handleOnDrop}
          handleDragEnter={handleDragEnter}
          FilterBy={FilterBy}
          setFilterBy={setFilterBy}
          setPage={setPage}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          pageSize={pageSize}
          page={page}
          apiname={apiname}
          singleDelete={singleDelete}
          addButtonName={addButtonName}
          deleteid={deleteid}
          handleDeletedforce={handleDeletedforce}
          closereconfirmdelete={closereconfirmdelete}
          viewUsageModal={viewUsageModal}
          closeViewUsageModal={closeViewUsageModal}
          deletemodalask={deletemodalask}
          Update={Update}
          refetch={refetch}
          stickyHeader={stickyStart}
          data={data}
          setData={setData}
          productType={productType}
          productTypeDisposable={productTypeDisposable}
          productTypeOptions={productTypeOptions}
          RawMaterialGradeOptions={RawMaterialGradeOptions}
          TapeRollOptions={TapeRollOptions}
          BoxPattiOptions={BoxPattiOptions}
          PanniOptions={PanniOptions}
          EmployeeOptions={EmployeeOptions}
          CustomerOptions={CustomerOptions}
          setDos={setDos}
          dos={dos}
          setCustomerId={setCustomerId}
          setIsChecked={setIsChecked}
          DispatchedOptions={DispatchedOptions}
          // setProductType={setProductType}
          // setProductTypeForSingleCode={setProuctType}
          // ProductTypeForSingleCode={productType}
          refetch_For_Change={refetch}
          permissionClass={permissionClass}
          idTableFooter={idTableFooter}
          totalOfAll={totalOfAll}
        />
        {isFooter && <>
          {/* Table Footer */}
          <Table_footer
            key={"footer"}
            customerId={customerId}
            apiname={apiname}
            page={page}
            setPageSize={setPageSize}
            setPage={setPage}
            pageSize={pageSize}
            pageNumber={pageNumber}
            visibleColumns={visibleColumns}
            data={fetchAllData}
            PageLoading={pageLoader}
            permissionClass={permissionClass}
            dos={dos}
            isGenerateButton={isGenerateButton}
          />
        </>}


        {/* Edit Modal HTML */}
        <Modal
          show={deleteConfirm}
          onHide={() => setDeleteConfirm(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete {addButtonName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              {" "}
              <span className="text-center">Sure you want to delete it?</span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="info"
              className="text-white"
              onClick={handleMultipleDeleted}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Deleteforce
          handleDeletedforce={handleDeletedforce}
          reconfirmdelete={confirmforcemodal}
          masterName={addButtonName}
          closereconfirmdelete={closereconfirmdelete}
          referance={
            deletesucess
              ? deletedata
              : deleteMultipleSuccess
                ? deleteMultiple
                : ""
          }
          apiname={apiname}
        />

        <ViewUsage
          // handleViewUsage={handleViewUsage}
          openViewUsage={openViewUsage}
          masterName={addButtonName}
          closeViewUsage={closeViewUsageModal}
          referance={
            isSuccessViewUsage
              ? viewUsageData
              : ""
          }
          apiname={apiname}
        />

        <Deletesingle
          confirmmodal={deleteConfirmsingle}
          confirmmodalclose={confirmmodalclose}
          masterName={addButtonName}
          confirmmodalsubmit={confirmmodalsubmit}
          apiname={apiname}
        />
      </div>
    </>
  );
};

export default Table;
