import { useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tooltip } from "@mui/material";
import { Cell } from "jspdf-autotable";
import { Modal } from "react-bootstrap";
import Axiosinstance from "../../../hooks/Interceptor";

interface Data {
  selection: any
  code: any
  sheet: any
  material_garde: any
  sheet_color: any
  opretar: any
  helper: any
  shift_time: any
  machine: any
  raw_material_betch_total_kg: any

  // task_name: any



  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  // actions: any;
}


// export const createDayColumns = () => {
//   const columns = [];

//   for (let day = 1; day <= 31; day++) {
//     columns.push({
//       id: `point_close_time_${day}`,
//       Header: `${day}`,
//       accessor: `point_close_time_${day}`,
//       Cell: ({ row }:any) => row.original[`point_close_time_${day}`]
//     });
//   }

//   return columns;
// };


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },

  {
    id: `code`,
    Header: "Batch No.",
  },

  {
    id: `sheet`,
    Header: "Sheet Code & Name",
  },
  {
    id: `material_garde`,
    Header: "Material Grade",
  },
  {
    id: `sheet_color`,
    Header: "Sheet Color",
  },
  {
    id: `opretar`,
    Header: "Opretar Code & Name",
  },
  {
    id: `raw_material_betch_total_kg`,
    Header: "Raw Material Total Kg.",
  },
  // {
  //   id: 'status',
  //   Header: 'Status',


  // },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  // {
  //   id: 'updated_at',
  //   Header: `Updated At`,

  // },
  // {
  //   id: 'updated_by',
  //   Header: `Updated By`,

  // },





]



export const Columns_TFM_Transaction: any = [
  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",
  },
  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Code</div>,
    accessor: `code`,
    sticky: "left",

  },
  {
    id: 'opretar',
    Header: () => <div style={{ display: "inline" }}>Operator Code<br />& Name</div>,
    accessor: 'opretar',
    Cell: ({ row }: any) => {
      return (
        <div>{row.original.extra_response?.opretar.code} {row.original.extra_response?.opretar.surname} {row.original.extra_response?.opretar.name} {row.original.extra_response?.opretar.father_name}</div>
      )
    }
  },
  {
    id: 'helper',
    Header: () => <div style={{ display: "inline" }}>Helper Code<br />& Name</div>,
    accessor: 'helper',
    Cell: ({ row }: any) => {
      let Helper = row.original?.extra_response?.helper?.map((e: any) => {
        return `${e?.code}::${e?.surname}::${e?.name}::${e?.father_name}`
      })
      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{Helper?.join(',')}</p>} placement="top" arrow >
            <div>
              {Helper.length >= 1 ? Helper?.join(',')?.slice(0, 14)?.concat('...') : Helper?.join(',')}
            </div>
          </Tooltip>

        </>
      )
    }
  },
  {
    id: 'machine',
    Header: () => <div style={{ display: "inline" }}>Machine Code<br />& Name</div>,
    accessor: 'machine',
    Cell: ({ row }: any) => {

      return (
        <div>{row.original.extra_response?.machine.machine_code} {row.original.extra_response?.machine.particular_sector_name}</div>
      )
    }
  },
  {
    id: 'sheet',
    Header: () => <div style={{ display: "inline" }}>Sheet Code Grade<br />Name & Color</div>,
    accessor: 'sheet',
    Cell: ({ row }: any) => {

      return (
        <div>{row.original.extra_response?.sheet.code}::
          {row.original.extra_response?.sheet.grade ? row.original.extra_response?.sheet.grade : <span style={{ color: 'red' }}>****</span>}::
          {row.original.extra_response?.sheet.name ? row.original.extra_response?.sheet.name : <span style={{ color: 'red' }}>****</span>}::
          {row.original.extra_response?.sheet.color ? row.original.extra_response?.sheet.color : <span style={{ color: 'red' }}>****</span>}
        </div>
      )
    }
  },

  {
    id: 'mould_code_and_diameter',
    Header: () => <div style={{ display: "inline" }}>Mould Code<br />& Diameter</div>,
    accessor: 'mould_code_and_diameter',
    Cell: ({ row }: any) => {
      
      return (
        <div>{row.original?.extra_response?.mould_code_and_diameter?.code}</div>
      )
    }
  },
  {
    id: 'cavity',
    Header: () => <div style={{ display: "inline" }}>Cavity Code<br />& Name</div>,
    accessor: 'cavity',
    Cell: ({ row }: any) => {
      
      return (
        <div>{row.original?.extra_response?.cavity?.code}</div>
      )
    }
  },
  {
    id: 'shift_time',
    Header: () => <div style={{ display: "inline" }}>Shift</div>,
    accessor: 'shift_time',
    Cell: ({ row }: any) => {
      
      const convertToUpper = row.original.shift_time
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },

  {
    id: `tfm_batch_detail`,
    Header: () => <div style={{ display: "inline" }}>TFM <br/>Batch Detail</div>,
    accessor: 'tfm_batch_detail',
    Cell: ({ row }: any) => {
      const [openProducts, setOpenProducts] = useState(false)
      const [GetData, setGetData]: any = useState()
      const [GetID, setGetID]: any = useState()
      const apiName = process.env.REACT_APP_PRODUCTION_TFM_BATCH_DETAIL_API?.toString()
      useEffect(() => {
        if (openProducts === true) {
          if (row.original?.tfm_batch_detail?.length > 0) {
            const getCall = async () => {
              try {
                const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiName}/fetch_multiple_ids/`, {
                  ids: row.original?.tfm_batch_detail
                });
                setGetID(response.data?.data?.map((e: any) => e?.id))
                setGetData(response.data?.data)

              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
          }
        }
      }, [row, openProducts])
      return (<>

        <div>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mx-3" disabled={row.original?.tfm_batch_detail?.length === 0}
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", fontSize:"10px" }}

            onClick={() => setOpenProducts(true)}
          >Batch</button>
        </div>
        <Modal size="xl" show={openProducts} onHide={() => setOpenProducts(false)} aria-labelledby="contained-modal-title-vcenter"
          centered    >
          <Modal.Header >
            <Modal.Title>
              Products
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="table-responsive"  >



              <table className="table table-striped table-bordered" style={{ marginTop: "3px", width: "-webkit-fill-available" }}>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th >Sr. <br /> No.</th>
                    <th>Batch <br />No.</th>
                    <th>Product Code <br /> & Name & Type</th>
                    <th>Roll<br />Width</th>
                    <th>Thickness</th>
                    <th>Roll<br />Meter</th>
                    <th>Roll<br />Weight(Kg.)</th>
                    <th>Product 1<br />Pcs Weight</th>
                    <th>Sheet Roll<br />Yes-No</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {GetData?.map((field: any, index: any) => (
                    <tr key={index} >
                      <td>{index + 1}</td>
                      <td>{field.extra_response?.roll_batch_no?.batch_code}</td>
                      <td>{field.extra_response?.product?.product_code}</td>
                      <td>{field.roll_width}</td>
                      <td>{field.thickness}</td>
                      <td>{field.roll_meter}</td>
                      <td>{field.roll_weight}</td>
                      <td>{field.product_1pcs_weight}</td>
                      <td>{field.sheet_roll?.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")}</td>
                      <td>{field.created_at?.split(' ')[1]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>


          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => setOpenProducts(false)}>Close</button>

          </Modal.Footer>
        </Modal>
      </>
      )
    }

  },



  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Actions',
    Header: 'Actions',
  },

  // {
  //   id: 'duplicate_granual',
  //   Header: 'Duplicate',


  // },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_TFM_Transaction, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}checklist/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
    }} />
  },
]

