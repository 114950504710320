import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import { useFetchDiscountProductDropDown, useFetchResponsiblePerson } from "../../../hooks/DropDown_Api";


const Edit_Discount = () => {
    let pageName = "Discount"


    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_DISCOUNT_API?.toString()
    const { data } = useFetchSingle(id,apiname)
    const { mutate, isSuccess, isError,error,data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }:any = useForm();

   const handleReset=()=>{
    reset()
   }

  
    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedApprover(data?.approver?.id)
            setValue('approver',data?.approver?.id)
            setSelectedOptionalApprover(data?.optional_approver?.id)
            setValue('optional_approver',data?.optional_approver?.id)

        }
    }, [data]);


    const [optionsApprover,setOptionsApprover] = useState([])
    const [ValueApprover,setValueApprover] = useState(null)
    const [ValueOptionalApprover,setValueOptionalApprover] = useState(null)
    const [selectedApprover,setSelectedApprover] = useState('')
    const [selectedOptionalApprover,setSelectedOptionalApprover] = useState('')

    const {data:userData,refetch:userRefetch} = useFetchResponsiblePerson() 

    useEffect(()=>{
        setOptionsApprover(userData)
    },[userData])


    const onSubmit = (materialtypeDetails: any) => {
      

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
             
        }
        let data = {
          apiname: apiname,
          id:id,
          data: formData
      }
      if(!discountError){
        mutate(data)
      }
        
       
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listDiscount', { state: { showEditToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error,isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleChangeApprover = (e:any)=>{
        setValueApprover(e)
        setValue('approver',e?.value)
    }

    const handleChangeOptionalApprover = (e:any)=>{
        setValueOptionalApprover(e)
        setValue('optional_approver',e?.value)
    }


    const [fromDiscount,setFromDiscount] = useState('')
    const [toDiscount,setToDiscount] = useState('')
    const [discountMessage,setDiscountMessage] = useState('')
    const [discountError,setDiscountError] = useState(false)


    const handleFromDiscount=(e:any)=>{
        setFromDiscount(e.target.value)
    }
    const handleToDiscount=(e:any)=>{
        setToDiscount(e.target.value)
    }


    useEffect(()=>{
        if(fromDiscount && toDiscount){
            if(fromDiscount > toDiscount){
                setDiscountMessage('It Should be less than To discount')
                setDiscountError(true)
            }else{
                setDiscountError(false)
            }
        }
    },[fromDiscount,toDiscount,discountMessage,discountError])


    let apinameForNewProduct = `${process.env.REACT_APP_PRICELIST_API}/search_filter`

//   const { mutate: productData, data: dataOfProduct } = useCreate(apinameForNewProduct)


//   const [idForDiscount,setIdForDiscount] = useState('')

//   useEffect(()=>{
//     if(data){
//         setIdForDiscount(data?.instanceId)

//         axios.get('http://127.0.0.1:8000/api/web3/')
//             .then(response => {
              
//             })
//             .catch(error => {
//               console.error(error);
//             });
//     }
//       },[data])

     

 

 

   
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                             onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                       
                                                <div className="col-sm-4 mt-2">
                                                    <label >From</label>
                                                    <div className="input-group">
                                                    <input type="number" className="form-control"  placeholder="From" {...register("from_discount", { onChange:handleFromDiscount,required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.from_discount?.message}</span>
                                                    {discountError?<span className='text-red w-100'>{discountMessage}</span>:""}
                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <label >To</label>
                                                    <div className="input-group">
                                                    <input type="number" className="form-control"  placeholder="TO" {...register("to_discount", { onChange:handleToDiscount,required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.to_discount?.message}</span>
                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <label >Approver<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                            {...register("approver", { required: { value: true, message: "Approver is required" } })}
                                                            onChange={handleChangeApprover}
                                                            options={optionsApprover}
                                                            value={ValueApprover===null?optionsApprover?.filter((e:any)=>e?.value === selectedApprover):ValueApprover}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    {/* </div> */}
                                                    <span className='text-red w-100'>{errors?.approver?.message}</span>
                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <label >Optional Approver<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                            {...register("optional_approver", { required: { value: false, message: "Sales Inquiry Code is required" } })}
                                                            onChange={handleChangeOptionalApprover}
                                                            options={optionsApprover}
                                                            value={ValueOptionalApprover===null?optionsApprover?.filter((e:any)=>e?.value === selectedOptionalApprover):ValueOptionalApprover}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    {/* </div> */}
                                                    {/* <span className='text-red w-100'>{errors?.optional_approver?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                                    <label >Status</label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                                   
                                            
                                                
                                               
                                                

                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleSubmit(onSubmit)}
                                                >Submit</button>

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDiscount")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Edit_Discount