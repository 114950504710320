
import { useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Tooltip } from "@mui/material";

interface Data {


  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  actions: any;

}

export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr.No`,
  },
  {
    id: `code`,
    Header: `Sheet Code`,
  },



  {
    id: 'status',
    Header: 'Status',
  },

  {
    id: 'created_at',
    Header: 'Created At'
  },

  {
    id: 'created_by',
    Header: 'Created By'
  },
  {
    id: 'updated_at',
    Header: 'Updated At'
  },
  {
    id: 'updated_by',
    Header: 'Updated By'
  },

  {
    id: 'Actions',
    Header: 'Actions',
  },
  {
    id: 'Selection',
    Header: 'Selection',
  },
]




export const Columns_Sheet_Code: any = [



  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",
  },
  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Code</div>,
    accessor: `code`,
    sticky: "left",

  },
  {
    id: `sheet_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Grade Name</div>,
    accessor: `sheet_grade_name`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.material_type_name} {row.original.extra_response?.mould_name_and_type?.particular_sector_name} {row.original.extra_response?.mould_name_and_type?.product_categories}
        </div>
      )
    }

  },
  {
    id: `particular_name`,
    Header: () => <div style={{ display: "inline" }}>Particular<br />Name</div>,
    accessor: `particular_name`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.particular_name?.name}
        </div>
      )
    }

  },

  {
    id: `mould_name_and_type`,
    Header: () => <div style={{ display: "inline" }}>Mould Name & Type &<br />Category & HSN & GST % </div>,
    accessor: 'mould_name_and_type',
    Cell: ({ row }: any) => {
      const Material: any = `${row.original?.extra_response?.mould_name_and_type?.material_type_name}::${row.original?.extra_response?.mould_name_and_type?.particular_sector_name ? row.original?.extra_response?.mould_name_and_type?.particular_sector_name : <span style={{ color: 'red' }}>****</span>}
      ::${row.original?.extra_response?.mould_name_and_type?.product_categories ? row.original?.extra_response?.mould_name_and_type?.product_categories : <span style={{ color: 'red' }}>****</span>}
      ::${row.original?.extra_response?.mould_name_and_type?.hsn_code}
      ::${row.original?.extra_response?.mould_name_and_type?.GST}`
      return (
        <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{Material}</p>} placement="top" arrow >
            <div>
              {Material.length >= 2 ? Material?.slice(0, 19)?.concat('...') : Material}
            </div>
          </Tooltip>
      )
    }
  },

  {
    id: `gst_type`,
    Header: () => <div style={{ display: "inline" }}> GST<br />Type</div>,
    accessor: 'gst_type',
    Cell: ({ row }: any) =>
      <div>
        {row.original?.extra_response?.mould_name_and_type?.gst_type}

      </div>

  },
  {
    id: `type_name`,
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST% </div>,
    accessor: 'type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.extra_response?.mould_name_and_type?.material_type_name}::
          {row.original?.extra_response?.mould_name_and_type?.material_density === "0.000" || row.original?.extra_response?.mould_name_and_type?.material_density === null
            ? <span style={{ color: 'red' }}>0.000</span> : row.original?.extra_response?.mould_name_and_type?.material_density}::
          {row.original?.extra_response?.mould_name_and_type?.grade_sutra === null ? <span style={{ color: 'red' }}>****</span> : row.original?.extra_response?.mould_name_and_type?.grade_sutra}
          ::{row.original?.extra_response?.mould_name_and_type?.grade_sutra_india_GST}


        </div>
      )
    }


  },
  {
    id: `sheet_color_name`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Color Name</div>,
    accessor: `sheet_color_name`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.sheet_color_name?.company_color_name}
        </div>
      )
    }

  },

  {
    id: `mould_shape`,
    Header: () => <div style={{ display: "inline" }}>Use Mould Shape &<br />Shape Image &  Size mm</div>,
    accessor: `mould_shape`,
    Cell: ({ row }: any) => {
      let mould = row.original?.extra_response?.mould_shape?.map((e: any) => {
        return e?.shape_name
      })
      return (
        <div>
          {mould?.join(",")}
        </div>
      )
    }

  },

  {
    id: `use_mould_shape_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Mould<br />Shape Qty.</div>,
    accessor: `use_mould_shape_qty`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_mould_shape_qty}
        </div>
      )
    }

  },

  {
    id: `use_product_code`,
    Header: () => <div style={{ display: "inline" }}>Use Product Code & <br />Grade & Name & Type</div>,
    accessor: `use_product_code`,
    Cell: ({ row }: any) => {
      let mould = row.original?.extra_response?.use_product_code?.map((e: any) => {
        return `${e?.code}::${e?.grade}::${e?.name}::${e?.type}`
      })
      
      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{mould?.join(',')}</p>} placement="top" arrow >
            <div>
              {mould.length >=1 ? mould?.join(',')?.slice(0, 14)?.concat('...'):mould?.join(',')}
            </div>
          </Tooltip>

        </>
      )
}
    

  },

  {
    id: `use_product_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Ptoduct<br />Shape Qty.</div>,
    accessor: `use_product_qty`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_qty}
        </div>
      )
    }

  },

  //   {
  //     id: `use_product_code`,
  //     Header: () => <div style={{ display: "inline" }}>Use product Code<br />& Name & Type</div>,

  //     // Header: `Grade Name`,
  //     accessor:'use_product_code',
  //     Cell: ({ row }: any) =>
  //     <div>
  // {row.original?.use_product_code?row.original?.use_product_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

  //     </div>

  //   },


  {
    id: `sheet_roll`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Roll</div>,
    // Header: `Mould Company Name`,
    accessor: `sheet_roll`,
    Cell: ({ row }: any) => {
      return (

        <div>
          {row.original?.extra_response?.mould_name_and_type?.sheet_width}
        </div>
      )
    }
  },
  {
    id: `thickness_sheet_micron`,
    Header: () => <div style={{ display: "inline" }}>Thickness<br />Sheet Micron</div>,
    // Header: `Mould Company Name`,
    accessor: `thickness_sheet_micron`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.thickness_sheet_micron ? row.original?.thickness_sheet_micron : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
  },

  {
    id: `sheet_roll_weight`,
    Header: () => <div style={{ display: "inline" }}>Sheet Roll<br />Weight(Kg.)</div>,
    // Header: `Mould Company Name`,
    accessor: `sheet_roll_weight`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.sheet_roll_weight}
      </div>
  },
  {
    id: `roll_meter`,
    Header: () => <div style={{ display: "inline" }}>Roll<br />Meter</div>,
    // Header: `Mould Company Name`,
    accessor: `roll_meter`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.roll_meter ? row.original?.roll_meter : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
  },
  {
    id: `cutting_side_patti_kg`,
    Header: () => <div style={{ display: "inline" }}>Cutting Side<br />Patti KG</div>,
    // Header: `Mould Company Name`,
    accessor: `cutting_side_patti_kg`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.cutting_side_patti_kg}
      </div>
  },

  {
    id: `waste_sheet_kg`,
    Header: () => <div style={{ display: "inline" }}>Waste Sheet<br />KG</div>,
    // Header: `Mould Company Name`,
    accessor: `waste_sheet_kg`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.waste_sheet_kg}
      </div>
  },


  {
    id: `sheet_roll_core_id_mm`,
    Header: () => <div style={{ display: "inline" }}>Sheet Roll<br />Code Id MM</div>,
    // Header: `Mould Company Name`,
    accessor: `sheet_roll_core_id_mm`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.sheet_roll_core_id_mm?.name}
      </div>
  },


  {
    id: `roll_time_limit_maximum`,
    Header: () => <div style={{ display: "inline" }}>Roll Time<br />Limit Maximum</div>,
    // Header: `Mould Company Name`,
    accessor: `roll_time_limit_maximum`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.roll_time_limit_maximum}
      </div>
  },
  {
    id: `sheet_roll_special_running`,
    Header: () => <div style={{ display: "inline" }}>Sheet Roll<br />Special-Running</div>,
    // Header: `Mould Company Name`,
    accessor: `sheet_roll_special_running`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.sheet_roll_special_running?.name}
      </div>
  },
  {
    id: `roll_production_minimum`,
    Header: () => <div style={{ display: "inline" }}>Roll Production<br />Minimum</div>,
    // Header: `Mould Company Name`,
    accessor: `roll_production_minimum`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.roll_production_minimum}
      </div>
  },

  {
    id: `product_location`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Location</div>,
    // Header: `Mould Company Name`,
    accessor: `product_location`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.product_location?.name}
      </div>
  },


  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Grade Name</div>,
    accessor: 'material_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.material_type_name}
        </div>
      )
    }

  },
  {
    id: `product_name`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Name</div>,

    accessor: `product_name`,
    Cell: ({ row }: any) => row.original.cavity_to_product?.product_name?.name ? row.original.cavity_to_product?.product_name?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },

  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor: 'material_type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.particular_sector_name}
        </div>
      )
    }

  },

  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor: 'material_category',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.product_categories}
        </div>
      )
    }

  },


  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor: 'material_hsn',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.hsn_code}
        </div>
      )
    }

  },

  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
    accessor: 'material_gst',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor: 'product_density',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.mould_name_and_type?.material_density ? row.original.extra_response?.mould_name_and_type?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}

        </div>
      )
    }

  },


  {
    id: `remark`,
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    // Header: `Mould Company Name`,
    accessor: `remark`,
    Cell: ({ row }: any) =>

      <div>
        {row.original?.remark ? row.original?.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
  },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },
  {
    id: 'sheet_roll_code',
    Header: () => <div style={{ display: "inline" }}>Sheet Roll<br/>Code</div>,


  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },




  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Sheet_Code, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCTION_SHEET_CODE_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



