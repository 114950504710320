import React, { useEffect, useRef, useState } from "react";
// import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import InputField from "../../../components/Form Component/inputfield";
import Error from "../../../components/Form Component/error";
import { green } from "@mui/material/colors";
import useFocusOnEnter from "../../../components/focus";
import { RiPencilLine } from "react-icons/ri";
import CreatableSelect from "react-select/creatable";
import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import { Upload, UploadFile } from "antd";
import { Employee_Model } from "../../../components/Dynamic_Form_Models/Employee_Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
// import { useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import "./Multiple.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import Axiosinstance from "../../../hooks/Interceptor";
import Table from "../../../components/Dynamic_Table/Table/Table";
import { Columns_Sales_Document, titleofheaders } from "./Columns_Sales_Document"
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Sales_Modal = ({ setCurrentStep, currentStep, setForEditIds, addShowOffcancas, setAddShowOffcancas }: any) => {


    let pageName = "Document Detail"

    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [logo, setlogo]: any = useState([])
    const [logoPdf, setlogoPdf]: any = useState([])
    const [openEmployee, setOpenEmployee]: any = useState(false)

    const [File, setFile]: any = useState()
    const apiname = process.env.REACT_APP_DOCUMENT_DETAIL_API?.toString()


    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)


    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues, control }: any = useForm();

    const [documentPDF, setDocumentPDF] = useState(null)

    const onLogoChange = (e: any) => {
        setDocumentPDF(e.target.files[0].name)
    }



    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data]);

    const handleReset = () => {
        reset()
    }

    const onSubmit = (details: any) => {


        if (logo.length == 0) {
            delete details?.image
            delete details?.images
        }

        if (logoPdf.length == 0) {
            delete details?.pdf
            delete details?.pdfs
        }



        const formData = new FormData();


        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value

            if (key === "images") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('images', logo[i])
                }
                continue
            }
            if (key === "pdfs") {
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdfs', logoPdf[i])
                }
                continue
            }



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        mutate(formData);
    }











    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("person_type", e?.value)
        setValuePersonTypeCreatable(null)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // setValue("document_type", "")
            // setValue("in_print_name", "")
            // setValue("document_number", "")
            // setValue("remark", "")
            // setValue("status", "")

            reset()
            setlogo(null)
            setlogoPdf(null)
            setUploadFile([])
            setUploadFileDoc([])
            //  const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // };
            // navigate('/listAddress', { state: { showToast: true } })
        }
    }, [isSuccess])

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("person_type", personTypeData[0].value)

            }
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);







    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [forEmployeeValue, setForEmployeeValue]: any = useState("")
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        setForEmployeeValue(e)
        setValue("employee_code", e.value)
    }













    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [multipleIds, setMultipleIds]: any = useState([])


    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    return newIds;
                });


                setForEditIds((oldIds = []) => {
                    const newIds = [...oldIds, data.data.id];
                    return newIds;
                });




                
            }
        }
    }, [isSuccess, data]);

    



    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    // useEffect(()=>{
    //     if(isSuccess&& data){
    //         
    //         localStorage.setItem('Document Details',data?.data?.id)

    //         let tempId = {
    //             document:multipleIds
    //         }

    //         let tempData = {
    //             id: localStorage.getItem('Primary Id'),
    //             apiname: apinameForBasicDetails,
    //             data: tempId
    //         }
    //         BasicDetails(tempData);
    //         // BasicDetails({})
    //          const currentPath = window.location.pathname;
    // if(currentPath === '/addCustomerDetails'){
    // setCurrentStep(currentStep + 1)
    // };
    //     }
    // },[data,isSuccess])



    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    // useEffect(()=>{
    //     if(multipleIds.length>0 && !added){

    //         Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`,{
    //             ids:multipleIds
    //           })
    //       .then((response) => {
    //         setMultipleData(response?.data?.data)
    //         setAdded(false)
    //         setShowOffcancas(false)
    //         reset()

    //       })
    //       .catch((error) => {
    //         console.error('There was an error!', error);  
    //       });
    //     }
    // },[multipleIds,added])



    const [documentType, setDocumentType] = useState("")
    const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()

    const handleDocumentType = (e: any) => {


        const type = e.target.value
        if (type === "Aadhar") {
            setDocumentType("Aadhar Card")
            setRegexForDocumentNumber(/^\d{4}\s\d{4}\s\d{4}$/)
        } else if (type === "Pan") {
            setDocumentType("Pan Card")
            setRegexForDocumentNumber(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
        } else if (type === "Election") {
            setDocumentType("Election Card")
            setRegexForDocumentNumber(/^[A-Z]{3}[0-9]{7}$/)
        } else if (type === "Passport") {
            setDocumentType("Passport")
            setRegexForDocumentNumber(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/)
        } else if (type === "Driving") {
            setDocumentType("Driving License")
            setRegexForDocumentNumber(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/)
        } else if (type === "GST") {
            setDocumentType("GST Certificate")
            setRegexForDocumentNumber(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)

        }

    }



    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc] = useState<UploadFile[]>([])

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }


    const [SubmittedEmployee, setSubmittedEmployee]: any = useState(false)

    useEffect(() => {
        if (SubmittedEmployee) {

            setForEmployeeValue(employeeOptions[0])


            setValue("employee_code", employeeOptions[0]?.value)

        }

    }, [SubmittedEmployee, employeeOptions])

    const [multipleData, setMultipleData] = useState([])





    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;
    const handleNext = () => {
        let tempId = {
            address: multipleIds
        }

        let tempData = {
            id: basicId,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
    }


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    return (
        <div>
            <div className="" >
                <section className="content-header px-3 py-1">

                </section>
                <section className="">
                    <div className="">
                        <div className="">


                            {/* <div className="row d-flex  ">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                                    onClick={handleShow}

                                                                >Add Document Details</button>

                                                            </div>
                                                        </div>
                                                    </div> */}




                            <Offcanvas show={addShowOffcancas} onHide={() => setAddShowOffcancas(false)} placement={'end'} style={{ width: '178vh', zIndex: '10000' }} >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">


                                        <form
                                            ref={focus}
                                            onKeyUp={event => onEnterKey(event)}

                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">



                                                            <div className="col-sm-3 mt-2">
                                                                <label htmlFor="exampleInputEmail1">Document Name <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("document_type",
                                                                    {
                                                                        required: { value: true, message: "Type of File is required" },

                                                                    })}
                                                                    onChange={handleDocumentType}
                                                                >
                                                                    <option value="" selected>Select Any</option>
                                                                    <option value="Aadhar">Aadhar Card</option>
                                                                    <option value="Pan">Pan Card</option>
                                                                    <option value="Election">Election Id Card</option>
                                                                    <option value="Passport">Passport</option>
                                                                    <option value="Driving">Driving License</option>
                                                                    <option value="GST">GST Certificate</option>
                                                                </select>
                                                                {errors.document_type && <Error>{errors?.document_type?.message}</Error>}
                                                            </div>


                                                            <div className="col-sm-3 mt-2">


                                                                <label >Document In Print Name<span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={'Document In Print Name'}


                                                                        {...register('in_print_name', { required: { value: true, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.in_print_name?.message}</span>
                                                            </div>


                                                            <div className="col-sm-3 mt-2">


                                                                <label >Document Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={documentType ? documentType : "Document Number"}


                                                                        {...register('document_number', {
                                                                            required: 'Field is required',
                                                                            pattern: {
                                                                                value: regexForDocumentNumber,
                                                                                message: 'Please enter a valid document',
                                                                            },
                                                                        })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.document_number?.message}</span>
                                                            </div>





                                                            <div className="col-sm-3 mt-2">


                                                                <label >Re-Mark</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={'Re-Mark'}


                                                                        {...register('remark', { required: { value: false, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                            </div>

                                                            <div className="col-sm-3 mt-2">
                                                                <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status",
                                                                    {
                                                                        required: { value: true, message: " Status is required" }, maxLength: 30,

                                                                    }
                                                                )}>
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value={"active"}>Active</option>
                                                                    <option value={"inactive"}>Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>


                                                            </div>





                                                        </div>
                                                    </div>



                                                    <div className="row d-flex">
                                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document Image All</label>
                                                                    <Upload
                                                                        {...register("images")}
                                                                        accept="image/png, image/jpeg"
                                                                        listType="picture"
                                                                        onChange={FileOnChange}
                                                                        multiple={true}
                                                                        defaultFileList={uploadFile}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"images"}
                                                                    >
                                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                    </Upload>

                                                                </div>
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF All</label>
                                                                    <label style={{ cursor: "pointer" }}>
                                                                    </label>
                                                                    <Upload
                                                                        {...register("pdfs")}
                                                                        accept=".doc,.docx,.xml,.pdf"
                                                                        listType="text"
                                                                        onChange={DocOnChange}
                                                                        multiple={true}
                                                                        defaultFileList={uploadFileDoc}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"pdfs"}
                                                                    >
                                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                    </Upload>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row d-flex  ">
                                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                    <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

                                                                    <button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </div>
                </section>
            </div>
            <Employee_Model refetchForm={EmployeeRefetch} open={openEmployee} setOpen={setOpenEmployee} setSubmitted={setSubmittedEmployee} />
        </div>
    )
}

export default Add_Sales_Modal