import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { City_Model } from "../../../components/Dynamic_Form_Models/City_Model";
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCityDropDown, useFetchZonePincodeDropDown, useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
import { Employee_Model } from "../../../components/Dynamic_Form_Models/Employee_Model";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Zone = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_ZONE_API?.toString()


    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    const [openCityEmployee, setOpenCityEmployee]: any = useState(false)
    const [openAreaEmployee, setOpenAreaEmployee]: any = useState(false)
    const [openStateEmployee, setOpenStateEmployee]: any = useState(false)
    const [openCountryEmployee, setOpenCountryEmployee]: any = useState(false)
    const [openGeneralEmployee, setOpenGeneralEmployee]: any = useState(false)
    const [openOwnerEmployee, setOpenOwnerEmployee]: any = useState(false)
    const [openOwnerAllEmployee, setOpenOwnerAllEmployee]: any = useState(false)





    const handleReset = () => {
        reset()
        setForCityValue(null)
        setForSalesmanValue(null)
        setForStateManagerValue(null)
        setForAreaManagerValue(null)
        setForSalesManagerValue(null)
        setForGeneralManagerValue(null)
        setForOwnerValue(null)
        setForPincode(null)
        setForCityPincodeValue(null)
        setForOwnerAllValue(null)
        setTotalCity(null)
        setForState(null)
        setForCountry(null)
    }

    const onSubmit = (details: any) => {
        delete details.state
        delete details.country
        const formData = new FormData();
        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "city") {
                value.forEach((val, index) => formData.append(`city`, val))
            }
            else if (Array.isArray(value) && key === "pincode") {
                value.forEach((val, index) => formData.append(`pincode`, val))
            }
            else if (Array.isArray(value) && key === "salesman_employee") {
                value.forEach((val, index) => formData.append(`salesman_employee`, val))
            }
            else if (Array.isArray(value) && key === "area_manager") {
                value.forEach((val, index) => formData.append(`area_manager`, val))
            }
            else if (Array.isArray(value) && key === "state_manager") {
                value.forEach((val, index) => formData.append(`state_manager`, val))
            }
            else if (Array.isArray(value) && key === "sales_manager") {
                value.forEach((val, index) => formData.append(`sales_manager`, val))
            }
            else if (Array.isArray(value) && key === "general_manager") {
                value.forEach((val, index) => formData.append(`general_manager`, val))
            }
            else if (Array.isArray(value) && key === "sheth_employee") {
                value.forEach((val, index) => formData.append(`sheth_employee`, val))
            }













        }
        mutate(formData);
    }
    const [openCity, setOpenCity]: any = useState(false)

    const [forCityValue, setForCityValue]: any = useState()
    const [cityOptions, setCityOptions]: any = useState()
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        const city = cityData?.map((e: any) => {
            return {
                value: e.value,
                label: `${e?.city ? e?.city : e?.village}`,
            }
        })
        setCityOptions(city)
    }, [cityData])
    
    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [byCity, setByCity] = useState()
    const [forPincode, setForPincode]:any = useState("")
    const [forPincodeId, setForPincodeId] = useState([])


    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchZonePincodeDropDown(byCity)

    useEffect(() => {
        // let finalData: any = []
        // cityPincodeData?.map((e: any) => {
        //     e.label.map((x: any) => {
        //         finalData.push({ value: x.id, label: x.pincode })
        //     })

        // })
        // setCityPincodeOptions(finalData)
        let finalData: any[] = [];
        let labelsSet: Set<string> = new Set();

        cityPincodeData?.forEach((e: any) => {
            e.label.forEach((x: any) => {
                if (!labelsSet.has(x.pincode)) {
                    labelsSet.add(x.pincode);
                    finalData.push({ value: x.id, label: x.pincode });
                }
            });
        });

        setCityPincodeOptions(finalData);

    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        let pincode = e?.map((e: any) => {
            return e.value
        })
        setForCityPincodeValue(e)
        setValue("pincode", pincode)

    }


    const [forState, setForState]:any = useState("")
    const [forCountry, setForCountry]:any = useState("")

    const handleChangeCity = (e: any) => {


        let city = e?.map((e: any) => {
            return e.value
        })


        let StateAndCountry = e?.map((e: any) => {
            
            return { state: e.state, country: e.country, stateID: e.stateID, countryID: e.countryID }
        })
        setForCityValue(e)
        setByCity(city)
        // setByCity(e?.value)
        setValue("city", city)
        // setValue("city", e.value)
        setForPincode(city.length === 0 ? '' :city)
        // setForPincode(e?.label)
        setForPincodeId(e?.value)
        // setForCityPincodeValue(e?.value<=0 ? e?.value :null)
        // setValue('pincode',null)

        setTotalCity(city?.length)
        setValue("total_city", city?.length)

        setForState(StateAndCountry[0]?.state)
        setForCountry(StateAndCountry[0]?.country)
        setValue("state", StateAndCountry[0]?.stateID)
        setValue("country", StateAndCountry[0]?.countryID)
    }


    const [forSalesmanValue, setForSalesmanValue]: any = useState()
    const [forStateManagerValue, setForStateManagerValue]: any = useState()
    const [forAreaManagerValue, setForAreaManagerValue]: any = useState()

    const [forSalesManagerValue, setForSalesManagerValue]: any = useState()
    const [forGeneralManagerValue, setForGeneralManagerValue]: any = useState()
    const [forOwnerValue, setForOwnerValue]: any = useState()
    const [forOwnerAllValue, setForOwnerAllValue]: any = useState()


    const [forValue, setForValue]: any = useState()
    const [CityEmployeeOptions, setCityEmployeeOptions]: any = useState([])
    const [AreaEmployeeOptions, setAreaEmployeeOptions]: any = useState([])
    const [StateEmployeeOptions, setStateEmployeeOptions]: any = useState([])
    const [CountryEmployeeOptions, setCountryEmployeeOptions]: any = useState([])
    const [GeneralEmployeeOptions, setGeneralEmployeeOptions]: any = useState([])
    const [OwnerEmployeeOptions, setOwnerEmployeeOptions]: any = useState([])
    const [OwnerAllEmployeeOptions, setOwnerAllEmployeeOptions]: any = useState([])
    const [employeeQty, setEmployeeQty] = useState(0)


    const { data: employeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setCityEmployeeOptions(employeeData)

    }, [employeeData])
    useEffect(() => {
        setAreaEmployeeOptions(employeeData)


    }, [employeeData])
    useEffect(() => {
        setStateEmployeeOptions(employeeData)

    }, [employeeData])
    useEffect(() => {
        setCountryEmployeeOptions(employeeData)
    }, [employeeData])
    useEffect(() => {
        setGeneralEmployeeOptions(employeeData)
    }, [employeeData])
    useEffect(() => {
        setOwnerEmployeeOptions(employeeData)

    }, [employeeData])
    useEffect(() => {
        setOwnerAllEmployeeOptions(employeeData)

    }, [employeeData])


    // const handleChangeDesignation=(e:any)=>{
    //     let employees = e?.map((e:any)=>{
    //         return e.value
    //     })
    //     setEmployeeOptions(e)
    //     setValue("use_employee_code_and_surname_and_name",employees)
    //     setEmployeeQty(employees?.length)
    //     setValue("employee_qty",employees?.length)
    // }




    const handleChangeAreaManager = (e: any) => {
        //  let employees = e?.map((e:any)=>{
        //     return e.value
        // })
        setForAreaManagerValue(e)
        setValue("area_manager", e.value)

    }
    const handleChangeStateManager = (e: any) => {

        setForStateManagerValue(e)
        setValue("state_manager", e.value)

    }
    const handleChangeSalesManager = (e: any) => {

        setForSalesManagerValue(e)
        setValue("sales_manager", e.value)

    }
    const handleChangeGeneralManager = (e: any) => {

        setForGeneralManagerValue(e)
        setValue("general_manager", e.value)

    }
    const handleChangeOwner = (e: any) => {

        setForOwnerValue(e)
        setValue("sheth_employee", e.value)

    }
    const handleChangeOwnerAll = (e: any) => {

        setForOwnerAllValue(e)
        setValue("owner_all_management_code", e.value)

    }
    const handleChangeSalesman = (e: any) => {
        setForSalesmanValue(e)
        setValue("salesman_employee", e.value)

    }








    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listZone', { state: { showToast: true } })

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setForCityValue(cityOptions[0])


            setValue("city", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])

    const [SubmittedCityEmployee, setSubmittedCityEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedCityEmployee) {

            setForSalesmanValue(CityEmployeeOptions[0])


            setValue("salesman_employee", CityEmployeeOptions[0]?.value)

        }

    }, [SubmittedCityEmployee, CityEmployeeOptions])
    const [SubmittedAreaEmployee, setSubmittedAreaEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedAreaEmployee) {

            setForAreaManagerValue(AreaEmployeeOptions[0])


            setValue("area_manager", AreaEmployeeOptions[0]?.value)

        }

    }, [SubmittedAreaEmployee, AreaEmployeeOptions])
    const [SubmittedStateEmployee, setSubmittedStateEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedStateEmployee) {

            setForStateManagerValue(StateEmployeeOptions[0])


            setValue("state_manager", StateEmployeeOptions[0]?.value)

        }

    }, [SubmittedStateEmployee, StateEmployeeOptions])
    const [SubmittedCountryEmployee, setSubmittedCountryEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedCountryEmployee) {

            setForSalesManagerValue(CountryEmployeeOptions[0])


            setValue("sales_manager", CountryEmployeeOptions[0]?.value)

        }

    }, [SubmittedCountryEmployee, CountryEmployeeOptions])
    const [SubmittedGeneralEmployee, setSubmittedGeneralEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedGeneralEmployee) {

            setForGeneralManagerValue(GeneralEmployeeOptions[0])


            setValue("general_manager", GeneralEmployeeOptions[0]?.value)

        }

    }, [SubmittedGeneralEmployee, GeneralEmployeeOptions])
    const [SubmittedOwnerEmployee, setSubmittedOwnerEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedOwnerEmployee) {

            setForOwnerValue(OwnerEmployeeOptions[0])


            setValue("sheth_employee", OwnerEmployeeOptions[0]?.value)

        }

    }, [SubmittedOwnerEmployee, OwnerEmployeeOptions])
    const [SubmittedOwnerAllEmployee, setSubmittedOwnerAllEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedOwnerAllEmployee) {

            setForOwnerAllValue(OwnerAllEmployeeOptions[0])


            setValue("owner_all_management_code", OwnerAllEmployeeOptions[0]?.value)

        }

    }, [SubmittedOwnerAllEmployee, OwnerAllEmployeeOptions])


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add Zone</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listZone" className="text-decoration-none text-black">Zone</Link></li>
                                    <li className="breadcrumb-item active">Add Zone</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? (
                                        <Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone Number</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Zone Number" {...register("number", { onChange: handleValue, required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.number?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Route Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Route Name" {...register("route_name", { onChange: handleValue, required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.route_name?.message}</span>
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label >Pincode <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Route Name"  {...register("pincode", { onChange: handleValue, required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.route_name?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone City Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("city", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            isMulti
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            value={forCityValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(optionsUom: any) => {
                                                            //     if (optionsUom.status === "inactive" || optionsUom.deleted == true) {
                                                            //         return optionsUom
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city?.message}</span>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Zone City Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city", { required: { value: true, message: "Zone City Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            value={forCityValue}
                                                            components={customIndicator}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Zone City Pincode <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        isClearable={() => {
                                                            setForCityPincodeValue(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("pincode", { required: { value: true, message: "Zone City Pincode is required" } })}
                                                        isMulti
                                                        options={cityPincodeOptions}
                                                        onChange={handleChangePincode}
                                                        value={forCityPincodeValue}
                                                        isDisabled={!forPincode}
                                                        components={customIndicator}

                                                    />
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone Total City </label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" value={totalCity} placeholder="Zone Total City" {...register("total_city", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.total_city?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >State Name </label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" value={forState} placeholder="State Name" {...register("state", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Country Name</label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" value={forCountry} placeholder="Country Name" {...register("country", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >City Sales Executive Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("salesman_employee", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            value={forSalesmanValue}
                                                            onChange={handleChangeSalesman}
                                                            options={CityEmployeeOptions}
                                                            components={customIndicator}

                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCityEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.salesman_employee?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Area Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("area_manager", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeAreaManager}
                                                            options={AreaEmployeeOptions}
                                                            value={forAreaManagerValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenAreaEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.area_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >State Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("state_manager", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeStateManager}
                                                            options={StateEmployeeOptions}
                                                            value={forStateManagerValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenStateEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Country Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("sales_manager", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeSalesManager}
                                                            options={CountryEmployeeOptions}
                                                            value={forSalesManagerValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCountryEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sales_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >General Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("general_manager", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeGeneralManager}
                                                            options={GeneralEmployeeOptions}
                                                            value={forGeneralManagerValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenGeneralEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.general_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Owner Sales Department Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("sheth_employee", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeOwner}
                                                            options={OwnerEmployeeOptions}
                                                            value={forOwnerValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenOwnerEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheth_employee?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Owner All Management Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("owner_all_management_code", {
                                                                required: {
                                                                    value: true,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            onChange={handleChangeOwnerAll}
                                                            options={OwnerAllEmployeeOptions}
                                                            value={forOwnerAllValue}
                                                            components={customIndicator}
                                                            //     isOptionDisabled={(employeeOptions: any) => {
                                                            //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                            //         return employeeOptions
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenOwnerAllEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.owner_all_management_code?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label >Responsible Person</label>
                                                    <select className="form-select" {...register("seven", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Hemal</option>
                                                        <option value="inactive">Mann</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.seven?.message}</span>

                                                </div>
                                             */}



                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listZone")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openCityEmployee} setOpen={setOpenCityEmployee} setSubmitted={setSubmittedCityEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openAreaEmployee} setOpen={setOpenAreaEmployee} setSubmitted={setSubmittedAreaEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openStateEmployee} setOpen={setOpenStateEmployee} setSubmitted={setSubmittedStateEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openCountryEmployee} setOpen={setOpenCountryEmployee} setSubmitted={setSubmittedCountryEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openGeneralEmployee} setOpen={setOpenGeneralEmployee} setSubmitted={setSubmittedGeneralEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openOwnerEmployee} setOpen={setOpenOwnerEmployee} setSubmitted={setSubmittedOwnerEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openOwnerAllEmployee} setOpen={setOpenOwnerAllEmployee} setSubmitted={setSubmittedOwnerAllEmployee} />
        </div>
    )
}

export default Add_Zone