import { useEffect, useRef, useState } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useCreate } from "../../hooks/Api";
import { useFetchCavityNameDropDown, useFetchHSNMaterialNameDropDown, useFetchMaterialUseDropDown, useFetchMouldDropDown, useFetchMouldShapeDropDown, useFetchProductNameDropDown } from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import { Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import useFocusOnEnter from "../../components/focus";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { Mould_Model } from "../../components/Dynamic_Form_Models/Mould";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Cavity = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions]: any = useState([])
    const [optionsProduct, setOptionsProduct]: any = useState([])

    const [searchTerm, setSearchTerm] = useState("")
    const [logo, setlogo] = useState([])
    const [logoPdf, setlogoPdf] = useState([])

    const [productImage, setProductImage] = useState([])
    const [productDrawing, setProductDrawing] = useState([])
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [clear, setClear] = useState(null)

    const [optionsCavity, setOptionsCavity]: any = useState([]);
    const [valueCavity, setValueCavity]: any = useState();
    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();
    const apinameForCavity = process.env.REACT_APP_CAVITY_NAME_API?.toString()
    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)

    const apiname = process.env.REACT_APP_CAVITY_API?.toString()



    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)





    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();

    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const [openMaterialName, setOpenMaterialName] = useState(false)
    const [optionsMaterialName, setOptionsMaterialName]: any = useState([])
    const [MaterialNameValue, setMaterialNameValue]: any = useState('')

    const { data: MaterialNameData, refetch: MaterialNameRefetch } = useFetchHSNMaterialNameDropDown()

    useEffect(() => {
        setOptionsMaterialName(MaterialNameData)
    }, [MaterialNameData])




    const handleChangeMaterialName = (e: any) => {
        setMaterialNameValue(e)
        setValue("materialname_type_category_hsn_gst", `${e?.value}`)
        setMaterialNameValue({ label: e?.label, value: e?.value })

    }



    const { data: cavityNameData, refetch: cavityNameRefetch } = useFetchCavityNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavity(cavityNameData)
    }, [cavityNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            cavityNameRefetch()
        }
    }, [])


    const handleChangeCavityName = (e: any) => {

        cavityNameRefetch()

        setApiName(apinameForCavity)
        setDeleteApiName(apinameForCavity)
        setEditId(e?.value)
        setEditData(cavityNameData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCavity(e)

        setValue("name", `${e?.value}`)
        setValueCavityCreatable(null)
    }

    const handleCreate = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavity({ name: newOption.name, status: newOption.status })


            setOptionsCavity((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsCavity) {
                await cavityNameRefetch();
            }
            setValue("name", cavityNameData[0].value)
        })();

        return () => {
        };
    }, [cavitySuccess, cavityNameData]);

    const [valueProduct, setValueProduct]: any = useState();
    const [valueProductCreatable, setValueProductCreatable] = useState<Option | null>();
    const apinameForProduct = process.env.REACT_APP_PRODUCT_NAME_API?.toString()

    const { mutate: mutateProduct, data: dataProduct, isError: ProductIsError, isLoading: ProductLoading, isSuccess: ProductSuccess, error: ProductError }: any = useCreate(apinameForProduct)
    const { data: productNameData, refetch: productNameRefetch } = useFetchProductNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsProduct(productNameData)
    }, [productNameData])


    const handleChangeProduct = (e: any) => {

        productNameRefetch()

        setApiName(apinameForProduct)
        setDeleteApiName(apinameForProduct)
        setEditId(e?.value)
        setEditData(productNameData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProduct(e)


        setValue("product_name", `${e?.value}`)
        setValueProductCreatable(null)
    }

    const handleCreateProduct = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateProduct({ name: newOption.name, status: newOption.status })


            setOptionsProduct((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueProductCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (ProductSuccess && optionsProduct) {
                await productNameRefetch();
            }
            setValue("product_name", productNameData[0].value)
        })();

        return () => {
        };
    }, [ProductSuccess, optionsProduct]);

    const [optionsCavityType, setOptionsCavityType]: any = useState([])

    const [valueCavityType, setValueCavityType]: any = useState();
    const [valueCavityTypeCreatable, setValueCavityTypeCreatable] = useState<Option | null>();
    const apinameForCavityType = process.env.REACT_APP_MATERIAL_USE_API?.toString()

    const { mutate: mutateCavityType, data: dataCavityType, isError: CavityTypeIsError, isLoading: CavityTypeLoading, isSuccess: CavityTypeSuccess, error: CavityTypeError }: any = useCreate(apinameForCavityType)
    const { data: CavityTypeData, refetch: CavityTypeRefetch } = useFetchMaterialUseDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavityType(CavityTypeData)
    }, [CavityTypeData])


    const handleChangeCavityType = (e: any) => {

        CavityTypeRefetch()

        setApiName(apinameForCavityType)
        setDeleteApiName(apinameForCavityType)
        setEditId(e?.value)
        setEditData(CavityTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCavityType(e)


        setValue("type", `${e?.value}`)
        setValueCavityTypeCreatable(null)
    }

    const handleCreateCavityType = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavityType({ name: newOption.name, status: newOption.status })


            setOptionsCavityType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityTypeCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (CavityTypeSuccess && optionsCavityType) {
                await CavityTypeRefetch();
                setValue("type", CavityTypeData[0].value)
            }
        })();

        return () => {
        };
    }, [CavityTypeSuccess, optionsCavityType]);


    const [optionsCavityShape, setOptionsCavityShape]: any = useState([]);

    const [valueCavityShape, setValueCavityShape]: any = useState();


    const [valueCavityShapeCreatable, setValueCavityShapeCreatable] = useState<Option | null>();



    const apinameForCavityShape = process.env.REACT_APP_MOULD_SHAPE_API?.toString()

    const { mutate: mutateCavityShape, data: dataCavityShape, isError: cavityShapeIsError, isLoading: cavityShapeLoading, isSuccess: cavityShapeSuccess, error: cavityShapeError }: any = useCreate(apinameForCavityShape)

    const { data: CavityShapeData, refetch: CavityShapeRefetch } = useFetchMouldShapeDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavityShape(CavityShapeData)
    }, [CavityShapeData])

    const handleChangeCavityShape = (e: any) => {
        CavityShapeRefetch()

        setApiName(apinameForCavityShape)
        setDeleteApiName(apinameForCavityShape)
        setEditId(e?.value)
        setEditData(CavityShapeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCavityShape(e)

        setValue("cavity_shape", `${e?.value}`)
        setValueCavityShapeCreatable(null)
    }


    const handleCreateCavityShape = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavityShape({ name: newOption.name, status: newOption.status })


            setOptionsCavityShape((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityShapeCreatable(newOption);


        }, 0);

    };

    useEffect(() => {
        (async () => {
            if (cavityShapeSuccess && optionsCavityShape) {
                await CavityShapeRefetch();
            }
            setValue("cavity_shape", CavityShapeData[0].value)
        })();

        return () => {
        };
    }, [cavityShapeSuccess, optionsCavityShape]);


    const { data: colorData, refetch: colorRefetch } = useFetchMouldDropDown()

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])

    const handleChange = (e: any) => {
        setMouldUsageValue(e)
        setValue("mould_usage", `${e?.value}`)
        setMouldUsageValue({ label: e?.label, value: e?.value })

    }


    // const [cavityNameValue,setCavityNameValue]:any = useState()
    const [mouldUsageValue, setMouldUsageValue]: any = useState()


    const handleReset = () => {
        reset()
        setValue(null)
        setValueCavityCreatable(null)
        setMouldUsageValue(null)
        setValueProduct(null)
        setValueCavity(null)
        setMaterialNameValue(null)
        setValueCavityShapeCreatable(null)
        setValueCavityShape(null)
        setValueCavityTypeCreatable(null)
        setValueCavityType(null)
        setUploadFileDoc([])
        setUploadFile([])
        setUploadProductImage([])
        setUploadProductDrawing([])

    }

    const onSubmit = (cavitydetail: any) => {
        if (logo.length == 0) {
            delete cavitydetail.image
        }
        if (logoPdf.length == 0) {
            delete cavitydetail.pdf
        }
        if (productImage.length == 0) {
            delete cavitydetail.product_image
        }
        if (productDrawing.length == 0) {
            delete cavitydetail.product_pdf
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(cavitydetail)) {
            const formvalue: any = value
            // if (typeof value !== "object") {
            //     formData.append(key, formvalue)
            // }

            if (key === "image") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('image', logo[i])
                }
                continue
            }
            if (key === "pdf") {
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdf', logoPdf[i])
                }
                continue
            }
            if (key === "product_image") {
                for (let i = 0; i < productImage.length; i++) {

                    formData.append('product_image', productImage[i])
                }
                continue
            }
            if (key === "product_pdf") {
                for (let i = 0; i < productDrawing.length; i++) {

                    formData.append('product_pdf', productDrawing[i])
                }
                continue
            }
            if (typeof value !== "object") {
                if (value != undefined) {
                    formData.append(key, formvalue)
                }
            }
            if (key === 'height') {
                let decimalNumber = Number(valueHeight);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('height', formattedString)
            }
            if (key === 'cavity_size_mm') {
                let decimalNumber = Number(valueSize);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_size_mm', formattedString)
            }
            if (key === 'cavity_length_mm') {
                let decimalNumber = Number(valueLength);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_length_mm', formattedString)
            }
            if (key === 'cavity_width_mm') {
                let decimalNumber = Number(valueWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cavity_width_mm', formattedString)
            }

        }
        mutate(formData);


    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listCavity', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [valueSize, setValueSize] = useState('')
    const [valueHeight, setValueHeight] = useState('')
    const [valueLength, setValueLength] = useState('')
    const [valueWidth, setValueWidth] = useState('')



    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc] = useState<UploadFile[]>([])

    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])
    const [uploadProductDrawing, setUploadProductDrawing] = useState<UploadFile[]>([])

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const ProductOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const ProductDrawingOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductDrawing(tempFiles)
        setUploadProductDrawing(e.fileList)

    }
    const [forValue, setForValue]: any = useState([])


    useEffect(() => {
        if (submitted) {
            setMouldUsageValue(options[0])
            setValue("mould_usage", options[0]?.value)


        }

    }, [submitted, options])

const [SubmittedMaterialName,setSubmittedMaterialName]:any = useState('')
    useEffect(() => {
        if (SubmittedMaterialName) {
            setMaterialNameValue(optionsMaterialName[0])
            setValue("materialname_type_category_hsn_gst", optionsMaterialName[0]?.value)


        }

    }, [SubmittedMaterialName, optionsMaterialName])

    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {

        if (apiName === apinameForCavity) {
            cavityNameRefetch()
        }
        if (apiName === apinameForProduct) {
            productNameRefetch()
        }
        if (apiName === apinameForCavityShape) {
            CavityShapeRefetch()
        }
        if (apiName === apinameForCavityType) {
            CavityTypeRefetch()
        }

        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueCavity && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }
        if (OpenEditDropDownModel && valueProduct && apiName === apinameForProduct) {
            setValueProduct(null)
            setValueProductCreatable(null)
        }
        if (OpenEditDropDownModel && valueCavityShape && apiName === apinameForCavityShape) {
            setValueCavityShape(null)
            setValueCavityShapeCreatable(null)
        }
        if (OpenEditDropDownModel && valueCavityType && apiName === apinameForCavityType) {
            setValueCavityType(null)
            setValueCavityTypeCreatable(null)
        }







        if (deletesucess && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }
        if (deletesucess && apiName === apinameForProduct) {
            setValueProduct(null)
            setValueProductCreatable(null)
        }
        if (deletesucess && apiName === apinameForCavityShape) {
            setValueCavityShape(null)
            setValueCavityShapeCreatable(null)
        }
        if (deletesucess && apiName === apinameForCavityType) {
            setValueCavityType(null)
            setValueCavityTypeCreatable(null)
        }



    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



            {label !== 'Square' && label !== 'Round' && label !== 'Triangle' && label !== 'Rectangles' ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Product Cavity</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listCavity" className="text-decoration-none text-black">Product Cavity</Link></li>
                                    <li className="breadcrumb-item active">Add Product Cavity</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("name", { required: { value: true, message: "Cavity Name is required" } })}
                                                        isClearable={() => {
                                                            setValueCavity(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Name'}
                                                        options={optionsCavity}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeCavityName}
                                                        components={customCreatableIndicator}
                                                        value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => cavityNameRefetch()}

                                                    />
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProduct(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_name", { required: { value: true, message: "Product Name is required" } })}
                                                        placeholder={'Select Product Name'}
                                                        options={optionsProduct}
                                                        onCreateOption={handleCreateProduct}
                                                        onChange={handleChangeProduct}
                                                        value={valueProductCreatable == null ? valueProduct : valueProductCreatable}
                                                        components={customCreatableIndicator}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => productNameRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN Code & GST % <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("materialname_type_category_hsn_gst")}
                                                            placeholder={'Select Mould Usage'}
                                                            options={optionsMaterialName}
                                                            onChange={handleChangeMaterialName}
                                                            components={customIndicator}
                                                            value={MaterialNameValue}
                                                            // defaultMenuIsOpen={options.find((obj: any) => obj.value === selected)}
                                                            className="dropdown-select"
                                                            maxMenuHeight={130}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMaterialName(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name & Type</label>
                                                    <Select
                                                        {...register("", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={}
                                                        onChange={}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Qty</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>

                                                <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Single Code</label>
                                                    <Select
                                                        {...register("mould_usage", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={options}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div> 
                                                
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Single Code</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>
                                                
                                                */}


                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Mould Code & Diameter & Cavity Qty <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select

                                                            {...register("mould_usage", { onChange: handleUpper, required: { value: true, message: "Mould Usage is required" } })}
                                                            placeholder={'Select Mould Usage'}
                                                            options={options}
                                                            onChange={handleChange}
                                                            value={mouldUsageValue}
                                                            components={customIndicator}
                                                            className="dropdown-select"
                                                            maxMenuHeight={130}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Stock Pcs <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Stock Pcs" {...register("cavity_stock_pcs", { required: { value: true, message: "Cavity Stock Pcs is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_stock_pcs?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Shapes <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("cavity_shape", { required: { value: true, message: "Cavity Shapes is required" } })}
                                                        isClearable={() => {
                                                            setValueCavityShape(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Shapes'}
                                                        options={optionsCavityShape}
                                                        onCreateOption={handleCreateCavityShape}
                                                        onChange={handleChangeCavityShape}
                                                        components={customCreatableIndicator}
                                                        value={valueCavityShapeCreatable == null ? valueCavityShape : valueCavityShapeCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => CavityShapeRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.cavity_shape?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Size MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Size MM"
                                                            {...register("cavity_size_mm", { onChange: ((e: any) => setValueSize(e.target.value)), required: { value: valueCavityShape?.mouldA === "false" ? true : false, message: "Cavity Size MM is required" } })}
                                                            disabled={(valueCavityShape?.mouldA ? false : true)}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_size_mm?.message}</span>
                                                </div>






                                                {/* {(valueCavityShape?.mouldC ? <> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Width MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Width MM"
                                                            disabled={(valueCavityShape?.mouldC ? false : true)}
                                                            {...register("cavity_width_mm", { onChange: ((e: any) => setValueWidth(e.target.value)), required: { value: valueCavityShape?.mouldC === "false" ? true : false, message: "Cavity Width MM is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_width_mm?.message}</span>
                                                </div>
                                                {/* </>
                                                    : "")} */}

                                                {/* {(valueCavityShape?.mouldB ? <> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Length MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            disabled={(valueCavityShape?.mouldB ? false : true)} placeholder="Cavity Length MM"
                                                            {...register("cavity_length_mm", { onChange: ((e: any) => setValueLength(e.target.value)), required: { value: valueCavityShape?.mouldB === "false" ? true : false, message: "Cavity Length MM is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_length_mm?.message}</span>
                                                </div>
                                                {/* </>
                                                    : "")} */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Height MM <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height MM" {...register("height", { onChange: ((e: any) => setValueHeight(e.target.value)), required: { value: true, message: "Cavity Height is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Type <span style={{ color: "red" }}>*</span></label>
                                                    {/* <select className="form-select"  {...register("type", { required: { value: true, message: "Cavity Type is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="special" >Special</option>
                                                        <option value="running">Running</option>
                                                        <option value="full_running">Full Running</option>
                                                    </select> */}

                                                    <CreatableSelect
                                                        {...register("type", { required: { value: true, message: "Cavity Typeis required" } })}
                                                        isClearable={() => {
                                                            setValueCavityType(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Type'}
                                                        options={optionsCavityType}
                                                        onCreateOption={handleCreateCavityType}
                                                        onChange={handleChangeCavityType}
                                                        components={customCreatableIndicator}
                                                        value={valueCavityTypeCreatable == null ? valueCavityType : valueCavityTypeCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => CavityTypeRefetch()}

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity Image All</label>
                                                    <Upload
                                                        {...register("image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={FileOnChange}
                                                        multiple={true}
                                                        fileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity PDF Drawing All</label>
                                                    <Upload
                                                        {...register("pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                      fileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>
                                                    <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>
                                                    <Upload
                                                        {...register("product_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ProductDrawingOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductDrawing}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCavity")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            {/* <Material_Density_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted}/> */}
            <HSN_Model refetchForm={MaterialNameRefetch} open={openMaterialName} setOpen={setOpenMaterialName} setSubmitted={setSubmittedMaterialName} />
            <Mould_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Add_Cavity

