import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchCavityNameDropDown, useFetchCavityShapeDropDown, useFetchMouldDropDown, useFetchProductNameDropDown, useFetchMouldShapeDropDown, useFetchMaterialUseDropDown, useFetchHSNMaterialNameDropDown } from "../../hooks/DropDown_Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { Upload, UploadFile } from "antd";
import { Mould_Model } from "../../components/Dynamic_Form_Models/Mould";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import Axiosinstance from "../../hooks/Interceptor";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}



interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const Edit_Cavity = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const focus: any = useRef();
    const [open, setOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { onEnterKey } = useFocusOnEnter(focus);
    const [optionsCavity, setOptionsCavity]: any = useState([]);
    const [cavityImage, setCavityImage] = useState([])
    const [cavityDrawing, setCavityDrawing] = useState([])
    const [productImage, setProductImage] = useState([])
    const [productDrawing, setProductDrawing] = useState([])
    const [options, setOptions]: any = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [selected, setSelected] = useState("")
    const [sel, setSel] = useState("")
    const apiname = process.env.REACT_APP_CAVITY_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const [selCavity, setSelCavity] = useState('')
    const [selectedCavity, setSelectedCavity] = useState('')
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const { mutate, isSuccess, isError, error, data: cavityUpdated_response, isLoading }: any = useUpdateSingle()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const [valueCavity, setValueCavity]: any = useState();


    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();



    const apinameForCavity = process.env.REACT_APP_CAVITY_NAME_API?.toString()

    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)

    const [isLoadingValue, setIsLoadingValue] = useState(false);


    const { data: cavityNameData, refetch: cavityNameRefetch } = useFetchCavityNameDropDown()
    useEffect(() => {
        setOptionsCavity(cavityNameData)
    }, [cavityNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            cavityNameRefetch()
        }
    }, [])

    const handleChangeCavityName = (e: any) => {
        cavityNameRefetch()

        setApiName(apinameForCavity)
        setDeleteApiName(apinameForCavity)
        setEditId(e?.value)
        setEditData(cavityNameData)
        setRenderKey(prevKey => prevKey + 1);



        setValue("name", `${e?.value}`)
        setSelCavity(e)
        setValueCavity(e)
    }
    const handleCreate = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavity({ name: newOption.name, status: newOption.status })


            setOptionsCavity((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsCavity) {
                await cavityNameRefetch();
            }
            setValue("name", cavityNameData[0].value)
        })();

        return () => {
        };
    }, [cavitySuccess, cavityNameData]);


    const [openMaterialName, setOpenMaterialName] = useState(false)
    const [optionsMaterialName, setOptionsMaterialName]: any = useState([])
    const [MaterialNameValue, setMaterialNameValue]: any = useState('')
    const [selMaterialName,setSelMaterialName]:any = useState('')
    const [selectedMaterialName,setSelectedMaterialName]:any = useState('')

    const { data: MaterialNameData, refetch: MaterialNameRefetch } = useFetchHSNMaterialNameDropDown()

    useEffect(() => {
        setOptionsMaterialName(MaterialNameData)
    }, [MaterialNameData])




    const handleChangeMaterialName = (e: any) => {
        setMaterialNameValue(e)
        setSelMaterialName(e)
        setValue("materialname_type_category_hsn_gst", `${e?.value}`)
        setMaterialNameValue({ label: e?.label, value: e?.value })

    }



    const [valueProduct, setValueProduct]: any = useState();
    const [valueProductCreatable, setValueProductCreatable] = useState<Option | null>();
    const apinameForProduct = process.env.REACT_APP_PRODUCT_NAME_API?.toString()
    const [optionsProduct, setOptionsProduct]: any = useState([])
    const [selectedProductName, setSelectedProductName] = useState()

    const { mutate: mutateProduct, data: dataProduct, isError: ProductIsError, isLoading: ProductLoading, isSuccess: ProductSuccess, error: ProductError }: any = useCreate(apinameForProduct)
    const { data: productNameData, refetch: productNameRefetch } = useFetchProductNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsProduct(productNameData)
    }, [productNameData])


    const handleChangeProduct = (e: any) => {
        productNameRefetch()

        setApiName(apinameForProduct)
        setDeleteApiName(apinameForProduct)
        setEditId(e?.value)
        setEditData(productNameData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProduct(e)

        setValue("product_name", `${e?.value}`)
        setValueProductCreatable(null)
    }

    const handleCreateProduct = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateProduct({ name: newOption.name, status: newOption.status })


            setOptionsProduct((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueProductCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (ProductSuccess && optionsProduct) {
                await productNameRefetch();
            }
            setValue("product_name", productNameData[0].value)
        })();

        return () => {
        };
    }, [ProductSuccess, optionsProduct]);


    const [optionsCavityShape, setOptionsCavityShape]: any = useState([]);

    const [valueCavityShape, setValueCavityShape]: any = useState();
    const [valueCavityShow, setValueCavityShow]: any = useState();


    const [valueCavityShapeCreatable, setValueCavityShapeCreatable] = useState<Option | null>();

    const [selectedCavityShape, setSelectedCavityShape] = useState('')

    const apinameForCavityShape = process.env.REACT_APP_MOULD_SHAPE_API?.toString()

    const { mutate: mutateCavityShape, data: dataCavityShape, isError: cavityShapeIsError, isLoading: cavityShapeLoading, isSuccess: cavityShapeSuccess, error: cavityShapeError }: any = useCreate(apinameForCavityShape)

    const { data: CavityShapeData, refetch: CavityShapeRefetch } = useFetchMouldShapeDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavityShape(CavityShapeData)
    }, [CavityShapeData])

    const handleChangeCavityShape = (e: any) => {
        CavityShapeRefetch()

        setApiName(apinameForCavityShape)
        setDeleteApiName(apinameForCavityShape)
        setEditId(e?.value)
        setEditData(CavityShapeData)
        setRenderKey(prevKey => prevKey + 1);


        setValueCavityShape(e)
        setValueCavityShow(e)
        setValue("cavity_shape", `${e?.value}`)
        setValueCavityShapeCreatable(null)
    }

    const handleCreateCavityShape = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavityShape({ name: newOption.name, status: newOption.status })


            setOptionsCavityShape((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityShapeCreatable(newOption);


        }, 0);

    };

    useEffect(() => {
        (async () => {
            if (cavityShapeSuccess && optionsCavityShape) {
                await CavityShapeRefetch();
            }
            setValue("cavity_shape", CavityShapeData[0].value)
        })();

        return () => {
        };
    }, [cavityShapeSuccess, optionsCavityShape]);


    const { data: colorData, refetch: colorRefetch } = useFetchMouldDropDown()

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])

    const handleChange = (e: any) => {
        setValue("mould_usage", `${e?.value}`)
        setSel(e)

    }



    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedCavity(data?.name?.id)
            setValue("name", data?.name?.id)
            // setValue("mould_usage", data?.mould_usage?.id)
            // setSelected(data?.mould_usage?.id)
            setSelectedProductName(data?.product_name?.id)
            setValue("product_name", data?.product_name?.id)
            setSelectedCavityShape(data?.cavity_shape?.id)
            setValue("cavity_shape", data?.cavity_shape?.id)
            setValueSize(data?.cavity_size_mm)
            setValueHeight(data?.height)
            setValueLength(data?.cavity_length_mm)
            setValueWidth(data?.cavity_width_mm)
            setValueCavityShow(data?.cavity_shape)
            setSelectedCavityType(data?.type?.id)
            setValue("type",data?.type.id)
            // setSelectedMaterialName(data?.materialname_type_category_hsn_gst?.id)
            // setValue("materialname_type_category_hsn_gst",data?.materialname_type_category_hsn_gst?.id)
            // setValueCavityShape(data?.cavity_shape)

            let tempCavityImages = data?.image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempCavityDrawing = data?.pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })


            let tempProductImages = data?.product_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductDrawing = data?.product_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })



            setUploadCavityImage(tempCavityImages)
            setUploadCavityDrawing(tempCavityDrawing)
            setUploadProductImage(tempProductImages)
            setUploadProductDrawing(tempProductDrawing)
        }
        if (sel) {
            setSelected(data.grade_type?.id)

        }
    }, [data, selected]);

    const apinameMould = process.env.REACT_APP_MOULD_API?.toString()
    const apinameMaterialName = process.env.REACT_APP_HSN_API?.toString()
    useEffect(()=>{
        
    if(data?.mould_usage){
        const getCall = async()=>{
        try {
            const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameMould}/${data?.mould_usage}`);
            // setDataProductCode(response.data?.data)
            setSelected(response?.data?.data?.id)
            setValue('mould_usage',response?.data?.data?.id)
          } catch (error) {
            console.error('Error:', error);
          }
        }
        getCall()
    }
    if(data?.materialname_type_category_hsn_gst){
        const getCall = async()=>{
        try {
            const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameMaterialName}/${data?.materialname_type_category_hsn_gst}`);
            // setDataProductCode(response.data?.data)
            setSelectedMaterialName(response?.data?.data?.id)
            setValue("materialname_type_category_hsn_gst",response?.data?.data?.id)
          } catch (error) {
            console.error('Error:', error);
          }
        }
        getCall()
    }
    },[data])


    useEffect(() => {
        if (valueCavityShow?.mouldA === false) {
            setValue("cavity_size_mm", "")
        }
        if (valueCavityShow?.mouldB === false) {
            setValue("cavity_length_mm", "")
        }
        if (valueCavityShow?.mouldC === false) {
            setValue("cavity_width_mm", "")
        }

    }, [valueCavityShow])


    const [optionsCavityType, setOptionsCavityType]: any = useState([])

    const [valueCavityType, setValueCavityType]: any = useState();
    const [selectedCavityType, setSelectedCavityType]: any = useState('');
    const [valueCavityTypeCreatable, setValueCavityTypeCreatable] = useState<Option | null>();
    const apinameForCavityType = process.env.REACT_APP_MATERIAL_USE_API?.toString()

    const { mutate: mutateCavityType, data: dataCavityType, isError: CavityTypeIsError, isLoading: CavityTypeLoading, isSuccess: CavityTypeSuccess, error: CavityTypeError }: any = useCreate(apinameForCavityType)
    const { data: CavityTypeData, refetch: CavityTypeRefetch } = useFetchMaterialUseDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsCavityType(CavityTypeData)
    }, [CavityTypeData])


    const handleChangeCavityType = (e: any) => {

        CavityTypeRefetch()

        setApiName(apinameForCavityType)
        setDeleteApiName(apinameForCavityType)
        setEditId(e?.value)
        setEditData(CavityTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCavityType(e)


        setValue("type", `${e?.value}`)
        setValueCavityTypeCreatable(null)
    }

    const handleCreateCavityType = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateCavityType({ name: newOption.name, status: newOption.status })


            setOptionsCavityType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueCavityTypeCreatable(newOption);


        }, 0);




    };

    useEffect(() => {
        (async () => {
            if (CavityTypeSuccess && optionsCavityType) {
                await CavityTypeRefetch();
                setValue("type", CavityTypeData[0].value)
            }
        })();

        return () => {
        };
    }, [CavityTypeSuccess, optionsCavityType]);



    const onSubmit = (cavitydetail: any) => {
        // if (cavitydetail.image?.length == 1 && cavitydetail.pdf?.length != 1) {
        //     delete cavitydetail.pdf

        // } else if (cavitydetail.pdf?.length == 1 && cavitydetail.image?.length != 1) {
        //     delete cavitydetail.image
        // } else if (cavitydetail.image?.length == 1 && cavitydetail.pdf?.length) {
        // }
        // else {
        //     delete cavitydetail.image
        //     delete cavitydetail.pdf
        // }
        if (valueCavityShow?.mouldA === false) {
            cavitydetail.cavity_size_mm = ""
        }
        if (valueCavityShow?.mouldB === false) {
            cavitydetail.cavity_length_mm = ""
        }
        if (valueCavityShow?.mouldC === false) {
            cavitydetail.cavity_width_mm = ""
        }
        const formData = new FormData();
        const dimensionKeys = new Set(['height', 'cavity_size_mm', 'cavity_length_mm', 'cavity_width_mm']);

        for (const [key, value] of Object.entries(cavitydetail)) {
            const formvalue: any = value


            if (dimensionKeys.has(key)) {
                continue;
            }


            if (key === "image") {
                cavityImageId.map((old: any) => {
                    formData.append('cavity_images', old?.id)
                })
                for (let i = 0; i < cavityImage.length; i++) {
                    formData.append('image', cavityImage[i])
                }
            }
            if (key === "pdf") {
                cavityDrawingId.map((old: any) => {
                    formData.append('cavity_pdfs', old?.id)
                })
                for (let i = 0; i < cavityDrawing.length; i++) {

                    formData.append('pdf', cavityDrawing[i])
                }
            }

            if (key === "product_image") {
                productImageId.map((old: any) => {
                    formData.append('product_images', old?.id)
                })
                for (let i = 0; i < productImage.length; i++) {
                    formData.append('product_image', productImage[i])
                }
            }
            if (key === "product_pdf") {
                productDrawingId.map((old: any) => {
                    formData.append('product_pdfs', old?.id)
                })
                for (let i = 0; i < productDrawing.length; i++) {

                    formData.append('product_pdf', productDrawing[i])
                }
            }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (key === "image" || key === "pdf") {
            //     if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            //         formData.append(key, formvalue[0])
            //     }
            //     else {
            //         formData.append(key, "")
            //     }
            // }
            // Convert and append height if not set

        }

        // Now, append the dimensions outside of the loop
        if ('height' in cavitydetail) {
            let decimalNumber = Number(cavitydetail.height);
            formData.append('height', decimalNumber.toFixed(2));

        }
        if ('cavity_size_mm' in cavitydetail) {
            let decimalNumber = Number(cavitydetail.cavity_size_mm);
            if (cavitydetail.hasOwnProperty('cavity_size_mm') && Number(cavitydetail.cavity_size_mm) === 0.00) {
                formData.append('cavity_size_mm', "");
            } else {

                formData.append('cavity_size_mm', decimalNumber.toFixed(2));
            }

        }
        if ('cavity_length_mm' in cavitydetail) {
            let decimalNumber = Number(cavitydetail.cavity_length_mm);

            if (cavitydetail.hasOwnProperty('cavity_length_mm') && Number(cavitydetail.cavity_size_mm) === 0.00) {
                formData.append('cavity_length_mm', "");
            } else {

                formData.append('cavity_length_mm', decimalNumber.toFixed(2));
            }
            // formData.append('cavity_length_mm', decimalNumber.toFixed(2));
        }
        if ('cavity_width_mm' in cavitydetail) {
            let decimalNumber = Number(cavitydetail.cavity_width_mm);
            if (cavitydetail.hasOwnProperty('cavity_width_mm') && Number(cavitydetail.cavity_size_mm) === 0.00) {
                formData.append('cavity_width_mm', "");
            } else {

                formData.append('cavity_width_mm', decimalNumber.toFixed(2));
            }
            // formData.append('cavity_width_mm', decimalNumber.toFixed(2));
        }


        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)


    }


    // const onLogoChange = (e: any) => {
    //     setlogo(e.target.files[0].name)
    // }

    // const onLogoPDFChange = (e: any) => {
    //     setlogoPdf(e.target.files[0].name)
    // }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listCavity', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, cavityUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         navigate('/listCavity', { state: { showEditToast: true }})
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [data, error,isSuccess])



    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);



    const handleCavityName = (e: any) => {
        let Capital = e.target.value
        setValue("name", Capital.charAt(0).toUpperCase() + Capital.slice(1))

    }

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    const [cavityImageId, setCavityImageId]: any = useState([])
    const [cavityDrawingId, setCavityDrawingId]: any = useState([])
    const [productImageId, setProductImageId]: any = useState([])
    const [productDrawingId, setProductDrawingId]: any = useState([])

    const [uploadCavityImage, setUploadCavityImage]: any = useState<UploadFile[]>([])
    const [uploadCavityDrawing, setUploadCavityDrawing] = useState<UploadFile[]>([])

    const [uploadProductImage, setUploadProductImage]: any = useState<UploadFile[]>([])
    const [uploadProductDrawing, setUploadProductDrawing] = useState<UploadFile[]>([])

    const onCavityImage = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setCavityImageId(tempOldFiles)
        setCavityImage(tempFiles)
        setUploadCavityImage(e.fileList)

    }

    const onProductImage = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductImageId(tempOldFiles)
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const onCavityDrawing = (e: any) => {
        e.file.status = "done"


        let tempOldFiles: any = []
        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }

                tempOldFiles.push(ctx)
            }
        })



        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)



        setCavityDrawingId(tempOldFiles)
        setCavityDrawing(tempFiles)
        setUploadCavityDrawing(e.fileList)

    }

    const onProductDrawing = (e: any) => {
        e.file.status = "done"


        let tempOldFiles: any = []
        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }

                tempOldFiles.push(ctx)
            }
        })



        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)



        setProductDrawingId(tempOldFiles)
        setProductDrawing(tempFiles)
        setUploadProductDrawing(e.fileList)

    }



    const [valueSize, setValueSize] = useState('')
    const [valueHeight, setValueHeight] = useState('')
    const [valueLength, setValueLength] = useState('')
    const [valueWidth, setValueWidth] = useState('')



    useEffect(() => {
        if (submitted) {
            setSel(options[0])
            setValue("mould_usage", options[0]?.value)


        }

    }, [submitted, options])


    const [SubmittedMaterialName,setSubmittedMaterialName]:any = useState('')
    useEffect(() => {
        if (SubmittedMaterialName) {
            setSelMaterialName(optionsMaterialName[0])
            setValue("materialname_type_category_hsn_gst", optionsMaterialName[0]?.value)


        }

    }, [SubmittedMaterialName, optionsMaterialName])

    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {

        if (apiName === apinameForCavity) {
            cavityNameRefetch()
        }
        if (apiName === apinameForProduct) {
            productNameRefetch()
        }
        if (apiName === apinameForCavityShape) {
            CavityShapeRefetch()
        }
        if (apiName === apinameForCavityType) {
            CavityTypeRefetch()
        }

        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueCavity && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }
        if (OpenEditDropDownModel && valueProduct && apiName === apinameForProduct) {
            setValueProduct(null)
            setValueProductCreatable(null)
        }
        if (OpenEditDropDownModel && valueCavityShape && apiName === apinameForCavityShape) {
            setValueCavityShape(null)
            setValueCavityShapeCreatable(null)
        }
        if (OpenEditDropDownModel && valueCavityType && apiName === apinameForCavityType) {
            setValueCavityType(null)
            setValueCavityTypeCreatable(null)
        }






        if (deletesucess && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }
        if (deletesucess && apiName === apinameForProduct) {
            setValueProduct(null)
            setValueProductCreatable(null)
        }
        if (deletesucess && apiName === apinameForCavityShape) {
            setValueCavityShape(null)
            setValueCavityShapeCreatable(null)
        }
        if (deletesucess && apiName === apinameForCavityType) {
            setValueCavityType(null)
            setValueCavityTypeCreatable(null)
        }


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



            {label !== 'Square' && label !== 'Round' && label !== 'Triangle' && label !== 'Rectangles' ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Product Cavity</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listCavity" className="text-decoration-none text-black">Product Cavity</Link></li>
                                    <li className="breadcrumb-item active">Edit Product Cavity</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Cavity</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("name", { required: { value: true, message: "Cavity Name is required" } })}
                                                        isClearable={() => {
                                                            setValueCavity(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Name'}
                                                        options={optionsCavity}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeCavityName}
                                                        components={customCreatableIndicator}
                                                        // value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}
                                                        value={valueCavityCreatable == null ? valueCavity == null ? optionsCavity?.filter((obj: any) => obj.value === selectedCavity) : valueCavity : valueCavityCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => cavityNameRefetch()}
                                                    />
                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProduct(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_name", { required: { value: true, message: "Product Name is required" } })}
                                                        placeholder={'Select Product Name'}
                                                        options={optionsProduct}
                                                        onCreateOption={handleCreateProduct}
                                                        onChange={handleChangeProduct}
                                                        // value={valueProductCreatable == null ? valueProduct:valueProductCreatable}
                                                        value={valueProductCreatable == null ? valueProduct == null ? optionsProduct?.filter((obj: any) => obj.value === selectedProductName) : valueProduct : valueProductCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => productNameRefetch()}
                                                        components={customCreatableIndicator}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN Code & GST % <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("materialname_type_category_hsn_gst")}
                                                            placeholder={'Select Mould Usage'}
                                                            options={optionsMaterialName}
                                                            onChange={handleChangeMaterialName}
                                                            components={customIndicator}
                                                            // value={MaterialNameValue}
                                                            value={selMaterialName === "" ? optionsMaterialName?.find((obj: any) => obj.value === selectedMaterialName) : selMaterialName}
                                                            // defaultMenuIsOpen={options.find((obj: any) => obj.value === selected)}
                                                            className="dropdown-select"
                                                            maxMenuHeight={130}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMaterialName(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Name & Type</label>
                                                    <Select
                                                        {...register("", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={}
                                                        onChange={}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Qty</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Single Code</label>
                                                    <Select
                                                        {...register("mould_usage", { required: { value: true, message: "Mould Usage is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={options}
                                                        onChange={handleChange}
                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_usage?.message}</span>

                                                </div> 
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Single Code</label>
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { required: { value: true, message: "Cavity Height is required" } })} />
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>
                                                
                                                */}
                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Mould Code & Diameter & Cavity Qty <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("mould_usage")}
                                                            placeholder={'Select Mould Usage'}
                                                            options={options}
                                                            onChange={handleChange}
                                                            components={customIndicator}
                                                            value={sel === "" ? options?.find((obj: any) => obj.value === selected) : sel}
                                                            // defaultMenuIsOpen={options.find((obj: any) => obj.value === selected)}
                                                            className="dropdown-select"
                                                            maxMenuHeight={130}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Stock Pcs <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Stock Pcs" {...register("cavity_stock_pcs", { required: { value: true, message: "Cavity Stock Pcs is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cavity_stock_pcs?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Shapes <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("cavity_shape", { required: { value: true, message: "Cavity Shapes is required" } })}
                                                        isClearable={() => {
                                                            setValueCavityShape(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Shapes'}
                                                        options={optionsCavityShape}
                                                        onCreateOption={handleCreateCavityShape}
                                                        onChange={handleChangeCavityShape}
                                                        components={customCreatableIndicator}
                                                        // value={valueCavityShapeCreatable == null ? valueCavityShape : valueCavityShapeCreatable}
                                                        value={valueCavityShapeCreatable == null ? valueCavityShape == null ? optionsCavityShape?.filter((obj: any) => obj.value === selectedCavityShape) : valueCavityShape : valueCavityShapeCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => CavityShapeRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.cavity_shape?.message}</span>
                                                </div>
                                                {/* {(valueCavityShow?.mouldA ? <> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Size MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Size MM"
                                                            disabled={(valueCavityShow?.mouldA ? false : true)}
                                                            {...register("cavity_size_mm", { onChange: ((e: any) => setValueSize(e.target.value)), required: { value: true, message: "Cavity Size MM is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.size?.message}</span>
                                                </div>
                                                {/* </>: "")} */}

                                                {/* {(valueCavityShow?.mouldC ? <> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Width MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Width MM"
                                                            disabled={(valueCavityShow?.mouldC ? false : true)}
                                                            {...register("cavity_width_mm", { onChange: ((e: any) => setValueWidth(e.target.value)), required: { value: valueCavityShape?.mouldC === "false" ? true : false, message: "Cavity Width MM is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.width?.message}</span>
                                                </div>
                                                {/* </>: "")} */}

                                                {/* {(valueCavityShow?.mouldB ? <> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Length MM </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Length MM"
                                                            disabled={(valueCavityShow?.mouldB ? false : true)}
                                                            {...register("cavity_length_mm", { onChange: ((e: any) => setValueLength(e.target.value)), required: { value: valueCavityShape?.mouldB === "false" ? true : false, message: "Cavity Length MM is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.length?.message}</span>
                                                </div>
                                                {/* </>: "")} */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Height MM <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Cavity Height" {...register("height", { onChange: ((e: any) => setValueHeight(e.target.value)), required: { value: true, message: "Cavity Height is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.height?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Type <span style={{ color: "red" }}>*</span></label>
                                                    {/* <select className="form-select" {...register("type", { required: { value: true, message: "Cavity Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="special">Special</option>
                                                        <option value="running">Running</option>
                                                        <option value="full_running">Full Running</option>
                                                    </select> */}

                                                    <CreatableSelect
                                                        {...register("type", { required: { value: true, message: "Cavity Typeis required" } })}
                                                        isClearable={() => {
                                                            setValueCavityType(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Select Cavity Type'}
                                                        options={optionsCavityType}
                                                        onCreateOption={handleCreateCavityType}
                                                        onChange={handleChangeCavityType}
                                                        components={customCreatableIndicator}
                                                        // value={valueCavityTypeCreatable == null ? valueCavityType : valueCavityTypeCreatable}
                                                        value={valueCavityTypeCreatable == null ? valueCavityType == null ? optionsCavityType?.filter((obj: any) => obj.value === selectedCavityType) : valueCavityType : valueCavityTypeCreatable}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => CavityTypeRefetch()}

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cavity Code </label>
                                                    <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Cavity Code" {...register("cavity_code")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity Image All</label>


                                                    {uploadCavityImage?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={onCavityImage}
                                                                multiple={true}
                                                                defaultFileList={[...uploadCavityImage]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={onCavityImage}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }





                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity PDF Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    {uploadCavityDrawing?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={onCavityDrawing}
                                                                multiple={true}
                                                                defaultFileList={[...uploadCavityDrawing]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={onCavityDrawing}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }

                                                </div>


                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>


                                                    {uploadProductImage?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("product_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={onProductImage}
                                                                multiple={true}
                                                                defaultFileList={[...uploadProductImage]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("product_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={onProductImage}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }





                                                </div>


                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    {uploadProductDrawing?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("product_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={onProductDrawing}
                                                                multiple={true}
                                                                defaultFileList={[...uploadProductDrawing]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("product_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={onProductDrawing}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    {/* 
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity Image</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("image",
                                                            { onChange: onLogoChange }
                                                        )} />
                                                    </label>
                                                    <a href={data?.image} target="_blank" ><p>{logo == null ? data?.image?.split("/").pop() : logo}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cavity PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("pdf",
                                                            { onChange: onLogoPDFChange }
                                                        )} />
                                                    </label>
                                                    <a href={data?.pdf} target="_blank"><p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCavity")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Mould_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            <HSN_Model refetchForm={MaterialNameRefetch} open={openMaterialName} setOpen={setOpenMaterialName} setSubmitted={setSubmittedMaterialName} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Edit_Cavity