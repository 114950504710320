
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import noImage from "../../../images/noImage.png"
import { Update, useFetch } from "../../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";

interface Data {
  selection: any

  person_type: any
  employee_code: any
  type: any
  document_number: any
  pdf: any



  created_at: any

  created_by: any
  updated_by: any
  updated_at: any
  status: any
  Stepper_Actions: any;

  actions: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },


  {
    id: `person_type`,
    Header: `Product Type`,
  },


  {
    id: `employee_code`,
    Header: `Employee Code`,
  },

  {
    id: `type`,
    Header: `Document Type`,
  },

  {
    id: `document_number`,
    Header: `Document Number`,
  },

  {
    id: `pdf`,
    Header: `PDF`,
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

  {
    id: 'status',
    Header: 'Status',
  },
]

export const Columns_Sales_Document: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    sticky: "left",
  },


  // {
  //     id: `person_type`,
  //     Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
  //     accessor:'person_type',  
  //     sticky:"left",
  //     Cell: ({ row }: any) => row.original.person_type?.name
  // },


  //   {
  //     id: `employee_code`,
  //     Header: () => <div style={{display:"inline"}}>Code & Full Name<br/>& City</div>,
  //     accessor:'employee_code',  
  //     Cell: ({ row }: any) => row.original.employee_code?.code
  // },


  //   {
  //     id: `account_type`,
  //     Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
  //     accessor:'account_type',  
  //     Cell: ({ row }: any) => row.original.employee_code?.group?.accountStatementType?.name
  // },


  //   {
  //     id: `country`,
  //     Header: () => <div style={{display:"inline"}}>Country<br/>Name</div>,
  //     accessor:'country',  
  //     Cell: ({ row }: any) => row.original.employee_code?.city_name_and_distict_name?.country?.name
  // },

  {
    id: `document_type`,
    Header: () => <div style={{ display: "inline" }}>Document<br />Name</div>,
    accessor: 'document_type',
    sticky: "left",
    Cell: ({ row }: any) => row.original.document_type
  },

  {
    id: `in_print_name`,
    Header: () => <div style={{ display: "inline" }}>Document In<br />Print Name</div>,
    accessor: 'in_print_name',
    Cell: ({ row }: any) => row.original.in_print_name
  },

  {
    id: `document_number`,
    Header: () => <div style={{ display: "inline" }}>Document<br />Number</div>,
    accessor: 'document_number',
    Cell: ({ row }: any) => row.original.document_number
  },


  {
    id: `remark`,
    Header: () => <div style={{ display: "inline" }}>Re-Mark</div>,
    accessor: 'remark',
    Cell: ({ row }: any) => row.original.remark ? row.original.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>
  },




  {
    id: `image`,
    Header: () => <div style={{ display: "inline" }}>Document<br />Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };

      return (
        <>

          <div >

            {/* {!row.original.image ? <> */}
            {!row.original.image || row.original.image?.length === 0 ? <>

              <a

                href="#"

              // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                    onClick={() => handleShowImages(row.original)}

                // onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
                {/* <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} height={60} width={100} /> */}
                <img src={row.original.image[0]?.doc ? row.original.image[0]?.doc : noImage} alt='no image' width={50} height={50} />

              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            {/* <Modal.Body>
              <img src={(row.original.image?.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body> */}
              <Modal.Body>
                  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
    
                    </div>
    
    
    
                    <div className="carousel-inner">
                      {row.original.image?.map((imgSrc: any, index: any) => (
                        <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                          <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                        </div>
                      ))}
                    </div>
    
                    <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
                  </div>
                </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>

              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  // {
  //   id: `pdf`,
  //   Header: () => <div style={{ display: "inline" }}>Document<br />PDF All</div>,

  //   // accessor: `pdf`,
  //   Cell: ({ row }: any) =>



  //     <div>
  //       <a target="_blank" href={(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc : null)}>
  //         {(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
  //       </a>
  //     </div>

  // },

  {
    id: `pdf`,
    Header: () => <div style={{ display: "inline" }}>Document<br />PDF All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
            {!row.original.pdf || row.original.pdf?.length === 0? <>
              <a

                href="#"

              >
                <span className="text-danger">No Document Uploaded</span>
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.pdf.length > 0 ? row.original.pdf.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}
            
          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.pdf?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },


  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  //   {
  //     id: `pdf`,
  //     Header: `PDF`,
  //     accessor:'pdf',  
  // },





  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Stepper_Actions',
    Header: 'Actions',


  },


  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Sales_Document, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DOCUMENT_DETAIL_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]
