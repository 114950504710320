
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";
import { Tooltip } from "@mui/material";


interface Data {
  selection: any
  
  product_type:any
  product_grade_name:any
  HSN_code:any
  color:any
  panni:any
  panni_balloon:any


  product_image:any
  product_drawing:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  Actions: any;
}




export const titleofheaders_Other=[
  {
    id: `srno`,
    Header: `Sr.No`,
  },



  {
    id: `product_type`,
    Header: `Product Type`,
  },


  {
    id: `product_grade_name`,
    Header: `Product Grade Name`,
  },





  {
    id: `HSN_code`,
    Header: `HSN Code`,
  },


  {
    id: `color`,
    Header: `Color`,
  },


  


 














{
  id: `panni`,
  Header: `Panni`,
},
  





  

  


 

 
    {
      id: `panni_balloon`,
      Header: `Panni Balloon`,
    },

  

    {
      id: 'status',
      Header: 'Status',
    
    }, 
  


{
  id: 'created_at',
  Header: 'Created at',
},


{
  id: 'created_by',
  Header: 'Created By',


},
{
  id: 'updated_at',
  Header: 'Updated At',
},
{
  id: 'updated_by',
  Header: 'Updated By',
},


{
  id: `product_image`,
  Header: 'Product Image',


  },


  {
    id: `product_drawing`,
    Header: 'Product Drawing',

    },



{
  id: 'Actions',
  Header: 'Actions',


},
 
]





export const Columns_SingleCode_Other: any = [



  
  {
    id: `srno`,
    Header: `Sr.No`,
    sticky:"left",
  },


  
  {
    id: `product_type`,
  Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,

    // Header: `Product Type`,
    accessor:`product_type`,
    sticky:"left",
    Cell: ({ row }: any) => row.original?.product_type?.name?row.original?.product_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `single_piece_code`,
    Header: () => <div style={{display:"inline"}}>Product Code<br/>L*W*M*</div>,
    accessor:`single_piece_code`,
    Cell: ({ row }: any) => row.original.single_piece_code
  },

  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Product Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.HSN_code?.material_type_name} {row.original.extra_response?.HSN_code?.particular_sector_name} {row.original.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: `HSN_code`,
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,

    // Header: `HSN Code`,
    accessor:`HSN_code`,
    Cell: ({ row }: any) => {
      const HSN:any = ` ${row.original.extra_response?.HSN_code?.material_type_name}::${row.original.extra_response?.HSN_code?.particular_sector_name?row.original.extra_response?.HSN_code?.particular_sector_name:<span style={{ color: 'red' }}>****</span>}::
       ${row.original.extra_response?.HSN_code?.product_categories_name?row.original.extra_response?.HSN_code?.product_categories_name:<span style={{ color: 'red' }}>****</span>}::
       ${row.original.extra_response?.HSN_code?.hsn_code}::${row.original?.extra_response?.HSN_code?.india_GST}`

      return (
          <>
            <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{HSN}</p>} placement="top" arrow >
              <div>
                {HSN.length >=2 ? HSN?.slice(0, 19)?.concat('...'):HSN}
              </div>
            </Tooltip>
  
          </>
        )
  }
    
  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.extra_response?.HSN_code?.gst_type_name}
        </div>
      )
    }
  },
  {
    id: `type`,
    Header: () => <div style={{ display: "inline" }}>Type Name &<br />Density & Sutra & GST%</div>,
    accessor: 'type',
    Cell: ({ row }: any) => {
        return(

        <div>
            {row.original.extra_response?.HSN_code?.material_type_name}::{row.original.extra_response?.HSN_code?.material_density === "0.000" ||row.original.extra_response?.HSN_code?.material_density=== null
      ? <span style={{ color: 'red'}}>0.000</span>
      : row.original.extra_response?.HSN_code?.material_density}::{row.original.extra_response?.HSN_code?.grade_sutra?row.original.extra_response?.HSN_code?.grade_sutra:<span style={{ color: 'red'}}>****</span>}::{row.original?.extra_response?.HSN_code?.grade_type_india_GST}
        </div>
         )}
},

{
  id: 'use_code_name',
  Header: () => <div style={{ display: "inline" }}>Use Product Single Code<br />& Name & Type</div>,
  // Header: 'Hsn Code',
  accessor: 'use_code_name',
  Cell: ({ row }: any) => {
    return (
      <div>
        {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
    )
  }
},
{
  id: 'use_code_qty',
  Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
  // Header: 'Hsn Code',
  accessor: 'use_code_qty',
  Cell: ({ row }: any) => {
    return (
      <div>
        {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
    )
  }
},

  // {
  //   id: `product_grade_name`,
  // Header: () => <div style={{display:"inline"}}>Product<br/>Grade Name</div>,

  //   // Header: `Product Grade Name`,
  //   accessor:`product_grade_name`,
  //   sticky:"left",
  //   Cell: ({ row }: any) => row.original.product_grade_name?.material_type_name?row.original.product_grade_name?.material_type_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  // },





  // {
  //   id: `HSN_code`,
  // Header: () => <div style={{display:"inline"}}>HSN<br/>Code</div>,

  //   // Header: `HSN Code`,
  //   accessor:`HSN_code`,
  //   Cell: ({ row }: any) => row.original.HSN_code?.hsn_code?row.original.HSN_code?.hsn_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  // },


  {
    id: 'material_type',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    // Header: 'Material Use',
    accessor: 'material_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.material_type?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  
  },
  
  
  {
    id: `color`,
    Header: () => <div style={{display:"inline"}}>Product Color<br/>Name</div>,
  
    accessor:`color`,
    Cell: ({ row }: any) => row.original.extra_response?.company_color_name?row.original.extra_response?.company_color_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
    {
      id: `product_width_mm`,
      Header: () => <div style={{display:"inline"}}>Product Width<br/>mm</div>,
    
      accessor:`product_width_mm`,
      Cell: ({ row }: any) => row.original.product_width_mm?row.original.product_width_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_length_mm`,
      Header: () => <div style={{display:"inline"}}>Product Length<br/>mm</div>,
    
      accessor:`product_length_mm`,
      Cell: ({ row }: any) => row.original.product_length_mm?row.original.product_length_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `thickness_micron`,
      Header: () => <div style={{display:"inline"}}>Thickness<br/>Micron</div>,
    
      accessor:`thickness_micron`,
      Cell: ({ row }: any) => row.original.thickness_micron?row.original.thickness_micron:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `thickness_gauge`,
      Header: () => <div style={{display:"inline"}}>Thickness<br/>Gauge</div>,
    
      accessor:`thickness_gauge`,
      Cell: ({ row }: any) => row.original.thickness_gauge === "undefined" || row.original.thickness_gauge === null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original.thickness_gauge
    },
    {
      id: `gadget_yes_no`,
      Header: () => <div style={{display:"inline"}}>Gadget<br/>Yes-No</div>,
    
      accessor:`gadget_yes_no`,
      Cell: ({ row }: any) => {
        const convertToUpper = row.original?.gadget_yes_no
        if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
        // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
        return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
      }
    },
    {
      id: `gadget_size_mm`,
      Header: () => <div style={{display:"inline"}}>Gadget<br/>Size mm</div>,
      
      accessor:`gadget_size_mm`,
      Cell: ({ row }: any) => row.original.gadget_size_mm?row.original.gadget_size_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_kan_yes_no`,
      Header: () => <div style={{display:"inline"}}>Product Kan<br/>Yes-No</div>,
    
      accessor:`product_kan_yes_no`,
      Cell: ({ row }: any) => {
        const convertToUpper = row.original?.product_kan_yes_no
        if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
        // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
        return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
      }
    },
    {
      id: `product_kan_width_mm`,
      Header: () => <div style={{display:"inline"}}>Product Kan<br/>Width mm</div>,
      
      accessor:`product_kan_width_mm`,
      Cell: ({ row }: any) => row.original.product_kan_width_mm?row.original.product_kan_width_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_kan_length_mm`,
      Header: () => <div style={{display:"inline"}}>Product Kan<br/>Length mm</div>,
      
      accessor:`product_kan_length_mm`,
      Cell: ({ row }: any) => row.original.product_kan_length_mm?row.original.product_kan_length_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    
    {
      id: `product_sealing_type`,
      Header: () => <div style={{display:"inline"}}>Product Sealing<br/>Type</div>,
      
      accessor:`product_sealing_type`,
      Cell: ({ row }: any) => row.original.product_sealing_type?.name?row.original.product_sealing_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_ml`,
      Header: () => <div style={{display:"inline"}}>Product<br/>ML</div>,
      
      accessor:`product_ml`,
      Cell: ({ row }: any) => row.original.product_ml?row.original.product_ml:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_ml_actual`,
      Header: () => <div style={{display:"inline"}}>Product Actual<br/>ML</div>,
      
      accessor:`product_ml_actual`,
      Cell: ({ row }: any) => row.original.product_ml_actual?row.original.product_ml_actual:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_1_pcs_weight`,
      Header: () => <div style={{display:"inline"}}>Product 1 Pcs<br/>Weight Gram</div>,
      
      accessor:`product_1_pcs_weight`,
      Cell: ({ row }: any) => row.original.product_1_pcs_weight?row.original.product_1_pcs_weight:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_1_kg_pcs`,
      Header: () => <div style={{display:"inline"}}>Product 1<br/>Kg In Pcs</div>,
      
      accessor:`product_1_kg_pcs`,
      Cell: ({ row }: any) => row.original.product_1_kg_pcs?row.original.product_1_kg_pcs:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_minimum_kg`,
      Header: () => <div style={{display:"inline"}}>Product<br/>Minimum Kg</div>,
      
      accessor:`product_minimum_kg`,
      Cell: ({ row }: any) => row.original.product_minimum_kg?row.original.product_minimum_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_maximum_kg`,
      Header: () => <div style={{display:"inline"}}>Product 1<br/>Maximum Kg</div>,
      
      accessor:`product_maximum_kg`,
      Cell: ({ row }: any) => row.original.product_maximum_kg?row.original.product_maximum_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_bursting_strength`,
      Header: () => <div style={{display:"inline"}}>Product Bursting<br/>Strength</div>,
      
      accessor:`product_bursting_strength`,
      Cell: ({ row }: any) => row.original.product_bursting_strength?row.original.product_bursting_strength:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_our_other`,
      Header: () => <div style={{display:"inline"}}>Production<br/>Our-Other</div>,
      
      accessor:`product_our_other`,
      Cell: ({ row }: any) => row.original.product_our_other?.name?row.original.product_our_other?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },
    {
      id: `product_special_running_type`,
      Header: () => <div style={{display:"inline"}}>Production<br/>Type</div>,
      
      accessor:`product_special_running_type`,
      Cell: ({ row }: any) => row.original.product_special_running_type?.name?row.original.product_special_running_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    },

  
    {
      id: 'material_name',
      Header: () => <div style={{ display: "inline" }}>Material<br />Grade Name</div>,
      accessor:'material_name',
      Cell: ({ row }: any) => {
        return(
          <div>
            {row.original.extra_response?.HSN_code?.material_type_name}
          </div>
        )
      }
  
    },
   
    {
      id: 'material_type_name',
      Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
      accessor:'material_type_name',
      Cell: ({ row }: any) => {
        return(
          <div>
            {row.original.extra_response?.HSN_code?.particular_sector_name}
          </div>
        )
      }
  
    },
  
    {
      id: 'material_category',
      Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
      accessor:'material_category',
      Cell: ({ row }: any) => {
        return(
          <div>
          {row.original.extra_response?.HSN_code?.product_categories_name}
          </div>
        )
      }
  
    },
    
    
    {
      id: 'material_hsn',
      Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
      accessor:'material_hsn',
      Cell: ({ row }: any) => {
        return(
          <div>
            {row.original.extra_response?.HSN_code?.hsn_code}
          </div>
        )
      }
  
    },
   
    {
      id: 'material_gst',
      Header: () => <div style={{ display: "inline" }}>Product<br />GST%</div>,
      accessor:'material_gst',
      Cell: ({ row }: any) => {
        return(
          <div>
           {row.original.extra_response?.HSN_code?.india_GST}
          </div>
        )
      }
  
    },
    {
      id: 'product_density',
      Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
      accessor:'product_density',
      Cell: ({ row }: any) => {
        return(
          <div>
           {row.original.extra_response?.HSN_code?.material_density?row.original.extra_response?.HSN_code?.material_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
  
          </div>
        )
      }
  
    },
    {
      id: 'remark',
      Header: () => <div style={{ display: "inline" }}>Remark</div>,
      accessor:'remark',
      Cell: ({ row }: any) => {
        return(
          <div>
            {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        )
      }
  
    },




















// {
//   id: `panni`,
//   Header: `Panni`,
//   accessor:`panni`,
//   Cell: ({ row }: any) => {
//     return(
//   <div>
// {row.original.panni?.panni_code?row.original.panni?.panni_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
//   </div>
  
// )}
// },
  

// {
//   id: `panni_balloon`,
//   Header: () => <div style={{display:"inline"}}>Panni<br/>Balloon</div>,

//   accessor:`panni_balloon`,
//   Cell: ({ row }: any) => {
//     return(
//   <div>
// {row.original?.panni_balloon?row.original?.panni_balloon:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
//   </div>
  
// )}
// },



  

  





  
 

 
    

  

    {
      id: 'status',
      Header: () => <div style={{display:"inline"}}>Product<br/>Status</div>,
      accessor:'status',
    
    }, 
  


    {
      id: 'created_at',
      Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
      accessor:'created_at',
      Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
    },
  
  
    {
      id: 'created_by',
      Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
      accessor:'created_by',
      Cell: ({ row }: any) => row.original.created_by?.username
  
   
    },
    {
      id: 'updated_at',
      Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
      accessor:'updated_at',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
    },
    {
      id: 'updated_by',
      Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
      accessor:'updated_by',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
    },


    {
      id: `product_image`,
      Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
      Cell: ({ row }: any) => {
        const [deleteConfirm, setDeleteConfirm] = useState(false)
        const [currentIndex, setCurrentIndex] = useState(0);
        const [fieldImages, setFieldImages] = useState([])
  
        const handleShowImages = (field: any) => {
          
          setDeleteConfirm(true)
          setFieldImages(field.product_image)
        }
        const goToPrev = () => {
          const isFirstSlide = currentIndex === 0;
          const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
          setCurrentIndex(newIndex);
        };
  
        const goToNext = () => {
          const isLastSlide = currentIndex === fieldImages?.length - 1;
          const newIndex = isLastSlide ? 0 : currentIndex + 1;
          setCurrentIndex(newIndex);
        };
        return (
          <>
            <div >
  
              {!row.original.product_image || row.original.product_image?.length === 0 ? <>
                <a
  
                  href="#"
  
                >
                  <img src={noImage} height={60} width={100} />
                </a>
              </> : <>
                <a
                  onClick={() => handleShowImages(row.original)}
                  href={`#galleryModal${row.original.name}`}
  
                  data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                  <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
                </a>
              </>}
  
            </div>
  
            <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
              centered    >
  
              <Modal.Body>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
  
                  </div>
  
  
  
                  <div className="carousel-inner">
                    {row.original.product_image?.map((imgSrc: any, index: any) => (
                      <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                        <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                      </div>
                    ))}
                  </div>
  
                  <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                    <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                    <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" onClick={goToNext} >
                    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                    <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: 'space-between' }}>
                <p>{currentIndex + 1}/{fieldImages.length}</p>
                <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
  
              </Modal.Footer>
            </Modal>
          </>)
      }
    },
    
       {
    id: `product_pdf_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
            {!row.original.product_pdf_drawing || row.original.product_pdf_drawing?.length === 0 ? <>
              <a

                href="#"
                className="text-danger"
              >
                No Document Uploaded
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_pdf_drawing.length > 0 ? row.original.product_pdf_drawing.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.product_pdf_drawing?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },
    




{
  id: 'Actions',
  Header: 'Actions',


},
  {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_SingleCode_Other, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}single_code/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



