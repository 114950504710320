import React from 'react'
import Header from '../components/Header/header'
import Sidebar from '../components/Sidebar/sidebar'
import { Route, Routes, Navigate } from 'react-router-dom'
import Table from '../components/Table/Table'
import AddColor from '../pages/Color/Add_Color'
import EditColor from '../pages/Color/Edit_Color'
import AddBrand from '../pages/Brand/Add_Brand'
import BrandList from '../pages/Brand/List_Brand'
import EditBrand from '../pages/Brand/Edit_Brand'
import Footer from '../components/Footer/footer'
import Gst_Add from '../pages/GST/Add_Gst'
import Gst_Edit from '../pages/GST/Edit_Gst'
import GstList from '../pages/GST/List_Gst'
import Material_Density_List from '../pages/Material Density/List_MaterialDensity'
import Material_Density_Add from '../pages/Material Density/Add_MaterialDensity'
import Material_Density_Edit from '../pages/Material Density/Edit_MaterialDensity'
import HsnList from '../pages/HSN/List_Hsn'
import Hsn_Add from '../pages/HSN/Add_Hsn'
import Hsn_Edit from '../pages/HSN/Edit_Hsn'
import Add_Machine from '../pages/Machine/Add_Machine'
import Add_Panni from '../pages/Panni/Add_Panni'
import Edit_Machine from '../pages/Machine/Edit_Machine'
import List_Machine from '../pages/Machine/List_Machine'
import Add_Material_Type from '../pages/Material Type/Add_Material_Type'
import List_Material_Type from '../pages/Material Type/List_Material_Type'
import Edit_Material_Type from '../pages/Material Type/Edit_Material_Type'
import List_StickPacking from '../pages/Stick Packing/List_StickPacking'
import Add_StickPacking from '../pages/Stick Packing/Add_StickPacking'
import Edit_StickPacking from '../pages/Stick Packing/Edit_StickPacking'
import Add_MouldShape from '../pages/Mould Shape/Add_MouldShape'
import Edit_MouldShape from '../pages/Mould Shape/Edit_MouldShape'
import List_MouldShape from '../pages/Mould Shape/List_MouldShape'
import Add_Capacity from '../pages/UOM/Add_UOM'
import Edit_Capacity from '../pages/UOM/Edit_UOM'
import List_Capacity from '../pages/UOM/List_UOM'
import Add_ProductType from '../pages/Product Type/Add_ProductType'
import Edit_ProductType from '../pages/Product Type/Edit_ProductType'
import Edit_Panni from '../pages/Panni/Edit_Panni'
import List_Panni from '../pages/Panni/List_Panni'
import Add_Mould from '../pages/Mould/Add_Mould'
import List_Mould from '../pages/Mould/List_Mould'
import Edit_Mould from '../pages/Mould/Edit_Mould'
import Add_Cavity from '../pages/Cavity/Add_Cavity'
import Edit_Cavity from '../pages/Cavity/Edit_Cavity'
import List_Cavity from '../pages/Cavity/List_Cavity'
import List_TapeRoll from '../pages/Tape Roll/List_TapeRoll'
import Add_TapeRoll from '../pages/Tape Roll/Add_TapeRoll'
import Edit_TapeRoll from '../pages/Tape Roll/Edit_TapeRoll'
import Add_Document from '../pages/Document/Add_Document'
import List_Document from '../pages/Document/List_Document'
import Edit_Document from '../pages/Document/Edit_Document'
import Add_SingleCode from '../pages/Single Code/Add_SingleCode'
import List_RawMaterialGrade from '../pages/RawMaterialGrade/List_RawMaterialGrade'
import Add_RawMaterialGrade from '../pages/RawMaterialGrade/Add_RawMaterialGrade'
import Edit_RawMaterialGrade from '../pages/RawMaterialGrade/Edit_RawMaterialGrade'
import List_BoxPatti from '../pages/Box Patti/List_BoxPatti'
import Add_BoxPatti from '../pages/Box Patti/Add_BoxPatti'
import Edit_BoxPatti from '../pages/Box Patti/Edit_BoxPatti'
import List_Carton from '../pages/Carton/List_Carton'
import Add_Carton from '../pages/Carton/Add_Carton'
import Edit_Carton from '../pages/Carton/Edit_Carton'
import List_NumberSystem from '../pages/Number System/List_NumberSystem'
import Add_NumberSystem from '../pages/Number System/Add_NumberSystem'
import Edit_NumberSystem from '../pages/Number System/Edit_NumberSystem'
// import List_SingleCode from '../pages/Single Code/List_SingleCode'
import Edit_SingleCode from '../pages/Single Code/Edit_SingleCode'
import List_PriceList from '../pages/Price List/List_PriceList'
import Add_PriceList from '../pages/Price List/Add_PriceList'
import Edit_PriceList from '../pages/Price List/Edit_PriceList'
// import List_DisposableProduct from '../pages/Disposable Product/List_DisposableProduct'
import Add_DisposableProduct_Copy from '../pages/Disposable Product/Add_DisposableProduct copy'
import Edit_DisposableProduct_Copy from '../pages/Disposable Product/Edit_DisposableProduct copy'
import List_HrDepartment from '../pages/HR/HrDepartment/List_HrDepartment'
import Add_HrDepartment from '../pages/HR/HrDepartment/Add_HrDeparttment'
import Edit_HrDepartment from '../pages/HR/HrDepartment/Edit_HrDeparttment'
import List_Sales from '../pages/Sales_Marketing/Sales/List_Sales'
import List_Source_Inquiry from '../pages/Sales_Marketing/Source Inquiry/List_Source_Inquiry'
import List_Online_Sales_Inquiry from '../pages/Sales_Marketing/Online Sales Inquiry/List_Online_Sales_Inquiry'
import Add_Sales from '../pages/Sales_Marketing/Sales/Add_Sales'
import Add_Source_Inquiry from '../pages/Sales_Marketing/Source Inquiry/Add_Source_inquiry'
import Add_Online_Sales_Inquiry from '../pages/Sales_Marketing/Online Sales Inquiry/Add_Online_Sales_Inquiry'
import Add_Zone from '../pages/Sales_Marketing/Zone/Add_Zone'
import Add_State from '../pages/Sales_Marketing/State/Add_State'
import Add_City from '../pages/Sales_Marketing/City/Add_City'
// import Add_CustomerDetail from '../pages/Sales_Marketing/Customer Detail/Add_CustomerDetail'
import Add_Country from '../pages/Sales_Marketing/Country/Add_Country'
import List_Zone from '../pages/Sales_Marketing/Zone/List_Zone'
import List_City from '../pages/Sales_Marketing/City/List_City'
import List_State from '../pages/Sales_Marketing/State/List_State'
import List_Country from '../pages/Sales_Marketing/Country/List_Country'
import Columnsettings from '../pages/coldispsetting/setting'
import Add_CheckList from '../pages/CheckList/Add_CheckList'
import List_CheckList from '../pages/CheckList/List_CheckList'
import Edit_CheckList from '../pages/CheckList/Edit_CheckList'
import Form from '../components/Form Component/Form'
import Custom_Form from '../Form/Form'
import PageNotFound from '../pages/PageNotFound'
import Add_UserAdmin from '../pages/User Admin/Add_UserAdmin'
import Edit_UserAdmin from '../pages/User Admin/Edit_UserAdmin'
import List_UserAdmin from '../pages/User Admin/List_UserAdmin'
import List_Color from '../pages/Color/List_Color'
import Add_UserType from '../pages/User Type/Add_UserType'
import List_UserType from '../pages/User Type/List_UserType'
import Edit_UserType from '../pages/User Type/Edit_UserType'
import Editable from '../components/Editable_Table/Editable'
import List_Cartoon_Ply from '../pages/Cartoon_Ply/List_Cartoon_Ply'
import List_Task_List from '../pages/Task_List/List_TaskList'
import Add_HrDesignation from '../pages/Sales_Marketing/Sales_Designation/Add_Sales_Designation'
import Edit_HrDesignation from '../pages/Sales_Marketing/Sales_Designation/Edit_Sales_Designation'
import { Add_Employee } from '../pages/Employee/Add_Employee'
import List_SingleCode_Disposable from '../pages/Single Code/List_SingleCode_Disposable'
import List_SingleCode_LLDP from '../pages/Single Code/List_SingleCode_LLDP'
import List_SingleCode_LDPE from '../pages/Single Code/List_SingleCode_LDPE'
import List_Disposable_Disposable from '../pages/Disposable Product/List_DisposableProduct_Disposable'
import List_Disposable_LLDPE from '../pages/Disposable Product/List_DisposableProduct_LLDPE'
import List_Disposable_LDPE from '../pages/Disposable Product/List_DisposableProduct_LDPE'
import List_Employee from '../pages/Employee/List_Employee'
import Edit_Employee from '../pages/Employee/Edit_Employee'
import Add_Account from '../pages/Account/Add_Account'
import List_Account from '../pages/Account/List_Account'
import Edit_Account from '../pages/Account/Edit_Account'
import Main_Dashboard from '../pages/Sales_Dashboard/sales_Dashboard'
import Sales_Dashboard from '../pages/Sales_Dashboard/sales_Dashboard'
import Marketing_Dashboard from '../pages/Marketing_Dashboard/marketing_Dashboard'
// import Dashboard from '../Dashboard/views/dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux';
import Add_Sales_Designation from '../pages/Sales_Marketing/Sales_Designation/Add_Sales_Designation'
import List_Sales_Designation from '../pages/Sales_Marketing/Sales_Designation/List_Sales_Designation'
import Edit_Sales_Designation from '../pages/Sales_Marketing/Sales_Designation/Edit_Sales_Designation'
import Edit_City from '../pages/Sales_Marketing/City/Edit_City'
import Edit_Zone from '../pages/Sales_Marketing/Zone/Edit_Zone'
import AnimatedCursor from '../Custom_Cursor/customCursor'
import List_Bank_Format from '../pages/Sales_Marketing/Bank_Format/List_Bank_Format'
import Add_Bank_Format from '../pages/Sales_Marketing/Bank_Format/Add_Bank_Format'
import Edit_Bank_Format from '../pages/Sales_Marketing/Bank_Format/Edit_Bank_Format'
import Add_Contact_Format from '../pages/Sales_Marketing/Contact_Format/Add_Contact_Format'
import Edit_Contact_Format from '../pages/Sales_Marketing/Contact_Format/Edit_Contact_Format'
import List_Contact_Format from '../pages/Sales_Marketing/Contact_Format/List_Contact_Format'
import List_Transport_Format from '../pages/Sales_Marketing/Transport_Format/List_Transport_Format'
import Add_Transport_Format from '../pages/Sales_Marketing/Transport_Format/Add_Transport_Format'
import Edit_Transport_Format from '../pages/Sales_Marketing/Transport_Format/Edit_Transport_Format'
import List_Sales_Document from '../pages/Sales_Marketing/Sales_Document/List_Sales_Document'
import Add_Sales_Document from '../pages/Sales_Marketing/Sales_Document/Add_Sales_Document'
import Edit_Sales_Document from '../pages/Sales_Marketing/Sales_Document/Edit_Sales_Document'
import List_Branch_Office from '../pages/Sales_Marketing/Branch_Office/List_Branch_Office'
import List_Address_Details from '../pages/Sales_Marketing/Address_Details/List_Address_Details'
import Add_Branch_Office from '../pages/Sales_Marketing/Branch_Office/Add_Branch_Office'
import Add_Address_Details from '../pages/Sales_Marketing/Address_Details/Add_Address_Details'
import Edit_Address_Details from '../pages/Sales_Marketing/Address_Details/Edit_Address_Details'
import Edit_Branch_Office from '../pages/Sales_Marketing/Branch_Office/Edit_Branch_Office'
import Add_WareHouse from '../pages/Sales_Marketing/Ware House/Add_WareHouse'
import Edit_WareHouse from '../pages/Sales_Marketing/Ware House/Edit_WareHouse'
import List_WareHouse from '../pages/Sales_Marketing/Ware House/List_WareHouse'
import List_Customer_Details from '../pages/Sales_Marketing/Customer Detail/List_Customer_Detail'
import Add_Customer_Details from '../pages/Sales_Marketing/Customer Detail/Add_Customer_Detail'
import Edit_Customer_Details from '../pages/Sales_Marketing/Customer Detail/Edit_Customer_Detail'
import List_Online_Inquiry from '../pages/Sales_Marketing/Online Inquiry/List_Online_Inquiry'
import Assign_To from '../pages/Sales_Marketing/Online Inquiry/Assign_To'
import Add_Online_Inquiry from '../pages/Sales_Marketing/Online Inquiry/Add_Online_Inquiry'
import Edit_Online_Inquiry from '../pages/Sales_Marketing/Online Inquiry/Edit_Online_Inquiry'
import List_Customer_Grade from '../pages/Sales_Marketing/Customer_Grade/List_Customer_Grade'
import Add_Customer_Grade from '../pages/Sales_Marketing/Customer_Grade/Add_Customer_Grade'
import Edit_Customer_Grade from '../pages/Sales_Marketing/Customer_Grade/Edit_Customer_Grade'
import Add_LeadGeneraed from '../pages/Sales_Marketing/Lead Generated/Add_LeadGenerated'
import Edit_Sales from '../pages/Sales_Marketing/Sales/Edit_Sales'
import List_LeadGeneraed from '../pages/Sales_Marketing/Lead Generated/List_Lead_Generated'
import Edit_LeadGeneraed from '../pages/Sales_Marketing/Lead Generated/Edit_LeadGenerated'
import Edit_Quotation from '../pages/Sales_Marketing/Qutation/Edit_Quotation'
import List_Quotation from '../pages/Sales_Marketing/Qutation/List_Quotation'
import Add_Quotation from '../pages/Sales_Marketing/Qutation/Add_Quotation'
import Quotation from '../pages/Sales_Marketing/Qutation/Quotation'
import Sales_Order from '../pages/Sales_Marketing/Sales Order/Sales_Order'
import Check from '../pages/Sales_Marketing/Qutation/Check'
import Quotation_test from '../pages/Sales_Marketing/Qutation/quotation_test'
import Quotation_Details from '../pages/Sales_Marketing/Qutation/Quotation_Details'
import Stepper from '../pages/Sales_Marketing/Customer Detail/stepper'
import Add_Discount from '../pages/Sales_Marketing/Discount/Add_Discount'
import List_Discount from '../pages/Sales_Marketing/Discount/List_Discount'
import Edit_Discount from '../pages/Sales_Marketing/Discount/Edit_Discount'
import Discount_Approval from '../pages/Sales_Marketing/Discount approval/Discount_Approval'
import Edit_Discount_Approval_View from '../pages/Sales_Marketing/Discount approval/View_Discount_Approval'
import Quotation_Accept from '../pages/Quotation_Accept/Quotation_Accept'
import Dispatch_Order from '../pages/Sales_Marketing/Dispatch_Order/Dispatch_Order'
import List_Sales_Order from '../pages/Sales_Marketing/Sales Order/List_Sales_Order'
import Add_Dispatch from '../pages/Sales_Marketing/Dispatch/Add_Dispatch'
import List_ProductTypeAll from '../pages/Product Type/List_ProductTypeAll'
import List_ProductTypeDisposable from '../pages/Product Type/List_ProductTypeDisposable'
import List_ProductTypeLiner from '../pages/Product Type/List_ProductTypeLiner'
import List_ProductTypeOther from '../pages/Product Type/List_ProductTypeOther'
import List_Dispatch_Order from '../pages/Sales_Marketing/Dispatch_Order/List_Dispatch_Order'
import List_RawMaterialGrade_Other from '../pages/RawMaterialGrade/List_RawMaterialGrade_Other'
import List_RawMaterialGrade_HM from '../pages/RawMaterialGrade/List_RawMaterialGrade_HM'
import List_RawMaterialGrade_LD from '../pages/RawMaterialGrade/List_RawMaterialGrade_LD'
import List_RawMaterialGrade_PP from '../pages/RawMaterialGrade/List_RawMaterialGrade_PP'
import List_Dispatched from '../pages/Sales_Marketing/Dispatch/List_Dispatched'
import Verify_Products_List from '../pages/Sales_Marketing/Verify_Products_List/Verify_Products_List'
import List_RawMaterialGrade_LLDP from '../pages/RawMaterialGrade/List_RawMaterialGrade_LLDP'
import Verify_Products_List_Id from '../pages/Sales_Marketing/Verify_Products_List/Verify_Products_List_Id'
import List_TapeRoll_Bopp from '../pages/Tape Roll/List_TapeRollBopp'
import List_TapeRoll_PP from '../pages/Tape Roll/List_TapeRollPP'
import List_TapeRoll_Other from '../pages/Tape Roll/List_TapeRollOther'
import List_BoxPatti_Bopp from '../pages/Box Patti/List_BoxPattiBopp'
import List_BoxPatti_PP from '../pages/Box Patti/List_BoxPattiPP'
import List_BoxPatti_Other from '../pages/Box Patti/List_BoxPattiOther'
import List_Panni_PP from '../pages/Panni/List_Panni_PP'
import List_Panni_LD from '../pages/Panni/List_Panni_LD'
import List_Panni_LLDP from '../pages/Panni/List_Panni_LLDP'
import List_Panni_HM from '../pages/Panni/List_Panni_HM'
import List_Panni_Other from '../pages/Panni/List_Panni_Other'
import List_Employee_Other from '../pages/Employee/List_Employee_Other'
import List_Employee_Yearly from '../pages/Employee/List_Employee_Yearly'
import List_Employee_Monthly from '../pages/Employee/List_Employee_Monthly'
import List_Employee_Daily from '../pages/Employee/List_Employee_Daily'
import List_Employee_Contract from '../pages/Employee/List_Employee_Contract'
import Add_Personal_Details from '../pages/Sales_Marketing/Personal Details/Add_Personal_Details'
import Edit_Personal_Details from '../pages/Sales_Marketing/Personal Details/Edit_Personal_Details'
import Add_Sales_Test from '../pages/Sales_Marketing/Sales/Add_Sales copy'
import Add_Sales_Inquiry from '../pages/Sales_Marketing/Sales/Add_Sales_Inquiry'
import Edit_Basic_Details from '../pages/Sales_Marketing/Basic Details/Edit_Basic_Details'
import Edit_Sales_Inquiry from '../pages/Sales_Marketing/Sales/Edit_Sales_Inquiry'
import Add_Maintenance from '../pages/Sales_Marketing/Maintenance/Add_Maintenance'
import List_Maintenance from '../pages/Sales_Marketing/Maintenance/List_Maintenance'
import Edit_Maintenance from '../pages/Sales_Marketing/Maintenance/Edit_Maintenance'
import List_Maintenance_Responsible from '../pages/Sales_Marketing/Maintenance/Responsible Maintenance/List_Maintenance_Responsible'
import Add_Maintenance_Action from '../pages/Sales_Marketing/Maintenance/Add_Maintenance_Action'
import Add_DisposableProduct from '../pages/Disposable Product/Add_DisposableProduct'
import Checklist_Report from '../pages/CheckList/Checklist Report/Checklist_Report'
import Add_Maintenance_Complaint from '../pages/Sales_Marketing/Maintenance/Maintenance Complaint/Add_Maintenance_Complaint'
import List_Maintenance_Complaint from '../pages/Sales_Marketing/Maintenance/Maintenance Complaint/List_Maintenance_Complaint'
import List_Maintenance_Status from '../pages/Sales_Marketing/Maintenance/Maintenance Status/List_Maintenance_Status'
import Add_Sheet_Code from '../pages/Production/Sheet Code/Add_Sheet_Code'
import List_Sheet_Code from '../pages/Production/Sheet Code/List_Sheet_Code'
import Edit_Sheet_Code from '../pages/Production/Sheet Code/Edit_Sheet_Code'
import Granual from '../pages/Production/Granual/Granual'
import List_Granual from '../pages/Production/Granual/List_Granual'
import Sheet_Roll_Code from '../pages/Production/Sheet Code/Sheet_Roll_Code'
import List_Sheet_Roll_Code from '../pages/Production/Sheet Code/List_Sheet_Roll_Code'
import TFM_Transaction from '../pages/Production/TFM/TFM_Transaction'
import List_Customer_Detail_Employee from '../pages/Sales_Marketing/Customer Detail/List_Customer_Detail_Employee'
import List_Customer_Detail_Supplier from '../pages/Sales_Marketing/Customer Detail/List_Customer_Detail_Supplier'
import List_Customer_Detail_Other from '../pages/Sales_Marketing/Customer Detail/List_Customer_Detail_Other'
import List_Customer_Detail_Customer from '../pages/Sales_Marketing/Customer Detail/List_Customer_Detail_Customer'
import List_TFM_Transaction from '../pages/Production/TFM/List_TFM_Transaction'
import Edit_DisposableProduct from '../pages/Disposable Product/Edit_DisposableProduct'
import Test from '../pages/HRMS/test'
import HRMS from './HRMS'
import List_SingleCode_HDPE from '../pages/Single Code/List_SingleCode_HDPE'
import List_SingleCode_LDPE_Shrink from '../pages/Single Code/List_SingleCode_LDPE_Shrink'
import List_SingleCode_PP from '../pages/Single Code/List_SingleCode_PP'
import List_SingleCode_Other from '../pages/Single Code/List_SingleCode_Other'
import List_Disposable_LDPE_Shrink from '../pages/Disposable Product/List_DisposableProduct_LDPE_Shrink'
import List_Disposable_HDPE from '../pages/Disposable Product/List_DisposableProduct_HDPE'
import List_Disposable_PP from '../pages/Disposable Product/List_DisposableProduct_PP'
import List_Disposable_Other from '../pages/Disposable Product/List_DisposableProduct_Other'
import List_Panni_LDPE_Shrink from '../pages/Panni/List_Panni_LDPE_Shrink'
import Antd_Sidebar from '../components/Sidebar/Antd_Sidebar'
import Antd_Header from '../components/Header/Antd_header'
import Qr from '../pages/Qr/Qr'
import QrCopy from '../pages/Qr/Qr copy'
import List_Disposable_Shrink from '../pages/Disposable Product/List_DisposableProduct_Shrink'
import List_SingleCode_Shrink from '../pages/Single Code/List_SingleCode_LDPE_Shrink copy'
import List_Online_Inquiry_Report from '../pages/Sales_Marketing/Online Inquiry/Online Inquiry Report/List_Online_Inquiry_Report'


const Colorlayout = () => {

    // const isDash = useSelector((state:any) => state.isDash.status)

    return (
        <div className='wrapper'>
            {/* <AnimatedCursor/> */}
            <Header isDashboard={false} />
            {/* <Antd_Header/> */}
            <Routes>


                {/* <Route path='salesDashboard' element={
                    <Sales_Dashboard />
                } />

<Route path='marketingDashboard' element={
                    <Marketing_Dashboard />
                } /> */}

                <Route path='/' element={<Navigate to="/salesDashboard" />} />


                <Route path='listColor' element={
                    <List_Color />
                } />
                <Route path='addColor' element={
                    <AddColor />
                } />
                <Route path='editColor/:id' element={
                    <EditColor />
                } />

                <Route path='listBrand' element={
                    <BrandList />
                } />
                <Route path='addBrand' element={
                    <AddBrand />
                } />
                <Route path='editBrand/:id' element={
                    <EditBrand />
                } />


                {/* <Route path='pannilist' element={
                    // <PanniList/>
                    <></>
                } />

                <Route path='addpanni' element={
                    <AddPanni />
                } />

                <Route path='editpanni/:id' element={
                    <EditPanni />
                } /> */}

                <Route path='listMachinename' element={
                    <List_Machine />
                } />

                <Route path='addMachine' element={
                    <Add_Machine />
                } />

                <Route path='editMachine/:id' element={
                    <Edit_Machine />
                } />



                <Route path='listDepartment' element={
                    <List_HrDepartment />
                } />
                {/* <Route path='listHrDepartment' element={
                    <List_HrDepartment />
                } /> */}
                <Route path='addHrDepartment' element={
                    <Add_HrDepartment />
                } />

                <Route path='editdepartment/:id' element={
                    <Edit_HrDepartment />
                } />


                <Route path='listPanni' element={
                    <List_Panni />
                } />
                <Route path='listPanni/PP' element={
                    <List_Panni_PP />
                } />
                <Route path='listPanni/LD' element={
                    <List_Panni_LD />
                } />
                <Route path='listPanni/LLDP' element={
                    <List_Panni_LLDP />
                } />
                <Route path='listPanni/LDPEShrink' element={
                    <List_Panni_LDPE_Shrink />
                } />
                <Route path='listPanni/HM' element={
                    <List_Panni_HM />
                } />
                <Route path='listPanni/Other' element={
                    <List_Panni_Other />
                } />
                <Route path='addPanni' element={
                    <Add_Panni />
                } />
                <Route path='editpanniView/:id' element={
                    <Edit_Panni />
                } />




                <Route path='listMaterialType' element={
                    <List_Material_Type />
                } />
                <Route path='addMaterialType' element={
                    <Add_Material_Type />
                } />
                <Route path='editMaterialType/:id' element={
                    <Edit_Material_Type />
                } />


                <Route path='listCarton' element={
                    <List_Carton />
                } />
                <Route path='addCarton' element={
                    <Add_Carton />
                } />
                <Route path='editCarton/:id' element={
                    <Edit_Carton />
                } />


                <Route path='listAllCode' element={
                    <List_NumberSystem />
                } />
                <Route path='addNumberSystem' element={
                    <Add_NumberSystem />
                } />
                <Route path='editNumberSystem/:id' element={
                    <Edit_NumberSystem />
                } />



                <Route path='listBoxpatti' element={
                    <List_BoxPatti />
                } />

                <Route path='listBoxpatti/Bopp' element={
                    <List_BoxPatti_Bopp />
                } />
                <Route path='listBoxpatti/PP' element={
                    <List_BoxPatti_PP />
                } />
                <Route path='listBoxpatti/Other' element={
                    <List_BoxPatti_Other />
                } />


                <Route path='addBoxPatti' element={
                    <Add_BoxPatti />
                } />
                <Route path='editBoxPatti/:id' element={
                    <Edit_BoxPatti />
                } />


                <Route path='listStickPacking' element={
                    <List_StickPacking />
                } />
                <Route path='addStickPacking' element={
                    <Add_StickPacking />
                } />
                <Route path='editStickPacking/:id' element={
                    <Edit_StickPacking />
                } />



                <Route path='listGST' element={
                    <GstList />
                } />
                <Route path='addGST' element={
                    <Gst_Add />
                } />
                <Route path='editGST/:id' element={
                    <Gst_Edit />
                } />



                <Route path='listMouldShape' element={
                    <List_MouldShape />
                } />
                <Route path='addMouldShape' element={
                    <Add_MouldShape />
                } />
                <Route path='editMouldShape/:id' element={
                    <Edit_MouldShape />
                } />





                <Route path='listMould' element={
                    <List_Mould />
                } />
                <Route path='addMould' element={
                    <Add_Mould />
                } />
                <Route path='editMould/:id' element={
                    <Edit_Mould />
                } />



                <Route path='listUom' element={
                    <List_Capacity />
                } />
                <Route path='addUom' element={
                    <Add_Capacity />
                } />
                <Route path='editUom/:id' element={
                    <Edit_Capacity />
                } />


                <Route path='listCavity' element={
                    <List_Cavity />
                } />
                <Route path='addCavity' element={
                    <Add_Cavity />
                } />
                <Route path='editCavity/:id' element={
                    <Edit_Cavity />
                } />



                <Route path='/listProducttype' element={
                    <List_ProductTypeAll />
                } />
                <Route path='/listProductType/Disposable' element={
                    <List_ProductTypeDisposable />
                } />
                <Route path='/listProductType/Liner' element={
                    <List_ProductTypeLiner />
                } />
                <Route path='/listProductType/Other' element={
                    <List_ProductTypeOther />
                } />
                <Route path='addProductType' element={
                    <Add_ProductType />
                } />
                <Route path='editProductType/:id' element={
                    <Edit_ProductType />
                } />



                <Route path='listTaperoll' element={
                    <List_TapeRoll />
                } />
                <Route path='listTaperoll/Bopp' element={
                    <List_TapeRoll_Bopp />
                } />
                <Route path='listTaperoll/PP' element={
                    <List_TapeRoll_PP />
                } />
                <Route path='listTaperoll/Other' element={
                    <List_TapeRoll_Other />
                } />

                <Route path='addTapeRoll' element={
                    <Add_TapeRoll />
                } />
                <Route path='editTapeRoll/:id' element={
                    <Edit_TapeRoll />
                } />


                <Route path='listDocument' element={
                    <List_Document />
                } />
                <Route path='addDocument' element={
                    <Add_Document />
                } />
                <Route path='editDocument/:id' element={
                    <Edit_Document />
                } />


                <Route path='/listRawMaterialGrade/PP' element={
                    <List_RawMaterialGrade_PP />
                } />
                <Route path='/listRawMaterialGrade/LD' element={
                    <List_RawMaterialGrade_LD />
                } />
                <Route path='/listRawMaterialGrade/LLDP' element={
                    <List_RawMaterialGrade_LLDP />
                } />
                <Route path='/listRawMaterialGrade/HM' element={
                    <List_RawMaterialGrade_HM />
                } />
                <Route path='/listRawMaterialGrade/Other' element={
                    <List_RawMaterialGrade_Other />
                } />
                <Route path='/listRawmaterialgrade' element={
                    <List_RawMaterialGrade />
                } />
                <Route path='addRawMaterialGrade' element={
                    <Add_RawMaterialGrade />
                } />
                <Route path='editRawMaterialGrade/:id' element={
                    <Edit_RawMaterialGrade />
                } />




                <Route path='/listSingleCode' element={
                    <List_SingleCode_Disposable />
                } />
                <Route path='/listSingleCode/LLDP' element={
                    <List_SingleCode_LLDP />
                } />
                <Route path='/listSingleCode/LDPE' element={
                    <List_SingleCode_LDPE />
                } />
                <Route path='/listSingleCode/HDPE' element={
                    <List_SingleCode_HDPE />
                } />
                <Route path='/listSingleCode/LDPEShrink' element={
                    <List_SingleCode_LDPE_Shrink />
                } />
                <Route path='/listSingleCode/Shrink' element={
                    <List_SingleCode_Shrink />
                } />
                <Route path='/listSingleCode/PP' element={
                    <List_SingleCode_PP />
                } />
                <Route path='/listSingleCode/Other' element={
                    <List_SingleCode_Other />
                } />
                <Route path='addSingleCode' element={
                    <Add_SingleCode />
                } />
                <Route path='editSingleCode/:id' element={
                    <Edit_SingleCode />
                } />


                <Route path='listPriceList' element={
                    <List_PriceList />
                } />
                <Route path='addPriceList' element={
                    <Add_PriceList />
                } />
                <Route path='editPriceList/:id' element={
                    <Edit_PriceList />
                } />


                <Route path='listDisposableproducts' element={
                    <List_Disposable_Disposable />
                } />
                <Route path='listDisposableproducts/LLDPE' element={
                    <List_Disposable_LLDPE />
                } />
                <Route path='listDisposableproducts/LDPE' element={
                    <List_Disposable_LDPE />
                } />
                <Route path='listDisposableproducts/LDPEShrink' element={
                    <List_Disposable_LDPE_Shrink />
                } />
                <Route path='listDisposableproducts/Shrink' element={
                    <List_Disposable_Shrink />
                } />
                <Route path='listDisposableproducts/HDPE' element={
                    <List_Disposable_HDPE />
                } />
                <Route path='listDisposableproducts/PP' element={
                    <List_Disposable_PP />
                } />
                <Route path='listDisposableproducts/Other' element={
                    <List_Disposable_Other />
                } />
                <Route path='addDisposableProduct' element={
                    <Add_DisposableProduct />
                } />
                <Route path='editDisposableProduct/:id' element={
                    <Edit_DisposableProduct />
                } />




                <Route path='listMaterialDensity' element={
                    <Material_Density_List />
                } />

                <Route path='addMaterialDensity' element={
                    <Material_Density_Add />
                } />


                <Route path='editMaterialDensity/:id' element={
                    <Material_Density_Edit />
                } />

                <Route path='listHSN' element={
                    <HsnList />
                } />

                <Route path='addHSN' element={
                    <Hsn_Add />
                } />


                <Route path='editHSN/:id' element={
                    <Hsn_Edit />
                } />


                {/* Sales And Marketing */}



                <Route path='listSalesInquiry' element={
                    <List_Sales />
                } />

                <Route path='addSalesInquiry' element={
                    <Add_Sales_Inquiry />
                } />
                {/* <Route path='addSalesInquiry' element={
                    <Add_Sales />
                } /> */}
                <Route path='editsalesInquiry/:id' element={
                    <Edit_Sales_Inquiry />
                } />
                {/* <Route path='editTestsalesInquiry/:id' element={
                    <Edit_Sales_Inquiry />
                } /> */}



                <Route path='listSourceInquiry' element={
                    <List_Source_Inquiry />
                } />

                <Route path='addSourceInquiry' element={
                    <Add_Source_Inquiry />
                } />



                {/* <Route path='listOnlineInquiry' element={
                    <List_Source_Inquiry />
                } /> */}

                {/* <Route path='addOnlineInquiry' element={
                    <Add_Source_Inquiry />
                } /> */}




                <Route path='listZone' element={
                    <List_Zone />
                } />

                <Route path='addZone' element={
                    <Add_Zone />
                } />






                <Route path='listState' element={
                    <List_State />
                } />

                <Route path='addState' element={
                    <Add_State />
                } />



                <Route path='listCountry' element={
                    <List_Country />
                } />

                <Route path='addCountry' element={
                    <Add_Country />
                } />

                <Route path='settings/columnvisibility' element={
                    <Columnsettings />
                } />




                {/* checkList */}
                <Route path='listCheckList' element={
                    <List_CheckList />
                } />
                <Route path='addCheckList' element={
                    <Add_CheckList />
                } />
                <Route path='editCheckList/:id' element={
                    <Edit_CheckList />
                } />





                <Route path='dynamicForm' element={
                    <Custom_Form />} />


                <Route path='addUser' element={
                    <Add_UserAdmin />
                } />
                <Route path='edituser/:id' element={
                    <Edit_UserAdmin />
                } />
                <Route path='listLeouser' element={
                    <List_UserAdmin />
                } />
                <Route path='addGroup' element={
                    <Add_UserType />
                } />
                <Route path='listGroup' element={
                    <List_UserType />
                } />
                <Route path='editgroup/:id' element={
                    <Edit_UserType />
                } />







                <Route path='addAccount' element={
                    <Add_Account />
                } />
                <Route path='listAccountType' element={
                    <List_Account />
                } />
                <Route path='editAccountType/:id' element={
                    <Edit_Account />
                } />


                <Route path='addEmployee' element={
                    <Add_Employee />
                } />

                <Route path='listEmployee' element={
                    <List_Employee />
                } />
                <Route path='listEmployee/Contract' element={
                    <List_Employee_Contract />
                } />
                <Route path='listEmployee/DailyWages' element={
                    <List_Employee_Daily />
                } />
                <Route path='listEmployee/MonthlySalary' element={
                    <List_Employee_Monthly />
                } />
                <Route path='listEmployee/YearlySalary' element={
                    <List_Employee_Yearly />
                } />
                <Route path='listEmployee/Other' element={
                    <List_Employee_Other />
                } />
                {/* <Route path='editEmployee/:id' element={
                    <Edit_Employee />
                } /> */}

                <Route path='listTask' element={
                    <List_Task_List />
                } />
                <Route path='editable' element={
                    <Editable />
                } />



                {/* sales */}


                <Route path='addSalesDesignation' element={
                    <Add_Sales_Designation />
                } />

                <Route path='listDesignation' element={
                    <List_Sales_Designation />
                } />
                <Route path='editDesignation/:id' element={
                    <Edit_Sales_Designation />
                } />


                <Route path='addZone' element={
                    <Add_Zone />
                } />

                <Route path='listZone' element={
                    <List_Zone />
                } />
                <Route path='editZone/:id' element={
                    <Edit_Zone />
                } />



                {/* <Route path='addCustomerDetail' element={
                    <Add_CustomerDetail />
                } /> */}



                <Route path='listCity' element={
                    <List_City />
                } />

                <Route path='addCity' element={
                    <Add_City />
                } />
                <Route path='editCity/:id' element={
                    <Edit_City />
                } />
                <Route path='listBankFormat' element={
                    <List_Bank_Format />
                } />

                <Route path='addBankFormat' element={
                    <Add_Bank_Format />
                } />
                <Route path='editBankFormat/:id' element={
                    <Edit_Bank_Format />
                } />
                <Route path='listContactDetail' element={
                    <List_Contact_Format />
                } />

                <Route path='addContactDetail' element={
                    <Add_Contact_Format />
                } />
                <Route path='editContactDetail/:id' element={
                    <Edit_Contact_Format />
                } />



                <Route path='listTransportdetail' element={
                    <List_Transport_Format />
                } />

                <Route path='addTransportFormat' element={
                    <Add_Transport_Format />
                } />
                <Route path='edittransportDetail/:id' element={
                    <Edit_Transport_Format />
                } />


                <Route path='listDocumentdetail' element={
                    <List_Sales_Document />
                } />

                <Route path='addSalesDocument' element={
                    <Add_Sales_Document />
                } />
                <Route path='editdocumentdetail/:id' element={
                    <Edit_Sales_Document />
                } />
                <Route path='listBranch' element={
                    <List_Branch_Office />
                } />

                <Route path='addBranch' element={
                    <Add_Branch_Office />
                } />
                <Route path='editBranch/:id' element={
                    <Edit_Branch_Office />
                } />
                <Route path='listWarehouse' element={
                    <List_WareHouse />
                } />

                <Route path='addWarehouse' element={
                    <Add_WareHouse />
                } />
                <Route path='editWarehouse/:id' element={
                    <Edit_WareHouse />
                } />



                <Route path='listAddress' element={
                    <List_Address_Details />
                } />

                <Route path='addAddressDetails' element={
                    <Add_Address_Details />
                } />
                <Route path='editaddress/:id' element={
                    <Edit_Address_Details />
                } />
                <Route path='listBank' element={
                    <List_Bank_Format />
                } />

                <Route path='addBank' element={
                    <Add_Bank_Format />
                } />
                <Route path='editBank/:id' element={
                    <Edit_Bank_Format />
                } />


                <Route path='listPersonDetail' element={
                    <List_Customer_Details />
                } />
                <Route path='listPersonDetail/Employee' element={
                    <List_Customer_Detail_Employee />
                } />
                <Route path='listPersonDetail/Customer' element={
                    <List_Customer_Detail_Customer />
                } />
                <Route path='listPersonDetail/Supplier' element={
                    <List_Customer_Detail_Supplier />
                } />
                <Route path='listPersonDetail/Other' element={
                    <List_Customer_Detail_Other />
                } />

                <Route path='addCustomerDetails' element={
                    <Add_Customer_Details />
                } />
                {/* <Route path='editCustomerDetails/:id' element={
                    <Edit_Customer_Details />
                } /> */}
                <Route path='editperson/:id' element={
                    <Edit_Customer_Details />
                } />
                <Route path='listGradeType' element={
                    <List_Customer_Grade />
                } />

                <Route path='addCustomerGrade' element={
                    <Add_Customer_Grade />
                } />
                <Route path='editCustomerGrade/:id' element={
                    <Edit_Customer_Grade />
                } />


                <Route path='listOnlinesalesinquiry' element={
                    <List_Online_Inquiry />
                } />
                <Route path='listOnlinesalesinquiryReport' element={
                    <List_Online_Inquiry_Report />
                } />
                <Route path='addOnlineInquiry' element={
                    <Add_Online_Inquiry />
                } />
                <Route path='editonlineSalesInquiry/:id' element={
                    <Edit_Online_Inquiry />
                } />

                <Route path='assignTo/:id' element={
                    <Assign_To />
                } />
                <Route path='listLeadGenerated' element={
                    <List_LeadGeneraed />
                } />
                <Route path='addLeadGenerated' element={
                    <Add_LeadGeneraed />
                } />
                <Route path='editLeadGenerated/:id' element={
                    <Edit_LeadGeneraed />
                } />
                <Route path='listQuotation' element={
                    <List_Quotation />
                } />
                <Route path='addQuotation' element={
                    <Add_Quotation />
                } />
                <Route path='editQuotation/:id' element={
                    <Edit_Quotation />
                } />

                <Route path='Quotation/:id' element={
                    <Quotation />
                } />
                <Route path='QuotationTest' element={
                    <Quotation_test />
                } />

                <Route path='Check' element={
                    <Check />
                } />
                <Route path='QuotationDetails' element={
                    <Quotation_Details />
                } />

                <Route path='addDiscount' element={
                    <Add_Discount />
                } />
                <Route path='listDiscount' element={
                    <List_Discount />
                } />
                <Route path='editDiscount/:id' element={
                    <Edit_Discount />
                } />



                <Route path='stepper' element={
                    <Stepper />
                } />
                <Route path='listDiscountApproval' element={
                    <Discount_Approval />
                } />
                <Route path='editpending_approvers/:id' element={
                    <Edit_Discount_Approval_View />
                } />

                <Route path='addDispatchorder/:id' element={
                    <Dispatch_Order />
                } />
                <Route path='listDispatchorder' element={
                    <List_Dispatch_Order />
                } />
                {/* <Route path='quotationAccepted' element={
                    <Quotation_Accept/>
                } />  */}

                <Route path='addSalesorder' element={
                    <Sales_Order />
                } />
                <Route path='listSalesorder' element={
                    <List_Sales_Order />
                } />

                <Route path='addDispatch' element={
                    <Add_Dispatch />
                } />
                <Route path='listDispatch' element={
                    <List_Dispatched />
                } />

                <Route path='verifydispatch_order_list/' element={
                    <Verify_Products_List />
                } />

                <Route path='verifydispatch_order_list_id/:id' element={
                    <Verify_Products_List_Id />
                } />




                <Route path='addPersonalDetails' element={
                    <Add_Personal_Details />
                } />

                <Route path='editPersonalDetails/:id' element={
                    <Edit_Personal_Details />
                } />


                {/* ----------------- Maintenance----------- */}

                <Route path='addMaintenance' element={
                    <Add_Maintenance />
                } />
                <Route path='addMaintenanceResponsible/:id' element={
                    <Edit_Maintenance />
                } />
                <Route path='listMaintenanceResponsible' element={
                    <List_Maintenance_Responsible />
                } />
                <Route path='editMaintenanceAction/:id' element={
                    <Add_Maintenance_Action />
                } />
                <Route path='addComplaintMaintenance' element={
                    <Add_Maintenance_Complaint />
                } />
                <Route path='listComplaintMaintenance' element={
                    <List_Maintenance_Complaint />
                } />
                <Route path='listStatusReport' element={
                    <List_Maintenance_Status />
                } />



                <Route path='listMaintenance' element={
                    <List_Maintenance />
                } />



                {/* -----------------Production----------- */}

                <Route path='addSheetCode' element={
                    <Add_Sheet_Code />
                } />

                <Route path='listSheet' element={
                    <List_Sheet_Code />
                } />
                <Route path='editsheet/:id' element={
                    <Edit_Sheet_Code />
                } />
                <Route path='sheetRollCode/:id' element={
                    <Sheet_Roll_Code />
                } />
                <Route path='listSheetRoll' element={
                    <List_Sheet_Roll_Code />
                } />

                {/* <Route path='addTFMTransaction' element={
                    <TFM_Transaction />
                } /> */}
                <Route path='listTFMTransaction' element={
                    <List_TFM_Transaction />
                } />
                <Route path='listProductionline' element={
                    <List_Granual />
                } />




                <Route path='addDisposableProductCopy' element={
                    <Add_DisposableProduct_Copy />
                } />
                <Route path='EditDisposableProductCopy/:id' element={
                    <Edit_DisposableProduct_Copy />
                } />



                <Route path='checkListReport' element={
                    <Checklist_Report />
                } />

                <Route path='HrmsTest' element={
                    <Test />
                } />
                <Route path='Qr' element={
                    <Qr />
                } />
                <Route path='QrCopy' element={
                    <QrCopy />
                } />
                {/* <Route path='addTestSales' element={
                    <Add_Sales_Test />
                } />
                <Route path='addTestSales2' element={
                    <Add_Sales_Test_2 />
                } /> */}


                <Route path='/*' element={

                    <HRMS />

                } />
                <Route path='*' element={
                    <PageNotFound />} />



            </Routes>





            {/* <Sidebar /> */}
            <Antd_Sidebar />
            <Footer />

        </div>
    )
}

export default Colorlayout