import { useEffect, useRef, useState } from "react";
// import "../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUpdateSingle, useCreate, useUpdateSingleforcewithyes, useUpdateSingleforcewithno } from "../../../hooks/Api";
import useFocusOnEnter from "../../../components/focus";
import { useFetchEmployeeDropDown, useFetchCustomerDropDown, useFetchIFSCDropDown, useFetchBankAccountTypeDropDown, useFetchPriorityDropDown } from "../../../hooks/DropDown_Api";
import Button from "react-bootstrap/esm/Button";
import { UploadFile } from "antd";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Edit_Bank_Modal from "./Edit_Bank_Modal";
import Add_Bank_Modal from "./Add_Bank_Modal";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import { Columns_Bank_Format, titleofheaders } from "./Columns_Bank_Format";

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_Bank_Format = ({ currentStep, setCurrentStep }: any) => {
  let pageName = "Bank Format";
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  // const { id } = useParams();




  let rawData = localStorage.getItem('Add Data');
  let addData = rawData ? JSON.parse(rawData) : null;

  // const id = addData?.bank?.id
  const tempData = addData?.bank
  // const data = tempData
  const prevDataRef = useRef();

  const [data, setData]: any = useState(tempData);
  const [id, setId] = useState()






  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_BANK_API?.toString();
  // const { data } = useFetchSingle(id, apiname);
  const {
    mutate,
    isSuccess,
    isError,
    error,
    data: gstUpdated_response,
  }: any = useUpdateSingle();

  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()



  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  }: any = useForm();






  // useEffect(() => {
  //   const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
  //   if (data && isDataChanged) {
  //     reset(data);
  //     prevDataRef.current = data;
  //   }
  // }, [data, reset]);




  const [PersonType, setPersonType]: any = useState("");

  const onPersonTypeChange = (e: any) => {
    setPersonType(e.target.value)
  }
  const [employeeOptions, setEmployeeOptions]: any = useState()
  const [forEmployeeValue, setForEmployeeValue]: any = useState(0)
  const [selEmployee, setSelEmployee]: any = useState("")
  const [selectedEmployee, setSelectedEmployee]: any = useState("")


  const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


  useEffect(() => {
    setEmployeeOptions(employeeData)
  }, [employeeData])

  const handleChangeEmployee = (e: any) => {

    setForEmployeeValue(e)
    setSelEmployee(e)
    setValue("employee", e.value)

  }

  const [options, setOptions]: any = useState([])
  const [forCustomerValue, setCustomerValue]: any = useState(0)
  const [selCustomer, setSelCustomer]: any = useState("")
  const [selectedCustomer, setSelectedCustomer]: any = useState("")
  const { data: customerData, refetch: customerRefetch } = useFetchCustomerDropDown()


  useEffect(() => {
    setOptions(customerData)
  }, [customerData])

  const handleChange = (e: any) => {

    setCustomerValue(e)
    setSelCustomer(e)
    setValue("customer", e?.value)

  }

  const [IFSCid, setIFSCid]: any = useState()
  const [selectedIFSC, setSelectedIFSC]: any = useState()
  const [check, setCheck] = useState("")



  const apinamePriority = process.env.REACT_APP_BANK_PRIORITY_TYPE_API?.toString()
  const { mutate: mutatePriority, data: dataPriority, isError: isErrorPriority, isLoading: isLoadingPriority, isSuccess: isSuccessPriority, error: errorPriority }: any = useCreate(apinamePriority)
  const [optionsPriority, setOptionsPriority]: any = useState([]);
  const [valuePriority, setValuePriority]: any = useState();
  const [selectedPriority, setSelectedPriority]: any = useState('');
  const [valuePriorityCreatable, setValuePriorityCreatable] = useState<Option | null>();
  const { data: PriorityData, refetch: PriorityRefetch } = useFetchPriorityDropDown()
  useEffect(() => {
    setOptionsPriority(PriorityData)
  }, [PriorityData])

  const handleCreatePriority = async (inputValue: string) => {
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setValue("name", `${newOption?.value}`)
      mutatePriority({ name: newOption.name, status: newOption.status })
      setOptionsPriority((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValuePriorityCreatable(newOption);
    }, 0);

  };

  const handleChangePriority = (e: any) => {

    PriorityRefetch()
    setApiName(apinamePriority)
    setDeleteApiName(apinamePriority)
    setEditId(e?.value)
    setEditData(PriorityData)
    setRenderKey(prevKey => prevKey + 1);

    setValuePriority(e)
    setValue("priority", `${e?.value}`)
    setValuePriorityCreatable(null)
  }

  useEffect(() => {
    (async () => {
      if (isSuccessPriority && optionsPriority && valuePriorityCreatable != null) {
        await PriorityRefetch();
        setValue("priority", PriorityData[0]?.value)
      }
    })();
    return () => {
    };
  }, [isSuccessPriority, PriorityData]);


  const apinameAccountType = process.env.REACT_APP_BANK_ACCOUNT_TYPE_API?.toString()
  const { mutate: mutateAccountType, data: dataAccountType, isError: isErrorAccountType, isLoading: isLoadingAccountType, isSuccess: isSuccessAccountType, error: errorAccountType }: any = useCreate(apinameAccountType)
  const [optionsAccountType, setOptionsAccountType]: any = useState([]);
  const [valueAccountType, setValueAccountType]: any = useState();
  const [selectedAccountType, setSelectedAccountType]: any = useState('');
  const [valueAccountTypeCreatable, setValueAccountTypeCreatable] = useState<Option | null>();
  const { data: AccountTypeData, refetch: AccountTypeRefetch } = useFetchBankAccountTypeDropDown()
  useEffect(() => {
    setOptionsAccountType(AccountTypeData)
  }, [AccountTypeData])

  const handleCreateAccountType = async (inputValue: string) => {
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setValue("name", `${newOption?.value}`)
      mutateAccountType({ name: newOption.name, status: newOption.status })
      setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValueAccountTypeCreatable(newOption);
    }, 0);

  };

  const handleChangeAccountType = (e: any) => {

    AccountTypeRefetch()
    setApiName(apinameAccountType)
    setDeleteApiName(apinameAccountType)
    setEditId(e?.value)
    setEditData(AccountTypeData)
    setRenderKey(prevKey => prevKey + 1);

    setValueAccountType(e)
    setValue("account_type", `${e?.value}`)
    setValueAccountTypeCreatable(null)
  }

  useEffect(() => {
    (async () => {
      if (isSuccessAccountType && optionsAccountType && valueAccountTypeCreatable != null) {
        await AccountTypeRefetch();
        setValue("account_type", AccountTypeData[0]?.value)
      }
    })();
    return () => {
    };
  }, [isSuccessAccountType, AccountTypeData]);




  // useEffect(() => {
  //   if (data) {

  //     reset(data);
  //     setPersonType(data?.type)
  //     setBankName(data.ifsc_code?.BANK)
  //     setCityName(data.ifsc_code?.CITY)
  //     setBranchName(data.ifsc_code?.BRANCH)
  //     setDistrict(data.ifsc_code?.DISTRICT)
  //     setState(data.ifsc_code?.STATE)
  //     setCountry(data.ifsc_code?.COUNTRY)
  //     setMicr(data.ifsc_code?.MICR)
  //     setPincode(data.ifsc_code?.PINCODE)
  //     setSelectedEmployee(data.employee?.id)
  //     setValue("employee", data.employee?.id)
  //     setSelectedCustomer(data.customer?.id)
  //     setValue("customer", data.customer?.id)
  //     setSelectedPriority(data?.priority?.id)
  //     setValue('priority', data?.priority?.id)
  //     setSelectedAccountType(data?.account_type?.id)
  //     setValue('account_type', data?.account_type?.id)
  //     if (check == "") {

  //       setSelectedIFSC(data?.ifsc_code?.IFSC)
  //       setIFSCid(data?.ifsc_code?.id)
  //     }
  //     let tempImages = data?.image?.map((img: any, index: number) => {
  //       let ctx: UploadFile = {
  //         uid: `${img?.id}`,
  //         name: `${img.title}`,
  //         status: 'done',
  //         url: img?.doc,
  //         thumbUrl: img?.doc,
  //       }

  //       return ctx
  //     })
  //     setUploadFile(tempImages)
  //     setValue("ifsc_code", data?.ifsc_code?.IFSC)

  //     if (data?.ifsc_code?.IFSC) {
  //       setVerify(true)
  //       setChangeIFSC(true)
  //     }

  //     // setSelectedIFSC(data?.ifsc_code?.IFSC)
  //     // setSelectedIFSC(data?.ifsc_code?.id)
  //   }






  // }, []);

  const onSubmit = (bankDetails: any) => {

    bankDetails.ifsc_code = IFSCid


    if (PersonType != "Customer") {
      delete bankDetails.customer
    }
    if (PersonType != "Employee") {
      delete bankDetails.employee
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(bankDetails)) {
      const formvalue: any = value;

      if (key === "image") {
        imageID.map((old: any) => {
          formData.append('cheque_image', old?.id)
        })
        for (let i = 0; i < logo?.length; i++) {
          formData.append('image', logo[i])
        }
      }
      if (typeof value !== "object") {
        if (value != undefined) {
          formData.append(key, formvalue)
        }
      }

    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData,
    };
    mutate(data);
    setValuesofform(data)

  };

  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  const [openEditDateModel, setOpenEditDateModel] = useState(false)
  const [confirmmodal, setConfirmmodal] = useState(false)
  const [valuesofform, setValuesofform] = useState<any>()
  const [expirationDate, setExpirationDate] = useState()

  const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
  const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

  let rawDataTemp = localStorage.getItem('Add Data');
  let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

  let basicIdTemp = addDataTemp ? addDataTemp.id : null;

  const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

  useEffect(() => {
    if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
      setShow(true);
      setshowmessage("success");
      setBasicDetailsInitiated(true);

    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
    isSuccess]);

  const [updateCompleted, setUpdateCompleted] = useState(false)

  useEffect(() => {
    if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

        localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
        setUpdateCompleted(true);
        const currentPath = window.location.pathname;
        if (currentPath === '/addCustomerDetails') {
            setCurrentStep(currentStep + 1)
        };
    }
}, [basicDetailsIsSuccess, basicDetailsData])
  useEffect(() => {
    if (isError) {
      if (error.response?.status === 405) {
        setConfirmmodal(true);
      }
    } else if (isError || yeserror || noerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [isError, yeserror, noerror]);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);
  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }
  const noupdatemodal = () => {
    setOpenEditDateModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {

    delete data.created_at
    delete data.created_by
    let ok = {
      id: basicIdTemp,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditDateModel(false)
  }
  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  const handleValueUppercase = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.toUpperCase())
      .join(' ');
  }

  const apiIFSC = process.env.REACT_APP_IFSC_API?.toString()
  // const  { mutate: IFSC, data:dataIFSC, isError:isErrorIFSC, isLoading:isLoadingIFSC, isSuccess:isSuccessIFSC, error:errorIFSC }:any = useCreate(apiIFSC)
  const [ifscValue, setIfscValue] = useState("")
  const [changeIFSC, setChangeIFSC] = useState(false)
  const [verify, setVerify] = useState(false)
  const [ifscError, setIFSCError] = useState(false)
  const searchTerm = verify ? ifscValue : null;
  const { data: ifscData, refetch: ifscRefetch, isLoading: ifscIsLoading, isError: ifscIsError }: any = useFetchIFSCDropDown(searchTerm)

  const handleChangeIFSC = () => {
    // ifscIsSuccess(false)
    setChangeIFSC(false)
    setVerify(false)
  }

  const handleIFSC = (e: any) => {
    setVerify(false)
    setIfscValue(e.target.value)
    setIFSCError(false)
    // setSelIFSC(e.target.value)
    setCheck(e.target.value)

    // if(check != ""){
    setSelectedIFSC(e.target.value)
    // }

  }

  const onSubmitIFSC = (e: any) => {

    setVerify(true)
    setChangeIFSC(true)

  }

  useEffect(() => {
    if (ifscIsError) {
      setIFSCError(true)
    }
  }, [ifscIsError, ifscError])

  const [BankName, setBankName]: any = useState()
  const [CityName, setCityName]: any = useState()
  const [BranchName, setBranchName]: any = useState()
  const [District, setDistrict]: any = useState()
  const [State, setState]: any = useState()
  const [Country, setCountry]: any = useState()
  const [Micr, setMicr]: any = useState()
  const [Pincode, setPincode]: any = useState()








  useEffect(() => {
    if (ifscData) {
      if (check != "") {
        // setValue("ifsc_code",ifscData?.id)
        setIFSCid(ifscData?.id)
      }
      setBankName(ifscData?.BANK)
      setCityName(ifscData?.CITY)
      setBranchName(ifscData?.BRANCH)
      setDistrict(ifscData?.DISTRICT)
      setState(ifscData?.STATE)
      setCountry(ifscData?.COUNTRY)
      setMicr(ifscData?.MICR)
      setPincode(ifscData?.PINCODE)
    }
  }, [ifscData])


  const [uploadFile, setUploadFile]: any = useState<UploadFile[]>([])
  const [imageID, setImageID]: any = useState([])
  const [logo, setlogo]: any = useState([])

  const FileOnChange = (e: any) => {
    e.file.status = "done"

    let tempOldFiles: any = []
    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)
      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setImageID(tempOldFiles)
    setlogo(tempFiles)
    setUploadFile(e.fileList)

  }
  const onLogoChange = (e: any) => {
    setlogo(e.target.files)
  }


  useEffect(() => {
    if (ifscIsError) {
      setValue("ifsc_code", "")
      setChangeIFSC(false)
      setVerify(false)
    }
  }, [ifscIsError])



  useEffect(() => {
    if (ifscError) {
      setTimeout(() => {
        setIFSCError(false)
      }, 3000)
    }
  }, [ifscError]);



  const handleNumbercode = (e: any) => {
    let temp = e.target.value.replace(/\D/g, '');
    if (temp?.length > 10) temp = temp.substring(0, 10);
    if (temp?.length > 6) {
      temp = temp.slice(0, 5) + ' ' + temp.slice(5);
    }
    e.target.value = temp;

    setValue('service_mobile_no', temp)
  }

  const currentPath = window.location.pathname;

  // const apinameBank = process.env.REACT_APP_BANK_API?.toString()

  // useEffect(() => {
  //   if (data.id) {
  //     const getCall = async () => {
  //       try {
  //         const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameBank}/${data.id}/`);
  //         // reset(response?.data?.data)
  //         setPersonType(response?.data?.data?.type)
  //         setBankName(response?.data?.data.ifsc_code?.BANK)
  //         setCityName(response?.data?.data.ifsc_code?.CITY)
  //         setBranchName(response?.data?.data.ifsc_code?.BRANCH)
  //         setDistrict(response?.data?.data.ifsc_code?.DISTRICT)
  //         setState(response?.data?.data.ifsc_code?.STATE)
  //         setCountry(response?.data?.data.ifsc_code?.COUNTRY)
  //         setMicr(response?.data?.data.ifsc_code?.MICR)
  //         setPincode(response?.data?.data.ifsc_code?.PINCODE)
  //         setSelectedEmployee(response?.data?.data.employee?.id)
  //         setValue("employee", response?.data?.data.employee?.id)
  //         setSelectedCustomer(response?.data?.data.customer?.id)
  //         setValue("customer", response?.data?.data.customer?.id)
  //         setSelectedPriority(response?.data?.data?.priority?.id)
  //         setValue('priority', response?.data?.data?.priority?.id)
  //         setSelectedAccountType(response?.data?.data?.account_type?.id)
  //         setValue('account_type', response?.data?.data?.account_type?.id)
  //         if (check == "") {

  //           setSelectedIFSC(response?.data?.data?.ifsc_code?.IFSC)
  //           setIFSCid(response?.data?.data?.ifsc_code?.id)
  //         }
  //         let tempImages = response?.data?.data?.image?.map((img: any, index: number) => {
  //           let ctx: UploadFile = {
  //             uid: `${img?.id}`,
  //             name: `${img.title}`,
  //             status: 'done',
  //             url: img?.doc,
  //             thumbUrl: img?.doc,
  //           }

  //           return ctx
  //         })
  //         setUploadFile(tempImages)
  //         setValue("ifsc_code", response?.data?.data?.ifsc_code?.IFSC)

  //         if (response?.data?.data?.ifsc_code?.IFSC) {
  //           setVerify(true)
  //           setChangeIFSC(true)
  //         }

  //         // setSelectedIFSC(data?.ifsc_code?.IFSC)
  //         // setSelectedIFSC(data?.ifsc_code?.id)



  //       } catch (error) {
  //         console.error('Error:', error);
  //       }
  //     }
  //     getCall()
  //   }

  // }, [])


  const [renderKey, setRenderKey] = useState(0);
  const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()


  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);

  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();




  const handleRefetch = () => {
    if (apiName === apinamePriority) {
      PriorityRefetch()
    }
    if (apiName === apinameAccountType) {
      AccountTypeRefetch()
    }


  }


  useEffect(() => {
    if (OpenEditDropDownModel && valuePriority && apiName === apinamePriority) {
      setValuePriority(null)
      setValuePriorityCreatable(null)
    }
    if (OpenEditDropDownModel && valueAccountType && apiName === apinameAccountType) {
      setValueAccountType(null)
      setValueAccountTypeCreatable(null)
    }




    if (deletesucess && apiName === apinamePriority) {
      setValuePriority(null)
      setValuePriorityCreatable(null)
    }
    if (deletesucess && apiName === apinameAccountType) {
      setValueAccountType(null)
      setValueAccountTypeCreatable(null)
    }

  }, [OpenEditDropDownModel, apiName, deletesucess])


  const [isExist, setIsExist] = useState(true)


  const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end" }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}
            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditDropDownModel}
          // permissionClass={permissionClass}
          />
          : ""}

      </div>
    </div>


  )


  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
  }



  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };
  const [addShowOffcancas, setAddShowOffcancas] = useState(false)

  const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);
  const [ids, setIds] = useState([])

  const handleCloseEdit = () => setShowOffcancasEdit(false);
  const handleShowEdit = (id: any) => {
    setId(id)
    setShowOffcancasEdit(true)
  };
  const [ForEditIds, setForEditIds]: any = useState([])
  const [added, setAdded] = useState(false)
  const [isEdited, setIsEdited]: any = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [render, setRender] = useState(0)
  useEffect(() => {
    if (isNew || isEdited) {
      const rawData = localStorage.getItem('Add Data');
      const addData = rawData ? JSON.parse(rawData) : null;
      setData(addData?.bank);

      setIsNew(false);
      setIsEdited(false)
    }
  }, [isNew, isEdited]);
  const filter = {

    name: ""

  };

  const addButtonName = "Bank Detail";
  const addButtonPageName = "BankFormat"
  const permissionClass = {
    front: "Account",
    back: "bank"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  const [activePage, setActivePage] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setActivePage('Edit Bank Details')
    }, 500)

  }, [activePage]);

  const [localIds, setLocalIds] = useState([])
  const [getIdForEdit, setGetIdForEdit]: any = useState()
  const [multipleIds, setMultipleIds]: any = useState([])
  const handleNext = () => {
    let tempId = {
        bank: multipleIds
    }

    let tempData = {
        id: basicIdTemp,
        apiname: apinameForBasicDetails,
        data: tempId
    }
    BasicDetails(tempData);
    const currentPath = window.location.pathname;
    if (currentPath === '/addCustomerDetails') {
        setCurrentStep(currentStep + 1)
    };
}

useEffect(() => {
  const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
  if (data && ids?.length > 0 && isDataChanged) {
      reset(data);
      setMultipleIds(data)
      setForEditIds(data)
      prevDataRef.current = data;
  }
}, [data, reset, ids]);




useEffect(() => {
  if (isSuccess) {
      if (data) {
          setMultipleIds((oldIds: any) => {
              const newIds = [...oldIds, data.data.id];
              return newIds;
          });

          setAdded(false)
      }
  }
}, [isSuccess, data]);
useEffect(() => {
  if (data?.length > 0) {
      const getCall = async () => {
        try {
              const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`, {
                  ids: data
              });
              setIds(response?.data?.data)

              reset(response?.data?.data)

          } catch (error) {
              console.error('Error:', error);
          }
      }
      getCall()
  }
}, [])


  return (
    <div>
      <div >
        <section className="">
          <div className="">
            <div className="card card-default">
              <div className="row">
                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
              </div>
              <div className="row d-flex  ">
                <div className="row d-flex ">
                  <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                    <button
                      type="button" className="border-1 rounded text-white px-4 py-1"
                      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                      onClick={() => setAddShowOffcancas(true)}

                    >Add Bank Detail</button>

                  </div>
                </div>
              </div>
              {/* <form
                ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                       
                        <div className="col-sm-3 mt-2">
                          <label >Priority</label>
                         
                          <CreatableSelect
                            {...register("priority", { required: { value: true, message: "Field is required" } })}
                            isClearable={() => {
                              setValuePriority(null)
                              setValue(null)
                            }}
                            components={customCreatableIndicator}
                            options={optionsPriority}
                            onCreateOption={handleCreatePriority}
                            onChange={handleChangePriority}
                            onMenuOpen={() => PriorityRefetch()}
                            // value={valuePriorityCreatable == null ? valuePriority : valuePriorityCreatable}
                            value={valuePriorityCreatable == null ? valuePriority == null ? optionsPriority?.filter((obj: any) => obj.value === selectedPriority) : valuePriority : valuePriorityCreatable}

                          />
                          <span className='text-red w-100'>{errors?.priority?.message}</span>

                        </div>






                       


                        <div className="col-sm-3 mt-2">

                          <label >Bank IFSC Code <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled={changeIFSC}

                              // value={selIFSC == undefined?selectedIFSC:selIFSC}

                              placeholder="Bank IFSC Code" onChange={handleValueUppercase}
                              value={selectedIFSC}
                              {...register("ifsc_code", { onChange: handleIFSC, required: { value: true, message: "Bank IFSC Code is required" } })} />
                            
                            <div className="input-group-append" style={{ width: "62px", padding: 0 }}>
                              {verify ?
                                <>
                                  <Button className="input-group-text border-start-0 bg-info" onClick={handleChangeIFSC}>
                                    Change
                                  </Button>
                                </>
                                :

                                <>
                                  <Button className="input-group-text border-start-0 bg-success" disabled={ifscValue == "" || ifscIsLoading} onClick={onSubmitIFSC}>
                                    {ifscIsLoading ? "Verifying..." : "Verify"}
                                  </Button>
                                </>}

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.ifsc_code?.message}</span>
                          {ifscError && <span className='text-red w-100'>{"IFSC Not Found"}</span>}
                        </div>

                        <div className="col-sm-3 mt-2">


                          <label >Bank Name </label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control" value={BankName} placeholder="Branch Name" />

                          </div>
                          <span className='text-red w-100'>{errors?.name_and_city?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">


                          <label >City Name</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control" value={CityName} placeholder="Branch Name" />

                          </div>

                          <span className='text-red w-100'>{errors?.city?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Branch Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled value={BranchName} placeholder="Branch Name" />

                          </div>
                          <span className='text-red w-100'>{errors?.branch_name?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Bank Account Type <span style={{ color: 'red' }}>*</span></label>
                         

                          <CreatableSelect
                            {...register("account_type", { required: { value: true, message: "Field is required" } })}
                            isClearable={() => {
                              setValueAccountType(null)
                              setValue(null)
                            }}
                            components={customCreatableIndicator}
                            options={optionsAccountType}
                            onCreateOption={handleCreateAccountType}
                            onChange={handleChangeAccountType}
                            onMenuOpen={() => AccountTypeRefetch()}
                            // value={valueAccountTypeCreatable == null ? valueAccountType : valueAccountTypeCreatable}
                            value={valueAccountTypeCreatable == null ? valueAccountType == null ? optionsAccountType?.filter((obj: any) => obj.value === selectedAccountType) : valueAccountType : valueAccountTypeCreatable}

                          />
                          <span className='text-red w-100'>{errors?.account_type?.message}</span>

                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Bank Account Number <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="number" className="form-control" placeholder="Bank Account Number" {...register("account_number", { required: { value: true, message: "Bank Account Number is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.account_number?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Bank Swift Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Swift Code" {...register("swift_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank Swift Code is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.swift_code?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Bank IEC Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank IEC Code" {...register("iec_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank IEC Code is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.iec_code?.message}</span>
                        </div>
                        <div className="col-sm-3 mt-2">
                          <label >Bank Micr Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled value={Micr} placeholder="Bank Micr Code" />

                          </div>
                          <span className='text-red w-100'>{errors?.micr_code?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Check Print Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Check Print Name" {...register("cheque_print_name", { onChange: handleValue, required: { value: false, message: "Check Print Name is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.cheque_print_name?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Amount Transfer Account Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Amount Transfer Account Name" {...register("amount_transfer_account_name", { onChange: handleValue, required: { value: true, message: "Amount Transfer Account Name is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.amount_transfer_account_name?.message}</span>
                        </div>




                        <div className="col-sm-3 mt-2">

                          <label >District</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={District}
                              placeholder="District"

                            />

                          </div>


                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >State</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={State}
                              placeholder="State"

                            />

                          </div>


                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >Country</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={Country}
                              placeholder="Country"
                            />

                          </div>


                        </div>
                        <div className="col-sm-3 mt-2">
                          <label >Pin Code </label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={Pincode}
                              placeholder="Pin Code"

                            />
                          </div>
                          <span className='text-red w-100'>{errors?.account_type?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >Pan No.</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Pan No." {...register("pan_number", { onChange: handleValueUppercase, required: { value: true, message: "Pan No. is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.pan_number?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Bank Contact Number-1 <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Contact Number-1"
                              {...register('bank_contact_number_1', {
                                required: "Bank Contact Number-1 is required",

                                pattern: {
                                  value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                  message: 'Please enter a valid number',
                                },
                              })}
                            // onChange={handleNumbercode}
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_contact_number_1?.message}</span>
                        </div>
                        <div className="col-sm-3 mt-2">

                          <label >Bank Contact Number-2 </label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Contact Number-2"
                              {...register('bank_contact_number_2', {
                                required: false,

                                pattern: {
                                  value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                  message: 'Please enter a valid number',
                                },
                              })}
                            // onChange={handleNumbercode}
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_contact_number_2?.message}</span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Bank Email Address-1 <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-1"
                              {...register('bank_email_id_1', {
                                required: 'Email is required',
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                  message: 'Please enter a valid email',
                                },
                              })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_email_id_1?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Bank Email Address-2 </label>
                          <div className="input-group">
                            <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-2"
                              {...register('bank_email_id_2', {
                                required: false,
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                  message: 'Please enter a valid email',
                                },
                              })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_email_id_2?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">


                          <label >Re-Mark</label>
                          <div className="input-group">
                            <input type="text" className="form-control"

                              placeholder={'Re-Mark'}


                              {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                            //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Status</label>
                          <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                            <option value={""} selected>Select Any</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className='text-red w-100'>{errors?.status?.message}</span>

                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Cheque Image</label>


                          {uploadFile?.length > 0 ?

                            <>
                              <Upload
                                {...register("image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={FileOnChange}
                                multiple={true}
                                defaultFileList={[...uploadFile]}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </> :
                            <>
                              <Upload
                                {...register("image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={FileOnChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </>
                          }





                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          {!verify && <span style={{ color: "#4287f5" }}>Don't Forget To Verify Your IFSC Before Submit</span>}
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Submit
                          </button>
                          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                          {currentPath === '/addCustomerDetails' ?
                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form> */}


              <Add_Bank_Modal addShowOffcancas={addShowOffcancas} setAddShowOffcancas={setAddShowOffcancas} setAdded={setAdded} setForEditIds={setForEditIds} />

              <Edit_Bank_Modal showOffcancasEdit={showOffcancasEdit} handleCloseEdit={handleCloseEdit} setShowOffcancasEdit={setShowOffcancasEdit} id={id} setIsEdited={setIsEdited} />

              <Stepper_Table
                key={render}
                apiname={apiname}
                filterFields={filter}
                addButtonName={addButtonName}
                stickyStart={stickyStart}
                stickyEnd={stickyEnd}
                TableColumns={Columns_Bank_Format}
                addButtonPageName={addButtonPageName}
                titleofheaders={titleofheaders}
                permissionClass={permissionClass}
                isMore={false}
                isAddButton={false}
                multipleIds={ForEditIds}
                added={added}
                setAdded={setAdded}
                setShowOffcancas={setAddShowOffcancas}
                localIds={localIds}
                setGetIdForEdit={setGetIdForEdit}
                setIsNew={setIsNew}
                handleCloseEdit={handleCloseEdit}
                handleShowEdit={handleShowEdit}
                activePage={activePage}
              />
              <div className="row d-flex  ">
                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                  <div className="row d-flex ">
                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                      <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                        onClick={handleNext}
                      >Next</button>
                      <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                      {currentPath === '/addCustomerDetails' ?
                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                    yesupdatemodal={yesupdatemodal}
                    cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

                <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered    >
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                    <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                    {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                        <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleSubmit(handleConfirm)}
                        >Confirm</button>
                    </Modal.Footer>
                </Modal>
      <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey}
        refetchForm={handleRefetch}
        open={OpenEditDropDownModel}
        setOpen={setOpenEditDropDownModel}
        id={editId}
        // superAccountId={superAccountId} 
        // optionsSuperAccount={optionsSuperAccount}
        // isSuperAccount={isSuperAccount}
        apiname={apiName} />



      {show && (
        <Mui_Toaster
          message={`Bank Details Updated Successfully`}
          severity={"success"}
          Isopen={true}
        />
      )}
    </div>
  );
};

export default Edit_Bank_Format;
