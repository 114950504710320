import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../../components/Dynamic_Table/Table/Table";
import { Columns_Online_Inquiry, titleofheaders } from "../Columns_Online_Inquiry";
import { useForm } from "react-hook-form";
import { useCreate } from "../../../../hooks/Api";
import Accordion from 'react-bootstrap/Accordion';
import IndicatorsContainer from '../../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../../components/Select Dropdown/Creatabel'
import Restricted from "../../../../Roles/Restricted";
import { useFetchOnlineInquiryDropDown } from "../../../../hooks/DropDown_Api";
import Select from 'react-select'

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

const List_Online_Inquiry_Report = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Online Inquiry";

  const Breadcrumb_Title_Name = "Online Inquiry Report";

  const apiname = process.env.REACT_APP_SALES_ONLINE_INQUIRY_API?.toString();

 
  const [maintenanceReportData, setmaintenanceReportData]: any = useState([])

  const [renderTable, setRenderTable] = useState(0)

  const [ComponyValue, setComponyValue]: any = useState("")
  const [Compony, setCompony]: any = useState('')
  const [optionsCompony, setOptionsCompony]: any = useState([])
  const { data: ComponyData, refetch: ComponyRefetch } = useFetchOnlineInquiryDropDown()

  useEffect(() => {
    setOptionsCompony(ComponyData)
  }, [ComponyData])

  const handleChangeCompony = (e: any) => {
    setValue('company_name', e?.value)
    setComponyValue(e?.label)
    setCompony(e)
  }

  const apinameGetData = `${process.env.REACT_APP_SALES_ONLINE_INQUIRY_API}/search_filter`?.toString()
  const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apinameGetData)

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();
  const onSubmit = (data: any) => {
    
    let tempData = {
      company_name:ComponyValue

    }

    mutate(tempData);
  }

const [resetData,setResetData] = useState(false)
  const handleReset = () => {
    reset()
    setCompony(null)
    setmaintenanceReportData([])

    setComponyValue(null)

    setValue('company_name','')
    setResetData(false)    
    setRenderTable(renderTable + 1)
  }

  useEffect(() => {
    if (isSuccess) {
      setmaintenanceReportData(data?.results)
      setResetData(true)    
      setRenderTable(renderTable + 1)
    }
  }, [data, isSuccess])



  const filter = {
    // selection: "",
    // department_name: "",
    // issue_registered_by_person_code_and_name: "",
    // maintenance_location: "",
    // refrence_person_code_and_name: "",
    // responsible_person: "",
    // handover_work_date: "",
    // repairing_date: "",
    // code: "",

    // created_by: "",
    // updated_by: "",
    // status: ''
  };
  const addButtonName = "Online Sales Inquiry";
  const addButtonPageName = "OnlineInquiry"
  const permissionClass = {
    front: "sales",
    back: "onlinesalesinquiry"
  }
  const stickyStart = 1;
  const stickyEnd = 2;



  const [onReset, setOnReset] = useState(false)

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
            <Restricted
          to={`${permissionClass?.front}.view_${permissionClass?.back}`}
          fallback={""}
        >
              <div className="">
                <Accordion defaultActiveKey={'0'} flush >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Search Filter</Accordion.Header>
                    <Accordion.Body>
                      <form>
                        <div className="row d-flex">
                          
                        <div className="col-sm-2 mt-2" >
                            <label htmlFor="name_and_grade">Company Name </label>
                            <div className="d-flex row" >
                              <Select
                                {...register("company_name", { required: { value: false, message: "Company Name is required" } })}

                                // placeholder={'Department'}
                                onChange={handleChangeCompony}
                                value={Compony}
                                options={optionsCompony}
                                components={customIndicator}

                              />

                            </div>
                            <span className='text-red w-100'>{errors?.company_name?.message}</span>

                          </div>

                        </div>
                        <div className="row d-flex  ">
                          <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                <button
                                  type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                  style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                  onClick={handleSubmit(onSubmit)} >Get Data</button>
                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              </Restricted>

              <div className="container p-0" style={{ maxWidth: "100%" }}>
               
                    <Table
                      key={renderTable}
                      apiname={apiname}
                      filterFields={filter}
                      addButtonName={addButtonName}
                      stickyStart={stickyStart}
                      stickyEnd={stickyEnd}
                      TableColumns={Columns_Online_Inquiry}
                      addButtonPageName={addButtonPageName}
                      titleofheaders={titleofheaders}
                      permissionClass={permissionClass}
                      isAddButton={false}
                      maintenanceReportData={maintenanceReportData}
                      resetData={resetData}

                    />
               

              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default List_Online_Inquiry_Report;
