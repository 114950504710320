
import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useFetchDispatchDropDown, useFetchCustomerDropDown } from "../../../hooks/DropDown_Api";
import {DatePicker} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import Axiosinstance from "../../../hooks/Interceptor";

const Verify_Products_List = () => {


  const location = useLocation();
  const dosDataStr = new URLSearchParams(location.search).get('dos');

  const [dosData,setDosData]:any = useState()

  
  useEffect(() => {
    if (dosDataStr) {
        const parsedData = JSON.parse(decodeURIComponent(dosDataStr));
        setDosData(parsedData);
        
        // Call your mutate function here
        if (parsedData) {
            ExistedProductsMutate({ do_ids: parsedData });
        }
    }
}, [dosDataStr]);


useEffect(()=>{
if(dosData){
  Dispach({do_ids:dosData})
}
},[dosData])

  





    let pageName ="Verify"
    const navigate = useNavigate()
    // const { id } = useParams()
    // const apiname = "sales/dispatchOrder"
    // const { data } = useFetchSingle(id,apiname)
    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;

    const apiname =  process.env.REACT_APP_SALES_DISPATCH_ORDER_GET_DISPATCHE_ORDER_DATA_API?.toString()
    const { mutate: Dispach, isSuccess, isError, error, data: data_response }: any = useCreate(apiname)



    const apinameForExistedProducts = process.env.REACT_APP_SALES_DISPATCH_ORDER_GET_DISPATCHED_DATA_API?.toString();

    const { mutate: ExistedProductsMutate, data: ExistedProductsData,isError: ExistedProductsIsError, isLoading: ExistedProductsISLoading, isSuccess: ExistedProductsSuccess, error: ExistedProductsError }:any = useCreate(apinameForExistedProducts)

      
    
    
    const apinameForDispachedProducts = "sales/orderDispatched";

    const { mutate: dispachedProductsMutate, data: dispachedProductsData,isError: dispachedProductsIsError, isLoading: dispachedProductsISLoading, isSuccess: dispachedProductsSuccess, error: dispachedProductsError }:any = useCreate(apinameForDispachedProducts)
   
   const [exist,setExist] = useState(false)

    const[productsData,setProductsData]:any = useState([])
    const [customer,setCustomer]:any = useState()

   
// const {data:CustomerDetailData, refetch:CustomerDetailRefetch}= useFetchDispatchDropDown()


    let apiForInvoice = "sales/orderDispatched/submit_dispatch_order"

    // let apiForConfirmInvoice = "sales/orderDispatched/submit_dispatch_order/?force_flag=true"

    const { mutate: invoiceMutate, data: invoiceData,isError: invoiceIsError, isLoading: invoiceIsLoading, isSuccess: invoiceIsSuccess, error: invoiceError }:any = useCreate(apiForInvoice)


    const handleInvoice = ()=>{
      invoiceMutate({do_ids: dosData})
      
    }

    useEffect(()=>{
      if(invoiceIsSuccess){
        navigate("/listDispatch")
      }
      },[invoiceIsSuccess])

    const handleConfirmInvoice = ()=>{
      axios.post(`${process.env.REACT_APP_BASE_URL}${apiForInvoice}/?force=true`,{
        do_ids: dosData
      })
  .then((response) => {
    navigate("/listDispatch")
   
  })
  .catch((error) => {
    console.error('There was an error!', error);  
  });
      
    }


    const[isInvoice,setIsInvoice] = useState(false)

    useEffect(()=>{
        if(dispachedProductsIsError){
          if(dispachedProductsError?.response?.data?.message)
          
            setExist(true)
            setErrorMessage(dispachedProductsError?.response?.data?.message[0])
        }

        if(invoiceIsError){
          if(invoiceError?.response?.data?.message){
            setIsInvoice(true)
          setExist(true)
          setErrorMessage(invoiceError?.response?.data?.message[0])
      }
    }
        
    },[dispachedProductsIsError,invoiceIsError])

    const[QrNumbers,setQrNumbers] = useState()
    
    const handleQRChange = (event:any) => {
        setQrNumbers(event?.target?.value);
    };




    const [isLoading, setIsLoading] = useState(false);

  //   const fetchExistedProducts = () => {
  //   setIsLoading(true);
  //   ExistedProductsMutate({ do_ids: [Number(id)] }, {
  //     onSuccess: (data:any) => {
  //       setProductsData(data?.data);
  //       setIsLoading(false);
  //     },
  //     onError: () => {
  //       setIsLoading(false);
  //     }
  //   });
  // };


//   useEffect(()=>{
//     if(id){
//         // ExistedProductsMutate({do_ids:[Number(id)]})
//         fetchExistedProducts()
//     }
// },[id])





 


    const [renderCounter, setRenderCounter] = useState(0);
    
    // const handleVerify = async (qrNumber: any) => {
    //     try {
    //         await dispachedProductsMutate({do_ids:[Number(id)], qr_number: QrNumbers});
    //         await ExistedProductsMutate({do_ids:[Number(id)]});
    //     } catch (error) {
    //         console.error("Error during verification:", error);
    //     }
    //     setRenderCounter(prev => prev + 1);
    //     setProductsData(ExistedProductsData?.data)
    // };

    // useEffect(()=>{
    //     if(ExistedProductsData){
    //         setProductsData(ExistedProductsData?.data)
    //         setRenderCounter(prev => prev + 1);
    //     }
    // },[ExistedProductsData,renderCounter])


  //   const handleVerify = async (qrNumber: any) => {
  //     try {
  //         await dispachedProductsMutate({do_ids:[Number(id)], qr_number: QrNumbers});
  //         await ExistedProductsMutate({do_ids:[Number(id)]});
  //     } catch (error) {
  //         console.error("Error during verification:", error);
  //     }
  // };


const [errorMessage,setErrorMessage] = useState('')





  useEffect(()=>{
if(isLoading){
  setRenderCounter(prev => prev + 1);
}
  },[isLoading,renderCounter])
  
  // useEffect(() => {
  //     if (ExistedProductsData) {
  //         setProductsData(ExistedProductsData?.data);
  //         // This will cause a re-render if ExistedProductsData is updated
  //     }
  // }, [ExistedProductsData]);

  useEffect(()=>{
    if(ExistedProductsData){
      let finalData = ExistedProductsData?.data?.map((e:any)=>{
        if(e?.is_dispatched === false){
          return e
        }
      })
      setProductsData(finalData);
    }
  },[ExistedProductsData])
 




//   const [CustomerOptions,setCustomerOptions]:any = useState()

//     const {data:CustomerData, refetch:CustomerRefetch} = useFetchCustomerDropDown()


//     const [DispatchOption,setDispatchOption] = useState()


//     useEffect(()=>{
//         setCustomerOptions(CustomerData)
//     },[CustomerData])


//     const apinameForDispachOrder = "sales/dispatchOrder";

//     const handleChangeCustomer=(e:any)=>{
//        setValue("customer",e?.value)


//        axios.post(`${process.env.REACT_APP_BASE_URL}${apinameForDispachOrder}/search_filter/`,{
//         customer_id:e?.value
//       })
//   .then((response) => {
//     let dos = response?.data.results?.map((e:any)=>{
//       return{
//         value:e?.id,
//         label:e?.do_number,
//       }
//     })
//     setDispatchOption(dos)
//   })
//   .catch((error) => {
//     console.error('There was an error!', error);  
//   });
        
//     }


 


 


const [valueDos,setValueDos]:any = useState()
const [dos,setDos] = useState([])
    const handleChangeDispatch=(e:any)=>{

       let dos = e?.map((e:any)=>{
        return e?.value
       })
       setValue("product",dos)
       setValueDos(e)
       setDos(dos)
      //  ExistedProductsMutate({ do_ids: dos })
        
    }


    const [forceUpdate, setForceUpdate] = useState(0);
   
  //   const fetchData = async () => {
  //     
  //     try {
  //         const response = await  ExistedProductsMutate({ do_ids: dosData });
  //         setProductsData(response.data.results);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //     }
  // };
  
  //   useEffect(() => {
  //     if (forceUpdate) {
  //         // Fetch the data
  //         fetchData();
  //         // Reset verified state
          
  //     }
  // }, [forceUpdate]);

    
    const handleVerify = async (qrNumber:any) => {
      
      try {
        // Perform the dispatched products mutation
        await dispachedProductsMutate({ do_ids: dosData, qr_number: QrNumbers });
        
        await new Promise(resolve => setTimeout(resolve, 500));

        // Now call ExistedProductsMutate after the delay
        await ExistedProductsMutate({ do_ids: dosData });

        let finalData = ExistedProductsData?.data?.map((e:any)=>{
        if(e?.is_dispatched === false){
          return e
        }
      })
      
        
        await setProductsData(finalData);
        await setForceUpdate(prev => prev + 1);

        // After that mutation, refetch the existed products
        // fetchExistedProducts();
      } catch (error) {
        console.error("Error during verification:", error);
        setIsLoading(false);
      }
    };
    
const[isDelete,setIsDelete] = useState(false)
    const handleDelete=async(e:any,field:any)=>{
      e.preventDefault()
      await axios.delete(`${process.env.REACT_APP_BASE_URL}${apinameForDispachedProducts}/${field}/?force=true`).then((e:any)=>{
      })
      await new Promise(resolve => setTimeout(resolve, 500));

      // Now call ExistedProductsMutate after the delay
      await ExistedProductsMutate({ do_ids: dosData });

      let finalData = ExistedProductsData?.data?.map((e:any)=>{
        if(e?.is_dispatched === false){
          return e
        }
      })
      
      await setProductsData(finalData);
      await setForceUpdate(prev => prev + 1);
    }


   

  


  
   
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>{pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">{pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Cavity</span></h5>
                            </div> */}
                            <form >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                    {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">

                                      <div className="row d-flex mb-2">

                                      
            {/* <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Customer Name <span style={{ color: 'red' }}>*</span></label>
                                            <Select 
                                                            {...register("customer",{required: { value: true, message: "Customer Name is required" }})}
                                                            placeholder={'Select Customer Name'}
                                                            onChange={handleChangeCustomer}
                                                            options={CustomerOptions}
                                                            // value={}
                                                          

                                                        />
                                                        <span className='text-red w-100'>{errors?.customer?.message}</span>
                                                        </div>
                                                <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Dispatch Order <span style={{ color: 'red' }}>*</span></label>
                                            <Select 
                                                            {...register("dispatch",{required: { value: true, message: "Dispatch Order is required" }})}
                                                            options={DispatchOption}
                                                            value={valueDos}
                                                            onChange={handleChangeDispatch}
                                                            isMulti={true}
                                                           

                                                        />
                                           
                                                    <span className='text-red w-100'>{errors?.district?.message}</span>
                                                </div> */}


                                                </div>


                                    <div className="card" style={{backgroundColor:"#013564", color:"white"}}>
            <div className="card-body" style={{padding:"0px"}}>


                <div className="row">

               
                    
                    {/* <div className="col-sm-4"  style={{fontSize:"13px"}}>
                        <p className="card-text"><strong>Name:</strong> {customer?.name?.name}</p>
                        <p className="card-text"><strong>Phone:</strong> {customer?.name?.contact_no}</p>
                    </div>    
                    <div  className="col-sm-4">
                        
                        <p className="card-text"><strong>Address:</strong> {customer?.name?.address[0]?.address}</p>
                        
                        <p className="card-text"><strong>Delivered Address:</strong> {customer?.sales_order?.delivery_address?.address}</p>
                      </div>
                        <div  className="col-sm-4">
                        <p className="card-text"><strong>Sales Person Name:</strong> {customer?.name?.zone?.salesman_employee?.name}</p>
                        <p className="card-text"><strong>D/O Number:</strong> {customer?.do_number}</p>
                        
                    </div> */}
                    
                    
                    <div className="col-sm-4"  style={{fontSize:"13px"}}>
                    <p className="card-text"><strong>Name:</strong> {data_response?.data[0]?.extra_response?.customer?.name} {data_response?.data[0]?.extra_response?.customer?.code}</p>
                        <p className="card-text"><strong>Phone:</strong> {data_response?.data[0]?.extra_response?.customer?.contact_detail_number}</p>
                    </div>    
                    <div  className="col-sm-4">
                        {/* <p className="card-text"><strong>Delivered Cust. To:</strong> </p> */}
                        <p className="card-text"><strong>Address:</strong> {`${data_response?.data[0]?.extra_response?.delivery_address?.number},${data_response?.data[0]?.extra_response?.delivery_address?.building_name?data_response?.data[0]?.extra_response?.delivery_address?.building_name:data_response?.data[0]?.extra_response?.delivery_address?.bunglow_name},${data_response?.data[0]?.extra_response?.delivery_address?.city_name?data_response?.data[0]?.extra_response?.delivery_address?.city_name:data_response?.data[0]?.extra_response?.delivery_address?.village_name}`} </p>
                        {/* <p className="card-text"><strong>Zone:</strong></p> */}
                        <p className="card-text"><strong>Delivery Address:</strong> {`${data_response?.data[0]?.extra_response?.delivery_address?.number},${data_response?.data[0]?.extra_response?.delivery_address?.building_name?data_response?.data[0]?.extra_response?.delivery_address?.building_name:data_response?.data[0]?.extra_response?.delivery_address?.bunglow_name},${data_response?.data[0]?.extra_response?.delivery_address?.city_name?data_response?.data[0]?.extra_response?.delivery_address?.city_name:data_response?.data[0]?.extra_response?.delivery_address?.village_name}`}</p>
                      </div>
                        <div  className="col-sm-4">
                        <p className="card-text"><strong>Sales Person Name:</strong> {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.name} {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.surname} {data_response?.data[0]?.sales_order?.lead_generate?.sales_inquiry_code?.code}</p>
                        <p className="card-text"><strong>D/O Number:</strong> {data_response?.data?.map((e:any)=>e?.do_number)?.join(", ")}</p>
                        {/* <p className="card-text"><strong>Delivery Date:</strong> 26/10/2023 </p> */}
                        {/* <p className="card-text"><strong>Req. Delivery Date:</strong> 14-09-2023</p> */}
                        {/* <p className="card-text"><strong>Commitment Date:</strong> 14-09-2023</p> */}
                    </div>
                </div>
            </div>
        </div>


<div style={{display:"flex",justifyContent:"center"}}>
        <div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">QR Number</label>
      <input
      {...register("qr_number")}
           className="form-control"
             type="text"
             placeholder="QR Number"
            //  value={!QrNumbers}
             onChange={handleQRChange}
           />
         
    </div>
  </div>


  <div className="col-md-2  mt-4" style={{ justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingTop: "20px",}}>
    <div className="mb-3">
     
    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleVerify}
                    >Check</button>
    </div>
  </div>

  </div>


                                     

                                        <div className="table-responsive"  style={{minHeight:"0px"}}>

                                       
 

 <table className="table" key={forceUpdate}  style={{marginTop:"20px"}}>
   <thead>
     <tr>
       <th>ID</th>
       <th>Product Code</th>
       {/* <th>UOM</th>  */}
       <th>Order Qty</th>
       {/* <th>Brand</th> */}
       <th>QR Number</th>
       <th>Dispatched Stock</th>
       {/* <th>Verify</th> */}
      
     </tr>
   </thead>
   <tbody>



   {productsData?.map((field:any, index:any) => {

return(

<tr key={index} >
 <td>{field?.id}</td>
 <td>
 {/* <Select
     options={optionsProductType}
   value={optionsProductType?.find((option:any) => option.value === field?.product_type?.id)}
   isDisabled
      /> */}
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Product Type"
     value={field?.sales_product?.product_code}
     
   />

 </td>
 {/* <td>
 
   <input
   className="form-control"
     type="text"
     disabled
     placeholder="Gst %"
     value={field.uom}
     
   />

 </td> */}
 <td>
 

<input
   className="form-control"
     type="text"
     disabled
     value={field?.sales_product?.order_qty}
    
   />
 
 </td>

 {/* <td>
<input
   className="form-control"
     type="text"
     disabled
     value={field?.brand}
    
   />

 </td> */}

 <td>
 <input
  className="form-control"
  type="text"
  disabled
  value={field?.production?.qr_number}
/>


 </td>


 <td className="justify-content-center d-flex border-0">
 {/* <input
  className="form-control"
  type="text"
  disabled
  value={field?.production?.stock}
/> */}


<button
          onClick={(e:any)=>handleDelete(e,field?.id)}
          className="btn btn-primary text-white"
        >
          <RiDeleteBinLine />
        </button>


 </td>


 {/* <td>
 <button 
    type="button" 
    className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    onClick={() => handleVerify(qrNumbers[index])}
>
    Verify
</button>

 </td> */}
 

</tr>
)})}
</tbody>
</table>



</div>

                                        <div className="row d-flex  ">
                                            <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                <div className="row d-flex ">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                        <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleInvoice}
                                                >Generate Invoice</button>
{/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

{/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listLeadGenerated")}>Cancel</button> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>

               


            </div>
            {exist && (
        <div 
        style={{background:"#00000080"}}
          className="modal fade show d-block" 
          tabIndex={-1} 
          role="dialog" 
          aria-labelledby="exampleModalCenterTitle" 
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger" id="exampleModalLongTitle">Alert</h5>
                {/* <button 
                  type="button" 
                  className="close" 
                  onClick={setExist(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-body">
              {errorMessage}
              </div>
              {isInvoice?<>
                <div className="modal-footer">
                <button 
                style={{background:"#003665"}}
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={()=>setExist(false)}
                >
                  No
                </button>

                <button 
                style={{background:"#003665"}}
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={handleConfirmInvoice}
                >
                  Yes
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
              </>:
              <>
               <div className="modal-footer">
                <button 
                style={{background:"#003665"}}
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={()=>setExist(false)}
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
              </>}
             
            </div>
          </div>
        </div>
      )}
        </div>
    )
}

export default Verify_Products_List