import React, { useEffect, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import { useFetchDistrictDropDown,useFetchStateDropDown,useFetchCountryDropDown, useFetchTalukaDropDown, useFetchPincodeDropDown } from "../../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
// import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}




interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptionsPincode = (label: string) => ({
    label,
    // value: label,
    pincode: label,
    status: "active"
});
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const createOptionDistrict = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const createOptionState = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const createOptionCountry = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});



const Edit_City = () => {
    const navigate = useNavigate()
let pageName ="city"

const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end", zIndex: 5 }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}

            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditModel}
          // permissionClass={permissionClass}
          />
          : ""}

      </div>
    </div>


  )

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
}


const [openEditDateModel, setOpenEditDateModel] = useState(false)
const [confirmmodal, setConfirmmodal] = useState(false)
const [valuesofform, setValuesofform] = useState<any>()
const [expirationDate, setExpirationDate] = useState()

    const { id } = useParams()
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedDistrict, setSelectedDistrict] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [selectedCountry, setSelectedCountry] = useState("")
    const [valueDistrict, setValueDistrict]:any = useState();               
    const [valueState, setValueState]:any = useState();               
    const [valueCountry, setValueCountry]:any = useState();               
    const [optionsDistrict, setOptionsDistrict]: any = useState([]);
    const [optionsState, setOptionsState]: any = useState([]);
    const [optionsCountry, setOptionsCountry]: any = useState([]);
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const apiname = process.env.REACT_APP_CITY_API?.toString()
    const { data } = useFetchSingle(id, apiname)


    const [isExist, setIsExist] = useState(true)
  const [OpenEditModel, setOpenEditModel] = useState(false)

  const [countryName, setCountryName] = useState("")
  const [stateName, setStateName] = useState("")
  const [editName, setEditName] = useState("")

  const [submittedEdit, setSubmittedEdit] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
//   const apinameForCountry = process.env.REACT_APP_COUNTRY_API?.toString()
  const [valueCountryCreatable, setValueCountryCreatable] = useState<Option | null>();

  const [isCountryOpen, setIsCountryOpen] = useState(false)
  const [isStateOpen, setIsStateOpen] = useState(false)

  const [forState, setForState] = useState(false)

  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()
  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();
  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);



    const { mutate, isSuccess, isError, error, data: CityUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue,getValues }: any = useForm();

    const [valueDistrictCreatable, setValueDistrictCreatable] = useState<Option | null>();


    const apinameForDistrict = process.env.REACT_APP_DISTRICT_API?.toString()

    const { mutate: mutateDistrict, data: dataDistrict, isError: DistrictIsError, isLoading: DistrictLoading, isSuccess: DistrictSuccess, error: DistrictError }: any = useCreate(apinameForDistrict)
    
    const [byDistrict,setByDistrict] = useState()
    const { data: DistrictData, refetch: machineNameRefetch } = useFetchDistrictDropDown(byDistrict)
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsDistrict(DistrictData)
    }, [DistrictData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineNameRefetch()
        }
    }, [])

    const [forTaluka, setForTaluka] = useState(false)
  const [forDistrict, setForDistrict] = useState(false)

    const handleChangeMachineName = (e: any) => {


      machineNameRefetch()

      setForDistrict(true)
    setForState(true)
    setForTaluka(false)
  
      setApiName(apinameForDistrict)
      setDeleteApiName(apinameForDistrict)
      setEditId(e?.value)
      setEditName(e?.label)
      setEditData(DistrictData)
      setValueDistrict(e)
      setRenderKey(prevKey => prevKey + 1);

    

    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)

        setValue("district", `${e?.value}`)
        setValueDistrictCreatable(null)
        setByTaluka(e?.value)
        setDistrictValue(e?.value)
    }


    const handleCreate = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOptionDistrict(inputValue);
        setIsLoadingValue(false);
       
        
        mutateDistrict({name:newOption.name,status:newOption.status,state:stateValue,country:countryValue})
        setOptionsDistrict((prev:any) => [...prev, newOption]);
        setValueDistrictCreatable(newOption);
      }, 0);

   
    };

    useEffect(() => {
        (async () => {
            if(DistrictSuccess && optionsDistrict){
            await machineNameRefetch();
            setValue("district",DistrictData[0].value)
            setDistrictValue(DistrictData[0].value)
            }
           
        })();
        
        return () => {
        };
      }, [DistrictSuccess,DistrictData]);




      const [valueTaluka, setValueTaluka]:any = useState();   
      const [optionsTaluka, setOptionsTaluka]: any = useState([]);
      const [valueTalukaCreatable, setValueTalukaCreatable] = useState<Option | null>();


    //   const[selTaluka,setSelTaluka]:any = useState()
      const[selectedTaluka,setSelectedTaluka]:any = useState()


      const apinameForTaluka = process.env.REACT_APP_TALUKA_API?.toString()
  
      const { mutate: mutateTaluka, data: dataTaluka, isError: TalukaIsError, isLoading: TalukaLoading, isSuccess: TalukaSuccess, error: TalukaError }: any = useCreate(apinameForTaluka)
      
      const [byTaluka,setByTaluka] = useState()
      const { data: TalukaData, refetch: TalukaRefetch } = useFetchTalukaDropDown(byTaluka)
      // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
      useEffect(() => {
        setOptionsTaluka(TalukaData)
      }, [TalukaData])
  
  
    //   useEffect(() => {
    //       if (searchTerm?.length >= 1) {
    //           machineNameRefetch()
    //       }
    //   }, [])

    const[districtValue,setDistrictValue]:any = useState()


    const[talukaValue,setTalukaValue]:any = useState()
  
  
      const handleChangeTaluka = (e: any) => {

        TalukaRefetch()

        setForTaluka(true)
        setForState(true)
        setForDistrict(true)
    
        setApiName(apinameForTaluka)
        setDeleteApiName(apinameForTaluka)
        setEditId(e?.value)
        setEditName(e?.label)
        setEditData(TalukaData)
        // setValueDistrict(e)
        setRenderKey(prevKey => prevKey + 1);
  
          setValue("taluka", `${e?.value}`)
          setValueTalukaCreatable(null)
          setTalukaValue(e?.value)
          setByPincode(e?.value)
          setValueTaluka(e)
      }
  
  
      const handleCreateTaluka = (inputValue: string) => {
          
          inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
          setIsLoadingValue(true);
          setTimeout(() => {
          const newOption = createOptions(inputValue);
          setIsLoadingValue(false);
         
          
          mutateTaluka({name:newOption.name,status:newOption.status,state:stateValue,country:countryValue,district:districtValue})
          setOptionsTaluka((prev:any) => [...prev, newOption]);
          setValueTalukaCreatable(newOption);
        }, 0);
  
     
      };
  
      useEffect(() => {
          (async () => {
              if(TalukaSuccess && optionsTaluka){
              await TalukaRefetch();
              setValue("taluka",TalukaData[0].value)
              setValueTaluka(TalukaData[0].value)
              }
              
          })();
          
          return () => {
          };
        }, [TalukaSuccess,TalukaData]);












        const [valuePincode, setValuePincode]:any = useState();   
        const [optionsPincode, setOptionsPincode]: any = useState<Option[] | null>([]);
        const [valuePincodeCreatable, setValuePincodeCreatable]:any = useState<Option[] | null>([]);
  
  
        const apinameForPincode = process.env.REACT_APP_PINCODE_API?.toString()
    
        const { mutate: mutatePincode, data: dataPincode, isError: PincodeIsError, isLoading: PincodeLoading, isSuccess: PincodeSuccess, error: PincodeError }: any = useCreate(apinameForPincode)
        
        const [byPincode,setByPincode] = useState()
        const { data: PincodeData, refetch: PincodeRefetch } = useFetchPincodeDropDown(byPincode)
        // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
        useEffect(() => {
            setOptionsPincode(PincodeData)
        }, [PincodeData])
    
    
      //   useEffect(() => {
      //       if (searchTerm?.length >= 1) {
      //           machineNameRefetch()
      //       }
      //   }, [])
  
      const[pincodeValue,setPincodeValue]:any = useState([])

      const [selectedPincode,setSelectedPincode]:any = useState()
    
    
        const handleChangePincode = (e:any) => {

          const lastElement = e[e?.length - 1];

            let pincodes = e?.map((e:any)=>{
                return e.value
            })


            PincodeRefetch()

            setForTaluka(false)
            setForState(false)
            setForDistrict(false)
            
        
            setApiName(apinameForPincode)
            setDeleteApiName(apinameForPincode)
            setEditId(lastElement?.value)
            // setEditName(e?.label)
            setEditData(PincodeData)
            // setValueDistrict(e)
            setRenderKey(prevKey => prevKey + 1);
        
            

            setValue("pincode", pincodes)
            setValuePincodeCreatable(e)
            setPincodeValue(pincodes)
        }
    
    
        const handleCreatePincode = async (inputValue: string) => {
            
            
            inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
            setIsLoadingValue(true);
           
            const newOption = createOptionsPincode(inputValue);
            setIsLoadingValue(false);
                


           
            if (byTaluka){
                await mutatePincode({pincode:newOption.pincode,status:newOption.status,state:stateValue,country:countryValue,district:districtValue,taluka:talukaValue})
               
            }else{
               await mutatePincode({pincode:newOption.pincode,status:newOption.status,state:stateValue,country:countryValue,district:districtValue})
            }
       
        };
    
        useEffect(() => {
            (async () => {
                if(PincodeSuccess && optionsPincode){
                await dataPincode
                let ok = getValues('pincode'); // Get the existing values
                ok = Array.isArray(ok) ? ok : []; // Initialize as an array if not already

                let ctx = {
                    deleted: dataPincode.data.is_deleted,
                    label: dataPincode.data.pincode,
                    status: dataPincode.data.status,
                    value: dataPincode.data.id,
                }

    
                setOptionsPincode((prev:any) => [...prev, ctx]);
                // setValuePincodeCreatable((prevSelected:any) => [...prevSelected, ctx]);
                setValuePincodeCreatable((prevSelected: any) => [...(Array.isArray(prevSelected) ? prevSelected : []), ctx]);

                let check = [...ok, dataPincode.data.id];
                setValue("pincode", check); // Update the 'pincode' field value
                
                
                
                }
                
            })();
            
            return () => {

                
            };
          }, [PincodeSuccess,dataPincode]);

          useEffect(()=>{
           
          },[pincodeValue,valuePincodeCreatable])












































      const [stateValue,setStateValue] = useState("")
      const [valueStateCreatable, setValueStateCreatable] = useState<Option | null>();

    const apinameForState = process.env.REACT_APP_STATE_API?.toString()

    const { mutate: mutateState, data: dataState, isError: StateIsError, isLoading: StateLoading, isSuccess: StateSuccess, error: StateError }: any = useCreate(apinameForState)
    
    const [byState,setByState] = useState()
    const { data: StateData, refetch: StateRefetch } = useFetchStateDropDown(byState)
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsState(StateData)
    }, [StateData])

    const handleChangeState = (e: any) => {

        StateRefetch()

        
    setForState(true)
    setForDistrict(false)
    setForTaluka(false)
    
        setApiName(apinameForState)
        setDeleteApiName(apinameForState)
        setEditId(e?.value)
        setEditName(stateName)
        setEditData(StateData)
        setValueState(e)

        setValue("state", `${e?.value}`)
        setValueStateCreatable(null)
        setValueState(e)
        setByDistrict(e?.value)
        setStateValue(e?.value)

        
    setValueDistrict(null)
    setValueDistrictCreatable(null)

    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)

        setRenderKey(prevKey => prevKey + 1);
    }

    const [countryValue,setCountryValue]=useState("")
    // const [valueCountryCreatable, setValueCountryCreatable] = useState<Option | null>();

    useEffect(() => {
        if (valueCountry || valueCountryCreatable || valueState || valueStateCreatable) {
          setEditId(editId)
        }
      }, [valueCountry,
        valueCountryCreatable,
        valueState,
        valueStateCreatable])


    const handleCreateState = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOptionState(inputValue);
        setIsLoadingValue(false);
       
        
        mutateState({name:newOption.name,status:newOption.status,country:countryValue})
        setOptionsState((prev:any) => [...prev, newOption]);
        setValueStateCreatable(newOption);
      }, 0);

   
    };

    useEffect(() => {
        (async () => {
            if(StateSuccess && optionsState){
            await StateRefetch();
            setValue("state",StateData[0].value)
            setStateValue(StateData[0].value)
            }
           
        })();
        
        return () => {
        };
      }, [StateSuccess,StateData]);


      const [CountryCode, setCountryCode]:any = useState(0);
    const apinameForCountry = process.env.REACT_APP_COUNTRY_API?.toString()

    const { mutate: mutateCountry, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCountry)
    
    const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
    useEffect(() => {
        setOptionsCountry(CountryData)
    }, [CountryData])

    const handleChangeCountry = (e: any) => {

        CountryRefetch()

        setValueState(null)
        setValueStateCreatable(null)
    
        setForState(false)
    
        setApiName(apinameForCountry)
        setDeleteApiName(apinameForCountry)
        setEditId(e?.value)
        setEditName(countryName)
        setEditData(CountryData)
        setCountryCode(e?.countryCode)
        setValue("country_code_mobile",e?.countryCode)
        setValue("country", `${e?.value}`)
        setValueCountryCreatable(null)
        setValueCountry(e)
        setByState(e?.value)
        setCountryValue(e?.value)

        setValueDistrict(null)
    setValueDistrictCreatable(null)

    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)
    
        setRenderKey(prevKey => prevKey + 1);
    }


    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        if (OpenEditModel && valueCountry && apiName === apinameForCountry) {
          setValueCountry(null)
          setValueCountryCreatable(null)
        }
        if (OpenEditModel && valueState && apiName === apinameForState) {
          setValueState(null)
          setValueStateCreatable(null)
        }

        if (OpenEditModel && valueDistrict && apiName === apinameForDistrict) {
          setValueDistrict(null)
          setValueDistrictCreatable(null)
        }
    
        if (OpenEditModel && valueTaluka && apiName === apinameForTaluka) {
          setValueTaluka(null)
          setValueTalukaCreatable(null)
        }

        
    if (OpenEditModel && valuePincode && apiName === apinameForPincode) {
      setValuePincode(null)
      setValuePincodeCreatable(null)
    }

    
    
        if(deletesucess && apiName === apinameForCountry){
          setValueCountry(null)
          setValueCountryCreatable(null)
        }
    
        if(deletesucess && apiName === apinameForState){
          setValueState(null)
          setValueStateCreatable(null)
        }

        if(deletesucess && apiName === apinameForDistrict){
          setValueDistrict(null)
          setValueDistrictCreatable(null)
        }
    
        if(deletesucess && apiName === apinameForTaluka){
          setValueTaluka(null)
          setValueTalukaCreatable(null)

        }

        if(deletesucess && apiName === apinameForPincode){
          setValuePincode(null)
          setValuePincodeCreatable(null)
        }
      }, [OpenEditModel, apiName, deletesucess])


    const handleCreateCountry = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOptionCountry(inputValue);
        setIsLoadingValue(false);
       
        
        mutateCountry({name:newOption.name,status:newOption.status})
        setOptionsCountry((prev:any) => [...prev, newOption]);
        setValueCountryCreatable(newOption);
      }, 0);

   
    };

    useEffect(() => {
        (async () => {
            if(CountrySuccess && optionsCountry){
            await CountryRefetch();
            setValue("country",CountryData[0].value)
            setCountryValue(CountryData[0].value)
            }
      
        })();
        
        return () => {
        };
      }, [CountrySuccess,CountryData]);
    const onSubmit = (cityData: any) => {

        if(!valueTaluka){
            delete cityData?.taluka
        }

        if(villageValue){
             cityData.city_name = ''
          }
  
          if(cityValue){
             cityData.village_name =''
          }

        const formData = new FormData();
        for (const [key, value] of Object.entries(cityData)) {
            const formvalue: any = value
         


            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }  else if (Array.isArray(value) && key === "pincode") {
                value.forEach((val:any) => formData.append(`pincode`, val))
            }
            
    }
        let data={
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data);

    }


        useEffect(()=>{
        if(data){
            reset(data)
            setSelectedDistrict(data.district?.id)
            setValue("district",data.district?.id)
            setSelectedState(data.state?.id)
            setValue("state",data.state?.id)
            setSelectedCountry(data.country?.id)
            setValue("country",data.country?.id)

            setCountryValue(data.country?.id)

            // setStateValue(data?.state?.id)

            setValue("taluka",data?.taluka?.id)
            setSelectedTaluka(data?.taluka?.id)
            // setSelectedColor(data.color.map((e:any) => e.id ))
            setValue("pincode",data.pincode.map((e:any) => e.id ))
            setSelectedPincode(data.pincode.map((e:any) => e.id ))
            setCountryCode(data?.country?.country_code_mobile)
            setValue("country_code_mobile",data?.country?.country_code_mobile)
            // setByState(data?.country?.id)
            // setByDistrict(data?.state?.id)

            setByTaluka(data?.district?.id)

            setStateValue(data?.state?.id)
            setDistrictValue(data?.district?.id)
            setCityValue(data?.city_name)
            setVillageValue(data?.village_name)


        }
        },[data])


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
          setShow(true);
          setshowmessage("success");
          navigate("/listCity", { state: { showEditToast: true } });
        } else if (isError) {
          setShow(true);
          setshowmessage("danger");
        }
      }, [data,isSuccess,yessuccess,nosuccess,CityUpdated_response,error,
        isSuccess,]);
      useEffect(() => {
        if (isError) {
          if (error.response?.status === 405) {
            setConfirmmodal(true);
          }
        } else if (isError || yeserror || noerror) {
          setShow(true);
          setshowmessage("danger");
        }
      }, [isError, yeserror, noerror]);
      useEffect(() => {
        setTimeout(() => {
          setShow(false);
        }, 3000);
      }, [show]);

    const handleValue=(e:any)=>{
        e.target.value = e.target.value.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }


    
    const [villageValue,setVillageValue] = useState('')
    const [cityValue,setCityValue] = useState('')

    const handleVillageChange=(e:any)=>{
      e.target.value = e.target.value.toLowerCase()
  .split(' ')
  .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

  setVillageValue(e.target.value)
  setValue('village_name',e.target.value)
  }

  const handleCityChange=(e:any)=>{
    e.target.value = e.target.value.toLowerCase()
.split(' ')
.map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
.join(' ');
setCityValue(e.target.value)
setValue('city_name',e.target.value)
}










  let countryId = getValues("country")
  let stateId = getValues("state")








//   const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()
  const [editRefetch, setEditRefetch]: any = useState()


  const handleRefetch = () => {
    if (apiName === apinameForCountry) {
      CountryRefetch()
    } 
    if (apiName === apinameForState) {
      StateRefetch()
    }
     if(apiName === apinameForDistrict){
      machineNameRefetch()
    }  if(apiName === apinameForTaluka){
    TalukaRefetch()
  }
  if(apiName === apinameForPincode){
    PincodeRefetch()
  }
  }




  // useEffect(() => {
  //     if (deleteid) {
  //       setDeleteApiName(apinameForCountry)
  //     }

  //   }, [apinameForCountry])


  //   useEffect(()=>{
  //     let countryId = getValues("country")
  //     let stateId = getValues("state")
  //      if(isCountryOpen){
  //         setApiName(apinameForCountry)
  //         setEditId(countryId)
  //         setEditName(countryName)
  //      }else if(isStateOpen){
  //         setApiName(apinameForState)
  //         setEditId(stateId)
  //         setEditName(stateName)
  //      }
  //   },[apiName,editId,editName,isCountryOpen,isStateOpen])







  
  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
      CountryRefetch();
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
      CountryRefetch();
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
        CountryRefetch();
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };






    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listCity" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                           



                                               

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country Name <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueCountry(null)
                                                        setCountryCode(null)
                                                        setValue('country_code_mobile','')
                                                        setValue(null)}}
                                                        {...register("country", { required: { value: true, message: "Country Name is required" } })}
                                                        placeholder={'Select Country Name'}
                                                        options={optionsCountry}
                                                        onCreateOption={handleCreateCountry}
                                                        onChange={handleChangeCountry}
                                                        onMenuOpen={() => {
                                                            CountryRefetch()
                                                            setIsCountryOpen(true)
                                                          }}
                                                          onMenuClose={() => setIsCountryOpen(false)}
                                                        // value={valueCountryCreatable==null?valueCountry:valueCountryCreatable}
                                                        value={valueCountryCreatable==null?valueCountry==null?optionsCountry?.filter((obj:any) => obj.value === selectedCountry):valueCountry:valueCountryCreatable}

                                                        components={customCreatableIndicator}
                                                        // defaultValue={{value:1,label:"India"}}  
                                                        
                                                        // isOptionSelected={(optionsCountry:any)=>
                                                        //         {
                                                        //             
                                                        //             if(optionsCountry.label === "India"){
                                                        //             return optionsCountry
                                                        //             }
                                                        //            }}
                                                                   
                                                    />
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">State Name <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueState(null)
                                                        setValue(null)}}
                                                        {...register("state", { required: { value: true, message: "State Name is required" } })}
                                                        placeholder={'Select State Name'}
                                                        options={optionsState}
                                                        onMenuOpen={() => {
                                                            StateRefetch()
                                                            setIsStateOpen(true)
                                                          }}
                                                          onMenuClose={() => setIsStateOpen(false)}
                                                        onCreateOption={handleCreateState}
                                                        onChange={handleChangeState}
                                                        components={customCreatableIndicator}

                                                        // value={valueStateCreatable==null?valueState:valueStateCreatable}
                                                        value={valueStateCreatable==null?valueState==null?optionsState?.filter((obj:any) => obj.value === selectedState):valueState:valueStateCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueDistrict(null)
                                                        setValue(null)}}
                                                        {...register("district", { required: { value: true, message: "District Name is required" } })}
                                                        placeholder={'Select District Name'}
                                                        options={optionsDistrict}
                                                        onMenuOpen={() => {
                                                          StateRefetch()
                                                        }}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeMachineName}
                                                        components={customCreatableIndicator}

                                                        value={valueDistrictCreatable==null?valueDistrict==null?optionsDistrict?.filter((obj:any) => obj.value === selectedDistrict):valueDistrict:valueDistrictCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.district?.message}</span>

                                                </div>
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Taluka Name</label>
                                                    {/* <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Taluka Name" {...register("taluka", {onChange:handleValue, required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.taluka?.message}</span> */}




                                                    <CreatableSelect
                                                    isClearable={()=>{setValueTaluka(null)
                                                        setValue(null)}}
                                                        {...register("taluka", { required: { value: false, message: "Taluka Name is required" } })}
                                                        placeholder={'Select Taluka Name'}
                                                        isDisabled={!byTaluka}
                                                        options={optionsTaluka}
                                                        onMenuOpen={() => {
                                                          TalukaRefetch()
                                                        }}
                                                        onCreateOption={handleCreateTaluka}
                                                        onChange={handleChangeTaluka}
                                                        components={customCreatableIndicator}

                                                        // value={valueTalukaCreatable==null?valueTaluka:valueTalukaCreatable}
                                                        value={valueTalukaCreatable==null?valueTaluka==null?optionsTaluka?.filter((obj:any) => obj.value === selectedTaluka):valueTaluka:valueTalukaCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.taluka?.message}</span>
                                                </div>
                                                
                                                
                                                   
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Responsible Person</label>
                                                    <select className="form-select" {...register("four", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Mr...</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.four?.message}</span>

                                                </div> */}






<div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Village Name {cityValue?"":<span style={{ color: 'red' }}>*</span>}</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand"
                                                    value={villageValue}
                                                    disabled={cityValue}
                                                    placeholder="Village Name" {...register("village_name", {onChange:handleVillageChange, required: { value:cityValue? false:true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.village_name?.message}</span>

                                                </div>
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name {villageValue?"":<span style={{ color: 'red' }}>*</span>}</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" 
                                                    value={cityValue}
                                                    disabled={villageValue}
                                                    id="exampleInputBrand" placeholder="City Name" {...register("city_name", {onChange:handleCityChange, required: {value:villageValue? false:true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name?.message}</span>

                                                </div>
                                              

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Pin Code Number <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group">
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="City Pin Code" {...register("pincode", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span> */}


<CreatableSelect
                                                    isClearable={()=>{setValuePincode(null)
                                                        setValue(null)}}
                                                        {...register("pincode", { required: { value: true, message: "Pin Code Number is required" } })}
                                                        placeholder={'Select Pin Code Number'}
                                                        // isDisabled={!byPincode}
                                                        isDisabled={!countryValue|| !stateValue||!districtValue}
                                                        options={optionsPincode}
                                                        onCreateOption={handleCreatePincode}
                                                        onChange={handleChangePincode}
                                                        onMenuOpen={() => {
                                                          PincodeRefetch()
                                                        }}
                                                        // value={valuePincodeCreatable==null?valuePincode:valuePincodeCreatable}
                                                        // value={valueTalukaCreatable==null?valueTaluka==null?optionsTaluka?.filter((obj:any) => obj.value === selectedTaluka):valueTaluka:valueTalukaCreatable}
                                                        value={valuePincodeCreatable?.length === 0 ? optionsPincode?.filter((obj: any) => selectedPincode?.includes(obj.value)):valuePincodeCreatable}
                                                        getOptionLabel={(e:any)=>e.label}
                                                        getOptionValue={(e:any)=>e.value}    
                                                        isMulti
                                                        components={customCreatableIndicator}


                                                    />
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span>
                                                </div>

                                              
                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country Mobile Code</label>
                                                    <div className="input-group">
                                                    <input type="text"  className="form-control" id="exampleInputBrand" value={CountryCode} disabled placeholder="Country Mobile Code" {...register("country_code_mobile", {onChange:handleValue, required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country_code_mobile?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Re-Mark</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark" {...register("remark", {onChange:handleValue, required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>

                                                </div>
                                                
                                               
                                            
                                            
                                        
                                                
                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} disabled>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Code </label>
                                                    <div className="input-group">
                                                    <input disabled type="text" className="form-control" id="exampleInputBrand" placeholder="City Code" {...register("code", { required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.code?.message}</span>
                                                </div>

                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} 
                                                        // disabled={!isDirty}
                                                        >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCity")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />






  {/* Edit Modal HTML */}
  <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

<SimpleInput_Edit key={renderKey} refetchForm={handleRefetch} 
open={OpenEditModel} 
setOpen={setOpenEditModel} 
value={editName} id={editId} 
countryId={countryValue} stateId={stateValue} 
districtId={districtValue} 
talukaId={talukaValue} setSubmitted={setSubmittedEdit} 
forState={forState} forDistrict={forDistrict} 
forTaluka={forTaluka} apiname={apiName} />








        </div>
    )
}

export default Edit_City