
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import noImage from "../../images/noImage.png"
import { Update, useFetch } from "../../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from "@mui/material";

interface Data {
  selection: any



  task_name: any



  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  actions: any;
}


// export const createDayColumns = () => {
//   const columns = [];

//   for (let day = 1; day <= 31; day++) {
//     columns.push({
//       id: `point_close_time_${day}`,
//       Header: `${day}`,
//       accessor: `point_close_time_${day}`,
//       Cell: ({ row }:any) => row.original[`point_close_time_${day}`]
//     });
//   }

//   return columns;
// };


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },

  {
    id: `check_list_code`,
    Header: 'Checklist Code'
  },

  {
    id: 'name',
    Header: 'Task Name',
  },

  {
    id: `choice_yes_no`,
    Header: `Choice Yes/No`,
  },

  {
    id: `point_open_time`,
    Header: `Open Time`,
  },

  {
    id: `point_close_time`,
    Header: `Close Time`,
  },

  // {
  //   id: 'status',
  //   Header: 'Status',


  // },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
 




]


for (let date = 1; date <= 31; date++) {
  const formattedDate = String(date).padStart(2, '0');
  titleofheaders.push({
    id: `${formattedDate}`,
    Header: `${date}`,
  })
}



let a = [
  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",

  },


  {
    id: `check_list_code`,
    Header: () => <div style={{ display: "inline" }}>Checklist<br />Code</div>,
    accessor: `check_list_code`,
    sticky: "left",

  },
  {
    id: `name`,
    Header: () => <div style={{ display: "inline" }}>Task<br />Name</div>,
    // Header: `Task Name`,
    accessor: `name`,
    sticky: "left",
    Cell: ({ row }: any) => {



      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{row.original.name}</p>} placement="top" arrow >
            <div>
              {row.original?.name?.slice(0, 14).concat('...')}
            </div>
          </Tooltip>

        </>
      )
    }

  },

  {
    id: `choice_yes_no`,
    Header: () => <div style={{ display: "inline" }}>Choice<br />Yes/No</div>,
    // Header: `Choice Yes/No`,
    accessor: `choice_yes_no`,
    Cell: ({ row }: any) => row.original.choice_yes_no

  },

  {
    id: `point_open_time`,
    Header: () => <div style={{ display: "inline" }}>Open<br />Time</div>,
    // Header: `Open Time`,
    accessor: `point_open_time`,
    Cell: ({ row }: any) => row.original.point_open_time

  },

  {
    id: `point_close_time`,
    // Header: () => <div style={{ display: "inline" }}>Close<br />Time</div>,
    Header: `Close Time`,
    accessor: `point_close_time`,
    Cell: ({ row }: any) => row.original.point_close_time

  },
]
for (let date = 1; date <= 31; date++) {
  const formattedDate = String(date).padStart(2, '0');
  a.push({
    id: `${formattedDate}`,
    Header: `${date}`,
    accessor: `${formattedDate}`,
    Cell: ({ row }: any) => {


        
        let dateExists = false; 
        let temp = row.original.checklistMapper?.map((e: any) => {
          let created_date = e.created_at.split(' ')[0].split('-')[0]
  
          if (created_date === formattedDate) {
            dateExists = true;
            return (
              <span style={e.choice_yes_no === "yes" ? { color: 'green', fontWeight: 'bold' } : { color: 'red', fontWeight: 'bold' }}>
                { e.choice_yes_no?.split("_")?.map((w: any) => w[0]?.toUpperCase() + w?.substr(1)?.toLowerCase())?.join(" ")}
                </span>
            )
          }
         
        })

 

      if (!dateExists) {
            return <span>-</span>;
          }else{

      return (
        <>
           {temp}
        </>
      )
      }
      

    }
  })
}



export const Columns_CheckList_Report: any = [...a,




// {
//   id: `srno`,
//   Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
//   sticky:"left",

// },


// {
//   id: `check_list_code`,
//   Header: () => <div style={{display:"inline"}}>Checklist<br/>Code</div>,
//   accessor: `check_list_code`,

//   sticky:"left",

// },
// {
//   id: `name`,
//   Header: `Task Name`,
//   accessor: `name`,
//   Cell: ({ row }: any) => row.original.name

// },

// {
//   id: `choice_yes_no`,
//   Header: `Choice Yes/No`,
//   accessor: `choice_yes_no`,
//   Cell: ({ row }: any) => row.original.choice_yes_no

// },

// {
//   id: `point_open_time`,
//   Header: `Open Time`,
//   accessor: `point_open_time`,
//   Cell: ({ row }: any) => row.original.point_open_time

// },

// {
//   id: `point_close_time`,
//   Header: `Close Time`,
//   accessor: `point_close_time`,
//   Cell: ({ row }: any) => row.original.point_close_time

// },



// {
//   id: `1`,
//   Header: `1`,
//   accessor: `1`,
//   // Cell: ({ row }: any) => row.original.point_close_time

// },

// {
//   id: `2`,
//   Header: `1`,
//   accessor: `2`,
//   // Cell: ({ row }: any) => row.original.point_close_time

// },















// {
//   id: 'status',
//   Header: 'Status',
//   accessor:'status',


// },

{
  id: 'created_at',
  Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
  accessor:'created_at',
  Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
},


{
  id: 'created_by',
  Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
  accessor:'created_by',
  Cell: ({ row }: any) => row.original.created_by?.username


},
{
  id: 'updated_at',
  Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
  accessor:'updated_at',
  Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
},
{
  id: 'updated_by',
  Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
  accessor:'updated_by',
  Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
},



// {
//   id: 'Actions',
//   Header: 'Actions',


// },
{
  id: 'Selection',
  // sticky:"left",
  Header: ({ getToggleAllRowsSelectedProps }: any) => {
    const [data, setData] = useState([])

    const columns: any = useMemo(() => Columns_CheckList_Report, [])
    const defaultColumn: any = useMemo(() => {
      return {
        Filter: Columnlevel
      }
    }
      , [])

    const tableInstance: any = useTable({
      columns,
      data: data,
      defaultColumn,

    },


    )
    const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
    let { selectedFlatRows } = tableInstance
    const { } = useMutation((deleteid: any) => {
      const temp_data = {
        id: deleteMultipleid
      }
      return axios.post(`${process.env.REACT_APP_BASE_URL}checklist/deletelist/`, temp_data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
    });
    return (
      <>
        <div>
          <Checkbox

            onClick={() => {
              setDeleteMultipleid(() => {
                const temp = selectedFlatRows?.map((e: any) => {
                  return e.original.id;
                });
                return temp;
              });
            }}

            {...getToggleAllRowsSelectedProps()} />
        </div>
      </>
    );
  },

  Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
    // Add your onClick function here
  }} />
},
]

