
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any
  
  name_and_grade:any
  color:any
  brand:any
  hsn_code:any
  gst:any
  printing_color:any

 
  tape_roll_code: any
  tape_roll_special_running: any,
  bursting_strength: any,
  printing: any
  printing_type: any
  printing_total_color: any
  width: any
  thickness_film_micron: any
  gum_name: any
  tape_roll_1_nos_meter: any
  gum_ler_micron: any
  tape_roll_1_meter_weight: any
  tape_roll_1_card_board_weight: any
  tape_roll_1_nos_weight: any
  tape_roll_1_bundle_weight: any
  tape_roll_bundle_in_carton: any
  tape_roll_carton_net_weight_kg: any
  tape_roll_1_Bundle_Nos: any
  box_image:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: "Sr No",
  },


  {
    id: `tape_roll_code`,
    Header: `Tape Roll Code`,
  },

  {
    id: `name_and_grade`,
    Header: `Name And Grade`,
  },

  {
    id: `color`,
    Header: `Color`,
},
  
{
  id: `printing`,
  Header: `Printing`,
},

  {
    id: `brand`,
    Header: `Brand`,
  },
  {
    id: `type`,
    Header: `Brand Type`,
  },


  {
    id: `printing_type`,
    Header: `Printitng Type`,
  },
  {
    id: `printing_color`,
    Header: `Printing Color`,
  },
  {
    id: `printing_total_color`,
    Header: `Printing Total Color`,
  },

  {
    id: `width`,
    Header: `Width`,
  },
  {
    id: `thickness_film_micron`,
    Header: `Thickness Film Micron`,
  },


  {
    id: `tape_roll_1_nos_meter`,
    Header: `Tape Roll 1nos Meter`,
  },

  {
    id: `gum_name`,
    Header: `Gum Name`,
  },
  
  {
    id: `gum_ler_micron`,
    Header: `Gum Micron`,
  },

  
  {
    id: `tape_roll_1_meter_weight`,
    Header: `Tape Roll 1 Meter Weight`,
  },
  {
    id: `tape_roll_1_card_board_weight`,
    Header: `Tape Roll 1 Puthu Weight`,
  },
  {
    id: `tape_roll_1_nos_weight`,
    Header: `Tape Roll 1 NOS Weight`,
  },

  {
    id: `tape_roll_1_Bundle_Nos`,
    Header: `Tape Roll 1 Bundle Nos`,
  },

  {
    id: `tape_roll_1_bundle_weight`,
    Header: `Tape Roll 1 Bundle Weight`,
  },
  {
    id: `tape_roll_bundle_in_carton`,
    Header: `Tape Roll Bundle In Carton`,
  },
  {
    id: `tape_roll_carton_net_weight_kg`,
    Header: `Tape Roll Carton Net Weight Kg`,

  },

  {
    id: `tape_roll_special_running`,
    Header: `Tape Roll`,
   
  },



  {
    id: `hsn_code`,
    Header: `Hsn Code`,
  },

  {
    id: `gst`,
    Header: `GST`,
  },

  {
    id: `bursting_strength`,
    Header: `Bursting Strenth`,
  },
  {
    id: 'status',
    Header: 'Status',

  },
  {
    id: `box_image`,
    Header: `Box Image`,
  },
  {
    id: `packet_image`,
    Header: `Packet Image`,
  },
  {
    id: `tape_roll_image`,
    Header: `Tape Roll Image`,
  },
 
  {
    id: `tape_roll_drawing`,
    Header: `Tape Roll PDF`,
  },
 
  {
    id: 'created_at',
    Header: 'Created at',
    
  },


  {
    id: 'created_by',
    Header: 'Created By',
    

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



export const Columns_TapeRoll_Bopp: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },


  {
    id: `tape_roll_code`,
    Header: () => <div style={{display:"inline"}}>Tape Roll<br/>Code</div>,
    // Header: `Tape Roll Code`,
    accessor:`tape_roll_code`,
    sticky:"left",
    Cell: ({ row }: any) => row.original.tape_roll_code
  },
  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Material Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    // sticky: "left",
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_and_hsn_code?.grade_type?.material_type_name} {row.original.material_name_and_type_and_hsn_code?.particular_sector_name?.name} {row.original.material_name_and_type_and_hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'hsn_code',
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,
    // Header: 'Hsn Code',
    accessor: 'hsn_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_and_hsn_code?.grade_type?.material_type_name}::
          {row.original.material_name_and_type_and_hsn_code?.particular_sector_name?.name === "0.000" || row.original.material_name_and_type_and_hsn_code?.particular_sector_name?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>0.000</span> : row.original.material_name_and_type_and_hsn_code?.particular_sector_name?.name}::
          {row.original.material_name_and_type_and_hsn_code?.product_categories?.name === "0.000" || row.original.material_name_and_type_and_hsn_code?.product_categories?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>****</span> : row.original.material_name_and_type_and_hsn_code?.product_categories?.name}::
          {row.original.material_name_and_type_and_hsn_code?.hsn_code}::
          {row.original.material_name_and_type_and_hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row?.original?.material_name_and_type_and_hsn_code?.gst_type?.name}
        </div>
      )
    }
  },
  {
    id: 'type_name',
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST % </div>,
    // Header: 'Hsn Code',
    accessor: 'type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_and_hsn_code?.grade_type?.material_type_name}::{row.original.material_name_and_type_and_hsn_code?.grade_type?.material_density ? row.original.material_name_and_type_and_hsn_code?.grade_type?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::
          {row.original.material_name_and_type_and_hsn_code?.grade_type?.grade_sutra ? row.original.material_name_and_type_and_hsn_code?.grade_type?.grade_sutra : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::{row.original.hsn_code?.grade_type?.gst?.india_GST}
        </div>
      )
    }
  },
  {
    id: 'use_code_name',
    Header: () => <div style={{ display: "inline" }}>Use Product Code & <br />Grade & Name & Type</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: 'use_code_qty',
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },

  {
    id: `material_type`,
    Header: () => <div style={{display:"inline"}}>Material<br/>Type</div>,
    accessor:`material_type`,
    Cell: ({ row }: any) => row.original.material_type?.name
  },

  {
    id: `product_color`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Color Name</div>,
    accessor:`product_color`,
    Cell: ({ row }: any) => row.original.product_color?.company_color_name?row.original.product_color?.company_color_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `product_width`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Width mm</div>,
    accessor:`product_width`,
    Cell: ({ row }: any) => row.original.product_width	
  },

  {
    id: `product_1_roll_length_meter`,
    Header: () => <div style={{display:"inline"}}>Product 1 Roll<br/>Length Meter</div>,
    accessor:`product_1_roll_length_meter`,
    Cell: ({ row }: any) => row.original.product_1_roll_length_meter	
  },

  {
    id: `thickness_film_micron`,
    Header: () => <div style={{display:"inline"}}>Thickness Film<br/>Micron</div>,
    accessor:`thickness_film_micron`,
    Cell: ({ row }: any) => row.original.thickness_film_micron	
  },


  {
    id: `thickness_gauge`,
    Header: () => <div style={{display:"inline"}}>Thickness<br/>Gauge</div>,
    accessor:`thickness_gauge`,
    Cell: ({ row }: any) => row.original.thickness_gauge	
  },

  
  {
    id: `tape_roll_1_meter_film_net_weight_gram`,
    Header: () => <div style={{display:"inline"}}>1 Meter Film Net<br/>Weight Gram</div>,
    accessor:`tape_roll_1_meter_film_net_weight_gram`,
    Cell: ({ row }: any) => row.original.tape_roll_1_meter_film_net_weight_gram?row.original.tape_roll_1_meter_film_net_weight_gram:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `gumname`,
    Header: () => <div style={{display:"inline"}}>Tape Roll<br/>Gum Name</div>,
    accessor:`gumname`,
    Cell: ({ row }: any) => row.original.gumname?.name	
  },

  {
    id: `gum_ler_micron`,
    Header: () => <div style={{display:"inline"}}>Gum Ler<br/>Micron</div>,
    accessor:`gum_ler_micron`,
    Cell: ({ row }: any) => row.original.gum_ler_micron	
  },

  {
    id: `tape_roll_1_meter_film_in_gum_net_gram`,
    Header: () => <div style={{display:"inline"}}>1 Meter Film In<br/>Gum Net Gram</div>,
    accessor:`tape_roll_1_meter_film_in_gum_net_gram`,
    Cell: ({ row }: any) => row.original.tape_roll_1_meter_film_in_gum_net_gram	
  },

  {
    id: `one_tape_roll_film_net_weight_gram`,
    Header: () => <div style={{display:"inline"}}>1 Tape Roll Film<br/>Net Weight Gram</div>,
    accessor:`one_tape_roll_film_net_weight_gram`,
    Cell: ({ row }: any) => row.original.one_tape_roll_film_net_weight_gram	
  },

  {
    id: `one_tape_roll_gum_ler_weight_gram`,
    Header: () => <div style={{display:"inline"}}>1 Tape Roll Gum Ler<br/>Weight Gram</div>,
    accessor:`one_tape_roll_gum_ler_weight_gram`,
    Cell: ({ row }: any) => row.original.one_tape_roll_gum_ler_weight_gram?row.original.one_tape_roll_gum_ler_weight_gram:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },

  {
    id: `one_tape_roll_film_pluse_gum_weight_gram`,
    Header: () => <div style={{display:"inline"}}>1 Tape Roll Film +<br/>Gum Weight Gram</div>,
    accessor:`one_tape_roll_film_pluse_gum_weight_gram`,
    Cell: ({ row }: any) => row.original.one_tape_roll_film_pluse_gum_weight_gram	
  },

  {
    id: `paper_conr_weight_gram`,
    Header: () => <div style={{display:"inline"}}>Paper Conr<br/>Weight Gram</div>,
    accessor:`paper_conr_weight_gram`,
    Cell: ({ row }: any) => row.original.paper_conr_weight_gram	
  },
  {
    id: `one_tape_roll_film`,
    Header: () => <div style={{display:"inline"}}> 1 Tape Roll Film + Gum<br/>+ Corn Weight Gram</div>,
    accessor:`one_tape_roll_film`,
    Cell: ({ row }: any) => row.original.one_tape_roll_film?row.original.one_tape_roll_film:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `bundle_qty`,
    Header: () => <div style={{display:"inline"}}>Tape Roll<br/>Bundle Qty</div>,
    accessor:`bundle_qty`,
    Cell: ({ row }: any) => row.original.bundle_qty?.name
  },

  {
    id: `tape_roll_bundle_weight_kg`,
    Header: () => <div style={{display:"inline"}}>Tape Roll Bundle<br/>Weight KG</div>,
    accessor:`tape_roll_bundle_weight_kg`,
    Cell: ({ row }: any) => row.original.tape_roll_bundle_weight_kg?row.original.tape_roll_bundle_weight_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },

  {
    id: `bundle_in_carton`,
    Header: () => <div style={{display:"inline"}}>Tape Roll Bundle<br/>In Carton</div>,
    accessor:`bundle_in_carton`,
    Cell: ({ row }: any) => row.original.bundle_in_carton?.name
  },

  {
    id: `tape_roll_net_kg_in_carton`,
    Header: () => <div style={{display:"inline"}}>Tape Roll Net KG<br/>In Carton</div>,
    accessor:`tape_roll_net_kg_in_carton`,
    // Cell: ({ row }: any) => row.original.tape_roll_net_kg_in_carton?row.original.tape_roll_net_kg_in_carton:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    Cell: ({ row }: any) => row.original.tape_roll_net_kg_in_carton?row.original.tape_roll_net_kg_in_carton:<span>0.000</span>
  },


  {
    id: `film_net_1kg_in_meter`,
    Header: () => <div style={{display:"inline"}}>Film Net 1 KG<br/>In Meter</div>,
    accessor:`film_net_1kg_in_meter`,
    // Cell: ({ row }: any) => row.original.film_net_1kg_in_meter?row.original.film_net_1kg_in_meter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    Cell: ({ row }: any) => row.original.film_net_1kg_in_meter?row.original.film_net_1kg_in_meter:<span>0.000</span>
  },


  {
    id: `gum_1kg_in_ler_meter`,
    Header: () => <div style={{display:"inline"}}>Gum 1 KG In<br/>Ler Meter</div>,
    accessor:`gum_1kg_in_ler_meter`,
    Cell: ({ row }: any) => row.original.gum_1kg_in_ler_meter?row.original.gum_1kg_in_ler_meter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },


  {
    id: `film_gum_1kg_in_meter`,
    Header: () => <div style={{display:"inline"}}>Film + Gum<br/>1 Kg. In Meter</div>,
    accessor:`film_gum_1kg_in_meter`,
    Cell: ({ row }: any) => row.original.film_gum_1kg_in_meter?row.original.film_gum_1kg_in_meter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },


  {
    id: `printing`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Printing</div>,
    accessor:`printing`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.printing
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },


  {
    id: `printing_type`,
    Header: () => <div style={{display:"inline"}}>Product <br/>Printing Type</div>,
    accessor:`printing_type`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.printing_type?.name
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },


  {
    id: `printing_color`,
    Header: () => <div style={{display:"inline"}}>Printing<br/>Color Name</div>,
    accessor:`printing_color`,
    Cell: ({row}:any) => {
      let Printing_Color = row.original.printing_color?.map((e:any)=>{
         return e.name
                
         })
      if (Printing_Color.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return Printing_Color.join(",")
     
    }
  },


  
  {
    id: `printing_total_color`,
    Header: () => <div style={{display:"inline"}}>Printing<br/>Total Color</div>,
    accessor:`printing_total_color`,
    Cell: ({ row }: any) => row.original.printing_total_color?row.original?.printing_total_color:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `brand`,
    Header: () => <div style={{display:"inline"}}>Printing<br/>Brand Name</div>,
    accessor:`brand`,
    Cell: ({ row }: any) => row.original.brand?.name?row.original.brand?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: `brand_our_other`,
    Header: () => <div style={{display:"inline"}}>Brand<br/>Our - Other</div>,
    accessor:`brand_our_other`,
    Cell: ({ row }: any) => row.original.brand_our_other?row.original.brand_our_other:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: `carton_stock_minimum_qty`,
    Header: () => <div style={{display:"inline"}}>Carton Stock<br/>Minimum Qty</div>,
    accessor:`carton_stock_minimum_qty`,
    Cell: ({ row }: any) => row.original.carton_stock_minimum_qty	
  },


  
  {
    id: `carton_stock_maximum_qty`,
    Header: () => <div style={{display:"inline"}}>Carton Stock<br/>Maximum Qty</div>,
    accessor:`carton_stock_maximum_qty`,
    Cell: ({ row }: any) => row.original.carton_stock_maximum_qty	
  },


  {
    id: `bursting_strength`,
    Header: () => <div style={{display:"inline"}}>Bursting<br/>Strength</div>,
    accessor:`bursting_strength`,
    Cell: ({ row }: any) => row.original.bursting_strength	
  },


  {
    id: `product_by`,
    Header: () => <div style={{display:"inline"}}>Product<br/>By</div>,
    accessor:`product_by`,
    Cell: ({ row }: any) => row.original.product_by?.name
  },

  {
    id: `product_our_other`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Our - Other</div>,
    accessor:`product_our_other`,
    Cell: ({ row }: any) => row.original.product_our_other?.name
  },

  {
    id: `taperoll_type`,
    Header: () => <div style={{display:"inline"}}>Tape Roll<br/>Type</div>,
    accessor:`taperoll_type`,
    Cell: ({ row }: any) => row.original.taperoll_type?.name
  },

  {
    id: `product_maximum_debit_day`,
    Header: () => <div style={{display:"inline"}}>Product Maximum<br/>Debit Day</div>,
    accessor:`product_maximum_debit_day`,
    Cell: ({ row }: any) => row.original.product_maximum_debit_day	
  },


  {
    id: `product_star`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Star</div>,
    accessor:`product_star`,
    Cell: ({ row }: any) => row.original.product_star?.name
  },


  {
    id: `product_location`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Location</div>,
    accessor:`product_location`,
    Cell: ({ row }: any) => row.original.product_location?.name
  },

  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Name</div>,
    accessor:'material_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.grade_type?.material_type_name}
        </div>
      )
    }

  },
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor:'material_type_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.particular_sector_name?.name}
        </div>
      )
    }

  },
  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor:'material_category',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor:'material_hsn',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.hsn_code}
        </div>
      )
    }

  },
 
  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>Product<br />GST %</div>,
    accessor:'material_gst',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor:'product_density',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_and_hsn_code?.product_density?row.original.material_name_and_type_and_hsn_code?.product_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },


  {
    id: `remark`,
    Header: () => <div style={{display:"inline"}}>Re-Mark<br/></div>,
    accessor:`remark`,
    Cell: ({ row }: any) => row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },


  {
    id: 'status',
    Header: 'Status',
    accessor:'status',
  
  },

    



  {
    id: `carton_packing_image`,
    Header: () => <div style={{ display: "inline" }}>Carton Packing<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.carton_packing_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.carton_packing_image || row.original.carton_packing_image?.length === 0? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                // onClick={() => setDeleteConfirm(true)}
                onClick={() => handleShowImages(row.original)}

                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.carton_packing_image.length > 0 ? row.original.carton_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                {/* <img src={(row.original.carton_packing_image.length > 0 ? row.original.carton_packing_image.at(-1).doc : noImage)} height={60} width={100} /> */}
                <img src={row.original.carton_packing_image[0]?.doc ? row.original.carton_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            {/* <Modal.Body>
              <img src={(row.original.carton_packing_image?.length > 0 ? row.original.carton_packing_image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body> */}
             <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.carton_packing_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>
              
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },



  {
    id: `product_packet_packing_image`,
    Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_packet_packing_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_packet_packing_image || row.original.product_packet_packing_image?.length === 0? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                // onClick={() => setDeleteConfirm(true)}
                onClick={() => handleShowImages(row.original)}

                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_packet_packing_image.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                {/* <img src={(row.original.product_packet_packing_image.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} height={60} width={100} /> */}
                <img src={row.original.product_packet_packing_image[0]?.doc ? row.original.product_packet_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            {/* <Modal.Body>
              <img src={(row.original.product_packet_packing_image?.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body> */}
             <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_packet_packing_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>

              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },




  {
    id: `product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_image || row.original.product_image?.length === 0? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                // onClick={() => setDeleteConfirm(true)}
                onClick={() => handleShowImages(row.original)}

                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                {/* <img src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} height={60} width={100} /> */}
                <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            {/* <Modal.Body>
              <img src={(row.original.product_image?.length > 0 ? row.original.product_image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body> */}
             <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>

              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },
 




    


  {
    id: `product_pdf_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc : null)}>
          {(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },


       
  


    
  {
    id: 'Actions',
    Header: 'Actions',

  
  },

  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_TapeRoll_Bopp, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}tapeRoll/taperoll/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



