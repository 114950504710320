
import "../../../components/Table/table.css"
import { Link, useNavigate } from "react-router-dom";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { useForm } from "react-hook-form";
import useFocusOnEnter from "../../../components/focus";
import { useEffect, useRef, useState } from "react";
import Select from "react-select"
import { DatePicker } from "antd";
import { useFetchCityDropDown, useFetchCustomerDropDown, useFetchDisposableDropDown, useFetchEmployeeDropDown, useFetchMaterialTypeDropDown, useFetchPincodeDropDown, useFetchPriceListProductDropDown, useFetchSourceOfInquiryDropDown, useFetchUOMDropDown } from "../../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import { AiOutlinePlus } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Add_Sales_Product from "../../../components/Add_Sales_Products/Add_Sales_Product";
import "./add_sales.css"
import { Alert } from "../../../components/Model/Model";
import { nanoid } from 'nanoid'
import { customAlphabet } from 'nanoid'
import Discount_Calculation from "../Discount/Discount_Calculation";
import Button from "react-bootstrap/esm/Button";
import Axiosinstance from "../../../hooks/Interceptor";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});


const Add_Sales_Inquiry = () => {

  const nanoid = customAlphabet('1234567890', 5)

  let pageName = "Sales Inquiry"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()

  const apiname = process.env.REACT_APP_SALES_INQUIRY_API?.toString();

  const {
    mutate: sales,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);


  const { mutate: salesUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate, data: dataUpdate }: any = useUpdateSingle()




  const onSubmitFinal = async (data: any) => {
    

    if (isEdit) {
      if (!DiscountWise) {
        delete data.discount
        delete data.percentage_or_amount
        data.avg_discount = 0
      }

      if (!visitingCard) {
        delete data.visiting_card_image
      }

      if (DiscountWise === "Product Wise") {
        delete data?.final_amount
        delete data?.discount
        // delete data?.discount
        delete data?.percentage_or_amount
      }

      if (companyType === "old") {

        // delete data.contact_person
        delete data.name_of_customer
        delete data.mobile_number
        delete data.email
        delete data.village_code_and_name
        delete data.address
        delete data.pincode
        delete data.reference_name


      }

      if (companyType === "new") {
        delete data.company_name_and_city
        delete data.address
        delete data.pincode
      }

      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        const formvalue: any = value
        if (typeof value !== "object") {
          formData.append(key, formvalue)
        }



        else if (key === "visiting_card_image") {
          if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            formData.append(key, formvalue[0])
          }
          else {
            formData.append(key, "")
          }
        }


      }


      let finalData = {
        id: saleInquiryId,
        data: formData,
        apiname: apiname
      }

      salesUpdate(finalData);


    }
    else {


      const formData = new FormData();



      const newFieldErrors = additionalFields.map((field: any) => {
        const errors: any = {};

        if (!field.product_type) {
          errors.product_type = 'Field is required';
        }

        if (!field.product_special_running) {
          errors.product_special_running = 'Field is required';
        }

        if (!field.pricelist_product) {
          errors.pricelist_product = 'Field is required';
        }

        if (!field.uom) {
          errors.uom = 'Field is required';
        }


        if (!field.product_description) {
          errors.product_description = 'Field is required';
        }

        if (!field.product_qty) {
          errors.product_qty = 'Field is required';
        }

        if (!field.expected_basic_value) {
          errors.expected_basic_value = 'Field is required';
        }

        if (!field.requirement_date) {
          errors.requirement_date = 'Date is required';
        }

        if (!field.possible_yes_no) {
          errors.possible_yes_no = 'Field is required';
        }

        return errors;
      });

      setFieldErrors(newFieldErrors);

      const hasErrors = newFieldErrors.some((errors: any) => Object.keys(errors).length > 0);

      if (Array.isArray(productIds)) {
        productIds.forEach((val: any, index: any) => formData.append(`products`, val))
      }


      let finalData = {
        id: saleInquiryId,
        data: formData,
        apiname: apiname
      }

      if (displayData?.length === 0) {
        setNoProductError(true)
      } else {
        await salesUpdate(finalData);
      }


      if (data && noProductError === false) {

        quotation({ sales_inquiry: data?.id, total_product_qty: totalQty })
      }

    }



  }









  useEffect(() => {
    if (isSuccessUpdate) {
      setShow(true)
      setshowmessage("success")
      if (isEdit) {
        setSalesInquiryId(dataUpdate?.data?.id)
        setSalesInquirydata(dataUpdate?.data)
        setIsEdit(false)
      } else {
        navigate('/listSalesinquiry', { state: { showToast: true } })
      }
    }
    else if (isErrorUpdate) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isSuccessUpdate, dataUpdate, errorUpdate])


















  const apinameQuotation = process.env.REACT_APP_QUOTATION_API?.toString();

  const {
    mutate: quotation,
    data: dataQuotation,
    isError: isErrorQuotation,
    isLoading: isLoadingQuotation,
    isSuccess: isSuccessQuotation,
    error: errorQuotation,
  }: any = useCreate(apinameQuotation);

  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });
  const { errors } = formState;


  const [visitingCard, setVisitingCard] = useState(null)
  const onLogoChange = (e: any) => {
    setVisitingCard(e.target.files[0].name)
  }

  const [additionalFields, setAdditionalFields]: any = useState([]);
  const [fieldCounter, setFieldCounter] = useState(2);
  const [fieldErrors, setFieldErrors]: any = useState(Array(additionalFields.length).fill({}));


  const [noProductError, setNoProductError] = useState(false)



  const onSubmit = async (data: any) => {
    if (!DiscountWise) {
      delete data.discount
      delete data.percentage_or_amount
      data.avg_discount = 0
    }

    if (!visitingCard) {
      delete data.visiting_card_image
    }

    if (DiscountWise === "Product Wise") {
      delete data?.final_amount
      delete data?.discount
      // delete data?.discount
      delete data?.percentage_or_amount
    }

    if (companyType === "old") {

      // delete data.contact_person
      delete data.name_of_customer
      delete data.mobile_number
      delete data.email
      delete data.village_code_and_name
      delete data.address
      delete data.pincode
      delete data.reference_name


    }

    if (companyType === "new") {
      delete data.company_name_and_city
      delete data.address
      delete data.pincode
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value
      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }



      else if (key === "visiting_card_image") {
        if (typeof value === 'object' && value !== null && formvalue.length > 0) {
          formData.append(key, formvalue[0])
        }
        else {
          formData.append(key, "")
        }
      }


    }




    sales(formData);

  }

  const [added, setAdded] = useState(false)
  const [displayData, setDisplayData] = useState([])
  const [productIds, setProductIds]: any = useState([])

  useEffect(() => {
    if (displayData?.length > 0) {
      setNoProductError(false)
    }
  }, [displayData])


  // useEffect(() => {
  //   if (additionalFields.length === 0) {
  //     setFieldErrors([]);
  //   }
  // }, [additionalFields]);




  const apinameLead = process.env.REACT_APP_SALES_SOURCE_INQUIRY_API?.toString()



  const { mutate: mutateLead, data: dataLead, isError: isErrorLead, isLoading: isLoadingLead, isSuccess: isSuccessLead, error: errorLead }: any = useCreate(apinameLead)



  const [optionsLead, setOptionsLead]: any = useState([]);

  const [valueLead, setValueLead]: any = useState();


  const [valueLeadCreatable, setValueLeadCreatable] = useState<Option | null>();


  const { data: soiData, refetch: soiRefetch } = useFetchSourceOfInquiryDropDown()
  useEffect(() => {
    setOptionsLead(soiData)
  }, [soiData])







  const handleCreateLead = async (inputValue: string) => {



    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');


    setTimeout(() => {
      const newOption = createOption(inputValue);

      // setValue("name", `${newOption?.value}`)

      mutateLead({ name: newOption.name, status: newOption.status })


      setOptionsLead((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValueLeadCreatable(newOption);


    }, 0);




  };




  const handleChangeLead = (e: any) => {

    soiRefetch()

    setApiName(apinameLead)
    setDeleteApiName(apinameLead)
    setEditId(e?.value)
    setEditData(soiData)
    setRenderKey(prevKey => prevKey + 1);

    setValueLead(e)


    setValue("lead_source_of_inquiry", `${e?.value}`)
    setValueLeadCreatable(null)


  }


  useEffect(() => {
    (async () => {

      if (isSuccessLead && optionsLead && valueLeadCreatable != null) {
        await soiRefetch();
        setValue("lead_source_of_inquiry", soiData[0]?.value)

      }
    })();

    return () => {
    };
  }, [isSuccessLead, soiData]);






  const [optionsSalesExecutive, setOptionsSalesExecutive]: any = useState()
  const [SalesExecutiveValue, setSalesExecutiveValue]: any = useState("")

  const { data: salesExecutiveData, refetch: salesExecutiveRefetch } = useFetchEmployeeDropDown()

  useEffect(() => {
    setOptionsSalesExecutive(salesExecutiveData)
  }, [salesExecutiveData])

  const handleChangeSalesExecutive = (e: any) => {
    setSalesExecutiveValue(e)
    setValue("lead_generated_by_sales_executive_code_and_name", e?.value)
  }



  const [optionsCompany, setOptionsCompany] = useState()
  const [companyValue, setCompanyValue]: any = useState("")
  // const { data: companyData, refetch: companyRefetch } = useFetchCustomerDropDown()
  const { data: companyData, refetch: companyRefetch } = useFetchCustomerDropDown()

  useEffect(() => {
    setOptionsCompany(companyData)
  }, [companyData])

  // useEffect(() => {
  //   let temp = companyData?.map((e: any) => {

  //     return (
  //       {
  //         value: e?.value,
  //         label: `${e?.customerName} ${e.cityName}`,
  //         name: e?.name,

  //         // cityName:`${e.address[0]?.city?.city_name?e.address[0]?.city?.city_name:e.address[0]?.city?.village_name}-${e.address[0]?.city?.district?.name}`,
  //         city: e.cityName,
  //         // city: `${e.city_name_and_distict_name?.district?.name}`,
  //         countryCode: e?.countryCode,
  //         mobile: e?.mobile,
  //         email: e?.email,
  //         reference: `${e?.reference}`,

  //       }
  //     )
  //   })
  //   setOptionsCompany(temp)
  // }, [companyData])

  const handleChangeCompany = (e: any) => {
    setCompanyValue(e)
    setValue("company_name_and_city", e?.value)
    setTaluka(e?.taluka)
    setDistrict(e?.district)
    setState(e?.state)
    // setCountry(e?.country)
    setValue("name_of_customer", e?.name)
    setValue("mobile_number", e?.mobile)
    setValue("email", e?.email)
    setValue("village_code_and_name", e?.city)
    setValue("pincode", e?.pincode)
    setValue("address", e?.address)
    setValue("reference_name", e?.reference)
    setValue("country_code", e?.countryCode)
    setValue("customer_grade", e?.grade)
    // setCountry(e?.countryCode)
    setValue("country_code", e?.countryCode)

  }


  const [optionsProductType, setOptionsProductType]: any = useState([])
  const [valueProductType, setValueProductType]: any = useState("")
  const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown()

  useEffect(() => {
    setOptionsProductType(productTypeData)
  }, [productTypeData])

  // const handleChangeProductType=(e:any)=>{
  //     setValueProductType(e)
  //     setValue("product_type",e?.value)
  // }



  const [optionsPriceList, setOptionsPriceList] = useState([])
  const [PriceListValue, setPriceListValue] = useState("")
  const { data: PriceListData, refetch: PriceListRefetch } = useFetchPriceListProductDropDown()

  useEffect(() => {
    setOptionsPriceList(PriceListData)
  }, [PriceListData])


  const handlePriceList = (e: any) => {
    setPriceListValue(e)
  }





  const [optionsDisposableProduct, setOptionsDisposableProduct] = useState()
  const [valueDisposableProduct, setValueDisposableProduct]: any = useState("")
  const { data: disposableProductData, refetch: disposableProductRefetch } = useFetchDisposableDropDown()

  useEffect(() => {
    setOptionsDisposableProduct(disposableProductData)
  }, [disposableProductData])

  // const handleChangeDisposableProduct=(e:any)=>{
  //     setValueDisposableProduct(e)
  //     setValue("pricelist_product",e?.value)
  // }


  const [optionsCity, setOptionsCity] = useState()
  const [valueCity, setValueCity]: any = useState("")
  const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

  useEffect(() => {
    setOptionsCity(cityData)
  }, [cityData])



  const [taluka, setTaluka] = useState("")
  const [district, setDistrict] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")


  const handleChangeCity = (e: any) => {
    setValueCity(e)
    setValue("village_code_and_name", e?.value)

    // setTaluka(e?.taluka)
    // setDistrict(e?.district)
    // setState(e?.state)
    // setCountry(e?.countryCode)
    setValue("country_code", e?.countryCode)

    // setPincodeByCity(e?.pincode)
  }

  const [pincodeByCity, setPincodeByCity]: any = useState()

  // const [optionsPincode, setOptionsPincode] = useState()
  // const [valuePincode, setValuePincode]: any = useState("")
  // const { data: pincodeData, refetch: pincodeRefetch } = useFetchPincodeDropDown(pincodeByCity)

  // useEffect(() => {
  //   setOptionsPincode(pincodeData)
  // }, [pincodeData])

  // const handleChangePincode = (e: any) => {
  //   setValuePincode(e)
  //   setValue("pincode", e?.value)
  // }


  const [requirementDate, setRequirementDate]: any = useState(null)

  const dateFormatList = ['DD-MM-YYYY'];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {

    return current && current < dayjs().startOf('day');
  };


  //       const handleRequirementDate=(e:any)=>{
  // setRequirementDate(e)
  // setValue("requirement_date",`${e?.$D}-${e?.$M+1}-${e?.$y}`)
  //       }

  const [leadContactedDate, setLeadContactedDate] = useState(null)
  const handleLeadContactedDate = (e: any) => {
    setLeadContactedDate(e)
    setValue("lead_contacted", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
  }




  const handleReset = () => {
    reset()
    setValueLeadCreatable(null)
    setValueLead(null)
    setCompanyValue(null)
    setLeadContactedDate(null)
    setVisitingCard(null)
    setProductType(null)
    setUom(null)
    setRequirementDate(null)
  }






  const [DiscountWise, setDiscountWise] = useState('')
  const [DiscountType, setDiscountType]: any = useState('')
  const [Discount, setDiscount]: any = useState('')
  const [finalAmount, setFinalAmount]: any = useState(0)
  const [totalAmount, setTotalAmount]: any = useState(0)

  const onDiscountWiseChange = (e: any) => {
    setDiscountWise(e.target.value)
  }
  const onDiscountTypeChange = (e: any) => {
    setDiscountType(e.target.value)
  }
  const onDiscountChange = (e: any) => {
    setDiscount(e.target.value)
  }





  const [companyType, setCompanyType] = useState("")

  const handleCompanyType = (e: any) => {
    setCompanyType(e.target.value)

  }



  const apinameAddProduct = process.env.REACT_APP_SALES_ADD_PRODUCT_API?.toString();

  const {
    mutate: addProduct,
    data: addProductData,
    isError: addProductIsError,
    isLoading: addProductIsLoading,
    isSuccess: addProductIsSuccess,
    error: addProductError,
  }: any = useCreate(apinameAddProduct);

  const { mutate: productPatch, isSuccess: productPatchSuccess, isError: productPatchIsError, error: productPatchError, data: dataProductPatch }: any = useUpdateSingle()


  const apinameGetMultipleProduct = process.env.REACT_APP_SALES_GET_MULTIPLE_PRODUCTS_API?.toString();







  const {
    mutate: getProduct,
    data: getProductData,
    isError: getProductIsError,
    isLoading: getProductIsLoading,
    isSuccess: getProductIsSuccess,
    error: getProductError,
  }: any = useCreate(apinameGetMultipleProduct);


  const { register: registerProduct, handleSubmit: handleSubmitProduct, setError: setErrorProduct, formState: formStateProduct, reset: resetProduct, setValue: setValueProduct }: any = useForm({
    mode: "onChange"
  });
  const { errors: errorsProduct } = formStateProduct;






  useEffect(() => {
    if (getProductData) {
      setDisplayData(getProductData?.data)
    }
  }, [getProductData])

  useEffect(() => {
    if (productIds) {
      getProduct({ ids: productIds })
    }
  }, [productIds, productPatchSuccess, dataProductPatch])



  useEffect(() => {
    if (added && addProductIsSuccess) {
      if (addProductData) {
        setProductIds((oldIds: any) => [...oldIds, addProductData?.data?.id]);



        setAdded(false)
      }


    }

  }, [addProductData, addProductIsSuccess, added])



  const [renderKey1, setRenderKey1]: any = useState(false)

  const [activeEditId, setActiveEditId] = useState()
  const handlePatchProduct = async (productData: any) => {

    const formData = new FormData();

    if (DiscountWise === '') {
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }


    if (specialRunning === 'special') {
      delete productData.pricelist_product
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }
    for (const [key, value] of Object.entries(productData)) {
      const formvalue: any = value
      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
    }

    let finalData = {
      id: activeEditId,
      data: formData,
      apiname: apinameAddProduct
    }
    await productPatch(finalData);
    setAdded(true)


    // setProductModel(false)
    setRenderKey1((key: any) => key + 1)
    // resetProduct()
    setProductType(null)
    setUom(null)
    setCodeNameType(null)
    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    // setSpecialRunning(null)
    setValueProduct('product_type', '')
    setValueProduct('pricelist_product', '')
    setValueProduct("product_special_running", "")
    setValueProduct("product_description", "")
    setValueProduct("product_qty", "")
    // setValueProduct('requirement_date', '')
    setValueProduct('expected_basic_value', '')
    setValueProduct('remark', '')
    setValueProduct('possible_yes_no', '')
    // setRequirementDate(null)


  }

  const handleAddProduct = async (productData: any) => {

    
    const formData = new FormData();

    if (DiscountWise === '') {
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }


    if (specialRunning === 'special') {
      delete productData.pricelist_product
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }
    for (const [key, value] of Object.entries(productData)) {
      const formvalue: any = value
      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
    }


    await addProduct(formData);
    setAdded(true)


    // setProductModel(false)
    setRenderKey1((key: any) => key + 1)
    // resetProduct()
    setProductType(null)
    setUom(null)
    setCodeNameType(null)
    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    // setSpecialRunning(null)
    setValueProduct('product_type', '')
    setValueProduct('pricelist_product', '')
    setValueProduct("product_special_running", "")
    setValueProduct("product_description", "")
    setValueProduct("product_qty", "")
    // setValueProduct('requirement_date', '')
    setValueProduct('expected_basic_value', '')
    setValueProduct('remark', '')
    setValueProduct('possible_yes_no', '')
    // setRequirementDate(null)

    // setValueProductType(null)
    // setCodeNameType(null)
    // setUom(null)
    // setRequirementDate(null)
    // setValueProduct('requirement_date','')
    // setDiscountOn(null)
    // setDiscountType(null)
    // setDiscountWith(null)
    // setDiscount(null)
    // setFinalAmount(null)


    // const newProduct = {
    //   id: nanoid(),
    //   product_type: null,
    //   product_special_running: '',
    //   pricelist_product: null,
    //   uom: null,
    //   percentage_or_amount: '',
    //   kg_or_carton: '',
    //   with_or_without_gst: '',
    //   discount: '',
    //   // approved_discount: '',
    //   product_description: '',
    //   product_qty: '',
    //   requirement_date: null,
    //   expected_basic_value: '',
    //   possible_yes_no: '',
    //   pricelist: '',


    // };

    // setAdditionalFields([...additionalFields, newProduct]);




  };

  const handleDeleteProduct = async (id: any) => {
    // const updatedFields = additionalFields.filter((field: any) => field.id !== id);
    // setAdditionalFields(updatedFields);
    await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apinameAddProduct}/${id}/`)

    setProductIds((currentIds: any) => currentIds.filter((currentId: any) => currentId !== id));

    getProduct({ ids: productIds })
    setRenderKey1((key: any) => key + 1)


  };


  const [isEditOn, setIsEditOn] = useState(false)

  useEffect(() => {
    if (productPatchSuccess || dataProductPatch) {
      setIsEditOn(false)
    }
  }, [productPatchSuccess, dataProductPatch])

  const handleEditProduct = (field: any) => {
    setIsEditOn(true)
    setActiveEditId(field.id)

    setProductType({ value: field.product_type?.id, label: field.product_type?.type })
    setValueProduct('product_type', field.product_type?.id)
    setPriceListProductType(field.product_type?.type)


    setValueProduct('product_special_running', field?.product_special_running)

    if (field?.extra_response) {

      setCodeNameType({ value: field?.pricelist_product, label: `${field?.extra_response?.product_code}::${field?.extra_response?.product_name}::${field?.extra_response?.mouldshape_name}` })
      // setCodeNameType({value:field?.pricelist_product?.id,label:`${field?.pricelist_product?.product_code?.product_code}::${field?.pricelist_product?.product_code?.product_type?.type}::${field?.pricelist_product?.product_code?.mouldshape?.name}`})
      setValueProduct('pricelist_product', field?.pricelist_product)
      // setValueProduct('pricelist_product', field?.pricelist_product?.id)
      setValueProduct('pricelist', field?.pricelist)
      // setValueProduct('pricelist', field?.pricelist?.id)
      setNewProductID(field?.pricelist_product)
      // setNewProductID(field?.pricelist_product?.id)
    }
    // setDiscountOn(null)
    // setDiscountType(null)
    // setDiscountWith(null)
    // setDiscount(null)
    // setFinalAmount(null)
    // setNewProductCode(selectedOption?.productCode)



    setValueProduct('product_description', field?.product_description)

    setValueProduct('product_qty', field?.product_qty)


    setUom({ value: field.extra_response.uom_id, label: field.extra_response?.uom })
    // setUom({value:field.uom.id,label:field.uom.name.name})
    setValueProduct('uom', field?.extra_response.uom_id)
    // setValueProduct('uom', field?.uom.id)





    // setRequirementDate(final)
    // setValueProduct('requirement_date', final)
    setValueProduct('requirement_date', field?.requirement_date)
    setRequirementDate(dayjs(field?.requirement_date, 'DD-MM-YYYY'))


    setValueProduct('expected_basic_value', field?.expected_basic_value)

    setValueProduct('possible_yes_no', field?.possible_yes_no)


    setValueProduct('remark', field?.remark)




  }

  const [specialRunning, setSpecialRunning]: any = useState('')
  const handleSpecialRunningChange = (e: any, index: any) => {
    setSpecialRunning(e.target.value)
    // setValue('special_running', e?.value)

  };


  // const [discountType,setDiscountType] = useState('')


  // const[discountType,setDiscountType] = useState('')

  const handleDiscountTypeChange = (e: any, index: any) => {


    setDiscountType(e.target.value)
    setValueProduct('percentage_or_amount', e.target.value)
    // const updatedFields = [...additionalFields];
    // updatedFields[index].percentage_or_amount = e.target.value
    // setKgPrice(updatedFields[index].discount_type)

    // if (updatedFields[index].kg_or_carton === "KG") {
    // } else if (updatedFields[index].kg_or_carton === "Carton") {
    // }

    // setAdditionalFields(updatedFields);



  };

  const [discountOn, setDiscountOn]: any = useState('')
  const [productKG, setProductKG] = useState('')
  const [productCarton, setProductCarton] = useState('')
  const handleDiscountOnChange = (e: any, index: any) => {



    setDiscountOn(e.target.value)

    setValueProduct('kg_or_carton', e.target.value)


    // const updatedFields = [...additionalFields];
    // updatedFields[index].kg_or_carton = e.target.value;


    // updatedFields[index].kg = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate
    // updatedFields[index].carton = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate


    let kg = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate
    let carton = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate

    setProductKG(kg)
    setProductCarton(carton)
    if (e.target.value == "KG") {
      // updatedFields[index].final_amount = updatedFields[index].kg
      setFinalAmount(kg)
    } else if (e.target.value == "Carton") {
      // updatedFields[index].final_amount = updatedFields[index].carton
      setFinalAmount(carton)
    }

    // setKgPrice(dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate)
    // setCartonPrice(dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate)


    // setAdditionalFields(updatedFields);




  };


  const [discountWith, setDiscountWith]: any = useState('')

  const handleDiscountWithChange = (e: any, index: any) => {
    // const updatedFields = [...additionalFields];
    // updatedFields[index].with_or_without_gst = e.target.value;
    // setAdditionalFields(updatedFields);

    setDiscountWith(e.target.value)
    setValueProduct('with_or_without_gst', e.target.value)
  };

  const handleYesNoChange = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].possible_yes_no = e.target.value;
    setAdditionalFields(updatedFields);
  };


  let apiNamePricelistProduct = `${process.env.REACT_APP_PRICELIST_API}/search_filter`
  // let apiNamePricelistProduct = `${process.env.REACT_APP_PRICE_LIST_PRODUCTS_API}/search_filter`
  const { mutate: mutatePricelistProduct, data: dataPricelistProduct }: any = useCreate(apiNamePricelistProduct)

  const [comingData, setComingData]: any = useState([])

  const [optionsCodeName, setOptionsCodeName]: any = useState([])




const [priceListId,setPriceListId]:any = useState('')



  useEffect(() => {
    if (dataPricelistProduct) {
      let temp = dataPricelistProduct?.results[0]?.price_list_products
        ?.map((e: any) => ({
          value: e?.id,
          // label: e?.product_code?.product_code,
          
          label: `${e?.product_code?.product_code}::${e?.product_code?.mouldshape?.name}::${e?.product_code?.product_type?.type}`,
       
          // priceListID: e?.price_list
        }));

        
        
        
        
        setOptionsCodeName(temp)
        let tempId = dataPricelistProduct?.results[0]?.id
        setPriceListId(tempId)
    }
  }, [dataPricelistProduct])





  const [priceListProductType, setPriceListProductType]: any = useState()

  const [productType, setProductType]: any = useState('')

  const handleChangeProductType = (e: any) => {

    setProductType(e)

    setValueProduct('product_type', e.value)






    setPriceListProductType(e?.label)



  };

  const [previousPriceListProductType, setPreviousPriceListProductType] = useState('')

  // useEffect(()=>{

  //   if (priceListProductType) {
  //     if (priceListProductType !== previousPriceListProductType) {
  //       mutatePricelistProduct({ product_type: priceListProductType });
  //       setPreviousPriceListProductType(priceListProductType);
  //     }
  //   }


  // },[priceListProductType,previousPriceListProductType])

  // useEffect(() => {
  //   let timeoutId: any;

  //   if (priceListProductType) {
  //     if (priceListProductType !== previousPriceListProductType) {
  //       if (timeoutId) {
  //         clearTimeout(timeoutId);
  //       }

  //       timeoutId = setTimeout(() => {
  //         mutatePricelistProduct({ product_type: priceListProductType });
  //         setPreviousPriceListProductType(priceListProductType);
  //       }, 3000);
  //     }
  //   }

  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [priceListProductType, previousPriceListProductType]);


  useEffect(() => {
    let timeoutId: any;

    if (priceListProductType && priceListProductType !== previousPriceListProductType) {
      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout for 3 seconds
      timeoutId = setTimeout(() => {
        mutatePricelistProduct({ product_type: priceListProductType });
        setPreviousPriceListProductType(priceListProductType);
      }, 3000);
    }

    // Cleanup the timeout when the component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [priceListProductType, previousPriceListProductType]);


  let apinameForNewProductPricelist = `${process.env.REACT_APP_PRICELIST_API}/search_filter`

  const { mutate: newProductPricelist, data: dataOfNewProductPricelist } = useCreate(apinameForNewProductPricelist)

  const [kgPrice, setKgPrice] = useState(0)
  const [cartonPrice, setCartonPrice] = useState(0)
  // useEffect(()=>{
  // },[kgPrice,cartonPrice])











  // const handleChangeDisposableProduct = (selectedOption:any, index:any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].pricelist_product = selectedOption.value;

  //   setAdditionalFields(updatedFields);
  // };

  const [newProductID, setNewProductID]: any = useState("")
  const [newProductPricelistId, setNewProductPricelistId] = useState("")
  const [newProductCode, setNewProductCode] = useState("")


  // const[PriceListForDiscount,setPriceListForDiscount]:any = useState()




  const handleChangePriceList = async (selectedOption: any, index: any) => {
    // const updatedFields = [...additionalFields];

    // updatedFields[index].pricelist_product = selectedOption.value;
    // updatedFields[index].pricelist = selectedOption.priceListID;
    // setAdditionalFields(updatedFields);

    // setNewProductID(selectedOption?.value)

    // updatedFields[index].kg_or_carton = "";
    // updatedFields[index].percentage_or_amount = "";
    // updatedFields[index].with_or_without_gst = "";
    // updatedFields[index].discount = "";
    // updatedFields[index].final_amount = "";
    // setNewProductCode(selectedOption?.productCode)





  };


  const [codeNameType, setCodeNameType]: any = useState('')
  const handleChangeCodeNameType = async (selectedOption: any, index: any) => {

    setCodeNameType(selectedOption)
    setValueProduct('pricelist_product', selectedOption.value)
    // const updatedFields = [...additionalFields];

    // updatedFields[index].pricelist_product = selectedOption.value;
    // updatedFields[index].pricelist = selectedOption.priceListID;
    // setAdditionalFields(updatedFields);

    setValueProduct('pricelist',priceListId)

    setNewProductID(selectedOption?.value)

    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    setFinalAmount(null)

    setNewProductCode(selectedOption?.productCode)

    // updatedFields[index].kg_or_carton = "";
    // updatedFields[index].percentage_or_amount = "";
    // updatedFields[index].with_or_without_gst = "";
    // updatedFields[index].discount = "";
    // updatedFields[index].final_amount = "";
    // setNewProductCode(selectedOption?.productCode)





  };

  const [optionsUOM, setOptionsUOM] = useState([])
  const { data: dataUOM, refetch: refetchUOM } = useFetchUOMDropDown()
  useEffect(() => {
    setOptionsUOM(dataUOM)
  }, [dataUOM])

  const [uom, setUom]: any = useState('')
  const handleChangeUOM = async (selectedOption: any, index: any) => {


    setUom(selectedOption)
    setValueProduct('uom', selectedOption?.value)
    // const updatedFields = [...additionalFields];

    // updatedFields[index].uom = selectedOption.value;
    // setAdditionalFields(updatedFields);
  }



  useEffect(() => {

    if (additionalFields) {

      const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {
        return total + item.final_amount;
      }, 0);
      setFinalAmount(Number(totalFinalAmount))
      setTotalAmount(Number(totalFinalAmount))
    }

  }, [additionalFields])





  useEffect(() => {
    if (newProductID) {
      newProductPricelist({ price_list_products: newProductID })

    }
  }, [newProductID])

  useEffect(() => {
    if (dataOfNewProductPricelist) {
      setNewProductPricelistId(dataOfNewProductPricelist?.results[0]?.id)
    }
  }, [dataOfNewProductPricelist, newProductPricelistId])


  // const [requirementDate,setRequirementDate]:any = useState()
  const handleRequirementDate = (date: any, dateString: any) => {

    setValueProduct('requirement_date', dateString)
    setRequirementDate(dayjs(dateString, 'DD-MM-YYYY'))

  };


  const handleDescriptionChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].product_description = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };

  // const [calculate, setCalculate]: any = useState('')
  const [discountPrice, setDiscountPrice]: any = useState('')


  const [discountPercentage, setDiscountPercentage]: any = useState()

  const handleDiscountChange = (e: any, index: any) => {



    setDiscount(e.target.value)

    setValueProduct('discount', e.target.value)

    // const updatedFields: any = [...additionalFields];
    // updatedFields[index].discount = e.target.value

    // = e.target.value.toLowerCase()
    // .split(' ')
    // .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');
    // updatedFields[index].final_amount = "";
    // setAdditionalFields(updatedFields);




    if (DiscountType === "Percentage") {
      if (discountOn === "KG") {
        let calculate = ((Number(Discount) / 100) * Number(productKG))
        let percentage = (Number(Discount))

        setDiscountPercentage(percentage)

        let cal = calculate
        let discount = (Number(productKG) - Number(cal))
        setValueProduct('final_amount', discount)
        setFinalAmount(discount)
      }
      if (discountOn === "Carton") {
        let calculate = ((Number(Discount) / 100) * Number(productCarton))
        let percentage = (Number(Discount))
        setDiscountPercentage(percentage)
        let cal = calculate
        let discount = (Number(productCarton) - Number(cal))
        setValueProduct('final_amount', discount)
        setFinalAmount(discount)
      }
    }
    else if (DiscountType === "Amount") {
      if (discountOn === "KG") {
        let discount = ((Number(Discount) / Number(productKG)) * 100)
        let finalAmount = (Number(productKG) - Number(discount))
        setValueProduct('final_amount', finalAmount)
        setFinalAmount(finalAmount)
        setDiscountPercentage(discount)
      }
      if (discountOn === "Carton") {
        let discount = ((Number(Discount) / Number(productCarton)) * 100)
        let finalAmount = (Number(productCarton) - Number(discount))
        setValueProduct('final_amount', finalAmount)
        setFinalAmount(finalAmount)
        setDiscountPercentage(discount)


      }
    }
    // updatedFields[index].avg_discount = updatedFields[index].final_amount
  };
  const [avgDiscount, setAvgDiscount]: any = useState()

  // useEffect(() => {
  //   if (additionalFields) {

  //     const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {

  //       return total + item.discount_percentage/additionalFields.length;
  //     }, 0);
  //     setAvgDiscount(Number(totalFinalAmount))
  //    setValue("avg_discount",avgDiscount)
  //   }

  // }, [additionalFields])






  const handleFinalAmountChange = (e: any, index: any) => {



    const updatedFields: any = [...additionalFields];
    updatedFields[index].final_amount = discountPrice
    // = e.target.value.toLowerCase()
    // .split(' ')
    // .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');

    setAdditionalFields(updatedFields);
    // setDiscountPrice(kgPrice)



  };
  const handleDiscountAmountChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].discount_amount = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };
  const handleApprovedDiscountChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].approved_discount = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };
  // const handlePriceListID = (e:any, index:any) => {

  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].pricelist  =  e.target.value
  //   setAdditionalFields(updatedFields);

  // };

  const handleChangeProductQty = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].product_qty = e.target.value;
    setAdditionalFields(updatedFields);
  };

  const handleChangeBasicValue = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].expected_basic_value = e.target.value;
    setAdditionalFields(updatedFields);
  }


  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  const [saleInquiryId, setSalesInquiryId]: any = useState()

  const [InquiryData, setSalesInquirydata]: any = useState(null)



  useEffect(() => {
    if (isSuccess && data) {
      setSalesInquiryId(data?.data?.id)
      setSalesInquirydata(data?.data)
    }
  }, [isSuccess, data])
  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")

      // navigate('/listSalesinquiry', { state: { showToast: true } })

    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isSuccess, data, error])

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  }








  const [totalQty, setTotalQty] = useState(0)

  useEffect(() => {

    let totalQuantity: number = 0;
    let totalBasicAmount: number = 0;

    additionalFields?.forEach((field: any) => {
      const productQty: any = field.product_qty;
      const basicAmount: any = field.product_qty;

      if (!isNaN(productQty)) {
        totalQuantity += Number(productQty);
      }
      setTotalQty(totalQuantity);


      if (!isNaN(basicAmount)) {
        totalBasicAmount += Number(totalBasicAmount);
      }

    });

  }, [additionalFields, totalQty])


  // useEffect(()=>{
  //   const results = Discount_Calculation(dataOfNewProductPricelist)
  // },[dataOfNewProductPricelist])

  const [perTotal, setPerTotal] = useState(0)

  useEffect(() => {


    if (DiscountType == "Percentage") {

      let calculated = ((Number(Discount) / 100) * Number(finalAmount))
      // setPerTotal(Number(calculated))


      let discount = (Number(finalAmount) - Number(calculated))
      setTotalAmount(Number(discount))
      setPerTotal(finalAmount)
      setValueProduct("discount_percentage", Discount)
      setValueProduct("final_amount", discount)
      setValue("avg_discount", Discount)
    }
    else if (DiscountType === "Amount") {
      let discount = ((Number(Discount) / Number(finalAmount)) * 100)
      let totalAmount = (Number(finalAmount) - Number(Discount))

      setTotalAmount(Number(totalAmount))
      setValueProduct("discount_percentage", discount)
      setPerTotal(discount)
      setValueProduct("final_amount", totalAmount)
      setValue("avg_discount", discount)
    }


  }, [DiscountType, Discount, totalAmount, finalAmount])




  // useEffect(()=>{
  // if(finalAmount){
  //   if (DiscountType === "percentage") {

  //     if(Discount){

  //           let calculated = ((Number(Discount) / 100) * Number(finalAmount))
  //           setPerTotal(Number(calculated))
  //     }

  //           let discount = (Number(finalAmount) - Number(perTotal))
  //           setFinalAmount(Number(discount))
  //       }
  // }

  // if (DiscountType === "percentage") {
  //   let temp = ((Number(Discount) / 100) * Number(finalAmount))
  //   let discount = (Number(finalAmount) - Number(temp))
  //   setFinalAmount(Number(discount))


  // }


  // },[DiscountType])

  const [totalDiscount, setTotalDiscount]: any = useState(0)



  useEffect(() => {
    if (Discount == '') {
      setTotalDiscount(finalAmount)
    }
    else {
      setTotalDiscount(totalAmount)

    }

  }, [totalDiscount])




  // const [productModel, setProductModel] = useState(false)



  const [renderKey, setRenderKey] = useState(0);
  const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()


  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);

  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();




  const handleRefetch = () => {
    if (apiName === apinameLead) {
      soiRefetch()
    }




    // else if(apiName === apinameForTaluka){
    //   TalukaRefetch()
    // }
  }


  useEffect(() => {
    if (OpenEditDropDownModel && valueLead && apiName === apinameLead) {
      setValueLead(null)
      setValueLeadCreatable(null)
    }







    if (deletesucess && apiName === apinameLead) {
      setValueLead(null)
      setValueLeadCreatable(null)
    }


  }, [OpenEditDropDownModel, apiName, deletesucess])


  const [isExist, setIsExist] = useState(true)


  const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end" }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}
            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditDropDownModel}
          // permissionclassName={permissionClass}
          />
          : ""}

      </div>
    </div>


  )


  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
  }



  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };
  const [isDeleted, setIsDeleted] = useState(false)

  const [isEdit, setIsEdit] = useState(false)

  const handleDeleteSaleInquiry = () => {


    setValue("company_new_old", '')
    setCompanyValue(null)
    setValue("village_code_and_name", '')
    setValueCity(null)
    setLeadContactedDate(null)
    setValue("requirement_status", '')
    setValue("lead_source_of_inquiry", '')
    setValueLeadCreatable(null)
    setValueLead(null)
    setValue("mobile_number", '')
    setValue("email", '')
    setValue("name_of_customer", '')
    setValue("reference_name", '')
    setValue("country_code", '')
    setValue("remark", '')
    setValue("discount_type", '')
    setVisitingCard(null)


    setSalesInquirydata(null)
    setSalesInquiryId(null)
    setIsDeleted(true)
    setIsEdit(false)

  }


  const handleEditSaleInquiry = () => {
    setSalesInquirydata(null)
    setIsDeleted(false)
    setIsEdit(true)

  }


  const optionsGrade = [
    {
      value: 5,
      label: "E"
    },
    {
      value: 4,
      label: "D"
    },
    {
      value: 3,
      label: "C"
    },
    {
      value: 2,
      label: "B"
    },
    {
      value: 1,
      label: "A"
    },

  ]
  const [forGradeValue, setForGradeValue]: any = useState([])

  const handleChangeGrade = (e: any) => {
    setForGradeValue(e)
    setValue("customer_grade", e?.label)
  }

  // return e.product_type.id
  // }))

  // let temp = displayData.map((e:any)=>{
  //   if(e.pricelist_product?.product_code?.id)
  // })



  const customStyles = {
    control: (styles: any) => ({
        ...styles,

    }),
    option: (styles: any) => ({
        ...styles,

    }),
    menu: (styles: any) => ({
        ...styles,
        width: '220px'
    })

};


  return (
    <>
      <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Add {pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                  <li className="breadcrumb-item active">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">

              <form
                ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body" >

                  {/* <div className="col-md-4 row g-3 align-items-center">
                    <div className="col-sm">
                      <label className="col-form-label">Price List Grid</label>
                    </div>
                    <div className="col-6 d-flex">
                      <Select

                        components={customIndicator}

                      />
                    </div>
                  </div>

                  <div className="col-md-4 row g-3 align-items-center">
                    <div className="col-sm">
                      <label className="col-form-label">Password</label>
                    </div>
                    <div className="col-6 d-flex">
                      <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                    </div>
                  </div> */}

                  {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""} */}
                  <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">



                        <div className="row ">
                          <h4 className="mb-0">Sales Info</h4>
                        </div>
                        {InquiryData == null ?
                          <>
                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                              <div className="col-md-12">
                                <div className="row">


                                  <div className="row">
                                    <div className="col-sm" style={{ paddingRight: '0px' }}>
                                      {/* <div className="col-sm row  align-items-center border border-dark">
                                      <div className="col-sm">
                                        <label className="col-form-label">Price List Grade <span style={{ color: 'red' }}>*</span></label>
                                      </div>
                                      <div className="col-6 ">
                                        <Select

                                          components={customIndicator}

                                        />
                                        <span className='text-red w-100'>{errors?.company_name_and_city?.message}</span>
                                      </div>
                                      </div> */}

                                      <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                        <div className="col-sm">
                                          <label className="col-form-label">Customer New-Old <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <select className="form-select"
                                            {...register("company_new_old", { onChange: handleCompanyType, required: { value: true, message: "Field is required" } })}
                                          >
                                            <option value={""} selected>Select Any</option>
                                            <option value={"old"}>Old</option>
                                            <option value={"new"}>New</option>
                                          </select>

                                        </div>
                                        <span className='text-red w-100'>{errors?.company_new_old?.message}</span>
                                      </div>
                                      {/* {companyType === "old" && */}
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Customer Name  {companyType === "old" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 ">
                                          <Select

                                            {...register("company_name_and_city", {
                                              required: {
                                                value: companyType === "old" ? true : false,
                                                message: "Field is required",
                                              },
                                            })}
                                            onChange={handleChangeCompany}
                                            isDisabled={companyType === "new" || companyType === ""}
                                            options={optionsCompany}
                                            value={companyValue}
                                            components={customIndicator}
                                            

                                          />
                                          <span className='text-red w-100'>{errors?.company_name_and_city?.message}</span>
                                        </div>
                                      </div>
                                      {/* } */}

                                      {companyType === "old" ?
                                        <>
                                          <div className="col-sm row  align-items-center border border-dark">
                                            <div className="col-sm">
                                              <label className="col-form-label" >City Name </label>
                                            </div>
                                            <div className="col-6 d-flex">
                                              <input type="text" className="form-control"
                                                disabled={companyType === "old"}
                                                placeholder="City Name"

                                                {...register("village_code_and_name")}
                                              />

                                            </div>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <div className="col-sm row  align-items-center border border-dark">
                                            <div className="col-sm">
                                              <label className="col-form-label">City Name  {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                            </div>
                                            <div className="col-6 ">
                                              <Select
                                                {...register("village_code_and_name", {
                                                  required: {
                                                    value: companyType === "new" ? true : false,
                                                    message: "Field is required",
                                                  },
                                                })}

                                                // isDisabled={companyType === "old"}
                                                onChange={handleChangeCity}
                                                components={customIndicator}
                                                options={optionsCity}
                                                value={valueCity}
                                              />
                                              <span className='text-red w-100'>{errors?.village_code_and_name?.message}</span>
                                            </div>
                                          </div>
                                        </>
                                      }
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Requirment Date </label>
                                        </div>
                                        <div className="col-6 d-flex">

                                          <DatePicker

                                            {...register("lead_contacted", { required: { value: false, message: "Date is required" } })}
                                            className="form-control"
                                            format={dateFormatList}
                                            value={leadContactedDate}
                                            disabledDate={disabledDate}


                                            onChange={handleLeadContactedDate}

                                          />


                                        </div>
                                        <span className='text-red'>{errors?.lead_contacted?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                        <div className="col-sm">
                                          <label className="col-form-label">Requirment Status <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <select className="form-select"
                                            {...register("requirement_status", { required: { value: true, message: "Field is required" } })}
                                          >
                                            <option value={""} selected>Select Any</option>
                                            <option value={"Hot"}>Hot</option>
                                            <option value={"warm"}>Warm</option>
                                          </select>
                                        </div>
                                        <span className='text-red w-100'>{errors?.requirement_status?.message}</span>
                                      </div>
                                      {/* <span className="hr"></span> */}
                                    </div>

                                    <div className="col-sm" style={{ paddingLeft: '0px', paddingRight: '0px' }}>




                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label"> Lead Source Of Enquiry <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 ">
                                          <CreatableSelect
                                            isClearable={() => {
                                              setValueLead(null)
                                              setValue(null)
                                            }}
                                            {...register("lead_source_of_inquiry", { required: { value: true, message: "Field is required" } })}
                                            components={customCreatableIndicator}
                                            options={optionsLead}
                                            onCreateOption={handleCreateLead}
                                            onChange={handleChangeLead}
                                            onMenuOpen={() => soiRefetch()}
                                            value={valueLeadCreatable == null ? valueLead : valueLeadCreatable}
                                          />
                                          <span className='text-red w-100'>{errors?.lead_source_of_inquiry?.message}</span>
                                        </div>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Mobile Number {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type={companyType === "old"?"text":"number"} className="form-control" id="exampleInputBrand" placeholder="Mobile Number"
                                            disabled={companyType === "old"}
                                            // maxLength={10}
                                            // minLength={15}
                                            // onInput={maxLengthCheck}

                                            // {...register('mobile_number', {
                                            //   required: { value: companyType === "new" ? true : false, message: "Field is required" },
                                            //   pattern: companyType === "new"?{
                                            //     value: /^(\+\d{1,6}[- ]?)?\d{10}$/g,
                                            //     message: 'Please enter a valid number',
                                            //   }:'',
                                            // })}
                                            {...register('mobile_number', { required: { value: false, message: "Field is required" }})}
                                          />
                                          {companyType === "new" ?
                                            <div className="input-group-append" >
                                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                              // style={{backgroundColor:"blue",color:"green"}} 
                                              /></span>

                                            </div>
                                            : ""}
                                        </div>
                                        <span className='text-red w-100'>{errors?.mobile_number?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">E-Mail  {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="email" className="form-control" id="exampleInputBrand"
                                            disabled={companyType === "old"}
                                            placeholder="Email Address"
                                            {...register('email', {
                                              required: { value: companyType === "new" ? true : false, message: "Field is required" },
                                              pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,6}\.[0-9]{1,6}\.[0-9]{1,6}\.[0-9]{1,6}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                message: 'Please enter a valid email',
                                              },
                                            })}
                                          />
                                          {companyType === "new" ?
                                            <div className="input-group-append" >
                                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                              // style={{backgroundColor:"blue",color:"green"}} 
                                              /></span>

                                            </div>
                                            : ""}
                                        </div>
                                        <span className='text-red w-100'>{errors?.email?.message}</span>
                                      </div>
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Name Of Owner  </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled={companyType === "old"}
                                            placeholder="Name Of Owner "

                                            {...register("name_of_customer", { onChange: handleValue, required: { value: companyType === "new" ? false : false, message: "Field is required" } })}
                                          />
                                          {companyType === "new" ?
                                            <div className="input-group-append" >
                                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                            </div>
                                            : ""}
                                        </div>
                                        <span className='text-red w-100'>{errors?.name_of_customer?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Reference By Name </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled={companyType === "old"}
                                            placeholder="Reference By Name"
                                            {...register("reference_name", { onChange: handleValue, required: { value: companyType === "new" ? false : false, message: "Field is required" } })}
                                          />
                                          {companyType === "new" ?
                                            <div className="input-group-append" >
                                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                            </div>
                                            : ""}
                                        </div>
                                        <span className='text-red w-100'>{errors?.reference_name?.message}</span>
                                      </div>


                                    </div>

                                    <div className="col-sm " style={{ paddingLeft: '0px' }}>


                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Customer Grade {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        {companyType === "old" ?
                                          <>
                                            <div className="col-6 d-flex">
                                              <input type="text" className="form-control"
                                                disabled={companyType === "old"}
                                                placeholder="Customer Grade"

                                                {...register("customer_grade")}
                                              />

                                            </div>
                                          </> :
                                          <>
                                            <div className="col-6 ">
                                              <Select
                                                {...register("customer_grade", { required: { value: true, message: "Customer Grade is required" } })}
                                                placeholder={'Customer Grade'}
                                                onChange={handleChangeGrade}
                                                value={forGradeValue}
                                                options={optionsGrade}
                                                components={customIndicator}
                                                maxMenuHeight={150}
                                              />

                                            </div>
                                          </>}
                                        <span className='text-red w-100'>{errors?.discount_wise?.message}</span>
                                      </div>


                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Country Code </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled
                                            placeholder="Country Code"
                                            // value={country}

                                            {...register("country_code")}
                                          />
                                          {companyType === "new" ?
                                            <div className="input-group-append" >
                                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                            </div>
                                            : ""}
                                        </div>
                                        <span className='text-red w-100'>{errors?.contact_person?.message}</span>
                                      </div>






                                      {/* </div>
                                      <div className="col-sm"> */}
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Remark </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"

                                            placeholder="Remark"
                                            {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.remark?.message}</span>
                                      </div>





                                      <div className="col-sm row  align-items-center border border-dark">
                                        {/* <div className="col-sm-6 mt-2 image-upload"> */}
                                        <div className="col-sm">
                                          <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <label style={{ cursor: "pointer" }}>
                                            <img src={cameraLogo} alt='' className="uploaded-image" style={{ width: 80, height: 70 }} />
                                            <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image", { onChange: onLogoChange, required: false })} hidden />
                                          </label>
                                          {visitingCard && <p style={{ display: "inline-flex" }}>{visitingCard}
                                            {/* <button style={{border:"none"}} onClick={()=>setVisitingCard(null)}><RxCross2 color='red' size={20}/></button> */}
                                          </p>}

                                        </div>
                                      </div>


                                    </div>
                                  </div>

                                  <div >

                                    {isEdit ?
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          // onClick={() => setProductModel(true)}
                                          style={{ marginTop: '10px' }}
                                          onClick={handleSubmit(onSubmitFinal)}
                                        >
                                          Submit
                                        </button>
                                      </> :
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          // onClick={() => setProductModel(true)}
                                          style={{ marginTop: '10px' }}
                                          onClick={handleSubmit(onSubmit)}
                                        >
                                          Submit
                                        </button>
                                      </>}

                                  </div>



                                </div>
                              </div>
                            </div>
                          </>
                          : null}


                        <div className="" style={{ height: "155px", width: '100%' }} >

                          {/* {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Product</span> : null} */}


                          <table key={renderKey1} className="table" style={{ marginTop: "20px", width: "-webkit-fill-available", textAlign: 'center' }}>
                            <thead>
                              <tr>
                                {/* <th>ID</th> */}
                                {/* <th>Price-List<br />Grade</th> */}
                                <th>Customer<br />New-Old</th>
                                <th>Customer <br />Name</th>
                                <th>City &<br />District</th>
                                <th>Requirment<br />Date</th>
                                <th>Requirment<br />Status</th>
                                <th>Lead Source<br />Of Enquiry</th>
                                <th>Mobile <br />Number</th>
                                <th>E-Mail <br />Address</th>
                                <th>Name Of <br />Owner</th>
                                <th>Reference By<br />Name</th>
                                <th>Customer<br />Grade</th>
                                <th>Country<br />Code</th>
                                <th>Remark</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {InquiryData ?
                              <tbody>
                                {/* {displayData?.map((field: any, index: any) => ( */}
                                <tr  >
                                  {/* <td>1</td> */}


                                  {/* <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    // value={'Xyz'}

                                  />

                                </td> */}

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.company_new_old}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.customer_name}
                                    />

                                  </td>



                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={`${InquiryData?.extra_response?.company_name_and_city?.city[0]?.city_name ? InquiryData?.extra_response?.company_name_and_city?.city[0]?.city_name : InquiryData?.extra_response?.company_name_and_city?.city[0]?.village_name}-${InquiryData?.extra_response?.company_name_and_city?.city[0]?.district}`}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.lead_contacted}

                                    />

                                  </td>




                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.requirement_status}

                                    />

                                  </td>




                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.lead_source_of_inquiry?.name}

                                    />

                                  </td>


                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.contact_detail_number}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.email_address}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.customer_name}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.reference_name ? InquiryData?.extra_response?.company_name_and_city?.reference_name : InquiryData?.extra_response?.company_name_and_city?.other_reference}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.customer_grade}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.extra_response?.company_name_and_city?.country_code_mobile[0]}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.remark ? InquiryData?.remark : "-"}

                                    />

                                  </td>

                                  <td>
                                    <div style={{ display: 'flex' }}>

                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={handleEditSaleInquiry}
                                        style={{ marginTop: '10px' }}
                                      >
                                        <FiEdit />
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={handleDeleteSaleInquiry}
                                        style={{ marginTop: '10px' }}
                                      >
                                        <RiDeleteBinLine />
                                      </button>
                                    </div>
                                  </td>
                                  {/* 
                                  <td>
                                    
                                  </td> */}


                                </tr>
                                {/* ))} */}
                              </tbody>
                              : null}
                          </table>




                        </div>
                      </div>

                      {/* <button
                        type="button"
                        className="btn btn-success"
                        // onClick={() => setProductModel(true)}
                        style={{ marginTop: '10px' }}
                      >
                        Add Products
                      </button> */}


                      <div className="row ">
                        <h4 className="mb-0 ">Add Product</h4>
                      </div>
                      <div className="table-responsive " style={{ height: 'auto', overflowX: 'visible' }}>

                        {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Product</span> : null}


                        <table key={renderKey1} className="table" style={{ marginTop: "20px", textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th> 
                                <label htmlFor="exampleInputEmail1" style={{ paddingBottom: '55px' }}>ID</label>
                                </th>
                              <th style={{ width: "12%" }}>
                                {/* Product <br />Type */}
                                {/* <div className="col-sm-2 mt-2"> */}
                                <label htmlFor="exampleInputEmail1">Product <br />Type<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <Select
                                    {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                                    placeholder="Product Type"
                                    value={productType}
                                    // className="form-select"
                                    components={customIndicator}
                                    options={optionsProductType}
                                    onChange={handleChangeProductType}
                                    maxMenuHeight={100}
                                  // isOptionDisabled={(optionsProductType:any)=>{
                                  //   if(optionsProductType.value === productType.value){
                                  //     return optionsProductType
                                  //   }
                                  // }}

                                  />

                                  <span className='text-red w-100'>{errorsProduct?.product_type?.message}</span>
                                </div>

                                {/* </div> */}
                              </th>
                              <th>
                                {/* Special/Running */}

                                <label htmlFor="exampleInputEmail1">Special/<br />Running<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <select
                                    {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                                    onChange={handleSpecialRunningChange}
                                    className="form-select"
                                  >
                                    <option value={''}>Select Any</option>
                                    <option value={'special'}>Special</option>
                                    <option value={'running'}>Running</option>
                                  </select>

                                  <span className='text-red w-100'>{errorsProduct?.product_special_running?.message}</span>
                                </div>


                              </th>
                              <th style={{ width: "12%" }}>
                                {/* Product Code & <br />Name & Type */}

                                <label htmlFor="exampleInputEmail1">Product Code & <br />Name & Type {specialRunning === "running" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                <div className="">
                                  <Select
                                    {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                                    value={codeNameType}
                                    isDisabled={specialRunning === "special"}
                                    options={optionsCodeName}
                                    components={customIndicator}
                                    // className="form-select"
                                    onChange={handleChangeCodeNameType}
                                    styles={customStyles} 
                                    maxMenuHeight={100}
                                    isOptionDisabled={(optionsCodeName: any) => {
                                      const temp = displayData.map((e: any) => e.pricelist_product);
                                      return temp.includes(optionsCodeName.value);
                                    }}



                                  />

                                  <span className='text-red w-100'>{errorsProduct?.pricelist_product?.message}</span>
                                </div>

                              </th>
                              <th>
                                {/* Product <br />Description */}

                                <label htmlFor="exampleInputEmail1">Product <br />Description<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <input
                                    {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                                    className="form-control"
                                    type="text"
                                    placeholder="Product Description"
                                  // value={field.product_description}
                                  // onChange={handleDescriptionChange}
                                  />
                                  <span className='text-red w-100'>{errorsProduct?.product_description?.message}</span>
                                </div>

                              </th>
                              <th>
                                {/* Product<br />Qty */}
                                <label htmlFor="exampleInputEmail1">Product<br />Qty <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <input
                                    {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                                    className="form-control"
                                    type="number"
                                    placeholder="Product Total Qty."
                                  // value={field.product_qty}
                                  // onChange={handleChangeProductQty}
                                  />
                                  <span className='text-red w-100'>{errorsProduct?.product_qty?.message}</span>
                                </div>


                              </th>
                              <th style={{ width: "10%" }}>
                                {/* UOM */}


                                <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>UOM <span style={{ color: 'red' }}>*</span> </label>
                                <div className="">
                                  <Select
                                    {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                                    placeholder="UOM"
                                    options={optionsUOM}
                                    components={customIndicator}
                                    value={uom}
                                    onChange={
                                      handleChangeUOM
                                    }
                                    maxMenuHeight={100}

                                  />

                                  <span className='text-red w-100'>{errorsProduct?.uom?.message}</span>
                                </div>
                              </th>
                              <th>
                                {/* Requirement <br />Date */}

                                <label htmlFor="exampleInputEmail1">Requirement <br />Date <span style={{ color: 'red' }}>*</span></label>
                                <div className="input-group">
                                  <DatePicker
                                    className="form-control"
                                    {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                                    allowClear={false}
                                    format={dateFormatList}
                                    value={requirementDate}
                                    onChange={handleRequirementDate}
                                    disabledDate={disabledDate}

                                  />
                                  <span className='text-red w-100'>{errorsProduct?.requirement_date?.message}</span>
                                </div>
                              </th>
                              <th>
                                {/* Expected <br />Basic Value */}

                                <label htmlFor="exampleInputEmail1">Expected <br />Basic Value <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <input
                                    {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                                    className="form-control"
                                    type="number"
                                    placeholder="Expected Basic Value"
                                  // value={field.expected_basic_value}
                                  // onChange={handleChangeBasicValue}
                                  />
                                  <span className='text-red w-100'>{errorsProduct?.expected_basic_value?.message}</span>
                                </div>

                              </th>
                              <th>
                                {/* Possible <br />Yes/No */}

                                <label htmlFor="exampleInputEmail1">Possible <br />Yes/No <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  <select
                                    {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                                    // value={field.possible_yes_no}
                                    // onChange={handleYesNoChange}
                                    className="form-select"
                                  >
                                    <option value={''}>Select Any</option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                  </select>
                                  <span className='text-red w-100'>{errorsProduct?.possible_yes_no?.message}</span>
                                </div>

                              </th>
                              <th>
                                {/* Re-Mark */}

                                <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>Re-Mark <br /></label>
                                <div className="">
                                  <input
                                    {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                                    className="form-control"
                                    type="text"
                                    placeholder="Re-Mark"
                                  // value={field.expected_basic_value}
                                  // onChange={handleChangeBasicValue}
                                  />
                                  <span className='text-red w-100'>{errorsProduct?.remark?.message}</span>
                                </div>

                              </th>

                              {/* <th>
                                <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>Edit <br /></label>

                              </th> */}

                              <th>
                                {/* Actions */}
                                <label htmlFor="exampleInputEmail1" >Actions </label>
                                {/* <div className=""> */}

                                {isEditOn ?
                                  <>
                                    <div >
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        // onClick={() => setProductModel(true)}
                                        // onClick={handleSubmitProduct(handleAddProduct)}
                                        style={{ marginTop: '10px', fontSize: '13px' }}
                                        onClick={() => {

                                          setIsEditOn(false)
                                          setProductType(null)
                                          setUom(null)
                                          setCodeNameType(null)
                                          setDiscountOn(null)
                                          setDiscountType(null)
                                          setDiscountWith(null)
                                          setDiscount(null)
                                          // setSpecialRunning(null)
                                          setValueProduct('product_type', '')
                                          setValueProduct('pricelist_product', '')
                                          setValueProduct("product_special_running", "")
                                          setValueProduct("product_description", "")
                                          setValueProduct("product_qty", "")
                                          // setValueProduct('requirement_date', '')
                                          setValueProduct('expected_basic_value', '')
                                          setValueProduct('remark', '')
                                          setValueProduct('possible_yes_no', '')
                                          // setRequirementDate(null)

                                        }}
                                      >
                                        Cancel
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        // onClick={() => setProductModel(true)}
                                        onClick={handleSubmitProduct(handlePatchProduct)}
                                        style={{ marginTop: '10px', fontSize: '13px' }}
                                      // onClick={handleCheck}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </> :
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    // onClick={() => setProductModel(true)}
                                    onClick={handleSubmitProduct(handleAddProduct)}
                                    style={{ marginTop: '10px' }}
                                  // onClick={handleCheck}
                                  >
                                    Save
                                  </button>
                                }
                                {/* <button
                                  type="button"
                                  className="btn btn-success"
                                  // onClick={() => setProductModel(true)}
                                  onClick={handleSubmitProduct(handleAddProduct)}
                                  style={{ marginTop: '10px' }}
                                // onClick={handleCheck}
                                >
                                  Save
                                </button> */}
                                {/* </div> */}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayData?.map((field: any, index: any) => (
                              <tr  >
                                <td>{index + 1}</td>


                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.product_type?.type}

                                  />

                                </td>

                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.product_special_running}

                                  />

                                </td>

                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={`${field?.pricelist_product != null ? `${field?.extra_response?.product_code}::${field?.extra_response?.product_name}::${field?.extra_response?.mouldshape_name}` : '-'}`}

                                  />

                                </td>



                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.product_description}
                                  />

                                </td>

                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.product_qty}

                                  />

                                </td>




                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.extra_response?.uom}

                                  />

                                </td>

                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.requirement_date}

                                  />

                                </td>


                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.expected_basic_value}

                                  />

                                </td>


                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.possible_yes_no}

                                  />

                                </td>

                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    value={field.remark ? field.remark : '-'}
                                  />

                                </td>

                                {/* <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleEditProduct(field)}
                                  >
                                    Edit
                                  </button>
                                </td> */}

                                <td>
                                  <div style={{ display: 'block' }}>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleEditProduct(field)}
                                    >
                                      <FiEdit />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleDeleteProduct(field.id)}
                                    >
                                      <RiDeleteBinLine />
                                    </button>
                                  </div>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>




                      </div>





                      {DiscountWise === "Total Amount Wise" ?


                        <>
                          <div className="row d-flex">

                            <div className="col-sm-4 mt-2">
                              <label >Discount Type <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <select className="form-select"
                                  {...register("percentage_or_amount", { onChange: onDiscountTypeChange, required: { value: true, message: "Field is required" } })}
                                >
                                  <option value={""} selected>Select Any</option>
                                  <option value={"Amount"}>Amount</option>
                                  <option value={"Percentage"}>Percentage</option>
                                </select>

                              </div>
                              <span className='text-red w-100'>{errors?.percentage_or_amount?.message}</span>
                            </div>


                            <div className="col-sm-4 mt-2">
                              <label >Discount  <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <input type="number" className="form-control"

                                  placeholder="Discount"
                                  {...register("discount", { onChange: onDiscountChange, required: { value: true, message: "Field is required" } })}
                                />
                                <div className="input-group-append" >
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.discount?.message}</span>
                            </div>
                            <div className="col-sm-4 mt-2">
                              <label >Total Amount  <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <input type="number" className="form-control"
                                  disabled
                                  placeholder="Total Amount"
                                  value={totalAmount}
                                  {...register("final_amount", { onChange: onDiscountChange })}
                                />
                                <div className="input-group-append" >
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        : ""}

                      {/* <div className="row d-flex"> */}
                      <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                        <div className="row d-flex">
                          <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center">
                            <button
                              type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                              style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                              onClick={onSubmitFinal}
                            // onClick={handleSubmit(onSubmit)}
                            >Submit</button>
                            <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSalesinquiry")}>Cancel</button>

                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>



        {/* Product Modal */}
        <Modal
          key={renderKey1}
          size="xl"
          // show={productModel}
          // onHide={() => setProductModel(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Products </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
            </span> */}

            <form >


              <div className="card-body" style={{ maxHeight: "100vh" }}>
                <div className="row d-flex justify-content-md- center justify-content-lg-start">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Type<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <Select
                            {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                            placeholder="Product Type"
                            value={productType}
                            maxMenuHeight={100}
                            // className="form-select"
                            components={customIndicator}
                            options={optionsProductType}
                            onChange={handleChangeProductType}

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_type?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Special / Running<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <select
                            {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                            onChange={handleSpecialRunningChange}
                            className="form-select"
                          >
                            <option value={''}>Select Any</option>
                            <option value={'special'}>Special</option>
                            <option value={'running'}>Running</option>
                          </select>

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_special_running?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Code & Name & Type {specialRunning === "running" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                        <div className="">
                          <Select
                            {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                            value={codeNameType}
                            maxMenuHeight={100}
                            isDisabled={specialRunning === "special"}
                            options={optionsCodeName}
                            components={customIndicator}
                            // className="form-select"
                            onChange={handleChangeCodeNameType}

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.pricelist_product?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">UOM<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <Select
                            {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                            placeholder="UOM"
                            maxMenuHeight={100}
                            options={optionsUOM}
                            components={customIndicator}
                            value={uom}
                            onChange={
                              handleChangeUOM
                            }

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.uom?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount On {DiscountWise === "" || specialRunning === "special" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("kg_or_carton", { required: { value: (DiscountWise === "" || specialRunning === "special" ? false : true), message: "Field is required" } })}
                            className="form-select"
                            value={discountOn}
                            onChange={handleDiscountOnChange}
                            disabled={DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"KG"}>KG</option>
                            <option value={"Carton"}>Carton</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.kg_or_carton?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount Type {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("percentage_or_amount", { required: { value: (DiscountWise != "Product Wise" || specialRunning === "special" ? false : true), message: "Field is required" } })}
                            className="form-select"
                            onChange={handleDiscountTypeChange}
                            value={DiscountType}
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"Amount"}>Amount</option>
                            <option value={"Percentage"}>Percentage</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.percentage_or_amount?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount With {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("with_or_without_gst", { required: { value: DiscountWise != "Product Wise" || specialRunning === "special" ? false : true, message: "Field is required" } })}
                            onChange={handleDiscountWithChange}
                            value={discountWith}
                            className="form-select"
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"Whit GST"}>With GST</option>
                            <option value={"Whitout GST"}>Without GST</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.with_or_without_gst?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <input
                            {...registerProduct("discount", { required: { value: DiscountWise != "Product Wise" || specialRunning === "special" ? false : true, message: "Field is required" } })}
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                            className="form-control"
                            type="number"
                            placeholder="Discount"
                            // value={field.discount}
                            onChange={handleDiscountChange}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.discount?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Final Amount</label>
                        <div className="">
                          <input
                            {...registerProduct("final_amount", { required: { value: false, message: "Field is required" } })}
                            disabled
                            className="form-control"
                            type="number"
                            placeholder="Final Amount"
                          // value={finalAmount}
                          // onChange={handleDiscountChange}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.final_amount?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Discription<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="text"
                            placeholder="Product Description"
                          // value={field.product_description}
                          // onChange={handleDescriptionChange}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_description?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Total Qty<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="number"
                            placeholder="Product Total Qty."
                          // value={field.product_qty}
                          // onChange={handleChangeProductQty}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_qty?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Requirement Date<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-group">
                          <DatePicker
                            className="form-control"
                            {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                            allowClear={false}
                            format={dateFormatList}
                            value={requirementDate}
                            onChange={handleRequirementDate}
                            disabledDate={disabledDate}

                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.requirement_date?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Expected Basic Value<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="number"
                            placeholder="Expected Basic Value"
                          // value={field.expected_basic_value}
                          // onChange={handleChangeBasicValue}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.expected_basic_value?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Possible<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <select
                            {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                            // value={field.possible_yes_no}
                            // onChange={handleYesNoChange}
                            className="form-select"
                          >
                            <option value={''}>Select Any</option>
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.possible_yes_no?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Re-Mark<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                            className="form-control"
                            type="text"
                            placeholder="Re-Mark"
                          // value={field.expected_basic_value}
                          // onChange={handleChangeBasicValue}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.remark?.message}</span>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                // setProductModel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="info"
              className="text-white"
            // onClick={handleSubmitProduct(handleAddProduct)}
            // onClick={handleCheck}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Edit Modal HTML */}
        <Modal
          show={deleteConfirm}
          onHide={() => setDeleteConfirm(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete {""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              {" "}
              <span className="text-center">Sure you want to delete it?</span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="info"
              className="text-white"
              onClick={handleMultipleDeleted}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Deleteforce
          key={renderKey}
          handleDeletedforce={handleDeletedforce}
          reconfirmdelete={confirmforcemodal}
          masterName={""}
          closereconfirmdelete={closereconfirmdelete}
          referance={
            deletesucess
              ? deletedata
              : ""
          }
          apiname={apiName}
        />
        <Deletesingle
          key={renderKey}
          confirmmodal={deleteConfirmsingle}
          confirmmodalclose={confirmmodalclose}
          masterName={""}
          confirmmodalsubmit={confirmmodalsubmit}
          apiname={apiName}
        />

        <SimpleInput_Edit key={renderKey}
          refetchForm={handleRefetch}
          open={OpenEditDropDownModel}
          setOpen={setOpenEditDropDownModel}
          id={editId}
          // superAccountId={superAccountId} 
          // optionsSuperAccount={optionsSuperAccount}
          // isSuperAccount={isSuperAccount}
          apiname={apiName} />




      </div>
    </>
  )
}

export default Add_Sales_Inquiry