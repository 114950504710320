import DatePicker from 'antd/es/date-picker';
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import Select from "react-select"
import { useFetchRawMaterialGardeDropDown, useFetchEmployeeDropDown, useFetchMachineDropDown, useFetchSheetCodeNameDropDown, useFetchMouldDropDown, useFetchCavityDropDown, useFetchBatchNoDropDown, useFetchDisposableDropDown } from '../../../hooks/DropDown_Api';
import { RxCross2 } from "react-icons/rx";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import { QueryClient, InvalidateQueryFilters } from 'react-query';
import { Tooltip } from "@mui/material";

import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import { useCreate } from '../../../hooks/Api';
import { useQueryClient } from 'react-query';
import Axiosinstance from '../../../hooks/Interceptor';
function getSteps() {
    return ['Step 1', 'Step 2'];
}


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}




const TFM_Transaction = ({ onOpenTFM, setOnOpenTFM, setNewAddedTFM }: any) => {
    const queryClient = useQueryClient();
    const [openModel, setOpenModel] = useState(true)
    const navigate = useNavigate()

    // const [optionsProductCode, setOptionsProductCode]: any = useState([])
    const [forProductCodeValue, setForProductCodeValue]: any = useState()
    const [ProductQty, setProductQty]: any = useState('')
    const [SubmitModel, setSubmitModel] = useState(false)


    const apiname = process.env.REACT_APP_PRODUCTION_TFM_TRANSACTION_API?.toString()


    const { mutate: TFM, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)



    const { register, handleSubmit, setError, reset, setValue, getValues, control, trigger, formState: { errors } }: any = useForm()

    const apinameTfmBatch = process.env.REACT_APP_PRODUCTION_TFM_BATCH_DETAIL_API?.toString()

    const { register: registerMaterial, handleSubmit: handleSubmitMaterial, setError: setErrorMaterial, reset: resetMaterial, setValue: setValueMaterial, formState: formStateProduct }: any = useForm();

    const { errors: errorsMaterial } = formStateProduct;


    const { mutate: TFMBatch, data: dataGrade, isError: isErrorGrade, isLoading: isLoadingGrade, isSuccess: isSuccessGrade, error: errorGrade }: any = useCreate(apinameTfmBatch)


    const apinameTfmBatchFetch = `${process.env.REACT_APP_PRODUCTION_TFM_BATCH_DETAIL_API}/fetch_multiple_ids`?.toString()
    const { mutate: TFMBatchFetch, data: dataGradeFetch, isError: isErrorGradeFetch, isLoading: isLoadingGradeFetch, isSuccess: isSuccessGradeFetch, error: errorGradeFetch }: any = useCreate(apinameTfmBatchFetch)

    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [HelperOptions, setHelperOptions]: any = useState([])

    const [forOperatorValue, setForOperator]: any = useState("")
    const [forHelperValue, setForHelper]: any = useState([])
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()



    useEffect(() => {
        setEmployeeOptions(EmployeeData)
        setHelperOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeOperator = (e: any) => {

        setForOperator(e)
        setValue("opretar", e?.value)
    }
    const handleChangeHelper = (e: any) => {
        setForHelper(e)
        const helper = e?.map((e: any) => {
            return e.value
        })
        setValue("helper", helper)
    }



    const [MachineOptions, setMachineOptions]: any = useState([])
    const [MachineValue, setMachineValue]: any = useState('')

    const { data: MachineData, refetch: MachineRefetch } = useFetchMachineDropDown()

    useEffect(() => {
        setMachineOptions(MachineData)
    }, [MachineData])

    const handleChangeMachine = (e: any) => {

        setMachineValue(e)
        setValue("machine", e?.value)
    }


    const [MouldOptions, setMouldOptions]: any = useState([])
    const [MouldValue, setMouldValue]: any = useState('')

    const { data: MouldData, refetch: MouldRefetch } = useFetchMouldDropDown()

    useEffect(() => {
        setMouldOptions(MouldData)
    }, [MouldData])

    const handleChangeMould = (e: any) => {

        setMouldValue(e)
        setValue("mould_code_and_diameter", e?.value)
    }


    const [CavityOptions, setCavityOptions]: any = useState([])
    const [CavityValue, setCavityValue]: any = useState('')

    const { data: CavityData, refetch: CavityRefetch } = useFetchCavityDropDown()

    useEffect(() => {
        setCavityOptions(CavityData)
    }, [CavityData])

    const handleChangeCavity = (e: any) => {

        setCavityValue(e)
        setValue("cavity", e?.value)
    }


    const [optionsBatchNo, setOptionsBatchNo]: any = useState([])
    const [BatchNo, setBatchNoValue]: any = useState('')

    const { data: BatchNoData, refetch: BatchNoRefetch } = useFetchBatchNoDropDown()

    useEffect(() => {

        setOptionsBatchNo(BatchNoData)
    }, [BatchNoData])

    const handleChangeBatchNo = (e: any) => {

        setBatchNoValue(e)
        setValueMaterial("roll_batch_no", e?.value)
    }


    const [optionsDisposableProduct, setOptionsDisposableProduct]: any = useState([])
    const [forDisposableProductValue, setForDisposableProductValue]: any = useState('')
    const [spacialRunning, setSpacialRunning]: any = useState('')

    const { data: ProductCodeData, refetch: ProductCodeRefetch } = useFetchDisposableDropDown()

    useEffect(() => {
        let temp = ProductCodeData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.code} ${e?.name} ${e?.type} ${e?.color}`,
                    special_running: e?.special_running
                }
            )
        })
        setOptionsDisposableProduct(temp)
    }, [ProductCodeData])

    const handleChangeDisposableProduct = (e: any) => {
        setValueMaterial("product", e.value)
        setForDisposableProductValue(e)
        setSpacialRunning(e.special_running)

    }



    const [SheetCodeOptions, setSheetCodeOptions]: any = useState([])
    const [SheetCodeValue, setSheetCodeValue]: any = useState('')
    const [Grade, SetGrade] = useState('')
    const [Color, SetColor] = useState('')
    const [AllProduct, SetAllProduct] = useState('')

    const { data: SheetCodeData, refetch: SheetCodeRefetch } = useFetchSheetCodeNameDropDown()

    useEffect(() => {
        setSheetCodeOptions(SheetCodeData)
    }, [SheetCodeData])

    const handleChangeSheetCode = (e: any) => {
        SetGrade(e.grade)
        SetColor(e.color)
        SetAllProduct(e.allproduct)
        setSheetCodeValue(e)
        setValue("sheet", e?.value)
    }


    const handleChangeProductCode = (selectedItem: any) => {
        setForProductCodeValue(selectedItem);
        setValue('sheet_raw_material_temp', selectedItem?.value);

        let tempAdd = {
            id: selectedItem.value,
            label: selectedItem.label,
            grade: selectedItem.grade,
            gradeType: selectedItem.gradeType,
            materialName: selectedItem.materialName,
            kg: 0,
            type: 'number'
        };

        setAddToForm((oldFields: any) => [...oldFields, tempAdd]);
    }






    const [addToForm, setAddToForm]: any = useState([])


    const [showData, setShowData]: any = useState([])

    const handleAdd = (e: any) => {
        setShowData((oldData: any) => [...oldData, ...addToForm]);
        setAddToForm([])

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listProductionline', { state: { showToast: true } })
            setOpenModel(false)
            setOnOpenTFM(false)
            setNewAddedTFM(true)
            queryClient.invalidateQueries(['Fetch']);
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

   


    const onSubmit = async (granual: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(granual)) {
            let formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
          
            else if (Array.isArray(value) && key === "helper") {
                value.forEach((val, index) => formData.append(`helper`, val))
            }
           
            
        }
        if (Array.isArray(productIds)) {
            productIds.forEach((val: any, index: any) => formData.append(`tfm_batch_detail`, val))
          }
        TFM(formData)
        
    };

    
    const handleAddProduct = async (data: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
        }
        TFMBatch(formData)
        setAdded(true)

        
    };

   
    useEffect(() => {
        if (isSuccessGrade) {
            
            setSubmitModel(false)
            setValueMaterial("roll_batch_no", "")
            setValueMaterial("product", "")
            setValueMaterial("roll_width", "")
            setValueMaterial("thickness", "")
            setValueMaterial("roll_meter", "")
            setValueMaterial("roll_weight", "")
            setValueMaterial("product_1pcs_weight", "")
            setValueMaterial("sheet_roll", "")
            setBatchNoValue(null)
            setForDisposableProductValue(null)
            setSpacialRunning("")
            
            queryClient.invalidateQueries(['Fetch']);
        }
        else if (isErrorGrade) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccessGrade, dataGrade, errorGrade])

    const [added, setAdded] = useState(false)
    const [displayData, setDisplayData]: any = useState([])
    const [productIds, setProductIds]: any = useState([])
    const [Batchnoget, setBatchnoget]: any = useState('')

    useEffect(() => {
        if (dataGradeFetch) {
            setDisplayData(dataGradeFetch?.data)
            setBatchnoget(dataGradeFetch?.data?.map((e: any) => e?.roll_batch_no))
        }
    }, [dataGradeFetch])

    useEffect(() => {
        if (productIds) {
            TFMBatchFetch({ ids: productIds })
        }
    }, [productIds])





    useEffect(() => {
        if (added && isSuccessGrade) {
            if (dataGrade) {
                setProductIds((oldIds: any) => [...oldIds, dataGrade?.data?.id]);



                setAdded(false)
            }


        }

    }, [dataGrade, isSuccessGrade, added])

    const customStyles = {
        control: (styles: any) => ({
            ...styles,

        }),
        option: (styles: any) => ({
            ...styles,

        }),
        menu: (styles: any) => ({
            ...styles,
            width: '250px'
        })

    };


    const handelModel = () => {
        setSubmitModel(true)
    }



    const getStepContent = (stepIndex: any) => {
        switch (stepIndex) {
            case 0:

                return (
                    <>
                        {activeStep !== 1 &&
                            <form name='step -1' key={'0'} onSubmit={handleSubmit(onSubmit)}>


                                <div className="card-body" style={{ maxHeight: "100vh" }}>
                                    <div className="row d-flex justify-content-md- center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm" style={{ paddingRight: '0px' }}>


                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Operator Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                {...register("opretar", { required: { value: true, message: "Operator Code & Name is required" } })}
                                                                placeholder="Operator Code & Name"
                                                                options={employeeOptions}
                                                                onChange={handleChangeOperator}
                                                                value={forOperatorValue}
                                                                components={customIndicator}
                                                            

                                                            />

                                                         

                                                        </div>
                                                        <span className='text-red w-100'>{errors?.opretar?.message}</span>

                                                    </div>

                                                    {/* <Tooltip title={<h5 style={{ fontSize: '15px', color: 'white' }}>{forHelperValue[0]?.label}</h5>} placement="top" arrow > */}
                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Helper Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                {...register("helper", { required: { value: true, message: "Helper Code & Name is required" } })}
                                                                placeholder="Helper Code & Name"
                                                                options={HelperOptions}
                                                                onChange={handleChangeHelper}
                                                                value={forHelperValue}
                                                                
                                                                isMulti
                                                                components={customIndicator}
                                                            

                                                            />

                                                            <span className='text-red w-100'>{errors?.helper?.message}</span>
                                                        </div>

                                                    </div>
                                                    

                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Machine Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                placeholder={'Machine Code & Name'}
                                                                {...register("machine", { required: { value: true, message: "Machine Code & Name is required" } })}
                                                                value={MachineValue}
                                                                options={MachineOptions}
                                                                components={customIndicator}
                                                                onChange={handleChangeMachine}
                                                           
                                                            />
                                                           

                                                        </div>
                                                        <span className='text-red w-100'>{errors?.machine?.message}</span>

                                                    </div>



                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Sheet Code, Grade, Name & Color <span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                {...register("sheet", { required: { value: true, message: "Sheet Code, Grade, Name & Color is required" } })}
                                                                options={SheetCodeOptions}
                                                                components={customIndicator}

                                                                value={SheetCodeValue}
                                                                onChange={handleChangeSheetCode}
                                                           
                                                            />
                                                          
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.sheet?.message}</span>

                                                    </div>


                                                </div>

                                                <div className="col-sm" style={{ paddingRight: '0px' }}>


                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Mould Code & Diameter </label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                {...register("mould_code_and_diameter", { required: { value: true, message: "Mould Code & Diameter is required" } })}
                                                                options={MouldOptions}
                                                                components={customIndicator}
                                                                value={MouldValue}
                                                                onChange={handleChangeMould}
                                                           
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">Cavity Code & Name </label>
                                                        </div>
                                                        <div className="col-6 ">
                                                            <Select
                                                                {...register("cavity", { required: { value: true, message: "Mould Code & Diameter is required" } })}
                                                                options={CavityOptions}
                                                                components={customIndicator}

                                                                value={CavityValue}
                                                                onChange={handleChangeCavity}
                                                            
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label >Shift <span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div className="col-6 d-flex">
                                                            <select className="form-select"
                                                                {...register("shift_time", { required: { value: true, message: "Shift is required" } })}
                                                            >
                                                                <option value={""} selected>Select Any</option>
                                                                <option value={"Day"}>Day</option>
                                                                <option value={"Night"}>Night</option>
                                                            </select>
                                                           
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.shift_time?.message}</span>
                                                    </div>

                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">All Single Code & Name & Type </label>
                                                        </div>
                                                        <div className="col-6 d-flex">
                                                            <input type="text" className="form-control" disabled

                                                                placeholder="All Single Code & Name & Type"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                        <div className="col-sm">
                                                            <label htmlFor="exampleInputEmail1">All Product Code & Name & Type </label>
                                                        </div>
                                                        <div className="col-6 d-flex">
                                                            <input type="text" className="form-control" disabled value={AllProduct}

                                                                placeholder="All Product Code & Name & Type"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                    </>

                )

            case 1:
                return (

                    <form name='step -2' key={'1'} >
                        <div className="card-body" style={{ maxHeight: "100vh" }}>
                            <div className="row d-flex justify-content-md- center justify-content-lg-start">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm" style={{ paddingRight: '0px' }}>


                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Operator Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                    <input type="text" className="form-control" disabled
                                                        value={forOperatorValue?.label}
                                                        placeholder="Operator Code & Name"
                                                    />

                                                </div>
                                                <span className='text-red w-100'>{errors?.opretar?.message}</span>

                                            </div>
                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Helper Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                    <Select
                                                        {...register("helper", { required: { value: true, message: "Helper Code & Name is required" } })}
                                                        placeholder="Helper Code & Name"
                                                        options={HelperOptions}
                                                        onChange={handleChangeHelper}
                                                        value={forHelperValue}
                                                        isMulti
                                                        isDisabled

                                                    />
                                                    <span className='text-red w-100'>{errors?.helper?.message}</span>
                                                </div>

                                            </div>
                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Machine Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                   
                                                    <input type="text" className="form-control" disabled
                                                        value={MachineValue?.label}
                                                        placeholder={'Machine Code & Name'}
                                                    />

                                                </div>
                                                <span className='text-red w-100'>{errors?.machine?.message}</span>

                                            </div>



                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Sheet Code, Grade, Name & Color </label>
                                                </div>
                                                <div className="col-6 ">
                                                    
                                                    <input type="text" className="form-control" disabled
                                                        value={SheetCodeValue?.label}
                                                        placeholder="Sheet Code, Grade, Name"
                                                    />
                                                </div>
                                                <span className='text-red w-100'>{errors?.sheet?.message}</span>

                                            </div>


                                        </div>

                                        <div className="col-sm" style={{ paddingRight: '0px' }}>


                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Mould Code & Diameter </label>
                                                </div>
                                                <div className="col-6 ">
                                                    
                                                    <input type="text" className="form-control" disabled
                                                        value={MouldValue?.label}
                                                        placeholder="Sheet Code, Grade, Name"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">Cavity Code & Name </label>
                                                </div>
                                                <div className="col-6 ">
                                                   
                                                    <input type="text" className="form-control" disabled
                                                        value={CavityValue?.label}
                                                        placeholder="Sheet Code, Grade, Name"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label >Shift </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                  
                                                    <input type="text" className="form-control" disabled
                                                        {...register("shift_time", { required: { value: true, message: "Shift is required" } })}
                                                        placeholder="Material Grade Name"
                                                    />
                                                </div>
                                                <span className='text-red w-100'>{errors?.shift_time?.message}</span>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">All Single Code & Name & Type </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled

                                                        placeholder="All Single Code & Name & Type"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                                <div className="col-sm">
                                                    <label htmlFor="exampleInputEmail1">All Product Code & Name & Type </label>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <input type="text" className="form-control" disabled value={AllProduct}

                                                        placeholder="All Product Code & Name & Type"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                       
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Raw Material Batch Total Kg. <span style={{ color: 'red' }}>*</span></label>
                                                <div className="d-flex row" >
                                                </div>
                                                <input type="text" className="form-control" disabled
                                                    label="Step 2 Field"
                                                    // value={TotalKg}

                                                    placeholder="Raw Material Batch Total Kg."
                                                    {...register("raw_material_betch_total_kg", { required: { value: false, message: "Raw Material Batch Total Kg. is required" } })}
                                                />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                        <div className="mt-2 ">
                                            <table className="table table-bordered" style={{ marginTop: "10px", textAlign: 'center' }}>
                                                <thead>
                                                    <tr>
                                                       
                                                        <th style={{ width: "12%" }}>

                                                           
                                                            <label htmlFor="exampleInputEmail1">Batch<br />No. <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <Select
                                                                    {...registerMaterial("roll_batch_no", { required: { value: true, message: "Field is required" } })}
                                                                    
                                                                    value={BatchNo}
                                                                    components={customIndicator}
                                                                    options={optionsBatchNo}
                                                                    onChange={handleChangeBatchNo}
                                                                    maxMenuHeight={150}
                                                                    styles={customStyles}
                                                            

                                                                />

                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.roll_batch_no?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th style={{ width: "12%" }}>
                                                          

                                                            <label htmlFor="exampleInputEmail1">Product Code <br />& Name & Type <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <Select
                                                                    {...registerMaterial("product", { required: { value: true, message: "Field is required" } })}
                                                                    
                                                                    value={forDisposableProductValue}
                                                                 
                                                                    components={customIndicator}
                                                                    options={optionsDisposableProduct}
                                                                    onChange={handleChangeDisposableProduct}
                                                                    maxMenuHeight={150}
                                                                    styles={customStyles}
                                                                />

                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.product?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                           
                                                            <label htmlFor="exampleInputEmail1">Roll<br />Width<span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <input
                                                                    {...registerMaterial("roll_width", { required: { value: true, message: "Field is required" } })}
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Roll Width"
                                                             
                                                                />
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.roll_width?.message}</span>
                                                            </div>

                                                        </th>
                                                        <th>
                                                           
                                                            <label htmlFor="exampleInputEmail1">Thickness<br />  <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <input
                                                                    {...registerMaterial("thickness", { required: { value: true, message: "Field is required" } })}
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Thickness"
                                                              
                                                                />
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.thickness?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            
                                                            <label htmlFor="exampleInputEmail1">Roll<br />Meter<span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <input
                                                                    {...registerMaterial("roll_meter", { required: { value: true, message: "Field is required" } })}
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Roll Meter"
                                                               
                                                                />
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.roll_meter?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                           
                                                            <label htmlFor="exampleInputEmail1">Roll<br />Weight(Kg.)<span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <input
                                                                    {...registerMaterial("roll_weight", { required: { value: true, message: "Field is required" } })}
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Roll Weight(Kg.)"
                                                               
                                                                />
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.roll_weight?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            
                                                            <label htmlFor="exampleInputEmail1">Product 1<br />Pcs Weight<span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <input
                                                                    {...registerMaterial("product_1pcs_weight", { required: { value: true, message: "Field is required" } })}
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Product 1 Pcs Weight"
                                                               
                                                                />
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.product_1pcs_weight?.message}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                           
                                                            <label htmlFor="exampleInputEmail1">Sheet Roll<br />Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="">
                                                                <select
                                                                    {...registerMaterial("sheet_roll", { required: { value: true, message: "Field is required" } })}
                                                                    
                                                                    className="form-select"
                                                                    style={{ width: '80px' }}
                                                                >
                                                                    <option value={''}>Select Any</option>
                                                                    <option value={'yes'}>Yes</option>
                                                                    <option value={'no'}>No</option>
                                                                </select>
                                                                <span className='text-red w-100' style={{ fontSize: '12px' }}>{errorsMaterial?.sheet_roll?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            
                                                            <label htmlFor="exampleInputEmail1">Spacial/<br />Running </label>
                                                            <div className="">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Spacial/Running"
                                                                    disabled
                                                                    value={spacialRunning}
                                                                />
                                                                <span className='text-red w-100'>{errorsMaterial?.product_description?.message}</span>
                                                            </div>
                                                        </th>
                                                        <th>

                                                            <label htmlFor="exampleInputEmail1">Time <br /> </label>
                                                          
                                                        </th>
                                                        <th>

                                                            <label htmlFor="exampleInputEmail1" >Actions </label>

                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={handleSubmitMaterial(handelModel)}
                                                                style={{ marginTop: '10px' }}
                                                            >
                                                                Save
                                                            </button>

                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {displayData?.map((field: any, index: any) => {
                                                        return (

                                                            <tr>
                                                                <td>


                                                                    {field.extra_response?.roll_batch_no?.batch_code}
                                                                    

                                                                </td>
                                                                <td>{`${field.extra_response?.product?.product_code} ${field.extra_response?.product?.material_type_name} ${field.extra_response?.product?.product_type} ${field.extra_response?.product?.company_color_name[0]}`}</td>
                                                                <td>{field.roll_width}</td>
                                                                <td>{field.thickness}</td>
                                                                <td>{field.roll_meter}</td>
                                                                <td>{field.roll_weight}</td>
                                                                <td>{field.product_1pcs_weight}</td>
                                                                <td>{field.sheet_roll}</td>
                                                                <td>{field.extra_response?.product?.product_special_running}</td>
                                                                <td>{field.created_at?.split(' ')[1]}</td>
                                                                <td>-</td>
                                                                {/* <td>
                                                        <div style={{ display: 'block' }}>
                                                            <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => handleEditProduct(field)}
                                                            >
                                                            <FiEdit />
                                                            </button>
                                                            <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => handleDeleteProduct(field.id)}
                                                            >
                                                            <RiDeleteBinLine />
                                                            </button>
                                                        </div>
                                                        </td> */}
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );

            default:
                return 'Unknown step';
        }
    }





    const [activeStep, setActiveStep]: any = useState(0);
    const steps = getSteps();

    const handleNext = async () => {
        const isFormValid = await trigger();

        if (isFormValid) {


            setActiveStep((prevActiveStep: any) => {
                if (prevActiveStep === 1) {
                    return prevActiveStep;
                }
                return prevActiveStep + 1;
            });

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };




    return (
        <div>
            <Modal
                size="lg"
                show={SubmitModel}
                onHide={() => setSubmitModel(false)
                }
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure You Want To Submit This Data!
                </Modal.Body>
                <Modal.Footer>
                    <Button className='border-0'
                        onClick={handleSubmitMaterial(handleAddProduct)}
                    >Submitt</Button>

                </Modal.Footer>
            </Modal>
            <Modal
                size="xl"
                show={openModel}
                onHide={() => {
                    setOpenModel(false)
                    setOnOpenTFM(false)
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title style={{ fontSize: '16px' }}>TFM Transaction</Modal.Title>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '70%', height: '13%' }}  >

                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel></StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Modal.Header>
                <Modal.Body>


                    <div>
                        {activeStep === steps.length ? (
                            <div>

                            </div>
                        ) :
                            (
                                <div>
                                    <Typography>{getStepContent(activeStep)}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Edit
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                       

                                        {activeStep === steps.length - 1 ? <Button className='border-0' onClick={handleSubmit(onSubmit)}>Submitt</Button> : <Button onClick={handleNext}>Next</Button>}
                                    </Box>
                                </div>
                            )}
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TFM_Transaction