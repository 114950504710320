import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { excel } from "react-export-table-to-excel/lib/lib";
import { RiPencilLine } from "react-icons/ri";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';

// import IndicatorsContainer from '../../components/Select Dropdown/Select'
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchCompanyNameDropDown, useFetchMaterialBrandDropDown, useFetchMaterialDensityDropDown, useFetchMaterialNameDropDown, useFetchMaterialSectorDropDown, useFetchMaterialSourceDropDown, useFetchMaterialUseDropDown, useFetchHSNMaterialNameDropDown, useFetchUseCategoryNameDropDown } from "../../hooks/DropDown_Api";
import Button from "react-bootstrap/esm/Button";
import { Material_Density_Model } from "../../components/Dynamic_Form_Models/Material_Density_Model";
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import { AiOutlinePlus } from "react-icons/ai";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Edit_RawMaterialGrade = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const { id } = useParams()
    const apiname = process.env.REACT_APP_RAWMATERIALGRADE_API?.toString()
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: panniUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    const [open, setOpen] = useState(false)
    const [colorOpen, setColorOpen] = useState(false)
    const [brandOpen, setBrandOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [submittedColor, setSubmittedColor] = useState(false)
    const [submittedBrand, setSubmittedBrand] = useState(false)


    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [TapeRollType, setTapeRollType]: any = useState({})
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const [options, setOptions]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchPanniTerm, setSearchPanniTerm] = useState('')
    const [ColorTotal, setColorTotal] = useState([])
    const [selected, setSelected] = useState([])

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [colorNamePanni, setColorNamePanni]: any = useState({})


    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [searchHSN, setSearchHSN] = useState('')
    const [loadingHSN, setLoadingHSN] = useState(false)
    const [gstNumber, setGSTNumber]: any = useState({})
    const [GST, setGst] = useState()

    const [selectedValue, setSelectedValue] = useState([]);
    const [optionsPanniName, setOptionsPanniName]: any = useState([])
    const [loadingPanniName, setLoadingPanniName] = useState(false)
    const [panniName, setPanniName]: any = useState({})

    const [selectedOptions, setselectedOptions] = useState()
    const [showColor, setShowColor]: any = useState("");



    const [selectedBrand, setSelectedBrand]: any = useState("");
    const [sel, setSel] = useState("");

    const [showPanni, setShowPanni] = useState("")
    const [selectedPanni, setSelectedPanni]: any = useState("");


    const [openHSN, setOpenHSN] = useState(false)

    const [selectedHSN, setSelectedHSN] = useState('');
    const [selHSN, setSelHSN] = useState("");



    const [selPanni, setSelPanni] = useState("");


    const [selectedColor, setSelectedColor]: any = useState([]);
    const [selColor, setSelColor]: any = useState([]);

    const [polymer, setPolymer] = useState("")


    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }
    const onGadgetChange = (e: any) => {
        setpanniGadget(e.target.value)
    }
    const onPanniChange = (e: any) => {
        setpanniKan(e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const [isLoadingValue, setIsLoadingValue] = useState(false);


    // const [optionsMaterial, setOptionsMaterial]: any = useState([]);

    // const [valueMaterial, setValueMaterial]: any = useState();

    // const apinameForMaterial = process.env.REACT_APP_MATERIAL_NAME_API?.toString()

    // const { mutate: mutateMaterial, data: dataMaterial, isError: MaterialIsError, isLoading: MaterialLoading, isSuccess: MaterialSuccess, error: MaterialError }: any = useCreate(apinameForMaterial)
    // const [selectedMaterial, setSelectedMaterial]: any = useState()
    // const [valueMaterialCreatable, setValueMaterialCreatable] = useState<Option | null>();
    // const { data: MaterialData, refetch: MaterialRefetch } = useFetchMaterialNameDropDown()
    // // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    // useEffect(() => {
    //     setOptionsMaterial(MaterialData)
    // }, [MaterialData])


    // useEffect(() => {
    //     if (searchTerm?.length >= 1) {
    //         MaterialRefetch()
    //     }
    // }, [])


    // const handleChangeMaterial = (e: any) => {
    //     setValueMaterial(e)
    //     setValue("name", `${e?.value}`)
    //     setValueMaterialCreatable(null)
    // }





    // const handleCreate = (inputValue: string) => {

    //     inputValue = inputValue.toLowerCase()
    //         .split(' ')
    //         .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
    //         .join(' ');
    //     setIsLoadingValue(true);
    //     setTimeout(() => {
    //         const newOption = createOption(inputValue);
    //         setIsLoadingValue(false);


    //         mutateMaterial({ name: newOption.name, status: newOption.status })
    //         setOptionsMaterial((prev: any) => [...prev, newOption]);
    //         setValueMaterialCreatable(newOption);
    //     }, 0);


    // };

    // useEffect(() => {
    //     (async () => {
    //         if (MaterialSuccess && optionsMaterial) {
    //             await MaterialRefetch();
    //         }
    //         setValue("name", MaterialData[0].value)
    //     })();

    //     return () => {
    //     };
    // }, [MaterialSuccess, MaterialData]);


    const [valueUseCategory, setValueUseCategory]: any = useState();

    const apinameForUseCategory = process.env.REACT_APP_USE_CATEGORY_NAME_API?.toString()
    const [optionsUseCategory, setOptionsUseCategory]: any = useState([]);
    const [selectedUseCategory, setSelectedUseCategory] = useState('')
    const { mutate: mutateUseCategory, data: dataUseCategory, isError: UseCategoryIsError, isLoading: UseCategoryLoading, isSuccess: UseCategorySuccess, error: UseCategoryError }: any = useCreate(apinameForUseCategory)

    const [valueUseCategoryCreatable, setValueUseCategoryCreatable] = useState<Option | null>();

    const { data: UseCategoryData, refetch: UseCategoryRefetch } = useFetchUseCategoryNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsUseCategory(UseCategoryData)
    }, [UseCategoryData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            UseCategoryRefetch()
        }
    }, [])


    const handleChangeUseCategory = (e: any) => {
        MaterialSourceRefetch()

        setApiName(apinameForUseCategory)
        setDeleteApiName(apinameForUseCategory)
        setEditId(e?.value)
        setEditData(UseCategoryData)
        setRenderKey(prevKey => prevKey + 1);

        setValueUseCategory(e)

       
        setValue("use_category", `${e?.value}`)
        setValueUseCategoryCreatable(null)
    }





    const handleCreateUseCategory = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateUseCategory({ name: newOption.name, status: newOption.status })
            setOptionsUseCategory((prev: any) => [...prev, newOption]);
            setValueUseCategoryCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (UseCategorySuccess && optionsUseCategory) {
                await UseCategoryRefetch();
            }
            setValue("use_category", UseCategoryData[0].value)
        })();

        return () => {
        };
    }, [UseCategorySuccess, UseCategoryData]);



    const apinameForMaterialSource = process.env.REACT_APP_MATERIAL_SOURCE_API?.toString()
    const { mutate: mutateMaterialSource, data: dataMaterialSource, isError: MaterialSourceIsError, isLoading: MaterialSourceLoading, isSuccess: MaterialSourceSuccess, error: MaterialSourceError }: any = useCreate(apinameForMaterialSource)

    const [selectedMaterialSource, setSelectedMaterialSource]: any = useState()
    const [valueMaterialSource, setValueMaterialSource]: any = useState();
    const [optionsMaterialSource, setOptionsMaterialSource]: any = useState([]);
    const [valueMaterialSourceCreatable, setValueMaterialSourceCreatable] = useState<Option | null>();
    const { data: MaterialSourceData, refetch: MaterialSourceRefetch } = useFetchMaterialSourceDropDown()

    useEffect(() => {
        setOptionsMaterialSource(MaterialSourceData)
    }, [MaterialSourceData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialSourceRefetch()
        }
    }, [])


    const handleChangeMaterialSource = (e: any) => {

        MaterialSourceRefetch()

        setApiName(apinameForMaterialSource)
        setDeleteApiName(apinameForMaterialSource)
        setEditId(e?.value)
        setEditData(MaterialSourceData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialSource(e)
        
        setValue("source", `${e?.value}`)
        setValueMaterialSourceCreatable(null)
    }





    const handleCreateMaterialSource = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialSource({ name: newOption.name, status: newOption.status })
            setOptionsMaterialSource((prev: any) => [...prev, newOption]);
            setValueMaterialSourceCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialSourceSuccess && optionsMaterialSource) {
                await MaterialSourceRefetch();
            }
            setValue("source", MaterialSourceData[0].value)
        })();

        return () => {
        };
    }, [MaterialSourceSuccess, MaterialSourceData]);


    const apinameForMaterialUse = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateMaterialUse, data: dataMaterialUse, isError: MaterialUseIsError, isLoading: MaterialUseLoading, isSuccess: MaterialUseSuccess, error: MaterialUseError }: any = useCreate(apinameForMaterialUse)

    const [selectedMaterialUse, setSelectedMaterialUse]: any = useState()
    const [valueMaterialUse, setValueMaterialUse]: any = useState();
    const [optionsMaterialUse, setOptionsMaterialUse]: any = useState([]);
    const [valueMaterialUseCreatable, setValueMaterialUseCreatable] = useState<Option | null>();

    const { data: MaterialUseData, refetch: MaterialUseRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialUse(MaterialUseData)
    }, [MaterialUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeMaterialUse = (e: any) => {
        MaterialSourceRefetch()

        setApiName(apinameForMaterialUse)
        setDeleteApiName(apinameForMaterialUse)
        setEditId(e?.value)
        setEditData(MaterialUseData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialUse(e)

      
        setValue("material_use", `${e?.value}`)
        setValueMaterialUseCreatable(null)
    }





    const handleCreateMaterialUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialUse({ name: newOption.name, status: newOption.status })
            setOptionsMaterialUse((prev: any) => [...prev, newOption]);
            setValueMaterialUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialUseSuccess && optionsMaterialUse) {
                await MaterialUseRefetch();
            }
            setValue("material_use", MaterialUseData[0].value)
        })();

        return () => {
        };
    }, [MaterialUseSuccess, MaterialUseData]);



    const apinameForMaterialSector = process.env.REACT_APP_MATERIAL_SECTOR_API?.toString()
    const { mutate: mutateMaterialSector, data: dataMaterialSector, isError: MaterialSectorIsError, isLoading: MaterialSectorLoading, isSuccess: MaterialSectorSuccess, error: MaterialSectorError }: any = useCreate(apinameForMaterialSector)
    const [selectedMaterialSector, setSelectedMaterialSector]: any = useState()
    const [valueMaterialSector, setValueMaterialSector]: any = useState();
    const [optionsMaterialSector, setOptionsMaterialSector]: any = useState([]);
    const [valueMaterialSectorCreatable, setValueMaterialSectorCreatable] = useState<Option | null>();

    const { data: MaterialSectorData, refetch: MaterialSectorRefetch } = useFetchMaterialSectorDropDown()

    useEffect(() => {
        setOptionsMaterialSector(MaterialSectorData)
    }, [MaterialSectorData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeMaterialSector = (e: any) => {
        MaterialSectorRefetch()

        setApiName(apinameForMaterialSector)
        setDeleteApiName(apinameForMaterialSector)
        setEditId(e?.value)
        setEditData(MaterialSectorData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialSector(e)

       
        setValue("sector", `${e?.value}`)
        setValueMaterialSectorCreatable(null)
    }





    const handleCreateMaterialSector = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialSector({ name: newOption.name, status: newOption.status })
            setOptionsMaterialSector((prev: any) => [...prev, newOption]);
            setValueMaterialSectorCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialSectorSuccess && optionsMaterialSector) {
                await MaterialSectorRefetch();
            }
            setValue("sector", MaterialSectorData[0].value)
        })();

        return () => {
        };
    }, [MaterialSectorSuccess, MaterialSectorData]);



    const apinameForCompanyName = process.env.REACT_APP_RAWMATERIAL_COMPANY_NAME_API?.toString()
    const { mutate: mutateCompanyName, data: dataCompanyName, isError: CompanyNameIsError, isLoading: CompanyNameLoading, isSuccess: CompanyNameSuccess, error: CompanyNameError }: any = useCreate(apinameForCompanyName)
    const [selectedCompanyName, setSelectedCompanyName] = useState('')
    const [valueCompanyName, setValueCompanyName]: any = useState();
    const [optionsCompanyName, setOptionsCompanyName]: any = useState([]);
    const [valueCompanyNameCreatable, setValueCompanyNameCreatable] = useState<Option | null>();

    const { data: CompanyNameData, refetch: CompanyNameRefetch } = useFetchCompanyNameDropDown()

    useEffect(() => {
        setOptionsCompanyName(CompanyNameData)
    }, [CompanyNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeCompanyName = (e: any) => {
        CompanyNameRefetch()

        setApiName(apinameForCompanyName)
        setDeleteApiName(apinameForCompanyName)
        setEditId(e?.value)
        setEditData(CompanyNameData)
        setRenderKey(prevKey => prevKey + 1);

       

        setValueCompanyName(e)
        setValue("company_name", `${e?.value}`)
        setValueCompanyNameCreatable(null)
    }





    const handleCreateCompanyName = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCompanyName({ name: newOption.name, status: newOption.status })
            setOptionsCompanyName((prev: any) => [...prev, newOption]);
            setValueCompanyNameCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CompanyNameSuccess && optionsCompanyName) {
                await CompanyNameRefetch();
            }
            setValue("company_name", CompanyNameData[0].value)
        })();

        return () => {
        };
    }, [CompanyNameSuccess, CompanyNameData]);


    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])

    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handlePanniInputChange = (e: any) => {
        setSearchPanniTerm(e)
    }
    const handleChange = (e: any) => {


        setValue("material_color", e.value)
        setSelColor(e)
        setSelectedColor([])

    }
    const [HSN, setHSN]: any = useState()
    const [Density, setDensity]: any = useState()

    const { data: HSNData, refetch: HSNRefetch } = useFetchHSNMaterialNameDropDown(searchTerm)

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setSelHSN(e)
        setValue("hsn_code", `${e.value}`)
        setHSN(e.hsn)
        setDensity(e.material)
        setPolymer(e.polymer)
        setValue("polymer_grade", e.polymer)
    }

    // const handleChangeHSNData = (e: any) => {


    //     setValue("material_color", e.value)
    //     setSelColor(e)
    //     setSelectedColor([])

    // }




    // const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown(searchTerm)

    // useEffect(() => {
    //     setOptionsBrand(brandData)
    // }, [brandData])


    // const handleInputChangeBrand = (e: any) => {
    //     setSearchTermBrand(e)
    // }

    // const handleChangeBrand = (e: any) => {
    //     setColorNamePanni(e)
    //     // setValue("type",e.type)
    //     setValue("brand_name", e.value)
    //     setValue("brand_type", e.type)
    //     setSel(e)


    // }




    // const { data: materialdensityData, refetch: materialdensityRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    // useEffect(() => {
    //     setOptionsPanniName(materialdensityData)
    // }, [materialdensityData])

    // const {mutate:getrelated,data:relatedData} = useCreate(getRelatedHsn)
    // const [sutra, setSutra] = useState(0)
    // const handleChangePanniName = (e: any) => {

    //     setPanniName(e)
    //     setSelPanni(e)
    //     setValue("name_and_grade", `${e.value}`)

    //     setPolymer(e.polymer)
    //     setValue("polymer_grade", e.polymer)
    //     setValue("type", e.value)
    //     setGst(e?.gst)
    //     setValue("gst", e?.gst_id)
    //     getrelated({"grade":e.value})

    //     setValue("density", e?.density)
    // }









    // let temp_results = relatedData?.results.map((e:any)=>{
    //     return (
    //         {
    //             value:e.id,
    //             label:e.hsn_code,
    //             gst_id:e.gst.id,
    //             gst:e.gst.india_GST,
    //         })

    // })

    // setOptionsHSN(temp_results)

    // useEffect(() => {
    //     let temp_results = relatedData?.results.map((e:any)=>{
    //         return (
    //             {
    //                 value:e.id,
    //                 label:e.hsn_code,
    //                 gst_id:e.gst.id,
    //                 gst:e.gst.india_GST,
    //             })

    //     })

    //     setOptionsHSN(temp_results)
    // }, [relatedData])
    const [forHSNValue, setForHSNValue]: any = useState([])

    const [hsnCode, setHsnCode] = useState()
    // const {mutate:getrelated,data:relatedData} = useCreate(getRelatedHsn)

    // const handleChangeHSN = (e: any) => {
    //     setSelHSN(e)
    //     setValue("hsn_code", `${e.value}`)
    // }


    // useEffect(() => {
    //     let temp_results = relatedData?.results.map((e:any)=>{
    //         return (
    //             {
    //                 value:e.id,
    //                 label:e.hsn_code,
    //                 gst_id:e.gst.id,
    //                 gst:e.gst.india_GST,
    //             })

    //     })

    //     setOptionsHSN(temp_results)
    // }, [relatedData])


    const [valueMaterialBrandCreatable, setValueMaterialBrandCreatable] = useState<Option | null>();
    const [valueMaterialBrand, setValueMaterialBrand]: any = useState();
    const apinameForMaterialBrand = process.env.REACT_APP_RAWMATERIAL_BRAND_NAME_API?.toString()
    const { mutate: mutateMaterialBrand, data: dataMaterialBrand, isError: MaterialBrandIsError, isLoading: MaterialBrandLoading, isSuccess: MaterialBrandSuccess, error: MaterialBrandError }: any = useCreate(apinameForMaterialBrand)

    const [optionsMaterialBrand, setOptionsMaterialBrand]: any = useState([]);
    // const [SelectedBrand, setSelectedBrand]: any = useState([]);


    const { data: MaterialBrandData, refetch: MaterialBrandRefetch } = useFetchMaterialBrandDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMaterialBrand(MaterialBrandData)
    }, [MaterialBrandData])
    const handleChangeMaterialBrand = (e: any) => {
        MaterialBrandRefetch()

        setApiName(apinameForMaterialBrand)
        setDeleteApiName(apinameForMaterialBrand)
        setEditId(e?.value)
        setEditData(MaterialBrandData)
        setRenderKey(prevKey => prevKey + 1);

       
        setValueMaterialBrand(e)
        setValue("brand_name", `${e?.value}`)
        setValueMaterialBrandCreatable(null)
    }

    const handleCreateMaterialBrand = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialBrand({ name: newOption.name, status: newOption.status })
            setOptionsMaterialBrand((prev: any) => [...prev, newOption]);
            setValueMaterialBrandCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialBrandSuccess && optionsMaterialBrand) {
                await MaterialBrandRefetch();
            }
            setValue("brand_name", MaterialBrandData[0]?.value)
        })();

        return () => {
        };
    }, [MaterialBrandSuccess, MaterialBrandData]);



    const [panniType, setPanniType] = useState("")

    const handleSetPanniType = (e: any) => {
        setPanniType(e.target.value)
    }

    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedPanni(data?.type?.id)
            setSelectedColor(data?.material_color?.id)
            setSelectedBrand(data?.brand_name?.id)
            // setValue("name", data?.name?.id)
            setGst(data?.hsn_code?.gst?.india_GST)
            // setSelectedMaterial(data.name?.id)
            setSelectedMaterialSource(data?.source?.id)
            setSelectedMaterialUse(data?.material_use?.id)
            setValue("material_use", data?.material_use?.id)
            setSelectedUseCategory(data?.use_category?.id)
            setValue('use_category', data?.use_category?.id)
            setSelectedMaterialSector(data?.sector?.id)
            setValue("sector", data?.sector?.id)

            setSelectedHSN(data?.hsn_code?.id)
            setValue('hsn_code', data?.hsn_code?.id)

            setValue('source', data?.source?.id)
            // setValue("type", data?.type?.id)
            setValue("brand_name", data?.brand_name?.id)
            // setValue("gst", data?.hsn_code?.gst?.id)
            setValue("material_color", data?.material_color?.id)
            // setValue("use_category", data?.use_category)
            setValue("polymer_grade", data?.polymer_grade)
            // getrelated({"grade": data.type?.id})
            // setValue("density", data?.type?.material_density)
            setValueMFA5(data?.mfa5)
            setHSN(data?.hsn_code?.hsn_code)
            setDensity(`${data?.hsn_code?.grade_type?.material_type_name}::${data?.hsn_code?.grade_type?.material_density}::${data?.hsn_code?.grade_type?.grade_sutra}::${data?.hsn_code?.grade_type?.gst?.india_GST}`)

            setSelectedCompanyName(data?.company_name?.id)
            setValue("company_name", data?.company_name?.id)




        }
    }, [data])


    // useEffect(() => {
    //     if (submitted) {



    //         setSelPanni(optionsPanniName[0])
    //         setValue("type", optionsPanniName[0].value)
    //         setValue("density", optionsPanniName[0].density)
    //         setGst(optionsPanniName[0]?.gst)
    //         setValue("gst", optionsPanniName[0]?.gst_id)
    //         setPolymer(optionsPanniName[0].polymer)
    //         setValue("polymer_grade", optionsPanniName[0].polymer)

    //     }



    // }, [submitted, optionsPanniName])


    useEffect(() => {


        if (submittedColor) {

            setValue("material_color", options[0]?.value)
            setSelColor(options[0])

        }


    }, [submittedColor, options])

    useEffect(() => {



        if (submittedBrand) {

            setValue("brand_name", optionsbrand[0].value)
            setSel(optionsbrand[0])

        }

    }, [submittedBrand, optionsbrand])

    


    const [valueMFA5, setValueMFA5] = useState('')
    const handleMFA5 = (e: any) => {
        setValueMFA5(e.target.value)
    };


    const onSubmit = (pannidetail: any) => {

        if (pannidetail.panni_image?.length == 1 && pannidetail.panni_drawing?.length != 1) {
            delete pannidetail.panni_drawing

        } else if (pannidetail.panni_drawing?.length == 1 && pannidetail.panni_image?.length != 1) {
            delete pannidetail.panni_image
        }
        else {
            delete pannidetail.panni_image
            delete pannidetail.panni_drawing
        }

        const formData = new FormData();
        if (panniPrinting === "no") {
            delete pannidetail.printing_type
            delete pannidetail.printing_total_color
            delete pannidetail.printinig_length
            delete pannidetail.printinig_width
            delete pannidetail.printinig_color_name


        }


        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

            else if (Array.isArray(value) && key === "material_color") {
                value.forEach((val, index) => formData.append(`material_color`, val))
            }


            else if (Array.isArray(value) && key === "printing_color") {
                value.forEach((val, index) => formData.append(`printing_color`, val))
            }
            if (key === 'mfa5') {
                let decimalNumber = Number(valueMFA5);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mfa5', formattedString)
            }



        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listRawmaterialgrade', { state: { showEditToast: true } })

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, isSuccess, yessuccess,
        nosuccess, panniUpdated_response])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }



    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])


    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }



    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }
    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setSelHSN(optionsHSN[0])
            setValue("hsn_code", optionsHSN[0]?.value)
            setDensity(optionsHSN[0]?.material)
            setPolymer(optionsHSN[0]?.polymer)
            
        }

    }, [submittedHSN, optionsHSN])


    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForMaterialSource) {
            MaterialSourceRefetch()
        }
        if (apiName === apinameForMaterialUse) {
            MaterialUseRefetch()
        }
        if (apiName === apinameForMaterialSector) {
            MaterialSectorRefetch()
        }
        if (apiName === apinameForUseCategory) {
            UseCategoryRefetch()
        }
        if (apiName === apinameForMaterialBrand) {
            MaterialBrandRefetch()
        }
        if (apiName === apinameForCompanyName) {
            CompanyNameRefetch()
        }


    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueMaterialSource && apiName === apinameForMaterialSource) {
            setValueMaterialSource(null)
            setValueMaterialSourceCreatable(null)
        }
        if (OpenEditDropDownModel && valueMaterialUse && apiName === apinameForMaterialUse) {
            setValueMaterialUse(null)
            setValueMaterialUseCreatable(null)
        }
        if (OpenEditDropDownModel && valueMaterialSector && apiName === apinameForMaterialSector) {
            setValueMaterialSector(null)
            setValueMaterialSectorCreatable(null)
        }
        if (OpenEditDropDownModel && valueUseCategoryCreatable && apiName === apinameForUseCategory) {
            setValueUseCategory(null)
            setValueUseCategoryCreatable(null)
        }
        if (OpenEditDropDownModel && valueUseCategoryCreatable && apiName === apinameForMaterialBrand) {
            setValueMaterialBrand(null)
            setValueMaterialBrandCreatable(null)
        }
        if (OpenEditDropDownModel && valueCompanyName && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }




        if (deletesucess && apiName === apinameForMaterialSource) {
            setValueMaterialSource(null)
            setValueMaterialSourceCreatable(null)
        }
        if (deletesucess && apiName === apinameForMaterialUse) {
            setValueMaterialUse(null)
            setValueMaterialUseCreatable(null)
        }
        if (deletesucess && apiName === apinameForMaterialSector) {
            setValueMaterialSector(null)
            setValueMaterialUseCreatable(null)
        }
        if (deletesucess && apiName === apinameForUseCategory) {
            setValueUseCategory(null)
            setValueUseCategoryCreatable(null)
        }
        if (deletesucess && apiName === apinameForMaterialBrand) {
            setValueMaterialBrand(null)
            setValueMaterialBrandCreatable(null)
        }
        if (deletesucess && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Granule Details</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Purchase</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listRawmaterialgrade" className="text-decoration-none text-black">Granule Details</Link></li>
                                    <li className="breadcrumb-item active">Edit Granule Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Panni</span></h5>
                            </div> */}
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Panni Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Name <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterial(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("name", { required: { value: true, message: "Material Name is required" } })}
                                                        placeholder={'Select Material Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterial}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeMaterial}
                                                        // value={valueMaterialCreatable==null?valueMaterial:valueMaterialCreatable}
                                                        value={valueMaterialCreatable == null ? valueMaterial == null ? optionsMaterial?.filter((obj: any) => obj.value === selectedMaterial) : valueMaterial : valueMaterialCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN & GST%<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            placeholder={'Select Material Name & Type & HSN & GST%'}
                                                            {...register("hsn_code", { required: { value: false, message: "Hsn Code is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                                            maxMenuHeight={100}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type & Density & Sutra <span style={{ color: 'red' }}>*</span></label> */}
                                                    {/* <div className="d-flex row" >
                                                        <Select
                                                            // id="Panni Name & Grade"
                                                            {...register("type", { required: { value: true, message: "This field is required" } })}
                                                            placeholder={'Material Type'}
                                                            // onInputChange={handleInputChangePanniName}
                                                            components={customIndicator}
                                                            onChange={handleChangePanniName}
                                                            options={optionsPanniName}
                                                            value={selPanni === "" ? optionsPanniName?.filter((obj: any) => obj.value === selectedPanni) : selPanni}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="input-group" data-colorpicker-id={2}>

                                                        <input type="text"
                                                            value={Density} disabled
                                                            className="form-control" id="exampleInputBrand" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.type?.message}</span>
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}> */}
                                                {/* <Select
                                                            placeholder={'Select HSN Code'}
                                                            {...register("hsn_code", { required: { value: false, message: "Hsn Code is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}

                                                            /> */}

                                                {/* <input type="text"
                                                            value={HSN} disabled
                                                            className="form-control" id="exampleInputBrand"/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                   
                                                           
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>


                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Source <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialSource(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("source", { required: { value: true, message: "Material Source is required" } })}
                                                        placeholder={'Select Material Source'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialSource}
                                                        onCreateOption={handleCreateMaterialSource}
                                                        onChange={handleChangeMaterialSource}
                                                        // value={valueMaterialSourceCreatable==null?valueMaterialSource:valueMaterialSourceCreatable}
                                                        value={valueMaterialSourceCreatable == null ? valueMaterialSource == null ? optionsMaterialSource?.filter((obj: any) => obj.value === selectedMaterialSource) : valueMaterialSource : valueMaterialSourceCreatable}
                                                        onMenuOpen={() => MaterialSourceRefetch()}   
                                                    />
                                                    <span className='text-red w-100'>{errors?.source?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Color <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("material_color", { required: { value: true, message: "Color is required" } })}
                                                            placeholder={'Select Color'}
                                                            onInputChange={handleInputChange}
                                                            components={customIndicator}
                                                            onChange={handleChange}
                                                            options={options}
                                                            value={selColor.length === 0 ? options?.filter((obj: any) => obj.value === selectedColor) : selColor}
                                                            // defaultValue={options}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.material_color?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text"
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand"
                                                            {...register("grade", { onChange: handleValue, required: { value: false, message: "This field is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.grade?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material MFA5 </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text"
                                                            placeholder="Material MFA5"
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand"
                                                            {...register("mfa5", { onChange: handleMFA5, required: { value: false, message: "This field is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mfa5?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Polymer Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text"
                                                            disabled
                                                            placeholder="Polymer Name"
                                                            // value={polymer}
                                                            className="form-control" id="exampleInputBrand"
                                                            {...register("polymer_grade", { onChange: handleValue, required: { value: false, message: "This field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.polymer_grade?.message}</span>
                                                </div>



                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" disabled
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand"
                                                            {...register("density", { required: { value: false, message: "This field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.density?.message}</span>
                                                </div> */}




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Use <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialUse(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                                        placeholder={'Select Material Use'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialUse}
                                                        onCreateOption={handleCreateMaterialUse}
                                                        onChange={handleChangeMaterialUse}
                                                        // value={valueMaterialUseCreatable==null?valueMaterialUse:valueMaterialUseCreatable}
                                                        value={valueMaterialUseCreatable == null ? valueMaterialUse == null ? optionsMaterialUse?.filter((obj: any) => obj.value === selectedMaterialUse) : valueMaterialUse : valueMaterialUseCreatable}
                                                        onMenuOpen={() => MaterialUseRefetch()}

                                                    />
                                                    <span className='text-red w-100'>{errors?.material_use?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Material Machine Name <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialSector(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("sector", { required: { value: true, message: "Use Material Machine Name is required" } })}
                                                        placeholder={'Select Use Material Machine Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialSector}
                                                        onCreateOption={handleCreateMaterialSector}
                                                        onChange={handleChangeMaterialSector}
                                                        value={valueMaterialSectorCreatable == null ? valueMaterialSector == null ? optionsMaterialSector?.filter((obj: any) => obj.value === selectedMaterialSector) : valueMaterialSector : valueMaterialSectorCreatable}
                                                        onMenuOpen={() => MaterialSectorRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.sector?.message}</span>
                                                </div>








                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Product <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" 
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand" placeholder="Use Category"
                                                            {...register("use_category", {  onChange:handleValue,required: { value: false, message: "This field is required" } })} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div> */}

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueUseCategory(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("use_category", { required: { value: true, message: "Machine Product is required" } })}
                                                        placeholder={'Select Machine Product'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsUseCategory}
                                                        onCreateOption={handleCreateUseCategory}
                                                        onChange={handleChangeUseCategory}
                                                        // value={valueUseCategoryCreatable == null ? valueUseCategory : valueUseCategoryCreatable}
                                                        value={valueUseCategoryCreatable == null ? valueUseCategory == null ? optionsUseCategory?.filter((obj: any) => obj.value === selectedUseCategory) : valueUseCategory : valueUseCategoryCreatable}
                                                        onMenuOpen={() => UseCategoryRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.polymer_grade?.message}</span>
                                                </div>





                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Brand Name <span style={{ color: 'red' }}>*</span></label>

                                                    {/* <div className="d-flex row" >
                                                        <Select
                                                            {...register("brand_name", { required: { value: true, message: "Material Brand is required" } })}
                                                            placeholder={'Select Material Brand Name'}
                                                            onInputChange={handleInputChangeBrand}
                                                            components={customIndicator}
                                                            onChange={handleChangeBrand}
                                                            options={optionsbrand}
                                                            value={sel === "" ? optionsbrand?.filter((obj: any) => obj.value === selectedBrand) : sel}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setBrandOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div> */}
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialBrand(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("brand_name", { required: { value: false, message: "Material Brand Name is required" } })}
                                                        placeholder={'Select Material Brand Name'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialBrand}
                                                        onCreateOption={handleCreateMaterialBrand}
                                                        onChange={handleChangeMaterialBrand}
                                                        // value={valueMaterialBrandCreatable == null ? valueMaterialBrand : valueMaterialBrandCreatable}
                                                        value={valueMaterialBrandCreatable == null ? valueMaterialBrand == null ? optionsCompanyName?.filter((obj: any) => obj.value === selectedBrand) : valueMaterialBrand : valueMaterialBrandCreatable}
                                                        onMenuOpen={() => MaterialBrandRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.brand_name?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Company Name & City <span className='text-red'>*</span></label>
                                                    {/* <select className="form-select"
                                                        {...register("company_name", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="company-1" >Company-1</option>
                                                        <option value="company-2" >Company-2</option>
                                                    </select> */}
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCompanyName(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("company_name", { required: { value: true, message: "Use Category  is required" } })}
                                                        placeholder={'Select Use Category '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsCompanyName}
                                                        onCreateOption={handleCreateCompanyName}
                                                        onChange={handleChangeCompanyName}
                                                        // value={valueCompanyNameCreatable==null?valueCompanyName:valueCompanyNameCreatable}
                                                        value={valueCompanyNameCreatable == null ? valueCompanyName == null ? optionsCompanyName?.filter((obj: any) => obj.value === selectedCompanyName) : valueCompanyName : valueCompanyNameCreatable}
                                                        onMenuOpen={() => CompanyNameRefetch()}

                                                    />


                                                    <span className='text-red'>{errors?.status?.message}</span>




                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1"> GST %<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" disabled
                                                            value={GST}
                                                            className="form-control" id="exampleInputBrand"
                                                            {...register("gst", { required: { value: false, message: "This field is required" } })}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst?.message}</span>
                                                </div> */}



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" disabled selected style={{ fontSize: "20px", padding: "5px", background: "rgb(170, 170, 170)", color: "white" }}>-Status-</option>
                                                        <option value="active" style={{ fontSize: "20px", padding: "5px" }}>Active</option>
                                                        <option value="inactive" style={{ fontSize: "20px", padding: "5px" }}>Inactive</option>
                                                    </select>



                                                    <span className='text-red'>{errors?.status?.message}</span>




                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listRawmaterialgrade")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>


            <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />

            {/* <Footer /> */}
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
                {/* Edit Modal HTML */}
   <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Edit_RawMaterialGrade

