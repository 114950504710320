
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../hooks/Api";
import noImage from "../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";



interface Data {
  selection: any

  name_and_grade: any
  color: any
  brand: any
  brand_type: any
  hsn_code: any
  type: any
  length: any
  width: any
  micron: any
  gauge: any
  panni_1_nos_weight_gram: any
  panni_1KG_in_nos: any
  material_use: any
  bursting_strength: any
  sealing_type: any
  panni_special_running: any
  gst: any
  printing: any
  printing_type: any
  printing_color_name: any
  printing_total_color: any
  printing_length: any
  printing_width: any
  gadget: any
  gadget_size: any
  kan: any
  kan_length: any
  kan_width: any
  packet_packing_type: any
  packing_kg: any
  packing_type: any
  panni_image: any
  panni_drawing: any








  created_at: any

  created_by: any
  updated_by: any
  updated_at: any


  status: any;
  actions: any;
}


export const titleofheaders_PP = [
  {
    id: `srno`,
    Header: 'Sr No'

  },

  {
    id: 'name_and_grade',
    Header: 'Name & Grade'


  },
  {
    id: 'color',
    Header: 'Color'

  },
  {
    id: 'brand',
    Header: 'Brand'


  },
  {
    id: 'brand_type',
    Header: 'Brand Type'


  },
  {
    id: 'hsn_code',
    Header: 'HSN Code'


  },
  {
    id: 'type',
    Header: 'Type'


  },
  {
    id: 'length',
    Header: 'Length'


  },
  {
    id: 'width',
    Header: 'Width'


  },
  {
    id: 'micron',
    Header: 'Micron'


  },
  {
    id: 'gauge',
    Header: 'Gauge'

  },

  {
    id: 'panni_1_nos_weight_gram',
    Header: '1 Nos Weight'

  },
  {
    id: 'panni_1KG_in_nos',
    Header: '1kg In Nos'


  },
  {
    id: 'material_use',
    Header: 'Material Use'

  },
  {
    id: 'bursting_strength',
    Header: 'Bursting Strength'

  },
  {
    id: 'sealing_type',
    Header: 'Sealing Type'

  },
  {
    id: 'panni_special_running',
    Header: 'Special / Running'

  },
  {
    id: 'gst',
    Header: 'GST',

  },
  {
    id: 'printing',
    Header: 'Printing',


  },
  {
    id: 'printing_type',
    Header: ' Printing Type'


  },
  {
    id: 'printing_color_name',
    Header: 'Printing Color Name'



  },
  {
    id: 'printing_total_color',
    Header: 'Printing Total Color'


  },
  {
    id: 'printing_length',
    Header: 'Printing Length'


  },
  {
    id: 'printing_width',
    Header: 'Printing Width'


  },
  {
    id: 'gadget',
    Header: 'Gadget',


  },
  {
    id: 'gadget_size',
    Header: 'Gadget Size'




  },
  {
    id: 'kan',
    Header: 'Kan',

  },
  {
    id: 'kan_length',
    Header: 'Kan Length'



  },
  {
    id: 'kan_width',
    Header: 'Kan Width'



  },
  {
    id: 'packet_packing_type',
    Header: 'packet Packing Type'



  },
  {
    id: 'packing_kg',
    Header: 'Packing Kg'


  },
  {
    id: 'packing_type',
    Header: 'Packing Type'



  },





  {
    id: 'status',
    Header: 'Status',



  },


  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },




]





export const Columns_Panni_PP: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    // Header: `Sr.No`,
    sticky: "left",
  },
  {
    id: `panni_code`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Code</div>,
    // Header: `Sr.No`,
    accessor: "panni_code",
    sticky: "left",
  },

  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Product Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    sticky: "left",
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.grade_type?.material_type_name} {row.original.hsn_code?.particular_sector_name?.name} {row.original.hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'hsn_code',
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,
    // Header: 'Hsn Code',
    accessor: 'hsn_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.grade_type?.material_type_name}::
          {row.original.hsn_code?.particular_sector_name?.name === "0.000" || row.original.hsn_code?.particular_sector_name?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>0.000</span> : row.original.hsn_code?.particular_sector_name?.name}::
          {row.original.hsn_code?.product_categories?.name === "0.000" || row.original.hsn_code?.product_categories?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>****</span> : row.original.hsn_code?.product_categories?.name}::
          {row.original.hsn_code?.hsn_code}::
          {row.original.hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row?.original?.hsn_code?.gst_type?.name}
        </div>
      )
    }
  },
  {
    id: 'type_name',
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST % </div>,
    // Header: 'Hsn Code',
    accessor: 'type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.grade_type?.material_type_name}::{row.original.hsn_code?.grade_type?.material_density ? row.original.hsn_code?.grade_type?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::
          {row.original.hsn_code?.grade_type?.grade_sutra ? row.original.hsn_code?.grade_type?.grade_sutra : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::{row.original.hsn_code?.grade_type?.gst?.india_GST}
        </div>
      )
    }
  },
  {
    id: 'use_code_name',
    Header: () => <div style={{ display: "inline" }}>Use Product Code<br />& Name & Type</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: 'use_code_qty',
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: 'material_use',
    Header: () => <div style={{ display: "inline" }}>Material<br />Use</div>,
    // Header: 'Material Use',
    accessor: 'material_use',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.material_use?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'color',
    Header: () => <div style={{ display: "inline" }}>Product<br />Color Name</div>,
    // Header: 'Color',
    accessor: 'color',
    Cell: ({ row }: any) => {
      let color = row.original?.color?.map((e: any) => {
        return e?.company_color_name
      })
      return (
        <div>
          {color?.join(",")}
        </div>
      )
    }

  },
  {
    id: 'width',
    Header: () => <div style={{ display: "inline" }}>Product<br />Width mm</div>,
    // Header: 'Panni Width',
    accessor: 'width',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.width}
        </div>
      )
    }

  },
  {
    id: 'length',
    Header: () => <div style={{ display: "inline" }}>Product<br />Length mm</div>,
    // Header: 'Panni Length',
    accessor: 'length',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.length}
        </div>
      )
    }

  },
 
  {
    id: 'micron',
    Header: () => <div style={{ display: "inline" }}>Thickness<br />Micron</div>,
    // Header: 'Micron',
    accessor: 'micron',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.micron}
        </div>
      )
    }

  },
  {
    id: 'gauge',
    Header: () => <div style={{ display: "inline" }}>Thickness<br />Gauge</div>,
    // Header: 'Gauge',
    accessor: 'gauge',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.gauge}
        </div>
      )
    }

  },


  {
    id: 'gadget',
    Header: () => <div style={{ display: "inline" }}>Gadget<br />Yes-No</div>,
    accessor: 'gadget',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.gadget
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }


  },
  {
    id: 'gadget_size',
    Header: () => <div style={{ display: "inline" }}>Gadget<br />Size mm</div>,

    // Header: 'Gadget Size',
    accessor: 'gadget_size',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.gadget_size ? row.original?.gadget_size : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: 'kan',
    Header: () => <div style={{ display: "inline" }}>Product Kan<br />Yes-No</div>,
    accessor: 'kan',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.kan
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'kan_width',
    Header: () => <div style={{ display: "inline" }}>Product Kan<br />Width mm</div>,

    // Header: 'Kan Width',
    accessor: 'kan_width',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.kan_width ? row.original?.kan_width : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'kan_length',
    Header: () => <div style={{ display: "inline" }}>Product Kan<br />Length mm</div>,

    // Header: 'Kan Length',
    accessor: 'kan_length',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.kan_length ? row.original?.kan_length : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  
  {
    id: 'sealing_type',
    Header: () => <div style={{ display: "inline" }}>Product<br />Sealing Type</div>,

    // Header: 'Kan Width',
    accessor: 'sealing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.sealing_type?.name ? row.original?.sealing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'printing',
    Header: () => <div style={{ display: "inline" }}>Product<br />Printing</div>,

    accessor:'printing',
    Cell: ({row}:any) => {
      const convertToUpper = row.original?.printing
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'printing_type',
    Header: () => <div style={{ display: "inline" }}>Product<br />Printing Type</div>,
    // Header: 'Printing Type',
    accessor: 'printing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.printing_type?.name?row.original?.printing_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
      // if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'printing_color_name',
    Header: () => <div style={{ display: "inline" }}>Product Printing<br />Color Name</div>,
    // Header: 'Printing Color Name',
    accessor: 'printing_color_name',



    Cell: ({ row }: any) => {
      let printingColor = row.original?.printing_color_name?.map((e: any) => {
        return e?.name
      })
      if (printingColor.length === 0) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      return printingColor?.join(",")

    }


  },
  {
    id: 'printing_total_color',
    Header: () => <div style={{ display: "inline" }}>Product Printing<br />Total Color</div>,
    // Header: 'Printing Total Color',
    accessor: 'printing_total_color',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.printing_total_color ? row.original?.printing_total_color : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'brand',
    Header: () => <div style={{ display: "inline" }}>Product<br />Brand Name</div>,
    // Header: 'Brand Name',
    accessor: 'brand',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.brand?.name?row.original.brand?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'brand_type',
    Header: () => <div style={{ display: "inline" }}>Brand<br />Our-Other</div>,
    // Header: 'Brand Type',
    accessor: 'brand_type',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.brand?.type
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }


  },
  
  {
    id: 'printing_width',
    Header: () => <div style={{ display: "inline" }}>Product Print<br />Width mm</div>,
    // Header: 'Printing Width',
    accessor: 'printing_width',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.printing_width ? row.original?.printing_width : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'printing_length',
    Header: () => <div style={{ display: "inline" }}>Product Print<br />Length mm</div>,
    // Header: 'Printing Length',
    accessor: 'printing_length',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.printing_length ? row.original?.printing_length : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'product_ml',
    Header: () => <div style={{ display: "inline" }}>Product<br />ML</div>,
    // Header: 'Printing Length',
    accessor: 'product_ml',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_ml ? row.original?.product_ml : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'product_actual_ml',
    Header: () => <div style={{ display: "inline" }}>Product<br />Actual ML</div>,
    // Header: 'Printing Length',
    accessor: 'product_actual_ml',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_actual_ml ? row.original?.product_actual_ml : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
        id: 'panni_1_nos_weight_gram',
        Header: () => <div style={{ display: "inline" }}>Product 1 Nos<br />Weight Gram</div>,
        // Header: '1 nos Weight Gram',
        accessor:'panni_1_nos_weight_gram',
        Cell: ({ row }: any) => {
          return(
            <div>
              {row.original.panni_1_nos_weight_gram}
            </div>
          )
    }
      },
      {
        id: 'panni_1KG_in_nos',
        Header: () => <div style={{ display: "inline" }}>Product 1 KG<br />In Nos</div>,
        // Header: '1 KG In nos',
        accessor:'panni_1KG_in_nos',
        Cell: ({ row }: any) => {
          return(
            <div>
              {row.original.panni_1KG_in_nos}
            </div>
          )
    }
  
      },
      {
        id: 'packet_packing_type',
        Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Type</div>,
        // Header: '1 KG In nos',
        accessor:'packet_packing_type',
        Cell: ({ row }: any) => {
          return(
            <div>
              {row.original.packet_packing_type?.name}
            </div>
          )
    }
  
      },
      {
        id: 'uom',
        Header: () => <div style={{ display: "inline" }}>U.O.M</div>,
        // Header: '1 KG In nos',
        accessor:'uom',
        Cell: ({ row }: any) => {
          const convertToUpper = row.original.uom?.name?.abbreviation
              if (!convertToUpper) return "";
              return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
          // return(
          //   <div>
          //     {row.original.uom?.name?.abbreviation}
          //   </div>
          // )
    }
  
      },
      {
        id: 'packet_qty',
        Header: () => <div style={{ display: "inline" }}>Packet<br/>Qty</div>,
        // Header: '1 KG In nos',
        accessor:'packet_qty',
        Cell: ({ row }: any) => {
        
          return(
            <div>
              {row.original.packet_qty}
            </div>
          )
    }
  
      },
      {
        id: 'product_packing_total_kg',
        Header: () => <div style={{ display: "inline" }}>Product Packing<br/>Total Kg.</div>,
        // Header: '1 KG In nos',
        accessor:'product_packing_total_kg',
        Cell: ({ row }: any) => {
        
          return(
            <div>
              {row.original.product_packing_total_kg}
            </div>
          )
    }
  
      },
      {
        id: 'product_packing_total_kg_uom',
        Header: () => <div style={{ display: "inline" }}>U.O.M</div>,
        // Header: '1 KG In nos',
        accessor:'product_packing_total_kg_uom',
        Cell: ({ row }: any) => {
          const convertToUpper = row.original.product_packing_total_kg_uom?.name?.abbreviation
              if (!convertToUpper) return "";
              return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
          // return(
          //   <div>
          //     {row.original.uom?.name?.abbreviation}
          //   </div>
          // )
    }
  
      },
      {
        id: 'packing_type',
        Header: () => <div style={{ display: "inline" }}>Product Packing<br/>Type</div>,
        // Header: '1 KG In nos',
        accessor:'packing_type',
        Cell: ({ row }: any) => {
        
          return(
            <div>
              {row.original.packing_type?.name}
            </div>
          )
    }
  
      },

      {
        id: 'product_stock_minimum_qty',
        Header: () => <div style={{ display: "inline" }}>Product Stock<br />Minimum Qty </div>,
        // Header: 'Bursting Strength',
        accessor: 'product_stock_minimum_qty',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row.original.product_stock_minimum_qty}
            </div>
          )
        }
    
      },
      {
        id: 'product_stock_maximum_qty',
        Header: () => <div style={{ display: "inline" }}>Product Stock<br />Maximum Qty </div>,
        // Header: 'Bursting Strength',
        accessor: 'product_stock_maximum_qty',
        Cell: ({ row }: any) => {
          return (
            <div>
              {row.original.product_stock_maximum_qty}
            </div>
          )
        }
    
      },

  // {
  //   id: 'type',
  //   Header: () => <div style={{ display: "inline" }}>Panni<br />Type</div>,
  //   // Header: 'Panni Type',
  //   accessor:'type',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original.type
  //     if (!convertToUpper) return "";
  //     return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }


  // },
  //   
  

  {
    id: 'bursting_strength',
    Header: () => <div style={{ display: "inline" }}>Bursting<br />Strength</div>,
    // Header: 'Bursting Strength',
    accessor: 'bursting_strength',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.bursting_strength}
        </div>
      )
    }

  },
 
  {
    id: 'product_by',
    Header: () => <div style={{ display: "inline" }}>Product<br />By</div>,
    // Header: 'Special Or Running',
    accessor: 'product_by',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_by?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'panni_special_running',
    Header: () => <div style={{ display: "inline" }}>Product<br />Type</div>,
    // Header: 'Special Or Running',
    accessor: 'panni_special_running',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.panni_special_running?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'product_maximum_debit_day',
    Header: () => <div style={{ display: "inline" }}>Product Maximum<br />Debit Day</div>,
    // Header: 'Bursting Strength',
    accessor: 'product_maximum_debit_day',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_maximum_debit_day}
        </div>
      )
    }

  },
  {
    id: 'panni_location',
    Header: () => <div style={{ display: "inline" }}>Material<br />Location</div>,
    // Header: 'Special Or Running',
    accessor: 'panni_location',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.panni_location?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Name</div>,
    accessor:'material_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.grade_type?.material_type_name}
        </div>
      )
    }

  },
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor:'material_type_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.particular_sector_name?.name}
        </div>
      )
    }

  },
  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor:'material_category',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor:'material_hsn',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.hsn_code}
        </div>
      )
    }

  },
 
  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
    accessor:'material_gst',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor:'product_density',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.hsn_code?.product_density?row.original.hsn_code?.product_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'remark',
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    accessor:'remark',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },
 

  // {
  //   id: 'packet_packing_type',
  //   Header: () => <div style={{ display: "inline" }}>Panni<br />Packing Type</div>,

  //   // Header: 'Packet Packing Type',
  //   accessor:'packet_packing_type',
  //   Cell: ({ row }: any) => {
  //     return(
  //       <div>
  //         {row.original.packet_packing_type}
  //       </div>
  //     )
  //   }

  // },
  // {
  //   id: 'packing_kg',
  //   Header: () => <div style={{ display: "inline" }}>Panni<br />KG</div>,

  //   // Header: 'Packing KG',
  //   accessor:'packing_kg',
  //   Cell: ({ row }: any) => {
  //     return(
  //       <div>
  //         {row.original.packing_kg}
  //       </div>
  //     )
  //   }

  // },
  // {
  //   id: 'packing_type',
  //   Header: () => <div style={{ display: "inline" }}>Panni<br />Packing Type</div>,

  //   // Header: 'Packing Type',
  //   accessor:'packing_type',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original?.packing_type
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }

  // },
  
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  {
    id: `bori_and_carton_image`,
    Header: () => <div style={{ display: "inline" }}>Bori & Carton Packing<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.bori_and_carton_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

          {!row.original.bori_and_carton_image || row.original.bori_and_carton_image?.length === 0? <>

              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.bori_and_carton_image.length > 0 ? row.original.bori_and_carton_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.bori_and_carton_image[0]?.doc ? row.original.bori_and_carton_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.bori_and_carton_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
           <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },



  {
    id: `product_packet_packing_image`,
    Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_packet_packing_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

          {!row.original.product_packet_packing_image || row.original.product_packet_packing_image?.length === 0? <>

              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_packet_packing_image.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_packet_packing_image[0]?.doc ? row.original.product_packet_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_packet_packing_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
           <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },




  {
    id: `product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

          {!row.original.product_image || row.original.product_image?.length === 0? <>

              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
           <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },








  {
    id: `product_pdf_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
          {!row.original.product_pdf_drawing || row.original.product_pdf_drawing?.length === 0? <>

              <a

                href="#"
                className="text-danger"
              >
                No Document Uploaded
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_pdf_drawing.length > 0 ? row.original.product_pdf_drawing.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}
            
          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.product_pdf_drawing?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },







 






  {
    id: 'Actions',
    Header: 'Actions',


  },

  {
    id: 'Selection',
    sticky: "left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Panni_PP, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}panni/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



