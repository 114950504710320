import React from 'react'

const Calculation = (panniLength: any,panniWidth:any,gauge:any,sutra:any,panniType:any,panniGadget:any,panniKan:any, panniKanLength:any) => {
    let doubleSideTotalLength = 0
    let squareSutra = 645.16
    let oneNosWeight = 0
    let OneKgNos = 0
    
    
    // if (panniType === "single patto") {
        // doubleSideTotalLength = panniLength / 2
    // }

    if (panniGadget == "" && panniKan == "") {
        doubleSideTotalLength = panniLength / 2
    }
    if (panniGadget == "no" && panniKan == "no") {
        doubleSideTotalLength = panniLength * 2 / 2
    }

    // if (panniType === "liner") {
    //     doubleSideTotalLength = panniLength * 2 / 2
    // }

    // if (panniType === "liner" && panniGadget == "yes" && panniKan == "no") {
    if (panniGadget == "yes" && panniKan == "no") {
        doubleSideTotalLength = panniLength * 2 / 2
    }
    // if (panniType === "liner" && panniKan == "yes" && panniGadget == "no") {
    if (panniKan == "yes" && panniGadget == "no") {
        doubleSideTotalLength = ((panniLength * 2) + panniKanLength) / 2
    }

    // if (panniType === "liner" && panniGadget == "yes" && panniKan == "yes") {
    if (panniGadget == "yes" && panniKan == "yes") {
        doubleSideTotalLength = ((panniLength * 2) + panniKanLength) / 2
    }

    
    
    let TotalSquare = doubleSideTotalLength * Number(panniWidth)
    let TotalInch = TotalSquare / squareSutra
    
    
    if (panniLength != 1) {
        const temp_oneNos = Number((TotalInch * Number(gauge)) / Number(sutra))
        oneNosWeight = Number(temp_oneNos.toFixed(2))
    }
    if (oneNosWeight != 0) {
        OneKgNos = Number((1000 / oneNosWeight).toFixed(2))
    }

    return {onenos: oneNosWeight, kg: OneKgNos}
}

export default Calculation