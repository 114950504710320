import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCityDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Transport_Format = ({ setCurrentStep, currentStep }: any) => {
    let pageName = "Transport"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_TRANSPORT_FORMAT_API?.toString()


    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();







    const handleReset = () => {
        reset()
        setForCity1Value(null)
        setForCity2Value(null)
        setTotalOfCities(0)

    }

    const onSubmit = (details: any) => {

        if (!forCity2Value) {
            delete details?.city_2
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "city_1") {
                value.forEach((val, index) => formData.append(`city_1`, val))
            }
            else if (Array.isArray(value) && key === "city_2") {
                value.forEach((val, index) => formData.append(`city_2`, val))
            }

        }
        mutate(formData);
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [forCity1Value, setForCity1Value]: any = useState()
    const [forCity2Value, setForCity2Value]: any = useState()
    const [cityOptions, setCityOptions]: any = useState()
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        setCityOptions(cityData)
    }, [cityData])


    const [forState, setForState] = useState("")
    const [forCountry, setForCountry] = useState("")


    const [totalOfCities, setTotalOfCities] = useState(0)

    useEffect(() => {
        if (forCity1Value || forCity2Value) {
            setTotalOfCities(1)
            setValue("total_city_qty", 1)
        }
        if (forCity1Value && forCity2Value) {
            setTotalOfCities(2)
            setValue("total_city_qty", 2)
        }
    }, [forCity1Value, forCity2Value, totalOfCities])


    const handleChangeCity1 = (e: any) => {

        setForCity1Value(e)
        setValue("city_1", e?.value)

    }


    const handleChangeCity2 = (e: any) => {

        setForCity2Value(e)
        setValue("city_2", e?.value)

    }



    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;




    useEffect(() => {
        if (isSuccess && data) {


            let tempId = {
                transport_detail: data?.data?.id
            }

            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            // BasicDetails({})

        }
    }, [data, isSuccess])


    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'


    const [forSalesOrder, setForSalesOrder] = useState(false)
    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            // localStorage.setItem('Edit Data', JSON.stringify(basicDetailsData?.data))
            setForSalesOrder(true)
            if (!personTypeLabel && currentPath != '/addSalesOrder') {

                navigate('/listPersonDetail')
                localStorage.removeItem('PersonType')
                localStorage.removeItem('Add Data')
            } else {

            }
            // setCurrentStep(currentStep + 1);
        }
    }, [basicDetailsIsSuccess, basicDetailsData])


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listTransportdetail', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
            setForSalesOrder(false)
        }, 3000)

    }, [show]);

    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const currentPath = window.location.pathname;




    return (
        <div>
            <div className="">
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                            <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name?addData?.name:addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Priority</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Priority" {...register("priority", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.priority?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Transport Id <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Transport Id" {...register("transport_id", { required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.transport_id?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Transport Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Transport Name" {...register("name", { onChange: handleValue, required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Transport City-1 <span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        {...register("city_1", { required: { value: true, message: "Field is required" } })}
                                                        placeholder="Transport City-1"
                                                        onChange={handleChangeCity1}
                                                        options={cityOptions}
                                                        value={forCity1Value}
                                                        components={customIndicator}

                                                    />

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Transport City-2 </label>

                                                    <Select
                                                        isClearable
                                                        {...register("city_2", { required: { value: false, message: "Field is required" } })}
                                                        placeholder="Transport City-2"
                                                        value={forCity2Value}
                                                        options={cityOptions}
                                                        onChange={handleChangeCity2}
                                                        components={customIndicator}
                                                    />

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone,Village & City Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" disabled className="form-control" value={totalOfCities} placeholder="Zone,Village & City Qty" {...register("total_city_qty", { required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.total_city_qty?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Mobile Number Of City 1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Mobile Number Of City 1"
                                                            maxLength={10}
                                                            minLength={15}
                                                            onInput={maxLengthCheck}
                                                            {...register('mobile_no_city_1', {

                                                                required: 'Mobile Number is required',
                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_no_city_1?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Mobile Number Of City 2 </label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Mobile Number Of City 2"
                                                            maxLength={10}
                                                            minLength={15}
                                                            onInput={maxLengthCheck}
                                                            {...register('mobile_no_city_2', {
                                                                required: false,

                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_no_city_2?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Email ID Of City 1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder="Email ID Of City 1"
                                                            {...register('email_id_city_1', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_id_city_1?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Email ID Of City 2 </label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder="Email ID Of City 2"
                                                            {...register('email_id_city_2', {
                                                                required: false,

                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_id_city_2?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >State Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="State Name" {...register("state", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


                                                    <label >Country Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Country Name" {...register("country", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
                                                    <div>
                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => navigate('/listPersonDetail')}>Skip</button>
                                                        : null}

                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                        : null}
                                                    </div>

                                                    <div>
                                                        {forSalesOrder ? <h5 style={{ color: 'green' }}>Added</h5> : ''}
                                                        <button
                                                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                            onClick={handleSubmit(onSubmit)}
                                                        >Submit</button>
                                                        <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Transport_Format