
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import Modal from "react-bootstrap/esm/Modal";
import noImage from "../../../images/noImage.png"

interface Data {
  selection: any

  employee: any
  type: any
  customer: any
  priority: any
  account_type: any
  account_number: any
  swift_code: any
  iec_code: any
  ifsc_code: any
  cheque_print_name: any
  amount_transfer_account_name: any
  pan_number: any
  contact_number: any
  bank: any
  city: any
  branch: any
  micr: any
  district: any
  state: any
  country: any
  pincode: any


  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  status: any;
  Stepper_Actions: any;
}


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `type`,
    Header: "PersonType",

  },
  {
    id: `priority`,
    Header: "Priority",

  },

  {
    id: `customer`,
    Header: "Customer Code & Name",

  },
  {
    id: `employee`,
    Header: "Employee Code & Name",

  },
  {
    id: `ifsc_code`,
    Header: "IFSCCode",

  },
  {
    id: `bank`,
    Header: "Bank Name",

  },
  {
    id: `city`,
    Header: "City Name",

  },
  {
    id: `branch`,
    Header: "Branch Name",

  },
  {
    id: `account_type`,
    Header: "Account Type",

  },
  {
    id: `account_number`,
    Header: "Account Number",


  },
  {
    id: `swift_code`,
    Header: "Bank SwiftCode",

  },
  {
    id: `iec_code`,
    Header: "Bank IECCode",

  },
  {
    id: `micr`,
    Header: "Bank Micr Code",

  },
  {
    id: `cheque_print_name`,
    Header: "Cheque PrintName",

  },
  {
    id: `amount_transfer_account_name`,
    Header: "Amount TransferAccount Name",

  },
  {
    id: `district`,
    Header: "District",

  },
  {
    id: `state`,
    Header: "State",


  },
  {
    id: `country`,
    Header: "Country",

  },
  {
    id: `pincode`,
    Header: "Pin Code",

  },
  {
    id: `pan_number`,
    Header: "Pan Number",

  },
  {
    id: `contact_number`,
    Header: "Contact Number",

  },
  {
    id: `bank_contact_number_1`,
    Header: "Bank Contact Number-1",

  },
  {
    id: `bank_contact_number_2`,
    Header: "Bank Contact Number-2",

  },
  {
    id: `bank_email_id_1`,
    Header: "Bank E-mail ID-1",

  },
  {
    id: `bank_email_id_2`,
    Header: "Bank E-mail ID-2",

  },
  {
    id: `re_mark`,
    Header: "Re-Mark",

  },
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Bank_Format: any[] = [



  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    accessor: 'srno',
    sticky: "left",
  },
  // {
  //     id: `type`,
  //     Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
  //     accessor:'type',
  //    
  // },
  {
    id: `priority`,
    Header: () => <div style={{ display: "inline" }}>Priority<br />Type</div>,
    accessor: 'priority',
    sticky: "left",
    Cell: ({ row }: any) => {
      return (
        (row.original?.priority?.name ? row.original?.priority?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)
      )
    }
  },
  {
    id: `bank`,
    Header: () => <div style={{ display: "inline" }}>Bank<br />Name</div>,
    accessor: 'bank',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.BANK)

    }

  },

  // {
  //   id: `customer`,
  //   Header: () => <div style={{display:"inline"}}>Customer Code<br/>& Name</div>,
  //   accessor:'customer',
  //   Cell: ({ row }: any) => {
  //     return(
  //     (row.original?.customer?.name?row.original?.customer?.name: <span style={{ color: 'red',fontSize:"20px" }}>****</span>)
  //     )
  //     }

  // },
  // {
  //   id: `employee`,
  //   Header: () => <div style={{display:"inline"}}>Employee Code<br/>& Name</div>,
  //   accessor:'employee',
  //   Cell: ({ row }: any) => {
  //     return(
  //       (row.original?.employee?.name?row.original?.employee?.name: <span style={{ color: 'red',fontSize:"20px" }}>****</span>)
  //     )
  //     }

  // },


  {
    id: `city`,
    Header: () => <div style={{ display: "inline" }}>City<br />Name</div>,
    accessor: 'city',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.CITY)

    }

  },
  {
    id: `account_type`,
    Header: () => <div style={{ display: "inline" }}>Account <br /> Type</div>,
    accessor: 'account_type',
    Cell: ({ row }: any) => {
      return (row.original?.account_type?.name)

    }

  },
  {
    id: `account_number`,
    Header: () => <div style={{ display: "inline" }}>Bank Account <br /> Number</div>,
    accessor: 'account_number',
    Cell: ({ row }: any) => {
      return (row.original?.account_number)

    }

  },

  {
    id: `branch`,
    Header: () => <div style={{ display: "inline" }}>Branch<br />Name</div>,
    accessor: 'branch',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.BRANCH)

    }

  },

  {
    id: `ifsc_code`,
    Header: () => <div style={{ display: "inline" }}>Bank IFSC<br />Code</div>,
    accessor: 'ifsc_code',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.IFSC)

    }

  },
  {
    id: `swift_code`,
    Header: () => <div style={{ display: "inline" }}>Bank Swift<br />Code</div>,
    accessor: 'swift_code',
    Cell: ({ row }: any) => {
      return (row.original?.swift_code ? row.original?.swift_code : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)

    }

  },
  {
    id: `iec_code`,
    Header: () => <div style={{ display: "inline" }}>Bank IEC<br />Code</div>,
    accessor: 'iec_code',
    Cell: ({ row }: any) => {
      return (row.original?.iec_code ? row.original?.iec_code : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)

    }

  },
  {
    id: `micr`,
    Header: () => <div style={{ display: "inline" }}>Bank Micr <br /> Code</div>,
    accessor: 'micr',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.MICR ? row.original?.ifsc_code?.MICR : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)

    }

  },
  {
    id: `cheque_print_name`,
    Header: () => <div style={{ display: "inline" }}>Cheque Print<br />In Name</div>,
    accessor: 'cheque_print_name',
    Cell: ({ row }: any) => {
      return (row.original?.cheque_print_name ? row.original?.cheque_print_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)

    }

  },
  {
    id: `amount_transfer_account_name`,
    Header: () => <div style={{ display: "inline" }}>Amount Transfer<br />Account Name</div>,
    accessor: 'amount_transfer_account_name',
    Cell: ({ row }: any) => {
      return (row.original?.amount_transfer_account_name)

    }

  },
  {
    id: `district`,
    Header: () => <div style={{ display: "inline" }}>District <br /> Name</div>,
    accessor: 'district',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.DISTRICT)

    }

  },
  {
    id: `state`,
    Header: () => <div style={{ display: "inline" }}>State<br /> Name</div>,
    accessor: 'state',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.STATE)

    }

  },
  {
    id: `country`,
    Header: () => <div style={{ display: "inline" }}>Country<br /> Name</div>,
    accessor: 'country',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.COUNTRY ? row.original?.ifsc_code?.COUNTRY : <span style={{ color: 'red' }}>xxxx</span>)

    }

  },
  {
    id: `pincode`,
    Header: () => <div style={{ display: "inline" }}>Pin Code<br /> Number</div>,
    accessor: 'pincode',
    Cell: ({ row }: any) => {
      return (row.original?.ifsc_code?.PINCODE ? row.original?.ifsc_code?.PINCODE : <span style={{ color: 'red' }}>xxxx</span>)

    }

  },
  {
    id: `pan_number`,
    Header: () => <div style={{ display: "inline" }}>Pan <br /> Number</div>,
    accessor: 'pan_number',
    Cell: ({ row }: any) => {
      return (row.original?.pan_number)

    }

  },


  {
    id: `bank_contact_number_1`,
    Header: () => <div style={{ display: "inline" }}>Bank Contact <br /> Number-1</div>,
    accessor: 'bank_contact_number_1',
    Cell: ({ row }: any) => {
      return (row.original?.bank_contact_number_1)

    }

  },


  {
    id: `bank_contact_number_2`,
    Header: () => <div style={{ display: "inline" }}>Bank Contact <br /> Number -2</div>,
    accessor: 'bank_contact_number_2',
    Cell: ({ row }: any) => {
      return (row.original?.bank_contact_number_2?row.original?.bank_contact_number_2: <span style={{ color: 'red' }}>xxxx</span>)

    }

  },

  {
    id: `bank_email_id_1`,
    Header: () => <div style={{ display: "inline" }}>Bank E-mail <br /> ID-1</div>,
    accessor: 'bank_email_id_1',
    Cell: ({ row }: any) => {
      return (row.original?.bank_email_id_1)

    }

  },

  {
    id: `bank_email_id_2`,
    Header: () => <div style={{ display: "inline" }}>Bank E-mail <br /> ID-2</div>,
    accessor: 'bank_email_id_2',
    Cell: ({ row }: any) => {
      return (row.original?.bank_email_id_2?row.original?.bank_email_id_2: <span style={{ color: 'red' }}>xxxx</span>)

    }

  },

  {
    id: `re_mark`,
    Header: () => <div style={{ display: "inline" }}>Re-Mark</div>,
    accessor: 're_mark',
    Cell: ({ row }: any) => {
      return (row.original?.re_mark?row.original?.re_mark: <span style={{ color: 'red' }}>xxxx</span>)

    }

  },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },
  {
    id: `image`,
    Header: () => <div style={{ display: "inline" }}>Cheque<br />Image</div>,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };

      return (
        <>

          <div >


            {!row.original?.image || row.original.image?.length === 0 ? <>

              <a

                href="#"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}

                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.image[0]?.doc ? row.original.image[0]?.doc : noImage} alt='no image' width={50} height={50} />

              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                    </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <p>{currentIndex + 1}/{fieldImages.length}</p>

              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Stepper_Actions',
    Header: 'Actions',


  },

  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Bank_Format, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



