import { Menu, MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import logo from "../../images/logo.png";
import MaintenanceLogo from "../../images/Dashboard (8).png";
import HRMS from "../../images/icons8-human-resource-32.png";
import Accounting from "../../images/icons8-accounting-30.png";
import { Link } from 'react-router-dom';
import { useFetchSidebar } from '../../hooks/DropDown_Api';
import "./sidebar.css"

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        key: 'Settings',
        icon: <img src={MaintenanceLogo}/>,
        label: 'Settings',
        children: [
            { key: 'Column Visibility', label: (<Link to={'/settings/ColumnVisibility'} style={{ textDecoration: "none" }}>Column Visibility</Link>) }
        ],
    },
    // {
    //     key: 'HRMS',
    //     icon: <img src={HRMS} style={{color:'white'}}/>,
    //     label: 'HRMS',
    //     children: [
    //         // { key: 'Account', label: (<Link to={'/hrms/listAccount'} style={{ textDecoration: "none" }}>Account</Link>) },
    //         { key: 'Project', label: (<Link to={'/listProject'} style={{ textDecoration: "none" }}>Project</Link>) },
    //         { key: 'Milestone', label: (<Link to={'/listMilestone'} style={{ textDecoration: "none" }}>Milestone</Link>) },
    //         { key: 'Task Priority', label: (<Link to={'/listTaskPriority'} style={{ textDecoration: "none" }}>Task Priority</Link>) },
    //         { key: 'Attendance', label: (<Link to={'/listAttendance'} style={{ textDecoration: "none" }}>Attendance</Link>) },
    //         { key: 'Award', label: (<Link to={'/listAward'} style={{ textDecoration: "none" }}>Award</Link>) },
    //         { key: 'Leave Application', label: (<Link to={'/listLeaveApplication'} style={{ textDecoration: "none" }}>Leave Application</Link>) },
    //         { key: 'Leave Policy', label: (<Link to={'/listLeavePolicy'} style={{ textDecoration: "none" }}>Leave Policy</Link>) },
    //         { key: 'Announcement', label: (<Link to={'/listAnnouncement'} style={{ textDecoration: "none" }}>Announcement</Link>) },
    //         { key: 'Email', label: (<Link to={'/listEmail'} style={{ textDecoration: "none" }}>Email</Link>) },
    //         { key: 'Company Setting', label: (<Link to={'/listCompanySetting'} style={{ textDecoration: "none" }}>Company Setting</Link>) },
    //         { key: 'Team', label: (<Link to={'/listTeam'} style={{ textDecoration: "none" }}>Team</Link>) },
    //         { key: 'Public Holiday', label: (<Link to={'/listPublicHoliday'} style={{ textDecoration: "none" }}>Public Holiday</Link>) },
    //         { key: 'Weekly Holiday', label: (<Link to={'/listWeeklyHoliday'} style={{ textDecoration: "none" }}>Weekly Holiday</Link>) },
    //         { key: 'CalculatePayroll', label: (<Link to={'/CalculatePayroll'} style={{ textDecoration: "none" }}>Calculate Payroll</Link>) },
    //         // { key: 'Transaction', label: (<Link to={'/listTransaction'} style={{ textDecoration: "none" }}>Transaction</Link>) },
    //         // { key: 'Hrms Task', label: (<Link to={'/listHrmsTask'} style={{ textDecoration: "none" }}>Hrms Task</Link>) },
    //         // { key: 'Task Status', label: (<Link to={'/listTaskStatus'} style={{ textDecoration: "none" }}>Task Status</Link>) },
    //     ],
    // },
    // {
    //     key: 'Accounting',
    //     icon: <img src={Accounting}/>,
    //     label: 'Accounting',
    //     children: [
    //         { key: 'Person Transaction', label: (<Link to={'/listPersonTransaction'} style={{ textDecoration: "none" }}>Person Transaction</Link>) },
    //         { key: 'Bank Transaction', label: (<Link to={'/listBankTransaction'} style={{ textDecoration: "none" }}>Bank Transaction</Link>) },
    //         { key: 'Other Transaction', label: (<Link to={'/listOtherTransaction'} style={{ textDecoration: "none" }}>Other Transaction</Link>) },
    //         { key: 'Other Transaction Accounts', label: (<Link to={'/listOtherTransactionAccounts'} style={{ textDecoration: "none" }}>Other Transaction Accounts</Link>) },
    //         { key: 'Cash Transaction', label: (<Link to={'/listCashTransaction'} style={{ textDecoration: "none" }}>Cash Transaction</Link>) },
    //         { key: 'Bank Accounts Transaction', label: (<Link to={'/listBankAccountsTransaction'} style={{ textDecoration: "none" }}>Bank Accounts Transaction</Link>) },
    //     ],
    // },
]


interface LevelKeysProps {
    key?: string;
    children?: LevelKeysProps[];
  }

const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2?.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  

const Antd_Sidebar = () => {

  //   const [stateOpenKeys, setStateOpenKeys]:any = useState([]);

  //   const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
  //     const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
  //     // open
  //     if (currentOpenKey !== undefined) {
  //       const repeatIndex = openKeys
  //         .filter((key) => key !== currentOpenKey)
  //         .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
  
  //       setStateOpenKeys(
  //         openKeys
  //           // remove repeat key
  //           .filter((_, index) => index !== repeatIndex)
  //           // remove current level all child
  //           .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
  //       );
  //     } else {
  //       // close
  //       setStateOpenKeys(openKeys);
  //     }
  //   };
    

  //   const [sidebarOptions, setSidebarOptions]: any = useState([]);
  //   const { data: sidebarData, refetch: colorRefetch } = useFetchSidebar();

  //   useEffect(() => {
  //       setSidebarOptions(sidebarData);
  //   }, [sidebarData]);

  //   const data: any = sidebarOptions?.map((option: any, index: any) => {

  //       let child = option.modules?.map((suboption: any, subindex: any) => {
  //           let subchild = suboption.modules?.map((subchildoption: any, subchildindex: any) => {
  //               return (
  //                   {
  //                       key: subchildoption?.label ? subchildoption?.label : subchildoption?.model?.app_label,
  //                       label: (<Link to={`/${option?.link_path}${subchildoption?.url?.charAt(0)?.toUpperCase() + subchildoption?.url?.slice(1) ?
  //                           subchildoption?.url?.charAt(0)?.toUpperCase() + subchildoption?.url.slice(1) :
  //                           subchildoption?.model?.model?.charAt(0)?.toUpperCase() + subchildoption?.model?.model.slice(1)}`} style={{ textDecoration: "none" }} rel="noopener noreferrer">
  //                           {subchildoption?.label ? subchildoption?.label : subchildoption?.model?.app_label}
  //                       </Link>)
  //                   }
  //               )

  //           })
  //           return (
  //               {
  //                   key: suboption?.label ? suboption?.label : suboption?.model?.app_label,
  //                   label: (<Link to={suboption?.title ? "" : `/${option?.link_path}${suboption?.url?.charAt(0)?.toUpperCase() + suboption?.url?.slice(1) ?
  //                       suboption?.url?.charAt(0)?.toUpperCase() + suboption?.url.slice(1) :
  //                       suboption?.model?.model?.charAt(0)?.toUpperCase() + suboption?.model?.model.slice(1)}`} style={{ textDecoration: "none" }} rel="noopener noreferrer">
  //                       {suboption?.label ? suboption?.label : suboption?.model?.app_label || suboption?.title}
  //                   </Link>),
  //                   children: subchild
  //               }
  //           )
  //       })
  //       return ({
  //           key: option?.id,
  //           label: option?.title,
  //           icon: <img src={option?.icon} />,
  //           children: child,
  //       })
  //   })
  //   const sidebar: any = data?.concat(items)
  // const levelKeys = getLevelKeys(sidebar as LevelKeysProps[]);

    
  //   return (
  //       <>
  //           <div className='wrapper sidebar-dark-primary' style={{ width: "fit-content" }}>
  //               <div className='main-sidebar'>
  //                   <div className="bg-light p-0 brand-link ">
  //                       <img src={logo} alt="" className="p-1 mx-2 mt-1" width={56} />
  //                       <span className="brand-text font-weight-light">
  //                           Quantum<b>Bot</b>
  //                       </span>
  //                   </div>
  //                   <Menu
  //                       style={{ height: "-webkit-fill-available", overflow:'auto',paddingBottom:'70px' }}
  //                       mode="inline"
  //                       theme="dark"
  //                       // defaultSelectedKeys={['231']}
  //                       inlineCollapsed={false}
  //                       items={sidebar}
  //                       openKeys={stateOpenKeys}
  //                       onOpenChange={onOpenChange}
  //                   />
  //               </div>
  //           </div>
  //       </>
  //   )

  const [stateOpenKeys, setStateOpenKeys]:any = useState([]);

const onOpenChange: MenuProps['onOpenChange'] = (openKeys:any) => {
  const currentOpenKey = openKeys.find((key:any) => !stateOpenKeys.includes(key));

  if (currentOpenKey) {
    const newOpenKeys:any = openKeys.filter((key:any) => {
      const isCurrentLevel = levelKeys[key] === levelKeys[currentOpenKey];
      return key === currentOpenKey || !isCurrentLevel;
    });

    setStateOpenKeys(newOpenKeys);
  } else {
    setStateOpenKeys(openKeys);
  }
};

const [sidebarOptions, setSidebarOptions] = useState([]);
const { data: sidebarData } = useFetchSidebar();

useEffect(() => {
  setSidebarOptions(sidebarData);
}, [sidebarData]);

const data: any = sidebarOptions?.map((option: any) => {
  const children = option.modules?.map((suboption: any) => {
    const subchildren = suboption.modules?.map((subchildoption: any) => {
      const url = subchildoption?.url ? capitalizeFirstLetter(subchildoption.url) : capitalizeFirstLetter(subchildoption?.model?.model);
      
      return {
        key: subchildoption?.label || subchildoption?.model?.app_label,
        label: (
          <Link
            to={`/${option?.link_path}${url}`}
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer"
          >
            {subchildoption?.label || subchildoption?.model?.app_label}
          </Link>
        ),
      };
    });

    return {
      key: suboption?.label || suboption?.model?.app_label,
      label: (
        <Link
          to={suboption?.title ? '' : `/${option?.link_path}${suboption?.url ? capitalizeFirstLetter(suboption.url) : capitalizeFirstLetter(suboption?.model?.model)}`}
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
        >
          {suboption?.label || suboption?.model?.app_label || suboption?.title}
        </Link>
      ),
      children: subchildren?.length > 0 ? subchildren : undefined, // Only include children if there are any
    };
  });

  return {
    key: option?.id,
    label: option?.title,
    icon: <img src={option?.icon} alt="" />,
    children: children?.length > 0 ? children : undefined, // Only include children if there are any
  };
});



const sidebar = data?.concat(items);
const levelKeys = getLevelKeys(sidebar);

return (
  <div className='wrapper sidebar-dark-primary' style={{ width: 'fit-content' }}>
    <div className='main-sidebar'>
      <div className='bg-light p-0 brand-link'>
        <img src={logo} alt="" className='p-1 mx-2 mt-1' width={56} />
        <span className='brand-text font-weight-light'>
          Quantum<b>Bot</b>
        </span>
      </div>
      <Menu
        style={{ height: '-webkit-fill-available', overflow: 'auto', paddingBottom: '70px' }}
        mode="inline"
        theme="dark"
        inlineCollapsed={false}
        items={sidebar}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
      />
    </div>
  </div>
);
}

export default Antd_Sidebar
const capitalizeFirstLetter = (string:any) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};