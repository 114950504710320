import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { excel } from "react-export-table-to-excel/lib/lib";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import {
    useFetchCartonDropDown, useFetchColorDropDown, useFetchBrandDropDown, useFetchPanniProductPakingTypeDropDown,
    useFetchUomDropDown, useFetchPanniProductPrintingTypeDropDown, useFetchMaterialDensityDropDown,
    useFetchMaterialTypeDropDown, useFetchMouldShapeDropDown, useFetchPanniDropDown, useFetchPiceCodeDropDown,
    useFetchProductMLDropDown, useFetchStickDropDown, useFetchPacketPakingDropDown, useFetchCategoryDropDown, useFetchPanniProductByDropDown, useFetchMaterialUseDropDown, useFetchProductLocationDropDown, useFetchBoxPattiDropDown, useFetchTapeRollDropDown, useFetchPackingCoverDoriDropDown,
    useFetchSingleCodeProductTypeDropDown,
    useFetchSinglePiceCodeDropDown
} from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import Axiosinstance from "../../hooks/Interceptor";
import { Upload } from "antd";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import type { UploadFile } from 'antd/es/upload/interface';
import styles from './singlecodeDeseble.module.css';

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { UOM_Model } from "../../components/Dynamic_Form_Models/Uom_Model";
import CustomOptionDropDown from "./CustomOption";
import { Deletesinglewithdependancy } from "../../components/modal";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active",
});


const Edit_DisposableProduct = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const navigate = useNavigate()
    const { id } = useParams()

    const apiname = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: Updated_response }: any = useUpdateSingle()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo]: any = useState(null)
    const [logoPdf, setlogoPdf]: any = useState(null)

    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [searchTermpiceCode, setSearchTermpiceCode] = useState('')

    const [optionspanni, setOptionsPanni] = useState([])
    const [optionscarton, setOptionsCarton] = useState([])
    const [optionsPiceCode, setOptionsPiceCode] = useState([])

    const [PanniValue, setPanniValue]: any = useState('')
    const [colorNameStick, setColorNameStick]: any = useState({})
    const [piceCode, setPiceCode]: any = useState({})
    const [optionsProductType, setOptionsProductType]: any = useState([]);

    const [searchTermPanni, setSearchTermPanni] = useState('')
    const [searchTermStick, setSearchTermStick] = useState('')

    const [productType, setProductType] = useState("");

    // const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    // useEffect(() => {
    //     setOptions(colorData)
    // }, [colorData])


    // useEffect(() => {
    //     if (searchTerm.length >= 1) {
    //         colorRefetch()
    //     }
    // }, [])





    const [selpanni, setSelpanni]: any = useState('')
    const [selectedpanni, setSelectedpanni]: any = useState('')

    const [PackingCoverCode, setPackingCoverCode]: any = useState()
    const [selPackingCoverCode, setSelPackingCoverCode]: any = useState('')
    const [selectedPackingCoverCode, setSelectedPackingCoverCode]: any = useState('')

    const { data: panniData, refetch: panniRefetch } = useFetchPanniDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanni(panniData)
    }, [panniData])


    useEffect(() => {
        if (searchTermPanni.length >= 1) {
            panniRefetch()
        }
    }, [])





    const handleChangePanni = (e: any) => {

        setPanniValue(e)
        setSelpanni(e)

        setValue("panni_code_and_grade_name", e?.value)
    }

    const handleChangePackingCoverCode = (e: any) => {
        setPackingCoverCode(e)
        setSelPackingCoverCode(e)
        setValue("product_packing_cover_code_name", e?.value)
    }

    const apinameForProductType = process.env.REACT_APP_SINGLE_CODE_PRODUCT_TYPE_API?.toString()
    const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
    const [selectedProductType, setSelectedProductType]: any = useState('')
    const [valueProductType, setValueProductType]: any = useState();
    const [SelProductTypeLabel, setSelProductTypeLabel]: any = useState();
    const [ProductTypeLabel, setProductTypeLabel]: any = useState();
    const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();

    const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchSingleCodeProductTypeDropDown()

    useEffect(() => {
        setOptionsProductType(ProductTypeData)
    }, [ProductTypeData])


    const handleChangeProductType = (e: any) => {

        ProductTypeRefetch()

        setApiName(apinameForProductType)
        setDeleteApiName(apinameForProductType)
        setEditId(e?.value)
        setEditData(ProductTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductType(e)
        setProductTypeLabel(e?.label)

        setValue("product_type", `${e?.value}`)
        setValueProductTypeCreatable(null)
    }





    const handleCreateProductType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductType({ name: newOption.name, status: newOption.status })
            setOptionsProductType((prev: any) => [...prev, newOption]);
            setValueProductTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductTypeSuccess && optionsProductType) {
                await ProductTypeRefetch();
                setValue("product_type", ProductTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductTypeSuccess, ProductTypeData]);

    const [selectedPiceCode, setSelectedPiceCode]: any = useState('')
    const [selPiceCode, setSelPiceCode]: any = useState('')
    const { data: picecodeData, refetch: picecodeRefetch } = useFetchSinglePiceCodeDropDown(valueProductType?.label || SelProductTypeLabel)

    useEffect(() => {
        setOptionsPiceCode(picecodeData)
    }, [picecodeData])

    useEffect(() => {
        if (searchTermpiceCode.length >= 1) {
            picecodeRefetch()
        }
    }, [])



    const handleInputChangePiceCode = (e: any) => {
        setSearchTermpiceCode(e)
    }

    const handleChangePiceCode = (e: any) => {
        // Disposable
        setSelPiceCode(e)
        setValue("product_price_list_one_piece_weight", e.price_list_1_pcs_weight)
        setValue("product_current_height_mm", e.product_current_height_mm)
        setValue("product_ml_price_list", e.product_ml_price_list)
        setValue("product_actual_ml", e.product_actual_ml)
        setValue("product_final_height_mm", e.product_final_height_mm)
        // setValue("product_lip",e.product_lip)
        setValue("product_1_pcs_weight", e.product_1_pcs_weight)
        setValue("price_list_mould_diameter_mm", e.price_list_mould_diameter_mm)
        setValue("production_mould_diameter_mm", e.product_mould_diameter_mm)
        setValue("production_mould_length_mm", e.product_length_diameter)
        setValue("production_mould_width_mm", e.product_width_diameter)
        setValue("mould_shape", e?.mould_shape)
        setValue("product_lip", e?.current_lip)


        // LLDP LD Liner
        setValue('product_grade_name', e?.product_grade_name)
        setValue('material_type', e?.material_type)
        setValue('company_color_name', e?.company_color_name)
        setValue('product_width_mm', e?.product_width_mm)
        setValue('product_length_mm', e?.product_length_mm)
        setValue('thickness_micron', e?.thickness_micron)
        setValue('thickness_gauge', e?.thickness_gauge)
        setValue('gadget_yes_no', e?.gadget_yes_no)
        setValue('gadget_size_mm', e?.gadget_yes_no === 'no' ? '-' : e?.gadget_size_mm)
        setValue('product_kan_yes_no', e?.product_kan_yes_no)
        setValue('product_kan_width_mm', e?.product_kan_yes_no === 'no' ? "-" : e?.product_kan_width_mm)
        setValue('product_kan_length_mm', e?.product_kan_yes_no === 'no' ? "-" : e?.product_kan_length_mm)
        setValue('product_sealing_type', e?.product_sealing_type)
        setValue('product_ml', e?.product_ml)
        setValue('product_ml_actual', e?.product_ml_actual)
        setValue('product_bursting_strength', e?.product_bursting_strength)
        setValue('product_our_other', e?.product_our_other)
        setValue('product_special_running_type', e?.product_special_running_type)


        setPiceCode(e)
        setValue("product_single_piece_code", e.value)
    }

    const [selcarton, setSelcarton]: any = useState('')
    const [selectedcarton, setSelectedcarton]: any = useState('')
    const { data: cartonData, refetch: cartonRefetch } = useFetchCartonDropDown(searchTerm)

    useEffect(() => {
        setOptionsCarton(cartonData)
    }, [cartonData])


    useEffect(() => {
        if (searchTermStick.length >= 1) {
            cartonRefetch()
        }
    }, [])


    const handleChangeCarton = (e: any) => {
        setColorNameStick(e)
        setSelcarton(e)
        setValue("carton_code_and_grade_and_name", e.value)


    }





    const [prductTypeValue, setProductTypeValue]: any = useState(null);
    const handleReset = () => {
        reset()
        setProductTypeValue(null)
        setProductType("")
        setPiceCode(null)
        setlogo("")
        setlogoPdf("")
    }

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [brandOpen, setBrandOpen] = useState(false)
    const [forBrandValue, setForBrandValue]: any = useState()
    const [submittedBrand, setSubmittedBrand] = useState(false)
    const [selBrand, setSelBrand]: any = useState('')
    const [selectedBrand, setSelectedBrand]: any = useState('')

    const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown(searchTerm)

    useEffect(() => {
        setOptionsBrand(brandData)
    }, [brandData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch()
        }

    }, [])





    const handleChangeBrand = (e: any) => {
        setSelBrand(e)
        setValue("product_brand", `${e.value}`)
        setValue("our_product_brand", `${e.type}`)
        setForBrandValue({ label: e?.label, value: e?.value, type: e?.type })
    }

    const [optionsBoxpatti, setOptionsboxpatti]: any = useState([])
    const [forBoxPattiValue, setForBoxPattiValue]: any = useState()
    const [selBoxPatti, setSelBoxPatti]: any = useState('')
    const [selectedBoxPatti, setSelectedBoxPatti]: any = useState('')


    const { data: boxpattiData, refetch: boxpattiRefetch } = useFetchBoxPattiDropDown(searchTerm)

    useEffect(() => {
        setOptionsboxpatti(boxpattiData)
    }, [boxpattiData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            boxpattiRefetch()
        }

    }, [])





    const handleChangeBoxPatti = (e: any) => {
        setForBoxPattiValue(e)
        setSelBoxPatti(e)
        setValue("box_patti", `${e.value}`)
        setForBoxPattiValue({ label: e?.label, value: e?.value, type: e?.type })
    }

    const [optionsTapeRoll, setOptionsTapeRoll]: any = useState([])
    const [forTapeRollValue, setForTapeRollValue]: any = useState()
    const [selTapeRoll, setSelTapeRoll]: any = useState('')
    const [selectedTapeRoll, setSelectedTapeRoll]: any = useState('')


    const { data: TapeRollData, refetch: TapeRollRefetch } = useFetchTapeRollDropDown(searchTerm)

    useEffect(() => {
        setOptionsTapeRoll(TapeRollData)
    }, [TapeRollData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            TapeRollRefetch()
        }

    }, [])





    const handleChangeTapeRoll = (e: any) => {
        setForTapeRollValue(e)
        setSelTapeRoll(e)
        setValue("tape_roll_code_and_grade_name", `${e.value}`)
        setForTapeRollValue({ label: e?.label, value: e?.value, type: e?.type })
    }



    const apinameForProductPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
    const { mutate: mutateProductPrintingType, data: dataProductPrintingType, isError: ProductPrintingTypeIsError, isLoading: ProductPrintingTypeLoading, isSuccess: ProductPrintingTypeSuccess, error: ProductPrintingTypeError }: any = useCreate(apinameForProductPrintingType)

    const [valueProductPrintingType, setValueProductPrintingType]: any = useState();
    const [selectedProductPrintingType, setSelectedProductPrintingType]: any = useState('');
    const [optionsProductPrintingType, setOptionsProductPrintingType]: any = useState([]);
    const [valueProductPrintingTypeCreatable, setValueProductPrintingTypeCreatable] = useState<Option | null>();

    const { data: ProductPrintingTypeData, refetch: ProductPrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

    useEffect(() => {
        setOptionsProductPrintingType(ProductPrintingTypeData)
    }, [ProductPrintingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPrintingTypeRefetch()
        }
    }, [])


    const handleChangeProductPrintingType = (e: any) => {

        ProductPrintingTypeRefetch()

        setApiName(apinameForProductPrintingType)
        setDeleteApiName(apinameForProductPrintingType)
        setEditId(e?.value)
        setEditData(ProductPrintingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPrintingType(e)

        setValue("panni_printing_type", `${e?.value}`)
        setValueProductPrintingTypeCreatable(null)
    }





    const handleCreateProductPrintingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPrintingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPrintingType((prev: any) => [...prev, newOption]);
            setValueProductPrintingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPrintingTypeSuccess && optionsProductPrintingType) {
                await ProductPrintingTypeRefetch();
                setValue("panni_printing_type", ProductPrintingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPrintingTypeSuccess, ProductPrintingTypeData]);

    const apinameForStickPackingType = process.env.REACT_APP_STICK_PACKING_API?.toString()
    const { mutate: mutateStickPackingType, data: dataStickPackingType, isError: StickPackingTypeIsError, isLoading: StickPackingTypeLoading, isSuccess: StickPackingTypeSuccess, error: StickPackingTypeError }: any = useCreate(apinameForStickPackingType)

    const [valueStickPackingType, setValueStickPackingType]: any = useState();
    const [selectedStickPackingType, setSelectedStickPackingType]: any = useState('');
    const [optionsStickPackingType, setOptionsStickPackingType]: any = useState([]);
    const [valueStickPackingTypeCreatable, setValueStickPackingTypeCreatable] = useState<Option | null>();

    const { data: StickPackingTypeData, refetch: StickPackingTypeRefetch } = useFetchStickDropDown()

    useEffect(() => {
        setOptionsStickPackingType(StickPackingTypeData)
    }, [StickPackingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            StickPackingTypeRefetch()
        }
    }, [])


    const handleChangeStickPackingType = (e: any) => {

        StickPackingTypeRefetch()

        setApiName(apinameForStickPackingType)
        setDeleteApiName(apinameForStickPackingType)
        setEditId(e?.value)
        setEditData(StickPackingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueStickPackingType(e)

        setValue("stick_packing_type", `${e?.value}`)
        setValueStickPackingTypeCreatable(null)
    }





    const handleCreateStickPackingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateStickPackingType({ name: newOption.name, status: newOption.status })
            setOptionsStickPackingType((prev: any) => [...prev, newOption]);
            setValueStickPackingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (StickPackingTypeSuccess && optionsStickPackingType) {
                await StickPackingTypeRefetch();
                setValue("stick_packing_type", StickPackingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [StickPackingTypeSuccess, StickPackingTypeData]);





    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    const [optionsUom, setOptionsUom]: any = useState()
    const [selUom_1, setselUom_1]: any = useState('')
    const [selUom_2, setselUom_2]: any = useState('')
    const [selectedUom_1, setselectedUom_1]: any = useState('')
    const [selectedUom_2, setselectedUom_2]: any = useState('')
    const [optionsProductUom, setOptionsProductUom]: any = useState()
    const [forUOMValue, setForUOMValue]: any = useState([])
    const [ForProductUOMValue, setForProductUOMValue]: any = useState([])
    const [openUOM, setOpenUOM] = useState(false)
    const [openProductUOM, setOpenProductUOM] = useState(false)

    useEffect(() => {
        setOptionsUom(UomData)
        setOptionsProductUom(UomData)
    }, [UomData])

    const handleChangeUom = (e: any) => {
        setselUom_1(e)
        setValue("uom_1", `${e.value}`)
        setForUOMValue(e)
        setForUOMValue({ label: e?.label, value: e?.value })

    }

    const handleChangeProductUom = (e: any) => {
        setselUom_2(e)
        setValue("uom_2", `${e.value}`)
        setForProductUOMValue(e)
        setForProductUOMValue({ label: e?.label, value: e?.value })

    }


    const [submittedUOM, setSubmittedUOM] = useState(false)


    useEffect(() => {
        if (submittedUOM) {
            setForUOMValue(optionsUom[0])

            setValue("uom_1", optionsUom[0]?.value)

        }

    }, [submittedUOM, optionsUom])
    const [submittedProductUOM, setSubmittedProductUOM] = useState(false)


    useEffect(() => {
        if (submittedProductUOM) {
            setForProductUOMValue(optionsProductUom[0])

            setValue("uom_2", optionsProductUom[0]?.value)

        }

    }, [submittedProductUOM, optionsProductUom])

    const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
    const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)

    const [valueProductBy, setValueProductBy]: any = useState();
    const [selectedProductBy, setSelectedProductBy]: any = useState();
    const [optionsProductBy, setOptionsProductBy]: any = useState([]);
    const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

    const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

    useEffect(() => {
        setOptionsProductBy(ProductByData)
    }, [ProductByData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductByRefetch()
        }
    }, [])


    const handleChangeProductBy = (e: any) => {


        ProductByRefetch()

        setApiName(apinameForProductBy)
        setDeleteApiName(apinameForProductBy)
        setEditId(e?.value)
        setEditData(ProductByData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductBy(e)

        setValue("product_by", `${e?.value}`)
        setValueProductByCreatable(null)
    }





    const handleCreateProductBy = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductBy({ name: newOption.name, status: newOption.status })
            setOptionsProductBy((prev: any) => [...prev, newOption]);
            setValueProductByCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductBySuccess && optionsProductBy) {
                await ProductByRefetch();
                setValue("product_by", ProductByData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductBySuccess, ProductByData]);


    const apinameForMaterialUse = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateMaterialUse, data: dataMaterialUse, isError: MaterialUseIsError, isLoading: MaterialUseLoading, isSuccess: MaterialUseSuccess, error: MaterialUseError }: any = useCreate(apinameForMaterialUse)

    const [valueMaterialUse, setValueMaterialUse]: any = useState();
    const [selectedMaterialUse, setSelectedMaterialUse]: any = useState('');
    const [optionsMaterialUse, setOptionsMaterialUse]: any = useState([]);
    const [valueMaterialUseCreatable, setValueMaterialUseCreatable] = useState<Option | null>();

    const { data: MaterialUseData, refetch: MaterialUseRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialUse(MaterialUseData)
    }, [MaterialUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeMaterialUse = (e: any) => {

        MaterialUseRefetch()

        setApiName(apinameForMaterialUse)
        setDeleteApiName(apinameForMaterialUse)
        setEditId(e?.value)
        setEditData(MaterialUseData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialUse(e)

        setValue("product_special_running", `${e?.value}`)
        setValueMaterialUseCreatable(null)
    }





    const handleCreateMaterialUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialUse({ name: newOption.name, status: newOption.status })
            setOptionsMaterialUse((prev: any) => [...prev, newOption]);
            setValueMaterialUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialUseSuccess && optionsMaterialUse) {
                await MaterialUseRefetch();
                setValue("product_special_running", MaterialUseData[0].value)
            }
        })();

        return () => {
        };
    }, [MaterialUseSuccess, MaterialUseData]);


    const apinameForProductLocation = process.env.REACT_APP_PRODUCT_LOCATION_API?.toString()
    const { mutate: mutateProductLocation, data: dataProductLocation, isError: ProductLocationIsError, isLoading: ProductLocationLoading, isSuccess: ProductLocationSuccess, error: ProductLocationError }: any = useCreate(apinameForProductLocation)

    const [valueProductLocation, setValueProductLocation]: any = useState();
    const [selectedProductLocation, setSelectedProductLocation]: any = useState('');
    const [optionsProductLocation, setOptionsProductLocation]: any = useState([]);
    const [valueProductLocationCreatable, setValueProductLocationCreatable] = useState<Option | null>();

    const { data: ProductLocationData, refetch: ProductLocationRefetch } = useFetchProductLocationDropDown()

    useEffect(() => {
        setOptionsProductLocation(ProductLocationData)
    }, [ProductLocationData])





    const handleChangeProductLocation = (e: any) => {

        ProductLocationRefetch()

        setApiName(apinameForProductLocation)
        setDeleteApiName(apinameForProductLocation)
        setEditId(e?.value)
        setEditData(ProductLocationData)
        setValueProductLocation(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("product_location", `${e?.value}`)
        setValueProductLocationCreatable(null)
    }





    const handleCreateProductLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateProductLocation({ name: newOption.name, status: newOption.status })
            setOptionsProductLocation((prev: any) => [...prev, newOption]);
            setValueProductLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductLocationSuccess && optionsProductLocation) {
                await ProductLocationRefetch();
                setValue("product_location", ProductLocationData[0]?.value)
            }

        })();

        return () => {
        };
    }, [ProductLocationSuccess, ProductLocationData]);

    const apinameForProductPackingType = process.env.REACT_APP_PANNI_PACKET_PAKING_TYPE_API?.toString()
    const { mutate: mutateProductPackingType, data: dataProductPackingType, isError: ProductPackingTypeIsError, isLoading: ProductPackingTypeLoading, isSuccess: ProductPackingTypeSuccess, error: ProductPackingTypeError }: any = useCreate(apinameForProductPackingType)

    const [valueProductPackingType, setValueProductPackingType]: any = useState();
    const [selectedProductPackingType, setSelectedProductPackingType]: any = useState('');
    const [optionsProductPackingType, setOptionsProductPackingType]: any = useState([]);
    const [valueProductPackingTypeCreatable, setValueProductPackingTypeCreatable] = useState<Option | null>();

    const { data: ProductPackingTypeData, refetch: ProductPackingTypeRefetch } = useFetchPacketPakingDropDown()

    useEffect(() => {
        setOptionsProductPackingType(ProductPackingTypeData)
    }, [ProductPackingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPackingTypeRefetch()
        }
    }, [])


    const handleChangeProductPackingType = (e: any) => {

        ProductPackingTypeRefetch()

        setApiName(apinameForProductPackingType)
        setDeleteApiName(apinameForProductPackingType)
        setEditId(e?.value)
        setEditData(ProductPackingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPackingType(e)
        setValueProductPackingTypeCreatable(e)
        setValue("packet_packing_type", `${e?.value}`)
        setValueProductPackingTypeCreatable(null)
    }





    const handleCreateProductPackingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPackingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPackingType((prev: any) => [...prev, newOption]);
            setValueProductPackingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPackingTypeSuccess && optionsProductPackingType) {
                await ProductPackingTypeRefetch();
                setValue("packet_packing_type", ProductPackingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPackingTypeSuccess, ProductPackingTypeData]);
    const apinameForPackingCoverDori = process.env.REACT_APP_DISPOSABLE_PRODUCT_PACKING_COVER_DORI_API?.toString()
    const { mutate: mutatePackingCoverDori, data: dataPackingCoverDori, isError: PackingCoverDoriIsError, isLoading: PackingCoverDoriLoading, isSuccess: PackingCoverDoriSuccess, error: PackingCoverDoriError }: any = useCreate(apinameForPackingCoverDori)

    const [valuePackingCoverDori, setValuePackingCoverDori]: any = useState();
    const [selectedPackingCoverDori, setSelectedPackingCoverDori]: any = useState('');
    const [optionsPackingCoverDori, setOptionsPackingCoverDori]: any = useState([]);
    const [valuePackingCoverDoriCreatable, setValuePackingCoverDoriCreatable] = useState<Option | null>();

    const { data: PackingCoverDoriData, refetch: PackingCoverDoriRefetch } = useFetchPackingCoverDoriDropDown()

    useEffect(() => {
        setOptionsPackingCoverDori(PackingCoverDoriData)
    }, [PackingCoverDoriData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            PackingCoverDoriRefetch()
        }
    }, [])


    const handleChangePackingCoverDori = (e: any) => {

        PackingCoverDoriRefetch()

        setApiName(apinameForPackingCoverDori)
        setDeleteApiName(apinameForPackingCoverDori)
        setEditId(e?.value)
        setEditData(PackingCoverDoriData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePackingCoverDori(e)
        setValuePackingCoverDoriCreatable(e)
        setValue("packing_cover_dori", `${e?.value}`)
        setValuePackingCoverDoriCreatable(null)
    }





    const handleCreatePackingCoverDori = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePackingCoverDori({ name: newOption.name, status: newOption.status })
            setOptionsPackingCoverDori((prev: any) => [...prev, newOption]);
            setValuePackingCoverDoriCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (PackingCoverDoriSuccess && optionsPackingCoverDori) {
                await PackingCoverDoriRefetch();
                setValue("packing_cover_dori", PackingCoverDoriData[0]?.value)
            }
        })();

        return () => {
        };
    }, [PackingCoverDoriSuccess, PackingCoverDoriData]);

    const apinameForProductPakingType = process.env.REACT_APP_PANNI_PACKING_TYPE_API?.toString()
    const { mutate: mutateProductPakingType, data: dataProductPakingType, isError: ProductPakingTypeIsError, isLoading: ProductPakingTypeLoading, isSuccess: ProductPakingTypeSuccess, error: ProductPakingTypeError }: any = useCreate(apinameForProductPakingType)

    const [valueProductPakingType, setValueProductPakingType]: any = useState();
    const [selectedProductPakingType, setSelectedProductPakingType]: any = useState('');
    const [optionsProductPakingType, setOptionsProductPakingType]: any = useState([]);
    const [valueProductPakingTypeCreatable, setValueProductPakingTypeCreatable] = useState<Option | null>();

    const { data: ProductPakingTypeData, refetch: ProductPakingTypeRefetch } = useFetchPanniProductPakingTypeDropDown()

    useEffect(() => {
        setOptionsProductPakingType(ProductPakingTypeData)
    }, [ProductPakingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPakingTypeRefetch()
        }
    }, [])


    const handleChangeProductPakingType = (e: any) => {

        ProductPakingTypeRefetch()

        setApiName(apinameForProductPakingType)
        setDeleteApiName(apinameForProductPakingType)
        setEditId(e?.value)
        setEditData(ProductPakingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPakingType(e)

        setValue("packing_type", `${e?.value}`)
        setValueProductPakingTypeCreatable(null)
    }





    const handleCreateProductPakingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPakingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPakingType((prev: any) => [...prev, newOption]);
            setValueProductPakingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPakingTypeSuccess && optionsProductPakingType) {
                await ProductPakingTypeRefetch();
                setValue("packing_type", ProductPakingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPakingTypeSuccess, ProductPakingTypeData]);



    const [packet_packing_type, setPacket_packing_type]: any = useState()
    const [ProductQty, setProductQty]: any = useState()

    useEffect(() => {
        if (valueProductPackingTypeCreatable) {
            setPacket_packing_type(valueProductPackingTypeCreatable?.label)
        } else {
            setPacket_packing_type(valueProductPackingType?.label)
        }
    }, [valueProductPackingTypeCreatable, valueProductPackingType])

    const [ProductPackingtotal, setProductPackingtotal]: any = useState()

    useEffect(() => {
        setProductPackingtotal(Number(packet_packing_type) * Number(ProductQty))
        setValue("product_total_packing_kg", ProductPackingtotal)

    }, [ProductPackingtotal, packet_packing_type, ProductQty])

    const onProductQtyChange = (e: any) => {
        setProductQty(e.target.value)
    }

    const onSubmit = (pannidetail: any) => {
        if (!logo) {
            delete pannidetail.disposable_product_image
        }
        if (!logoPdf) {
            delete pannidetail.disposable_product_drawing
        }
        if (piceCode) {
            delete pannidetail.product_price_list_one_piece_weight
            delete pannidetail.product_1_pcs_weight
            delete pannidetail.mould_shape
            delete pannidetail.price_list_mould_diameter_mm
            delete pannidetail.production_mould_diameter_mm
            delete pannidetail.production_mould_width_mm
            delete pannidetail.production_mould_length_mm
            delete pannidetail.product_final_height_mm
            delete pannidetail.product_current_height_mm
            delete pannidetail.product_lip
            delete pannidetail.product_ml_price_list
            delete pannidetail.product_actual_ml
            delete pannidetail.mould

            delete pannidetail.product_grade_name
            delete pannidetail.material_type
            delete pannidetail.company_color_name
            delete pannidetail.product_width_mm
            delete pannidetail.product_length_mm
            delete pannidetail.thickness_micron
            delete pannidetail.thickness_gauge
            delete pannidetail.gadget_yes_no
            delete pannidetail.gadget_size_mm
            delete pannidetail.product_kan_yes_no
            delete pannidetail.product_kan_width_mm
            delete pannidetail.product_kan_length_mm
            delete pannidetail.product_sealing_type
            delete pannidetail.product_ml
            delete pannidetail.product_ml_actual
            delete pannidetail.product_bursting_strength
            delete pannidetail.product_our_other
            delete pannidetail.product_special_running_type
        }
        if (panniPrinting === "no" || panniPrinting === "") {
            delete pannidetail.panni_printing_type
            delete pannidetail.panni_printing_total_color
            delete pannidetail.panni_printing_color_name
            delete pannidetail.product_brand
            delete pannidetail.our_product_brand
        }
        if (SelProductTypeLabel !== "Disposable") {
            if (panniPrinting === "no" || panniPrinting === "") {
                delete pannidetail.panni_printing_type
                delete pannidetail.panni_printing_total_color
                delete pannidetail.panni_printing_color_name
                delete pannidetail.product_brand
                delete pannidetail.our_product_brand
            }
            delete pannidetail.stick_packing_type
            // delete pannidetail.product_special_running
            delete pannidetail.product_by
        }
        if (SelProductTypeLabel === "Disposable") {
            delete pannidetail.packet_packing_type
            delete pannidetail.packing_cover_dori
            delete pannidetail.packing_type
            delete pannidetail.uom_1
            delete pannidetail.uom_2
        }

        if (forBoxPattiValue === undefined) {
            delete pannidetail.box_patti
        }
        if (colorNameStick === undefined) {
            delete pannidetail.carton_code_and_grade_and_name
        }
        if (valuePackingCoverDori === undefined) {
            delete pannidetail.packing_cover_dori
        }
        const formData = new FormData();

        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value
            if (key === "bori_and_carton_image") {
                logoId.map((old: any) => {
                    formData.append('bori_and_carton_images', old?.id)
                })
                for (let i = 0; i < boriAndCartonImage.length; i++) {

                    formData.append('bori_and_carton_image', boriAndCartonImage[i])
                }
            }
            if (key === "product_packet_packing_image") {
                ProductImageId.map((old: any) => {
                    formData.append('product_packet_packing_images', old?.id)
                })
                for (let i = 0; i < productPacketPacking.length; i++) {

                    formData.append('product_packet_packing_image', productPacketPacking[i])
                }
            }

            if (key === "product_image") {
                ProductDrawingId.map((old: any) => {
                    formData.append('product_images', old?.id)
                })
                for (let i = 0; i < productImage.length; i++) {

                    formData.append('product_image', productImage[i])
                }
            }
            if (key === "product_pdf_drawing") {
                logoImagesId.map((old: any) => {
                    formData.append('product_pdfs_drawing', old?.id)
                })
                for (let i = 0; i < productPDF.length; i++) {

                    formData.append('product_pdf_drawing', productPDF[i])
                }
            }
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "panni_printing_color_name") {
                value.forEach((val, index) => formData.append(`panni_printing_color_name`, val))
            }
            else if (key === "disposable_product_image" || key === "disposable_product_drawing") {
                if (typeof value === 'object' && value !== null) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listDisposableproducts', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, Updated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const { data: priceData, refetch: priceRefetch } =
        useFetchProductMLDropDown(searchTerm);

    useEffect(() => {
        setOptionsPriceList(priceData);
    }, [priceData]);

    const apinameForPrice = process.env.REACT_APP_PRICELIST_NAME_API?.toString();

    const {
        mutate: mutatePrice,
        data: dataPrice,
        isError: cpriceIsError,
        isLoading: priceLoading,
        isSuccess: priceSuccess,
        error: priceError,
    }: any = useCreate(apinameForPrice);

    const handleValue = (e: any) => {
        e.target.value = e.target.value
            .toLowerCase()
            .split(" ")
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
    };


    const [isLoadingValue, setIsLoadingValue]: any = useState();

    const [optionsPriceList, setOptionsPriceList]: any = useState([]);

    const [valuePriceCreatable, setValuePriceCreatable] =
        useState<Option | null>();

    const handleCreatePrice = (inputValue: string) => {

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePrice({
                ProductPriceListML: newOption.name,
                status: newOption.status,
            });
            setOptionsPriceList((prev: any) =>
                prev ? [...prev, newOption] : [newOption]
            );
            setValuePriceCreatable(newOption);
        }, 0);
    };





    useEffect(() => {

        (async () => {
            if (priceSuccess && optionsPriceList) {
                await priceRefetch();
                setValue("product_ml_price_list", priceData[0]?.value)
            }
        })();

        return () => {
        };
    }, [priceSuccess, priceData]);

    let ok = getValues("product_ml_price_list")









    useEffect(() => {
        if (piceCode) {
            setpanniGadget(piceCode?.gadget_yes_no)
            setpanniKan(piceCode?.product_kan_yes_no)
        }
    }, [piceCode])

    const handleChangePriceList = (e: any) => {
        setValue("product_ml_price_list", `${e?.value}`);
        setValuePriceCreatable(null);
    };




    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }

    const [optionsPrinting, setOptionsPrinting]: any = useState([])
    const [forColorsValue, setForColorsValue]: any = useState([])
    const [selColors, setSelColors]: any = useState([])
    const [selectedColors, setSelectedColors]: any = useState([])


    const { data: colorPrintingData, refetch: colorPrintingRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptionsPrinting(colorPrintingData)
    }, [colorPrintingData])

    const handleChangePrintingColor = (e: any) => {

        setSelColors(e)

        const color_name = e?.map((value: any) => {
            return value?.value
        })
        setValue("panni_printing_color_name", color_name)
        setValue("panni_printing_total_color", e.length)
        setForColorsValue(e)
    }




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForStickPackingType) {
            StickPackingTypeRefetch()
        }
        if (apiName === apinameForProductPrintingType) {
            ProductPrintingTypeRefetch()
        }
        if (apiName === apinameForProductBy) {
            ProductByRefetch()
        }
        if (apiName === apinameForProductPackingType) {
            ProductPackingTypeRefetch()
        }






        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueStickPackingType && apiName === apinameForStickPackingType) {
            setValueStickPackingType(null)
            setValueStickPackingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueStickPackingType && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPackingType && apiName === apinameForProductPackingType) {
            setValueProductPackingType(null)
            setValueProductPackingTypeCreatable(null)
        }









        if (deletesucess && apiName === apinameForStickPackingType) {
            setValueStickPackingType(null)
            setValueStickPackingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPackingType) {
            setValueProductPackingType(null)
            setValueProductPackingTypeCreatable(null)
        }




    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };



    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedProductType(data?.extra_response?.product_type?.id)
            setSelProductTypeLabel(data?.extra_response?.product_type?.name)
            setValue("product_type", data?.extra_response?.product_type?.id)
            setpanniPrinting(data?.product_printing)
            setValue("product_printing", data?.extra_response?.product_printing)
            setSelectedStickPackingType(data?.stick_packing_type)
            setValue('stick_packing_type', data?.stick_packing_type?.id)
            setSelectedProductPrintingType(data?.panni_printing_type?.id)
            setValue('panni_printing_type', data?.panni_printing_type?.id)
            setSelectedProductBy(data?.product_by?.id)
            setValue('product_by', data?.product_by?.id)
            setSelectedMaterialUse(data?.product_special_running?.id)
            setValue('product_special_running', data?.product_special_running?.id)
            setSelectedProductLocation(data?.product_location?.id)
            setValue('product_location', data?.product_location?.id)
            setSelectedProductPackingType(data?.packet_packing_type?.id)
            setValue('packet_packing_type', data?.packet_packing_type?.id)
            setSelectedProductPakingType(data?.packing_type?.id)
            setValue('packing_type', data?.packing_type?.id)
            setSelectedPackingCoverDori(data?.packing_cover_dori?.id)
            setValue('packing_cover_dori', data?.packing_cover_dori?.id)
            setSelectedPackingCoverCode(data?.product_packing_cover_code_name?.id)
            setValue("product_packing_cover_code_name", data?.product_packing_cover_code_name?.id)

            setSelectedPiceCode(data?.product_single_piece_code?.id)
            setValue('product_single_piece_code', data?.product_single_piece_code?.id)
            //Disposable
            setValue("product_price_list_one_piece_weight", data.product_single_piece_code?.price_list_1_pcs_weight)
            setValue("product_current_height_mm", data.product_single_piece_code?.product_current_height_mm)
            setValue("product_ml_price_list", data.product_single_piece_code?.product_ml_price_list?.name)
            setValue("product_actual_ml", data.product_single_piece_code?.product_actual_ml?.name)
            setValue("product_final_height_mm", data.product_single_piece_code?.product_final_height_mm)
            // setValue("product_lip",data.product_lip)
            setValue("product_1_pcs_weight", data.product_single_piece_code?.product_1_pcs_weight)
            setValue("price_list_mould_diameter_mm", data?.product_single_piece_code?.price_list_mould_diameter_mm?.name)
            setValue("production_mould_diameter_mm", data.product_single_piece_code?.extra_response?.mould_shape?.product_mould_diameter_mm)
            setValue("production_mould_length_mm", data.product_single_piece_code?.extra_response?.mould_shape?.product_length_diameter)
            setValue("production_mould_width_mm", data.product_single_piece_code?.extra_response?.mould_shape?.product_width_diameter)
            setValue("mould_shape", data?.product_single_piece_code?.extra_response?.mould_shape?.mould_shape_name)
            setValue("product_lip", data?.product_single_piece_code?.current_lip?.name)


            // LLDP LD Liner
            setValue('product_grade_name', `${data?.product_single_piece_code?.extra_response?.HSN_code?.material_type_name} ${data?.product_single_piece_code?.extra_response?.HSN_code?.particular_sector_name} ${data?.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}`)
            setValue('material_type', data?.product_single_piece_code?.material_type?.name)
            setValue('company_color_name', data?.product_single_piece_code?.extra_response?.company_color_name)
            setValue('product_width_mm', data?.product_single_piece_code?.product_width_mm)
            setValue('product_length_mm', data?.product_single_piece_code?.product_length_mm)
            setValue('thickness_micron', data?.product_single_piece_code?.thickness_micron)
            setValue('thickness_gauge', data?.product_single_piece_code?.thickness_gauge)
            setValue('gadget_yes_no', data?.product_single_piece_code?.gadget_yes_no)
            setValue('gadget_size_mm', data?.product_single_piece_code?.gadget_yes_no === 'no' ? '-' : data?.product_single_piece_code?.gadget_size_mm)
            setValue('product_kan_yes_no', data?.product_single_piece_code?.product_kan_yes_no)
            setValue('product_kan_width_mm', data?.product_single_piece_code?.product_kan_yes_no === 'no' ? "-" : data?.product_single_piece_code?.product_kan_width_mm)
            setValue('product_kan_length_mm', data?.product_single_piece_code?.product_kan_yes_no === 'no' ? "-" : data?.product_single_piece_code?.product_kan_length_mm)
            setValue('product_sealing_type', data?.product_single_piece_code?.product_sealing_type?.name)
            setValue('product_ml', data?.product_single_piece_code?.product_ml)
            setValue('product_ml_actual', data?.product_single_piece_code?.product_ml_actual)
            setValue('product_bursting_strength', data?.product_single_piece_code?.product_bursting_strength)
            setValue('product_our_other', data?.product_single_piece_code?.product_our_other?.name)
            setValue('product_special_running_type', data?.product_single_piece_code?.product_special_running_type?.name)


            let tempCartonPackingImage = data?.bori_and_carton_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductPacketPackingImage = data?.product_packet_packing_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductImage = data?.product_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductPdfDrawing = data?.product_pdf_drawing?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })




            setUploadCartonPackingImage(tempCartonPackingImage)
            setUploadProductPacketPacking(tempProductPacketPackingImage)
            setUploadProductImage(tempProductImage)
            setUploadProductPDF(tempProductPdfDrawing)
        }

    }, [data])




    const apinameProductType = process.env.REACT_APP_PRODUCT_TYPE_API?.toString()
    // const apinamePiceCode = process.env.REACT_APP_SINGLE_CODE_API?.toString()
    const apinameBrand = process.env.REACT_APP_BRAND_API?.toString()
    const apinamePanni = process.env.REACT_APP_PANNI_API?.toString()
    const apinameCarton = process.env.REACT_APP_CARTON_API?.toString()
    const apinameBoxPatti = process.env.REACT_APP_BOX_PATTI_API?.toString()
    const apinameTapeRoll = process.env.REACT_APP_TAPEROLL_API?.toString()
    const apinameColor = process.env.REACT_APP_COLOR_API?.toString()
    const apinameUom = process.env.REACT_APP_CAPACITY_API?.toString()

    useEffect(() => {

        if (data?.product_brand) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameBrand}/${data?.product_brand}`);
                    setSelectedBrand(response.data?.data?.id)
                    setValue('product_brand', response.data?.data?.id)
                    setValue("our_product_brand", response.data?.data?.type)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.panni_code_and_grade_name) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePanni}/${data?.panni_code_and_grade_name}`);
                    setSelectedpanni(response.data?.data?.id)
                    setValue('panni_code_and_grade_name', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.product_packing_cover_code_name) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePanni}/${data?.product_packing_cover_code_name}`);
                    setSelectedPackingCoverCode(response.data?.data?.id)
                    setValue('product_packing_cover_code_name', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.carton_code_and_grade_and_name) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCarton}/${data?.carton_code_and_grade_and_name}`);
                    setSelectedcarton(response.data?.data?.id)
                    setValue('carton_code_and_grade_and_name', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.box_patti) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameBoxPatti}/${data?.box_patti}`);
                    setSelectedBoxPatti(response.data?.data?.id)
                    setValue('box_patti', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.tape_roll_code_and_grade_name) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameTapeRoll}/${data?.tape_roll_code_and_grade_name}`);
                    setSelectedTapeRoll(response.data?.data?.id)
                    setValue('tape_roll_code_and_grade_name', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.uom_1) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameUom}/${data?.uom_1}`);
                    setselectedUom_1(response.data?.data?.id)
                    setValue('uom_1', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.uom_2) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameUom}/${data?.uom_2}`);
                    setselectedUom_2(response.data?.data?.id)
                    setValue('uom_2', response.data?.data?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }

        if (data?.panni_printing_color_name.length > 0) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameColor}/fetch_multiple_ids/`, {
                        ids: data?.panni_printing_color_name
                    });
                    setSelectedColors(response.data?.data?.map((e: any) => e?.id))
                    setValue('panni_printing_color_name', response.data?.data?.map((e: any) => e?.id))
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
    }, [data])



    const [packingStick, setPackingStick]: any = useState('')
    const [CartonStick, setCartonStick]: any = useState('')
    const [CartonTotalNos, setCartonTotalNos]: any = useState('')

    const handelChangePaking = (e: any) => {
        setPackingStick(e.target.value)
    }
    const handelChangeCartonStick = (e: any) => {
        setCartonStick(e.target.value)
    }
    useEffect(() => {
        setCartonTotalNos(Number(packingStick) * Number(CartonStick))
        setValue('carton_total_nos', Number(packingStick) * Number(CartonStick))
    }, [CartonTotalNos, packingStick, CartonStick])

    const [logoId, setLogoId] = useState([])
    const [logoImagesId, setLogoImagesId] = useState([])
    const [ProductImageId, setProductImageId] = useState([])
    const [ProductDrawingId, setProductDrawingId] = useState([])

    const [boriAndCartonImage, setBoriAndCartonImage] = useState([])
    const [uploadCartonPackingImage, setUploadCartonPackingImage] = useState<UploadFile[]>([])

    const [productPacketPacking, setProductPacketPacking] = useState([])
    const [uploadProductPacketPacking, setUploadProductPacketPacking] = useState<UploadFile[]>([])

    const [productImage, setProductImage]: any = useState([])
    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])

    const [productPDF, setProductPDF] = useState([])
    const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])

    const cartonPackingChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setLogoId(tempOldFiles)
        setBoriAndCartonImage(tempFiles)
        setUploadCartonPackingImage(e.fileList)

    }


    const ProductPacketPackingOnChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductImageId(tempOldFiles)
        setProductPacketPacking(tempFiles)
        setUploadProductPacketPacking(e.fileList)

    }

    const ProductImageOnChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductDrawingId(tempOldFiles)
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const ProductPDFOnChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setLogoImagesId(tempOldFiles)
        setProductPDF(tempFiles)
        setUploadProductPDF(e.fileList)

    }
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()



    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()


    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    return (
        <div >

            <div
                className="content-wrapper "
                style={{ overflow: "auto" }}
            >
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>All Product Code</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listDisposableProduct" className="text-decoration-none text-black">All Product Code</Link></li>
                                    <li className="breadcrumb-item active">Edit All Product Code</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}


                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                    Product Category <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_type", { required: { value: true, message: "Product Category is required" } })}
                                                        placeholder={'Select Product Category'}

                                                        components={customCreatableIndicator}
                                                        options={optionsProductType}
                                                        onMenuOpen={() => ProductTypeRefetch()}
                                                        onCreateOption={handleCreateProductType}
                                                        onChange={handleChangeProductType}
                                                        value={valueProductTypeCreatable == null ? valueProductType == null ? optionsProductType?.filter((obj: any) => obj.value === selectedProductType) : valueProductType : valueProductTypeCreatable}


                                                    />

                                                    <span className="text-red w-100">
                                                        {errors?.product_type?.message}
                                                    </span>
                                                </div>

                                                {/* {SelProductTypeLabel != "Disposable" || valueProductType?.label != "Disposable" || valueProductTypeCreatable?.label != "Disposable" &&  */}
                                                {SelProductTypeLabel != "Disposable" && (
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                                placeholder={'Single Piece Code'}
                                                                options={optionsPiceCode}
                                                                onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangePiceCode}
                                                                // components={customIndicator}
                                                                components={{ Option: CustomOptionDropDown, IndicatorsContainer: IndicatorsContainer }}
                                                                maxMenuHeight={130}
                                                                value={selPiceCode === "" ? optionsPiceCode?.find((obj: any) => obj.value === selectedPiceCode) : selPiceCode}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Grade & Name & Type
                                                            </label>
                                                            <input
                                                                {...register('product_grade_name', { required: { value: false } })}
                                                                placeholder=" Product Grade & Name & Type"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Material Type
                                                            </label>
                                                            <input
                                                                {...register('material_type', { required: { value: false } })}
                                                                placeholder=" Material Type"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Color Name
                                                            </label>
                                                            <input
                                                                {...register('company_color_name', { required: { value: false } })}
                                                                placeholder=" Product Color Name"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Width MM
                                                            </label>
                                                            <input
                                                                {...register('product_width_mm', { required: { value: false } })}
                                                                placeholder=" Product Width MM"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product length MM
                                                            </label>
                                                            <input
                                                                {...register('product_length_mm', { required: { value: false } })}
                                                                placeholder=" Product length MM"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Thickness Micron
                                                            </label>
                                                            <input
                                                                {...register('thickness_micron', { required: { value: false } })}
                                                                placeholder=" Thickness Micron"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Thickness Gauge
                                                            </label>
                                                            <input
                                                                {...register('thickness_gauge', { required: { value: false } })}
                                                                placeholder=" Thickness Gauge"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Silieing Type
                                                            </label>
                                                            <input
                                                                {...register('product_sealing_type', { required: { value: false } })}
                                                                placeholder=" Product Silieing Type "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Silieing Type
                                                                </label>
                                                                <input
                                                                placeholder=" Product Silieing Type "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                
                                                            </div> */}
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product ML Price List
                                                            </label>
                                                            <input
                                                                {...register('product_ml', { required: { value: false } })}
                                                                placeholder="Product ML Price List"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Actual ML
                                                            </label>
                                                            <input
                                                                {...register('product_ml_actual', { required: { value: false } })}
                                                                placeholder="Product Actual  ML "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Production 1 Piece Weight
                                                            </label>
                                                            <input
                                                                {...register('product_1_pcs_weight', { required: { value: false } })}
                                                                placeholder=" Product Production 1 Piece Weight"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Automatic 1 Piece Weight
                                                            </label>
                                                            <input
                                                                placeholder=" Product Automatic 1 Piece Weight"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packet Packing Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPackingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("packet_packing_type", { required: { value: true, message: "Product Packet Packing Type is required" } })}
                                                                placeholder={'Select Product Packet Packing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductPackingType}
                                                                onMenuOpen={() => ProductPackingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPackingType}
                                                                onChange={handleChangeProductPackingType}
                                                                // value={valueProductPackingTypeCreatable == null ? valueProductPackingType : valueProductPackingTypeCreatable}
                                                                value={valueProductPackingTypeCreatable == null ? valueProductPackingType == null ? optionsProductPackingType?.filter((obj: any) => obj.value === selectedProductPackingType) : valueProductPackingType : valueProductPackingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_Packet_Packing_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>U.O.M<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >

                                                                <Select
                                                                    {...register("uom_1", { required: { value: true, message: "U.O.M is required" } })}
                                                                    placeholder={'Select U.O.M'}
                                                                    onChange={handleChangeUom}
                                                                    options={optionsUom}
                                                                    // value={forUOMValue}
                                                                    value={selUom_1 === "" ? optionsUom?.find((obj: any) => obj.value === selectedUom_1) : selUom_1}
                                                                    components={customIndicator}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                        setOpenUOM(true)
                                                                    }}><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.UOM?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Packet Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("packet_qty", { onChange: onProductQtyChange, required: { value: false } })}
                                                                    placeholder=" Packet Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Total Packing Kg
                                                            </label>
                                                            <input
                                                                {...register("product_total_packing_kg", { required: { value: false } })}
                                                                placeholder=" Product Total Packing Kg"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>U.O.M<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >
                                                                <Select
                                                                    {...register("uom_2", { required: { value: true, message: "Product U.O.M is required" } })}
                                                                    placeholder={'Select U.O.M'}
                                                                    onChange={handleChangeProductUom}
                                                                    options={optionsProductUom}
                                                                    value={selUom_2 === "" ? optionsUom?.find((obj: any) => obj.value === selectedUom_2) : selUom_2}
                                                                    // value={ForProductUOMValue}
                                                                    components={customIndicator}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                        setOpenProductUOM(true)
                                                                    }}><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.UOM?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packing Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPakingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("packing_type", { required: { value: true, message: "Product Packing Type is required" } })}
                                                                placeholder={'Select Product Packing Type '}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductPakingType}
                                                                onMenuOpen={() => ProductPakingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPakingType}
                                                                onChange={handleChangeProductPakingType}
                                                                // value={valueProductPakingTypeCreatable == null ? valueProductPakingType : valueProductPakingTypeCreatable}
                                                                value={valueProductPakingTypeCreatable == null ? valueProductPakingType == null ? optionsProductPakingType?.filter((obj: any) => obj.value === selectedProductPakingType) : valueProductPakingType : valueProductPakingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_Packing_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Stock Minimum Kg
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_minimum_kg", { required: { value: false } })}
                                                                    placeholder=" Product Stock Minimum Kg"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Stock maximum Kg
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_maximum_kg", { required: { value: false } })}
                                                                    placeholder=" Product Stock maximum Kg"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Brusting Strenght
                                                            </label>
                                                            <input
                                                                {...register('product_bursting_strength', { required: { value: false } })}
                                                                placeholder=" Product Brusting Strenght "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Our Other
                                                            </label>
                                                            <input
                                                                {...register('product_our_other', { required: { value: false } })}
                                                                placeholder=" Product Our Other "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Type
                                                            </label>
                                                            <input
                                                                {...register('product_special_running_type', { required: { value: false } })}
                                                                placeholder=" Product Type "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div> */}

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueMaterialUse(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_special_running", { required: { value: true, message: "Material Use is required" } })}
                                                                placeholder={'Select Product Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsMaterialUse}
                                                                onCreateOption={handleCreateMaterialUse}
                                                                onChange={handleChangeMaterialUse}
                                                                onMenuOpen={() => MaterialUseRefetch()}
                                                                // value={valueMaterialUseCreatable == null ? valueMaterialUse : valueMaterialUseCreatable}
                                                                value={valueMaterialUseCreatable == null ? valueMaterialUse == null ? optionsMaterialUse?.filter((obj: any) => obj.value === selectedMaterialUse) : valueMaterialUse : valueMaterialUseCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Maximum Debit Day
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_debit_day", { required: { value: false } })}
                                                                    placeholder=" Product Maximum Debit Day"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product 1
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_1", { required: { value: false } })}
                                                                    placeholder="Product 1"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Location <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductLocation(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_location", { required: { value: true, message: "Product Location is required" } })}
                                                                placeholder={'Select Product Location'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductLocation}
                                                                onMenuOpen={() => ProductLocationRefetch()}
                                                                onCreateOption={handleCreateProductLocation}
                                                                onChange={handleChangeProductLocation}
                                                                // value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}
                                                                value={valueProductLocationCreatable == null ? valueProductLocation == null ? optionsProductLocation?.filter((obj: any) => obj.value === selectedProductLocation) : valueProductLocation : valueProductLocationCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_packet_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Day Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_packet_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Night Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Cartoon/Bori Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_carton_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Day Cartoon/Bori Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Cartoon/Bori Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_carton_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Night Cartoon/Bori Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Packing Cover Code & Name </label>
                                                            <Select
                                                                {...register("product_packing_cover_code_name", { required: { value: false, message: "Product Packing Cover Code & Name is required" } })}
                                                                placeholder={'Product Packing Cover Code & Name'}
                                                                options={optionspanni}
                                                                onChange={handleChangePackingCoverCode}
                                                                components={customIndicator}
                                                                // value={PanniValue}
                                                                value={selPackingCoverCode === "" ? optionspanni?.find((obj: any) => obj.value === selectedPackingCoverCode) : selPackingCoverCode}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_packing_cover_code_name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packing Cover Dori</label>
                                                            <CreatableSelect
                                                                // isClearable={() => {
                                                                //     setValuePackingCoverDori(null)
                                                                //     setValue(null)
                                                                // }}
                                                                {...register("packing_cover_dori", { required: { value: false, message: "Product Packing Cover Dori is required" } })}
                                                                placeholder={'Product Packing Cover Dori'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                // isDisabled={panniPrinting === 'no'}
                                                                options={optionsPackingCoverDori}
                                                                onMenuOpen={() => PackingCoverDoriRefetch()}
                                                                onCreateOption={handleCreatePackingCoverDori}
                                                                onChange={handleChangePackingCoverDori}
                                                                // value={valuePackingCoverDoriCreatable == null ? valuePackingCoverDori : valuePackingCoverDoriCreatable}
                                                                value={valuePackingCoverDoriCreatable == null ? valuePackingCoverDori == null ? optionsPackingCoverDori?.filter((obj: any) => obj.value === selectedPackingCoverDori) : valuePackingCoverDori : valuePackingCoverDoriCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.packing_cover_dori?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Carton Code & Grade & Name</label>
                                                            <Select
                                                                {...register("carton_code_and_grade_and_name", { required: { value: false, message: "Cartoon Code & Grade & Name is required" } })}
                                                                placeholder={'Cartoon Code & Grade & Name'}
                                                                options={optionscarton}
                                                                // onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangeCarton}
                                                                components={customIndicator}
                                                                value={selcarton === "" ? optionscarton?.find((obj: any) => obj.value === selectedcarton) : selcarton}

                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.Cartoon_Code_Grade_Name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Patti Code & Grade & Name</label>
                                                            <Select
                                                                {...register("box_patti", { required: { value: false, message: "Patti Code & Grade & Name is required" } })}
                                                                placeholder={'Patti Code & Grade & Name'}
                                                                options={optionsBoxpatti}
                                                                value={selBoxPatti === "" ? optionsBoxpatti?.find((obj: any) => obj.value === selectedBoxPatti) : selBoxPatti}
                                                                // value={forBoxPattiValue}
                                                                onChange={handleChangeBoxPatti}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.Patti_Code_Grade_Name?.message}</span>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <h4 className="mb-0 mt-2">Product Printing</h4>
                                                        </div>
                                                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Printing <span style={{ color: "red" }}>*</span></label>
                                                                        <select className="form-select" {...register("product_printing", { onChange: onPrintingChange, required: { value: false, message: "Product Printing is required" } })}>
                                                                            <option value={""} disabled selected>Select Any</option>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </select>
                                                                        <span className='text-red w-100'>{errors?.product_printing?.message}</span>
                                                                    </div>

                                                                    <div className="col-sm-3 mt-2">
                                                                        <label >Product Printing Printing Type {panniPrinting === 'yes' ? <span style={{ color: "red" }}>*</span> : ''}</label>
                                                                        <CreatableSelect
                                                                            isClearable={() => {
                                                                                setValueProductPrintingType(null)
                                                                                setValue(null)
                                                                            }}
                                                                            {...register("panni_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Printing Type is required" } })}
                                                                            placeholder={'Product printing Printing Type'}
                                                                            // menuIsOpen={true}
                                                                            components={customCreatableIndicator}
                                                                            isDisabled={panniPrinting === 'no'}
                                                                            options={optionsProductPrintingType}
                                                                            onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                                            onCreateOption={handleCreateProductPrintingType}
                                                                            onChange={handleChangeProductPrintingType}
                                                                            // value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}
                                                                            value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType == null ? optionsProductPrintingType?.filter((obj: any) => obj.value === selectedProductPrintingType) : valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                                        />
                                                                        <span className='text-red w-100'>{errors?.product_printing_type?.message}</span>
                                                                    </div>

                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Printing Color Name {panniPrinting === 'yes' ? <span style={{ color: "red" }}>*</span> : ''}</label>
                                                                        <Select
                                                                            {...register("panni_printing_color_name", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Color Name is required" } })}
                                                                            placeholder={'Product Printing Color Name'}
                                                                            isDisabled={panniPrinting === 'no'}
                                                                            options={optionsPrinting}
                                                                            value={selColors.length === 0 ? optionsPrinting?.filter((obj: any) => selectedColors?.includes(obj.value)) : selColors}
                                                                            // value={forColorsValue}
                                                                            onChange={handleChangePrintingColor}
                                                                            components={customIndicator}
                                                                            isMulti
                                                                        // maxMenuHeight={130}

                                                                        />
                                                                        <span className='text-red w-100'>{errors?.panni_printing_color_name?.message}</span>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Product Printing Total Color
                                                                        </label>
                                                                        <input
                                                                            {...register('panni_printing_total_color', { required: { value: false } })}
                                                                            placeholder=" Product Printing Total Color"
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputBrand"
                                                                            disabled
                                                                        // value={ColorTotal}
                                                                        // value={piceCode?.product_ml_actual}
                                                                        />

                                                                    </div>


                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Brand Name<span style={{ color: "red" }}>*</span></label>
                                                                        <div className="d-flex row" >
                                                                            <Select
                                                                                {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                                                placeholder={'Select Brand Name'}
                                                                                onChange={handleChangeBrand}
                                                                                options={optionsbrand}
                                                                                value={selBrand === "" ? optionsbrand?.find((obj: any) => obj.value === selectedBrand) : selBrand}
                                                                                // value={forBrandValue}
                                                                                components={customIndicator}
                                                                                isDisabled={panniPrinting === 'no'}
                                                                                // isOptionDisabled={(optionsbrand:any)=>
                                                                                //     {
                                                                                //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                                                //         return optionsbrand
                                                                                //         }
                                                                                //        }}
                                                                                className="dropdown-select"

                                                                            />
                                                                            <div className="input-group-append" style={{ width: 0, padding: 0 }} >
                                                                                <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                                    setBrandOpen(true)
                                                                                }} ><AiOutlinePlus /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <span className='text-red w-100'>{errors?.Product_Brand_Name?.message}</span>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Brand Our Other
                                                                        </label>
                                                                        <input
                                                                            {...register('our_product_brand')}
                                                                            placeholder="Brand Our Other"
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputBrand"
                                                                            disabled
                                                                        // value={piceCode?.product_1_pcs_weight}
                                                                        />

                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Product Print Width MM
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                {...register('product_print_width_mm', { required: { value: false } })}
                                                                                placeholder="Product Print Width MM "
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="exampleInputBrand"
                                                                                disabled={panniPrinting === 'no'}
                                                                            // value={piceCode?.product_1_pcs_weight}
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Product Print length MM
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                {...register('product_print_length_mm', { required: { value: false } })}
                                                                                placeholder="Product Print length MM "
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="exampleInputBrand"
                                                                                disabled={panniPrinting === 'no'}
                                                                            // value={piceCode?.product_1_pcs_weight}
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <h4 className="mb-0 mt-2">Product Gadget</h4>
                                                        </div>
                                                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Gadget Yes-No
                                                                        </label>
                                                                        <input
                                                                            {...register('gadget_yes_no', { required: { value: false } })}
                                                                            placeholder="Gadget Yes-No"
                                                                            type="text"
                                                                            className={`${styles.single_code} form-control`}
                                                                            id="exampleInputBrand"
                                                                            disabled
                                                                        // value={piceCode?.product_1_pcs_weight}
                                                                        />

                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Gadget Size MM
                                                                        </label>
                                                                        <input
                                                                            {...register('gadget_size_mm', { required: { value: false } })}
                                                                            placeholder=" Gadget Size MM "
                                                                            type="text"
                                                                            className={`${styles.single_code} form-control`}
                                                                            id="exampleInputBrand"
                                                                            disabled
                                                                        // value={piceCode?.product_1_pcs_weight}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {SelProductTypeLabel === "Shrink" && (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <h4 className="mb-0 mt-2">Product Kan</h4>
                                                                </div>
                                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-3 mt-2">
                                                                                <label htmlFor="exampleInputEmail1">
                                                                                    Product kan Yes-No
                                                                                </label>
                                                                                <input
                                                                                    {...register('product_kan_yes_no', { required: { value: false } })}
                                                                                    placeholder=" Product kan Yes-No "
                                                                                    type="text"
                                                                                    className={`${styles.single_code} form-control`}
                                                                                    id="exampleInputBrand"
                                                                                    disabled
                                                                                // value={piceCode?.product_1_pcs_weight}
                                                                                />

                                                                            </div>
                                                                            <div className="col-sm-3 mt-2">
                                                                                <label htmlFor="exampleInputEmail1">
                                                                                    Product kan Width MM
                                                                                </label>
                                                                                <input
                                                                                    {...register('product_kan_width_mm', { required: { value: false } })}
                                                                                    placeholder=" Product kan Width MM "
                                                                                    type="text"
                                                                                    className={`${styles.single_code} form-control`}
                                                                                    id="exampleInputBrand"
                                                                                    disabled
                                                                                // value={piceCode?.product_1_pcs_weight}
                                                                                />

                                                                            </div>
                                                                            <div className="col-sm-3 mt-2">
                                                                                <label htmlFor="exampleInputEmail1">
                                                                                    Product kan length MM
                                                                                </label>
                                                                                <input
                                                                                    {...register('product_kan_length_mm', { required: { value: false } })}
                                                                                    placeholder=" Product kan length MM "
                                                                                    type="text"
                                                                                    className={`${styles.single_code} form-control`}
                                                                                    id="exampleInputBrand"
                                                                                    disabled
                                                                                // value={piceCode?.product_1_pcs_weight}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}


                                                {SelProductTypeLabel === "Disposable" && (
                                                    // {valueProductType?.label === "Disposable" || valueProductTypeCreatable?.label === "Disposable"  ? (

                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                                placeholder={'Single Piece Code'}
                                                                options={optionsPiceCode}
                                                                onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangePiceCode}
                                                                components={customIndicator}
                                                                maxMenuHeight={130}
                                                                value={selPiceCode === "" ? optionsPiceCode?.find((obj: any) => obj.value === selectedPiceCode) : selPiceCode}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Price-List 1 Piece Weight </label>
                                                            <input
                                                                {...register("product_price_list_one_piece_weight")}
                                                                placeholder="Product Price-List 1 Piece Weight "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode.product_type}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Production 1 Piece Weight </label>
                                                            <input
                                                                {...register('product_1_pcs_weight')}
                                                                placeholder="Product Price-List 1 Piece Weight "
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode.product_type}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Mould Shape
                                                            </label>
                                                            <input
                                                                {...register("mould_shape")}
                                                                placeholder="Mould Shape"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Mould
                                                            </label>
                                                            <input
                                                                placeholder="Mould"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Price-List Mould Diameter mm
                                                            </label>
                                                            <input
                                                                {...register("price_list_mould_diameter_mm")}
                                                                placeholder="Price-List Mould Diameter mm"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Diameter mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_diameter_mm")}
                                                                placeholder="Price-List Mould Diameter mm"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Width mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_width_mm")}
                                                                placeholder="Production Mould Width mm"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Length mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_length_mm")}
                                                                placeholder=" Production Mould Length mm"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Final Height MM
                                                            </label>
                                                            <input
                                                                {...register("product_final_height_mm")}
                                                                placeholder="Product Final Height MM"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Currunt Height MM
                                                            </label>
                                                            <input
                                                                {...register("product_current_height_mm")}
                                                                placeholder=" Product Currunt Height MM"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Lip
                                                            </label>
                                                            <input
                                                                {...register("product_lip")}
                                                                placeholder="Product Lip"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product  Ml Price-List
                                                            </label>
                                                            <input
                                                                {...register("product_ml_price_list")}
                                                                placeholder="Product  Ml Price-List"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Ml Actual
                                                            </label>
                                                            <input
                                                                {...register("product_actual_ml")}

                                                                placeholder="Product  Ml Actual"
                                                                type="text"
                                                                className={`${styles.single_code} form-control`}
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label>Packing Material Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("panni_code_and_grade_name", { required: { value: true, message: "Packing Material Code & Grade & Name is required" } })}
                                                                placeholder={'Packing Material Code & Grade & Name'}
                                                                options={optionspanni}
                                                                onChange={handleChangePanni}
                                                                components={customIndicator}
                                                                // value={PanniValue}
                                                                value={selpanni === "" ? optionspanni?.find((obj: any) => obj.value === selectedpanni) : selpanni}

                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.panni_code_and_grade_name?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Carton Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("carton_code_and_grade_and_name", { required: { value: true, message: "Cartoon Code & Grade & Name is required" } })}
                                                                placeholder={'Cartoon Code & Grade & Name'}
                                                                options={optionscarton}
                                                                // onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangeCarton}
                                                                components={customIndicator}
                                                                // maxMenuHeight={130}
                                                                value={selcarton === "" ? optionscarton?.find((obj: any) => obj.value === selectedcarton) : selcarton}

                                                            />
                                                            <span className='text-red w-100'>{errors?.carton_code_and_grade_and_name?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Stick Packing Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueStickPackingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("stick_packing_type", { required: { value: true, message: "Stick Packing Type is required" } })}
                                                                placeholder={'Stick Packing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                // isDisabled={panniPrinting === 'no'}
                                                                options={optionsStickPackingType}
                                                                onMenuOpen={() => StickPackingTypeRefetch()}
                                                                onCreateOption={handleCreateStickPackingType}
                                                                onChange={handleChangeStickPackingType}
                                                                // value={valueStickPackingTypeCreatable == null ? valueStickPackingType : valueStickPackingTypeCreatable}
                                                                value={valueStickPackingTypeCreatable == null ? valueStickPackingType == null ? optionsStickPackingType?.filter((obj: any) => obj.value === selectedStickPackingType) : valueStickPackingType : valueStickPackingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.stick_packing_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Packing Stick Nos
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register('stick_nos', { onChange: handelChangePaking, required: { value: false, message: "Packing Stick Nos is required" } })}
                                                                    placeholder=" Packing Stick Nos"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Bundle Stick Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("stick_qty", { required: { value: false, message: "Bundle Stick Qty is required" } })}
                                                                    placeholder="Bundle Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton In Stick Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("carton_in_stick_packing", { onChange: handelChangeCartonStick, required: { value: false, message: "Cartoon In Stick Qty is required" } })}
                                                                    placeholder="Cartoon In Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton In Total Nos
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("carton_total_nos", { required: { value: false, message: "Carton In Stick Qty is required" } })}
                                                                    placeholder="Carton In Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    disabled
                                                                    value={CartonTotalNos}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Final Stick Hight MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_final_stick_height_mm")}
                                                                    placeholder="Product Final Stick Hight MM"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Current Stick Hight MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_current_stick_height_mm")}
                                                                    placeholder="Product Current Stick Hight MM"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Cartoon Stock Minimum Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_minimum_qty")}
                                                                    placeholder="Cartoon Stock Minimum Qty"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Cartoon Stock Maximum Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_maximum_qty")}
                                                                    placeholder="Cartoon Stock Maximum Qty"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product By <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductBy(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_by", { required: { value: true, message: "Product By is required" } })}
                                                                placeholder={'Select Product By '}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductBy}
                                                                onMenuOpen={() => ProductByRefetch()}
                                                                onCreateOption={handleCreateProductBy}
                                                                onChange={handleChangeProductBy}
                                                                // value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}
                                                                value={valueProductByCreatable == null ? valueProductBy == null ? optionsProductBy?.filter((obj: any) => obj.value === selectedProductBy) : valueProductBy : valueProductByCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_by?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueMaterialUse(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_special_running", { required: { value: true, message: "Material Use is required" } })}
                                                                placeholder={'Select Product Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsMaterialUse}
                                                                onCreateOption={handleCreateMaterialUse}
                                                                onChange={handleChangeMaterialUse}
                                                                onMenuOpen={() => MaterialUseRefetch()}
                                                                // value={valueMaterialUseCreatable == null ? valueMaterialUse : valueMaterialUseCreatable}
                                                                value={valueMaterialUseCreatable == null ? valueMaterialUse == null ? optionsMaterialUse?.filter((obj: any) => obj.value === selectedMaterialUse) : valueMaterialUse : valueMaterialUseCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Maximum Debit Day
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_debit_day")}
                                                                    placeholder=" Product Maximum Debit Day"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Location <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductLocation(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_location", { required: { value: true, message: "Product Location is required" } })}
                                                                placeholder={'Select Product Location'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductLocation}
                                                                onMenuOpen={() => ProductLocationRefetch()}
                                                                onCreateOption={handleCreateProductLocation}
                                                                onChange={handleChangeProductLocation}
                                                                // value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}
                                                                value={valueProductLocationCreatable == null ? valueProductLocation == null ? optionsProductLocation?.filter((obj: any) => obj.value === selectedProductLocation) : valueProductLocation : valueProductLocationCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_packet_packing_labour_rate")}
                                                                    placeholder="Day Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_packet_packing_labour_rate")}
                                                                    placeholder="Night Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Cartoon Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_carton_packing_labour_rate")}
                                                                    placeholder="Day Cartoon Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Cartoon Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_carton_packing_labour_rate")}
                                                                    placeholder="Night Cartoon Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Patti Code & Grade & Name</label>
                                                            <Select
                                                                {...register("box_patti", { required: { value: false, message: "Patti Code & Grade & Name is required" } })}
                                                                placeholder={'Patti Code & Grade & Name'}
                                                                options={optionsBoxpatti}
                                                                // value={forBoxPattiValue}
                                                                onChange={handleChangeBoxPatti}
                                                                components={customIndicator}
                                                                value={selBoxPatti === "" ? optionsBoxpatti?.find((obj: any) => obj.value === selectedBoxPatti) : selBoxPatti}

                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.box_patti?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Tap Roll Code & Grade & Name</label>
                                                            <Select
                                                                {...register("tape_roll_code_and_grade_name", { required: { value: false, message: "Tap Roll Code & Grade & Name is required" } })}
                                                                placeholder={'Tap Roll Code & Grade & Name'}
                                                                options={optionsTapeRoll}
                                                                // value={forTapeRollValue}
                                                                onChange={handleChangeTapeRoll}
                                                                components={customIndicator}
                                                                value={selTapeRoll === "" ? optionsTapeRoll?.find((obj: any) => obj.value === selectedTapeRoll) : selTapeRoll}

                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.tape_roll_code_and_grade_name?.message}</span>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <h4 className="mb-0 mt-2">Product Printing</h4>
                                                        </div>
                                                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Printing <span style={{ color: "red" }}>*</span></label>
                                                                        <select className="form-select" {...register("product_printing", { onChange: onPrintingChange, required: { value: false, message: "Product Printing is required" } })}>
                                                                            <option value={""} disabled selected>Select Any</option>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </select>
                                                                        <span className='text-red w-100'>{errors?.Product_Printing?.message}</span>
                                                                    </div>

                                                                    <div className="col-sm-3 mt-2">
                                                                        <label >Product Printing Printing Type {panniPrinting === 'yes' ? <span style={{ color: "red" }}>*</span> : ''}</label>
                                                                        <CreatableSelect
                                                                            isClearable={() => {
                                                                                setValueProductPrintingType(null)
                                                                                setValue(null)
                                                                            }}
                                                                            {...register("panni_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Printing Type is required" } })}
                                                                            placeholder={'Product printing Printing Type'}
                                                                            // menuIsOpen={true}
                                                                            components={customCreatableIndicator}
                                                                            isDisabled={panniPrinting === 'no'}
                                                                            options={optionsProductPrintingType}
                                                                            onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                                            onCreateOption={handleCreateProductPrintingType}
                                                                            onChange={handleChangeProductPrintingType}
                                                                            // value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}
                                                                            value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType == null ? optionsProductPrintingType?.filter((obj: any) => obj.value === selectedProductPrintingType) : valueProductPrintingType : valueProductPrintingTypeCreatable}


                                                                        />
                                                                        <span className='text-red w-100'>{errors?.panni_printing_type?.message}</span>
                                                                    </div>

                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Printing Color Name {panniPrinting === 'yes' ? <span style={{ color: "red" }}>*</span> : ''}</label>
                                                                        <Select
                                                                            {...register("panni_printing_color_name", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Color Name is required" } })}
                                                                            placeholder={'Product Printing Color Name'}
                                                                            isDisabled={panniPrinting === 'no'}
                                                                            options={optionsPrinting}
                                                                            // value={forColorsValue}
                                                                            value={selColors.length === 0 ? optionsPrinting?.filter((obj: any) => selectedColors?.includes(obj.value)) : selColors}
                                                                            onChange={handleChangePrintingColor}
                                                                            components={customIndicator}
                                                                            isMulti

                                                                        // maxMenuHeight={130}

                                                                        />
                                                                        <span className='text-red w-100'>{errors?.panni_printing_color_name?.message}</span>
                                                                    </div>

                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Product Printing Total Color
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                {...register('panni_printing_total_color', { required: { value: false } })}
                                                                                placeholder=" Product Printing Total Color"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="exampleInputBrand"
                                                                                disabled
                                                                            // value={ColorTotal}
                                                                            // value={piceCode?.product_ml_actual}
                                                                            />
                                                                            {/* <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label>Product Brand Name<span style={{ color: "red" }}>*</span></label>
                                                                        <div className="d-flex row" >
                                                                            <Select
                                                                                {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                                                placeholder={'Select Brand Name'}
                                                                                onChange={handleChangeBrand}
                                                                                options={optionsbrand}
                                                                                // value={forBrandValue}
                                                                                isDisabled={panniPrinting === 'no'}
                                                                                components={customIndicator}
                                                                                value={selBrand === "" ? optionsbrand?.find((obj: any) => obj.value === selectedBrand) : selBrand}
                                                                                // isOptionDisabled={(optionsbrand:any)=>
                                                                                //     {
                                                                                //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                                                //         return optionsbrand
                                                                                //         }
                                                                                //        }}
                                                                                className="dropdown-select"

                                                                            />
                                                                            <div className="input-group-append" style={{ width: 0, padding: 0 }} >
                                                                                <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                                    setBrandOpen(true)
                                                                                }} ><AiOutlinePlus /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <span className='text-red w-100'>{errors?.product_brand_name?.message}</span>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <label htmlFor="exampleInputEmail1">
                                                                            Brand Our Other
                                                                        </label>
                                                                        <input
                                                                            {...register('our_product_brand', { required: { value: false } })}
                                                                            placeholder="Brand Our Other"
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputBrand"
                                                                            disabled
                                                                        // value={piceCode?.product_1_pcs_weight}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    //     :
                                                    //     ""
                                                    // }
                                                )}





                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Remark
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            {...register("remark", { required: { value: false, message: "Remark is required" } })}
                                                            placeholder="Remark"
                                                            type="text"
                                                            className="form-control"
                                                            id="exampleInputBrand"

                                                        // value={piceCode?.product_ml_actual}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label>Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Product Code
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            {...register("product_code")}
                                                            placeholder="Product Code"
                                                            type="text"
                                                            disabled
                                                            className="form-control"
                                                            id="exampleInputBrand"

                                                        // value={piceCode?.product_ml_actual}
                                                        />

                                                    </div>
                                                </div>
                                                {/* {(prductTypeValue != null && prductCategoryValue.label == "Liner") ? */}
                                                {SelProductTypeLabel ?


                                                    <div className="row d-flex">
                                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bori & Cartoon Image All </label>

                                                                    {uploadCartonPackingImage?.length > 0 ?

                                                                        <>
                                                                            <Upload
                                                                                {...register("bori_and_carton_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={cartonPackingChange}
                                                                                multiple={true}
                                                                                defaultFileList={[...uploadCartonPackingImage]}
                                                                                onPreview={(e: any) => { return "" }}
                                                                                key={"image"}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </> :
                                                                        <>
                                                                            <Upload
                                                                                {...register("bori_and_carton_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={cartonPackingChange}
                                                                                multiple={true}
                                                                                onPreview={(e: any) => { return "" }}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Packet Packing Image All</label>

                                                                    {uploadProductPacketPacking?.length > 0 ?

                                                                        <>
                                                                            <Upload
                                                                                {...register("product_packet_packing_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={ProductPacketPackingOnChange}
                                                                                multiple={true}
                                                                                defaultFileList={[...uploadProductPacketPacking]}
                                                                                onPreview={(e: any) => { return "" }}
                                                                                key={"image"}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </> :
                                                                        <>
                                                                            <Upload
                                                                                {...register("product_packet_packing_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={ProductPacketPackingOnChange}
                                                                                multiple={true}
                                                                                onPreview={(e: any) => { return "" }}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>

                                                                    {uploadProductImage?.length > 0 ?

                                                                        <>
                                                                            <Upload
                                                                                {...register("product_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={ProductImageOnChange}
                                                                                multiple={true}
                                                                                defaultFileList={[...uploadProductImage]}
                                                                                onPreview={(e: any) => { return "" }}
                                                                                key={"image"}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </> :
                                                                        <>
                                                                            <Upload
                                                                                {...register("product_image")}
                                                                                accept="image/png, image/jpeg"
                                                                                listType="picture"
                                                                                onChange={ProductImageOnChange}
                                                                                multiple={true}
                                                                                onPreview={(e: any) => { return "" }}

                                                                            >
                                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                            </Upload>
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>

                                                                    {uploadProductPDF?.length > 0 ?
                                                                        <>
                                                                            <Upload
                                                                                {...register("product_pdf_drawing")}
                                                                                accept=".doc,.docx,.xml,.pdf"
                                                                                listType="text"
                                                                                onChange={ProductPDFOnChange}
                                                                                multiple={true}
                                                                                defaultFileList={[...uploadProductPDF]}
                                                                                onPreview={(e: any) => { return "" }}
                                                                                key={"pdf"}
                                                                                style={{ color: 'black' }}
                                                                            >
                                                                                <img src={pdfLogo} alt='' className="uploaded-image" />

                                                                            </Upload>
                                                                        </> : <>
                                                                            <Upload
                                                                                {...register("product_pdf_drawing")}
                                                                                accept=".doc,.docx,.xml,.pdf"
                                                                                listType="text"
                                                                                onChange={ProductPDFOnChange}
                                                                                multiple={true}
                                                                                onPreview={(e: any) => { return "" }}
                                                                                style={{ color: 'black' }}
                                                                            >
                                                                                <img src={pdfLogo} alt='' className="uploaded-image" />

                                                                            </Upload>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>



                                                    : " "}













                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button"
                                                        className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "rgb(0, 54, 101)",
                                                            background: "rgb(0, 54, 101)",
                                                            color: "white",
                                                        }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={isLoading}
                                                    >
                                                        Submit
                                                    </button>
                                                    {/* <button
                                                        type="button"
                                                        className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "blue",
                                                            background: "blue",
                                                            color: "white",
                                                        }}
                                                        onClick={handleReset}
                                                    >
                                                        Reset
                                                    </button> */}

                                                    <button
                                                        type="button"
                                                        className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                                                        style={{
                                                            borderColor: "red",
                                                            background: "red",
                                                            color: "white",
                                                        }}
                                                        onClick={() => navigate("/listDisposableproducts")}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div >
                    </div >
                </section >

            </div >
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <UOM_Model refetchForm={UomRefetch} open={openUOM} setOpen={setOpenUOM} setSubmitted={setSubmittedUOM} />
            <UOM_Model refetchForm={UomRefetch} open={openProductUOM} setOpen={setOpenProductUOM} setSubmitted={setSubmittedProductUOM} />
            {/* <Footer /> */}
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div >
    )
}

export default Edit_DisposableProduct