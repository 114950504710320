
import { useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"



interface Data {
    selection: any

//     old_carton_basic_rate: any
//   old_carton_rate: any
//   old_stick_rate: any
//   old_one_kg_rate: any
//   product_code: any
//   product_name_and_grade_and_type: any
//   show_on_off: any
//   filter_right_click: any
//   change_stick_rate: any
//   change_carton_rate: any
//   change_one_kg_rate: any
//   change_rate: any
//   change_carton_basic_rate: any
//   gst_change_amount: any
//   gst_rate: any
//   carton_basic_rate2: any
//   gst_amount: any
//   change_carton_rate2: any
//   difference_rate: any
//   new_carton_basic_rate: any
//   new_carton_rate: any
//   new_stick_rate: any
//   new_one_kg_rate: any
//   product_weight_price_list: any
//   stick_nos: any
//   carton_stick: any
//   product_total_qty: any
//   unit_of_measure: any
//   carton_weight: any
//   price_list_mould_size_mm: any
//   production_mould_size_mm: any
//   ml_price_list: any
//   product_color: any  
//   product_brand: any
//   panni_color:any
//   date: any,


code:"",
    name:"",
    grade:"",
    // product_type:"",
    // price_list_type:"",
    price:"",
    rate_type:"",
    rate:"",
    rate_change_date:"",
    


    updated_by: any
    created_by: any
    created_at: any
    updated_at: any
    status: any
    actions: any
}


export const titleofheaders=[
    {
      id: `srno`,
      Header: `Sr No`,
    },
    {
        id: `code`,
        Header: `Code`,
      },
      {
        id: `name`,
        Header: `Name`,
      },
      {
        id: `grade`,
        Header: `Grade`,
      },
    //   {
    //     id: `product_type`,
    //     Header: `Product Type`,
    //   },
    //   {
    //     id: `product_list_type`,
    //     Header: `Product List Type`,
    //   },
      {
        id: `price`,
        Header: `Price`,
      },
      {
        id: `rate_type`,
        Header: `Rate Type`,
      },
      {
        id: `rate`,
        Header: `Rate`,
      },
      {
        id: `rate_change_date`,
        Header: `Rate Change Date`,
      },
    // {
    //     id: `old_carton_basic_rate`,
    //     Header: "Old CartonBasic Rate",
       
    // },
    // {
    //     id: `old_carton_rate`,
    //     Header: "Old CartonRate",
        
    // },
    // {
    //     id: `old_stick_rate`,
    //     Header:" Old StickRate",
        
    // },
    // {
    //     id: `old_one_kg_rate`,
    //     Header: "Old 1 KgRate",
       
    // },
    // {
    //     id: `product_code`,
    //     Header: "Product Code",
      
    // },
    // {
    //     id: `product_name_and_grade_and_type`,
    //     Header: "Product Grade,Name & Type",
      
    // },
    // {
    //     id: `show_on_off`,
    //     Header: "ShowOn-Off",
       
    // },
    // {
    //     id: `change_carton_basic_rate`,
    //     Header: "Change CartonBasic Rate",
       
    // },
    // {
    //     id: `change_carton_rate`,
    //     Header: "Change CartonRate",
       
    // },
    // {
    //     id: `change_stick_rate`,
    //     Header: "Change StickRate",
        
    // },
    // {
    //     id: `change_one_kg_rate`,
    //     Header: "Change 1 KGRate",
       
    // },
    // {
    //     id: `change_rate`,
    //     Header:" % Rate",
       
    // },
    // {
    //     id: `gst_change_amount`,
    //     Header: "GST ChangeAmount",
       
    // },
    // {
    //     id: `gst_rate`,
    //     Header: "GSTRate %",
    
    // },
    // {
    //     id: `gst_amount`,
    //     Header: "GST Amount",
       
    // },
    // {
    //     id: `difference_rate`,
    //     Header: "Difference Rate",
      
    // },

    // {
    //     id: `new_carton_basic_rate`,
    //     Header: "New CartonBasic Rate",
        
    // },
    // {
    //     id: `new_carton_rate`,
    //     Header: "New CartonRate",
     
    // },
    // {
    //     id: `new_stick_rate`,
    //     Header: "New StickRate",
      
    // },
    // {
    //     id: `new_one_kg_rate`,
    //     Header: "New 1 KGRate",
       
    // },
    // {
    //     id: `date`,
    //     Header: "Company StartingDate",
        
    // },
    // {
    //     id: `product_weight_price_list`,
    //     Header: "Product WeightPrice List",
       
    // },
    // {
    //     id: `stick_nos`,
    //     Header: "StickNos",
        
    // },
    // {
    //     id: `carton_stick`,
    //     Header: "CartonStick",
       
    // },
    // {
    //     id: `product_total_qty`,
    //     Header: "Product Total Qty",
        
    // },
    // {
    //     id: `unit_of_measure`,
    //     Header: "UOM",
       
    // },
    // {
    //     id: `carton_weight`,
    //     Header: "CartonWeight",
        
    // },
    // {
    //     id: `price_list_mould_size_mm`,
    //     Header: "Price List Mould Size(MM)",
       
    // },
    // {
    //     id: `production_mould_size_mm`,
    //     Header: "ProductionMould Size(MM)",
       
    // },
    // {
    //     id: `ml_price_list`,
    //     Header:" ML PriceList",
       
    // },
    // {
    //     id: `product_color`,
    //     Header: "Product Color",
     
    // },
    // {
    //     id: `product_brand`,
    //     Header: "Product Brand",

    // },
    // {
    //     id: `panni_color`,
    //     Header: "Panni Color",
   
       
    // },
   
    {
      id: 'status',
      Header: 'Status',
    },
    {
        id: 'created_at',
        Header: `Created At`,
      },
    
    
      {
        id: 'created_by',
        Header: `Created By`,
    
     
      },
      {
        id: 'updated_at',
        Header: `Updated At`,
    
      },
      {
        id: 'updated_by',
        Header: `Updated By`,
    
      },
  ]





export const Columns_PriceList: any = [



    
    {
        id: `srno`,
        Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
        sticky:"left",
        accessor:'old_stick_rate',
    },

    {
        id: `code`,
        Header: `Code`,
        sticky:"left",
        accessor:'code',
        Cell: ({ row }: any) => {
                     return(
                 (row.original?.code)
                     )
                     }
      },
      {
        id: `name`,
        Header: `Name`,
        accessor:'name',
      },
      {
        id: `grade`,
        Header: `Grade`,
        accessor:'grade',
      },
    //   {
    //     id: `product_type`,
    //     Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,
    //     accessor:'product_type',
    //   },
    //   {
    //     id: `product_list_type`,
    //     Header: () => <div style={{display:"inline"}}>Product List<br/>Type</div>,
    //     accessor:'product_list_type',
    //   },
      {
        id: `price`,
        Header: `Price`,
        accessor:'price',
      },
      {
        id: `rate_type`,
        Header: () => <div style={{display:"inline"}}>Rate<br/>Type</div>,
        accessor:'rate_type',
      },
      {
        id: `rate`,
        Header: `Rate`,
        accessor:'rate',
      },
      {
        id: `rate_change_date`,
        Header: () => <div style={{display:"inline"}}>Rate Change<br/>Date</div>,
        accessor:'rate_change_date',
      },

    // {
    //     id: `old_carton_basic_rate`,
    //     Header: () => <div style={{display:"inline"}}>Old Carton<br/>Basic Rate</div>,
    //     accessor:'old_carton_basic_rate',
    //     sticky:"left",
    //     Cell: ({ row }: any) => {
    //         let old_carton_basic_rate = 10
    //         row.original.old_carton_basic_rate = old_carton_basic_rate
    //         return(
    //     (row.original?.old_carton_basic_rate)
    //         )
    //         }
    // },
    // {
    //     id: `old_carton_rate`,
    //     Header: () => <div style={{display:"inline"}}>Old Carton<br/>Rate</div>,
    //     accessor:'old_carton_rate',
    //     Cell: ({ row }: any) => {
    //         let old_carton_rate = 10
    //         row.original.old_carton_rate = old_carton_rate
    //         return(
    //     (row.original?.old_carton_rate)
    //         )
    //         }
    // },
    // {
    //     id: `old_stick_rate`,
    //     Header: () => <div style={{display:"inline"}}>Old Stick<br/>Rate</div>,
    //     accessor:'old_stick_rate',
    // },
    // {
    //     id: `old_one_kg_rate`,
    //     Header: () => <div style={{display:"inline"}}>Old 1 Kg<br/>Rate</div>,
    //     accessor:'old_one_kg_rate',
    // },
    // {
    //     id: `product_code`,
    //     Header: () => <div style={{display:"inline"}}>Product<br/>Code</div>,
    //     accessor:'product_code',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.product_code)
    //         )
    //         }
    // },
    // {
    //     id: `product_name_and_grade_and_type`,
    //     Header: () => <div style={{display:"inline"}}>Product Grade,<br/>Name & Type</div>,
    //     accessor:'product_name_and_grade_and_type',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.product_name_and_grade?.material_type_name)
    //         )
    //         }
    // },
    // {
    //     id: `show_on_off`,
    //     Header: () => <div style={{display:"inline"}}>Show<br/>On-Off</div>,
    //     accessor:'show_on_off',
    //     Cell: ({row}:any) => {
    //         const convertToUpper = row.original.show_on_off
    //         if (!convertToUpper) return "";
    //         // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
    //         return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    //       }
    // },
    // {
    //     id: `change_carton_basic_rate`,
    //     Header: () => <div style={{display:"inline"}}>Change Carton<br/>Basic Rate</div>,
    //     accessor:'change_carton_basic_rate',
    //     Cell: ({ row }: any) => {
            

    //         const change_carton_basic_rate = useSelector((state:any) => state.priceListCalculation.change_carton_basic_rate)
    //         const dispatch = useDispatch()            

    //         dispatch(setProductsGetorCreate(row.original.id))

    //         const [isEditable, setIsEditable] = useState(false);
    //         const [inputValue, setInputValue] = useState("0");
            
    //         row.original.change_carton_basic_rate = inputValue

            
          
    //         const enableInput = () => {
    //           setIsEditable(true);
    //         };
          
          
           
    //         return(
    //     <>
    //      {isEditable ? (
    //     <input
    //       type="number"
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent",width:"100%" }}
    //       value={inputValue}
    //       onChange={(e) => {setInputValue(e.target.value)
    //         dispatch(setChange_carton_basic_rate({id: row.original.id, value: e.target.value}))
    //     }}
    //       onBlur={() => setIsEditable(false)}
    //       autoFocus
    //     />
    //   ) : (
    //     <div
    //       onClick={enableInput}
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent", cursor: "pointer",width:"100%" }}
    //     >
    //       {inputValue}
    //     </div>
    //   )}
     
    //     </>
    //         )
    //         }
    // },
    // {
    //     id: `change_carton_rate`,
    //     Header: () => <div style={{display:"inline"}}>Change Carton<br/>Rate</div>,
    //     accessor:'change_carton_rate',
    //     Cell: ({ row }: any) => {

    //         const change_carton_rate = useSelector((state:any) => state.priceListCalculation.change_carton_rate)
    //         const dispatch = useDispatch()            

    //         dispatch(setChange_carton_rate(row.original.id))


    //         const [isEditable, setIsEditable] = useState(false);
    //         const [inputValue, setInputValue] = useState("0");
            
    //         row.original.change_carton_rate = inputValue

            
          
    //         const enableInput = () => {
    //           setIsEditable(true);
    //         };
          
          
           
    //         return(
    //     <>
    //      {isEditable ? (
    //     <input
    //       type="number"
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent",width:"100%" }}
    //       value={inputValue}
    //       onChange={(e) => {setInputValue(e.target.value)
    //         dispatch(setChange_carton_rate({id: row.original.id, value: e.target.value}))
    //     }}
    //       onBlur={() => setIsEditable(false)}
    //       autoFocus
    //     />
    //   ) : (
    //     <div
    //       onClick={enableInput}
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent", cursor: "pointer",width:"100%" }}
    //     >
    //       {inputValue}
    //     </div>
    //   )}
     
    //     </>
    //         )
    //         }
    // },
    // {
    //     id: `change_stick_rate`,
    //     Header: () => <div style={{display:"inline"}}>Change Stick<br/>Rate</div>,
    //     accessor:'change_stick_rate',
    //     Cell: ({ row }: any) => {

    //         const change_stick_rate = useSelector((state:any) => state.priceListCalculation.change_stick_rate)
    //         const dispatch = useDispatch()            

    //         dispatch(setChange_stick_rate(row.original.id))

    //         const [isEditable, setIsEditable] = useState(false);
    //         const [inputValue, setInputValue]:any = useState("0");
            
    //         row.original.change_stick_rate = inputValue

            
          
    //         const enableInput = () => {
    //           setIsEditable(true);
    //         };
          
          
           
    //         return(
    //     <>
    //      {isEditable ? (
    //     <input
    //       type="number"
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent",width:"100%" }}
    //       value={inputValue}
    //       onChange={(e) => setInputValue(e.target.value)}
    //       onBlur={() => setIsEditable(false)}
    //       autoFocus
    //     />
    //   ) : (
    //     <div
    //       onClick={enableInput}
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent", cursor: "pointer",width:"100%" }}
    //     >
    //       {inputValue}
    //     </div>
    //   )}
     
    //     </>
    //         )
    //         }
    // },
    // {
    //     id: `change_one_kg_rate`,
    //     Header: () => <div style={{display:"inline"}}>Change 1 KG<br/>Rate</div>,
    //     accessor:'change_one_kg_rate',
    //     Cell: ({ row }: any) => {

    //         const change_one_kg_rate = useSelector((state:any) => state.priceListCalculation.change_one_kg_rate)
    //         const dispatch = useDispatch()            

    //         dispatch(setChange_one_kg_rate(row.original.id))


    //         const [isEditable, setIsEditable] = useState(false);
    //         const [inputValue, setInputValue] = useState("0");
            
    //         row.original.change_one_kg_rate = inputValue

            
          
    //         const enableInput = () => {
    //           setIsEditable(true);
    //         };
          
          
           
    //         return(
    //     <>
    //      {isEditable ? (
    //     <input
    //       type="number"
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent",width:"100%" }}
    //       value={inputValue}
    //       onChange={(e) => setInputValue(e.target.value)}
    //       onBlur={() => setIsEditable(false)}
    //       autoFocus
    //     />
    //   ) : (
    //     <div
    //       onClick={enableInput}
    //       style={{ textAlign: "center", border: "none", backgroundColor: "transparent", cursor: "pointer",width:"100%" }}
    //     >
    //       {inputValue}
    //     </div>
    //   )}
     
    //     </>
    //         )
    //         }
    // },
    // {
    //     id: `change_rate`,
    //     Header: () => <div style={{display:"inline"}}>% Rate</div>,
    //     accessor:'change_rate',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.change_rate)
    //         )
    //         }
    // },
    // {
    //     id: `gst_change_amount`,
    //     Header: () => <div style={{display:"inline"}}>GST Change<br/>Amount</div>,
    //     accessor:'gst_change_amount',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.gst_change_amount)
    //         )
    //         }
    // },
    // {
    //     id: `gst_rate`,
    //     Header: () => <div style={{display:"inline"}}>GST<br/>Rate %</div>,
    //     accessor:'gst_rate',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.product_name_and_grade?.gst?.india_GST)
    //         )
    //         }
    // },
    // {
    //     id: `gst_amount`,
    //     Header: () => <div style={{display:"inline"}}>GST<br/>Amount</div>,
    //     accessor:'gst_amount',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.gst_amount)
    //         )
    //         }
    // },
    // {
    //     id: `difference_rate`,
    //     Header: () => <div style={{display:"inline"}}>Difference<br/>Rate</div>,
    //     accessor:'difference_rate',
    //     Cell: ({ row }: any) => {
    //         return(
    //     (row.original?.difference_rate)
    //         )
    //         }
    // },

//     {
//         id: `new_carton_basic_rate`,
//         Header: () => <div style={{display:"inline"}}>New Carton<br/>Basic Rate</div>,
//         accessor:'new_carton_basic_rate',
//         Cell: ({ row }: any) => {
            
//             const dispatch = useDispatch()    
                    


//             let old_carton_basic_rate = row.original.old_carton_basic_rate
//             let change_carton_basic_rate = row.original.change_carton_basic_rate
//             let new_carton_basic_rate = Number(old_carton_basic_rate) + Number(change_carton_basic_rate)
//             return(
//                 <></>
//             )
//             }
//     },
//     {
//         id: `new_carton_rate`,
//         Header: () => <div style={{display:"inline"}}>New Carton<br/>Rate</div>,
//         accessor:'new_carton_rate',
//         Cell: ({ row }: any) => {

           

            



          
          
            
//             return(
// <></>
//                 )
//             }
//     },
//     {
//         id: `new_stick_rate`,
//         Header: () => <div style={{display:"inline"}}>New Stick<br/>Rate</div>,
//         accessor:'new_stick_rate',
//         Cell: ({ row }: any) => {

          
         
            
//             return(
//               <></>
        
//             )
//             }
//     },
//     {
//         id: `new_one_kg_rate`,
//         Header: () => <div style={{display:"inline"}}>New 1 KG<br/>Rate</div>,
//         accessor:'new_one_kg_rate',
//         Cell: ({ row }: any) => {

         
            
            
//             return(
        
//         <></>
//             )
//             }
//     },
   
//     {
//         id: `product_weight_price_list`,
//         Header: () => <div style={{display:"inline"}}>Product Weight<br/>Price List</div>,
//         accessor:'product_weight_price_list',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.product_weight_price_list)
//             )
//             }
//     },
//     {
//         id: `stick_nos`,
//         Header: () => <div style={{display:"inline"}}>Stick<br/>Nos</div>,
//         accessor:'stick_nos',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.stick_nos)
//             )
//             }
//     },
//     {
//         id: `carton_stick`,
//         Header: () => <div style={{display:"inline"}}>Carton<br/>Stick</div>,
//         accessor:'carton_stick',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.carton_stick)
//             )
//             }
//     },
//     {
//         id: `product_total_qty`,
//         Header: () => <div style={{display:"inline"}}>Product Total <br/>Qty</div>,
//         accessor:'product_total_qty',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.product_total_qty)
//             )
//             }
//     },
//     {
//         id: `unit_of_measure`,
//         Header: () => <div style={{display:"inline"}}>UOM</div>,
//         accessor:'unit_of_measure',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.unit_of_measure?.name?.name)
//             )
//             }
//     },
//     {
//         id: `carton_weight`,
//         Header: () => <div style={{display:"inline"}}>Carton<br/>Weight</div>,
//         accessor:'carton_weight',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.carton_weight)
//             )
//             }
//     },
//     {
//         id: `price_list_mould_size_mm`,
//         Header: () => <div style={{display:"inline"}}>Price List <br/>Mould Size(MM)</div>,
//         accessor:'price_list_mould_size_mm',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.price_list_mould_size_mm)
//             )
//             }
//     },
//     {
//         id: `production_mould_size_mm`,
//         Header: () => <div style={{display:"inline"}}>Production<br/>Mould Size(MM)</div>,
//         accessor:'production_mould_size_mm',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.production_mould_size_mm)
//             )
//             }
//     },
//     {
//         id: `ml_price_list`,
//         Header: () => <div style={{display:"inline"}}>ML Price<br/>List</div>,
//         accessor:'ml_price_list',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.product_single_piece_code?.product_ml_price_list)
//             )
//             }
//     },
//     {
//         id: `product_color`,
//         Header: () => <div style={{display:"inline"}}>Product <br/>Color</div>,
//         accessor:'product_color',
//         Cell: ({ row }: any) => {
//             let color = row.original?.product_color?.map((e:any)=>{
//               return e?.name 
//             })
//             return(
//               <div>
//                 {color?.join(",")}
//               </div>
//             )
//       }
//     },
//     {
//         id: `product_brand`,
//         Header: () => <div style={{display:"inline"}}>Product <br/>Brand</div>,
//         accessor:'product_brand',
//         Cell: ({ row }: any) => {
//             return(
//         (row.original?.product_brand?.name)
//             )
//             }
//     },
//     {
//         id: `panni_color`,
//         Header: () => <div style={{display:"inline"}}>Panni <br/>Color</div>,
//         accessor:'panni_color',
//         Cell: ({ row }: any) => {
//             let color = row.original?.panni_code_and_grade_name?.color?.map((e:any)=>{
//               return e?.name
//             })
//             return(
//               <div>
//                 {color?.join(",")}
//               </div>
//             )
//       }
       
//     },

    // {
    //     id: 'created_at',
    //     Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    //     accessor:'created_at',
    //     Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
    //   },
    
    
    //   {
    //     id: 'created_by',
    //     Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    //     accessor:'created_by',
    //     Cell: ({ row }: any) => row.original.created_by?.username
    
     
    //   },
    //   {
    //     id: 'updated_at',
    //     Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    //     accessor:'updated_at',
    //     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
    //   },
    //   {
    //     id: 'updated_by',
    //     Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    //     accessor:'updated_by',
    //     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
    //   },

    {
        id: 'status',
        Header: 'Status',
        accessor: 'status',

    },

    {
        id: 'Actions',
        Header: 'Actions',


    },
    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_PriceList, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICELIST_API}/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
        }} />
    },
]

