import { buildQuery, toastHandler } from "./functions";
import { apiSlice } from "../hooks/apiSlice";
import { addPayslip } from "./payrollSlice";

export const payrollApi = apiSlice.injectEndpoints({
	endpoints: (builder:any) => ({
		getPayrolls: builder.query({
			query: () => ({
				url: `leoUser/user/show_all/`,
			}),
			providesTags: ["Payrolls"],
		}),

		getPayslips: builder.query({
			query: () => ({
				url: `leoUser/user/show_all/`,
			}),
			async onQueryStarted(arg:any, { queryFulfilled, dispatch }:any) {
				try {
					const { data } = await queryFulfilled;
					dispatch(addPayslip(data));
				} catch (err) {
					toastHandler("Something went wrong, Please try again", "warning");
				}
			},
			providesTags: ["PaySlips"],
		}),

		getPayslipForPaymentMonthWise: builder.query({
			query: (arg:any) => {
				const query = buildQuery(arg);
				return { url: `payroll/all?${query}` };
			},
			providesTags: ["PaySlipsByMonth"],
		}),

		getPayroll: builder.query({
			query: (id:any) => ({
				url: `payroll/${id}`,
			}),
			providesTags: ["Payroll"],
		}),

		addPayroll: builder.mutation({
			query: (values:any) => ({
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `hrms/payslip/`,
				body: values,
			}),

			async onQueryStarted(arg:any, { queryFulfilled, dispatch }:any) {
				try {
					await queryFulfilled;
					toastHandler("Payroll added successfully","success");
				} catch (err) {
					toastHandler("Something went wrong, Please try again", "warning");
				}
			},
			invalidatesTags: ["Payrolls", "PaySlips", "PaySlipsByMonth"],
		}),

		updatePayroll: builder.mutation({
			query: ({ id, values }:any) => ({
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `hrms/payslip/${id}`,
				body: values,
			}),

			async onQueryStarted(arg:any, { queryFulfilled, dispatch }:any) {
				try {
					await queryFulfilled;
					toastHandler("Payroll updated successfully","success");
				} catch (err) {
					toastHandler("Something went wrong, Please try again", "warning");
				}
			},
			invalidatesTags: ["Payrolls", "Payroll", "PaySlips", "PaySlipsByMonth"],
		}),

		deletePayroll: builder.mutation({
			query: (id:any) => ({
				url: `payroll/${id}`,
				method: "DELETE",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				body: {
					status: "false",
				},
			}),
			async onQueryStarted(arg:any, { queryFulfilled, dispatch }:any) {
				try {
					await queryFulfilled;

					toastHandler("Deleted Payroll successful","warning");
				} catch (err) {
					toastHandler("Something went wrong, Please try again", "warning");
				}
			},
			invalidatesTags: ["Payrolls", "Payroll", "PaySlips", "PaySlipsByMonth"],
		}),
	}),
});

export const {
	useGetPayrollsQuery,
	useGetPayslipsQuery,
	useGetPayslipForPaymentMonthWiseQuery,
	useGetPayrollQuery,
	useAddPayrollMutation,
	useUpdatePayrollMutation,
	useDeletePayrollMutation,
} = payrollApi;
