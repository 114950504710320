
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import Modal from "react-bootstrap/esm/Modal";
import { Tooltip } from "@mui/material";


interface Data {
  selection: any
  product_name_and_grade: any
  product_color: any
  panni_code_and_grade_name: any
  panni_printing_color_name: any
  panni_printing_total_color: any
  product_brand: any
  our_product_brand: any
  product_ml_price_list: any
  product_ml_actual: any
  stick_packing_type: any
  stick_noes: any
  stick_qty: any
  carton_total_nos: any
  carton_code_and_grade_and_name: any
  carton_in_stick_packing: any
  product_single_piece_code: any
  product_price_list_1_piece_weight: any
  product_production_list_1_piece_weight: any
  mouldshape: any
  product_list_mould_diameter: any
  product_type: any


  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  //   status: any;
  actions: any;
}


export const titleofheaders_HDPE = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `product_code`,
    Header: "Product Code",
  },
  {
    id: `product_grade_name_type`,
    Header: "Product Grade & Name & Type ",
  },
  {
    id: `product_single_piece_code`,
    Header: "Product Single Piece Code",
  },
  {
    id: `product_type`,
    Header: "Product Type",
  },
  {
    id: `material_grade_name_type_category_hsncode_gst`,
    Header: "Material Grade Name & Type & Category & HSN Code & GST %",
  },
  {
    id: `gst_type`,
    Header: "Gst Type",
  },
  {
    id: `type_name_density_sutra_gst`,
    Header: "Type Name & Density & Sutra & GST % ",
  },
  {
    id: `use_product_singlecode_grade_name_type`,
    Header: "Use Product Singal Code & Grade & Name & Type",
  },
  {
    id: `use_product_code_qty`,
    Header: "Use Product Code Qty",
  },
  {
    id: `material_type`,
    Header: "Material Type",
  },
  {
    id: `product_color_name`,
    Header: "Product Color Name",
  },
  {
    id: `product_width_mm`,
    Header: "Product Width MM",
  },
  {
    id: `product_length_mm`,
    Header: "Product Length MM",
  },
  {
    id: `thickness_micron`,
    Header: "Thickness Micron",
  },
  {
    id: `thickness_gauge`,
    Header: "Thickness Gauge",
  },
  {
    id: `gadget_yes_no`,
    Header: "Gadget Yes-No",
  },
  {
    id: `gadget_size_mm`,
    Header: "Gadget Size MM",
  },
  {
    id: `product_kan_yes_no`,
    Header: "Product Kan Yes-No",
  },
  {
    id: `product_kan_width_mm`,
    Header: "Product Kan Width MM",
  },
  {
    id: `product_kan_length_mm`,
    Header: "Product Kan Length MM",
  },
  {
    id: `product_silieing_type`,
    Header: "Product Silieing Type",
  },
  {
    id: `product_ml_price_list`,
    Header: "Product Ml Price List",
  },
  {
    id: `product_actual_ml`,
    Header: "Product Actual Ml",
  },
  {
    id: `product_printing`,
    Header: "Product Printing",
  },
  {
    id: `product_printing_type`,
    Header: "Product Printing Printing Type",
  },
  {
    id: `product_printing_color_name`,
    Header: "Product Printing Color Name",
  },
  {
    id: `product_printing_total_color`,
    Header: "Product Printing Total Color",
  },
  {
    id: `product_brand_name`,
    Header: "Product Brand Name",
  },
  {
    id: `product_our_other`,
    Header: "Product Our Other",
  },
  {
    id: `product_print_width_mm`,
    Header: "Product Print Width MM",
  },
  {
    id: `product_print_length_mm`,
    Header: "Product Print Length MM",
  },
  {
    id: `product_production_one_piece_weight`,
    Header: "Product Production 1 Piece Weight",
  },
  {
    id: `product_production_one_kg_in_piece`,
    Header: "Product Production 1 Kg In Piece",
  },
  {
    id: `product_automatic_one_piece_weight`,
    Header: "Product Automatic 1 Piece Weight",
  },
  {
    id: `product_automatic_one_kg_in_piece`,
    Header: "Product Automatic 1 Kg In Piece",
  },
  {
    id: `product_packet_packing_type`,
    Header: "Product Packet Packing Type",
  },
  {
    id: `u.o.m`,
    Header: "U.O.M",
  },
  {
    id: `product_packing_type`,
    Header: "Product Packing Type",
  },
  {
    id: `product_stock_minimum_kg`,
    Header: "Product Stock Minimum Kg ",
  },
  {
    id: `product_stock_maximum_kg`,
    Header: "Product Stock Maximum Kg ",
  },
  {
    id: `product_bursting_strenght`,
    Header: "Product Bursting Strenght ",
  },
  {
    id: `product_our_other`,
    Header: "Product Our Other",
  },
  {
    id: `product_type`,
    Header: "Product Type",
  },
  {
    id: `product_maximum_debit_day`,
    Header: "Product Maximum Debit Day",
  },
  {
    id: `product_one`,
    Header: "Product 1",
  },
  {
    id: `product_location`,
    Header: "Product Location",
  },
  {
    id: `material_grade_name`,
    Header: "Material Grade Name",
  },
  {
    id: `product_name`,
    Header: "Product Name",
  },
  {
    id: `material_type`,
    Header: "Material Type",
  },
  {
    id: `material_category`,
    Header: "Material Category",
  },
  {
    id: `hsn_code`,
    Header: "HSN Code",
  },
  {
    id: `product_gst`,
    Header: "Product Gst % ",
  },
  {
    id: `product_density`,
    Header: "Product Density",
  },
  {
    id: `day_packet_packing_labour_rate`,
    Header: "Day Packet Packing Labour Rate",
  },
  {
    id: `night_packet_packing_labour_rate`,
    Header: "Night Packet Packing Labour Rate",
  },
  {
    id: `day_cartoon_bori_packing_labour_rate`,
    Header: "Day Cartoon/Bori Packing Labour Rate",
  },
  {
    id: `night_cartoon_bori_packing_labour_rate`,
    Header: "Night Cartoon/Bori Packing Labour Rate",
  },
  {
    id: `product_packing_cover_code_name`,
    Header: "Product Packing Cover Code Name",
  },
  {
    id: `product_packing_cover_dori`,
    Header: "Product Packing Cover Dori",
  },
  {
    id: `cartoon_code_grade_name`,
    Header: "Cartoon Code Grade Name",
  },
  {
    id: `patti_code_grade_name`,
    Header: "Patti Code Grade Name",
  },
  {
    id: `remark`,
    Header: "Re-mark",
  },
  {
    id: 'product_status',
    Header: "Product Status",
  },

  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },
  {
    id: 'bori_cartoon_all_image',
    Header: "Bori & Cartoon All Image",
  },
  {
    id: 'product_packet_packing_all_image',
    Header: "Product Packet Packing All Image",
  },
  {
    id: 'product_all_image',
    Header: "Product All Image",
  },
  {
    id: 'product_pdf_all_drawing',
    Header: "Product Pdf All Drawing",
  },
  {
    id: 'packing_material_code_name',
    Header: "Packing Material Code & Name",
  },
  {
    id: 'action',
    Header: "Action",
  },

]

export const Columns_HDPE: any = [
  {
    id: `srno`,
    Header: () => <div>Sr<br />No</div>,
    sticky: "left",

  },

  {
    id: `product_code`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Code</div>,
    accessor: 'product_code',
    sticky: "left",

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_code}
        </div>
      )
    }

  },

  {
    id: `product_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Product Grade &<br />Name & Type</div>,
    accessor: 'product_grade_name',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name} {row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name} {row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: `product_single_piece_code`,
    Header: () => <div style={{ display: "inline" }}>Product Single<br />Piece Code</div>,
    accessor: 'product_single_piece_code',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.single_piece_code}
        </div>
      )
    }

  },
  {
    id: `product_category`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Category</div>,
    accessor: 'product_category',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_type?.name ? row.original.product_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },



  {
    id: `material_name_type`,
    Header: () => <div style={{ display: "inline" }}>Material Name & Type<br />& Category & HSN & GST%</div>,
    accessor: 'material_name_type',
    Cell: ({ row }: any) => {
      const Material: any = `${row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}::${row.original.product_single_piece_code?.extra_response?.HSN_code?.particular_sector_name}
      ::${row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}::${row.original.product_single_piece_code?.extra_response?.HSN_code?.hsn_code}
      ::${row.original.product_single_piece_code?.extra_response?.HSN_code?.india_GST}`
      // return (
      //     <div>
      //         {row.original.hsn_code?.grade_type?.material_type_name}::{row.original.hsn_code?.particular_sector_name?.name ? row.original.hsn_code?.particular_sector_name?.name : <span style={{ color: 'red' }}>****</span>}::
      //         {row.original.hsn_code?.product_categories?.name ? row.original.hsn_code?.product_categories?.name : <span style={{ color: 'red' }}>****</span>}::
      //         {row.original.hsn_code?.hsn_code}::{row.original?.hsn_code?.gst?.india_GST}
      //     </div>
      // )
      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{Material}</p>} placement="top" arrow >
            <div>
              {Material.length >= 2 ? Material?.slice(0, 19)?.concat('...') : Material}
            </div>
          </Tooltip>

        </>
      )
    }


  },
  {
    id: `gst_type`,
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    accessor: 'gst_type',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.gst_type_name}
        </div>
      )
    }

  },
  {
    id: `type_name_density`,
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & Gst</div>,
    accessor: 'type_name_density',


    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}::{row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density}::
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.grade_sutra}::{row.original.product_single_piece_code?.extra_response?.HSN_code?.grade_type_india_GST}
        </div>
      )
    }

  },
  {
    id: `use_product_singlecode_grade_name_type`,
    Header: () => <div>Use Product Singal Code<br />& Grade & Name & Type</div>,
    accessor: 'use_product_singlecode_grade_name_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_singlecode_grade_name_type ? row.original.use_product_singlecode_grade_name_type : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `use_product_code_qty`,
    Header: () => <div>Use Product<br />Code Qty</div>,
    accessor: 'use_product_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.use_product_code_qty ? row.original.use_product_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `automatic_material_type`,
    Header: () => <div>Material<br />Type</div>,
    accessor: 'automatic_material_type',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.material_type?.name ? row.original.product_single_piece_code?.material_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `product_color_name`,
    Header: () => <div>Product Color<br />Name</div>,
    accessor: 'product_color_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.company_color_name ? row.original.product_single_piece_code?.extra_response?.company_color_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_width_mm`,
    Header: () => <div>Product<br />Width MM</div>,
    accessor: 'product_width_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_width_mm ? row.original.product_single_piece_code?.product_width_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_length_mm`,
    Header: () => <div>Product<br />Length MM</div>,
    accessor: 'product_length_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_length_mm ? row.original.product_single_piece_code?.product_length_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `thickness_micron`,
    Header: () => <div>Thickness<br />Micron</div>,
    accessor: 'thickness_micron',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.thickness_micron ? row.original.product_single_piece_code?.thickness_micron : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `thickness_gauge`,
    Header: () => <div>Thickness<br />Gauge</div>,
    accessor: 'thickness_gauge',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.thickness_gauge ? row.original.product_single_piece_code?.thickness_gauge : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `gadget_yes_no`,
    Header: () => <div>Gadget<br />Yes-No</div>,
    accessor: 'gadget_yes_no',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.product_single_piece_code?.gadget_yes_no
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
 
  },
  {
    id: `gadget_size_mm`,
    Header: () => <div>Gadget<br />Size MM</div>,
    accessor: 'gadget_size_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.gadget_size_mm ? row.original.product_single_piece_code?.gadget_size_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_kan_yes_no`,
    Header: () => <div>Product Kan<br />Yes-No</div>,
    accessor: 'product_kan_yes_no',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.product_single_piece_code?.product_kan_yes_no
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
    
  },
  {
    id: `product_kan_width_mm`,
    Header: () => <div>Product Kan<br />Width MM</div>,
    accessor: 'product_kan_width_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_kan_width_mm ? row.original.product_single_piece_code?.product_kan_width_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_kan_length_mm`,
    Header: () => <div>Product Kan<br />Length MM</div>,
    accessor: 'product_kan_length_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_kan_length_mm ? row.original.product_single_piece_code?.product_kan_length_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_silieing_type`,
    Header: () => <div>Product<br />Silieing Type</div>,
    accessor: 'product_silieing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_sealing_type?.name ? row.original.product_single_piece_code?.product_sealing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_ml_price_list`,
    Header: () => <div>Product Ml<br />Price List</div>,
    accessor: 'product_ml_price_list',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_ml ? row.original.product_single_piece_code?.product_ml : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_actual_ml`,
    Header: () => <div>Product<br />Actual Ml</div>,
    accessor: 'product_actual_ml',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_ml_actual ? row.original.product_single_piece_code?.product_ml_actual : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_printing`,
    Header: () => <div>Product<br />Printing</div>,
    accessor: 'product_printing',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original?.product_printing
      if (!convertToUpper) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: `product_printing_type`,
    Header: () => <div>Product Printing<br />Printing Type</div>,
    accessor: 'product_printing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.panni_printing_type?.name ? row.original.panni_printing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_printing_color_name`,
    Header: () => <div>Product Printing<br />Color Name</div>,
    accessor: 'product_printing_color_name',

    Cell: ({ row }: any) => {
      let printingColor = row.original?.extra_response?.company_color_name?.map((e: any) => {
        return e
      })
      if (printingColor.length === 0) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      return printingColor?.join(",")

    }

  },
  {
    id: `panni_printing_color_name`,
    Header: () => <div>Product Printing<br />Total Color</div>,
    accessor: 'panni_printing_color_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.panni_printing_total_color ? row.original.panni_printing_total_color : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_brand_name`,
    Header: () => <div>Product<br />Brand Name</div>,
    accessor: 'product_brand_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.product_brand_name ? row.original.extra_response?.product_brand_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `brand_our_other`,
    Header: () => <div>Brand<br />Our Other</div>,
    accessor: 'brand_our_other',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.our_product_brand ? row.original.our_product_brand : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_print_width_mm`,
    Header: () => <div>Product Print<br />Width MM</div>,
    accessor: 'product_print_width_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_print_width_mm ? row.original.product_print_width_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_print_length_mm`,
    Header: () => <div>Product Print<br />Length MM</div>,
    accessor: 'product_print_length_mm',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_print_length_mm ? row.original.product_print_length_mm : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_production_one_piece_weight`,
    Header: () => <div>Product Production<br />1 Piece Weight</div>,
    accessor: 'product_production_one_piece_weight',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_1_pcs_weight ? row.original.product_single_piece_code?.product_1_pcs_weight : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_production_one_kg_in_piece`,
    Header: () => <div>Product Production<br />1 Kg In Piece</div>,
    accessor: 'product_production_one_kg_in_piece',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_production_one_kg_in_piece ? row.original.product_production_one_kg_in_piece : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_automatic_one_piece_weight`,
    Header: () => <div>Product Automatic<br />1 Piece Weight</div>,
    accessor: 'product_automatic_one_piece_weight',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_automatic_one_piece_weight ? row.original.product_automatic_one_piece_weight : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `product_automatic_one_kg_in_piece`,
    Header: () => <div>Product Automatic<br />1 Kg In Piece</div>,
    accessor: 'product_automatic_one_kg_in_piece',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_automatic_one_kg_in_piece ? row.original.product_automatic_one_kg_in_piece : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }


  },
  {
    id: `packet_packing_type`,
    Header: () => <div>Product Packet<br />Packing Type</div>,
    accessor: 'packet_packing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.packet_packing_type?.name ? row.original.packet_packing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `uom_1`,
    Header: () => <div>U.O.M</div>,
    accessor: 'uom_1',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.uom_1_name ? row.original.extra_response?.uom_1_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `packet_qty`,
    Header: () => <div>Packet<br />Qty</div>,
    accessor: 'packet_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.packet_qty ? row.original.packet_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_total_packing_kg`,
    Header: () => <div>Product Total<br />Packing Kg</div>,
    accessor: 'product_total_packing_kg',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_total_packing_kg ? row.original.product_total_packing_kg : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `uom_2`,
    Header: () => <div>U.O.M</div>,
    accessor: 'uom_2',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.extra_response?.uom_2_name ? row.original.extra_response?.uom_2_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `packing_type`,
    Header: () => <div>Product<br />Packing Type</div>,
    accessor: 'packing_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.packing_type?.name ? row.original.packing_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_stock_minimum_kg`,
    Header: () => <div>Product Stock<br />Minimum Kg</div>,
    accessor: 'product_stock_minimum_kg',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_stock_minimum_kg ? row.original.product_stock_minimum_kg : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `product_stock_maximum_kg`,
    Header: () => <div>Product Stock<br />Maximum Kg</div>,
    accessor: 'product_stock_maximum_kg',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_stock_maximum_kg ? row.original.product_stock_maximum_kg : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_bursting_strenght`,
    Header: () => <div>Product Bursting<br />Strenght</div>,
    accessor: 'product_bursting_strenght',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_bursting_strength ? row.original.product_single_piece_code?.product_bursting_strength : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_our_other`,
    Header: () => <div>Product<br />Our-Other</div>,
    accessor: 'product_our_other',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.product_our_other?.name ? row.original.product_single_piece_code?.product_our_other?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_special_running`,
    Header: () => <div>Product<br />Type</div>,
    accessor: 'product_special_running',
    Cell: ({ row }: any) => {
      return (
        <div>
          {/* {row.original.product_single_piece_code?.product_special_running_type?.name ? row.original.product_single_piece_code?.product_special_running_type?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>} */}
          {row.original.product_special_running?.name?row.original.product_special_running?.name:<span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_maximum_debit_day`,
    Header: () => <div>Product Maximum<br />Debit Day</div>,
    accessor: 'product_maximum_debit_day',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_debit_day ? row.original.product_debit_day : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_one`,
    Header: () => <div>Product<br />1</div>,
    accessor: 'product_one',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_1 ? row.original.product_1 : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `product_location`,
    Header: () => <div>Product<br />Location</div>,
    accessor: 'product_location',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_location?.name ? row.original.product_location?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material Grade<br />Name</div>,
    accessor: 'material_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_type_name}
        </div>
      )
    }

  },
  {
    id: 'product_name',
    Header: () => <div style={{ display: "inline" }}>Product<br />Name</div>,
    accessor: 'product_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name ? row.original.product_single_piece_code?.extra_response?.cavity_to_product?.product_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor: 'material_type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.particular_sector_name}
        </div>
      )
    }

  },
  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor: 'material_category',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor: 'material_hsn',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.hsn_code}
        </div>
      )
    }

  },

  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>Product<br />GST%</div>,
    accessor: 'material_gst',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor: 'product_density',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density ? row.original.product_single_piece_code?.extra_response?.HSN_code?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  // {
  //     id: `material_grade_name`,
  //     Header: () => <div>Material Grade Name</div>,
  //     accessor: 'material_grade_name',
  //     Cell: ({ row }: any) => {
  //       return (
  //         <div>
  //           {row.original.product_location?.name ? row.original.product_location?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
  //         </div>
  //       )
  //     }

  // },
  // {
  //     id: `product_name`,
  //     Header: () => <div>Product Name</div>,
  //     accessor: 'product_name',


  // },
  // {
  //     id: `material_type`,
  //     Header: () => <div>Material Type</div>,
  //     accessor: 'material_type',


  // },
  // {
  //     id: `material_category`,
  //     Header: () => <div>Material Category</div>,
  //     accessor: 'material_category',


  // },
  // {
  //     id: `hsn_code`,
  //     Header: () => <div>HSN Code</div>,
  //     accessor: 'hsn_code',


  // },
  // {
  //     id: `product_gst`,
  //     Header: () => <div>Product Gst % </div>,
  //     accessor: 'product_gst',


  // },
  // {
  //     id: `product_density`,
  //     Header: () => <div>Product Density</div>,
  //     accessor: 'product_density',


  // },

  {
    id: `day_packet_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Day Packet<br />Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor: 'day_packet_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_packet_packing_labour_rate ? row.original.day_packet_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `night_packet_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Night Packet<br />Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor: 'night_packet_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_carton_packing_labour_rate ? row.original.day_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `day_carton_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Day Cartoon/Bori<br />Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'day_carton_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.day_carton_packing_labour_rate ? row.original.day_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `night_carton_packing_labour_rate`,
    Header: () => <div style={{ display: "inline" }}>Night Cartoon/Bori<br />Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'night_carton_packing_labour_rate',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.night_carton_packing_labour_rate ? row.original.night_carton_packing_labour_rate : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  // {
  //     id: `product_packing_cover_code_name`,
  //     Header: () => <div>Product Packing Cover Code Name</div>,
  //     accessor: 'product_packing_cover_code_name',


  // },
  {
    id: `product_packing_cover_code_name`,
    Header: () => <div>Product Packing Cover<br/>Code & Name</div>,
    accessor: 'product_packing_cover_code_name',

    Cell: ({ row }: any) => {
      const packingCover = `${row.original.extra_response?.product_packing_cover_code_name?.panni_code || ''} 
                            ${row.original.extra_response?.product_packing_cover_code_name?.material_type_name || ''}
                            ${row.original.extra_response?.product_packing_cover_code_name?.particular_sector_name || ''}`.trim();
    
      return (
        <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{packingCover || 'No details available'}</p>} placement="top" arrow>
          <div>
            {packingCover.length > 0 ? (
              packingCover.length > 19 ? (
                packingCover.slice(0, 19).concat('...')
              ) : (
                packingCover
              )
            ) : (
              <span style={{ color: 'red', fontSize: "20px" }}>****</span>
            )}
          </div>
        </Tooltip>
      );
    }
    

  },
  {
    id: `packing_cover_dori`,
    Header: () => <div>Product Packing<br/> Cover Dori</div>,
    accessor: 'packing_cover_dori',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.packing_cover_dori?.name ? row.original.packing_cover_dori?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `carton_code`,
    Header: () => <div style={{ display: "inline" }}>Carton Code<br />& Grade & Name</div>,
    accessor: 'carton_code',



    Cell: ({ row }: any) => {
      return <div>
        {row.original?.extra_response?.carton ?
          <>
            {`${row.original.extra_response?.carton?.carton_code} ${row.original.extra_response?.carton?.material_type_name} ${row.original.extra_response?.carton?.particular_sector_name}`}
          </>
          : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>

    }


  },
  {
    id: `carton_code_and_grade_and_name`,
    Header: () => <div style={{ display: "inline" }}>Patti Code<br />& Grade & Name</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor: 'carton_code_and_grade_and_name',

    Cell: ({ row }: any) => {

      return <div>
        {row.original?.extra_response?.box_patti ?
          <>
            {`${row.original.extra_response?.box_patti?.box_patti_code} ${row.original.extra_response?.box_patti?.material_type_name} ${row.original.extra_response?.box_patti?.particular_sector_name}`}
          </>
          : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>

    }

  },
  {
    id: 'remark',
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    accessor: 'remark',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.remark ? row.original.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },


  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split("T")[0] : "-")


  },

  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username : "-")


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split("T")[0] : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: `bori_and_carton_image`,
    Header: () => <div style={{ display: "inline" }}>Bori & Carton Packing<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.bori_and_carton_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.bori_and_carton_image || row.original.bori_and_carton_image?.length === 0 ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.bori_and_carton_image.length > 0 ? row.original.bori_and_carton_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.bori_and_carton_image[0]?.doc ? row.original.bori_and_carton_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.bori_and_carton_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },



  {
    id: `product_packet_packing_image`,
    Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_packet_packing_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_packet_packing_image || row.original.product_packet_packing_image?.length === 0? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_packet_packing_image.length > 0 ? row.original.product_packet_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_packet_packing_image[0]?.doc ? row.original.product_packet_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_packet_packing_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },




  {
    id: `product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        
        setDeleteConfirm(true)
        setFieldImages(field.product_image)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.product_image || row.original.product_image?.length === 0? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.product_image?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },








  {
    id: `product_pdf_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
            {!row.original.product_pdf_drawing || row.original.product_pdf_drawing?.length === 0? <>
              <a

                href="#"
                className="text-danger"
              >
                No Document Uploaded
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_pdf_drawing.length > 0 ? row.original.product_pdf_drawing.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.product_pdf_drawing?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },


  {
    id: 'Actions',
    Header: 'Actions',


  },



  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_HDPE, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}disposableproduct/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]

