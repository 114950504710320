// import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../../hooks/Api";
import noImage from "../../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";
import { Tooltip } from "@mui/material";



interface Data {
  selection: any

  

  code: any
  godown_stock: any
  balance_stock: any
  lee10_price: any
  order_qty: any
  uom: any
  discount_after_tax: any
  approved_discount: any
  one_kg_rate:any
  after_discount_box: any
  brand: any
  nos_strip: any
  box_strip: any
  cup_weight: any
  box_weight: any
  basic_discount:any
  total_basic_discount:any
  basic_rate_per:any
  total_basic:any
  gst_rate:any
  gst_amount:any
  net_amount:any






  created_at:any

  created_by:any
  updated_by:any
  updated_at:any


  // status: any;
  order_actions: any;
}



export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },






  

  {
    id: 'code',
    Header: "Product Code",
   
  },
  {
    id: 'godown_stock',
    Header: "Godown Stock",
   
 
  },
  {
    id: 'balance_stock',
    Header:" Balance Stock",
  
 
  },
  {
    id: 'lee10_price',
    Header: "Lee10 Price",
   
 
  },
  {
    id: 'uom',
    Header: "UOM",
   
 
  },
  {
    id: 'order_qty',
    Header: "Order Qty",
   
 
  },


  {
    id: 'discount_after_tax',
    Header: "Discount After Tax",
   
 
  },
  {
    id: 'approved_discount',
    Header:"Approved Discount %",
   
 
  },
  {
    id: 'one_kg_rate',
    Header:" 1 KG Rate",
    
  },
  {
    id: 'after_discount_box',
    Header: "After Discount Box",
   
 
  },
  {
    id: 'brand',
    Header: "Brand",
   
  },
  {
    id: 'nos_strip',
    Header: "Nos/Strip",
   
  },
  {
    id: 'box_strip',
    Header: "Box/Strip",
  
  },
  {
    id: 'cup_weight',
    Header: "Cup Weight",
   
  },
  {
    id: 'box_weight',
    Header: "Box Weight",
    
  },
  {
    id: 'basic_discount',
    Header: "Basic Discount",
  
  },
  {
    id: 'total_basic_discount',
    Header: "Total Basic Discount",
   
  },
  {
    id: 'basic_rate_per',
    Header:" Basic Rate per",
   
  },
  
 
  {
    id: `total_basic`,
    Header: "Total Basic",
   
  },
  {
    id: `gst_rate`,
    Header: "GST Rate %",
   
  },
  {
    id: `gst_amount`,
    Header: "GST Amount",
    
  },
  {
    id: `net_amount`,
    Header: "Net Amount",
  
  },
  {
      id: 'order_actions',
      Header: 'Actions',
  
    
  },


  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },
  {
    id: 'status',
    Header: 'Status',
  },
]
      



export const Columns_SalesOrderProduct: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    sticky:"left",
  },

  {
    id: 'code',
    Header: () => <div style={{display:"inline"}}>Product Code,<br/>Name & Type</div>,
    // Header: 'Sales Inquiry Code',
    sticky:"left",
    accessor:'code',
    Cell: ({ row }: any) => {
      
     var Product = `${row.original.extra_response?.product_code}::${row.original.extra_response?.mouldshape?row.original.extra_response?.mouldshape:<span style={{ color: 'red', fontSize: "20px" }}>****</span>}::${row.original.extra_response?.use_grade_name}::${row.original.extra_response?.type}`
      return (
        <>
        <Tooltip title={Product} placement="top" arrow >
           <div> 
        {Product?.slice(0,15).concat('...')}
        </div>
        </Tooltip>

        </>
      )
      // return (
        // (`${row.original.pricelist_product?.product_code?.product_code}::${row.original.pricelist_product?.product_code?.mouldshape?.name}::${row.original.pricelist_product?.product_code?.product_name_and_grade?.use_grade_name}::${row.original.pricelist_product?.product_code?.product_type?.type}`)
      // )

  }
  },
  {
    id: 'godown_stock',
    Header: () => <div style={{display:"inline"}}>Godown<br/>Stock</div>,
    // Header: 'Lead Generated by Sales Executive Code & Name',
    accessor:'godown_stock',
    Cell: ({ row }: any) => {


      row.original.godown_stock = 500

     


      return(
        <div>
          {row.original.godown_stock}
          {/* {"500"} */}
        </div>
      )
}
 
  },
  {
    id: 'balance_stock',
    Header: () => <div style={{display:"inline"}}>Balance<br/>Stock</div>,
    // Header: 'Lead Source Of Enquiry',
    accessor:'balance_stock',
    Cell: ({ row }: any) => {

      row.original.balance_stock = 500
      return(
        <div>
          {row.original.balance_stock}
          {/* {"500"} */}
        </div>
      )
}
 
  },
  {
    id: 'lee10_price',
    Header: () => <div style={{display:"inline"}}>Lee10<br/>Price</div>,
    // Header: 'Requirment Status',
    accessor:'lee10_price',

Cell: ({row}:any) => {
 
  row.original.lee10_price = 500

  return(
<div>
      {row.original.lee10_price}
      </div>
  )
}
 
  },
  {
    id: 'uom',
    Header: () => <div style={{display:"inline"}}>UOM</div>,
    // Header: 'Company New-Old',
    accessor:'uom',
    Cell: ({row}:any) => {
     
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
      return(
      //  <></>
          (row.original.extra_response?.uom)
        
      )
    }
 
  },
  {
    id: 'order_qty',
    Header: () => <div style={{display:"inline"}}>Order<br/>Qty</div>,
    // Header: 'Company Name & City',
    accessor:'order_qty',
    Cell: ({ row }: any) => {
      // let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
        // return (row.original?.sales_inquiry_code?.product[0]?.product_qty)
      // })
      // if (productType?.length === 0) return "";
      // // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return productType?.join(",")
      return(
        <div>
          {row.original.product_qty}
        </div>
      )

  },
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.lead_generate?.sales_inquiry_code?.product?.product_qty}
//         </div>
//       )
// }
 
  },

//   {
//     id: 'contact_person',
//     Header: () => <div style={{display:"inline"}}>Contact Person<br/>Name</div>,
//     // Header: 'Contact Person Name',
//     accessor:'contact_person',
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.contact_person}
//         </div>
//       )
// }
 
//   },
  {
    id: 'discount_after_tax',
    Header: () => <div style={{display:"inline"}}>Discount<br/>After Tax</div>,
    // Header: 'Name Of Customer',
    accessor:'discount_after_tax',
    Cell: ({ row }: any) => {

      row.original.discount_after_tax = 500
      return(
        <div>
          {row.original.discount_after_tax}
          {/* {"500"} */}
        </div>
      )
}
 
  },
  {
    id: 'approved_discount',
    Header: () => <div style={{display:"inline"}}>Approved Discount <br/>%</div>,
    // Header: 'Mobile Number',
    accessor:'approved_discount',
    Cell: ({ row }: any) => {
      // row.original.discount = 500
      return(
        <div>
          {/* {"500"} */}
          {row.original.approved_discount}
        </div>
      )
    }
 
  },
  {
    id: 'one_kg_rate',
    Header: () => <div style={{display:"inline"}}>1 KG<br/>Rate</div>,
    // Header: 'E-Mail ID',
    accessor:'one_kg_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original?.extra_response?.new_one_kg_rate}
        </div>
      )
    }
    
  },
  {
    id: 'after_discount_box',
    Header: () => <div style={{display:"inline"}}>After Discount<br/>Box</div>,
    // Header: 'Address',
    accessor:'after_discount_box',
    Cell: ({ row }: any) => {
      row.original.after_discount_box = 500
      return(
        <div>
          {/* {"500"} */}
          {row.original.after_discount_box}
        </div>
      )
    }
 
  },
  {
    id: 'brand',
    Header: () => <div style={{display:"inline"}}>Brand</div>,
    // Header: 'Village Code & Name',
    accessor:'brand',
   

    Cell: ({ row }: any) => {
      
      return(
        <div>
          {row.original.extra_response?.product_brand_name}
        </div>
      )
}
    
 
  },
  {
    id: 'nos_strip',
    Header: () => <div style={{display:"inline"}}>Nos/<br/>Strip</div>,
    // Header: 'Taluka Name',
    accessor:'nos_strip',
    Cell: ({ row }: any) => {
      row.original.nos_strip =500
      return(
        <div>
          {row.original.nos_strip}
          {/* {"500"} */}
        </div>
      )
    }
 
  },
  {
    id: 'box_strip',
    Header: () => <div style={{display:"inline"}}>Box/<br/>Strip</div>,
    // Header: 'District Name',
    accessor:'box_strip',
    Cell: ({ row }: any) => {
      row.original.box_strip= 500
      return(
        <div>
          {row.original.box_strip}
          {/* {"500"} */}
        </div>
      )
    }
 
  },
  {
    id: 'cup_weight',
    Header: () => <div style={{display:"inline"}}>Cup<br/>Weight</div>,
    // Header: 'State Name',
    accessor:'cup_weight',
    Cell: ({ row }: any) => {
      row.original.cup_weight = 500
      return(
        <div>
          {row.original.cup_weight}
          {/* {"500"} */}
        </div>
      )
    }
 
  },
  {
    id: 'box_weight',
    Header: () => <div style={{display:"inline"}}>Box<br/>Weight</div>,
    // Header: 'Country Name',
    accessor:'box_weight',
    Cell: ({ row }: any) => {

      row.original.box_weight = 500
      return(
        <div>
          {row.original.box_weight}
          {/* {"500"} */}
        </div>
      )
    }
 
 
  },
  {
    id: 'basic_discount',
    Header: () => <div style={{display:"inline"}}>Basic<br/>Discount</div>,
    // Header: 'Pin Code Number',
    accessor:'basic_discount',
    Cell: ({ row }: any) => {
      row.original.basic_discount=500
      return(
        <div>
          {row.original.basic_discount}
          {/* {"500"} */}
        </div>
      )
    }
 
 
  },
  {
    id: 'total_basic_discount',
    Header: () => <div style={{display:"inline"}}>Total Basic<br/> Discount</div>,
    // Header: 'Reference Name',
    accessor:'total_basic_discount',
    Cell: ({ row }: any) => {
      row.original.total_basic_discount=500
      return(
        <div>
          {row.original.total_basic_discount}
          {/* {"500"} */}
        </div>
      )
    }
 
  },
  {
    id: 'basic_rate_per',
    Header: () => <div style={{display:"inline"}}>Basic <br/>Rate per</div>,
    // Header: 'Lead Contacted Date & Time',
    accessor:'basic_rate_per',
    Cell: ({ row }: any) => {
      row.original.basic_rate_per =500
      return(
        <div>
          {row.original.basic_rate_per}
          {/* {"500"} */}
        </div>
      )
    }
 
  },
  
 
  {
    id: `total_basic`,
    Header: () => <div style={{display:"inline"}}>Total <br/>Basic</div>,
    accessor:'total_basic',
    Cell: ({ row }: any) => {
      row.original.total_basic=500
      return(
        <div>
          {row.original.total_basic}
          {/* {"500"} */}
        </div>
      )
       
  }
  },


  {
    id: `new_carton_basic_rate`,
    Header: () => <div style={{display:"inline"}}>New Carton <br/>Basic Rate</div>,
    accessor:'new_carton_basic_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.new_carton_basic_rate}
          {/* {"500"} */}
        </div>
      )
       
  }
  },

  {
    id: `new_carton_rate`,
    Header: () => <div style={{display:"inline"}}>New Carton<br/>Rate</div>,
    accessor:'new_carton_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.new_carton_rate}
          {/* {"500"} */}
        </div>
      )
       
  }
  },

  {
    id: `new_one_kg_rate`,
    Header: () => <div style={{display:"inline"}}>New One<br/>Kg Rate</div>,
    accessor:'new_one_kg_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.new_one_kg_rate}
          {/* {"500"} */}
        </div>
      )
       
  }
  },

  {
    id: `new_stick_rate`,
    Header: () => <div style={{display:"inline"}}>New Stick<br/>Rate</div>,
    accessor:'new_stick_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.new_stick_rate}
          {/* {"500"} */}
        </div>
      )
       
  }
  },


  {
    id: `gst_rate`,
    Header: () => <div style={{display:"inline"}}>GST <br/>Rate %</div>,
    accessor:'gst_rate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.india_GST}
          {/* {"500"} */}
        </div>
      )
       
  }
  },
  {
    id: `gst_amount`,
    Header: () => <div style={{display:"inline"}}>GST<br/> Amount</div>,
    accessor:'gst_amount',
    Cell: ({ row }: any) => {
      row.original.gst_amount = 500
      return(
        <div>
          {row.original.gst_amount}
          {/* {"500"} */}
        </div>
      )
       
  }
  },
  {
    id: `net_amount`,
    Header: () => <div style={{display:"inline"}}>Net <br/>Amount</div>,
    accessor:'net_amount',
    Cell: ({ row }: any) => {
      row.original.net_amount = 500
      return(
        <div>
          {row.original.net_amount}
          {/* {"500"} */}
        </div>
      )
       
  }
  },
  {
      id: 'order_actions',
      Header: 'Actions',
  
    
  },
  // {
  //   id: `product_qty`,
  //   Header: () => <div style={{display:"inline"}}>Special <br/>Requirement</div>,
  //   accessor:'product_qty',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_qty
  //     })
  //     if (productType?.length === 0) return "";
  //     return productType?.join(",")
       
  // }
  // },
  

  // {
  //   id: 'status',
  //   Header: 'Status',
  //   accessor:'status',

 
  // },


  

  
  
 
 

 

  // {
  //   id: 'created_at',
  //   Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
  //   accessor:'created_at',
  //   Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  // },


  // {
  //   id: 'created_by',
  //   Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
  //   accessor:'created_by',
  //   Cell: ({ row }: any) => row.original.created_by?.username

 
  // },
  // {
  //   id: 'updated_at',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
  //   accessor:'updated_at',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  // },
  // {
  //   id: 'updated_by',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
  //   accessor:'updated_by',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  // },
  
 
  // {
  //   id: 'Actions',
  //   Header: 'Actions',

  
  // },
  // {
  //   id: 'Selection',
  //   Header: ({ getToggleAllRowsSelectedProps }: any) => {
  //     const [data, setData] = useState([])

  //     const columns: any = useMemo(() => Columns_SalesOrder, [])
  //     const defaultColumn: any = useMemo(() => {
  //       return {
  //         Filter: Columnlevel
  //       }
  //     }
  //       , [])

  //     const tableInstance: any = useTable({
  //       columns,
  //       data: data,
  //       defaultColumn,

  //     },


  //     ) 
  //     const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  //     let { selectedFlatRows} = tableInstance
  //     const { } = useMutation((deleteid: any) => {
  //       const temp_data = {
  //         id: deleteMultipleid
  //       }
  //       return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/deletelist/`, temp_data,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('token')}`
  //           }
  //         });
  //     });
  //     return (
  //       <>
  //         <div>
  //           <Checkbox

  //             onClick={() => {
  //               setDeleteMultipleid(() => {
  //                 const temp = selectedFlatRows?.map((e: any) => {
  //                   return e.original.id;
  //                 });
  //                 return temp;
  //               });
  //             }}

  //             {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       </>
  //     );
  //   },

  //   Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  //     // Add your onClick function here
  //   }} />
  // },
]




