
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../../components/Cell/Status";
import Actions from "../../../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../../../hooks/brand";
import noImage from "../../../../images/noImage.png"
import { Update, useFetch } from "../../../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from "react-tooltip";

interface Data {
  selection: any
  department_name: any;
  issue_registered_by_person_code_and_name: any;
  refrence_person_code_and_name: any;
  maintenance_location: any

  code: any

  created_at: any
  created_by: any
  fault_date: any
  status: any;
  actions: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `code`,
    Header: `Compliant Code`,
  },
  {
    id: 'fault_date',
    Header: "Fault Date",

  },
  {
    id: `department_name`,
    Header: "Department Name",

  },
  {
    id: `issue_registered_by_person_code_and_name`,
    Header: "Issue Registered Person",

  },
  {
    id: `refrence_person_code_and_name`,
    Header: "Reference Person",

  },
  {
    id: `maintenance_location`,
    Header: "Maintenance Location",

  },

  {
    id: `maintenance_detail`,
    Header: "Compliant Details",
  },
  {
    id: 'status',
    Header: 'Status',


  },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },





]





export const Columns_Maintenance_Responsible: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr <br /> No.</div>,
    sticky: "left",
  },
  {
    id: 'handover_date',
    Header: () => <div style={{ display: "inline" }}>Handover<br />Work Date</div>,
    accessor: 'handover_date',
    sticky: "left",
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(" ")[0]: <span style={{ color: 'red', fontSize: "20px" }}>****</span>)
  },
  {
    id: `responsible_person`,
    Header: () => <div style={{ display: "inline" }}>Responsible Person<br />Code & Name</div>,
    accessor: 'responsible_person',
    Cell: ({ row }: any) => {
    
      return(
    ((row.original?.responsible_person?.code=== undefined)?
    <span style={{ color: 'red', fontSize: "20px" }}>****</span>:
    `${row.original?.responsible_person?.code} ${row.original?.responsible_person?.surname} ${row.original?.responsible_person?.name} ${row.original?.responsible_person?.father_name[0]}`)
    )}  
  },  
  
  {
    id: `department_name`,
    Header: () => <div style={{ display: "inline" }}>Department<br />Name</div>,
    accessor: 'department_name',
    Cell: ({ row }: any) => (row.original?.department_name?.department)
  },
  {
    id: `issue_registered_by_person_code_and_name`,
    Header: () => <div style={{ display: "inline" }}>Issue Registered By<br />Person Code & Name</div>,
    accessor: 'issue_registered_by_person_code_and_name',
    Cell: ({ row }: any) => (`${row.original?.issue_registered_by_person_code_and_name?.code} ${row.original?.issue_registered_by_person_code_and_name?.surname} ${row.original?.issue_registered_by_person_code_and_name?.name} ${row.original?.issue_registered_by_person_code_and_name?.father_name[0]}`)
  },
  {
    id: `refrence_person_code_and_name`,
    Header: () => <div style={{ display: "inline" }}>Fault Reference Person<br />Code & Name</div>,
    accessor: 'refrence_person_code_and_name',
    Cell: ({ row }: any) => (`${row.original?.refrence_person_code_and_name?.code} ${row.original?.refrence_person_code_and_name?.surname} ${row.original?.refrence_person_code_and_name?.name} ${row.original?.refrence_person_code_and_name?.father_name[0]}`)
  },
  {
    id: `maintenance_location`,
    Header: () => <div style={{ display: "inline" }}>Maintenance<br />Location</div>,
    accessor: 'maintenance_location',
    Cell: ({ row }: any) => (row.original?.maintenance_location?.name)
  },

  {
    id: `maintenance_status`,
    Header: () => <div style={{ display: "inline" }}>Maintenance<br />Status</div>,
    accessor: 'maintenance_status',
    Cell: ({ value }: { value: any }) => { 
      return <span style={value==="InProgress"?{color:"#e5841b",fontWeight:'bold'}:value==="Completed"?{color:'green',fontWeight:'bold'}:value==="Pending"?{color:"red",fontWeight:'bold'}:{color:"#4f69c6",fontWeight:'bold'}}>{value.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")}</span>

    }
  },

  {
    id: `maintenance_detail_length`,
    Header: () => <div style={{ display: "inline" }}>Compliant<br />Details Count</div>,
    accessor: 'maintenance_detail_length',
    Cell: ({ row }: any) => (row.original?.maintenance_detail?.length)
  },

  {
    id: `maintenance_detail`,
    Header: () => <div style={{ display: "inline" }}>Compliant<br />Details</div>,
    accessor: 'maintenance_detail',
    Cell: ({ row }: any) => {
      const [openProducts, setOpenProducts] = useState(false)
      const [showImages, setShowImages] = useState(false)
      const [fieldImages, setFieldImages] = useState([])

      const [currentIndex, setCurrentIndex] = useState(0);

      const handleShowImages = (field: any) => {
        setShowImages(true)
        setFieldImages(field.image)
        // setFieldImages(field)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      // const ids = row.original.product?.map((e:any)=>{
      //   return e.id
      // })
      // const handleViewProducts=()=>{
      //   Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}//check_usages/${ids}/` )
      // }
      return (<>

        <div>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

            onClick={() => setOpenProducts(true)}
          >Compliant</button>
        </div>
        <Modal size="xl" show={openProducts} onHide={() => setOpenProducts(false)} aria-labelledby="contained-modal-title-vcenter"
          centered    >
          <Modal.Header >
            <Modal.Title>
              Maintenance Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="table-responsive"  >



              <table className="table table-striped table-bordered" style={{ marginTop: "3px", width: "-webkit-fill-available" }}>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th >Sr. <br /> No</th>
                    <th>Maintenance <br />Location</th>
                    <th>Fault<br /> Name</th>
                    <th>Maintenance<br />The Reason</th>
                    <th>Reason<br />Detail</th>
                    <th>Date<br />Limit</th>
                    <th>Take Time<br />To Complete</th>
                    <th>Image<br />All</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {row.original.maintenance_detail?.map((field: any, index: any) => (
                    <tr key={index} >
                      <td>{index + 1}</td>


                      <td>

                      {field.maintenance_location?.name}

                      </td>



                      <td data-tooltip-id="fault-name" data-tooltip-content={field.maintenance_fault}>

                        <Tooltip id="fault-name" />
                        {field.maintenance_fault.slice(0, 15).concat('...')}

                      </td>

                      <td>

                        {field.maintenance_issue?.name}

                      </td>

                      <td>


                        {field.reason_detail ? field.reason_detail : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}

                      </td>
                      <td>


                        {field.maintenance_date_limit}

                      </td>
                      <td>


                        {field.maintenance_time_limit}

                      </td>
                      <td>
                        {/* <img src={field.image[0]?.doc} onClick={()=>handleShowImages(field)} alt='no image' width={50} height={50} /> */}
                        {!field.image[0]?.doc ? <>
                          <a

                            href="#"

                          // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
                          >
                            <img src={noImage} height={60} width={100} />
                          </a>
                        </> : <>
                          <a
                            onClick={() => handleShowImages(field)}
                            href={`#galleryModal${row.original.name}`}

                            data-large-src={(field.image[0]?.doc.length > 0 ? field.image[0]?.doc.at(-1).doc : noImage)} data-toggle="modal">
                            {/* <img src={(field.image[0]?.doc ? field.image[0]?.doc.at(-1).doc : noImage)} height={60} width={100} /> */}
                            <img src={field.image[0]?.doc}  alt='no image' width={50} height={50} /> 
                          </a>
                        </>}
                        <Modal show={showImages} onHide={() => setShowImages(false)} aria-labelledby="contained-modal-title-vcenter"
                          centered    >

                          <Modal.Body>
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                              <div className="carousel-indicators">

                              </div>



                              <div className="carousel-inner">
                                {field.image?.map((imgSrc: any, index: any) => (
                                  <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                                    <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                                  </div>
                                ))}
                              </div>

                              <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button className="carousel-control-next" type="button" onClick={goToNext} >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button className="btn btn-primary" onClick={() => setShowImages(false)}>OK</button>

                          </Modal.Footer>
                        </Modal>
                      </td>



                    </tr>
                  ))}
                </tbody>
              </table>


            </div>


          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => setOpenProducts(false)}>Close</button>

          </Modal.Footer>
        </Modal>
      </>
      )
    }

  },
  {
    id: 'MaintenanceStatus',
    Header: 'Actions',


  },
  {
    id: 'fault_date',
    Header: () => <div style={{ display: "inline" }}>Fault<br />Date</div>,
    accessor: 'fault_date',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(" ")[0] : "-")
  },

  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Compliant<br />Code</div>,
    accessor: `code`,
    // sticky: "left",

  },

  {
    id: 'repairing_date',
    Header: () => <div style={{ display: "inline" }}>Repairing<br />Date</div>,
    accessor: 'repairing_date',
    Cell: ({ row }: any) => (row.original.repairing_date ? row.original.repairing_date : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)
  },
  {
    id: 'audit_date',
    Header: () => <div style={{ display: "inline" }}>Audit<br />Date</div>,
    accessor: 'audit_date',
    Cell: ({ row }: any) => (row.original.audit_date ? row.original.audit_date : <span style={{ color: 'red', fontSize: "20px" }}>****</span>)
  },


  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },


  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  
  // {
  //   id: 'updated_at',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
  //   accessor:'updated_at',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  // },
  // {
  //   id: 'updated_by',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
  //   accessor:'updated_by',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  // },



 

  // {
  //   id: 'Selection',
  //   // sticky:"left",
  //   Header: ({ getToggleAllRowsSelectedProps }: any) => {
  //     const [data, setData] = useState([])

  //     const columns: any = useMemo(() => Columns_Maintenance_Responsible, [])
  //     const defaultColumn: any = useMemo(() => {
  //       return {
  //         Filter: Columnlevel
  //       }
  //     }
  //       , [])

  //     const tableInstance: any = useTable({
  //       columns,
  //       data: data,
  //       defaultColumn,

  //     },


  //     )
  //     const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  //     let { selectedFlatRows} = tableInstance
  //     const { } = useMutation((deleteid: any) => {
  //       const temp_data = {
  //         id: deleteMultipleid
  //       }
  //       return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/deletelist/`, temp_data,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('token')}`
  //           }
  //         });
  //     });
  //     return (
  //       <>
  //         <div>
  //           <Checkbox

  //             onClick={() => {
  //               setDeleteMultipleid(() => {
  //                 const temp = selectedFlatRows?.map((e: any) => {
  //                   return e.original.id;
  //                 });
  //                 return temp;
  //               });
  //             }}

  //             {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       </>
  //     );
  //   },

  //   Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  //     // Add your onClick function here
  //   }} />
  // },
]
