import "../../../components/Table/table.css"
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useFetchCityDropDown, useFetchCustomerDropDown, useFetchDisposableDropDown, useFetchEmployeeDropDown, useFetchMaterialTypeDropDown, useFetchPincodeDropDown, useFetchSourceOfInquiryDropDown, useFetchUOMDropDown } from "../../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
import DatePicker, { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import Select from "react-select"
import useFocusOnEnter from "../../../components/focus";
import Axiosinstance from "../../../hooks/Interceptor";
import { nanoid } from 'nanoid'
import { customAlphabet } from 'nanoid'
// import { useDelete } from "../../../hooks/delete";
import axios from "axios";
import { Alert } from "../../../components/Model/Model";
import { Deletesinglewithdependancy } from "../../../components/modal";
import { FiEdit } from "react-icons/fi";

import Modal from "react-bootstrap/esm/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";

import Button from "react-bootstrap/esm/Button";

import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});

const Edit_Sales_Inquiry = () => {
  const nanoid = customAlphabet('1234567890', 5)
  let pageName = "Sales Inquiry"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()
  const apiname = process.env.REACT_APP_SALES_INQUIRY_API?.toString()
  const { id } = useParams()
  const { data, refetch: data_refetch }: any = useFetchSingle(id, apiname)

  const [productData, setProductData]: any = useState([])

  const [editableProductData, setEditableProductData] = useState([]);


  // useEffect(() => {
  //   if (data?.product.length > 0) {
  //     setProductData(data?.product)
  //     setEditableProductData(data?.product);
  //   }
  // }, [data])


  const { mutate: sales, isSuccess, isError, error, data: data_response }: any = useUpdateSingleforcewithyes()

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();


  const [openEditModel, setOpenEditModel] = useState(false)
  const [confirmmodal, setConfirmmodal] = useState(false)
  const [valuesofform, setValuesofform] = useState<any>()
  const [expirationDate, setExpirationDate] = useState()

  const { mutate: productPatch, isSuccess:productPatchSuccess, isError:productPatchIsError, error:productPatchError, data: dataProductPatch }: any = useUpdateSingleforcewithyes()

  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

  const [visitingCard, setVisitingCard] = useState(null)
  const onLogoChange = (e: any) => {
    setVisitingCard(e.target.files[0].name)
  }



  const [additionalFields, setAdditionalFields]: any = useState([]);
  const [fieldCounter, setFieldCounter] = useState(2);
  const [fieldErrors, setFieldErrors]: any = useState(Array(additionalFields.length).fill({}));

  const [noProductError, setNoProductError] = useState(false)


  const onSubmit = (data: any) => {



    if (!visitingCard) {
      delete data.visiting_card_image
    }

    if (companyType === "old") {

      // delete data.contact_person
      delete data.name_of_customer
      delete data.mobile_number
      delete data.email
      delete data.village_code_and_name
      delete data.address
      delete data.pincode
      delete data.reference_name


    }

    if (companyType === "new") {
      delete data.company_name_and_city
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value
      if (typeof value !== "object") {





        formData.append(key, formvalue)


      }


      else if (key === "visiting_card_image") {
        if (typeof value === 'object' && value !== null && formvalue.length > 0) {
          formData.append(key, formvalue[0])
        }
        else {
          formData.append(key, "")
        }
      }


    }

    if (Array.isArray(productIds)) {
      productIds.forEach((val: any, index: any) => formData.append(`products`, val))
    }

    // if (productIds) {
    //   formData.append('products', JSON.stringify(productIds))

    // }
    // if(companyType === "old"){
    //     formData.append('company_name_and_city', companyValue?.value)
    // }






    const newFieldErrors = additionalFields.map((field: any) => {
      const errors: any = {};

      if (!field.product_type) {
        errors.product_type = 'Field is required';
      }

      if (!field.product_special_running) {
        errors.product_special_running = 'Field is required';
      }

      // if (!field.product_code_name_type) {
      //   errors.product_code_name_type = 'Field is required';
      // }

      if (!field.product_description) {
        errors.product_description = 'Field is required';
      }

      if (!field.product_qty) {
        errors.product_qty = 'Field is required';
      }

      if (!field.expected_basic_value) {
        errors.expected_basic_value = 'Field is required';
      }

      if (!field.requirement_date) {
        errors.requirement_date = 'Date is required';
      }

      if (!field.possible_yes_no) {
        errors.possible_yes_no = 'Field is required';
      }

      return errors;
    });

    setFieldErrors(newFieldErrors);

    // Check if there are any errors
    const hasErrors = newFieldErrors.some((errors: any) => Object.keys(errors).length > 0);



    if (!hasErrors && additionalFields) {

      // additionalFields?.push(...data?.product);
      // const filteredArr = additionalFields?.map(({ id, ...rest }: any) => rest);
      // formData.append('product', JSON.stringify(filteredArr))

      const combinedProducts = [...additionalFields, ...displayData];



      const productsForSubmission = combinedProducts.map((product: any) => {
        if (additionalFields.includes(product)) {

          const { id, ...rest } = product;
          return rest;
        }

        return product;
      });





      // formData.append('products', JSON.stringify(productIds));



      let finalData = {
        id: id,
        data: formData,
        apiname: apiname
      }
      sales(finalData);
      // setValuesofform(finalData)


      if (productIds?.length === 0) {
        setNoProductError(true)
      } else {
        setNoProductError(false)
      }
      if (noProductError === false) {

        setValuesofform(finalData);
      }

    } else {
      // Display an error message or take any other necessary action
    }





    // if (additionalFields && !requiredFields) {

    // }


  }


  // useEffect(() => {
  //   if (additionalFields.length === 0) {
  //     setFieldErrors([]);
  //   }
  // }, [additionalFields]);




  const apinameLead = process.env.REACT_APP_SALES_SOURCE_INQUIRY_API?.toString()



  const { mutate: mutateLead, data: dataLead, isError: isErrorLead, isLoading: isLoadingLead, isSuccess: isSuccessLead, error: errorLead }: any = useCreate(apinameLead)



  const [optionsLead, setOptionsLead]: any = useState([]);

  const [valueLead, setValueLead]: any = useState();
  const [selectedLead, setSelectedLead]: any = useState("")


  const [valueLeadCreatable, setValueLeadCreatable] = useState<Option | null>();


  const { data: soiData, refetch: soiRefetch } = useFetchSourceOfInquiryDropDown()
  useEffect(() => {
    setOptionsLead(soiData)
  }, [soiData])







  const handleCreateLead = async (inputValue: string) => {



    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');


    setTimeout(() => {
      const newOption = createOption(inputValue);

      // setValue("name", `${newOption?.value}`)

      mutateLead({ name: newOption.name, status: newOption.status })


      setOptionsLead((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValueLeadCreatable(newOption);


    }, 0);




  };




  const handleChangeLead = (e: any) => {

    soiRefetch()

    setApiName(apinameLead)
    setDeleteApiName(apinameLead)
    setEditId(e?.value)
    setEditData(soiData)
    setRenderKey(prevKey => prevKey + 1);

    setValueLead(e)

    setValue("lead_source_of_inquiry", `${e?.value}`)
    setValueLeadCreatable(null)


  }


  useEffect(() => {
    (async () => {

      if (isSuccessLead && optionsLead && valueLeadCreatable != null) {
        await soiRefetch();

        setValue("lead_source_of_inquiry", soiData[0]?.value)

      }
    })();

    return () => {
    };
  }, [isSuccessLead, soiData]);






  const [optionsSalesExecutive, setOptionsSalesExecutive]: any = useState()
  const [SalesExecutiveValue, setSalesExecutiveValue]: any = useState("")
  const [selectedSalesExecutive, setSelectedSalesExecutive] = useState("")
  const { data: salesExecutiveData, refetch: salesExecutiveRefetch } = useFetchEmployeeDropDown()

  useEffect(() => {
    setOptionsSalesExecutive(salesExecutiveData)
  }, [salesExecutiveData])

  const handleChangeSalesExecutive = (e: any) => {
    setSalesExecutiveValue(e)
    setValue("lead_generated_by_sales_executive_code_and_name", e?.value)
  }


  const [refName, setRefName] = useState('')
  const [nameofCustomer, setNameOfCustomer] = useState('')

  const [Mobile, setMobile] = useState('')
  const [Email, setEmail] = useState('')
  const [optionsCompany, setOptionsCompany]: any = useState()
  const [companyValue, setCompanyValue]: any = useState("")
  const [selectedCompany, setSelectedCompany] = useState("")
  const { data: companyData, refetch: companyRefetch } = useFetchCustomerDropDown()
  // const { data: companyData, refetch: companyRefetch } = useFetchEmployeeDropDown()

  useEffect(() => {
    setOptionsCompany(companyData)
  }, [companyData])


  // useEffect(() => {
  //   let temp = companyData?.map((e: any) => {

  //     return (
  //       {
  //         value: e?.value,
  //         label: `${e?.customerName} ${e.cityName}`,
  //         name: e?.name,
  //         city: e.cityName,
  //         countryCode: e?.countryCode,
  //         mobile: e?.mobile,
  //         email: e?.email,
  //         reference: `${e?.reference}`,

  //       }
  //     )
  //   })
  //   setOptionsCompany(temp)
  // }, [companyData])


  const handleChangeCompany = (e: any) => {
    setCompanyValue(e)
    setValue("company_name_and_city", e?.value)
    setTaluka(e?.taluka)
    setDistrict(e?.district)
    setState(e?.state)
    setCountry(e?.countryCode)
    setMobile(e?.mobile)
    setEmail(e?.email)
    setRefName(e?.reference)
    setSelectedOldCity(e?.city)
    setNameOfCustomer(e?.name)
    setValue("name_of_customer", e?.name)
    setValue("mobile_number", e?.mobile)
    setValue("email", e?.email)
    setValue("village_code_and_name", e?.city)
    setValue("pincode", e?.pincode)
    setValue("address", e?.address)
    setValue("reference_name", e?.reference)


  }


  const [optionsProductType, setOptionsProductType]: any = useState()

  const [selectedProductType, setSelectedProductType] = useState("")
  const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown()

  useEffect(() => {
    setOptionsProductType(productTypeData)
  }, [productTypeData])

  // const handleChangeProductType=(e:any)=>{
  //     setValueProductType(e)
  //     setValue("product_type",e?.value)
  // }

  const [companyType, setCompanyType] = useState("")

  const handleCompanyType = (e: any) => {
    setCompanyType(e.target.value)

  }



  const [optionsDisposableProduct, setOptionsDisposableProduct]: any = useState()
  const [valueDisposableProduct, setValueDisposableProduct]: any = useState("")
  const [selectedDisposableProduct, setSelectedDisposableProduct] = useState("")
  const { data: disposableProductData, refetch: disposableProductRefetch } = useFetchDisposableDropDown()

  useEffect(() => {
    setOptionsDisposableProduct(disposableProductData)
  }, [disposableProductData])

  // const handleChangeDisposableProduct=(e:any)=>{
  //     setValueDisposableProduct(e)
  //     setValue("product_code_name_type",e?.value)
  // }


  const [optionsCity, setOptionsCity]: any = useState([])
  const [valueCity, setValueCity]: any = useState("")
  const [selectedCity, setSelectedCity] = useState("")
  const [selectedOldCity, setSelectedOldCity] = useState("")
  const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

  useEffect(() => {
    setOptionsCity(cityData)
  }, [cityData])



  const [taluka, setTaluka] = useState("")
  const [district, setDistrict] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")

  const handleChangeCity = (e: any) => {
    setValueCity(e)
    setValue("village_code_and_name", e?.value)

    setTaluka(e?.taluka)
    setDistrict(e?.district)
    setState(e?.state)
    setCountry(e?.countryCode)

    setPincodeByCity(e?.pincode)
  }

  const [pincodeByCity, setPincodeByCity]: any = useState()

  const [optionsPincode, setOptionsPincode]: any = useState()
  const [valuePincode, setValuePincode]: any = useState("")
  const [selectedPincode, setSelectedPincode] = useState("")
  const [selectedOldPincode, setSelectedOldPincode] = useState("")
  const { data: pincodeData, refetch: pincodeRefetch } = useFetchPincodeDropDown(pincodeByCity)

  useEffect(() => {
    setOptionsPincode(pincodeData)
  }, [pincodeData])

  const handleChangePincode = (e: any) => {
    setValuePincode(e)
    setValue("pincode", e?.value)
  }


  const [requirementDate, setRequirementDate]:any = useState(null)

  const dateFormatList = ['DD-MM-YYYY'];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {






    return current && current < dayjs().startOf('day');
  };


  //       const handleRequirementDate=(e:any)=>{
  // setRequirementDate(e)
  // setValue("requirement_date",`${e?.$D}-${e?.$M+1}-${e?.$y}`)
  //       }

  const [leadContactedDate, setLeadContactedDate] = useState(null)
  const handleLeadContactedDate = (e: any) => {
    setLeadContactedDate(e)
    setValue("lead_contacted", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
  }



  let dateOne = data?.lead_contacted
  let fromDateValue = dateOne







  const [selectedProductTypes, setSelectedProductTypes]: any = useState([]);

  // const handleAddProduct = () => {
  //   const newProduct = {
  //     id: nanoid(),
  //     product_type: null,
  //     product_special_running: '',
  //     // product_code_name_type: null,
  //     percentage_or_amount: '',
  //     kg_or_carton: '',
  //     with_or_without_gst: '',
  //     discount: '',
  //     product_description: '',
  //     product_qty: '',
  //     requirement_date: null,
  //     expected_basic_value: '',
  //     possible_yes_no: '',
  //   };

  //   const updatedFields = [...additionalFields, newProduct];
  //   setAdditionalFields(updatedFields);




  // };







  // const handleDeleteProduct = (id: any) => {

  //   const updatedFields = additionalFields.filter((field: any) => field.id !== id);
  //   setAdditionalFields(updatedFields);


  // };


  // const handleSpecialRunningChange = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].product_special_running = e.target.value;
  //   setAdditionalFields(updatedFields);
  // };

  // const handleYesNoChange = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].possible_yes_no = e.target.value;
  //   setAdditionalFields(updatedFields);
  // };



  // let apiNamePricelistProduct = `${process.env.REACT_APP_PRICE_LIST_PRODUCTS_API}/search_filter`
  // const { mutate: mutatePricelistProduct, data: dataPricelistProduct }: any = useCreate(apiNamePricelistProduct)

  // const [comingData, setComingData]: any = useState([])

  // const [optionsCodeName, setOptionsCodeName] = useState([])


  // let apiNamePricelistProduct = `${process.env.REACT_APP_PRICE_LIST_PRODUCTS_API}/search_filter`
  let apiNamePricelistProduct = `${process.env.REACT_APP_PRICELIST_API}/search_filter`
  const { mutate: mutatePricelistProduct, data: dataPricelistProduct, isSuccess: priceListIsSuccess } = useCreate(apiNamePricelistProduct)

  const [comingData, setComingData]: any = useState([])

  const [optionsCodeName, setOptionsCodeName]: any = useState([])

  const [priceListProductType, setPriceListProductType]: any = useState()

  const [productType, setProductType]: any = useState('')

  const [priceListId,setPriceListId]:any = useState('')

  
  
    useEffect(() => {
      if (dataPricelistProduct) {
        let temp = dataPricelistProduct?.results[0]?.price_list_products
          ?.map((e: any) => ({
            value: e?.id,
            // label: e?.product_code?.product_code,
            
            label: `${e?.product_code?.product_code}::${e?.product_code?.mouldshape?.name}::${e?.product_code?.product_type?.type}`,
         
            // priceListID: e?.price_list
          }));
  
          
          
          
          
          setOptionsCodeName(temp)
          let tempId = dataPricelistProduct?.results[0]?.id
          setPriceListId(tempId)
      }
    }, [dataPricelistProduct])






  // const [priceListProductType, setPriceListProductType]: any = useState()

  // const handleChangeProductType = (selectedOption: any, index: any) => {
  //   const updatedFields = [...additionalFields];


  //   updatedFields[index].product_type = selectedOption.value;
  //   setAdditionalFields(updatedFields);


  // setPriceListProductType(selectedOption?.label)





  // };

  // const [previousPriceListProductType, setPreviousPriceListProductType] = useState('')

  const [previousPriceListProductType, setPreviousPriceListProductType] = useState('')


  // useEffect(()=>{

  //   if (priceListProductType) {
  //     if (priceListProductType !== previousPriceListProductType) {
  //       mutatePricelistProduct({ product_type: priceListProductType });
  //       setPreviousPriceListProductType(priceListProductType);
  //     }
  //   }


  // },[priceListProductType,previousPriceListProductType])



  // let apinameForNewProductPricelist = `${process.env.REACT_APP_PRICELIST_API}/search_filter`

  // const { mutate: newProductPricelist, data: dataOfNewProductPricelist } = useCreate(apinameForNewProductPricelist)




  // const handleChangeDisposableProduct = (selectedOption:any, index:any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].pricelist_product = selectedOption.value;

  //   setAdditionalFields(updatedFields);
  // };

  // const [newProductID, setNewProductID] = useState("")
  // const [newProductPricelistId, setNewProductPricelistId] = useState()
  // const [newProductCode, setNewProductCode] = useState("")


  // const handleChangePriceList = async (selectedOption: any, index: any) => {
  //   const updatedFields = [...additionalFields];

  //   updatedFields[index].pricelist_product = selectedOption.value;
  //   updatedFields[index].pricelist = selectedOption.priceListID;
  //   setAdditionalFields(updatedFields);

  //   setNewProductID(selectedOption?.value)


  //   setNewProductCode(selectedOption?.productCode)



  // };
  const [newProductID, setNewProductID]: any = useState("")
  const [newProductPricelistId, setNewProductPricelistId] = useState("")
  const [newProductCode, setNewProductCode] = useState("")


  const [optionsUOM, setOptionsUOM] = useState([])
  const { data: dataUOM, refetch: refetchUOM } = useFetchUOMDropDown()
  useEffect(() => {
    setOptionsUOM(dataUOM)
  }, [dataUOM])


  // const handleChangeUOM = async (selectedOption: any, index: any) => {
  //   const updatedFields = [...additionalFields];

  //   updatedFields[index].uom = selectedOption.value;
  //   setAdditionalFields(updatedFields);
  // };
  const { mutate: salesUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate, data: dataUpdate }: any = useUpdateSingle()



  const onSubmitFinal = async () => {





    const formData = new FormData();



    const newFieldErrors = additionalFields.map((field: any) => {
      const errors: any = {};

      if (!field.product_type) {
        errors.product_type = 'Field is required';
      }

      if (!field.product_special_running) {
        errors.product_special_running = 'Field is required';
      }

      if (!field.pricelist_product) {
        errors.pricelist_product = 'Field is required';
      }

      if (!field.uom) {
        errors.uom = 'Field is required';
      }


      if (!field.product_description) {
        errors.product_description = 'Field is required';
      }

      if (!field.product_qty) {
        errors.product_qty = 'Field is required';
      }

      if (!field.expected_basic_value) {
        errors.expected_basic_value = 'Field is required';
      }

      if (!field.requirement_date) {
        errors.requirement_date = 'Date is required';
      }

      if (!field.possible_yes_no) {
        errors.possible_yes_no = 'Field is required';
      }

      return errors;
    });

    setFieldErrors(newFieldErrors);

    const hasErrors = newFieldErrors.some((errors: any) => Object.keys(errors).length > 0);

    if (Array.isArray(productIds)) {
      productIds.forEach((val: any, index: any) => formData.append(`products`, val))
    }

    // let tempData = {
    //   products:productIds
    // }


    let finalData = {
      id: id,
      data: formData,
      apiname: apiname
    }

    if (displayData?.length === 0) {
      setNoProductError(true)
    } else {
      await salesUpdate(finalData);
    }




  }







  let apinameForNewProductPricelist = `${process.env.REACT_APP_PRICELIST_API}/search_filter`

  const { mutate: newProductPricelist, data: dataOfNewProductPricelist } = useCreate(apinameForNewProductPricelist)










  useEffect(() => {
    if (newProductID) {
      newProductPricelist({ price_list_products: newProductID })

    }
  }, [newProductID])

  useEffect(() => {
    if (dataOfNewProductPricelist) {
      setNewProductPricelistId(dataOfNewProductPricelist?.results[0]?.id)
    }
  }, [dataOfNewProductPricelist, newProductPricelistId])


  //   const[valueProductType,setValueProductType]:any = useState("")


  // const handleChangeProductType = (selectedOption:any, index:any) => {

  //   const updatedFields = [...additionalFields];


  //   updatedFields[index].product_type = selectedOption.value;
  //   setAdditionalFields(updatedFields);


  // };

  // const handleChangeDisposableProduct = (selectedOption: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].product_code_name_type = selectedOption.value;

  //   setAdditionalFields(updatedFields);
  // };

  // const handleRequirementDate = (date: any, index: any) => {
  //   const updatedFields = [...additionalFields];

  //   let finalDate = date?.$D
  //   let finalMonth = date?.$M + 1
  //   let finalYear = date?.$y

  //   let final = `${finalDate}/${finalMonth}/${finalYear}`


  //   updatedFields[index].requirement_date = final;
  //   setAdditionalFields(updatedFields);
  // };

  // const handleDescriptionChange = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].product_description = e.target.value;
  //   setAdditionalFields(updatedFields);
  // };

  // const handleChangeProductQty = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].product_qty = e.target.value;
  //   setAdditionalFields(updatedFields);
  // };

  // const handleChangeBasicValue = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].expected_basic_value = e.target.value;
  //   setAdditionalFields(updatedFields);
  // }

  // const handleDiscountTypeChange = (e: any, index: any) => {

  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].percentage_or_amount = e.target.value

  //   if (updatedFields[index].kg_or_carton === "KG") {
  //   } else if (updatedFields[index].kg_or_carton === "Carton") {
  //   }

  //   setAdditionalFields(updatedFields);



  // };

  // const [discounton,setDiscountOn] = useState('')

  const handleDiscountDataChange = (e: any, index: any) => {

    const eidtableUpdated: any = [...editableProductData]
    const updatedProducts: any = editableProductData.map((item: any, idx: any) => {
      if (idx === index) {
        return { ...item, discount: e.target.value };
      }
      return item;
    });
    setEditableProductData(updatedProducts);




    if (eidtableUpdated[index].percentage_or_amount === "Percentage") {
      if (eidtableUpdated[index].kg_or_carton === "KG") {
        let calculate = ((Number(eidtableUpdated[index].discount) / 100) * Number(eidtableUpdated[index].kg))
        let percentage = (Number(eidtableUpdated[index].discount))
        eidtableUpdated[index].discount_percentage = percentage

        eidtableUpdated[index].calculate = calculate
        let discount = (Number(eidtableUpdated[index].kg) - Number(eidtableUpdated[index].calculate))
        eidtableUpdated[index].final_amount = discount
      }
      if (eidtableUpdated[index].kg_or_carton === "Carton") {
        let calculate = ((Number(eidtableUpdated[index].discount) / 100) * Number(eidtableUpdated[index].carton))
        let percentage = (Number(eidtableUpdated[index].discount))
        eidtableUpdated[index].discount_percentage = percentage
        eidtableUpdated[index].calculate = calculate
        let discount = (Number(eidtableUpdated[index].carton) - Number(eidtableUpdated[index].calculate))
        eidtableUpdated[index].final_amount = discount
      }
    }
    else if (eidtableUpdated[index].percentage_or_amount === "Amount") {
      if (eidtableUpdated[index].kg_or_carton === "KG") {
        let discount = ((Number(eidtableUpdated[index].discount) / Number(eidtableUpdated[index].kg)) * 100)
        let finalAmount = (Number(eidtableUpdated[index].kg) - Number(eidtableUpdated[index].discount))
        eidtableUpdated[index].final_amount = finalAmount
        eidtableUpdated[index].discount_percentage = discount
      }
      if (eidtableUpdated[index].kg_or_carton === "Carton") {
        let discount = ((Number(eidtableUpdated[index].discount) / Number(eidtableUpdated[index].carton)) * 100)
        let finalAmount = (Number(eidtableUpdated[index].carton) - Number(eidtableUpdated[index].discount))
        eidtableUpdated[index].final_amount = finalAmount
        eidtableUpdated[index].discount_percentage = discount
      }
    }
  };


  const handleDiscountOnDataChange = (e: any, index: any) => {
    const updatedProducts: any = editableProductData.map((item: any, idx: any) => {
      if (idx === index) {
        return { ...item, kg_or_carton: e.target.value };
      }
      return item;
    });
    setEditableProductData(updatedProducts);



  };

  const handleDiscountTypeDataChange = (e: any, index: any) => {
    const updatedProducts: any = editableProductData.map((item: any, idx: any) => {
      if (idx === index) {
        return { ...item, percentage_or_amount: e.target.value };
      }
      return item;
    });
    setEditableProductData(updatedProducts);
  };

  const handleDiscountWithDataChange = (e: any, index: any) => {
    const updatedProducts: any = editableProductData.map((item: any, idx: any) => {
      if (idx === index) {
        return { ...item, with_or_without_gst: e.target.value };
      }
      return item;
    });
    setEditableProductData(updatedProducts);
  };




  // const handleDiscountChange = (e:any, index:any) => {

  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].discount = e?.target?.value;
  //   setAdditionalFields(updatedFields);




  //     if (updatedFields[index].percentage_or_amount === "Percentage") {
  //       if (updatedFields[index].kg_or_carton === "KG") {
  //         let calculate = ((Number(updatedFields[index].discount) / 100) * Number(updatedFields[index].kg))
  //         let percentage = (Number(updatedFields[index].discount))
  //         updatedFields[index].discount_percentage = percentage

  //         updatedFields[index].calculate = calculate
  //         let discount = (Number(updatedFields[index].kg) - Number(updatedFields[index].calculate))
  //         updatedFields[index].final_amount = discount
  //       }
  //       if (updatedFields[index].kg_or_carton === "Carton") {
  //         let calculate = ((Number(updatedFields[index].discount) / 100) * Number(updatedFields[index].carton))
  //         let percentage = (Number(updatedFields[index].discount))
  //         updatedFields[index].discount_percentage = percentage
  //         updatedFields[index].calculate = calculate
  //         let discount = (Number(updatedFields[index].carton) - Number(updatedFields[index].calculate))
  //         updatedFields[index].final_amount = discount
  //       }
  //     }
  //     else if (updatedFields[index].percentage_or_amount === "Amount") {
  //       if (updatedFields[index].kg_or_carton === "KG") {
  //         let discount = ((Number(updatedFields[index].discount) / Number(updatedFields[index].kg)) * 100)
  //         let finalAmount = (Number(updatedFields[index].kg) - Number(updatedFields[index].discount))
  //         updatedFields[index].final_amount = finalAmount
  //         updatedFields[index].discount_percentage = discount
  //       }
  //       if (updatedFields[index].kg_or_carton === "Carton") {
  //         let discount = ((Number(updatedFields[index].discount) / Number(updatedFields[index].carton)) * 100)
  //         let finalAmount = (Number(updatedFields[index].carton) - Number(updatedFields[index].discount))
  //         updatedFields[index].final_amount = finalAmount
  //         updatedFields[index].discount_percentage = discount
  //       }
  //     }
  // };



  const handleDiscountOnOldChange = (e: any, index: any) => {


    const updatedFields = [...additionalFields];
    updatedFields[index].kg_or_carton = e.target.value;


    updatedFields[index].kg = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate
    updatedFields[index].carton = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate

    if (e.target.value == "KG") {
      updatedFields[index].final_amount = updatedFields[index].kg
    } else if (e.target.value == "Carton") {
      updatedFields[index].final_amount = updatedFields[index].carton
    }


    setAdditionalFields(updatedFields);






  };



  // const handleDiscountWithChange = (e: any, index: any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].with_or_without_gst = e?.target?.value;
  //   setAdditionalFields(updatedFields);
  // };








  // const handleDiscountChange = (e: any, index: any) => {

  //   

  //   const updatedFields: any = [...additionalFields];
  //   updatedFields[index].discount = e?.target?.value

  //   setAdditionalFields(updatedFields);






  //   if (updatedFields[index].percentage_or_amount === "Percentage") {
  //     if (updatedFields[index].kg_or_carton === "KG") {
  //       let calculate = ((Number(updatedFields[index].discount) / 100) * Number(updatedFields[index].kg))
  //       let percentage = (Number(updatedFields[index].discount))
  //       updatedFields[index].discount_percentage = percentage

  //       updatedFields[index].calculate = calculate
  //       let discount = (Number(updatedFields[index].kg) - Number(updatedFields[index].calculate))
  //       updatedFields[index].final_amount = discount
  //     }
  //     if (updatedFields[index].kg_or_carton === "Carton") {
  //       let calculate = ((Number(updatedFields[index].discount) / 100) * Number(updatedFields[index].carton))
  //       let percentage = (Number(updatedFields[index].discount))
  //       updatedFields[index].discount_percentage = percentage
  //       updatedFields[index].calculate = calculate
  //       let discount = (Number(updatedFields[index].carton) - Number(updatedFields[index].calculate))
  //       updatedFields[index].final_amount = discount
  //     }
  //   }
  //   else if (updatedFields[index].percentage_or_amount === "Amount") {
  //     if (updatedFields[index].kg_or_carton === "KG") {
  //       let discount = ((Number(updatedFields[index].discount) / Number(updatedFields[index].kg)) * 100)
  //       let finalAmount = (Number(updatedFields[index].kg) - Number(updatedFields[index].discount))
  //       updatedFields[index].final_amount = finalAmount
  //       updatedFields[index].discount_percentage = discount
  //     }
  //     if (updatedFields[index].kg_or_carton === "Carton") {
  //       let discount = ((Number(updatedFields[index].discount) / Number(updatedFields[index].carton)) * 100)
  //       let finalAmount = (Number(updatedFields[index].carton) - Number(updatedFields[index].discount))
  //       updatedFields[index].final_amount = finalAmount
  //       updatedFields[index].discount_percentage = discount
  //     }
  //   }
  //   // updatedFields[index].avg_discount = updatedFields[index].final_amount
  // };












  const [DiscountWise, setDiscountWise] = useState('')
  const [DiscountType, setDiscountType]: any = useState('')
  const [Discount, setDiscount]: any = useState('')
  const [finalAmount, setFinalAmount]: any = useState(0)
  const [totalAmount, setTotalAmount]: any = useState(0)

  const onDiscountWiseChange = (e: any) => {
    setDiscountWise(e.target.value)
  }
  const onDiscountTypeChange = (e: any) => {
    setDiscountType(e.target.value)
  }
  const onDiscountChange = (e: any) => {
    setDiscount(e.target.value)
  }


  useEffect(() => {

    if (additionalFields) {

      const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {
        return total + item.final_amount;
      }, 0)
      setFinalAmount(Number(totalFinalAmount))
      setTotalAmount(Number(totalFinalAmount))

    }




  }, [additionalFields])


  const [avgDiscount, setAvgDiscount]: any = useState()

  //   useEffect(() => {
  // // 
  //     if (additionalFields) {

  //       const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {

  //         return total + item.discount_percentage/additionalFields.length;
  //       }, 0);
  //       setAvgDiscount(Number(totalFinalAmount))
  //      setValue("avg_discount",avgDiscount)
  //     }

  //     if (editableProductData) {

  //       const totalFinalAmount = editableProductData?.reduce((total: any, item: any) => {

  //         return total + item.discount/editableProductData.length;
  //       }, 0);
  //       setAvgDiscount(Number(totalFinalAmount))
  //       setValue("avg_discount",avgDiscount)
  //     }

  //   }, [additionalFields,editableProductData])

  useEffect(() => {

    let totalDiscount = 0;
    let totalItems = 0;

    if (additionalFields && additionalFields.length > 0) {
      totalDiscount += additionalFields.reduce((total: any, item: any) => Number(total) + Number(item.discount_percentage), 0);
      totalItems += additionalFields.length;
    }

    if (editableProductData && editableProductData.length > 0) {
      totalDiscount += editableProductData.reduce((total: any, item: any) => Number(total) + Number(item.discount), 0);
      totalItems += editableProductData.length;
    }

    const averageDiscount = totalItems > 0 ? Number((totalDiscount / totalItems).toFixed(2)) : 0;
    setAvgDiscount(averageDiscount);
    setValue("avg_discount", averageDiscount);
  }, [additionalFields, editableProductData]);



  // const [totalDiscount, setTotalDiscount]: any = useState(0)
  const [totalDiscount, setTotalDiscount]: any = useState(0)

  useEffect(() => {
    if (Discount == '') {
      setTotalDiscount(finalAmount)
    }
    else {
      setTotalDiscount(totalAmount)

    }

  }, [totalDiscount])


  useEffect(() => {
  }, [additionalFields])


  const [address, setAddress] = useState('')
  // const [country, setCountry] = useState("")


  const [added, setAdded] = useState(false)
  const [displayData, setDisplayData] = useState([])
  const [productIds, setProductIds]: any = useState([])


  const [isEdit, setIsEdit] = useState(false)


  const apinameCity = process.env.REACT_APP_CITY_API?.toString()
  const apinameCustomer = process.env.REACT_APP_PERSON_CUSTOMER_API?.toString()


  useEffect(()=>{
    if (data?.extra_response?.company_name_and_city?.city[0]?.city_id && data?.company_new_old === 'new') {
      const getCall = async () => {
          try {
              const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCity}/?id=${data?.extra_response?.company_name_and_city?.city[0]?.city_id}`);
  
      setSelectedCity(response?.data?.results[0]?.id)
              setValue("village_code_and_name", response?.data?.results[0]?.id)
          } catch (error) {
              console.error('Error:', error);
          }
      }
      getCall()
  }
    if (data?.extra_response?.company_name_and_city  && data?.company_new_old === 'new') {
      const getCall = async () => {
          try {
              const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCustomer}/?id=${data?.extra_response?.company_name_and_city}`);
  
              setSelectedCompany(response?.data?.results[0]?.id)
              setValue("company_name_and_city", response?.data?.results[0]?.id)
          } catch (error) {
              console.error('Error:', error);
          }
      }
      getCall()
  }
  },[data])



  useEffect(() => {
    if (data) {

      reset(data)

      setIsEdit(false)
      if (!isEdit) {
        setSalesInquirydata(data)

      }




      // setValue("lead_generated_by_sales_executive_code_and_name", data?.lead_generated_by_sales_executive_code_and_name?.id)
      // setSelectedSalesExecutive(data?.lead_generated_by_sales_executive_code_and_name?.id)


      setValue("lead_source_of_inquiry", data?.lead_source_of_inquiry?.id)
      setSelectedLead(data?.lead_source_of_inquiry?.id)

      setValue("company_name_and_city", data?.extra_response?.company_name_and_city?.customer_name_id)
      // setSelectedCompany(data?.company_name_and_city?.id)
      setSelectedCompany( data?.extra_response?.company_name_and_city?.customer_name_id)
      // setValue("product_type",data?.product_type?.id)
      // setSelectedProductType(data?.product_type?.id)

      // setValue("product_code_name_type",data?.product_code_name_type?.id)
      // setSelectedDisposableProduct(data?.product_code_name_type?.id)
      // setSelectedCity(data?.company_name_and_city?.address[0]?.city?.id)
      setValue("name_of_customer", data?.extra_response?.company_name_and_city?.name)
      setValue("mobile_number", data?.extra_response?.company_name_and_city?.contact_detail_number)
      setValue("email", data?.extra_response?.company_name_and_city?.email_address)
      setValue("reference_name", `${data.extra_response?.company_name_and_city?.reference_name ? data.extra_response?.company_name_and_city?.reference_name : data.extra_response?.company_name_and_city?.other_reference}`)
      setValue("country_code", data?.extra_response?.company_name_and_city?.country_code_mobile[0])
      setValue("village_code_and_name", data?.extra_response?.company_name_and_city?.city[0]?.city_name    ?data?.extra_response?.company_name_and_city?.city[0]?.city_name :   data?.extra_response?.company_name_and_city?.city[0]?.village_name)
      // setSelectedCity(data?.company_name_and_city?.address[0]?.city_and_village?.id)
      // setSelectedOldCity(data?.company_name_and_city?.city_name_and_distict_name?.city_name?data?.company_name_and_city?.city_name_and_distict_name?.city_name:data?.company_name_and_city?.city_name_and_distict_name?.village_name)
      setSelectedOldCity(`${data?.company_name_and_city?.city_name_and_distict_name?.city_name ? data?.company_name_and_city?.city_name_and_distict_name?.city_name : data?.company_name_and_city?.city_name_and_distict_name?.village_name}-${data?.company_name_and_city?.city_name_and_distict_name?.district?.name}`)

      setCountry(data?.company_name_and_city?.city_name_and_distict_name?.country?.country_code_mobile)
    
      setSelectedGrade(data?.extra_response?.company_name_and_city?.customer_grade)
      setValue("customer_grade",data?.extra_response?.company_name_and_city?.customer_grade)
      setCompanyType(data?.company_new_old)
      setDiscountWise(data?.discount_type)
      setValue("final_amount", data?.final_amount)
      setTotalAmount(data?.final_amount)
      setValue("product", data?.product)
      setNameOfCustomer(data.company_name_and_city?.name)
      setMobile(data.company_name_and_city?.contact_detail?.number)
      setEmail(data.company_name_and_city?.contact_detail?.email_address)
      
      setRefName(`${data.company_name_and_city?.reference?.name ? data.company_name_and_city?.reference?.name : data.company_name_and_city?.other_reference}`)
      setFinalAmount(data?.final_amount)
     
      if (data?.product.length > 0) {
        // let oldProduct = data?.product?.map((e: any) => {
        //   return e?.id
        // })

        setProductIds(data?.product);
        
      }


    }
  }, [data]);







  useEffect(() => {
    if (displayData?.length > 0) {
      setNoProductError(false)
    }
  }, [displayData])

  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccessUpdate || yessuccess || nosuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listSalesinquiry", { state: { showEditToast: true } });
    } else if (isErrorUpdate) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [dataUpdate, isSuccessUpdate, yessuccess, nosuccess, data_response, errorUpdate,
    isSuccessUpdate,]);
  useEffect(() => {
    if (isErrorUpdate) {
      if (errorUpdate.response?.status === 405) {
        setConfirmmodal(true);
      }
    } else if (isErrorUpdate || yeserror || noerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [isErrorUpdate, yeserror, noerror]);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }
  const noupdatemodal = () => {
    setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {

    delete data.created_at
    delete data.created_by
    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
  }






  useEffect(() => {


    if (DiscountType == "Percentage") {

      let calculated = ((Number(Discount) / 100) * Number(finalAmount))
      // setPerTotal(Number(calculated))


      let discount = (Number(finalAmount) - Number(calculated))
      setTotalAmount(Number(discount))
      setValue("discount_percentage", Discount)
      setValue("final_amount", totalAmount)
      // setValue("avg_discount", discount)
    }
    else if (DiscountType === "Amount") {
      // 
      let discount = ((Number(Discount) / Number(finalAmount)) * 100)
      let totalAmount = (Number(finalAmount) - Number(Discount))

      setTotalAmount(Number(totalAmount))
      setValue("discount_percentage", discount)
      setValue("final_amount", totalAmount)
      // setValue("avg_discount", discount)

    }

    // setValue("final_amount",)
  }, [DiscountType, Discount, totalAmount, finalAmount])


  const apinameAddProduct = process.env.REACT_APP_SALES_ADD_PRODUCT_API?.toString();

  const {
    mutate: addProduct,
    data: addProductData,
    isError: addProductIsError,
    isLoading: addProductIsLoading,
    isSuccess: addProductIsSuccess,
    error: addProductError,
  }: any = useCreate(apinameAddProduct);


  const apinameGetMultipleProduct = process.env.REACT_APP_SALES_GET_MULTIPLE_PRODUCTS_API?.toString();




  const {
    mutate: getProduct,
    data: getProductData,
    isError: getProductIsError,
    isLoading: getProductIsLoading,
    isSuccess: getProductIsSuccess,
    error: getProductError,
  }: any = useCreate(apinameGetMultipleProduct);


  const { register: registerProduct, handleSubmit: handleSubmitProduct, setError: setErrorProduct, formState: formStateProduct, reset: resetProduct, setValue: setValueProduct, getValues:productGetValues }:any = useForm({
    mode: "onChange"
  });
  
  const { errors: errorsProduct } = formStateProduct;


  const { register: registerProductUpdate, handleSubmit: handleSubmitProductUpdate, setError: setErrorProductUpdate, formState: formStateProductUpdate, reset: resetProductUpdate, setValue: setValueProductUpdate,getValues:productUpdateGetValues }: any = useForm({
    mode: "onChange"
  });
  
  const { errors: errorsProductUpdate } = formStateProductUpdate;






  useEffect(() => {
    if (getProductData) {
      setDisplayData(getProductData?.data)
    }
  }, [getProductData])

  useEffect(() => {
    if (productIds) {
      getProduct({ ids: productIds })
    }
  }, [productIds,productPatchSuccess,dataProductPatch])



  useEffect(() => {
    if (added && addProductIsSuccess) {
      if (addProductData) {
        setProductIds((oldIds: any) => [...oldIds, addProductData?.data?.id]);



        setAdded(false)
      }


    }

  }, [addProductData, addProductIsSuccess, added])





  const [renderKey1, setRenderKey1]: any = useState(false)


  const[activeEditId,setActiveEditId] = useState()
  const handlePatchProduct = async(productData:any) => {
    const formData = new FormData();

    if (DiscountWise === '') {
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }


    if (specialRunning === 'special') {
      delete productData.pricelist_product
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }
    for (const [key, value] of Object.entries(productData)) {
      const formvalue: any = value
      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
    }

    let finalData = {
      id: activeEditId,
      data: formData,
      apiname: apinameAddProduct
    }
    await productPatch(finalData);
    setAdded(true)


    // setProductModel(false)
    setRenderKey1((key: any) => key + 1)
    // resetProduct()
    setProductType(null)
    setUom(null)
    setCodeNameType(null)
    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    // setSpecialRunning(null)
    setValueProductUpdate('product_type','')
    setValueProduct('pricelist_product','')
    setValueProductUpdate("product_special_running", "")
    setValueProductUpdate("product_description", "")
    setValueProductUpdate("product_qty", "")
    // setValueProductUpdate('requirement_date', '')
    setValueProductUpdate('expected_basic_value', '')
    setValueProductUpdate('remark', '')
    setValueProductUpdate('possible_yes_no', '')
    // setRequirementDate(null)

    
  }



  const handleAddProduct = async(productData: any) => {

    const formData = new FormData();



    if (DiscountWise === '') {
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }


    if (specialRunning === 'special') {
      delete productData.pricelist_product
      delete productData.kg_or_carton
      delete productData.percentage_or_amount
      delete productData.with_or_without_gst
      delete productData.discount
      delete productData.final_amount
    }
    for (const [key, value] of Object.entries(productData)) {
      const formvalue: any = value
      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
    }


    await addProduct(formData);
    setAdded(true)


    setProductModel(false)
    setRenderKey1((key: any) => key + 1)
   
    setProductType(null)
    setUom(null)
    setCodeNameType(null)
    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    setValueProduct('product_type','')
    setValueProduct('pricelist_product','')
    setValueProduct("product_special_running", "")
    setValueProduct("product_description", "")
    setValueProduct("product_qty", "")
    setValueProduct('expected_basic_value', '')
    setValueProduct('remark', '')
    setValueProduct('possible_yes_no', '')


  




  };



  const handleDeleteProduct = async (id: any) => {
    // const updatedFields = additionalFields.filter((field: any) => field.id !== id);
    // setAdditionalFields(updatedFields);
    await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apinameAddProduct}/${id}/`)

    setProductIds((currentIds: any) => currentIds.filter((currentId: any) => currentId !== id));

    getProduct({ ids: productIds })
    setRenderKey1((key: any) => key + 1)


  };

  const [specialRunning, setSpecialRunning] = useState('')
  const handleSpecialRunningChange = (e: any, index: any) => {
    setSpecialRunning(e.target.value)


    // setValue('product_special_running', e.target.value)

  };

  // useEffect(() => {
  //   if (specialRunning === 'special') {
  //     setCodeNameType(null)
  //   } else {
  //     setCodeNameType(codeNameType)
  //     setValueProductUpdate('pricelist_product', codeNameType?.value)
  //   }
  // }, [specialRunning])




  // const [discountType,setDiscountType] = useState('')


  // const[discountType,setDiscountType] = useState('')

  const handleDiscountTypeChange = (e: any, index: any) => {


    setDiscountType(e.target.value)
    setValueProduct('percentage_or_amount', e.target.value)
    // const updatedFields = [...additionalFields];
    // updatedFields[index].percentage_or_amount = e.target.value
    // setKgPrice(updatedFields[index].discount_type)

    // if (updatedFields[index].kg_or_carton === "KG") {
    // } else if (updatedFields[index].kg_or_carton === "Carton") {
    // }

    // setAdditionalFields(updatedFields);



  };

  const [discountOn, setDiscountOn]: any = useState('')
  const [productKG, setProductKG] = useState('')
  const [productCarton, setProductCarton] = useState('')
  const handleDiscountOnChange = (e: any, index: any) => {



    setDiscountOn(e.target.value)

    setValueProduct('kg_or_carton', e.target.value)


    // const updatedFields = [...additionalFields];
    // updatedFields[index].kg_or_carton = e.target.value;


    // updatedFields[index].kg = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate
    // updatedFields[index].carton = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate


    let kg = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate
    let carton = dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate

    setProductKG(kg)
    setProductCarton(carton)
    if (e.target.value == "KG") {
      // updatedFields[index].final_amount = updatedFields[index].kg
      setFinalAmount(kg)
    } else if (e.target.value == "Carton") {
      // updatedFields[index].final_amount = updatedFields[index].carton
      setFinalAmount(carton)
    }

    // setKgPrice(dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_one_kg_rate)
    // setCartonPrice(dataOfNewProductPricelist?.results[0]?.price_list_products[0]?.new_carton_rate)


    // setAdditionalFields(updatedFields);




  };


  const [discountWith, setDiscountWith]: any = useState('')

  const handleDiscountWithChange = (e: any, index: any) => {
    // const updatedFields = [...additionalFields];
    // updatedFields[index].with_or_without_gst = e.target.value;
    // setAdditionalFields(updatedFields);

    setDiscountWith(e.target.value)
    setValueProduct('with_or_without_gst', e.target.value)
  };

  const handleYesNoChange = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].possible_yes_no = e.target.value;
    setAdditionalFields(updatedFields);
  };









  // const [priceListId,setPriceListId]:any = useState('')

  
  
    useEffect(() => {
      if (dataPricelistProduct) {
        let temp = dataPricelistProduct?.results[0]?.price_list_products
          ?.map((e: any) => ({
            value: e?.id,
            // label: e?.product_code?.product_code,
            
            label: `${e?.product_code?.product_code}::${e?.product_code?.mouldshape?.name}::${e?.product_code?.product_type?.type}`,
         
            // priceListID: e?.price_list
          }));
  
          
          
          
          
          setOptionsCodeName(temp)
          let tempId = dataPricelistProduct?.results[0]?.id
          setPriceListId(tempId)
      }
    }, [dataPricelistProduct])






  const handleChangeProductType = (e: any) => {

    setProductType(e)

    setValueProduct('product_type', e.value)
    setValueProductUpdate('product_type', e.value)






    setPriceListProductType(e?.label)



  };

  useEffect(() => {
    let timeoutId: any;

    if (priceListProductType && priceListProductType !== previousPriceListProductType) {
      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout for 3 seconds
      timeoutId = setTimeout(() => {
        mutatePricelistProduct({ product_type: priceListProductType });
        setPreviousPriceListProductType(priceListProductType);
      }, 3000);
    }

    // Cleanup the timeout when the component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [priceListProductType, previousPriceListProductType]);

  // useEffect(() => {
  //   let timeoutId: any;

  //   if (priceListProductType) {
  //     if (priceListProductType !== previousPriceListProductType) {
  //       if (timeoutId) {
  //         clearTimeout(timeoutId);
  //       }

  //       timeoutId = setTimeout(() => {
  //         mutatePricelistProduct({ product_type: priceListProductType });
  //         setPreviousPriceListProductType(priceListProductType);
  //       }, 3000);
  //     }
  //   }

  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [priceListProductType, previousPriceListProductType]);







  const [kgPrice, setKgPrice] = useState(0)
  const [cartonPrice, setCartonPrice] = useState(0)
  // useEffect(()=>{
  // },[kgPrice,cartonPrice])











  // const handleChangeDisposableProduct = (selectedOption:any, index:any) => {
  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].pricelist_product = selectedOption.value;

  //   setAdditionalFields(updatedFields);
  // };




  // const[PriceListForDiscount,setPriceListForDiscount]:any = useState()




  const handleChangePriceList = async (selectedOption: any, index: any) => {
    // const updatedFields = [...additionalFields];

    // updatedFields[index].pricelist_product = selectedOption.value;
    // updatedFields[index].pricelist = selectedOption.priceListID;
    // setAdditionalFields(updatedFields);

    // setNewProductID(selectedOption?.value)

    // updatedFields[index].kg_or_carton = "";
    // updatedFields[index].percentage_or_amount = "";
    // updatedFields[index].with_or_without_gst = "";
    // updatedFields[index].discount = "";
    // updatedFields[index].final_amount = "";
    // setNewProductCode(selectedOption?.productCode)





  };

  const [codeNameType, setCodeNameType]: any = useState('')


  

 
  const handleChangeCodeNameType = async (selectedOption: any, index: any) => {

    setCodeNameType(selectedOption)
    setValueProduct('pricelist_product', selectedOption.value)
    setValueProductUpdate('pricelist_product', selectedOption.value)
    // const updatedFields = [...additionalFields];

    // updatedFields[index].pricelist_product = selectedOption.value;
    // updatedFields[index].pricelist = selectedOption.priceListID;
    // setAdditionalFields(updatedFields);

    setValueProduct('pricelist', priceListId)
    setValueProductUpdate('pricelist', priceListId)

    setNewProductID(selectedOption?.value)

    setDiscountOn(null)
    setDiscountType(null)
    setDiscountWith(null)
    setDiscount(null)
    setFinalAmount(null)

    setNewProductCode(selectedOption?.productCode)

    // updatedFields[index].kg_or_carton = "";
    // updatedFields[index].percentage_or_amount = "";
    // updatedFields[index].with_or_without_gst = "";
    // updatedFields[index].discount = "";
    // updatedFields[index].final_amount = "";
    // setNewProductCode(selectedOption?.productCode)





  };

  // useEffect(() => {
  //   setOptionsUOM(dataUOM)
  // }, [dataUOM])

  const [uom, setUom]: any = useState('')
  const handleChangeUOM = async (selectedOption: any, index: any) => {


    setUom(selectedOption)
    setValueProduct('uom', selectedOption?.value)
    setValueProductUpdate('uom', selectedOption?.value)
    // const updatedFields = [...additionalFields];

    // updatedFields[index].uom = selectedOption.value;
    // setAdditionalFields(updatedFields);
  }



  useEffect(() => {

    if (additionalFields) {

      const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {
        return total + item.final_amount;
      }, 0);
      setFinalAmount(Number(totalFinalAmount))
      setTotalAmount(Number(totalFinalAmount))
    }

  }, [additionalFields])





  useEffect(() => {
    if (newProductID) {
      newProductPricelist({ price_list_products: newProductID })

    }
  }, [newProductID])

  useEffect(() => {
    if (dataOfNewProductPricelist) {
      setNewProductPricelistId(dataOfNewProductPricelist?.results[0]?.id)
    }
  }, [dataOfNewProductPricelist, newProductPricelistId])


  // const [requirementDate,setRequirementDate]:any = useState()
  const handleRequirementDate = (date: any, dateString: any) => {

    setValueProduct('requirement_date', dateString)
    setValueProductUpdate('requirement_date', dateString)
    setRequirementDate(dayjs(dateString,'DD-MM-YYYY'))

  };


  const handleDescriptionChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].product_description = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };

  // const [calculate, setCalculate]: any = useState('')
  const [discountPrice, setDiscountPrice]: any = useState('')


  const [discountPercentage, setDiscountPercentage]: any = useState()

  const handleDiscountChange = (e: any, index: any) => {



    setDiscount(e.target.value)

    setValueProduct('discount', e.target.value)
    setValueProductUpdate('discount', e.target.value)

    // const updatedFields: any = [...additionalFields];
    // updatedFields[index].discount = e.target.value

    // = e.target.value.toLowerCase()
    // .split(' ')
    // .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');
    // updatedFields[index].final_amount = "";
    // setAdditionalFields(updatedFields);




    if (DiscountType === "Percentage") {
      if (discountOn === "KG") {
        let calculate = ((Number(Discount) / 100) * Number(productKG))
        let percentage = (Number(Discount))

        setDiscountPercentage(percentage)

        let cal = calculate
        let discount = (Number(productKG) - Number(cal))
        setValueProduct('final_amount', discount)
        setFinalAmount(discount)
      }
      if (discountOn === "Carton") {
        let calculate = ((Number(Discount) / 100) * Number(productCarton))
        let percentage = (Number(Discount))
        setDiscountPercentage(percentage)
        let cal = calculate
        let discount = (Number(productCarton) - Number(cal))
        setValueProduct('final_amount', discount)
        setFinalAmount(discount)
      }
    }
    else if (DiscountType === "Amount") {
      if (discountOn === "KG") {
        let discount = ((Number(Discount) / Number(productKG)) * 100)
        let finalAmount = (Number(productKG) - Number(discount))
        setValueProduct('final_amount', finalAmount)
        setFinalAmount(finalAmount)
        setDiscountPercentage(discount)
      }
      if (discountOn === "Carton") {
        let discount = ((Number(Discount) / Number(productCarton)) * 100)
        let finalAmount = (Number(productCarton) - Number(discount))
        setValueProduct('final_amount', finalAmount)
        setFinalAmount(finalAmount)
        setDiscountPercentage(discount)


      }
    }
    // updatedFields[index].avg_discount = updatedFields[index].final_amount
  };
  // const [avgDiscount, setAvgDiscount]: any = useState()

  // useEffect(() => {
  //   if (additionalFields) {

  //     const totalFinalAmount = additionalFields?.reduce((total: any, item: any) => {

  //       return total + item.discount_percentage/additionalFields.length;
  //     }, 0);
  //     setAvgDiscount(Number(totalFinalAmount))
  //    setValue("avg_discount",avgDiscount)
  //   }

  // }, [additionalFields])






  const handleFinalAmountChange = (e: any, index: any) => {



    const updatedFields: any = [...additionalFields];
    updatedFields[index].final_amount = discountPrice
    // = e.target.value.toLowerCase()
    // .split(' ')
    // .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');

    setAdditionalFields(updatedFields);
    // setDiscountPrice(kgPrice)



  };
  const handleDiscountAmountChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].discount_amount = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };
  const handleApprovedDiscountChange = (e: any, index: any) => {

    const updatedFields = [...additionalFields];
    updatedFields[index].approved_discount = e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setAdditionalFields(updatedFields);

  };
  // const handlePriceListID = (e:any, index:any) => {

  //   const updatedFields = [...additionalFields];
  //   updatedFields[index].pricelist  =  e.target.value
  //   setAdditionalFields(updatedFields);

  // };

  const handleChangeProductQty = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].product_qty = e.target.value;
    setAdditionalFields(updatedFields);
  };

  const handleChangeBasicValue = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].expected_basic_value = e.target.value;
    setAdditionalFields(updatedFields);
  }


  // const [show, setShow] = useState(false)
  // const [showmessage, setshowmessage] = useState("")

  // useEffect(() => {
  //   if (isSuccess) {
  //     setShow(true)
  //     setshowmessage("success")
  //     navigate('/listSalesinquiry', { state: { showToast: true } })

  //   }
  //   else if (isError) {
  //     setShow(true)
  //     setshowmessage("danger")
  //   }
  // }, [isSuccess, data, error])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(false)
  //   }, 3000)

  // }, [show]);

  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  }


  const [saleInquiryId, setSalesInquiryId]: any = useState()

  const [InquiryData, setSalesInquirydata]: any = useState(null)


  useEffect(() => {
    if (isSuccess && data_response) {
      setIsEdit(true)
      setSalesInquiryId(data_response?.data?.id)
      setSalesInquirydata(data_response?.data)
    }
  }, [isSuccess, data_response])
  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")

      // navigate('/listSalesinquiry', { state: { showToast: true } })

    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isSuccess, data, error])





  const [totalQty, setTotalQty] = useState(0)

  useEffect(() => {

    let totalQuantity: number = 0;
    let totalBasicAmount: number = 0;

    additionalFields?.forEach((field: any) => {
      const productQty: any = field.product_qty;
      const basicAmount: any = field.product_qty;

      if (!isNaN(productQty)) {
        totalQuantity += Number(productQty);
      }
      setTotalQty(totalQuantity);


      if (!isNaN(basicAmount)) {
        totalBasicAmount += Number(totalBasicAmount);
      }

    });

  }, [additionalFields, totalQty])


  // useEffect(()=>{
  //   const results = Discount_Calculation(dataOfNewProductPricelist)
  // },[dataOfNewProductPricelist])

  const [perTotal, setPerTotal] = useState(0)

  useEffect(() => {


    if (DiscountType == "Percentage") {

      let calculated = ((Number(Discount) / 100) * Number(finalAmount))
      // setPerTotal(Number(calculated))


      let discount = (Number(finalAmount) - Number(calculated))
      setTotalAmount(Number(discount))
      setPerTotal(finalAmount)
      setValueProduct("discount_percentage", Discount)
      setValueProduct("final_amount", discount)
      setValue("avg_discount", Discount)
    }
    else if (DiscountType === "Amount") {
      let discount = ((Number(Discount) / Number(finalAmount)) * 100)
      let totalAmount = (Number(finalAmount) - Number(Discount))

      setTotalAmount(Number(totalAmount))
      setValueProduct("discount_percentage", discount)
      setPerTotal(discount)
      setValueProduct("final_amount", totalAmount)
      setValue("avg_discount", discount)
    }


  }, [DiscountType, Discount, totalAmount, finalAmount])




  // useEffect(()=>{
  // if(finalAmount){
  //   if (DiscountType === "percentage") {

  //     if(Discount){

  //           let calculated = ((Number(Discount) / 100) * Number(finalAmount))
  //           setPerTotal(Number(calculated))
  //     }

  //           let discount = (Number(finalAmount) - Number(perTotal))
  //           setFinalAmount(Number(discount))
  //       }
  // }

  // if (DiscountType === "percentage") {
  //   let temp = ((Number(Discount) / 100) * Number(finalAmount))
  //   let discount = (Number(finalAmount) - Number(temp))
  //   setFinalAmount(Number(discount))


  // }


  // },[DiscountType])





  useEffect(() => {
    if (Discount == '') {
      setTotalDiscount(finalAmount)
    }
    else {
      setTotalDiscount(totalAmount)

    }

  }, [totalDiscount])




  const [productModel, setProductModel] = useState(false)





  const [renderKey, setRenderKey] = useState(0);
  const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()


  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);

  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();




  const handleRefetch = () => {
    if (apiName === apinameLead) {
      soiRefetch()
    }




    // else if(apiName === apinameForTaluka){
    //   TalukaRefetch()
    // }
  }


  useEffect(() => {
    if (OpenEditDropDownModel && valueLead && apiName === apinameLead) {
      setValueLead(null)
      setValueLeadCreatable(null)
    }







    if (deletesucess && apiName === apinameLead) {
      setValueLead(null)
      setValueLeadCreatable(null)
    }


  }, [OpenEditDropDownModel, apiName, deletesucess])


  const [isExist, setIsExist] = useState(true)


  const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end" }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}
            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditDropDownModel}
          // permissionClass={permissionClass}
          />
          : ""}

      </div>
    </div>


  )


  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
  }



  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };


  const handleDeleteSaleInquiry = () => {
    setSalesInquirydata(null)

  }


  const optionsGrade = [
    {
        value: 1,
        label: "A"
    },
    {
        value: 2,
        label: "B"
    },
    {
        value: 3,
        label: "C"
    },
    {
        value: 4,
        label: "D"
    },
    {
        value: 5,
        label: "E"
    },

]
const [forGradeValue, setForGradeValue]: any = useState([])
const [valueGrade, setValueGrade]: any = useState('')
const [selectedGrade, setSelectedGrade]: any = useState('')

const handleChangeGrade = (e: any) => {
    setForGradeValue(e)
    setValueGrade(e)
    setValue("customer_grade", e?.label)
}


const [isEditOn,setIsEditOn] = useState(false)

  useEffect(()=>{
    if(productPatchSuccess || dataProductPatch){
      setIsEditOn(false)
    }
  },[productPatchSuccess,dataProductPatch])

  const handleEditProduct=(field:any)=>{
    setIsEditOn(true)
    setActiveEditId(field.id)

    setProductType({value:field.product_type?.id,label:field.product_type?.type})
    setValueProductUpdate('product_type', field.product_type?.id)
    setPriceListProductType(field.product_type?.type)


    setValueProductUpdate('product_special_running',field?.product_special_running)

    if(field?.extra_response){
      setCodeNameType({value:field?.pricelist_product,label:`${field?.extra_response?.product_code}::${field?.extra_response?.product_name}::${field?.extra_response?.mouldshape_name}`})
    setValueProductUpdate('pricelist_product', field?.pricelist_product)
    // setValueProductUpdate('pricelist_product', field?.pricelist_product?.id)
    // setValueProductUpdate('pricelist', field?.pricelist?.id)
    setValueProductUpdate('pricelist', field?.pricelist)
    setNewProductID(field?.extra_response?.product_code_id)
    // setNewProductID(field?.pricelist_product?.id)
    }
    



    setValueProductUpdate('product_description',field?.product_description)

    setValueProductUpdate('product_qty',field?.product_qty) 


    setUom({value:field.extra_response.uom_id,label:field.extra_response?.uom})

    setValueProductUpdate('uom', field?.extra_response.uom_id)
    // setValueProductUpdate('uom', field?.uom.id)


   


    setValueProductUpdate('requirement_date', field?.requirement_date)
    setRequirementDate(dayjs(field?.requirement_date,'DD-MM-YYYY'))


    setValueProductUpdate('expected_basic_value',field?.expected_basic_value)

    setValueProductUpdate('possible_yes_no',field?.possible_yes_no)


    setValueProductUpdate('remark',field?.remark)




  }


  const customStyles = {
    control: (styles: any) => ({
        ...styles,

    }),
    option: (styles: any) => ({
        ...styles,

    }),
    menu: (styles: any) => ({
        ...styles,
        width: '220px'
    })

};
  
 
  return (
    <>
      <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header px-3 py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Edit {pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                  <li className="breadcrumb-item active">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              {/* <div className="card-header">
                                <h5 className="card-title mt-2">
                                    <img src={SearchLogo} className="mx-1" alt='' />
                                    <span className="mx-2">Edit Sales Inquiry</span></h5>
                            </div> */}
              <form
                ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                  <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">

                        <div className="row ">
                          <h4 className="mb-0 ">Sales Info</h4>
                        </div>
                        {InquiryData == null ?
                          <>
                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                              <div className="col-md-12">
                                <div className="row">

                                  <div className="row">
                                    <div className="col-sm" style={{ paddingRight: '0px' }}>
                                      {/* <div className="col-sm row  align-items-center border border-dark">
                                    <div className="col-sm">
                                      <label className="col-form-label">Price List Grade <span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                    <div className="col-6 ">
                                      <Select

                                        components={customIndicator}

                                      />
                                      <span className='text-red w-100'>{errors?.company_name_and_city?.message}</span>
                                    </div>
                                  </div> */}

                                      <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                        <div className="col-sm">
                                          <label className="col-form-label">Customer New-Old <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <select className="form-select" disabled
                                            {...register("company_new_old", { onChange: handleCompanyType, required: { value: false, message: "Field is required" } })}
                                          >
                                            <option value={""} selected>Select Any</option>
                                            <option value={"old"}>Old</option>
                                            <option value={"new"}>New</option>
                                          </select>

                                        </div>
                                        <span className='text-red w-100'>{errors?.company_new_old?.message}</span>
                                      </div>
                                      {/* {companyType === "old" && */}
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Customer Name  {companyType === "old" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 ">
                                          <Select

                                            {...register("company_name_and_city", {
                                              required: {
                                                value: companyType === "old" ? true : false,
                                                message: "Field is required",
                                              },
                                            })}
                                            onChange={handleChangeCompany}
                                            isDisabled={companyType === "new" || companyType === ""}
                                            options={optionsCompany}
                                            // value={companyValue}
                                            components={customIndicator}
                                            value={companyValue === "" ? optionsCompany?.filter((e: any) => e.value === selectedCompany) : companyValue}


                                          />
                                          <span className='text-red w-100'>{errors?.company_name_and_city?.message}</span>
                                        </div>
                                      </div>
                                      {/* } */}

                                      {companyType === "old" ?
                                        <>
                                          <div className="col-sm row  align-items-center border border-dark">
                                            <div className="col-sm">
                                              <label className="col-form-label" >City Name </label>
                                            </div>
                                            <div className="col-6 d-flex">
                                              <input type="text" className="form-control"
                                                disabled={companyType === "old"}
                                                placeholder="City Name"

                                                {...register("village_code_and_name")}
                                              />
                                              <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <div className="col-sm row  align-items-center border border-dark">
                                            <div className="col-sm">
                                              <label className="col-form-label">City Name  {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                            </div>
                                            <div className="col-6 ">
                                              <Select
                                                {...register("village_code_and_name", {
                                                  required: {
                                                    value: companyType === "new" ? true : false,
                                                    message: "Field is required",
                                                  },
                                                })}

                                                // isDisabled={companyType === "old"}
                                                onChange={handleChangeCity}
                                                components={customIndicator}
                                                options={optionsCity}
                                                // value={valueCity}
                                                value={valueCity === "" ? optionsCity?.filter((e: any) => e.value === selectedCity) : valueCity}
                                              />
                                              <span className='text-red w-100'>{errors?.village_code_and_name?.message}</span>
                                            </div>
                                          </div>
                                        </>
                                      }
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Requirment Date <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 d-flex">

                                          <DatePicker
                                            className="form-control"
                                            format={dateFormatList}
                                            // value={leadContactedDate}
                                            disabledDate={disabledDate}
                                            value={leadContactedDate == undefined ? dayjs(fromDateValue, "DD/MM/YYYY") : leadContactedDate}
                                            {...register("lead_contacted", { required: { value: true, message: "Date is required" } })}

                                            onChange={handleLeadContactedDate}
                                          />

                                        </div>
                                        <span className='text-red'>{errors?.lead_contacted?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark" style={{ paddingBottom: "2px" }}>
                                        <div className="col-sm">
                                          <label className="col-form-label">Requirment Status <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <select className="form-select"
                                            {...register("requirement_status", { required: { value: true, message: "Field is required" } })}
                                          >
                                            <option value={""} selected>Select Any</option>
                                            <option value={"Hot"}>Hot</option>
                                            <option value={"warm"}>Warm</option>
                                          </select>
                                        </div>
                                        <span className='text-red w-100'>{errors?.requirement_status?.message}</span>
                                      </div>
                                      {/* <span className="hr"></span> */}
                                    </div>

                                    <div className="col-sm" style={{ paddingLeft: '0px', paddingRight: '0px' }}>




                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label"> Lead Source Of Enquiry <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-6 ">
                                          <CreatableSelect
                                            isClearable={() => {
                                              setValueLead(null)
                                              setValue(null)
                                            }}
                                            {...register("lead_source_of_inquiry", { required: { value: true, message: "Field is required" } })}
                                            components={customCreatableIndicator}
                                            options={optionsLead}
                                            onCreateOption={handleCreateLead}
                                            onChange={handleChangeLead}
                                            onMenuOpen={() => soiRefetch()}
                                            // value={valueLeadCreatable == null ? valueLead : valueLeadCreatable}
                                            value={valueLeadCreatable == null ? valueLead == null ? optionsLead?.filter((obj: any) => obj.value === selectedLead) : valueLead : valueLeadCreatable}

                                          />
                                          <span className='text-red w-100'>{errors?.lead_source_of_inquiry?.message}</span>
                                        </div>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Mobile Number {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="number" className="form-control" id="exampleInputBrand" placeholder="Mobile Number"
                                            disabled={companyType === "old"}
                                            // maxLength={10}
                                            // minLength={15}
                                            // onInput={maxLengthCheck}
                                            {...register('mobile_number', {
                                              required: { value: companyType === "new" ? false : false, message: "Field is required" },
                                              pattern: {
                                                value: /^(\+\d{1,6}[- ]?)?\d{10}$/g,
                                                message: 'Please enter a valid number',
                                              },
                                            })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                            // style={{backgroundColor:"blue",color:"green"}} 
                                            /></span>

                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.mobile_number?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">E-Mail  {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="email" className="form-control" id="exampleInputBrand"
                                            disabled={companyType === "old"}
                                            placeholder="Email Address"
                                            {...register('email', {
                                              required: { value: companyType === "new" ? false : false, message: "Field is required" },
                                              pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,6}\.[0-9]{1,6}\.[0-9]{1,6}\.[0-9]{1,6}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                message: 'Please enter a valid email',
                                              },
                                            })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                            // style={{backgroundColor:"blue",color:"green"}} 
                                            /></span>

                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.email?.message}</span>
                                      </div>
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Name Of Owner  {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled={companyType === "old"}
                                            placeholder="Name Of Owner "

                                            {...register("name_of_customer", { onChange: handleValue, required: { value: companyType === "new" ? false : false, message: "Field is required" } })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.name_of_customer?.message}</span>
                                      </div>

                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Reference By Name {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled={companyType === "old"}
                                            placeholder="Reference By Name"
                                            {...register("reference_name", { onChange: handleValue, required: { value: companyType === "new" ? false : false, message: "Field is required" } })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.reference_name?.message}</span>
                                      </div>


                                    </div>

                                    <div className="col-sm " style={{ paddingLeft: '0px' }}>


                                    <div className="col-sm row  align-items-center border border-dark">
                                            <div className="col-sm">
                                          <label className="col-form-label">Customer Grade {companyType === "new" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                        </div>
                                        {companyType === "old" ?
                                        <>
                                         <div className="col-6 d-flex">
                                         <input type="text" className="form-control"
                                           disabled={companyType === "old"}
                                           placeholder="Customer Grade"

                                           {...register("customer_grade")}
                                         />
                                        
                                       </div>
                                       </>:
                                       <>
                                        <div className="col-6 ">
                                        <Select
                                                            {...register("customer_grade", { required: { value: true, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            // value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        value={valueGrade==""?optionsGrade?.filter((e:any)=>e?.label === selectedGrade):valueGrade}

                                                        />

                                        </div>
                                        </>}
                                        <span className='text-red w-100'>{errors?.discount_wise?.message}</span>
                                      </div>



                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Country Code </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"
                                            disabled
                                            placeholder="Country Code"
                                            // value={country}

                                            {...register("country_code")}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.contact_person?.message}</span>
                                      </div>






                                      {/* </div>
                                  <div className="col-sm"> */}
                                      <div className="col-sm row  align-items-center border border-dark">
                                        <div className="col-sm">
                                          <label className="col-form-label">Remark </label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <input type="text" className="form-control"

                                            placeholder="Remark"
                                            {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })}
                                          />
                                          <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                          </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.remark?.message}</span>
                                      </div>
                                      



                                      <div className="col-sm row  align-items-center border border-dark">
                                        {/* <div className="col-sm-6 mt-2 image-upload"> */}
                                        <div className="col-sm">
                                          <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                        </div>
                                        <div className="col-6 d-flex">
                                          <label style={{ cursor: "pointer" }}>
                                            <img src={cameraLogo} alt='' className="uploaded-image" style={{ width: 80, height: 70 }} />
                                            <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image", { onChange: onLogoChange, required: false })} hidden />
                                          </label>
                                          {/* {visitingCard&&<p style={{display:"inline-flex"}}>{visitingCard} 
                                                    <button style={{border:"none"}} onClick={()=>setVisitingCard(null)}><RxCross2 color='red' size={20}/></button>
                                                    </p>} */}
                                          <a href={data?.visiting_card_image} target="_blank" ><p style={{color:'black'}}>{visitingCard == null ? data?.visiting_card_image?.split("/").pop() : visitingCard}</p></a>

                                        </div>
                                      </div>


                                    </div>
                                  </div>

                                  <div >
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      // onClick={() => setProductModel(true)}
                                      style={{ marginTop: '10px' }}
                                      onClick={handleSubmit(onSubmit)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          : null}


                        <div className="" style={{ height: "155px", width: '100%', maxHeight: "653px" }} >

                          {/* {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Product</span> : null} */}


                          <table key={renderKey1} className="table" style={{ marginTop: "20px", width: "-webkit-fill-available", textAlign: 'center' }}>
                            <thead>
                              <tr>
                                {/* <th>ID</th> */}
                                {/* <th>Price-List<br />Grade</th> */}
                                <th>Customer<br />New-Old</th>
                                <th>Customer <br />Name</th>
                                <th>City &<br />District</th>
                                <th>Requirment<br />Date</th>
                                <th>Requirment<br />Status</th>
                                <th>Lead Source<br />Of Enquiry</th>
                                <th>Mobile <br />Number</th>
                                <th>E-Mail <br />Address</th>
                                <th>Name Of <br />Owner</th>
                                <th>Reference By<br />Name</th>
                                <th>Customer<br />Grade</th>
                                <th>Country<br />Code</th>
                                <th>Remark</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {InquiryData ?
                              <tbody>
                                {/* {displayData?.map((field: any, index: any) => ( */}
                                <tr  >
                                  {/* <td>1</td> */}


                                  {/* <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    // value={'Xyz'}

                                  />

                                </td> */}

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.company_new_old}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.name}
                                      value={InquiryData?.extra_response?.company_name_and_city?.customer_name}

                                    />

                                  </td>



                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={`${InquiryData?.company_name_and_city?.address[0]?.city?.city_name ? InquiryData?.company_name_and_city?.address[0]?.city?.city_name : InquiryData?.company_name_and_city?.address[0]?.city?.village_name}-${InquiryData?.company_name_and_city?.address[0]?.city?.district?.name}`}
                                      value={`${InquiryData?.extra_response?.company_name_and_city?.city[0]?.city_name ? InquiryData?.extra_response?.company_name_and_city?.city[0]?.city_name : InquiryData?.extra_response?.company_name_and_city?.city[0]?.village_name}-${InquiryData?.extra_response?.company_name_and_city?.city[0]?.district}`}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.lead_contacted}

                                    />

                                  </td>




                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.requirement_status}

                                    />

                                  </td>




                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.lead_source_of_inquiry?.name}

                                    />

                                  </td>


                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.contact_detail?.number}
                                      value={InquiryData?.extra_response?.company_name_and_city?.contact_detail_number}

                                    />

                                  </td>

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.contact_detail?.email_address}
                                      value={InquiryData?.extra_response?.company_name_and_city?.email_address}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.customer_name}
                                      value={InquiryData?.extra_response?.company_name_and_city?.name}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.reference ? InquiryData?.company_name_and_city?.reference : InquiryData?.company_name_and_city?.other_reference}
                                      value={InquiryData?.extra_response?.company_name_and_city?.reference_name ? InquiryData?.extra_response?.company_name_and_city?.reference_name : InquiryData?.extra_response?.company_name_and_city?.other_reference}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.customer_grade}
                                      value={InquiryData?.extra_response?.company_name_and_city?.customer_grade}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={InquiryData?.company_name_and_city?.address[0]?.city?.country?.country_code_mobile}
                                      value={InquiryData?.extra_response?.company_name_and_city?.country_code_mobile[0]}

                                    />

                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={InquiryData?.remark?InquiryData?.remark:'-'}

                                    />

                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={handleDeleteSaleInquiry}
                                      style={{ marginTop: '10px' }}
                                    >
                                      <FiEdit />
                                    </button>
                                  </td>


                                </tr>
                                {/* ))} */}
                              </tbody>
                              : null}
                          </table>




                        </div>


                      </div>


                      {/* <button
                        type="button"
                        className="btn btn-success"
                        style={{ marginTop: '10px' }}
                        onClick={() => setProductModel(true)}
                      >
                        Add Products
                      </button> */}

                      <div className="row ">
                        <h4 className="mb-0 ">Add Product</h4>
                      </div>
                      <div className="table-responsive" style={{height:'auto',overflowX:'visible'}}>
                        {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Product</span> : null}

                        <table className="table" key={renderKey1} style={{ marginTop: "20px", textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th> <label htmlFor="exampleInputEmail1" style={{ paddingBottom: '55px' }}>ID</label></th>
                              <th style={{width:"12%"}}>
                                {/* Product <br />Type */}
                                {/* <div className="col-sm-2 mt-2"> */}
                                <label htmlFor="exampleInputEmail1">Product <br />Type<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                  {isEditOn?
                                   <Select
                                  //  {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("product_type", { required: { value: true, message: "Field is required" } })}
                                   placeholder="Product Type"
                                   value={productType}
                                   maxMenuHeight={100}
                                   styles={customStyles} 
                                   // className="form-select"
                                   components={customIndicator}
                                   options={optionsProductType}
                                   onChange={handleChangeProductType}

                                 />
                                :
                                <Select
                                {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                                // {...registerProductUpdate("product_type", { required: { value: true, message: "Field is required" } })}
                                placeholder="Product Type"
                                value={productType}
                                styles={customStyles} 
                                maxMenuHeight={100}
                                // className="form-select"
                                components={customIndicator}
                                options={optionsProductType}
                                onChange={handleChangeProductType}

                              />
                                }
                                  {/* <Select
                                    {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("product_type", { required: { value: true, message: "Field is required" } })}
                                    placeholder="Product Type"
                                    value={productType}
                                    // className="form-select"
                                    components={customIndicator}
                                    options={optionsProductType}
                                    onChange={handleChangeProductType}

                                  /> */}
                                {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.product_type?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.product_type?.message}</span>
                                }
                                  {/* <span className='text-red w-100'>{errorsProduct?.product_type?.message}</span> */}
                                </div>

                                {/* </div> */}
                              </th>
                              <th>
                                {/* Special/Running */}

                                <label htmlFor="exampleInputEmail1">Special/<br />Running<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                {isEditOn?
                                   <select
                                  //  {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("product_special_running", { required: { value: true, message: "Field is required" } })}
                                   onChange={handleSpecialRunningChange}
                                   className="form-select"
                                 >
                                   <option value={''}>Select Any</option>
                                   <option value={'special'}>Special</option>
                                   <option value={'running'}>Running</option>
                                 </select>
                                  :
                                  <select
                                  {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("product_special_running", { required: { value: true, message: "Field is required" } })}
                                  onChange={handleSpecialRunningChange}
                                  className="form-select"
                                >
                                  <option value={''}>Select Any</option>
                                  <option value={'special'}>Special</option>
                                  <option value={'running'}>Running</option>
                                </select>
                                  }
                                  {/* <select
                                    {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("product_special_running", { required: { value: true, message: "Field is required" } })}
                                    onChange={handleSpecialRunningChange}
                                    className="form-select"
                                  >
                                    <option value={''}>Select Any</option>
                                    <option value={'special'}>Special</option>
                                    <option value={'running'}>Running</option>
                                  </select> */}

                                  {/* <span className='text-red w-100'>{errorsProduct?.product_special_running?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.product_special_running?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.product_special_running?.message}</span>
                                }
                                </div>


                              </th>
                              <th style={{width:"12%"}}>
                                {/* Product Code & <br />Name & Type */}

                                <label htmlFor="exampleInputEmail1">Product Code & <br />Name & Type {specialRunning === "running" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                <div className="">
                                {isEditOn?
                                   <Select
                                  //  {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                                   {...registerProductUpdate("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                                   value={codeNameType}
                                   isDisabled={specialRunning === "special"}
                                   options={optionsCodeName}
                                   components={customIndicator}
                                   maxMenuHeight={100}
                                   styles={customStyles} 
                                   // className="form-select"
                                   onChange={handleChangeCodeNameType}
                                   isOptionDisabled = {(optionsCodeName:any) => {
                                    const temp = displayData.map((e:any) => e.pricelist_product);
                                    return temp.includes(optionsCodeName.value);
                                }}

                                 />
                                  :
                                  <Select
                                  {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                                  // {...registerProductUpdate("pricelist_product", { required: { value: true, message: "Field is required" } })}
                                  value={codeNameType}
                                  isDisabled={specialRunning === "special"}
                                  options={optionsCodeName}
                                  components={customIndicator}
                                  maxMenuHeight={100}
                                  styles={customStyles} 
                                  // className="form-select"
                                  onChange={handleChangeCodeNameType}
                                  isOptionDisabled = {(optionsCodeName:any) => {
                                    const temp = displayData.map((e:any) => e.pricelist_product);
                                    return temp.includes(optionsCodeName.value);
                                }}

                                />
                                  }
                                  {/* <Select
                                    {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                                    {...registerProductUpdate("pricelist_product", { required: { value: true, message: "Field is required" } })}
                                    value={codeNameType}
                                    isDisabled={specialRunning === "special"}
                                    options={optionsCodeName}
                                    components={customIndicator}
                                    // className="form-select"
                                    onChange={handleChangeCodeNameType}

                                  /> */}

                                  {/* <span className='text-red w-100'>{errorsProduct?.pricelist_product?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.pricelist_product?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.pricelist_product?.message}</span>
                                }
                                </div>

                              </th>
                              <th>
                                {/* Product <br />Description */}

                                <label htmlFor="exampleInputEmail1">Product <br />Description<span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                {isEditOn?
                                   <input
                                  //  {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("product_description", { required: { value: true, message: "Field is required" } })}

                                   className="form-control"
                                   type="text"
                                   placeholder="Product Description"
                                 // value={field.product_description}
                                 // onChange={handleDescriptionChange}
                                 />
                                  :
                                  <input
                                  {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("product_description", { required: { value: true, message: "Field is required" } })}

                                  className="form-control"
                                  type="text"
                                  placeholder="Product Description"
                                // value={field.product_description}
                                // onChange={handleDescriptionChange}
                                />
                                  }
                                  {/* <input
                                    {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("product_description", { required: { value: true, message: "Field is required" } })}

                                    className="form-control"
                                    type="text"
                                    placeholder="Product Description"
                                  // value={field.product_description}
                                  // onChange={handleDescriptionChange}
                                  /> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.product_description?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.product_description?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.product_description?.message}</span>
                                }
                                </div>

                              </th>
                              <th>
                                {/* Product<br />Qty */}
                                <label htmlFor="exampleInputEmail1">Product<br />Qty <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                {isEditOn?
                                   <input
                                  //  {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("product_qty", { required: { value: true, message: "Field is required" } })}

                                   className="form-control"
                                   type="number"
                                   placeholder="Product Total Qty."
                                 // value={field.product_qty}
                                 // onChange={handleChangeProductQty}
                                 />
                                  :
                                  <input
                                  {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("product_qty", { required: { value: true, message: "Field is required" } })}

                                  className="form-control"
                                  type="number"
                                  placeholder="Product Total Qty."
                                // value={field.product_qty}
                                // onChange={handleChangeProductQty}
                                />
                                  }
                                  {/* <input
                                    {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("product_qty", { required: { value: true, message: "Field is required" } })}

                                    className="form-control"
                                    type="number"
                                    placeholder="Product Total Qty."
                                  // value={field.product_qty}
                                  // onChange={handleChangeProductQty}
                                  /> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.product_qty?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.product_qty?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.product_qty?.message}</span>
                                }
                                </div>


                              </th>
                              <th style={{width:"10%"}}>
                                {/* UOM */}


                                <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>UOM <span style={{ color: 'red' }}>*</span> </label>
                                <div className="">
                                {isEditOn?
                                  <Select
                                  // {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                                  {...registerProductUpdate("uom", { required: { value: true, message: "Field is required" } })}

                                  placeholder="UOM"
                                  maxMenuHeight={100}
                                  options={optionsUOM}
                                  components={customIndicator}
                                  value={uom}
                                  onChange={
                                    handleChangeUOM
                                  }

                                />
                                  :
                                  <Select
                                    {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                                    // {...registerProductUpdate("uom", { required: { value: true, message: "Field is required" } })}

                                    placeholder="UOM"
                                    maxMenuHeight={100}
                                    options={optionsUOM}
                                    components={customIndicator}
                                    value={uom}
                                    onChange={
                                      handleChangeUOM
                                    }

                                  />
                                  }
                                  {/* <Select
                                    {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("uom", { required: { value: true, message: "Field is required" } })}

                                    placeholder="UOM"
                                    options={optionsUOM}
                                    components={customIndicator}
                                    value={uom}
                                    onChange={
                                      handleChangeUOM
                                    }

                                  /> */}

                                  {/* <span className='text-red w-100'>{errorsProduct?.uom?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.uom?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.uom?.message}</span>
                                }
                                </div>
                              </th>
                              <th>
                                {/* Requirement <br />Date */}

                                <label htmlFor="exampleInputEmail1">Requirement <br />Date <span style={{ color: 'red' }}>*</span></label>
                                <div className="input-group">
                                {isEditOn?
                                   <DatePicker
                                   className="form-control"
                                  //  {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("requirement_date", { required: { value: true, message: "Field is required" } })}
                                  allowClear={false}
                                   format={dateFormatList}
                                   value={requirementDate}
                                   onChange={handleRequirementDate}
                                   disabledDate={disabledDate}

                                 />
                                  :
                                  <DatePicker
                                  className="form-control"
                                  {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("requirement_date", { required: { value: true, message: "Field is required" } })}
                                  allowClear={false}
                                  format={dateFormatList}
                                  value={requirementDate}
                                  onChange={handleRequirementDate}
                                  disabledDate={disabledDate}

                                />
                                  }
                                  {/* <DatePicker
                                    className="form-control"
                                    {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("requirement_date", { required: { value: true, message: "Field is required" } })}

                                    format={dateFormatList}
                                    value={requirementDate}
                                    onChange={handleRequirementDate}
                                    disabledDate={disabledDate}

                                  /> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.requirement_date?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.requirement_date?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.requirement_date?.message}</span>
                                }
                                </div>
                              </th>
                              <th>
                                {/* Expected <br />Basic Value */}

                                <label htmlFor="exampleInputEmail1">Expected <br />Basic Value <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                {isEditOn?
                                    <input
                                    // {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("expected_basic_value", { required: { value: true, message: "Field is required" } })}

                                    className="form-control"
                                    type="number"
                                    placeholder="Expected Basic Value"
                                  // value={field.expected_basic_value}
                                  // onChange={handleChangeBasicValue}
                                  />
                                  :
                                  <input
                                  {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("expected_basic_value", { required: { value: true, message: "Field is required" } })}

                                  className="form-control"
                                  type="number"
                                  placeholder="Expected Basic Value"
                                // value={field.expected_basic_value}
                                // onChange={handleChangeBasicValue}
                                />
                                  }
                                  {/* <input
                                    {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("expected_basic_value", { required: { value: true, message: "Field is required" } })}

                                    className="form-control"
                                    type="number"
                                    placeholder="Expected Basic Value"
                                  // value={field.expected_basic_value}
                                  // onChange={handleChangeBasicValue}
                                  /> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.expected_basic_value?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.expected_basic_value?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.expected_basic_value?.message}</span>
                                }
                                </div>

                              </th>
                              <th>
                                {/* Possible <br />Yes/No */}

                                <label htmlFor="exampleInputEmail1">Possible <br />Yes/No <span style={{ color: 'red' }}>*</span></label>
                                <div className="">
                                {isEditOn?
                                   <select
                                  //  {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                                   {...registerProductUpdate("possible_yes_no", { required: { value: true, message: "Field is required" } })}

                                   // value={field.possible_yes_no}
                                   // onChange={handleYesNoChange}
                                   className="form-select"
                                 >
                                   <option value={''}>Select Any</option>
                                   <option value={'yes'}>Yes</option>
                                   <option value={'no'}>No</option>
                                 </select>
                                  :
                                  <select
                                  {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                                  // {...registerProductUpdate("possible_yes_no", { required: { value: true, message: "Field is required" } })}

                                  // value={field.possible_yes_no}
                                  // onChange={handleYesNoChange}
                                  className="form-select"
                                >
                                  <option value={''}>Select Any</option>
                                  <option value={'yes'}>Yes</option>
                                  <option value={'no'}>No</option>
                                </select>
                                  }
                                  {/* <select
                                    {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                                    {...registerProductUpdate("possible_yes_no", { required: { value: true, message: "Field is required" } })}

                                    // value={field.possible_yes_no}
                                    // onChange={handleYesNoChange}
                                    className="form-select"
                                  >
                                    <option value={''}>Select Any</option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                  </select> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.possible_yes_no?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.possible_yes_no?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.possible_yes_no?.message}</span>
                                }
                                </div>

                              </th>
                              <th>
                                {/* Re-Mark */}

                                <label htmlFor="exampleInputEmail1" style={{ padding: '10px' }}>Re-Mark <br /></label>
                                <div className="">
                                {isEditOn?
                                     <input
                                    //  {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                                     {...registerProductUpdate("remark", { required: { value: false, message: "Field is required" } })}
 
                                     className="form-control"
                                     type="text"
                                     placeholder="Re-Mark"
                                   // value={field.expected_basic_value}
                                   // onChange={handleChangeBasicValue}
                                   />
                                  :
                                  <input
                                  {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                                  // {...registerProductUpdate("remark", { required: { value: true, message: "Field is required" } })}

                                  className="form-control"
                                  type="text"
                                  placeholder="Re-Mark"
                                // value={field.expected_basic_value}
                                // onChange={handleChangeBasicValue}
                                />
                                  }
                                  {/* <input
                                    {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                                    {...registerProductUpdate("remark", { required: { value: true, message: "Field is required" } })}

                                    className="form-control"
                                    type="text"
                                    placeholder="Re-Mark"
                                  // value={field.expected_basic_value}
                                  // onChange={handleChangeBasicValue}
                                  /> */}
                                  {/* <span className='text-red w-100'>{errorsProduct?.remark?.message}</span> */}
                                  {isEditOn?
                                 <span className='text-red w-100'>{errorsProductUpdate?.remark?.message}</span>
                                :
                                <span className='text-red w-100'>{errorsProduct?.remark?.message}</span>
                                }
                                </div>

                              </th>
                              <th>
                                {/* Actions */}
                                <label htmlFor="exampleInputEmail1" >Actions </label>
                                {/* <div className=""> */}

                                {isEditOn&&
                              <>
                               <div >
                              <button
                                  type="button"
                                  className="btn btn-success"
                                  // onClick={() => setProductModel(true)}
                                  // onClick={handleSubmitProduct(handleAddProduct)}
                                  style={{ marginTop: '10px',fontSize:'13px' }}
                                onClick={()=>{
                                  
                                  setIsEditOn(false)
                                  setProductType(null)
                                  setUom(null)
                                  setCodeNameType(null)
                                  setDiscountOn(null)
                                  setDiscountType(null)
                                  setDiscountWith(null)
                                  setDiscount(null)
                                  // setSpecialRunning(null)
                                  setValueProductUpdate('product_type','')
                                  setValueProduct('pricelist_product','')
                                  setValueProductUpdate("product_special_running", "")
                                  setValueProductUpdate("product_description", "")
                                  setValueProductUpdate("product_qty", "")
                                  // setValueProductUpdate('requirement_date', '')
                                  setValueProductUpdate('expected_basic_value', '')
                                  setValueProductUpdate('remark', '')
                                  setValueProductUpdate('possible_yes_no', '')
                                  // setRequirementDate(null)
                                
                                }}
                                >
                                  Cancel
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-success"
                                  // onClick={() => setProductModel(true)}
                                  onClick={handleSubmitProductUpdate(handlePatchProduct)}
                                  style={{ marginTop: '10px',fontSize:'13px' }}
                                // onClick={handleCheck}
                                >
                                  Save
                                </button>
                                </div>
                              </>  }

                              {!isEditOn && 
                               <button
                               type="button"
                               className="btn btn-success"
                               // onClick={() => setProductModel(true)}
                               onClick={handleSubmitProduct(handleAddProduct)}
                              //  style={{ marginTop: '10px' }}
                             // onClick={handleCheck}
                             >
                               Save Add
                             </button>
                              }
                                {/* <button
                                  type="button"
                                  className="btn btn-success"
                                  // onClick={() => setProductModel(true)}
                                  onClick={handleSubmitProduct(handleAddProduct)}
                                  style={{ marginTop: '10px' }}
                                // onClick={handleCheck}
                                >
                                  Save
                                </button> */}
                                {/* </div> */}
                              </th>
                              {/* <th>
                                <label htmlFor="exampleInputEmail1" >Actions </label>
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={handleSubmitProduct(handleAddProduct)}
                                  style={{ marginTop: '10px' }}
                                >
                                  Save
                                </button>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>



                            {displayData?.map((field: any, index: any) => {

                              return (

                                <tr key={index+1} >
                                  <td>{index + 1}</td>
                                  <td>


                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={field.product_type?.type}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_type}</div>
                                    )}
                                  </td>
                                  <td>




                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={field.product_special_running}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_special_running}</div>
                                    )}
                                  </td>
                                  <td>


                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={`${field?.pricelist_product != null ? `${field?.pricelist_product?.product_code?.product_code}::${field?.pricelist_product?.product_code?.product_type?.type}::${field?.pricelist_product?.product_code?.mouldshape?.name}` : '-'}`}
                                      value={`${field?.pricelist_product != null ? `${field?.extra_response?.product_code}::${field?.extra_response?.product_name}::${field?.extra_response?.mouldshape_name}` : '-'}`}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_code_name_type}</div>
                                    )}
                                  </td>




                                  {/* <td>
                                    <select
                                      value={discountOn === "" ? field.kg_or_carton : discountOn}
                                      onChange={(e) => handleDiscountOnDataChange(e, index)}
                                      className="form-select"
                                      // disabled={DiscountWise === ''}
                                      disabled

                                    >
                                      <option value={""} selected>Select Any</option>
                                      <option value={"KG"}>KG</option>
                                      <option value={"Carton"}>Carton</option>
                                    </select>
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].kg_or_carton}</div>
                                    )}
                                  </td> */}
                                  {/* <td>
                                    <select
                                      value={field.percentage_or_amount}
                                      onChange={(e) => handleDiscountTypeDataChange(e, index)}
                                      className="form-select"
                                      disabled
                                    // disabled={DiscountWise != "Product Wise"}
                                    >
                                      <option value={""} selected>Select Any</option>
                                      <option value={"Amount"}>Amount</option>
                                      <option value={"Percentage"}>Percentage</option>
                                    </select>
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].percentage_or_amount}</div>
                                    )}
                                  </td> */}


                                  {/* <td>
                                    <select
                                      value={field.with_or_without_gst}
                                      onChange={(e) => handleDiscountWithDataChange(e, index)}
                                      className="form-select"
                                      // disabled={DiscountWise != "Product Wise"}
                                      disabled

                                    >
                                      <option value={""} selected>Select Any</option>
                                      <option value={"Whit GST"}>With GST</option>
                                      <option value={"Whitout GST"}>Without GST</option>
                                    </select>
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].with_or_without_gst}</div>
                                    )}
                                  </td> */}

                                  {/* <td>
                                    <input
                                      disabled
                                      // disabled={DiscountWise != "Product Wise"}
                                      className="form-control"
                                      type="number"
                                      placeholder="Discount"
                                      value={field.discount}
                                      onChange={(e) => handleDiscountDataChange(e, index)}
                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].discount}</div>
                                    )}
                                  </td> */}
                                  {/* <td>
                                    <input
                                      disabled
                                      className="form-control"
                                      type="number"
                                      placeholder="Final Amount"
                                      value={field.final_amount}
                                      onChange={(e) => handleDiscountChange(e, index)}
                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].final_amount}</div>
                                    )}
                                  </td> */}

                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      placeholder="Product Description"
                                      value={field.product_description}
                                      onChange={(e) => handleDescriptionChange(e, index)}
                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_description}</div>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="number"
                                      disabled
                                      placeholder="Product Total Qty."
                                      value={field.product_qty}
                                      onChange={(e) => handleChangeProductQty(e, index)}
                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_qty}</div>
                                    )}
                                  </td>
                                  <td >
                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      // value={field?.uom?.name?.name}
                                    value={field.extra_response?.uom}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].product_code_name_type}</div>
                                    )}
                                  </td>
                                  <td>


                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={field.requirement_date}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].requirement_date}</div>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="number"
                                      disabled
                                      placeholder="Expected Basic Value"
                                      value={field.expected_basic_value}
                                      onChange={(e) => handleChangeBasicValue(e, index)}
                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].expected_basic_value}</div>
                                    )}
                                  </td>
                                  <td>


                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={field.possible_yes_no}

                                    />
                                    {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].possible_yes_no}</div>
                                    )}
                                  </td>
                                  <td>


                                    <input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={field.remark ? field.remark : '-'}

                                    />
                                    {/* {fieldErrors[index] && (
                                      <div className="error-message text-red">{fieldErrors[index].possible_yes_no}</div>
                                    )} */}
                                  </td>
                                  {/* <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleDeleteProduct(field.id)}
                                    >
                                      Delete
                                    </button>
                                  </td> */}

                                  <td>
                                <div style={{display:'block'}}>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleEditProduct(field)}
                                  >
                                     <FiEdit />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteProduct(field.id)}
                                  >
                                    <RiDeleteBinLine />
                                  </button>
                                  </div>
                                </td>

                                </tr>
                              )
                            })}








                            {/* {additionalFields.map((field: any, index: any) => {

                                return (

                                  <tr key={index} >
                                    <td>{field?.id}</td>
                                    <td>
                                      <Select
                                        options={optionsProductType}
                                        onChange={(selectedOption) =>
                                          handleChangeProductType(selectedOption, index)
                                        }



                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].product_type}</div>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        value={field.product_special_running}
                                        onChange={(e) => handleSpecialRunningChange(e, index)}
                                        className="form-select"
                                      >
                                        <option value={''}>Select Any</option>
                                        <option value={'special'}>Special</option>
                                        <option value={'running'}>Running</option>
                                      </select>
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].product_special_running}</div>
                                      )}
                                    </td>


                                    <td>

                                      <Select
                                        placeholder="code/type"
                                        options={optionsCodeName}
                                        onChange={(selectedOption) =>
                                          handleChangePriceList(selectedOption, index)
                                        }

                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].pricelist_product}</div>
                                      )}
                                    </td>



                                    <td>


                                      <Select
                                        placeholder="UOM"
                                        options={optionsUOM}
                                        onChange={(selectedOption) =>
                                          handleChangeUOM(selectedOption, index)
                                        }

                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].uom}</div>
                                      )}
                                    </td>
                                   


                                    <td>
                                      <select
                                        value={field.kg_or_carton}
                                        onChange={(e) => handleDiscountOnChange(e, index)}
                                        className="form-select"
                                      >
                                        <option value={""} selected>Select Any</option>
                                        <option value={"KG"}>KG</option>
                                        <option value={"Carton"}>Carton</option>
                                      </select>
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].kg_or_carton}</div>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        value={field.percentage_or_amount}
                                        onChange={(e) => handleDiscountTypeChange(e, index)}
                                        className="form-select"
                                        disabled={DiscountWise === "Total Amount Wise"}
                                      >
                                        <option value={""} selected>Select Any</option>
                                        <option value={"Amount"}>Amount</option>
                                        <option value={"Percentage"}>Percentage</option>
                                      </select>
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].percentage_or_amount}</div>
                                      )}
                                    </td>


                                    <td>
                                      <select
                                        value={field.with_or_without_gst}
                                        onChange={(e) => handleDiscountWithChange(e, index)}
                                        className="form-select"
                                        disabled={DiscountWise === "Total Amount Wise"}
                                      >
                                        <option value={""} selected>Select Any</option>
                                        <option value={"Whit GST"}>With GST</option>
                                        <option value={"Whitout GST"}>Without GST</option>
                                      </select>
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].with_or_without_gst}</div>
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        disabled={DiscountWise === "Total Amount Wise"}
                                        className="form-control"
                                        type="number"
                                        placeholder="Discount"
                                        value={field.discount}
                                        onChange={(e) => handleDiscountChange(e, index)}
                                        
                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].discount}</div>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        disabled
                                        className="form-control"
                                        type="number"
                                        placeholder="Final Amount"
                                        value={field.final_amount}
                                        onChange={(e) => handleDiscountChange(e, index)}
                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].final_amount}</div>
                                      )}
                                    </td>


                                    <td>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Product Description"
                                        value={field.product_description}
                                        onChange={(e) => handleDescriptionChange(e, index)}
                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].product_description}</div>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Product Total Qty."
                                        value={field.product_qty}
                                        onChange={(e) => handleChangeProductQty(e, index)}
                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].product_qty}</div>
                                      )}
                                    </td>
                                    <td>
                                      <DatePicker
                                        format={dateFormatList}
                                        onChange={(date: any) => handleRequirementDate(date, index)}

                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].requirement_date}</div>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Expected Basic Value"
                                        value={field.expected_basic_value}
                                        onChange={(e) => handleChangeBasicValue(e, index)}
                                      />
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].expected_basic_value}</div>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        value={field.possible_yes_no}
                                        onChange={(e) => handleYesNoChange(e, index)}
                                        className="form-select"
                                      >
                                        <option value={''}>Select Any</option>
                                        <option value={'yes'}>Yes</option>
                                        <option value={'no'}>No</option>
                                      </select>
                                      {fieldErrors[index] && (
                                        <div className="error-message text-red">{fieldErrors[index].possible_yes_no}</div>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteProduct(field.id)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })} */}
                          </tbody>
                        </table>


                        {/* <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleAddProduct}
                          >
                            Add Product
                          </button> */}

                      </div>


                      {DiscountWise === "Total Amount Wise" ?


                        <>
                          <div className="row d-flex">

                            <div className="col-sm-3 mt-2">
                              <label >Discount Type <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <select className="form-select"
                                  {...register("percentage_or_amount", { onChange: onDiscountTypeChange, required: { value: true, message: "Field is required" } })}
                                >
                                  <option value={""} selected>Select Any</option>
                                  <option value={"Amount"}>Amount</option>
                                  <option value={"Percentage"}>Percentage</option>
                                </select>

                              </div>
                              <span className='text-red w-100'>{errors?.percentage_or_amount?.message}</span>
                            </div>


                            <div className="col-sm-3 mt-2">
                              <label >Discount  <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <input type="number" className="form-control"

                                  placeholder="Discount"
                                  {...register("discount", { onChange: onDiscountChange, required: { value: true, message: "Field is required" } })}
                                />
                                <div className="input-group-append" >
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.discount?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label >Total Amount  <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" >
                                <input type="number" className="form-control"
                                  disabled
                                  placeholder="Total Amount"
                                  value={totalAmount}
                                  {...register("final_amount", { onChange: onDiscountChange })}
                                />
                                {/* <div className="input-group-append" >
          <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
        </div> */}
                              </div>
                            </div>
                          </div>
                        </>
                        : ""}





                      {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    {visitingCard&&<p style={{display:"inline-flex"}}>{visitingCard} 
                                                    </p>}
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div> */}
                      <div className="row d-flex  ">
                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                          <div className="row d-flex ">
                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                              <button
                                type="button" className="border-1 rounded text-white px-2 py-1 mt-2 mx-3"
                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                onClick={onSubmitFinal}
                              // onClick={handleSubmit(onSubmit)}
                              >Submit</button>

                              <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSalesinquiry")}>Cancel</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>



        {/* Product Modal */}
        {/* <Modal
          key={renderKey1}
          size="xl"
          show={productModel}
          onHide={() => setProductModel(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Products </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form >


              <div className="card-body" style={{ maxHeight: "100vh" }}>
                <div className="row d-flex justify-content-md- center justify-content-lg-start">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Type<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <Select
                            {...registerProduct("product_type", { required: { value: true, message: "Field is required" } })}
                            placeholder="Product Type"
                            value={productType}
                            components={customIndicator}
                            options={optionsProductType}
                            onChange={handleChangeProductType}

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_type?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Special / Running<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <select
                            {...registerProduct("product_special_running", { required: { value: true, message: "Field is required" } })}
                            onChange={handleSpecialRunningChange}
                            className="form-select"
                          >
                            <option value={''}>Select Any</option>
                            <option value={'special'}>Special</option>
                            <option value={'running'}>Running</option>
                          </select>

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_special_running?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Code & Name & Type {specialRunning === "running" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                        <div className="">
                          <Select
                            {...registerProduct("pricelist_product", { required: { value: specialRunning === "special" ? false : true, message: "Product Code & Name & Type is required" } })}
                            value={codeNameType}
                            isDisabled={specialRunning === "special"}
                            options={optionsCodeName}
                            components={customIndicator}
                            onChange={handleChangeCodeNameType}

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.pricelist_product?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">UOM<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <Select
                            {...registerProduct("uom", { required: { value: true, message: "Field is required" } })}
                            placeholder="UOM"
                            options={optionsUOM}
                            components={customIndicator}
                            value={uom}
                            onChange={
                              handleChangeUOM
                            }

                          />

                        </div>
                        <span className='text-red w-100'>{errorsProduct?.uom?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount On {DiscountWise === "" || specialRunning === "special" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("kg_or_carton", { required: { value: (DiscountWise === "" || specialRunning === "special" ? false : true), message: "Field is required" } })}
                            className="form-select"
                            value={discountOn}
                            onChange={handleDiscountOnChange}
                            disabled={DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"KG"}>KG</option>
                            <option value={"Carton"}>Carton</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.kg_or_carton?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount Type {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("percentage_or_amount", { required: { value: (DiscountWise != "Product Wise" || specialRunning === "special" ? false : true), message: "Field is required" } })}
                            className="form-select"
                            onChange={handleDiscountTypeChange}
                            value={DiscountType}
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"Amount"}>Amount</option>
                            <option value={"Percentage"}>Percentage</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.percentage_or_amount?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount With {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <select
                            {...registerProduct("with_or_without_gst", { required: { value: DiscountWise != "Product Wise" || specialRunning === "special" ? false : true, message: "Field is required" } })}
                            onChange={handleDiscountWithChange}
                            value={discountWith}
                            className="form-select"
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                          >
                            <option value={""} selected>Select Any</option>
                            <option value={"Whit GST"}>With GST</option>
                            <option value={"Whitout GST"}>Without GST</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.with_or_without_gst?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Discount {specialRunning === "special" || DiscountWise != "Product Wise" ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                        <div className="">
                          <input
                            {...registerProduct("discount", { required: { value: DiscountWise != "Product Wise" || specialRunning === "special" ? false : true, message: "Field is required" } })}
                            disabled={DiscountWise === "Total Amount Wise" || DiscountWise === "" || specialRunning === "special"}
                            className="form-control"
                            type="number"
                            placeholder="Discount"
                            onChange={handleDiscountChange}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.discount?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Final Amount</label>
                        <div className="">
                          <input
                            {...registerProduct("final_amount", { required: { value: false, message: "Field is required" } })}
                            disabled
                            className="form-control"
                            type="number"
                            placeholder="Final Amount"
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.final_amount?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Discription<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("product_description", { onChange: handleValue, required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="text"
                            placeholder="Product Description"
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_description?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Product Total Qty<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("product_qty", { required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="number"
                            placeholder="Product Total Qty."
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.product_qty?.message}</span>

                      </div>



                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Requirement Date<span style={{ color: 'red' }}>*</span></label>
                        <div className="input-group">
                          <DatePicker
                            className="form-control"
                            {...registerProduct("requirement_date", { required: { value: true, message: "Field is required" } })}
                            format={dateFormatList}
                            value={requirementDate}
                            onChange={handleRequirementDate}
                            disabledDate={disabledDate}
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.requirement_date?.message}</span>

                      </div>




                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Expected Basic Value<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("expected_basic_value", { required: { value: true, message: "Field is required" } })}
                            className="form-control"
                            type="number"
                            placeholder="Expected Basic Value"
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.expected_basic_value?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Possible<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <select
                            {...registerProduct("possible_yes_no", { required: { value: true, message: "Field is required" } })}
                            className="form-select"
                          >
                            <option value={''}>Select Any</option>
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.possible_yes_no?.message}</span>

                      </div>


                      <div className="col-sm-3 mt-2">
                        <label htmlFor="exampleInputEmail1">Re-Mark<span style={{ color: 'red' }}>*</span></label>
                        <div className="">
                          <input
                            {...registerProduct("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })}
                            className="form-control"
                            type="text"
                            placeholder="Re-Mark"
                          />
                        </div>
                        <span className='text-red w-100'>{errorsProduct?.remark?.message}</span>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setProductModel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="info"
              className="text-white"
              onClick={handleSubmitProduct(handleAddProduct)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal> */}




        <Modal
          show={deleteConfirm}
          onHide={() => setDeleteConfirm(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete {""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              {" "}
              <span className="text-center">Sure you want to delete it?</span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="info"
              className="text-white"
              onClick={handleMultipleDeleted}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Deleteforce
          key={renderKey}
          handleDeletedforce={handleDeletedforce}
          reconfirmdelete={confirmforcemodal}
          masterName={""}
          closereconfirmdelete={closereconfirmdelete}
          referance={
            deletesucess
              ? deletedata
              : ""
          }
          apiname={apiName}
        />
        <Deletesingle
          key={renderKey}
          confirmmodal={deleteConfirmsingle}
          confirmmodalclose={confirmmodalclose}
          masterName={""}
          confirmmodalsubmit={confirmmodalsubmit}
          apiname={apiName}
        />

        <SimpleInput_Edit key={renderKey}
          refetchForm={handleRefetch}
          open={OpenEditDropDownModel}
          setOpen={setOpenEditDropDownModel}
          id={editId}
          // superAccountId={superAccountId} 
          // optionsSuperAccount={optionsSuperAccount}
          // isSuperAccount={isSuperAccount}
          apiname={apiName} />





        <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
          centered    >
          <Modal.Header closeButton>
            <Modal.Title>Select a Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 mt-2">
                  <label htmlFor="exampleInputEmail1">Choose Date</label>
                  <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                  {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
            <button
              type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
              style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
              onClick={handleSubmit(handleConfirm)}
            >Confirm</button>
          </Modal.Footer>
        </Modal>
        <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
          yesupdatemodal={yesupdatemodal}
          cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
      </div>
    </>
  )
}

export default Edit_Sales_Inquiry