import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, useFormContext } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown, useFetchCountryDropDown, useFetchEmailTypeDropDown, useFetchNumberTypeDropDown, useFetchSocialMediaDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { Button, Modal, Offcanvas } from "react-bootstrap";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import { Columns_Contact_Format, titleofheaders } from "./Columns_Contact_Format";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Contact_Format_Modal = ({setCurrentStep,currentStep,setForEditIds,addShowOffcancas,setAddShowOffcancas}: any) => {
    let pageName = "Contact Detail"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
    const { mutate: Contact, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    const { errors } = formState;

   
    const onSubmit = (data: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        Contact(formData)
    }


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;


    let basicId = addData ? addData.id : null;

    const handleNext = () => {
        let tempId = {
            contact_detail: multipleIds
        }

        let tempData = {
            id: basicId,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
    }

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                // setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        reset()
        setAccountTypeCreatable(null)
        setAccountType(null)
        setNumberTypeCreatable(null)
        setNumberType(null)
        setSocialProfileCreatable(null)
        setSocialProfile(null)

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleReset = () => {
        reset()
        setAccountTypeCreatable(null)
        setAccountType(null)
        setNumberTypeCreatable(null)
        setNumberType(null)
        setSocialProfileCreatable(null)
        setSocialProfile(null)
    }

    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }


    const [activePage, setActivePage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setActivePage('Add Contact Detail')
        }, 500)

    }, [activePage]);

    const [multipleIds, setMultipleIds]: any = useState([])

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    return newIds;
                });


                setForEditIds((oldIds = []) => {
                    const newIds = [...oldIds, data.data.id];
                    return newIds;
                });




                
            }
        }
    }, [isSuccess, data]);

    const [optionsAccountType, setOptionsAccountType]: any = useState([]);
    const [accountType, setAccountType]: any = useState();
    const [accountTypeCreatable, setAccountTypeCreatable] = useState<Option | null>();
    const apinameForAccountType = process.env.REACT_APP_PERSON_EMAIL_TYPE_API?.toString()
    const { mutate: mutateAccountType, data: dataAccountType, isError: AccountTypeIsError, isLoading: AccountTypeLoading, isSuccess: AccountTypeSuccess, error: AccountTypeError }: any = useCreate(apinameForAccountType)

    const { data: accountTypeData, refetch: accountTypeRefetch } = useFetchEmailTypeDropDown()
    
    useEffect(() => {
        setOptionsAccountType(accountTypeData)
    }, [accountTypeData])
    
    const handleChangeAccountType = (e: any) => {
        accountTypeRefetch()
        setApiName(apinameForAccountType)
        setDeleteApiName(apinameForAccountType)
        setEditId(e?.value)
        setEditData(accountTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setAccountType(e)
        setValue("email_type", `${e?.value}`)
        setAccountTypeCreatable(null)
    }

    const handleCreateAccountType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateAccountType({ name: newOption.name, status: newOption.status })
            setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setAccountTypeCreatable(newOption);
        }, 0);
    };

    

   
    useEffect(() => {
        (async () => {
            if (AccountTypeSuccess && optionsAccountType) {
                await accountTypeRefetch();
            }
            setValue("email_type", accountTypeData[0].value)
        })();

        return () => {
        };
    }, [AccountTypeSuccess, optionsAccountType]);


    //
    const [optionsNumberType, setOptionsNumberType]: any = useState([]);
    const [NumberType, setNumberType]: any = useState();
    const [NumberTypeCreatable, setNumberTypeCreatable] = useState<Option | null>();
    const apinameForNumberType = process.env.REACT_APP_PERSON_NUMBER_TYPE_API?.toString()
    const { mutate: mutateNumberType, data: dataNumberType, isError: NumberTypeIsError, isLoading: NumberTypeLoading, isSuccess: NumberTypeSuccess, error: NumberTypeError }: any = useCreate(apinameForNumberType)

    const { data: NumberTypeData, refetch: NumberTypeRefetch } = useFetchNumberTypeDropDown()
    
    useEffect(() => {
        setOptionsNumberType(NumberTypeData)
    }, [NumberTypeData])
    
    const handleChangeNumberType = (e: any) => {
        NumberTypeRefetch()
        setApiName(apinameForNumberType)
        setDeleteApiName(apinameForNumberType)
        setEditId(e?.value)
        setEditData(NumberTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setNumberType(e)
        setValue("number_type", `${e?.value}`)
        setNumberTypeCreatable(null)
    }
    
    const handleCreateNumberType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateNumberType({ name: newOption.name, status: newOption.status })
            setOptionsNumberType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setNumberTypeCreatable(newOption);
        }, 0);
    };

    

   
    useEffect(() => {
        (async () => {
            if (NumberTypeSuccess && optionsNumberType) {
                await NumberTypeRefetch();
            }
            setValue("number_type", NumberTypeData[0].value)
        })();

        return () => {
        };
    }, [NumberTypeSuccess, optionsNumberType]);

    //

    const [optionsSocialProfile, setOptionsSocialProfile]: any = useState([]);
    const [SocialProfile, setSocialProfile]: any = useState();
    const [SocialProfileCreatable, setSocialProfileCreatable] = useState<Option | null>();
    const apinameForSocialProfile = process.env.REACT_APP_PERSON_SOCIAL_MEDIA_API?.toString()
    const { mutate: mutateSocialProfile, data: dataSocialProfile, isError: SocialProfileIsError, isLoading: SocialProfileLoading, isSuccess: SocialProfileSuccess, error: SocialProfileError }: any = useCreate(apinameForSocialProfile)

    const { data: SocialProfileData, refetch: SocialProfileRefetch } = useFetchSocialMediaDropDown()
    
    useEffect(() => {
        setOptionsSocialProfile(SocialProfileData)
    }, [SocialProfileData])
    
    const handleChangeSocialProfile = (e: any) => {
        SocialProfileRefetch()
        setApiName(apinameForSocialProfile)
        setDeleteApiName(apinameForSocialProfile)
        setEditId(e?.value)
        setEditData(SocialProfileData)
        setRenderKey(prevKey => prevKey + 1);
        setSocialProfile(e)
        setValue("social_media", `${e?.value}`)
        setSocialProfileCreatable(null)
    }
    
    const handleCreateSocialProfile = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateSocialProfile({ name: newOption.name, status: newOption.status })
            setOptionsSocialProfile((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setSocialProfileCreatable(newOption);
        }, 0);
    };

    

   
    useEffect(() => {
        (async () => {
            if (SocialProfileSuccess && optionsSocialProfile) {
                await SocialProfileRefetch();
            }
            setValue("social_media", SocialProfileData[0].value)
        })();

        return () => {
        };
    }, [SocialProfileSuccess, optionsSocialProfile]);

    
    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {

        if (apiName === apinameForAccountType) {
            accountTypeRefetch()
        }
        if (apiName === apinameForNumberType) {
            NumberTypeRefetch()
        }
        if (apiName === apinameForSocialProfile) {
            SocialProfileRefetch()
        }

        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && accountType && apiName === apinameForAccountType) {
            setAccountType(null)
            setAccountTypeCreatable(null)
        }
        if (OpenEditDropDownModel && NumberType && apiName === apinameForNumberType) {
            setNumberType(null)
            setNumberTypeCreatable(null)
        }
        if (OpenEditDropDownModel && SocialProfile && apiName === apinameForSocialProfile) {
            setSocialProfile(null)
            setSocialProfileCreatable(null)
        }







        if (deletesucess && apiName === apinameForAccountType) {
            setAccountType(null)
            setAccountTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForNumberType) {
            setNumberType(null)
            setNumberTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForSocialProfile) {
            setSocialProfile(null)
            setSocialProfileCreatable(null)
        }



    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



            {label !== 'Square' && label !== 'Round' && label !== 'Triangle' && label !== 'Rectangles' ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>
            <div className="" >
                <section className="content-header px-3 py-1">
                  
                </section>
                <section className="">
                    <div className="">
                        <div className="">
                                <Offcanvas show={addShowOffcancas} onHide={()=>setAddShowOffcancas(false)} placement={'end'} style={{ width: '178vh', zIndex: "1000", top: '71px' }} >
                                    <Offcanvas.Header closeButton>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body >
                                        <div className="card card-default">
                                            <h3>Add</h3>

                                            <form ref={focus}
                                                onKeyUp={event => onEnterKey(event)}
                                                onSubmit={handleSubmit(onSubmit)}>
                                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Country </label>
                                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_name} placeholder="Country"
                                                                    />
                                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                                </div>

                                                                {/* <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: 'red' }}>*</span></label>
                                                                    <select className="form-select" {...register("email_type", { required: { value: true, message: "Account Type is required" } })}>

                                                                        <option value={""} selected>Select Any</option>
                                                                        <option value="Home">Home</option>
                                                                        <option value="Shop">Shop</option>
                                                                        <option value="Office">Office</option>
                                                                        <option value="Personal">Personal</option>
                                                                        <option value="Company Email Id">Company Email Id</option>
                                                                        <option value="Personal Email Id">Personal Email Id</option>
                                                                    </select>
                                                                    <span className='text-red w-100'>{errors?.email_type?.message}</span>
                                                                </div> */}

<div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect

                                                        {...register("email_type", { required: { value: true, message: "Account Type is required" } })}
                                                        placeholder={'Select Account Type'}
                                                        options={optionsAccountType}
                                                        onCreateOption={handleCreateAccountType}
                                                        onChange={handleChangeAccountType}
                                                        value={accountTypeCreatable == null ? accountType : accountTypeCreatable}
                                                        components={customCreatableIndicator}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => accountTypeRefetch()}


                                                    />
                                                     <span className='text-red w-100'>{errors?.email_type?.message}</span>

                                                </div>

                                                                {/* <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: 'red' }}>*</span></label>
                                                                    <select className="form-select" {...register("number_type", { required: { value: true, message: "Number Type is required" } })}>

                                                                        <option value={""} selected>Select Any</option>
                                                                        <option value="Mobile-1">Mobile-1</option>
                                                                        <option value="Mobile-2">Mobile-2</option>
                                                                        <option value="Home Mobile">Home Mobile</option>
                                                                        <option value="Shop">Shop</option>
                                                                        <option value="Office">Office</option>
                                                                        <option value="Company Mobile No">Company Mobile No</option>
                                                                    </select>
                                                                    <span className='text-red w-100'>{errors?.number_type?.message}</span>
                                                                </div> */}
                                                                 <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect

                                                        {...register("number_type", { required: { value: true, message: "Number Type is required" } })}
                                                        placeholder={'Select Number Type'}
                                                        options={optionsNumberType}
                                                        onCreateOption={handleCreateNumberType}
                                                        onChange={handleChangeNumberType}
                                                        value={NumberTypeCreatable == null ? NumberType : NumberTypeCreatable}
                                                        components={customCreatableIndicator}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => NumberTypeRefetch()}


                                                    />
                                                     <span className='text-red w-100'>{errors?.number_type?.message}</span>

                                                </div>
                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Country Code </label>
                                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_code} placeholder="Country Code" {...register("country_code", { required: { value: false, message: "Country Code is required" } })} />
                                                                    <span className='text-red w-100'>{errors?.country_code?.message}</span>
                                                                </div>

                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" id="exampleInputBrand"
                                                                            placeholder="Mobile Number"

                                                                            {...register('number', {
                                                                                required: 'Mobile Number is required',
                                                                                pattern: {
                                                                                    message: 'Please enter a valid Mobile Number',
                                                                                },
                                                                            })}
                                                                            onChange={handleNumbercode}
                                                                        />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                             /></span>

                                                                        </div>
                                                                    </div>
                                                                    <span className='text-red w-100'>{errors?.number?.message}</span>
                                                                </div>
                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Contact Name & Surname <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Contact Name & Surname"
                                                                            {...register('contact_name_and_surname', { required: true, message: 'Contact Name & Surname is required' })} />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                            /></span>

                                                                        </div>
                                                                    </div>
                                                                    <span className='text-red w-100'>{errors?.contact_name_and_surname?.message}</span>
                                                                </div>




                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
                                                                    <div className="input-group">
                                                                        <input type="email" className="form-control" id="exampleInputBrand" placeholder="Email Address"
                                                                            {...register('email_address', {
                                                                                required: 'Email is required',
                                                                                pattern: {
                                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                                    message: 'Please enter a valid email',
                                                                                },
                                                                            })} />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                            /></span>

                                                                        </div>
                                                                    </div>
                                                                    <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                                                </div>

                                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Social Media Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect

                                                        {...register("social_media", { required: { value: true, message: "Social Media Type is required" } })}
                                                        placeholder={'Select Social Media Type'}
                                                        options={optionsSocialProfile}
                                                        onCreateOption={handleCreateSocialProfile}
                                                        onChange={handleChangeSocialProfile}
                                                        value={SocialProfileCreatable == null ? SocialProfile : SocialProfileCreatable}
                                                        components={customCreatableIndicator}
                                                        maxMenuHeight={130}
                                                        onMenuOpen={() => SocialProfileRefetch()}


                                                    />
                                                     <span className='text-red w-100'>{errors?.social_profile?.message}</span>

                                                </div>
                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Social Media Account </label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Social Media Account"
                                                                            {...register('social_media_account', { required: false, message: 'Social Media Account is required' })} />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                            /></span>

                                                                        </div>
                                                                    </div>
                                                                    <span className='text-red w-100'>{errors?.social_media_account?.message}</span>
                                                                </div>


                                                                <div className="col-sm-3 mt-2">


                                                                    <label >Re-Mark</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control"

                                                                            placeholder={'Re-Mark'}


                                                                            {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                                                                        />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                            /></span>

                                                                        </div>
                                                                    </div>
                                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                                </div>
                                                                <div className="col-sm-3 mt-2">
                                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                        <option value={""} selected>Select Any</option>
                                                                        <option value="active">Active</option>
                                                                        <option value="inactive">Inactive</option>
                                                                    </select>
                                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row d-flex  ">
                                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                    onClick={handleSubmit(onSubmit)}
                                                                >Submit</button>
                                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Offcanvas.Body>
                                </Offcanvas>
                        </div>
                    </div>
                </section >
            </div >
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
       </div >
    )
}

export default Add_Contact_Format_Modal




// import React, { useEffect, useRef, useState } from "react";
// import SearchLogo from "../../../images/SearchLogo.png";
// import cameraLogo from "../../images/Export/Camera.png";
// import pdfLogo from "../../images/Export/pdf upload.png";
// import "../../../components/Header/header.css";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useForm, useFormContext } from "react-hook-form";
// import { Alert } from "../../../components/Model/Model";
// import Select from 'react-select';
// import axios from 'axios'
// import { useCreate, useUpdateSingle } from "../../../hooks/Api";
// import { RiPencilLine } from "react-icons/ri";
// import useFocusOnEnter from "../../../components/focus";
// import CreatableSelect from "react-select/creatable";
// import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown, useFetchCountryDropDown } from "../../../hooks/DropDown_Api";
// import IndicatorsContainer from '../../../components/Select Dropdown/Select'
// import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'

// const customIndicator = {
//     IndicatorsContainer: IndicatorsContainer
// }

// const customCreatableIndicator = {
//     IndicatorsContainer: IndicatorsContainerCreate
// }

// interface Option {
//     readonly label: string;
//     //   readonly value: string;
//     readonly name: string;
// }
// const createOptionType = (label: string) => ({
//     label,
//     // value: label,
//     name: label,
//     status: "active"
// });

// const Add_Contact_Format = ({ setGoBackFromContactDetails, setCurrentStep
//     , currentStep }: any) => {
//     let pageName = "Contact Detail"
//     const focus: any = useRef();
//     const { onEnterKey } = useFocusOnEnter(focus);
//     const navigate = useNavigate()
//     const [optionsType, setOptionsType]: any = useState([]);
//     const [valueTypeCreatable, setValueTypeCreatable] = useState<Option | null>();
//     const [valueType, setValueType]: any = useState();
//     const [isLoadingValue, setIsLoadingValue] = useState(false);
//     const [searchTerm, setSearchTerm] = useState('')


//     // let rawData = localStorage.getItem('Add Data');
//     // let adddata = rawData ? JSON.parse(rawData) : null;




//     const apiname = process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
//     const { mutate: Contact, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)


//     const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
//         mode: "onChange"
//     });

//     // const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useFormContext();

//     const { errors } = formState;


//     const apinameForType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

//     const { mutate: mutateType, data: dataType, isError: TypeIsError, isLoading: TypeLoading, isSuccess: TypeSuccess, error: TypeError }: any = useCreate(apinameForType)


//     const { data: TypeData, refetch: TypeRefetch } = useFetchPersonTypeDropDown()
//     useEffect(() => {
//         setOptionsType(TypeData)
//     }, [TypeData])


//     useEffect(() => {
//         if (searchTerm?.length >= 1) {
//             TypeRefetch()
//         }
//     }, [])


//     const handleChangeType = (e: any) => {

//         setValue("type", `${e?.value}`)
//         setValueTypeCreatable(null)
//     }


//     const handleCreate = (inputValue: string) => {

//         inputValue = inputValue.toLowerCase()
//             .split(' ')
//             .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
//             .join(' ');
//         setIsLoadingValue(true);
//         setTimeout(() => {
//             const newOption = createOptionType(inputValue);
//             setIsLoadingValue(false);


//             mutateType({ name: newOption.name, status: newOption.status })
//             setOptionsType((prev: any) => [...prev, newOption]);
//             setValueTypeCreatable(newOption);
//         }, 0);


//     };



//     useEffect(() => {
//         (async () => {
//             if (TypeSuccess && optionsType) {
//                 await TypeRefetch();
//                 setValue("type", TypeData[0]?.value)
//             }
//         })();

//         return () => {
//         };
//     }, [TypeSuccess, TypeData]);


//     const [employeeOptions, setEmployeeOptions] = useState([])
//     const [forEmployeeValue, setForEmployeeValue]: any = useState("")
//     const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


//     useEffect(() => {
//         setEmployeeOptions(EmployeeData)
//     }, [EmployeeData])


//     const handleChangeEmployee = (e: any) => {
//         setForEmployeeValue(e)
//         setValue("employee_code", e.value)
//     }


//     const [CountryOptions, setCountryOptions] = useState([])
//     const [forCountryValue, setForCountryValue]: any = useState("")
//     const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()


//     useEffect(() => {
//         setCountryOptions(CountryData)
//     }, [CountryData])


//     const handleChangeCountry = (e: any) => {
//         setForCountryValue(e)
//         // setValue("country", e?.value)
//         setValue("country_code", e?.countryCode)
//     }


//     const onSubmit = (data: any) => {
//         const formData = new FormData();
//         for (const [key, value] of Object.entries(data)) {
//             const formvalue: any = value



//             if (typeof value !== "object") {
//                 formData.append(key, formvalue)
//             }

//         }
//         Contact(formData)
//     }


//     const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
//     const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

//     let rawData = localStorage.getItem('Add Data');
//     let addData = rawData ? JSON.parse(rawData) : null;

//     let basicId = addData ? addData.id : null;




//     useEffect(() => {
//         if (isSuccess && data) {


//             let tempId = {
//                 contact_detail: data?.data?.id
//             }

//             let tempData = {
//                 id: basicId,
//                 apiname: apinameForBasicDetails,
//                 data: tempId
//             }
//             BasicDetails(tempData);
//             // BasicDetails({})

//         }
//     }, [data, isSuccess])



//     useEffect(() => {
//         if (basicDetailsIsSuccess && basicDetailsData) {

//             localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
//             const currentPath = window.location.pathname;
//             if (currentPath === '/addCustomerDetails') {
//                 setCurrentStep(currentStep + 1)
//             };
//         }
//     }, [basicDetailsIsSuccess, basicDetailsData])

//     const [show, setShow] = useState(false)
//     const [showmessage, setshowmessage] = useState("")

//     useEffect(() => {
//         if (isSuccess) {
//             setShow(true)
//             setshowmessage("success")
//             //  const currentPath = window.location.pathname;
//             // if(currentPath === '/addCustomerDetails'){
//             // setCurrentStep(currentStep + 1)
//             // };
//         }
//         else if (isError) {
//             setShow(true)
//             setshowmessage("danger")
//         }
//     }, [data, error])

//     useEffect(() => {
//         setTimeout(() => {
//             setShow(false)
//         }, 3000)

//     }, [show]);

//     const handleReset = () => {
//         reset()
//     }




//     const [documentType, setDocumentType] = useState("")
//     const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()

//     const handleDocumentType = (e: any) => {


//         const type = e.target.value
//         if (type === "Lan") {
//             setDocumentType("Lan Number")
//             setRegexForDocumentNumber(/^[0-9]\d{2,4}\d{6,8}$/)
//         } else if (type === "Mobile") {
//             setDocumentType("Mobile Number")
//             setRegexForDocumentNumber(/^(\+\d{1,3}[- ]?)?\d{10}$/g)
//         } else if (type === "Fax") {
//             setDocumentType("Fax Number")
//             setRegexForDocumentNumber(/[\+? *[1-9]+]?[0-9 ]+/gm)
//         }
//     }

//     const maxLengthCheck = (object: any) => {
//         if (object.target.value.length > object.target.maxLength) {
//             object.target.value = object.target.value.slice(0, object.target.maxLength)
//         }
//     }


//     const handleNumbercode = (e: any) => {
//         let temp = e.target.value.replace(/\D/g, '');
//         if (temp.length > 10) temp = temp.substring(0, 10);
//         if (temp.length > 6) {
//             temp = temp.slice(0, 5) + ' ' + temp.slice(5);
//         }
//         e.target.value = temp;

//         setValue('service_mobile_no', temp)
//     }

//     const currentPath = window.location.pathname;



//     return (
//         <div>
//             <div className="" >
//                 <section className="content-header py-1 px-3">
//                     {/* <div className="container-fluid">
//                         <div className="row">
//                             <div className="col-sm-6">
//                                 <h4>Add {pageName}</h4>
//                             </div>
//                             <div className="col-sm-6">
//                                 <ol className="breadcrumb float-sm-right">
//                                     <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
//                                     <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
//                                     <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
//                                     <li className="breadcrumb-item active">Add {pageName}</li>
//                                 </ol>
//                             </div>
//                         </div>
//                     </div> */}
//                 </section>
//                 <section className="">
//                     <div className="">
//                         <div className="card card-default">
//                             {/* <div className="card-header">
//                                 <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
//                             </div> */}
//                             <form ref={focus}
//                                 onKeyUp={event => onEnterKey(event)}
//                                 onSubmit={handleSubmit(onSubmit)}>
//                                 <div className="card-body" >
//                                     <div className="row">
//                                         <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
//                                     </div>
//                                     <div className="row d-flex justify-content-md-center justify-content-lg-start">
//                                         <div className="col-md-12">
//                                             <div className="row">
//                                                 {/* <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Type <span style={{ color: 'red' }}>*</span></label>
//                                                     <CreatableSelect
//                                                     isClearable={()=>{setValueType(null)
//                                                         setValue(null)}}
//                                                         {...register("type", { required: { value: true, message: "Type is required" } })}
//                                                         placeholder={'Select Type'}
//                                                         options={optionsType}
//                                                         onCreateOption={handleCreate}
//                                                         onChange={handleChangeType}
//                                                         value={valueTypeCreatable==null?valueType:valueTypeCreatable}

//                                                     />
//                                                     <span className='text-red w-100'>{errors?.type?.message}</span>

//                                                 </div> */}
//                                                 {/* <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="name_and_grade">Employee Code & Surname & Name & City <span style={{ color: 'red' }}>*</span></label>

//                                                     <Select
//                                                         {...register("employee_code", { required: { value: true, message: "Employee Code,Surname,Name & City is required" } })}
//                                                         value={forEmployeeValue}
//                                                         options={employeeOptions}
//                                                         onChange={handleChangeEmployee}
//                                                         components={customIndicator}
//                                                         isOptionDisabled={(options: any) => {
//                                                             if (options.status === "inactive" || options.deleted == true) {
//                                                                 return options
//                                                             }
//                                                         }}

//                                                     />
                                                  
//                                                     <span className='text-red w-100'>{errors?.employee_code?.message}</span>
//                                                 </div> */}
//                                                 {/* <div className="col-sm-3 mt-2">
//                                                 <label htmlFor="name_and_grade">Country <span style={{ color: 'red' }}>*</span></label>
                                                   
//                                                         <Select 
//                                                             {...register("country",{required: { value: true, message: "Country is required" }})}
//                                                             value={forCountryValue}
//                                                             options={CountryOptions}
//                                                             onChange={handleChangeCountry}
//                                                             isOptionDisabled={(options:any)=>
//                                                                 {
//                                                                     if(options.status === "inactive" || options.deleted == true){
//                                                                     return options
//                                                                     }
//                                                                    }}

//                                                         />
                                                       
//                                                     <span className='text-red w-100'>{errors?.country?.message}</span>
//                                                 </div> */}

//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Country </label>
//                                                     <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_name} placeholder="Country"
//                                                     // {...register("country", { required: { value: false, message: "Country is required" } })} 
//                                                     />
//                                                     <span className='text-red w-100'>{errors?.country?.message}</span>
//                                                 </div>

//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Email Type <span style={{ color: 'red' }}>*</span></label>
//                                                     <select className="form-select" {...register("email_type", { required: { value: true, message: "Email Type is required" } })}>

//                                                         <option value={""} selected>Select Any</option>
//                                                         <option value="Home">Home</option>
//                                                         <option value="Shop">Shop</option>
//                                                         <option value="Office">Office</option>
//                                                         <option value="Personal">Personal</option>
//                                                         <option value="Company Email Id">Company Email Id</option>
//                                                         <option value="Personal Email Id">Personal Email Id</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.email_type?.message}</span>
//                                                 </div>

//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: 'red' }}>*</span></label>
//                                                     <select className="form-select" {...register("number_type", { required: { value: true, message: "Number Type is required" } })}>

//                                                         <option value={""} selected>Select Any</option>
//                                                         <option value="Mobile-1">Mobile-1</option>
//                                                         <option value="Mobile-2">Mobile-2</option>
//                                                         <option value="Home Mobile">Home Mobile</option>
//                                                         <option value="Shop">Shop</option>
//                                                         <option value="Office">Office</option>
//                                                         <option value="Company Mobile No">Company Mobile No</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.number_type?.message}</span>
//                                                 </div>
//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Country Code </label>
//                                                     <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_code} placeholder="Country Code" {...register("country_code", { required: { value: false, message: "Country Code is required" } })} />
//                                                     <span className='text-red w-100'>{errors?.country_code?.message}</span>
//                                                 </div>

//                                                 {/* <div className="col-sm-3 mt-2">
//                                                 <label htmlFor="exampleInputEmail1">Device Type <span style={{ color: 'red' }}>*</span></label>
//                                                     <select className="form-select" {...register("device_type", {onChange:handleDocumentType, required: { value: true, message: "Device Type is required" } })}>

//                                                         <option value={""} selected>Select Any</option>
//                                                         <option value="Lan">LAN</option>
//                                                         <option value="Mobile">Mobile</option>
//                                                         <option value="Fax">FAX</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.device_type?.message}</span>
//                                                 </div> */}

//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="exampleInputBrand"
//                                                             placeholder="Mobile Number"
//                                                             //  maxLength={10}
//                                                             // minLength={15}
//                                                             // onInput={documentType === "Mobile Number" && maxLengthCheck}
//                                                             {...register('number', {
//                                                                 required: 'Mobile Number is required',
//                                                                 pattern: {
//                                                                     // value: regexForDocumentNumber,
//                                                                     message: 'Please enter a valid Mobile Number',
//                                                                 },
//                                                             })}
//                                                             onChange={handleNumbercode}
//                                                         />
//                                                         <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
//                                                             
//                                                             /></span>

//                                                         </div>
//                                                     </div>
//                                                     <span className='text-red w-100'>{errors?.number?.message}</span>
//                                                 </div>
//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Contact Name & Surname <span style={{ color: 'red' }}>*</span></label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="exampleInputBrand" placeholder="Contact Name & Surname"
//                                                             {...register('contact_name_and_surname', { required: true, message: 'Contact Name & Surname is required' })} />
//                                                         <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
//                                                             
//                                                             /></span>

//                                                         </div>
//                                                     </div>
//                                                     <span className='text-red w-100'>{errors?.contact_name_and_surname?.message}</span>
//                                                 </div>




//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
//                                                     <div className="input-group">
//                                                         <input type="email" className="form-control" id="exampleInputBrand" placeholder="Email Address"
//                                                             {...register('email_address', {
//                                                                 required: 'Email is required',
//                                                                 pattern: {
//                                                                     value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
//                                                                     message: 'Please enter a valid email',
//                                                                 },
//                                                             })} />
//                                                         <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
//                                                             
//                                                             /></span>

//                                                         </div>
//                                                     </div>
//                                                     <span className='text-red w-100'>{errors?.email_address?.message}</span>
//                                                 </div>


//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Website </label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="exampleInputBrand" placeholder="Website"
//                                                             {...register('social_media_account', { required: false, message: 'Website is required' })} />
//                                                         <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
//                                                             
//                                                             /></span>

//                                                         </div>
//                                                     </div>
//                                                     <span className='text-red w-100'>{errors?.social_media_account?.message}</span>
//                                                 </div>


//                                                 <div className="col-sm-3 mt-2">


//                                                     <label >Re-Mark</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control"

//                                                             placeholder={'Re-Mark'}


//                                                             {...register('re_mark', { required: { value: false, message: "Field is required" } })}
//                                                         //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
//                                                         />
//                                                         <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
//                                                             /></span>

//                                                         </div>
//                                                     </div>
//                                                     <span className='text-red w-100'>{errors?.re_mark?.message}</span>
//                                                 </div>




//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
//                                                     <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

//                                                         <option value={""} selected>Select Any</option>
//                                                         <option value="active">Active</option>
//                                                         <option value="inactive">Inactive</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.status?.message}</span>

//                                                 </div>


//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="row d-flex  ">
//                                         <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
//                                             <div className="row d-flex ">
//                                                 <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
//                                                     <div>
//                                                         {currentPath === '/addCustomerDetails' ?
//                                                             <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
//                                                             : null}


//                                                         {currentPath === '/addCustomerDetails' ?
//                                                             <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
//                                                             : null}

//                                                     </div>
//                                                     <div>
//                                                         <button
//                                                             type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
//                                                             style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

//                                                             onClick={handleSubmit(onSubmit)}
//                                                         >Submit</button>
//                                                         <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
//                                                         <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
//                                                     </div>

//                                                     {/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button> */}

//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </form>

//                         </div>
//                     </div>
//                 </section>
//             </div>
//         </div>
//     )
// }

// export default Add_Contact_Format