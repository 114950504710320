import { createSlice, createAsyncThunk, current   } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

// const createProjectThunk = createAsyncThunk(
//   "project/createNew",
//   async (projectObj) => {
//     const response = await createProject(projectObj);
//     return response;
//   }
// );


// const updatePayslipTwo = createAsyncThunk(
//   'payroll/updatePayslip',
//   async (payload) => {
//     const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/payslip`, payload);
//     return response.data;
//   }
// );

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {
    clearPayroll: (state) => {
      state.payslip = [];
    },
    addPayslip: (state, { payload }) => {
      state.list = payload;
    },
    updatePayslip: (state, { payload: { id, value, key } }) => {
      const item = state.list.results.find((i) => {
        return i.id === id;
      });
      if (typeof item === "object") {
        item[key] = value;
        item.bonus = item.bonus;
        item.totalPayable =
          (item.salaryPayable || 0) + (item.bonus || 0) - (item.deduction || 0);
      }
    },

    // updatePayslip: (state, { payload: { id, value, key } }) => {
    //   const item = state.list.results.find((i) => {
    //     return i.id === id;
    //   });
    //   if (typeof item === "object") {
    //     item[key] = value;
    //     item.totalPayable =
    //       item.salaryPayable + item.bonus - (item.deduction || 0);
    //   }
    // },
   
    
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(updatePayslipTwo.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(updatePayslipTwo.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.list = action.payload;
  //     })
  //     .addCase(updatePayslipTwo.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     });
  // },

  // extraReducers: (builder) => {
  //   // Add reducers for additional action types here, and handle loading state as needed
  //   builder.addCase(createProjectThunk.fulfilled, (state, action) => {
  //     // Add user to the state array
  //     state.projects.push(action.payload);
  //   });
  // }
});

export default payrollSlice.reducer;
export const { clearPayroll, updatePayslip, addPayslip } = payrollSlice.actions;
