import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import { useFetchDiscountProductDropDown, useFetchResponsiblePerson } from "../../../hooks/DropDown_Api";
import Axiosinstance from "../../../hooks/Interceptor";


const Edit_Discount_Approval_View = () => {
    let pageName = "Discount Approval View"


    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_DISCOUNT_APPROVAL_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    const [approvalStatus, setApprovalStatus]:any = useState()

    const onApprovalStatusChange = (e: any) => {
        setApprovalStatus(e.target.value)
    }
    const [ValueDiscount, setValueDiscount] = useState()
    const onApprovalDiscount = (e:any) =>{
        setValueDiscount(e.target.value)
    }
    const [DiscountProduct,setDiscountProduct] = useState('')
    // const handleDiscountProductChange = (e:any) =>{
    //     setDiscountProduct(e.target.value)
    // }

    

    const handleReset = () => {
        reset()
    }
    const [optionsApprover, setOptionsApprover] = useState([])
    
    const [ValueOptionalApprover, setValueOptionalApprover] = useState(null)
    const [selectedApprover, setSelectedApprover]: any = useState()
    // const [selectedCustomer, setSelectedCustomer]: any = useState()
    const [selectedOptionalApprover, setSelectedOptionalApprover]: any = useState()
    const [selectedApprovalStatus, setSelectedApprovalStatus]: any = useState()

    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedApprover(data?.approver?.username)
            // setSelectedCustomer(data?.company_name_and_city?.name)
            // setSelectedApprovalStatus(data?.approval_status)
            setSelectedOptionalApprover(data?.optional_approver?.username)
            // setValue('optional_approver',data?.optional_approver?.id)



        }
    }, [data]);



    const { data: userData, refetch: userRefetch } = useFetchResponsiblePerson()

    useEffect(() => {
        setOptionsApprover(userData)
    }, [userData])


    const onSubmit = (materialtypeDetails: any) => {
        // 
        delete materialtypeDetails?.approved_date
        delete materialtypeDetails?.instanceId
        delete materialtypeDetails?.created_at
       
        delete materialtypeDetails?.approval_status
        
        delete materialtypeDetails?.is_deleted
        // if(inquiryData?.discount_type === "Total Amount Wise"){
        //     delete materialtypeDetails.approved_discount
        //     delete materialtypeDetails.approval_status
        // }
        // if(inquiryData?.discount_type === "Product Wise"){
        //     delete materialtypeDetails.approved_discount
        // }

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object" && key !="") {
                formData.append(key, formvalue)
            }

           
        
            

        }
        if(inquiryData?.discount_type === "Product Wise"){
            if(productData){
                // let temp = productData?.map((e:any)=>{
                //     return{
                //         id:e?.id,
                //         approval_status:e?.approval_status,
                //         approved_discount:e?.approved_discount,
                //     }
                // })
                formData.append('approval_status', approvalStatus)
                formData.append('product', JSON.stringify(productChanges))
            }
        }

        if (inquiryData?.discount_type === "Total Amount Wise") {
            if (ValueDiscount && approvalStatus) {
                let temp = {
                    approved_discount: ValueDiscount,
                    approval_status: approvalStatus
                };
                formData.append('amount_wise', JSON.stringify(temp));
            }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        if (!discountError) {
            mutate(data)
        }


    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listDiscountApproval', { state: { showEditToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error, isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);




    const [fromDiscount, setFromDiscount] = useState('')
    const [toDiscount, setToDiscount] = useState('')
    const [discountMessage, setDiscountMessage] = useState('')
    const [discountError, setDiscountError] = useState(false)


    const handleFromDiscount = (e: any) => {
        setFromDiscount(e.target.value)
    }
    const handleToDiscount = (e: any) => {
        setToDiscount(e.target.value)
    }


    useEffect(() => {
        if (fromDiscount && toDiscount) {
            if (fromDiscount > toDiscount) {
                setDiscountMessage('It Should be less than To discount')
                setDiscountError(true)
            } else {
                setDiscountError(false)
            }
        }
    }, [fromDiscount, toDiscount, discountMessage, discountError])


    const [productData, setProductData]: any = useState([])
    const [inquiryData, setInquiryData]: any = useState()

    useEffect(() => {
        if (inquiryData?.product?.length > 0) {
            setProductData(inquiryData?.product)
            setValue("",)
        }
    }, [inquiryData])


    useEffect(() => {

        if (data?.instanceId) {

            Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/${data?.instanceId}`).then((data: any) => setInquiryData(data.data.data))
        }
    }, [data])



    const [productChanges, setProductChanges]:any = useState([]);

    const handleDiscountProductChange = (e:any, productId:any) => {
        setDiscountProduct(e.target.value)
        const updatedValue = e.target.value;
        const existingProduct = productChanges?.find((p:any) => p.id === productId);
    
        if (existingProduct) {
            existingProduct.discountStatus = updatedValue;
            setProductChanges([...productChanges]);
        } else {
            setProductChanges([...productChanges, { id: productId, discountStatus: updatedValue }]);
        }
    };
    
    const handleApprovalDiscountChange = (e:any, productId:any) => {
        const updatedValue = e.target.value;
        const existingProduct = productChanges.find((p:any) => p.id === productId);
    
        if (existingProduct) {
            existingProduct.approvalDiscount = updatedValue;
            setProductChanges([...productChanges]);
        } else {
            setProductChanges([...productChanges, { id: productId, approvalDiscount: updatedValue }]);
        }
    };




    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" placeholder="Customer" value={inquiryData?.company_name_and_city?.name} />

                                                    </div>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Approver</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" placeholder="Approver" value={selectedApprover} />

                                                    </div>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Optional Approver</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled placeholder="Optional Approver" value={selectedOptionalApprover}
                                                        />

                                                    </div>
                                                </div>

                                                {inquiryData?.discount_type === "Total Amount Wise" ?


                                                    <>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Discount Type </label>
                                                            <div className="input-group" >
                                                                <input type="text" className="form-control"
                                                                    disabled value={inquiryData?.percentage_or_amount}
                                                                    placeholder="Discount Type"
                                                                />


                                                            </div>
                                                            <span className='text-red w-100'>{errors?.percentage_or_amount?.message}</span>
                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label >Discount  </label>
                                                            <div className="input-group" >
                                                                <input type="number" className="form-control"
                                                                    disabled value={inquiryData?.discount}
                                                                    placeholder="Discount"
                                                                />

                                                            </div>
                                                            <span className='text-red w-100'>{errors?.discount?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Total Amount  </label>
                                                            <div className="input-group" >
                                                                <input type="number" className="form-control"
                                                                    disabled value={inquiryData?.final_amount}
                                                                    placeholder="Total Amount"

                                                                />

                                                            </div>
                                                        </div>
                                                        {approvalStatus === "Approved" ?
                                                            <div className="col-sm-3 mt-2">
                                                                <label >Approved Discount  </label>
                                                                <div className="input-group" >
                                                                    <input type="number" className="form-control"
                                                                        placeholder="Approved Discount"
                                                                        onChange={onApprovalDiscount}
                                                                        // {...register("approved_discount", { required: { value: approvalStatus === "Approved" ? true : false, message: "Field is required" } })}

                                                                    />

                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </>
                                                    : ""}

                                                <div className="col-sm-3 mt-2">
                                                    <label >Approval Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" onChange={onApprovalStatusChange}
                                                    // {...register("approval_status", { onChange: onApprovalStatusChange, required: { value: true, message: "Field is required" } })}
                                                    >

                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value="Approved">Approved</option>
                                                        {/* <option value="Pending">Pending</option> */}
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.approval_status?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Remark </label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control"

                                                            placeholder="Remark"
                                                            {...register("remark", { required: { value: false, message: "Field is required" } })}
                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>







                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive" >
                                        <table className="table" style={{ marginTop: "20px" }}>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Product Type</th>
                                                    <th>Special/Running</th>
                                                    <th>Code/Name/Type</th>
                                                    <th>Unit Of Measurment</th>
                                                    <th>Discount On</th>
                                                    <th>Discount Type</th>
                                                    <th>Discount With</th>
                                                    <th>Discount </th>
                                                    <th>Final Amount </th>
                                                    <th>Description</th>
                                                    <th>Qty</th>
                                                    <th>Requirement Date</th>
                                                    <th>Expected Basic Value</th>
                                                    <th>Possible Yes/No</th>
                                                    {inquiryData?.discount_type === "Product Wise" ?
                                                    <>
                                                    <th>Approval Status</th>
                                                    {DiscountProduct === "Approved"? 
                                                    <th>Approval Discount</th>
                                                    :""}
                                                    </>:""}
                                                </tr>
                                            </thead>
                                            <tbody>



                                                {productData?.map((field: any, index: any) => {

                                                    return (

                                                        <tr key={index} >
                                                            <td>{field?.id}</td>
                                                            <td>


                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field.product_type.type}

                                                                />

                                                            </td>
                                                            <td>

                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field.product_special_running}

                                                                />

                                                            </td>
                                                            <td>

                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={`${field?.pricelist_product?.product_code?.product_code}::${field?.pricelist_product?.product_code?.product_type?.type}::${field?.pricelist_product?.product_code?.mouldshape?.name}`}

                                                                />

                                                            </td>

                                                            <td >
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field?.uom?.name?.name}

                                                                />

                                                            </td>


                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field?.kg_or_carton}

                                                                />

                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Discount Type"
                                                                    disabled
                                                                    value={field?.percentage_or_amount}

                                                                />


                                                            </td>


                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Discount With"
                                                                    disabled
                                                                    value={field?.with_or_without_gst}

                                                                />


                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Discount"
                                                                    disabled
                                                                    value={field?.discount}

                                                                />


                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Final Amount"
                                                                    disabled
                                                                    value={field?.final_amount}

                                                                />

                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field?.product_description}

                                                                />


                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field?.product_qty}

                                                                />


                                                            </td>
                                                            <td>

                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field.requirement_date}

                                                                />

                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    disabled
                                                                    placeholder="Expected Basic Value"
                                                                    value={field.expected_basic_value}
                                                                />

                                                            </td>
                                                            <td>

                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    disabled
                                                                    value={field.possible_yes_no}

                                                                />

                                                            </td>
                                                            {inquiryData?.discount_type === "Product Wise" ?


                                                    <>
                                                     <td>
                            <select
                                onChange={(e) => handleDiscountProductChange(e, field.id)}
                                className="form-select"
                            >
                                {/* ... your options */}
                                <option value={""} selected disabled>Select Any</option>
                                                                    <option value="Approved">Approved</option>
                                                                    {/* <option value="Pending">Pending</option> */}
                                                                    <option value="Rejected">Rejected</option>
                            </select>
                        </td>
                                                            {/* <td>
                                                                <select
                                                                    onChange={(e) => handleDiscountProductChange(e)}
                                                                    className="form-select"
                                                                >
                                                                    <option value={""} selected disabled>Select Any</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Rejected">Rejected</option>
                                                                </select>

                                                            </td> */}
                                                            {DiscountProduct === "Approved"? 
                                                              <td>
                                                              <input
                                                                  className="form-control"
                                                                  type="text"
                                                                  placeholder="Approval Discount"
                                                                  onChange={(e) => handleApprovalDiscountChange(e, field.id)}
                                                              />
                                                          </td>
                                                            :""}
                                                            </>
                                                            : ""}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDiscountApproval")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Edit_Discount_Approval_View