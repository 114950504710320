import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Restricted from '../../Roles/Restricted';
import { Link } from 'react-router-dom';


const Table_footer = ({visibleColumns, pageSize, setPageSize, data, page, setPage, pageNumber,PageLoading, apiname, permissionClass,customerId,dos,isGenerateButton=false }:any) => {
    const handlePageSize = (e: any) => {
        setPageSize(e.target.value)
        setPage(1)
      };

      const [count,setCount] = useState(0)

      useEffect(()=>{

        setCount(data?.count==undefined?0:data?.count)
        
      },[data])

  return (
    <>
      <Restricted
       to={`${permissionClass?.front}.view_${permissionClass?.back}`}
        fallback={""}
      >
       {(visibleColumns.length > 0 ?
          <>
            <div className="clearfix row d-flex mx-3 py-3" style={{ marginTop: "0px" }}>
              <div className='col-lg-6 col-sm-12 col-md-6 '>
                <div className="hint-text ">Showing <b>
                  <select value={pageSize} onChange={handlePageSize}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="all">Show All</option>
                  </select>
                </b> out of <b>{data?.count}</b> entries</div>

               {PageLoading&& <div className="spinner-border text-primary mx-3 my-1"  role="status">
  <span className="visually-hidden">Loading...</span>
</div>}

              </div>

             




             



              {pageSize!=="all" && <div className='col-lg-6 col-sm-12 col-md-6'>

              {dos?.length>0&&isGenerateButton&&<>
  <Link
                    // to={`/verifydispatch_order_list`}
                    to={`/verifydispatch_order_list?dos=${encodeURIComponent(JSON.stringify(dos))}`}
                    className="btn btn-primary text-white mx-2"
                    style={{float:"right"}}
                  >
                    Generate Dispatch
                  </Link>
</>}
              
                <ul className="pagination" style={{ color: "red" }} >

                  {page===1?
                <>
                                  {/* <li className="rounded-1" style={{ color: "#003665", border: "2px solid gray" }} onClick={() => setPage(1)}><a href="#" style={{ color: "gray", background: "white", textDecoration: "none" }} className='px-2 '>First</a></li> */}

                </>  
                :
                <>
                
                <li className="rounded-1" style={{ color: "#003665", border: "2px solid #003665" }} onClick={() => setPage(1)}><a href="#" style={{ color: "#003665", background: "white", textDecoration: "none" }} className='px-2'>First</a></li>

                </>
                
                }




                  <Pagination style={{ marginTop: "1.5px" }} color="primary" count={Math.ceil(count / parseInt(pageSize))} page={page} onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                    setPage(page);
                  }} variant="outlined" shape="rounded" />

                  {page === pageNumber.length - 0?
                  
                <>
                  {/* <li className="rounded-1" style={{ color: "#003665", border: "2px solid gray" }} onClick={() => setPage(pageNumber.length - 0)}><a href="#" style={{ color: "gray", background: "white", textDecoration: "none" }} className=' px-2'>Last</a></li> */}

                </>
                :
                <>
                 <li className="rounded-1" style={{ color: "#003665", border: "2px solid #003665" }} onClick={() => setPage(pageNumber.length - 0)}><a href="#" style={{ color: "#003665", background: "white", textDecoration: "none" }} className=' px-2'>Last</a></li>

                </>
                
                }



                </ul>

              </div>}


            </div>



          </>
          : <>
            <div className="text-center  mb-5">
              <h3>No Data to show .....</h3>
            </div>
          </>
        )}
        </Restricted>
    </>
  )
}

export default Table_footer